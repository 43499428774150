<table class="calibration-table">
  <thead>
      <tr>
        <th class=""></th>
        <th class="center " [innerHtml]="'SUPPLY_FAN_TABLE' | translate"></th>
        <th class="" ><span [innerHTML]="'RETURN_FAN_TABLE' | translate"></span><br/>
            <span class="d-sm-block d-none " [innerHTML]="'RECIRCULATION_DUMPER_TABLE' | translate"></span>
            <span class="d-sm-none d-block " [innerHTML]="'REC_DUMPER_TABLE' | translate"></span>
          </th>
      </tr>
    </thead>
 
   
    <tbody>
      <ng-container *ngFor="let record of calibrations;" >
    <tr>
      <td class="record_ts">{{record.ts}}</td>
      <td></td>
      <td></td>
     
    </tr>
    <tr>
      <td class="label">{{'PAHSE_ONE' | translate}}</td>
      <td>{{record.cal_ph1_sf}}</td>
      <td></td>
      
    </tr>
    <tr>
      <td  class="label">{{'PAHSE_TWO' | translate}}</td>
      <td>{{record.cal_ph2_sf}}</td>
      <td>{{record.cal_ph2_rf}}</td>
    </tr>
    <tr class="">
      <td  class="label last_row">{{'PAHSE_THREE' | translate}}</td>
      <td class="last_row">{{record.cal_ph3_sf}}</td>
      <td class="last_row">{{record.cal_ph3_rd}}</td>
    </tr>
  </ng-container>
  </tbody>
  
</table>

export const EMA_TRANSLATIONS = {
	"GENERAL_ALARM":"Allarme generale",
"LOGIC_FREEZED":"Logica bloccata",
"COMM_FREEZED":"Comunicazione bloccata",
"HISTORIC_FREEZED":"Storico bloccato",
"COMM_ERROR":"Errore di comunicazione",
"MODBUS_EXCEPTION":"Eccezione Modbus",
"LOW_PRESSURE":"Pressione bassa",
"HIGH_PRESSURE":"pressione alta",
"AIR_SUPPLY_PROBE_FAILURE":"Fallimento sonda aria in ingresso",
"INLET_WATER_PROBE_FAILURE":"Fallimento sonda acqua in entrata",
"EVAPORATOR_PROBE_FAILURE":"Fallimento sensore evaporatore",
"CONDENSATOR_PROBE_FAILURE":"Fallimento sonda condensatore",
"EMA_ALARM":"Allarme",
"HUMIDITY_PROBE_FAILURE":"Fallimento sonda umidità",
"DIRTY_FILTERS":"Filtri sporchi",
"GENERAL_WARNING":"Avvertenza generale",
"DISPLAY_COMM_ERROR":"Errore di comunicazione schermo",
"CO2_PROBE_ALARM":"Allarme sonda CO2",
"LOW_H20_TEMPERATURE":"Temperatura H2O bassa",
"HIGH_H20_TEMPERATURE":"Temperatura H2O elevata",
"ANTI_FREEZE_WARNING":"Avvertenza anticongelamento ",
"PROBE_ALARM":"Allarme sonda",
"P_TRAP_ALARM":"Allarme sifone",
"ANTI_LEGIONELLA_WARNING":"Avvertenza antilegionella",
"ANTI_LEGIONELLA_ALARM":"Allarme antilegionella",
"ANTI_FREEZE_ALARM":"Allarme anticongelamento",
"DHW_TANK_PROBE_FAULT":"Fallimento sonda accumulo DHW",
"RE_CIRCULATION_PROBE_FAULT":"Fallimento sonda di circolazione",
"TARGET_TEMPERATURE_NOT_REACHED":"Temperatura obiettivo non raggiunta",
"NO_ER_AVAILABLE":"Nessuna risorsa energetica",
"THERMAL_ACTUATOR_NOT_AVAILABLE":"Attuatore termico non disponibile",
"SUPPLY_TEMPERATURE_PROBE_FAULT":"Fallimento sonda temperatura in ingresso",
"RETURN_TEMPERATURE_PROBE_FAULT":"Fallimento sonda temperatura di ritorno",
"HIGH_TEMPERATURE_LEVEL_1":"Temperatura elevata livello 1",
"LOW_TEMPERATURE_WARNING":"Bassa temperatura",
"HIGH_TEMPERATURE_LEVEL_2":"Temperatura elevata livello 2",
"HIGH_TEMPERATURE_LEVEL_3_ACTION_REQUIRED":"Temperatura elevata livello 3 (Azione richiesta da parte dell'utente)",
"LOW_TEMPERATURE_ACTION_REQUIRED":"Bassa temperatura (Azione richiesta da parte dell'utente)",
"HIGH_TEMPERATURE_RESETTING_MIXING_VALVE":"Temperatura elevata (Reinizializzazione valvola di miscelazione)",
 "LOW_TEMPERATURE_RESETTING_MIXING_VALVE":"Bassa temperatura (Reinizializzazione valvola di miscelazione)",
"SUPPLY_ANTIFREEZE_WARNING":"Avvertenza anticongelamento in ingresso",
"SUPPLY_ANTIFREEZE_ALARM":"Allarme anticongelamento in ingresso",
"ROOM_ANTIFREEZE_WARNING":"Avvertenza anticongelamento stanza",
"ROOM_ANTIFREEZE_ALARM":"Allarme anticongelamento stanza",
"LOW_TEMPERATURE":"Bassa temperatura",
"SOFT_COLD_START_ON":"Avvio logica Soft Cold Start",
"SUPPLY_CONTROL_LEVEL_1":"Controllo in ingresso livello 1",
"SUPPLY_CONTROL_LEVEL_2":"Controllo in ingresso livello 2",
"THERMOELECTRIC_VALVE_CONTROL_NOT_AVAILABLE":"Valvola di controllo termoelettrica non disponibile",
"SEASON_MODE_INCOMPATIBLE_WITH_MAIN_HYDRONIC_SYSTEM":"Stagionalità non compatibile con il sistema idronico principale",
"DYNAMIC_DELTAR_IN_USE":"DeltaR dinamico in uso",
"TEMPERATURE_SENSOR_FAULT":"Fallimento sensore temperature",
"HUMIDITY_SENSOR_FAULT":"Fallimento sensore umidità",
"ALARM_POSITION":"Allarme posizione",
"SERVICE_POSITION":"Servizio posizione",
"ERROR_POSITION":"Errore posizione",
"OPERATIVE_TEMPERATURE_SENSOR":"Sensore temperatura operativa",
"AIR_TEMPERATURE_SENSOR":"sensore temperature aria",
"HUMIDITY_SENSOR":"Sensore umidità",
"ANTIFREEZE":"Anticongelamento",
"IAQ_SENSOR":"sensore iAQ",
"AIR_TEMPERATURE_AND_HUMIDITY_SENSOR":"Sensore umidità e temperatura aria",
"WOOD_FLOOR":"Pavimento in legno",
"HIGH_UMIDITY":"Umidità elevata",
"TEMPERATURE_PROBE":"Sonda temperatura",
"HIGH_TEMPERATURE":"Alta temperatura"
}

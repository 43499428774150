export const ALARMS_TRANSLATIONS = {
    "ANTI_FREEZE_ON": "Anti-freeze on",
    "ENVIRONMENT_TEMPERATURE_PROBE_ALARM": "Environment temp probe alarm",
    "WATER_PROBE_ALARM": "Water probe alarm",
    "HOT_WATER_PROBE_ALARM": "Hot water probe alarm",
    "ENVIRONMENT_HUMIDITY_PROBE_ALARM": "Environment humidity probe alarm",
    "PROBE_ALARM": "Probe alarm",
    "CONDENSATE_DRAINAGE_FAILURE": "Condensate drainage failure. Check air unit condensate and water lines immediately.",
    "DIRTY_FILTER": "Dirty Filter",
    "GENERAL_ALARM": "General alarm",
    "LOW_PRESSURE": "Low pressure",
    "HIGH_PRESSURE": "High pressure",
    "GENERIC_ALARM": "Generic Alarm",
    "RECOVERY_FILTER_ALARM": "Recovery filter alarm",
    "EXTERNAL_AIR_FILTER_ALARM": "External air filter alarm",
    "INLET_FAN_INVERTER_ALARM": "Inlet fan inverter alarm",
    "EXPULSION_FAN_INVERTER_ALARM": "Expulsion fan inverter alarm",
    "HIGH_PRESSURE_PROBE_ALARM": "High pressure probe alarm",
    "LOW_PRESSURE_PROBE_ALARM": "Low pressure probe alarm",
    "DIFF_AIR_PRESSURE_TRANSDUCER_ALARM": "Diff. air pressure transducer alarm",
    "HUMIDITY_PROBE_ALARM": "Humidity probe alarm",
    "SUPPLY_NTC_PROBE_ALARM": "Supply ntc probe alarm",
    "EXTERNAL_AIR_NTC_PROBE_ALARM": "External air ntc probe alarm",
    "INTERNAL_BATTERY_NTC_PROBE_ALARM": "Internal battery ntc probe alarm",
    "EXTERNAL_BATTERY_NTC_PROBE_ALARM": "External battery ntc probe alarm",
    "PHASE_SEQUENCE_ALARM": "Phase sequence alarm",
    "COMPRESSOR_INVERTER_ALARM": "Compressor inverter alarm",
    "ANTI_FREEZE": "Anti-freeze",
    "FREON_MISSING": "Freon missing",
    "PROBE_FAILURE": "Probe failure",
    "ERROR_ON_FLOW_VALUE": "Error on flow value",
    "ERROR_ON_TEMPERATURE_VALUE": "Error on temp value",
    "HIGH_FLOW": "High flow",
    "LOW_FLOW": "Low flow",
    "SUPPLY_ANTIFREEZE": "Supply antifreeze",
    "ROOM_ANTIFREEZE": "Room antifreeze",
    "SUPPLY_TEMP_DOESNT_REACH_THE_TARGET": "Supply temp doesn't reach the target",
    "ENERGY_RESOURCE_NOT_AVAILABLE": "Energy resource not available",
    "OPERATIVE_TEMPERATURE_SENSOR_FAULT": "Operative temp sensor fault",
    "OPERATIVE_TEMPERATURE_SENSOR_FAILURE": "Operative temp sensor failure",
    "AIR_TEMPERATURE_SENSOR_FAULT": "Air temp sensor fault",
    "OPERATIVE_TEMPERATURE_SENSOR_OUT_OF_RANGE": "Operative temp sensor out of range",
    "AIR_TEMPERATURE_SENSOR_FAILURE": "Air temp sensor failure",
    "AIR_TEMPERATURE_SENSOR_OUT_OF_RANGE": "Air temp sensor out of range",
    "RH_SENSOR_FAILURE": "RH sensor failure",
    "RH_SENSOR_OUT_OF_RANGE": "RH sensor out of range",
    "CO2_SENSOR_FAILURE": "Fallimento sensore CO2",
    "CO2_SENSOR_OUT_OF_RANGE": "CO2 sensor out of range",
    "VOC_SENSOR_FAILURE": "VOC sensor failure",
    "VOC_SENSOR_OUT_OF_RANGE": "VOC sensor out of range",
    "GENERIC_FAILURE": "Generic failure",
    "CO2_MODULE_COMMUNICATION_ERROR": "CO2 module communication error",
    "CO2_MEASUREMENT_ERROR": "CO2 measurement error",
    "NTC_MEASUREMENT_ERROR": "NTC measurement error",
    "RH_TEMPERATURE_MODULE_COMMUNICATION_ERROR": "RH/temp module communication error",
    "RH_MEASUREMENT_ERROR": "RH measurement error",
    "TEMPERATURE_MEASUREMENT_ERROR": "Temperature measurement error",
    "OUTPUT_CONFIGURATION_ERROR": "Output configuration error",
    "TEMPERATURE_PROBE_FAILURE": "Temperature probe failure",
    "MODBUS_EXCEPTION_CODE": "Modbus exception code",
    "OUTSIDE_TEMPERATURE_UNDER_LIMIT_HP": "Outside temp under limit for air to air HP",
    "HIGH_HUMIDITITY": "High humiditity",
    "WOOD_FLOOR_SENSOR_ALARM": "Wood floor sensor alarm",
    "CAL_WARNING": "Calibration in progress",
    "FIREFIGHTING_ALARM": "Fire alarm",
    "PURESSURE_ALARM": "High pressure alarm - please check your Axiom device",
    "FWA_OVERHEATING": "Overheating in the floor warmer room.",
    "FWA_THERMAL_REQ_INHIBITED": "Thermal request inhibited by energy saving settings.",
    "FWA_THERMAL_REQ_INHIBITED_RELATED": "Thermal request inhibited by energy saving settings - a related room sensor is off.",
    "FWA_THERMAL_REQ_INHIBITED_COOLING": "Thermal request inhibited - a cooling request has been given priority.",
    "FWA_THERMAL_REQ_INHIBITED_MAX_RUNTIME_1": "Thermal request inhibited - max run time reached",
    "FWA_THERMAL_REQ_INHIBITED_MAX_RUNTIME_2": "during a concurrent cooling request.",
    "ATU_TNK_TEMP_WARNING":" The tank temp has not yet reached the appropriate level required for the ATU to perform effectively.",
    "HYS_DELAY_ACT_VALIDATE_OPTIMAL":"The hydronic system pump has not been yet activated due to suboptimal buffer tank temperature",
    "HYS_DELAY_ACT_SUBOPTIMAL":"The hydronic system pump has been activated despite the buffer tank temp. being suboptimal"
}

import { Pipe, PipeTransform } from '@angular/core';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';  

/*
 * Format a percent
 */
@Pipe({name: 'pct', pure: true })
export class PctPipe implements PipeTransform {

    constructor(private _sanitizer: DomSanitizer) { }  

    transform(value: any, digits?: number): SafeHtml {
        if (value === null || value === undefined || isNaN(value) || value == -3276.8 || value == '-3276.8') {
            //return '--.- %';
            return this._sanitizer.bypassSecurityTrustHtml('--.-' + '<span style="font-size:0.6em">%</span>');  
        }
        if (typeof value !== 'number') {
            value = Number.parseFloat('' + value);
        }
        if (digits === null || digits === undefined) {
            digits = 1;
        }
      //  return value.toFixed(digits) + ' %';


         return this._sanitizer.bypassSecurityTrustHtml(value.toFixed(digits) + '<span style="font-size:0.6em">%</span>');  
    }

}


export class User {

    constructor(
        public username: string = null,
        public email: string = null,
   //     public password: string = null,
        public id: string = null,
        public userOf: any[] = [],
        public guestOf: any[] = [],
        public masterOf: any[] = [],
        public residenceOf: any[] = [],
        public serviceOf: any[] = [],
        public favourite: any[] = [],
        public token: string = null,
        public admin: boolean = false,
        public mht_admin: boolean = false,
        public vip: boolean = false,
        public use_terms: boolean = false,
        public local: boolean = false,

    ) {}

}

import {Component, Input, OnInit} from '@angular/core';
import {ARS, MCU, SCH} from "../../../models/tables";
import {CurrentDateTime} from "../../../models/current-date-time";
import {Config} from "../../../models/config";

@Component({
  selector: 'shared-erv-hrv-schedule',
  templateUrl: './shared-erv-hrv-schedule.component.html',
  styleUrls: ['./shared-erv-hrv-schedule.component.scss'],
})
export class SharedErvHrvScheduleComponent implements OnInit {

  @Input()
  loaded: boolean;

  @Input()
  ars: ARS;

  @Input()
  MCU: MCU;

  @Input()
  SCH: SCH[];

  @Input()
  currentDateTime: CurrentDateTime;

  @Input()
  existSCH: boolean;

  @Input()
  scheduleStyle: string;

  @Input()
  schedule: SCH;

  @Input()
  id: string;

  @Input()
  config: Config;

  constructor() { }

  ngOnInit() {}

}

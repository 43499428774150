import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Platform, ActionSheetController, AlertController, ToastController, ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';

export interface interpolationType {
   text:string;
   id:number;
   option:string;
}
export interface advancedSettingsOptions {
	showpoint:boolean;
	interpolation:interpolationType;
}




@Component({
  selector: 'app-advanced-settings-graph',
  templateUrl: './advanced-settings-graph.component.html',
  styleUrls: ['./advanced-settings-graph.component.scss'],
})
export class AdvancedSettingsGraphComponent implements OnInit {


  @Input() title:string;
  @Input() message:string;
 
  @Input() options:advancedSettingsOptions;

  public buttons:any[];
  public  interpolationOptions:interpolationType[];
  public interpolation:interpolationType;

  public showpoint:boolean;


  showpointChange(event){
  	// console.log('showpoint change')
  	// console.log(event);
  	this.showpoint=event.detail.checked;
  }

   interpolationChange(event){
  	// console.log('interpolation change')
  	// console.log(event);
  	this.interpolation=event.detail.value;
  }

  compareWith(o1: interpolationType, o2: interpolationType) {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }


  constructor(private storage:Storage,private navParams: NavParams,private translate:TranslateService, public modalController:ModalController) {

  	this.showpoint=(<advancedSettingsOptions>this.navParams.get('options')).showpoint;
  	this.interpolation=(<advancedSettingsOptions>this.navParams.get('options')).interpolation;
  	// console.log(`da costruttore tiro su showpoint ${this.showpoint} e interpolation`)
  	// console.log(this.interpolation);

  	this.interpolationOptions=[
            {text:'MONOTONE',id:0,option:'monotone'},
          
            {text:'BASIS',id:1,option:'basis'},
           
            {text:'LINEAR',id:2,option:'linear'}
        ];
  	    this.buttons=[
               
                {
                  text: this.translate.instant('OK'),
                  handler: async (retParams) => {
                      
                      
                         this.modalController.dismiss({
                      		...retParams
                    });
                  }

                },
                 {
                    text: this.translate.instant('CANCEL'),
                    handler: async (retParams) => {
                        this.modalController.dismiss({});
                    }
                }
            ];
  }

  ngOnInit() {
  	// console.log(`%c ngOninit modale settings, options vale`,'font-size:1.5rem;color:blue')
  	// console.log(this.options);
  	this.interpolation=this.options.interpolation;
  	this.showpoint=this.options.showpoint;


  }

    btnClick(button: any) {
        

      

        if (button.handler) {
            // a handler has been provided, execute it
            // pass the handler the values from the inputs
            button.handler({showpoint:this.showpoint,interpolation:this.interpolation}) 
        }

       
    }


  ionViewWillEnter(){
  	 

  }

}

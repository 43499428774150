<div class="usedSched" style="padding-left: 0.3em;padding-right: 0.3em">
            
            <ion-item class="no-lines" ><ion-label style="text-align: center">{{'APPLIED_TO' | translate}}</ion-label></ion-item>

            <ion-list *ngIf="usedSchTMR.length != 0">
                <ion-list-header ><ion-label class="w-100">{{'TIMER' | translate}}</ion-label></ion-list-header>
                <ion-item *ngFor="let u of usedSchTMR" class="no-lines">{{u.name}}</ion-item>
            </ion-list>


            <ion-list *ngIf="usedSchH.length != 0 && !commonSet">
                <ion-list-header ><ion-label class="w-100">{{'ZONES' | translate }} ({{'H' | translate}})</ion-label></ion-list-header>
                <ion-item *ngFor="let u of usedSchH" class="no-lines">{{u.name}}</ion-item>
            </ion-list>
            
            <ion-list *ngIf="usedSchC.length != 0 && !commonSet">
                 <ion-list-header ><ion-label class="w-100">{{'ZONES' | translate }} ({{'C' | translate}})</ion-label></ion-list-header>
                <ion-item *ngFor="let u of usedSchC" class="no-lines">{{u.name}}</ion-item>
            </ion-list>
            
            <ion-list *ngIf="usedSchH.length != 0 && commonSet">
                 <ion-list-header ><ion-label class="w-100">{{'ZONES' | translate }} ({{'H_SLASH_C' | translate}})</ion-label></ion-list-header>
                <ion-item *ngFor="let u of usedSchH" class="no-lines">{{u.name}}</ion-item>
            </ion-list>
            
            <ion-list *ngIf="usedSchDHW.length != 0">
                <ion-list-header ><ion-label class="w-100">{{'DHW_SYSTEMS' | translate }}</ion-label></ion-list-header>
                <ion-item *ngFor="let u of usedSchDHW" class="no-lines">{{u.name}}</ion-item>
            </ion-list>
            
            <ion-list *ngIf="usedSchARS.length != 0 || usedSchFNC.length != 0">
                <ion-list-header ><ion-label class="w-100">{{'AIR_UNITS' | translate }}</ion-label></ion-list-header>
                <ion-item *ngFor="let u of usedSchFNC" class="no-lines">{{u.name}}</ion-item>
                <ion-item *ngFor="let u of usedSchARS" class="no-lines">{{u.name}}</ion-item>
            </ion-list>
            
            <ion-list *ngIf="usedSchGRP.length != 0">
                <ion-list-header ><ion-label class="w-100">{{'SEASON_SCHEDULE' | translate }}</ion-label></ion-list-header>
                <ion-item *ngFor="let u of usedSchGRP" class="no-lines">{{u.name}}</ion-item>
            </ion-list>
            <div class="w-100 d-flex flex-row justify-content-center align-items-center marginBottom">
                <button class="popover-button" (click)="close()">{{'CLOSE' | translate}}</button>
            </div>
        </div>   
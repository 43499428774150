import {Component, Input, OnChanges} from '@angular/core';
import {ActionSheetController, AlertController, ModalController, Platform} from '@ionic/angular';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';


import {ChangeTableValues} from '../../../actions/tables.actions';


import {AppState} from '../../../services/app-state.service';
import {AbstractSchedulationCommons, checkDualVariableMinTempDifferenceCAndReturnIt, checkDualVariableMinTempDifferenceHAndReturnIt, NO_TEMPERATURE} from '../../../pages/schedule/commons';
import {BitArray} from '../../../commons/bitarray';
import {MCU, SCH} from '../../../models/tables';
import {hourToIndex, indexToHour} from '../../../commons/hours';
import {Config, DUAL_MODE, SchedulationType} from '../../../models/config';
import * as _ from 'lodash';
import 'moment/locale/it';
import {first} from 'rxjs/operators';
import {StatusBar, Style} from '@capacitor/status-bar';

export enum Mode { New = 0, Edit = 1 }

interface Hour {
    index: number;
    text: string;
}


interface WeekDays {
    it: string[];
    en: string[];
}


@Component({
    selector: 'new-schedule',
    templateUrl: './new-schedule.component.html',
    styleUrls: ['./new-schedule.component.scss'],
})
export class NewScheduleComponent extends AbstractSchedulationCommons implements OnChanges {

    TABLES = ['Config', 'SCH', 'MCU'];

    // master control unit
    MCU: MCU;

    @Input() id: number;


    // day (0=sunday)
    @Input() dayFrom: number;
    @Input() dayTo: number;
    // from time (string, e.g. 12:30)
    @Input() from: string;
    // to time (string, e.g. 14:30)
    @Input() to: string;
    // selected days
    days: boolean[];
    // day labels
    labels: string[];
    // disable day selection
    @Input() disableDays: boolean = false;
    @Input() disableHour: boolean = false;
    // mode
    @Input() mode: Mode = Mode.New;


    @Input() schedulationType: SchedulationType;
    // hours array
    hours: Hour[];
    // modal/page
    modal: boolean = false;

    userClick: boolean;


    // fromIndex: number = 0;
    fromIndexH: number = -1;
    fromIndexM: number = -1;
    fromAmPm: string = '';
    // toIndex: number = 0;
    toIndexH: number = -1;
    toIndexM: number = -1;
    toAmPm: string = '';

    // from time (string, e.g. 12:30)
    editFrom: string;
    // to time (string, e.g. 14:30)
    editTo: string;
    // selected temperature (for variable temperature schedulation)
    @Input() temperature: number;
    @Input() temperatureH: number;
    @Input() temperatureC: number;

    // temperature limits
    @Input() max: number;
    @Input() min: number;
    @Input() step: number;

    // export enum in template
    Mode = Mode;
    SchedulationType = SchedulationType;
    //founded several temperature on selected block
    @Input() mixed: boolean;


    public timeFrom: string = "12:00";
    public timeTo: string = "12:00";
    public hour: number = 12;
    public minutes: number = 0;
    public currentoffset: number = 0;

    public selectedtimeFrom: string;
    public selectedtimeTo: string;
    public clickTimeout;
    public timeframe: string = 'am';

    public allLabels: WeekDays;

    public configObs: Observable<Config>

    /* nuove variabili */

    public fromMode: any;
    public DUAL_MODE = DUAL_MODE;

    constructor(protected store: Store<AppState>,
                protected platform: Platform,
                protected alertController: AlertController,
                protected actionSheetController: ActionSheetController,
                protected translate: TranslateService,
                protected modalController: ModalController) {
        super(store, platform, alertController, actionSheetController, translate, modalController);
        this.userClick = false;
        this.configObs = store.select('config') as Observable<Config>;

        this.populateHours();
    }


    ionViewWillEnter() {
        this.load(this.TABLES);

    }


    /* nuovi metodi */

    trackByFn(index) {
        return index;
    }


    fromToClick() {


        if (this.clickTimeout != undefined)
            clearTimeout(this.clickTimeout);
        this.userClick = true;
        this.clickTimeout = setTimeout(() => {
            if (this.fromMode) {

                setTimeout(async () => {

                    if (this.platform.is('cordova') || this.platform.is('capacitor')) {

                        await StatusBar.setStyle({style: Style.Light});
                    }
                });

            }

            this.userClick = false;
        }, 1700)
    }


    initTime(h) {
        let val;
        if (h != undefined) {


            let temp = hourToIndex(h) % 24;

            var s: string[] = (h || '').split(':');

            var newh: number = parseInt(s[0]);
            //ore, da 0 a 24
            if (Number(newh) == 0 || Number(newh) == 12 || Number(newh) == 24)
                temp = 24; //intervallo di 24 mezz'ore

            //console.log(`h vale ${h} hourToIndex(h)%24 vale ${hourToIndex(h)%24} newh ${newh} temp: ${temp}`)
            return (temp);
        } else return 0;
    }

    updateTime(time) {

        // console.log(`updateTime(${time}) fromMode vale ${this.fromMode} time vale ${time} userClick ${this.userClick}`);


        if (!this.fromMode && time != undefined && !this.userClick) {

            setTimeout(async () => {

                if (this.platform.is('cordova') || this.platform.is('capacitor')) {

                    await StatusBar.setStyle({style: Style.Light});
                }
            });

            //from

            this.timeFrom = time;

            let res = this.timeFrom.split(':');

            this.fromIndexH = Number(res[0]);
            this.fromIndexM = Number(res[1]);
            this.selectedtimeFrom = this.timeFrom
            //this.fromAmPm=this.timeframe;

            if (this.fromIndexH == 12 && this.fromAmPm == 'am')
                this.fromIndexH = 0;

            if (this.fromIndexH == 12 && this.fromAmPm == 'pm')
                this.fromIndexH = 12;

            if (this.fromIndexH >= 1 && this.fromIndexH <= 11 && this.fromAmPm == 'pm')
                this.fromIndexH = this.fromIndexH + 12;


            let tmpH = Math.floor(this.fromIndexH)

            // this.from=this.timeFrom+' '+this.fromAmPm;

            // console.log(`aggiorno this.from ${this.fromIndexH} ${this.fromAmPm}`)

            this.from = '' + tmpH + ':' + (this.fromIndexM == 0 ? '00' : '30');
            // console.log(`diventa ${this.from}`)

        }


        if (this.fromMode && time != undefined && !this.userClick) {
            //to

            this.timeTo = time;
            let res = this.timeTo.split(':');

            this.toIndexH = Number(res[0]);
            this.toIndexM = Number(res[1]);
            // this.toAmPm=this.timeframe;
            this.selectedtimeTo = this.timeTo

            // console.log(`aggiorno this.to ${this.toIndexH} ${this.toAmPm}`)


            if (this.toIndexH == 12 && this.toAmPm == 'am')
                this.toIndexH = 24;

            if (this.toIndexH == 12 && this.toAmPm == 'pm')
                this.toIndexH = 12;

            if (this.toIndexH >= 1 && this.toIndexH <= 11 && this.toAmPm == 'pm')
                this.toIndexH = this.toIndexH + 12;


            let tmpH = Math.floor(this.toIndexH)

            // this.to=this.timeTo+' '+this.toAmPm;
            this.to = '' + tmpH + ':' + (this.toIndexM == 0 ? '00' : '30');
            // console.log(`diventa ${this.to}`)
        }

        // console.log(`timeFrom: ${this.timeFrom} ${this.fromAmPm} timeTo ${this.timeTo} ${this.toAmPm}`);
        // console.log(`from: ${this.from} to ${this.to}`);
    }


    getHours() {
        let hours = new Array(11);
        return hours;
    }


    changetime(timeframe) {
        if (!this.fromMode) {
            //from
            this.fromAmPm = timeframe;
        }
        if (this.fromMode) {
            //to
            this.toAmPm = timeframe;
        }


    }

    updateAmPm() {

        if (!this.fromMode) {
            //from
            if (this.fromAmPm == 'am')
                this.fromAmPm = 'pm'
            else this.fromAmPm = 'am'
        }
        if (this.fromMode) {
            //to
            if (this.toAmPm == 'am')
                this.toAmPm = 'pm'
            else this.toAmPm = 'am'
        }

    }


    /* vecchi metodi */


    ngOnInit(): void {
        if (this.from == '')
            this.from = '00:00';
        if (this.to == '')
            this.to = '24:00';

        this.fromMode = false;
        this.fromIndexM = 0;
        this.toIndexM = 0;
        this.fromAmPm = 'am';
        this.toAmPm = 'am';

        if (this.min === undefined) {
            this.min = 60;
        }
        //  this.max = this.navParams.get('max');
        if (this.max === undefined) {
            this.max = 90;
        }
        if (this.temperature === null || this.temperature === undefined || this.temperature == NO_TEMPERATURE) {

            this.temperature = Math.round((this.min + this.max) / 2);
        }

        if (this.temperatureH === null || this.temperatureH === undefined || this.temperatureH == NO_TEMPERATURE) {

            this.temperatureH = Math.round((this.min + this.max) / 2);
        }

        if (this.temperatureC === null || this.temperatureC === undefined || this.temperatureC == NO_TEMPERATURE) {

            this.temperatureC = Math.round((this.min + this.max) / 2);
        }

        // Selected days
        this.days = [];
        this.labels = [];
        this.allLabels = {'it': [], 'en': []};
        this.allLabels['it'] = ['D', 'L', 'M', 'M', 'G', 'V', 'S'];
        this.allLabels['en'] = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
        // console.log(this.allLabels);


        this.configObs.pipe(first()).subscribe((data: Config) => {
            // console.log(`%clang vale ${data.language} `,'font-size:1.2rem;color:blue;')
            for (let index = 0; index < 7; index++) {
                this.days[index] = index >= this.dayFrom && index <= this.dayTo;
                // this.days[index] = this.day == index;
                this.labels[index] = this.allLabels[data.language][index];
            }
            if (this.from != "")
                this.parseFromTo();

        });


    }

    getFillValue(cond) {
        if (cond)
            return false;
        else
            return "solid";
    }

    ngOnChanges(inputs): void {
        this.parseFromTo();
    }

    parseFromTo(): void {
        this.userClick = true;
        this.editFrom = this.from;
        this.editTo = this.to;


        // console.error('parseFromTo()');

        let resFrom = this.from.split(':');

        let hFrom = Number(resFrom[0]);
        let mFrom = resFrom[1];
        let resTo = this.to.split(':');

        let hTo = Number(resTo[0]);
        let mTo = resTo[1];

        // console.log(`parto da from ${this.from} => ${hFrom}:${mFrom}`)
        // console.log(`parto da to ${this.to} => ${hTo}:${mTo}`)

        if (this.from) {


            let t: number = hourToIndex(this.from);
            // console.log('faccio il parsing effettivo di from, t vale ${t}')

            if (t >= 24) {
                this.fromAmPm = 'pm';

                if (t > 24) {
                    hFrom = hFrom - 12;

                }


                t = t % 24;


            } else {
                this.fromAmPm = 'am';
            }


            this.timeFrom = '' + hFrom + ':' + mFrom;
            // console.log(`alla fine timeFrom mi diventa ${this.timeFrom} ${this.fromAmPm}`)


            let h = t, m = 0;//h = 0,1,...12 - m = 0,1 -> 00,30
            if (t % 2 == 1) {
                h--;
                m = 1; // 30 min
            }
            h = h / 2;
            // this.fromIndex = t;
            this.fromIndexH = h;
            this.fromIndexM = m;
        }
        if (this.to) {


            setTimeout(async () => {

                if (this.platform.is('cordova') || this.platform.is('capacitor')) {

                    await StatusBar.setStyle({style: Style.Dark});
                }
            });


            let t: number = hourToIndex(this.to);
            // console.log('faccio il parsing effettivo di to t vale ${t}')
            if (t == 48) { // 12 AM (midnight)
                t = 0;
                // console.log(`beccato t==48, hTo valeva ${hTo}, sottraggo 12`);
                hTo = hTo - 12;
                this.toAmPm = 'am';
            } else if (t >= 24) {

                this.toAmPm = 'pm';

                if (t > 24) {
                    hTo = hTo - 12;

                }

                t = t % 24;


            } else {
                this.toAmPm = 'am';
            }


            let h = t, m = 0;//h = 0,1,...12 - m = 0,1 -> 00,30
            if (t % 2 == 1) {
                h--;
                m = 1; // 30 min
            }
            h = h / 2;
            // this.toIndex = t;
            this.toIndexH = h;
            this.toIndexM = m;

            this.timeTo = '' + hTo + ':' + mTo;
            // console.log(`alla fine timeTo mi diventa ${this.timeTo} ${this.toAmPm}`)
        }

        setTimeout(() => {
            this.userClick = false;
        }, 300)

    }

    get sch(): SCH {

        if (this.SCH) {

            return this.SCH[this.id];
        }

        return null;
    }

    toggleDay = (day: number): void => {

        if (day == -1) { // all
            if (this.allDays) {
                for (let index = 0; index < 7; index++) {
                    this.days[index] = false;
                }
            } else {
                for (let index = 0; index < 7; index++) {
                    this.days[index] = true;
                }
            }
        } else {
            if (day == -2) { // week
                if (this.allWorkDays) {
                    for (let index = 1; index < 6; index++) {
                        this.days[index] = false;
                    }
                } else {
                    for (let index = 1; index < 6; index++) {
                        this.days[index] = true;
                    }
                }
            } else {
                this.days[day] = !this.days[day];
            }
        }
    }

    toggleHourTo = (hour: number): void => {
        //hour 12,1,....11
        this.toIndexH = hour;
        this.to = '' + Math.floor(this.toIndexH + (this.toAmPm == 'pm' ? 12 : 0)) + ':' + (this.toIndexM == 0 ? '00' : '30');
    }
    toggleHourFrom = (hour: number): void => {
        //hour 12,1,....11
        this.fromIndexH = hour;
        this.from = '' + Math.floor(this.fromIndexH + (this.fromAmPm == 'pm' ? 12 : 0)) + ':' + (this.fromIndexM == 0 ? '00' : '30');
    }

    toggleMinTo = (min: number): void => {
        //min 0->00, 1-> 30
        this.toIndexM = min;
        this.to = '' + Math.floor(this.toIndexH + (this.toAmPm == 'pm' ? 12 : 0)) + ':' + (this.toIndexM == 0 ? '00' : '30');
    }

    toggleMinFrom = (min: number): void => {
        //min 0->00, 1-> 30
        this.fromIndexM = min;
        this.from = '' + Math.floor(this.fromIndexH + (this.fromAmPm == 'pm' ? 12 : 0)) + ':' + (this.fromIndexM == 0 ? '00' : '30');
        // console.log(`this.fromIndexM vale ${this.fromIndexM}`)
        // console.log(`this.from vale ${this.from}`)
    }

    toggleAmPmTo = (s: string): void => {
        this.toAmPm = s;
        this.to = '' + Math.floor(this.toIndexH + (this.toAmPm == 'pm' ? 12 : 0)) + ':' + (this.toIndexM == 0 ? '00' : '30');
    }

    toggleAmPmFrom = (s: string): void => {
        this.fromAmPm = s;
        this.from = '' + Math.floor(this.fromIndexH + (this.fromAmPm == 'pm' ? 12 : 0)) + ':' + (this.fromIndexM == 0 ? '00' : '30');
    }

    /**
     * Populate the hours array
     */
    populateHours(): void {
        this.hours = _.range(0, 24).map(index => {
            let text = indexToHour(index);
            if (text == '0:00') {
                text = '12:00';
            } else if (text == '0:30') {
                text = '12:30';
            }
            return {index: index, text: text};
        });
    }

    /**
     * Return true if all days are selected
     */
    get allDays(): boolean {
        for (let index = 0; index < 7; index++) {
            if (!this.days[index]) {
                return false;
            }
        }
        return true;
    }

    /**
     * Return true if all days are selected
     */
    get allWorkDays(): boolean {
        for (let index = 1; index < 6; index++) {
            if (!this.days[index]) {
                return false;
            }
        }
        return true;
    }

    /**
     * Return true if almost one day is selected
     */
    get almostOneDay(): boolean {
        for (let index = 0; index < 7; index++) {
            if (this.days[index]) {
                return true;
            }
        }
        return false;
    }

    /**
     * Return true if the selected parameters are valid
     * ( to > from, almost one day selected
     */
    get valid(): boolean {
        if (!this.almostOneDay) {
            return false;
        }
        if (!this.from || !this.to) {
            return false;
        }
        let from = hourToIndex(this.from);
        let to = hourToIndex(this.to);
        if (this.schedulationType != SchedulationType.Season)
            return (to - from) >= 1; // 0 as 24:00
        //return to > from || to == 0; // 0 as 24:00
        else if (this.schedulationType == SchedulationType.Season) {
            return (to - from) >= 2;
        }
    }

    /**
     * This method is executed when the user selects a value for 'from'
     */
    // protected onChangeFrom = ($event) => {
    //     let t: number = this.fromIndex / 2;
    //     this.from = '' + Math.floor(t + (this.fromAmPm == 'pm' ? 12 : 0)) + ':' + (t % 1 == 0 ? '00': '30');
    // }
    //
    // /**
    //  * This method is executed when the user selects a value for 'to'
    //  */
    // protected onChangeTo = ($event) => {
    //     let t: number = this.toIndex / 2;
    //     this.to = '' + Math.floor(t + (this.toAmPm == 'pm' ? 12 : 0)) + ':' + (t % 1 == 0 ? '00': '30');
    // }

    /**
     * Set the scheduled period and dismiss the popup
     */
    ok(seasonType?: number, $event?, heatingOrCoolingOfDualMode?: DUAL_MODE): void {

        if (this.mixed) {

            this.confirmPopup($event, 'ALERT', 'CONFIRM_SCHEDULE', 'CONFIRM', () => this.confirmSCH(null, heatingOrCoolingOfDualMode));

        } else {

            this.confirmSCH(seasonType, heatingOrCoolingOfDualMode);
        }
    }

    confirmSCH(seasonType?: number, heatingOrCoolingOfDualMode?: DUAL_MODE): void {

        // console.warn('confirmSCH');

        const from = hourToIndex(this.from);
        let to = hourToIndex(this.to);

        // console.log(`from ${this.from} =>idx ${from};`);
        // console.log(`to ${this.to} =>idx ${to};`);

        if (to == 0) { // 0 as 24:00

            to = 48;
        }

        const schedule = this.sch.PAR_Schedule;
        const temperatures = this.sch.PAR_TempSched;
        const temperaturesH = this.sch.PAR_TempSchedH;
        const temperaturesC = this.sch.PAR_TempSchedC;

        let setON = true;

        if (seasonType && seasonType == 1) {

            setON = false
        }

        for (let day = 0; day < 7; day++) {

            if (this.days[day]) { // for each selected day

                // schedulation
                const bitarray = new BitArray(48).fromNumber(schedule[day] || 0);

                if (this.mode == Mode.Edit) {

                    const f: number = hourToIndex(this.editFrom);
                    let t: number = hourToIndex(this.editTo);

                    if (t == 0) { // 0 as 24:00

                        t = 48;
                    }

                    for (let i = f; i < t; i++) {

                        bitarray.set(i, !setON);
                    }
                }

                for (let i = from; i < to; i++) {

                    bitarray.set(i, setON);
                }

                schedule[day] = bitarray.toNumber();
                // console.log(`day ${day} aggiornato a ${schedule[day]}`);

                // temperature
                if (this.sch.CFG_Type === SchedulationType.Variable || this.sch.CFG_Type === SchedulationType.DualVariable) {

                    if (this.mode == Mode.Edit) {

                        // reset
                        const f1: number = hourToIndex(this.editFrom);
                        let t1: number = hourToIndex(this.editTo);

                        if (t1 == 0) { // 0 as 24:00

                            t1 = 48;
                        }

                        for (let i = f1; i < t1; i++) {

                            if (this.sch.CFG_Type === SchedulationType.DualVariable) {

                                if (heatingOrCoolingOfDualMode === DUAL_MODE.heating) {

                                    temperaturesH[day * 48 + i] = NO_TEMPERATURE;

                                } else {

                                    temperaturesC[day * 48 + i] = NO_TEMPERATURE;
                                }
                            }

                            else {

                                temperatures[day * 48 + i] = NO_TEMPERATURE;
                            }
                        }
                    }

                    // set
                    for (let i = from; i < to; i++) {

                        if (this.sch.CFG_Type === SchedulationType.DualVariable) {

                            if (heatingOrCoolingOfDualMode === DUAL_MODE.heating) {

                                temperaturesH[day * 48 + i] = this.temperatureH;
                                temperaturesH[day * 48 + i] = checkDualVariableMinTempDifferenceHAndReturnIt(temperaturesH[day * 48 + i], this.sch, day, i);
                            }

                            else {

                                temperaturesC[day * 48 + i] = this.temperatureC;
                                temperaturesC[day * 48 + i] = checkDualVariableMinTempDifferenceCAndReturnIt(temperaturesC[day * 48 + i], this.sch, day, i);
                            }
                        }

                        else {

                            temperatures[day * 48 + i] = this.temperature;
                        }
                    }

                }
            }
        }

        let values = {PAR_Schedule: schedule, PAR_TempSched: temperatures, PAR_TempSchedH: temperaturesH, PAR_TempSchedC: temperaturesC};
        this.store.dispatch(new ChangeTableValues('SCH', this.id, values));

        this.modalController.dismiss({'table': 'SCH', 'id': this.id, 'values': values});

        setTimeout(async () => {

            if (this.platform.is('cordova') || this.platform.is('capacitor')) {

                await StatusBar.setStyle({style: Style.Dark});
            }

        }, 1000);
    }

    /**
     * Cancel - dismiss
     */
    cancel(): void {
        this.modalController.dismiss({});


        setTimeout(async () => {

            if (this.platform.is('cordova') || this.platform.is('capacitor')) {

                await StatusBar.setStyle({style: Style.Dark});
            }

        }, 1000);


    }

    onChangeTemperature = (temperature: string): void => {
        this.temperature = Number.parseFloat(temperature);
    }

    onChangeTemperatureH = (temperature: string): void => {
        this.temperatureH = Number.parseFloat(temperature);
    }

    onChangeTemperatureC = (temperature: string): void => {
        this.temperatureC = Number.parseFloat(temperature);
    }
}


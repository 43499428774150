
import { Injectable, Component, ChangeDetectorRef, ElementRef, HostListener, ViewEncapsulation, Input, Renderer2 } from '@angular/core';
import { NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
//import { Config } from 'ionic-angular/config/config';
import { Config } from '@ionic/angular';

//deprecated
//import { isPresent } from 'ionic-angular/util/util';
import {isPresent} from '../../../deprecated/util';

//tutti questi moduli non esistono più in ionic 5
/*import { Key } from '@ionic/angular/util/key';
import { NavParams } from '@ionic/angular/components/nav/nav-params';
import { NavOptions } from '@ionic/angular/components/nav/nav-interfaces';
import { ViewController } from '@ionic/angular/components/nav/view-controller';
import { App } from '@ionic/angular/components/app/app';*/


import { ModalController } from '@ionic/angular';


export enum KEY_CODE {
  ESCAPE = 27,
  ENTER = 13
}

@Component({
  selector: 'days-selector',
  templateUrl: './days-selector.component.html',
  styleUrls: ['./days-selector.component.scss']
})
export class DaysSelectorComponent {
    private activeId: string;
    private descId: string;


   
    
    @Input()   message?: string;
    @Input()   subTitle?: string;
    @Input()   title?: string;
    @Input()   buttons?: any[];
    @Input()   inputs?: any[];
       


    private enabled: boolean;
    private hdrId: string;
    private id: number;
    private lastClick: number;
    private msgId: string;
    private subHdrId: string;


   constructor(private translate:TranslateService, public modalController:ModalController,private cdr: ChangeDetectorRef){

   }

   ngAfterViewInit() {
    
    this.cdr.detectChanges();
  }

       @HostListener('body:keyup', ['$event'])
    public _keyUp(ev: KeyboardEvent) {
      ev.preventDefault();
      // console.log(ev.keyCode);

        if (this.enabled) {
            if (ev.keyCode === KEY_CODE.ENTER) {
                if (this.lastClick + 1000 < Date.now()) {
                    // do not fire this click if there recently was already a click
                    // this can happen when the button has focus and used the enter
                    // key to click the button. However, both the click handler and
                    // this keyup event will fire, so only allow one of them to go.
                    // console.debug('alert, enter button');
                    let button = this.buttons[this.buttons.length - 1];
                    this.btnClick(button);
                }

            } else if (ev.keyCode === KEY_CODE.ESCAPE) {
                // console.debug('alert, escape button');
                 this.modalController.dismiss({
                      'toSave': false
            });
        }
    }
}
   
    ionViewLoaded() {
        // normalize the data
      

        this.buttons = this.buttons.map(button => {
            if (typeof button === 'string') {
                return { text: button };
            }
            return button;
        });

        this.inputs = this.inputs.map((input, index) => {
            return {
                type: input.type || 'text',
                name: isPresent(input.name) ? input.name : index,
                placeholder: isPresent(input.placeholder) ? input.placeholder : '',
                value: isPresent(input.value) ? input.value : '',
                label: input.label,
                checked: input.checked,
                disabled: !!input.disabled,
                id: 'alert-input-' + this.id + '-' + index
            };
        });

        let checkedInput = this.inputs.find(input => input.checked);
        if (checkedInput) {
            this.activeId = checkedInput.id;
        }
    }

     btnClick(button: any, dismissDelay?: number) {
           // console.warn('btn click prima di if ! this.enabled');
        if (!this.enabled) {
            return;
        }

        // console.warn('btn click');

        // keep the time of the most recent button click
        this.lastClick = Date.now();

        let shouldDismiss = true;

        if (button.handler) { 
            // console.log("c'è handler")
            // a handler has been provided, execute it
            // pass the handler the values from the inputs
            if(button.handler(this.getValues())){
                shouldDismiss=false;
            }
           
        } 

        if (shouldDismiss) {
            setTimeout(() => {
                this.modalController.dismiss();
            }, dismissDelay );
        }
    }

    cbClick(event,checkedInput: any) {
         checkedInput.checked=event.detail.checked;
       // console.warn("loggo il checkbox liccato");
        //console.log(checkedInput);
        
        // console.warn("event vale")
        // console.log(event);
       
    }

    do(checkedInput:any){

        setTimeout(()=>{
                  if (this.enabled) {
            if (checkedInput.value == -1) { // all days
                // console.warn("ho cliccato su all")
                if (!checkedInput.checked) {
                    // console.warn("all è false");
                    // deselect all
                    this.inputs.forEach(input => {
                        if (!input.disabled && input.value!=-1) {
                            input.checked = false;
                        }
                    });
                } else {
                    // select all
                    // console.log("all è vero")
                    this.inputs.forEach(input => {
                        if (!input.disabled && input.value!=-1) {
                            input.checked = true;
                        }
                    });
                }

                this.ngAfterViewInit();
                // console.warn("loggo tutti gli inputs")
                // console.log(this.inputs);
            } else

             {
                
                //checkedInput.checked=!event.detail.checked;

                /*if(!checkedInput.checked)
                    checkedInput.checked = true;
                else if(checkedInput.checked)
                     checkedInput.checked = false;*/


                
                let all_: boolean = true;
                this.inputs.forEach(input => {
                    if (!input.checked) {
                        all_ = false;
                    }
                });
                if (!all_) {
                    this.inputs[0].checked = false; // disable 'all days'
                };
            }
        }



        },500);
       
    }

      getValues() {
         

        return this.inputs.filter(i => i.checked).map(i => i.value);
    }




}

let alertIds = -1;

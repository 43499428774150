export class ZeroconfDevice {

    public address;
    public hostname;

    public mcuCode;
    public loading = false;

    constructor(address, hostname, mcuCode) {

        this.address = address;
        this.hostname = hostname;
        this.mcuCode = mcuCode;
    }
}

<!-- rounded container -->
<div *ngIf="(ars_extended?.realCanNtd && zonePage.showNtdSetpoint() || fncDehumification) && radiant" class="rounded-container" [ngClass]="{'opened':dehumVisibility || !extensible}">

    <ion-list-header class=" bg-header text-black positionRelative">

        <div *ngIf="extensible" class="positionAbsolute timer-arrow cursorPointer">
            <i (click)="show()" class="far" [ngClass]="{'fa-chevron-up':dehumVisibility,'fa-chevron-down':!dehumVisibility}"></i>
        </div>

        <div class="d-flex flex-row justify-content-between align-items-center w-100">

            <div class="d-flex flex-row justify-content-start align-items-center">

                <div class="d-flex flex-row align-items-center">

                    <!-- "alignIconToRadioButton" fixes the dehumidification button not aligned with radio buttons (the fix is slightly different between Android / Web and iOS) -->
                    <!-- forceShowIconIfDisabled: normally, dehumidification icons are shown only if active. In this case, since it's used in the collapse's header, I want to make sure it's shown even if it's disabled -->
                    <icons [MCU]="MCU" [showPowerOff]="showPowerOff" [config]="config"
                           [status]="rms?.RTU_Flags || [0]" [alignIconToRadioButton]="alignIconToRadioButton"
                           [forceShowIconIfDisabled]="true" onlyNtd="true"></icons>
                </div>

                <div class="d-flex flex-row align-items-center">
                    <ion-label class="text-black ars-title">
                        <div [ngClass]="{'messana-disabled': fncDehumification && !fnc.PAR_On || !fncDehumification && (!ars_extended?.PAR_On || !ars_extended?.PAR_NtdOn), 'colorEnabled': !disableNtdSetpoint()}" class="title-tmr">{{ 'DEHUMIDIFICATION' | translate }}</div>
                    </ion-label>
                </div>
            </div>

            <div class="d-flex flex-row justify-content-end align-items-center">
                <ion-item lines="none">

                    <ion-toggle color="secondary" [(ngModel)]="rms.PAR_NtdOn" [checked]="(ars_extended?.RTT_CanNtd || fncDehumification) && rms.PAR_NtdOn"
                                (ionChange)="updateRttCanNtd($event)"></ion-toggle>
                </ion-item>
            </div>
        </div>
    </ion-list-header>

    <div *ngIf="dehumVisibility || !extensible">

        <counter [inDisabled]="fncDehumification && !fnc?.PAR_On || !fncDehumification && ars_extended?.realCanNtd && (!ars_extended?.PAR_On || !ars_extended?.PAR_NtdOn || disableNtdSetpoint())"
                 (radioClickEmitter)="zonePage.changeRhModeDP($event)"
                 [radio]="ars_extended?.PAR_On || fnc?.PAR_On" [radioChecked]="MCU.PAR_RhMode" temperature
                 [disabledSP]="!MCU.PAR_RhMode"
                 label="{{ 'DEW_POINT_TEMP' | translate }}" table="RMS" [item]="rms" var="PAR_SetDehumDP"
                 [attrs]="zonePage.attrs" [MCU]="MCU" [changableSetpoint]="true"
                 [whileChange]="zonePage.onChange" itemClass="no-lines padding-right-counter paddingSide-16 padding-top-1-5-rem"></counter>

        <counter [inDisabled]="fncDehumification && !fnc?.PAR_On || !fncDehumification && ars_extended?.realCanNtd && (!ars_extended?.PAR_On || !ars_extended?.PAR_NtdOn || disableNtdSetpoint())"
                 (radioClickEmitter)="zonePage.changeRhModeHum($event)"
                 [radio]="ars_extended?.PAR_On || fnc.PAR_On" [radioChecked]="!MCU.PAR_RhMode" percent
                 [disabledSP]="MCU.PAR_RhMode"
                 label="{{ 'RELATIVE_HUMIDITY' | translate }}" table="RMS" [item]="rms" var="PAR_SetDehumRH"
                 [attrs]="zonePage.attrs" [MCU]="MCU" [changableSetpoint]="true"
                 [whileChange]="zonePage.onChange" itemClass="no-lines padding-right-counter paddingSide-16"></counter>

    </div>
</div>

<ion-header class="two-lines-header" *ngIf="!loading">

    <ion-toolbar>

        <ion-title *ngIf="rms?.CFG_IdxMCZ > 0">{{ mcz?.CFG_Name }}</ion-title>

        <ion-buttons slot="start">
            <back-button-custom (click)="goBack()"></back-button-custom>
        </ion-buttons>

        <ion-title *ngIf="!connected">{{ rms?.CFG_Name }}</ion-title>

        <ion-buttons slot="end">

            <button [ngClass]="connected ? 'positionAbsolute go-home' : 'more'" (click)="goHome(router)">
                <span class="far fa-tachometer-alt icon"></span>
            </button>

            <button *ngIf="connected"
                    class="d-flex flex-row justify-content-center align-items-center more positionRelative"
                    (click)="openMenu($event)">
                <ion-icon name="more"></ion-icon>
            </button>

        </ion-buttons>
    </ion-toolbar>

    <ion-toolbar *ngIf="connected" class="prev-next-toolbar">

        <ion-buttons slot="start">
            <button *ngIf="roomsLength > 1" royal (click)="prev()" [disabled]="!connected "
                    class="disable-hover prev-next-chevron">
                <!--<ion-icon name="arrow-dropleft"></ion-icon>-->
                <i class="far fa-chevron-circle-left"></i>
            </button>
        </ion-buttons>

        <ion-title>{{ rms?.CFG_Name }}</ion-title>

        <ion-buttons slot="end">

            <button *ngIf="roomsLength > 1" royal (click)="next()" [disabled]="!connected "
                    class="disable-hover prev-next-chevron">
                <!--<ion-icon name="arrow-dropright"></ion-icon>-->
                <i class="far fa-chevron-circle-right"></i>
            </button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="outer-content zone" (keypress)="prevNext($event.keyCode)">

    <div *ngIf="!connected || loading" class="spinner-cover">
        <div class="mac-spinner center cover">
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
        </div>
    </div>

    <div *ngIf="connected && !loading" class="rounded-container zone-controls" [ngClass]="{'off':!state}">

        <ion-list>
            <div class="d-flex flex-row justify-content-between align-items-center">
                <div class="d-flex flex-row justify-content-start align-items-center">
                    <div>
                        <ion-item class="no-lines">
                            <ion-toggle *ngIf="loaded && !noRadiant" color="secondary" class="zone-main-toggle"
                                        [checked]="state" (ionChange)="stateUpdated($event)"
                            ></ion-toggle>
                        </ion-item>
                    </div>
                </div>
                <div>
                    <ion-item class="no-lines main-status-icons">
                        <icons [MCU]="MCU" [ZON]="zones" [config]="config" [FNC]="fncs" [MCU_Par_On]="MCU.PAR_On"
                               [rms_extended]="rms_extended" class="align-right"
                               [status]="rms?.RTU_Flags || [0]"></icons>
                        <icons [MCU]="MCU" [showPowerOff]="showPowerOff(true)" class="align-right"></icons>
                        <!-- added: power-off icon if present -->
                    </ion-item>
                </div>
            </div>

            <div *ngIf="!noRadiant">

                <setpoint [page]="this" [rms_extended]="rms_extended" [GRP]="GRP" [season]="season" [sch]="sch" [rms]="rms" [mcz]="mcz" [state]="state" [attrs]="attrs" [MCU]="MCU"></setpoint>

                <div *ngIf="showCO2()" [ngClass]="{'disabled-item':!state}"
                     class="co2-box paddingAirQuality d-flex flex-row justify-content-center align-items-center w-100">
                    <div class="d-flex flex-row align-items-center">

                        <span *ngIf="co2Level == 0" class="airQuality far fa-laugh-beam messana-green icon"></span>
                        <span *ngIf="co2Level == 1" class="airQuality far fa-smile messana-yellow icon"></span>
                        <span *ngIf="co2Level == 2" class="airQuality far fa-meh messana-orange icon"></span>
                        <span *ngIf="co2Level == 3" class="airQuality far fa-frown messana-red icon"></span>
                    </div>
                    <div>
                        <ion-label>
                            {{ rmsCo2 }}
                        </ion-label>
                    </div>
                </div>
            </div>

            <!-- qui prima c'era un ion-item senza le classi del padding e del flex -->
            <div *ngIf="showCO2() && noRadiant" [ngClass]="{'disabled-item':!state}"
                 class="co2-box-noRadiant paddingAirQuality  d-flex flex-row justify-content-center align-items-center w-100">
                <div class="d-flex flex-row justify-content-center align-items-center">
                    <div>

                        <span *ngIf="co2Level == 0" class="airQuality far fa-laugh-beam messana-green icon"></span>
                        <span *ngIf="co2Level == 1" class="airQuality far fa-smile messana-yellow icon"></span>
                        <span *ngIf="co2Level == 2" class="airQuality far fa-meh messana-orange icon"></span>
                        <span *ngIf="co2Level == 3" class="airQuality far fa-frown messana-red icon"></span>
                        <ion-label>
                            &nbsp;{{ rms.RTU_CO2 }} ppm
                        </ion-label>
                    </div>
                </div>
            </div>

            <div class="zone-displayWidth d-flex flex-row  align-items-center"
                 [ngClass]="{'disabled-item':!state, 'justify-content-between':!MCU?.TEC_Bioset || seasonExe == Season.Heating,'justify-content-center':MCU?.TEC_Bioset && seasonExe == Season.Cooling}">
                <div *ngIf="MCU?.TEC_Bioset && seasonExe == Season.Cooling" class="zone-operative-temperature">
                    <span class="fal fa-thermometer-half"></span> <span
                        class="fixed-size-number">&nbsp;{{ rms?.getTp(MCU) | temperatureNoUnit }}</span>

                </div>
                <div *ngIf="!MCU?.TEC_Bioset || seasonExe == Season.Heating" class="zone-operative-temperature">
                    <span class="fal fa-thermometer-half"></span> <span
                        class="fixed-size-number">&nbsp;{{ rms?.RTU_OpTemp | temperatureNoUnit }}</span>

                </div>
                <div *ngIf="config?.humidity == 0 && (!MCU?.TEC_Bioset || seasonExe == Season.Heating)" class="zone-humidity">
                    <span class="fal fa-tint"></span>&nbsp;<span
                        class="fixed-size-number" [innerHtml]="rms?.RTU_Hum | pct"></span>
                </div>

                <div *ngIf="config?.humidity == 1 && (!MCU?.TEC_Bioset|| seasonExe == Season.Heating)" class="zone-humidity">
                    <span class="fal fa-dewpoint"></span> <span
                        class="fixed-size-number">&nbsp;{{ rms?.RTU_DewPoint | temperatureNoUnit }}</span>
                </div>
            </div><!-- chiudo il flex -->

            <!-- <ion-item [ngClass]="{'disabled-item':!state}" class="zone-info item no-lines">
                 <ion-label class="operative"><span class="fal fa-thermometer-half"></span> <span
                         class="fixed-size-number">&nbsp;{{ rms?.RTU_OpTemp | temperature }}</span></ion-label>
                 <ion-label class="humidity" *ngIf="config?.humidity == 0"><span class="icon-humidity"></span> <span
                         class="fixed-size-number">&nbsp;{{ rms?.RTU_Hum | pct }}</span></ion-label>
                 <ion-label class="humidity" *ngIf="config?.humidity == 1"><span class="icon-humidity"></span> <span
                         class="fixed-size-number">&nbsp;{{ rms?.RTU_DewPoint | temperature }}</span></ion-label>
             </ion-item> -->

            <div [ngClass]="{'disabled-item':!state}" *ngIf="!noRadiant" class="positionRelative">

                <!-- adding: [disabled]="!state" -> unclickable -->
                <schedule [isAutoModeStyleDualSetpoint]="isAutoModeStyleDualSetpoint()" [class]="rms_extended.zonScheduleStyle()" *ngIf="MCU?.TEC_SPMode" table="RMS"
                          [showGreyscaleToggle]="!state" [usePopupIsOpenedCheck]="true"
                          [item]="rms" idxVar="PAR_IdxSCH_H" [MCU]="MCU" [SCH]="SCH" toggle="PAR_SchedOnH"
                          [season]="Season.Heating" (whileChange)="onChangeSchedule($event)">

                    <schedule-option [value]="1" schedulation>{{ 'SCHEDULE' | translate }}</schedule-option>

                </schedule>

                <schedule [isAutoModeStyleDualSetpoint]="isAutoModeStyleDualSetpoint()" [class]="rms_extended.zonScheduleStyle()" *ngIf="season == 0 && !MCU?.TEC_SPMode"
                          [showGreyscaleToggle]="!state" table="RMS" [usePopupIsOpenedCheck]="true"
                          [item]="rms" idxVar="PAR_IdxSCH_H" [SCH]="SCH" [MCU]="MCU" toggle="PAR_SchedOnH"
                          [season]="Season.Heating" (whileChange)="onChangeSchedule($event)">

                    <schedule-option [value]="1" schedulation>{{ 'SCHEDULE' | translate }}</schedule-option>

                </schedule>

                <schedule [isAutoModeStyleDualSetpoint]="isAutoModeStyleDualSetpoint()" [class]="rms_extended.zonScheduleStyle()" *ngIf="season == 1 && !MCU?.TEC_SPMode"
                          [showGreyscaleToggle]="!state" table="RMS" [usePopupIsOpenedCheck]="true"
                          [item]="rms" idxVar="PAR_IdxSCH_C" [SCH]="SCH" [MCU]="MCU" toggle="PAR_SchedOnC"
                          [season]="Season.Cooling" (whileChange)="onChangeSchedule($event)">

                    <schedule-option [value]="1" schedulation>{{ 'SCHEDULE' | translate }}</schedule-option>
                </schedule>

                <!-- adding: && state in the *ngIf" -> makes it invisible / unclickable if the RMS is OFF -->
                <schedule-graph [config]="config" [disabled]="!state" [currentDateTime]="currentDateTime"
                                *ngIf="currentDateTime.dateTimeAsMoment && sch && ((season == 0 && rms?.PAR_IdxSCH_H != -1 && rms?.PAR_SchedOnH ) || (season == 1 && rms?.PAR_IdxSCH_C != -1 && rms?.PAR_SchedOnC))"
                                [schedule]="sch"
                                [id]="1+'zone'"></schedule-graph>


                <!-- fine schedule preview del collapse -->
            </div>

            <ion-item [ngClass]="{'disabled-item':!state}" *ngIf="MCU.PAR_ES == 1 && !noRadiant"
                      class="no-lines toggle-inner-item">
                <ion-toggle color="secondary" [checked]="esState" (ionChange)="esStateUpdated($event)"
                            [ngClass]="{'greyscale': !state}"></ion-toggle>
                <ion-label class="toggle-title">
                    {{ 'ENERGY_SAVING' | translate }}
                </ion-label>
                <ion-label class="maxWidth-10Perc" style="text-align: right">
                    <icons [MCU]="MCU" [showPowerOff]="showPowerOff()" [config]="config"
                           [status]="rms?.RTU_Flags || [0]"
                           onlyEs="true"></icons>
                </ion-label>
            </ion-item>

            <ng-container *ngFor="let ars_extended of rms_extended.arsList; let first=first; trackBy: trackById;">

                <ion-item *ngIf="ars_extended && ars_extended.RTT_CanInteg && ars_extended.PAR_IntegEnab && !noRadiant" class="no-lines toggle-inner-item" [ngClass]="{'disabled-item':!state}">

                    <!-- adding: [disabled]="&& !state" -> unclickable -->
                    <ion-toggle [ngClass]="{'greyscale': !state}" color="secondary" [disabled]="!ars_extended.PAR_On || !autoDualModeIntegrationReallyPresent()"
                                [checked]="integrationState"
                                (ionChange)="integrationUpdated($event)"></ion-toggle>

                    <ion-label class="toggle-title">
                        {{ 'INTEGRATION' | translate }}
                    </ion-label>

                    <ion-label class="maxWidth-10Perc" style="text-align: right">
                        <icons [MCU]="MCU" [showPowerOff]="showPowerOff()" [config]="config"
                               [status]="rms?.RTU_Flags || [0]"
                               onlyInt="true"></icons>
                    </ion-label>
                </ion-item>

                <ng-container *ngIf="ars_extended && ars_extended.PAR_On && ars_extended.RTT_CanInteg && ars_extended.PAR_IntegEnab && !noRadiant">

                    <ion-item [disabled]="!autoDualModeIntegrationReallyPresent()" [ngClass]="{'disabled-item': !state || !ars_extended.PAR_On || !integrationState}" class="counter item no-radio-counter no-lines indent">

                        <div (click)="goToSystemArs(ars_extended)" class="d-flex flex-row justify-content-between align-items-center w-100">

                            <div class="d-flex flex-row justify-content-start align-items-center cursorPointer">

                                <div class="d-flex flex-row justify-content-start align-items-center on min-width-1-5-em">
                                    <i [ngClass]="ars_extended.scheduleIsInRange() ? 'messana-green' : 'messana-disabled'"
                                       class="icon opacity-1 fa fa-calendar"></i>
                                </div>

                                <div>
                                <span class="item-text-wrap">
                                    <!-- <span>{{this.rms_extended.ars.sch.CFG_Name}}</span> -->
                                    <span>{{ 'AIR_UNIT_SCHEDULE' | translate }}</span>
                                </span>
                                </div>

                                <div *ngIf="config.admin" class="d-flex flex-row ion-justify-content-end align-items-center on min-width-1-5-em">
                                    <i class="fal fa-chevron-right icon align-right-important"></i>
                                </div>
                            </div>
                        </div>
                    </ion-item>

                    <counter temperature
                             [inDisabled]="!state || !ars_extended.PAR_On || !integrationState" [on]="true"
                             [changableSetpoint]="autoDualModeIntegrationReallyPresent()" [customLightbulbIconClass]="'min-width-1-5-em'"
                             label="{{getIntegrationOnLabel()}}" table="RMS" [item]="rms"
                             var="PAR_DiffIntegOn" [min]="rms.PAR_DiffIntegOff + 1" [attrs]="attrs" [MCU]="MCU"
                             [whileChange]="onChange" itemClass="no-lines indent convIntCounter"></counter>

                    <counter temperature
                             [inDisabled]="!state || !ars_extended.PAR_On || !integrationState" [off]="true"
                             [changableSetpoint]="autoDualModeIntegrationReallyPresent()" [customLightbulbIconClass]="'min-width-1-5-em'"
                             label="{{getIntegrationOffLabel()}}" table="RMS" [item]="rms"
                             var="PAR_DiffIntegOff" [max]="rms.PAR_DiffIntegOn - 1" [attrs]="attrs" [MCU]="MCU"
                             [whileChange]="onChange" itemClass="indent convIntCounter"></counter>

                </ng-container>
            </ng-container>

            <ion-item [ngClass]="{'disabled-item':!state}" *ngIf="fnc && (integH || integC) && !(getPrimaryArs() && getPrimaryArs().RTT_CanInteg) && !(getSecondaryArs() && getSecondaryArs().RTT_CanInteg) && !noRadiant"
                      class="no-lines toggle-inner-item">

                <div class="d-flex flex-row justify-content-center align-items-center">
                    <div class="d-flex flex-row justify-content-center align-items-center">
                        <ion-toggle [disabled]="!autoDualModeIntegrationReallyPresent()" color="secondary" [ngClass]="{'greyscale': !state}" [checked]="integrationState"
                                    (ionChange)="integrationUpdated($event)"></ion-toggle>
                    </div>

                    <div class="d-flex flex-row justify-content-start align-items-center">
                        <ion-label class="toggle-title">
                            {{ 'INTEGRATION' | translate }}
                        </ion-label>
                    </div>
                </div>
                <div class="justify-content-end d-flex flex-row align-items-center w-100">
                    <ion-label class="maxWidth-10Perc" style="text-align: right"></ion-label>
                </div>
            </ion-item>

            <counter *ngIf="fnc && (integH || integC) && !(getPrimaryArs() && getPrimaryArs().RTT_CanInteg) && !(getSecondaryArs() && getSecondaryArs().RTT_CanInteg) && !noRadiant" temperature
                     [inDisabled]="!state || !integrationState" [on]="true" [changableSetpoint]="autoDualModeIntegrationReallyPresent()"
                     label="{{getIntegrationOnLabel()}}" table="RMS" [item]="rms"
                     var="PAR_DiffIntegOn"
                     [min]="selectMax((rms.PAR_DiffIntegOff + 1),attrs['RMS']['PAR_DiffIntegOn']['Min'])"
                     [attrs]="attrs" [MCU]="MCU"
                     [whileChange]="onChange" itemClass="no-lines indent convIntCounter"></counter>
            <counter *ngIf="fnc && (integH || integC) && !(getPrimaryArs() && getPrimaryArs().RTT_CanInteg) && !(getSecondaryArs() && getSecondaryArs().RTT_CanInteg) && !noRadiant" temperature
                     [inDisabled]="!state || !integrationState" [off]="true" [changableSetpoint]="autoDualModeIntegrationReallyPresent()"
                     label="{{getIntegrationOffLabel()}}" table="RMS" [item]="rms"
                     var="PAR_DiffIntegOff" [max]="rms.PAR_DiffIntegOn - 1" [attrs]="attrs" [MCU]="MCU"
                     [whileChange]="onChange" itemClass="no-lines indent convIntCounter"></counter>

            <ng-container *ngFor="let ars_extended of rms_extended.arsList; trackBy: trackById;">

                <ion-item [ngClass]="{'disabled-item':!state}"
                          *ngIf="ars_extended && ars_extended.PAR_On && ars_extended.RTT_CanFc && fcStateARS && !noRadiant" class="no-lines">
                    <ion-label>
                        {{ 'FREE_COOLING' | translate }}
                    </ion-label>
                    <ion-toggle color="secondary" [ngClass]="{'greyscale': !state}" [checked]="fcState"
                                (ionChange)="fcUpdated($event)"></ion-toggle>
                </ion-item>

            </ng-container>

        </ion-list>
    </div><!-- fine di rounded-container -->

    <ng-container *ngIf="connected && !loading && loaded">

        <!-- Air Quality collapse -->
        <div *ngFor="let ars_extended of rms_extended.arsList; let first=first; trackBy: trackById;">

            <ion-list class="zone-timer-section">

                <air-quality-collapse [ngClass]="{'rounded-container-not-last': dehumidificationPresent() || humidificationPresent() || floorWarmer || connectedTMR.length > 0 || (!noArs() || fncs?.length > 0 || hys)}"
                                         [zonePage]="this" [extensible]="true" [ars_extended]="ars_extended"></air-quality-collapse>
            </ion-list>
        </div>

        <!-- Dehumidification collapse (ATU) -->
        <div *ngFor="let ars_extended of rms_extended.arsList; let first=first; trackBy: trackById;">

            <ion-list class="zone-timer-section">

                <dehum-collapse [ngClass]="{'rounded-container-not-last': dehumidificationCollapseIsNotLast()}"
                                [zonePage]="this" [config]="config" [showPowerOff]="showPowerOff()" [extensible]="true"
                                [MCU]="MCU" [fnc]="fnc" [ars_extended]="ars_extended" [rms]="rms" [fncDehumification]="dehum && first" [radiant]="!noRadiant"></dehum-collapse>
            </ion-list>
        </div>

        <!-- Dehumidification collapse (FNC) -->
        <div *ngIf="rms_extended.arsList.length === 0">

            <ion-list class="zone-timer-section">

                <dehum-collapse [ngClass]="{'rounded-container-not-last': dehumidificationCollapseIsNotLast()}"
                                [zonePage]="this" [config]="config" [showPowerOff]="showPowerOff()" [extensible]="true"
                                [MCU]="MCU" [fnc]="fnc" [ars_extended]="null" [rms]="rms" [fncDehumification]="dehum" [radiant]="!noRadiant"></dehum-collapse>
            </ion-list>
        </div>

        <!-- Humidification collapse -->
        <div *ngFor="let ars_extended of rms_extended.arsList; let first=first; trackBy: trackById;">

            <ion-list class="zone-timer-section">

                <humidification-collapse [ngClass]="{'rounded-container-not-last': floorWarmer || connectedTMR.length > 0 || (!noArs() || fncs?.length > 0 || hys)}"
                                [zonePage]="this" [extensible]="true" [ars_extended]="ars_extended"></humidification-collapse>
            </ion-list>
        </div>

    </ng-container>

    <!-- fwa section -->
    <div *ngIf="connected && !loading">

        <ion-list class="zone-timer-section" *ngIf="loaded && floorWarmer">

            <floor-warmer [ngClass]="{'rounded-container-not-last': connectedTMR.length > 0 || (!noArs() || fncs?.length > 0 || hys)}"
                          [currentDateTime]="currentDateTime" [context]="this" [SCH]="SCH" [MCU]="MCU" [config]="config"
                          [attrs]="attrs" [fwaState]="floorWarmer.PAR_On" [fwaVisibility]="false"
                          (updateFwaVisibility)="fwaVisibility=$event" [fwa]="floorWarmer"
                          [extensible]="true"></floor-warmer>
        </ion-list>
    </div>

    <!-- timers section -->
    <div *ngIf="connected && !loading">
        <ion-list class="zone-timer-section"
                  *ngFor="let tmr of connectedTMR; let i = index; trackBy: trackByIndex;">

            <timer [ngClass]="{'rounded-container-not-last': i < TMR.length - 1 || (!noArs() || fncs?.length > 0 || hys)}" [currentDateTime]="currentDateTime" [SCH]="SCH" [MCU]="MCU" [config]="config"
                   [tmrState]="tmrState[i]" [tmrVisibility]="tmrVisibility[i]"
                   (updateTmrVisibility)="tmrVisibility[i]=$event" [i]="i" *ngIf="loaded" [tmr]="tmr"
                   [extensible]="true"></timer>
        </ion-list>
    </div>
    <!-- end of timers section-->

    <!-- tech section -->
    <div class="rounded-container" *ngIf="connected && (!noArs() || fncs?.length > 0 || hys)">
        <div class="tech-mode" *ngIf="!loading">
            <tech [showPowerOffIcon]="true" [config]="config" [HYS]="hys" [GRP]="GRP" [MXV]="MXV" [FNC]="fncs"
                  [ATU]="this.atuList" [allATU]="ATU" [MCU]="MCU"
                  [nodata]="true"></tech>
        </div>
    </div>


</ion-content>

export class ToggleProperties {

    public checked;
    public disabled;

    constructor(checked, disabled) {

        this.checked = checked;
        this.disabled = disabled;
    }
}

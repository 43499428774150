import {SCH} from "../models/tables";

export class Constants {

    public static get NO_VALUE(): number { return -3276.8; }

    public static RTU_SetPointIsValid(sch: SCH, customRtuSetpoint = null) { // customRtuSetpoint can be useful to test manually (for example) sch.RTU_SetPointH/C of a DualVariableSchedule

        if (!sch && !customRtuSetpoint) {

            return false;
        }

        if (customRtuSetpoint) {

            return customRtuSetpoint !== this.NO_VALUE;
        }

        return sch.RTU_SetPoint !== this.NO_VALUE;
    }

    public static temperatureIsUndefined(value) {

        return value === this.NO_VALUE;
    }
}
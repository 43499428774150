import { ActionReducer, Action } from '@ngrx/store';
import { BackupsActions, BackupActionTypes } from '../actions/backup.actions';
import { Backup } from '../models/backups';

const initialState: Backup[] = [];

/**
 * Backup
 * (state management)
 *
 * Reducers are the foundation of any Store or Redux-based application, describing
 * sections of state and their potential transformations based on dispatched
 * action types. It is the combination of your reducers that makes up a
 * representation of application state at any given time.
 *
 * A reducer is a pure function, accepting two arguments, the previous state
 * and an action with a type and optional data (payload) associated with the
 * event.
 */
export function backupsReducer(state: Backup[] = initialState, action: BackupsActions) : Backup[] {

    var newState: Backup[];

    switch(action.type) {

        case BackupActionTypes.LoadBackupSuccess:
       
            return [...action.data];

        default:
            return state;
    };

}

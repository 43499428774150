import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from "@angular/common";
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import {ComponentsModule} from '../../../components/components.module';

import {PipesModule} from '../../../pipes/pipes.module';


import {NewScheduleComponent} from './new-schedule.component';
import { TimetravelDirective } from './timetravel.directive';
import {IonicModule} from "@ionic/angular";


@NgModule({
    imports: [CommonModule,
        TranslateModule,
        FormsModule,
        ComponentsModule,

        PipesModule, IonicModule

    ],
	declarations:[NewScheduleComponent, TimetravelDirective],
	exports:[NewScheduleComponent],
	schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class NewScheduleModule{}
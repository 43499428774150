import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from "@angular/common";





import {PctPipe} from './pct.pipe';
import {TemperaturePipe} from './temperature.pipe';
import {NameFilterPipe} from './name-filter.pipe';
import {OnOffPipe} from './on-off.pipe';
import {SortPipe} from './sort.pipe';
import {YesNoPipe} from './yes-no.pipe';
import { FilterPipe } from './filter.pipe';
import { KeysPipe } from './keys.pipe';
import { PctNoUnitPipe } from './pct-no-unit.pipe';
import { TemperatureNoUnitPipe } from './temperature-no-unit.pipe';

@NgModule({
	imports: [CommonModule],
	declarations:[TemperaturePipe,PctPipe,NameFilterPipe,OnOffPipe,SortPipe,YesNoPipe, FilterPipe, KeysPipe, PctNoUnitPipe, TemperatureNoUnitPipe],
	exports:[TemperaturePipe,PctPipe,NameFilterPipe,OnOffPipe,SortPipe,YesNoPipe,FilterPipe,KeysPipe,PctNoUnitPipe,TemperatureNoUnitPipe],
	schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class PipesModule{}

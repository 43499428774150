<div class="weather">

    <!--<div *ngIf="!mcu?.PAR_City && !mcu?.PAR_Address" class="place">
        {{ mcu?.PAR_Place }}
    </div>-->

    <div *ngIf="mcu?.PAR_Address" class="place">
        <span *ngIf="mcu?.PAR_Address">{{mcu?.PAR_Address}}</span>
    </div>

    <div *ngIf="mcu?.PAR_City" class="place">
        <span *ngIf="mcu?.PAR_City"> {{mcu?.PAR_City}},</span>
        <!--<span *ngIf="mcu?.PAR_Country"> {{ mcu?.PAR_Country}},</span>-->
        <span *ngIf="mcu?.PAR_State">&nbsp;{{mcu?.PAR_State}}</span>
        <span *ngIf="mcu?.PAR_ZipCode">&nbsp;{{mcu?.PAR_ZipCode}}</span>
    </div>

    <!-- main loading: there is no weather / previousWeather yet (first load) -->
    <div *ngIf="isLoading() && !this.weatherService.timeoutRequest && this.dataLoaded"
         class="d-flex flex-row justify-content-center spinner-height positionRelative">
        <div class="mac-spinner below-center">
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
        </div>
    </div>

    <!-- Errors (if timeoutRequest) -->
    <ng-container *ngIf="this.weatherService.timeoutRequest">

        <div class="div-table main">
            <div class="div-table-col">
                <i class="main-icon wi {{ errorWeather }}"></i>
            </div>
        </div>

        <div class="div-table main"
             *ngIf="this.weatherService.keyIsPresent && this.weatherService.anavailable">
            <ion-item no-lines>
                <ion-label style="text-align: center"> {{ 'ACCUWEATHER_UNAVAILABLE' | translate }}
                </ion-label>
            </ion-item>
        </div>

        <div class="div-table main"
             *ngIf="isEmpty(this.weatherService.weather) && dataLoaded && !this.weatherService.checkLocationKey">
            <ion-item no-lines>
                <ion-label *ngIf="this.weatherService.noValidLocationKey && !this.weatherService.keyIsPresent"
                           style="text-align: center"> {{ 'ACCUWEATHER_NOT_FOUND' | translate }}
                    <p class="warning">{{ 'ACCUWEATHER_CHECK_SETTINGS' | translate }}</p>
                </ion-label>

                <ion-label
                        *ngIf="this.weatherService.noValidLocationKey && this.weatherService.keyIsPresent && !this.weatherService.anavailable"
                        style="text-align: center"> {{ 'ACCUWEATHER_INVALID' | translate }}
                    <p class="warning">{{ 'ACCUWEATHER_CHECK_SETTINGS' | translate }}</p>
                </ion-label>
            </ion-item>
        </div>
    </ng-container>

    <!-- Weather display (is not loading and no timeout_request). Spinner + opacity of old if I'm updating data (so this.weatherService.weather is null while updating)  -->
    <div *ngIf="!isLoading() && !this.weatherService.timeoutRequest" class="positionRelative">

        <div *ngIf="!this.weatherService.weather" class="spinner-over-disabled-component">
            <div class="mac-spinner center">
                <div class="spinner-blade"></div>
                <div class="spinner-blade"></div>
                <div class="spinner-blade"></div>
                <div class="spinner-blade"></div>
                <div class="spinner-blade"></div>
                <div class="spinner-blade"></div>
                <div class="spinner-blade"></div>
                <div class="spinner-blade"></div>
                <div class="spinner-blade"></div>
                <div class="spinner-blade"></div>
                <div class="spinner-blade"></div>
                <div class="spinner-blade"></div>
            </div>
        </div>

        <div class="div-table main" [ngClass]="{'opacity-02':!this.weatherService.weather}">

            <div  class="div-table-row">

                <div class="div-table-col">
                    <i class="main-icon wi {{   this.getWeatherObject().icon || '' }}"></i>
                </div>

                <div class="div-table-col">
                    <div class="temperature">
                        <span *ngIf="ODS[0] && !defaultTemp">{{ ODS[0]?.RTU_Temp | temperatureNoUnit }}</span>
                        <span *ngIf="ODS[0] && defaultTemp">{{ this.getWeatherObject().temperature | temperatureNoUnit }}</span>
                        <span *ngIf="!ODS[0]">{{ this.getWeatherObject().temperature | temperatureNoUnit}}</span>
                    </div>

                    <!--  Relative = 0, DewPoint = 1 -->
                    <div class="humidity">
                    <span class="fal"
                          [ngClass]="{'fa-tint':config?.humidity == 0,'fa-dewpoint':config?.humidity == 1}"></span>&nbsp;
                        <span *ngIf="ODS[0] && config?.humidity == 0 && !defaultHum"
                              [innerHtml]="ODS[0]?.RTU_Hum | pct"></span>
                        <span *ngIf="ODS[0] && config?.humidity == 1 && !defaultHum">{{ ODS[0]?.RTU_DewPoint  | temperatureNoUnit}} </span>
                        <span *ngIf="ODS[0] && config?.humidity == 0 && defaultHum"
                              [innerHtml]="this.getWeatherObject().humidity | pct"></span>
                        <span *ngIf="ODS[0] && config?.humidity == 1 && defaultHum">{{  this.getWeatherObject().dewpoint | temperatureNoUnit }} </span>
                        <!--<span *ngIf="ODS[0] && config?.humidity == 1 && defaultHum">{{  dewPoint2 | temperature }} </span>-->
                        <span *ngIf="!ODS[0] && config?.humidity == 0"
                              [innerHtml]="this.getWeatherObject().humidity | pct"></span>
                        <span *ngIf="!ODS[0] && config?.humidity == 1">{{  this.getWeatherObject().dewpoint | temperatureNoUnit }}</span>
                        <!--<span *ngIf="!ODS[0] && config?.humidity == 1">{{  dewPoint2 | ok }}</span>-->
                    </div>

                    <div class="humidity">
                    <span *ngIf="this.getWeatherObject().forecast && this.getWeatherObject().forecast[0].iaqCategoryValue == 1"
                          class="airQuality fas fa-laugh-beam messana-green verticalMiddle"></span>
                        <span *ngIf="this.getWeatherObject().forecast && this.getWeatherObject().forecast[0].iaqCategoryValue == 2"
                              class="airQuality fas fa-smile messana-yellow verticalMiddle"></span>
                        <span *ngIf="this.getWeatherObject().forecast && this.getWeatherObject().forecast[0].iaqCategoryValue == 3"
                              class="airQuality fas fa-meh messana-orange verticalMiddle"></span>
                        <span *ngIf="this.getWeatherObject().forecast && this.getWeatherObject().forecast[0].iaqCategoryValue >= 4"
                              class="airQuality fas fa-frown messana-red verticalMiddle"></span>

                        <span *ngIf="this.getWeatherObject().forecast && this.getWeatherObject().forecast[0]"
                              class="iaq-text">&nbsp;{{ this.getWeatherObject().forecast[0].iaqCategory }}</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- there is no point showing the "refresh" icon anymore if I've ghe old data in background -->
        <!-- <div class="div-table main" *ngIf="this.weatherService.noValidLocationKey">
            <div class="div-table-row">

                <div class="div-table-col">
                    <i class="main-icon wi {{ noWeatherIcon }}"></i>
                </div>

                <div *ngIf="ODS[0]" class="div-table-col">
                    <div class="temperature">
                        <span *ngIf="!defaultTemp">{{ ODS[0]?.RTU_Temp | temperatureNoUnit }}</span>
                        <span *ngIf="defaultTemp">{{ this.getWeatherObject().temperature | temperatureNoUnit }}</span>
                    </div>

                    <div class="humidity">
                    <span class="fal"
                          [ngClass]="{'fa-tint':config?.humidity == 0,'fa-dewpoint':config?.humidity == 1}"></span>&nbsp;
                        <span *ngIf="config?.humidity == 0 && !defaultHum" [innerHtml]="ODS[0]?.RTU_Hum | pct"></span>
                        <span *ngIf="config?.humidity == 1 && !defaultHum">{{ dewPoint  | temperatureNoUnit}} </span>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- (  checkLocationKey) && !this.weatherService.timeoutRequest -->
        <div [ngClass]="{'opacity-02':!this.weatherService.weather}" class="div-table forecast">
            <div class="div-table-row">
                <div class="div-table-col icon">
                    <i class="main-icon wi {{this.getWeatherObject().forecast && this.getWeatherObject().forecast[1] && this.getWeatherObject().forecast[1]?.icon || '' }}"></i>
                </div>
                <div class="div-table-col icon">
                    <i class="main-icon wi {{ this.getWeatherObject().forecast && this.getWeatherObject().forecast[2] && this.getWeatherObject().forecast[2]?.icon || '' }}"></i>
                </div>
                <div class="div-table-col icon">
                    <i class="main-icon wi {{  this.getWeatherObject().forecast && this.getWeatherObject().forecast[3] && this.getWeatherObject().forecast[3]?.icon || '' }}"></i>
                </div>
            </div>

            <div class="div-table-row">
                <div class="div-table-col forcastrow">
                <span *ngIf="this.getWeatherObject().forecast && this.getWeatherObject().forecast[1].iaqCategoryValue == 1"
                      class="airQuality fas fa-laugh-beam messana-green weatherQualityIcon"></span>
                    <span *ngIf="  this.getWeatherObject().forecast && this.getWeatherObject().forecast[1].iaqCategoryValue == 2"
                          class="airQuality fas fa-smile messana-yellow weatherQualityIcon"></span>
                    <span *ngIf="  this.getWeatherObject().forecast && this.getWeatherObject().forecast[1].iaqCategoryValue == 3"
                          class="airQuality fas fa-meh messana-orange weatherQualityIcon"></span>
                    <span *ngIf="  this.getWeatherObject().forecast && this.getWeatherObject().forecast[1].iaqCategoryValue >= 4"
                          class="airQuality fas fa-frown messana-red weatherQualityIcon"></span>
                </div>
                <div class="div-table-col forcastrow">
                <span *ngIf="  this.getWeatherObject().forecast && this.getWeatherObject().forecast[2].iaqCategoryValue == 1"
                      class="airQuality fas fa-laugh-beam messana-green weatherQualityIcon"></span>
                    <span *ngIf="  this.getWeatherObject().forecast && this.getWeatherObject().forecast[2].iaqCategoryValue == 2"
                          class="airQuality fas fa-smile messana-yellow weatherQualityIcon"></span>
                    <span *ngIf="  this.getWeatherObject().forecast && this.getWeatherObject().forecast[2].iaqCategoryValue == 3"
                          class="airQuality fas fa-meh messana-orange weatherQualityIcon"></span>
                    <span *ngIf="  this.getWeatherObject().forecast && this.getWeatherObject().forecast[2].iaqCategoryValue >= 4"
                          class="airQuality fas fa-frown messana-red weatherQualityIcon"></span>
                </div>

                <div class="div-table-col forcastrow">

                <span *ngIf="  this.getWeatherObject().forecast && this.getWeatherObject().forecast[3].iaqCategoryValue == 1"
                      class="airQuality fas fa-laugh-beam messana-green weatherQualityIcon"></span>
                    <span *ngIf="  this.getWeatherObject().forecast && this.getWeatherObject().forecast[3].iaqCategoryValue == 2"
                          class="airQuality fas fa-smile messana-yellow weatherQualityIcon"></span>
                    <span *ngIf="  this.getWeatherObject().forecast && this.getWeatherObject().forecast[3].iaqCategoryValue == 3"
                          class="airQuality fas fa-meh messana-orange weatherQualityIcon"></span>
                    <span *ngIf="  this.getWeatherObject().forecast && this.getWeatherObject().forecast[3].iaqCategoryValue >= 4"
                          class="airQuality fas fa-frown messana-red weatherQualityIcon"></span>
                </div>
            </div>


            <div class="div-table-row">
                <div class="div-table-col forcastrow">
                <span class="max">{{   this.getWeatherObject().forecast && this.getWeatherObject().forecast[1] && this.getWeatherObject().forecast[1]?.high || '&nbsp;' }}
                    <!--<span *ngIf="this.getWeatherObject().forecast && this.getWeatherObject().forecast[1]">&deg;</span>--></span>
                    <span class="min">{{   this.getWeatherObject().forecast && this.getWeatherObject().forecast[1] && this.getWeatherObject().forecast[1]?.low || '&nbsp;' }}
                        <!--<span *ngIf="this.getWeatherObject().forecast && this.getWeatherObject().forecast[1]">&deg;</span>--></span>


                    <!--<span *ngIf="  this.getWeatherObject().forecast" class="icon-1000F messana-white weatherQualityIcon"></span>-->
                </div>
                <div class="div-table-col forcastrow">
                <span class="max">{{   this.getWeatherObject().forecast && this.getWeatherObject().forecast[2] && this.getWeatherObject().forecast[2]?.high || '&nbsp;' }}
                    <!--<span *ngIf="this.getWeatherObject().forecast && this.getWeatherObject().forecast[2]">&deg;</span>--></span>
                    <span class="min">{{   this.getWeatherObject().forecast && this.getWeatherObject().forecast[2] && this.getWeatherObject().forecast[2]?.low || '&nbsp;' }}
                        <!--<span *ngIf="this.getWeatherObject().forecast && this.getWeatherObject().forecast[2]">&deg;</span>--></span>

                    <!--<span *ngIf="  this.getWeatherObject().forecast" class="icon-1600F messana-white weatherQualityIcon"></span>-->
                </div>
                <div class="div-table-col forcastrow">
                <span class="max">{{   this.getWeatherObject().forecast && this.getWeatherObject().forecast[3] && this.getWeatherObject().forecast[3]?.high || '&nbsp;' }}
                    <!--<span *ngIf="this.getWeatherObject().forecast && this.getWeatherObject().forecast[3]">&deg;</span>--></span>
                    <span class="min">{{   this.getWeatherObject().forecast && this.getWeatherObject().forecast[3] && this.getWeatherObject().forecast[3]?.low || '&nbsp;' }}
                        <!--<span *ngIf="this.getWeatherObject().forecast && this.getWeatherObject().forecast[3]">&deg;</span>--></span>


                    <!--<span *ngIf="  this.getWeatherObject().forecast" class="icon-more-1600F messana-white weatherQualityIcon"></span>-->
                </div>
            </div>
            <div class="div-table-row">
                <div class="div-table-col day">{{   this.getWeatherObject().forecast && this.getWeatherObject().forecast[1] && this.getWeatherObject().forecast[1]?.day || '&nbsp;' }}</div>
                <div class="div-table-col day">{{   this.getWeatherObject().forecast && this.getWeatherObject().forecast[2] && this.getWeatherObject().forecast[2]?.day || '&nbsp;' }}</div>
                <div class="div-table-col day">{{   this.getWeatherObject().forecast && this.getWeatherObject().forecast[3] && this.getWeatherObject().forecast[3]?.day || '&nbsp;' }}</div>
            </div>
        </div>
    </div>
</div>
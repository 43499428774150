

      <div [class]="itemClass" class="d-flex flex-column justify-content-center align-items-center collapse item"  [ngClass]="{'disabled-item':disabled}">

           
           
            <button *ngIf="alarm" type="submit" [class]="buttonClass"   >{{ label }}</button>
            <button *ngIf="_checked && !alarm" type="submit" [class]="buttonClass"   >{{ (hideLabel==undefined ? 'HIDE'  : hideLabel )| translate }}</button>
            <button *ngIf="!_checked && !alarm" type="submit" [class]="buttonClass"   >{{ (showLabel==undefined ? 'SHOW'  :showLabel )| translate }}</button>
             <div class="collapse">
                <div class="checkbox-icon" [class.checkbox-checked]="_checked">
                <div class="checkbox-inner"></div>
              </div>

            
        </div>
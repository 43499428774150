
export class Forecast {

    constructor(
        public code: string, // 30
        public data: string, // 12 Sep 2016
        public day: string,  // Mon
        public high: string, // 87
        public low: string,  // 69
        public unit:string, // C || F
        public text: string, // Partialy Cloudy
        public icon: string,  // icon class
        public iaqCategory: string, // iAQ category
        public iaqValue: number,  // iAQ value
        public iaqCategoryValue: number  // iAQ CategoryValue
    ) {}
}

export class Weather {

    constructor(
        public humidity: number, // in range 0..1
        public temperature: number, // in F or C
        public dewpoint: number, // in F or C
        public text: string, // description
        public icon: string, // icon class
        public forecast: Forecast[]
    ) {}

}

    <!--<ion-backdrop></ion-backdrop>-->
    <div class="alert-wrapper noSchModal">
      <div class="alert-head">
        <h2  class="alert-title" *ngIf="title" [innerHTML]="title"></h2>
        
      </div>
    
      <ion-list class="selectList" >
      	<form>
      	 <ion-item class="no-lines">
                                <ion-label class="label-select"> {{ 'SHOW_POINTS' | translate}}</ion-label>
                                <ion-toggle color="secondary" ngDefaultControl  [checked]="showpoint" (ionChange)="showpointChange($event)" [ngModelOptions]="{standalone: true}"  ></ion-toggle>
                                   
                                   
                    </ion-item>
                    
      	<ion-item class="no-lines">

      	     <ion-label class="label-select"> {{ 'INTERPOLATION' | translate}}</ion-label>
                                <ion-select [value]="interpolation" [compareWith]="compareWith" ngDefaultControl (ionChange)="interpolationChange($event)"  [ngModelOptions]="{standalone: true}"  >
                                   
                                    <ion-select-option *ngFor="let op of interpolationOptions" [value]="op">
                                        <span>{{ op.text | translate }}</span> </ion-select-option>
                                    <!--<ion-select-option [value]="'monotone'">
                                        <span>{{ 'MONOTONE' | translate }}</span> </ion-select-option>
                                     <ion-select-option [value]="'monotone'">
                                        <span>{{ 'MONOTONE' | translate }}</span> </ion-select-option>-->



                                       
                                </ion-select>
           </ion-item>
      </form>
      </ion-list>


         

         




   <div class="alert-button-group" >
    
        <button category="alert-button" *ngFor="let b of buttons" (click)="btnClick(b)" class="alert-button" [innerHTML]="b.text">
         
        </button>
     
    </div>   

  
    </div>
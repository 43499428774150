import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {MCU, SCH, TMR} from 'src/app/models/tables';
import {Store} from '@ngrx/store';
import {AppState} from 'src/app/services/app-state.service';
import {ChangeTableValues} from 'src/app/actions/tables.actions';
import {Config} from 'src/app/models/config';
import {TMR_Extended} from "../../models/tables-extended";
import {CurrentDateTime} from "../../models/current-date-time";

@Component({
    selector: 'timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit {

    @Input() tmr: TMR;
    @Input() extensible: boolean;
    @Input() tmrVisibility: boolean;
    @Input() i: number;
    @Input() SCH: SCH[];
    @Input() tmrState: boolean;
    @Input() config: Config;
    @Input() MCU: MCU;

    @Output() updateTmrVisibility: EventEmitter<boolean> = new EventEmitter();

    @Input()
    currentDateTime: CurrentDateTime;

    //rayTMR:number[];

    overrideArray: number[];

    schModeTMR: boolean;
    todayIdxTMR: number = -1;

    constructor(protected store: Store<AppState>) {
        this.overrideArray = [30, 60, 90, 120];
        this.schModeTMR = false;
        /*if(!this.rayTMR || this.rayTMR.length == 0){
          this.rayTMR = new Array(2).fill(0);
      }*/

    }

    ngOnInit() {
    }

    get tmr_extended(): TMR_Extended {

        return TMR_Extended.createExtendedTMR(this.tmr, this.SCH);
    }

    /*updateTimerState(event,i){
      console.log(`updateTimerState(${event},${i})`);
       if(event!=1)
         this.schModeTMR=false;
     }*/


    updateTmrState($event) {

        // console.log('updateTmrState event vale')
        // console.log($event);

        let state = $event.detail.checked;

        if (state) {

            this.tmr.PAR_Mode = 2;
        } else {

            this.tmr.PAR_Mode = 0;
        }

        this.store.dispatch(new ChangeTableValues('TMR', this.tmr.id, {PAR_Mode: this.tmr.PAR_Mode}));
    }

    show() {

        //this.updateTimerIconState();

        this.tmrVisibility = !this.tmrVisibility;
        this.updateTmrVisibility.emit(this.tmrVisibility);
    }

    /* to dev */
    tmrScheduleStyle(tmr: TMR): string {

        if (this.SCH && tmr.PAR_IdxSCH != -1) {

            if (this.SCH[tmr.PAR_IdxSCH] && this.SCH[tmr.PAR_IdxSCH].RTU_On) {

                return 'thermal-in-range';
            }

            return 'thermal-disabled';

        } else {

            return 'thermal-disabled';
        }
    }

    disabledOverride(tmr: TMR): boolean {

        if (tmr.PAR_Mode == 2) {

            return true;
        }

        if (tmr && this.SCH && tmr.PAR_IdxSCH != -1 && this.SCH[tmr.PAR_IdxSCH] && tmr.PAR_Mode == 1) {

            return this.SCH[tmr.PAR_IdxSCH].RTU_On;
        }

        return false;
    }


    canResetOverride(tmr: TMR): boolean {

        if (tmr && this.SCH) {

            return (tmr.PAR_ForcePeriod != 0) && !this.disabledOverride(tmr);
        }

        return false;
    }


    isOverrideActive(tmr: TMR): boolean {

        if (tmr) {

            return tmr.PAR_ForcePeriod != 0;
        }

        return false;
    }


    forcePeriod(tmr: TMR, time: number) {

        if (!this.disabledOverride(tmr)) {

            const now = Math.floor(Date.now() / 1000);

            if (tmr.PAR_ForcePeriod == time) {

                tmr.PAR_ForcePeriod = 0;

            } else {

                tmr.PAR_ForcePeriod = time;
            }

            tmr.PAR_ForceStart = now;

            this.store.dispatch(new ChangeTableValues('TMR', tmr.id, {
                PAR_ForcePeriod: tmr.PAR_ForcePeriod,
                PAR_ForceStart: tmr.PAR_ForceStart
            }));
        }
    }

    resetOverride(tmr: TMR) {

        tmr.PAR_ForcePeriod = 0;
        this.store.dispatch(new ChangeTableValues('TMR', tmr.id, {PAR_ForcePeriod: tmr.PAR_ForcePeriod}));
    }
}

import { PopoverController} from '@ionic/angular';
import { Component, Input} from '@angular/core';

import {MCU, ARS, DHW, FNC, GRP, MCZ, RMS, TMR} from "../../models/tables";
import { RangeColorCell, UsedSchedulation } from '../schedule/commons';
import * as _ from 'lodash';

@Component({
  selector: 'popover-page-sch',
  templateUrl: './popover-page-sch.page.html'

})
export class PopoverPageSCH {

    static get parameters() {
    	//..dubbi.. marx86 26/06/2019, sostituisco navParams con NavigationExtras?
      //  return [[PopoverController],[NavParams]];
      return [[PopoverController]];
    }

    @Input() used:any;
    @Input() commonSet:any;

    usedSch: UsedSchedulation[] = [];
    usedSchARS: UsedSchedulation[] = [];
    usedSchDHW: UsedSchedulation[] = [];
    usedSchGRP: UsedSchedulation[] = [];
    usedSchH: UsedSchedulation[] = [];
    usedSchC: UsedSchedulation[] = [];
    usedSchTMR: UsedSchedulation[] = [];
    usedSchFNC: UsedSchedulation[] = [];
    
   

constructor(
    
    
    public popoverController: PopoverController) {
        
       
       
      
       
    }

    ngOnInit(): void {
        // setTimeout(() => this.close(), 7000);
        //console.log(`used vale ${this.used}`);
         let data = this.used as UsedSchedulation[];
       
        //console.log(data)

        this.usedSchH = _.uniqWith(data.filter(x => (x.table == "RMS" || x.table == "MCZ") && x.mode == 0), _.isEqual);
        this.usedSchC = _.uniqWith(data.filter(x => (x.table == "RMS" || x.table == "MCZ") && x.mode == 1), _.isEqual);
        this.usedSchARS = _.uniqWith(data.filter(x => x.table == "ARS"), _.isEqual);
        this.usedSchFNC = _.uniqWith(data.filter(x => x.table == "FNC"), _.isEqual);
        this.usedSchGRP = _.uniqWith(data.filter(x => x.table == "GRP"), _.isEqual);
        this.usedSchTMR = _.uniqWith(data.filter(x => x.table == "TMR"), _.isEqual);
        this.usedSchDHW = _.uniqWith(data.filter(x => x.table == "DHW"), _.isEqual);

      
    }

    close() {
        this.popoverController.dismiss();
    }
}
<ion-list *ngIf="calibrations!=null" ><!-- current calibration-->

  <div  *ngIf="ordered_calibrations.length>0" class="rounded-container opened" > <!-- rounded container -->
      <ion-list-header *ngIf="loaded "    class=" bg-header text-black">
              <!-- stava in ion-list-header [ngClass]="{'system-border-top':mustHaveBorder(i)}" -->
          <div class="d-flex flex-row justify-content-between align-items-center w-100">
          <div class="d-flex flex-row justify-content-start align-items-center">
              <div class="d-flex flex-row align-items-center">
                  <span  class="icon fal fa-clipboard" item-left></span>


              </div>
              <div class="d-flex flex-row align-items-center">
                  <ion-label class="text-black ars-title">
                      <div class="title-atu">
                      {{'CURRENT_CALIBRATION' | translate }}
                      </div>

              </ion-label>
              </div>
          </div>
          <div class="d-flex flex-row justify-content-end align-items-center">

          <!-- <ion-icon *ngIf="ars.CFG_Name" name="more" item-right
                  (click)="openMenu($event,null, ars,i)" click-stop-propagation></ion-icon> -->

          </div>
          </div>
      </ion-list-header>


      <!-- current CALIBRATION -->
      <div class="padding-outer-phase d-flex flex-row justify-content-center" *ngIf="loaded">
            <div>
                <calibration-table [calibrations]="[ordered_calibrations[0]]"></calibration-table>
            </div>
      </div><!-- closing padding outer phase-->

    </div><!-- rounded-container-->
</ion-list>







      <ion-list *ngIf="ordered_calibrations!=null" ><!-- previous calibrations-->

        <div  *ngIf="ordered_calibrations.length>0 && previous_calibrations?.length>0" class="rounded-container" [ngClass]="{'opened':showPrev}"> <!-- rounded container -->
            <ion-list-header *ngIf="loaded " (click)="show()"    class=" bg-header text-black cursorPointer">
                    <!-- stava in ion-list-header [ngClass]="{'system-border-top':mustHaveBorder(i)}" -->
                <div class="d-flex flex-row justify-content-between align-items-center w-100">
                <div class="d-flex flex-row justify-content-start align-items-center">
                    <div class="d-flex flex-row align-items-center">
                        <span  class="icon fal fa-clipboard" item-left></span>


                    </div>
                    <div class="d-flex flex-row align-items-center">
                        <ion-label class="text-black ars-title">
                            <div class="title-atu">
                            {{'PREVIOUS_CALIBRATIONS' | translate }}
                            </div>

                    </ion-label>
                    </div>
                </div>
                <div class="d-flex flex-row justify-content-end align-items-center">

                <!-- <ion-icon *ngIf="ars.CFG_Name" name="more" item-right
                        (click)="openMenu($event,null, ars,i)" click-stop-propagation></ion-icon> -->

                </div>
                </div>
            </ion-list-header>


            <!-- current CALIBRATION -->
            <div class="padding-outer-phase d-flex flex-row justify-content-center" *ngIf="loaded && showPrev">

                <div>


                    <calibration-table [calibrations]="previous_calibrations"></calibration-table>
                </div>




            </div><!-- closing padding outer phase-->

          </div><!-- rounded-container-->
      </ion-list><!-- closing previous calibrations-->





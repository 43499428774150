import {Injectable} from '@angular/core';
import {Action, Store} from '@ngrx/store';
import {HttpStatusService} from '../services/http-status.service';
import {
    CommutationError,
    CommutationSuccess,
    CommutationSyncToRecheck,
    OpenHttp,
    HttpStatusActionTypes,
    BaseUrlChanged,
    BaseUrlChangedError,
    SocketResetSuccess,
    SocketResetError,
    BaseUrlChangedWhenSync,
    SocketResetSuccessSync,
    ReloadingNetworkEnd,
    ResetCompleted,
    CommutationErrorRetry, BluetoothCommuted, CurrentDateTimeUpdated
} from '../actions/http-status.actions';
import {EMPTY, Observable, of, throwError, timer} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {map, catchError, tap, takeUntil, mergeMap, switchMap, delay} from 'rxjs/operators';
import {TablesActions, LoadTable, ChangeTableValues} from '../actions/tables.actions';
import {NetworkWay, NetworkStatus} from '../models/http-status';
import {AppState} from '../services/app-state.service';
import {Router} from "@angular/router";
import {BluetoothService} from "../services/bluetooth.service";
import {SupportService} from "../services/support.service";

@Injectable()
export class HttpStatusEffects {


    constructor(private actions$: Actions,
                protected store: Store<AppState>,
                private httpStatusService: HttpStatusService,
                private router: Router,
                private supportService: SupportService,
                private bluetoothService: BluetoothService) {

    }


    @Effect()
    reloadingNetworkUpdate$: Observable<any> = this.actions$.pipe(
        ofType(HttpStatusActionTypes.ReloadingNetworkUpdate),

        mergeMap(action => {

                return this.httpStatusService.resyncConnection(false);
            }
        )
    );


    @Effect()
    commutationError$: Observable<any> = this.actions$.pipe(
        ofType(HttpStatusActionTypes.CommutationError),
        tap(action => {
            //console.log('CommutationError EFFECT');
            //console.log(action)
        }),
        mergeMap(action => {

                return this.httpStatusService.changeBaseUrl(action['modality']).pipe(
                    //  tap(baseUrl =>{ //console.warn('base url cambiata'); //console.log(baseUrl); }),

                    // If successful, dispatch success action with result
                    map(() => new BaseUrlChanged()),
                    // If request fails, dispatch failed action
                    catchError((err) => of(new BaseUrlChangedError()))
                )
            }
        )
    );


    @Effect()
    commutationSync$: Observable<any> = this.actions$.pipe(
        ofType(HttpStatusActionTypes.CommutationSync),
        tap(action => {
            //console.log('CommutationSync EFFECT');
            //console.log(action)
        }),
        mergeMap(action => {

                return this.httpStatusService.changeBaseUrl(action['modality']).pipe(
                    //  tap(baseUrl =>{ //console.warn('base url cambiata'); //console.log(baseUrl); }),

                    // If successful, dispatch success action with result
                    map(() => new BaseUrlChangedWhenSync()),
                    // If request fails, dispatch failed action
                    catchError((err) => of(new BaseUrlChangedError()))
                )
            }
        )
    );

    @Effect()
    commutationSyncToRecheck$: Observable<any> = this.actions$.pipe(
        ofType(HttpStatusActionTypes.CommutationSyncToRecheck),
        tap(action => {
            //console.log('CommutationSync EFFECT');
            //console.log(action)
        }),
        mergeMap(action => {

                return this.httpStatusService.changeBaseUrl(action['modality']).pipe(
                    //  tap(baseUrl =>{ //console.warn('base url cambiata'); //console.log(baseUrl); }),

                    // If successful, dispatch success action with result
                    map(() => new BaseUrlChangedWhenSync()),
                    // If request fails, dispatch failed action
                    catchError((err) => of(new BaseUrlChangedError()))
                )
            }
        ),
        mergeMap(action => {
                this.store.dispatch(action);

                return this.httpStatusService.probeNetworkStatus().pipe(delay(7000),
                    tap(res => { //console.warn('probing effettuato per re-sync da CommutationErrorRetry'); //console.log(res)
                    }),

                    // If successful, dispatch success action with result
                    map(data => new CommutationSuccess(data as NetworkWay)),
                    // If request fails, dispatch failed action
                    catchError((err) => of(new CommutationErrorRetry(NetworkWay.Remote))));
            }
        )
    );


    @Effect()
    commutationSuccess$: Observable<any> = this.actions$.pipe(
        ofType(HttpStatusActionTypes.CommutationSuccess),

        tap(async (action) => {

            const commutationSuccess = action as CommutationSuccess;
            // await this.httpStatusService.backendCheckSocketIoPluginV4(commutationSuccess.modality);
        }),

        mergeMap(action => {

                return this.httpStatusService.changeBaseUrl(action['modality']).pipe(

                    // If successful, dispatch success action with result
                    map(() => {

                        if (action['modality'] === NetworkWay.BLUETOOTH) {

                            return new BluetoothCommuted();
                        }

                        return new BaseUrlChanged();
                    }),
                    // If request fails, dispatch failed action
                    catchError((err) => of(new BaseUrlChangedError()))
                )
            }
        )
    );

    @Effect()
    bluetoothCommuted$: Observable<any> = this.actions$.pipe(
        ofType(HttpStatusActionTypes.BluetoothCommuted),

        mergeMap(async (value) => {

            if (this.bluetoothService.isBluetoothEmergency()) {

                this.bluetoothService.startInternetConnectionListener(this.httpStatusService);
            }

            this.bluetoothService.startBluetoothOnOffIosListener(this.httpStatusService);

            const home_url = '/home';

            if (this.router.url === home_url) {

                await this.router.navigate(['/main-menu']);
                await this.router.navigate([home_url]);

            } else {

                await this.router.navigate([home_url]);
            }

            await this.supportService.updateCurrentDateTime();
            return new CurrentDateTimeUpdated();
        }),
    );


    @Effect()
    baseUrlChanged$: Observable<any> = this.actions$.pipe(
        ofType(HttpStatusActionTypes.BaseUrlChanged),
        tap(action => {
            //console.log('BaseUrlChanged () EFFECT ');
            //console.log(action)
        }),
        mergeMap(action => {

                return this.httpStatusService.socketReset().pipe(
                    //  tap(baseUrl =>{ //console.warn('base url cambiata'); //console.log(baseUrl); }),

                    // If successful, dispatch success action with result
                    map(() => new SocketResetSuccess()),
                    // If request fails, dispatch failed action
                    catchError((err) => of(new SocketResetError()))
                )
            }
        )
    );


    @Effect()
    baseUrlChangedWhenSync$: Observable<any> = this.actions$.pipe(
        ofType(HttpStatusActionTypes.BaseUrlChangedWhenSync),
        tap(action => {
            //console.log('BaseUrlChangedWhenSync () EFFECT  torno a lan');
            //console.log(action)
        }),
        mergeMap(action => {

                return this.httpStatusService.socketReset().pipe(
                    //  tap(baseUrl =>{ //console.warn('base url cambiata'); //console.log(baseUrl); }),

                    // If successful, dispatch success action with result
                    map(() => new SocketResetSuccessSync()),
                    // If request fails, dispatch failed action
                    catchError((err) => of(new SocketResetError()))
                )
            }
        )
    );

    @Effect()
    reloadingNetworkEnd$: Observable<any> = this.actions$.pipe(
        ofType(HttpStatusActionTypes.ReloadingNetworkEnd),
        tap(action => {
            //console.log('ReloadingNetworkEnd () EFFECT');
            //console.log(action)
        }),
        mergeMap(action => {

                return this.httpStatusService.resetNetUpdated().pipe(
                    map(() => {
                        return new ResetCompleted()
                    }));
            }
        )
    );

    @Effect()
    resetCompleted$: Observable<any> = this.actions$.pipe(
        ofType(HttpStatusActionTypes.ResetCompleted),

        mergeMap(async action => {

                await this.supportService.updateCurrentDateTime();
                return new CurrentDateTimeUpdated();
            }
        )
    );


    @Effect()
    commutationErrorRetry$: Observable<any> = this.actions$.pipe(
        ofType(HttpStatusActionTypes.CommutationErrorRetry),
        tap(action => {
            //console.log('CommutationErrorRetry EFFECT');
            //console.log(action)
        }),
        mergeMap(action => {

                return this.httpStatusService.probeNetworkStatus().pipe(
                    tap(res => { //console.warn('probing effettuato per re-sync da CommutationErrorRetry'); //console.log(res)
                    }),

                    // If successful, dispatch success action with result
                    map(data => new CommutationSuccess(data as NetworkWay)),
                    // If request fails, dispatch failed action
                    catchError((err) => of(new CommutationErrorRetry(NetworkWay.Remote))));
            }
        )
    );


    @Effect()
    socketResetSuccessSync$: Observable<any> = this.actions$.pipe(
        ofType(HttpStatusActionTypes.SocketResetSuccessSync),
        tap(action => {
            //console.log('SocketResetSuccessSync () EFFECT ');
            //console.log(action)
        }),
        mergeMap(action => {

            this.store.dispatch(new ReloadingNetworkEnd());
            this.store.dispatch(new ChangeTableValues('MCU', 0, {PAR_Network_Status: NetworkStatus.Active}));
            return timer(15000).pipe(tap(ended => {
                //console.log('__________________________');
                //console.log('__________________________');
                //console.log('__________________________');
                //console.log('sono passati 15 secondi :) ');  
                //console.log('__________________________');    
                //console.log('__________________________');
                //console.log('__________________________'); 
                // this.store.dispatch(new ReloadingNetworkEnd());
                // this.store.dispatch(new ChangeTableValues('MCU', 0, {PAR_Network_Status:NetworkStatus.Active}));
                //console.log(`scrivo ${NetworkStatus.Active} sul db` );
            }), switchMap(e => {

                return this.httpStatusService.probeNetworkStatus().pipe(
                    tap(res => { //console.warn('probing effettuato per re-sync'); //console.log(res)
                    }),

                    // If successful, dispatch success action with result
                    map(data => new CommutationSuccess(data as NetworkWay)),
                    // If request fails, dispatch failed action
                    catchError((err) => of(new CommutationErrorRetry(NetworkWay.Remote))));

            }));
        }));


}

<div class="w-100">

  <ion-item class="w-100">
    <ion-label
            class="subtitle ion-hide-sm-down">{{ techNoDataBlock.label | translate }}</ion-label>
    <ion-label>
      <span class="{{techNoDataBlock.icon}}" title="{{ techNoDataBlock.title | translate }}"></span>

      <ng-container *ngIf="techNoDataBlock.pipe === techNoDataBlock.TechNoDataBlockPipes.nothing">{{techNoDataBlock.value}}</ng-container>
      <ng-container *ngIf="techNoDataBlock.pipe === techNoDataBlock.TechNoDataBlockPipes.temperature">{{ techNoDataBlock.value | temperature }}</ng-container>
      <ng-container *ngIf="techNoDataBlock.pipe === techNoDataBlock.TechNoDataBlockPipes.temperatureNoUnit">{{ techNoDataBlock.value | temperatureNoUnit }}</ng-container>
      <ng-container *ngIf="techNoDataBlock.pipe === techNoDataBlock.TechNoDataBlockPipes.pctNoUnit">{{ techNoDataBlock.value | pctNoUnit:0 }}%</ng-container>
      <ng-container *ngIf="techNoDataBlock.pipe === techNoDataBlock.TechNoDataBlockPipes.number_double_digit">{{ techNoDataBlock.value | number:'.00' }}</ng-container>

      <span [ngClass]="techNoDataBlock.textAfterValueClass" *ngIf="techNoDataBlock.additionalTextAfterValue !== ''">&nbsp;{{techNoDataBlock.additionalTextAfterValue | translate}}</span>

    </ion-label>
  </ion-item>
</div>

<div class="d-flex flex-column" [ngClass]="{'disabled' : disabled}" [class]="itemClass">
    <ion-list class="{{ (ars||singleDHW||nodata)?'no-margin' : '' }}">

        <!-- inizio HYS -->
        <div class="d-flex flex-column" *ngFor="let hys of HYS" [hidden]="!config?.techModeHhdraulicSystems">

            <ion-item class="item header">
                <div class="d-flex flex-row justify-content-between align-items-center w-100">
                    <div class="d-flex flex-row justify-content-start align-items-center">
                        <ion-toggle color="secondary" *ngIf="singleDHW" [checked]="hys.PAR_On"
                                    (ionChange)="hysClick($event,hys)" class="arsToggle"></ion-toggle>


                        <ion-label [ngClass]="{'cursorPointer': config.admin}" (click)="goToLink('/system-hydronic', hys.id)" *ngIf="!singleDHW" class="tech-header">{{ hys?.CFG_Name }}<i *ngIf="config.admin" class="fal fa-chevron-right icon chevron-link-to-page"></i></ion-label>
                    </div>
                    <div class="d-flex fle-row align-items-center">
                        <ion-label class="icons">
                            <icons [MCU]="MCU" [showPowerOff]="showPowerOff(hys)" [config]="config"
                                   [status]="hys?.RTU_Flags || 0"></icons>
                        </ion-label>
                    </div>
                </div>


                <!--<ion-label class="icons">
                <icons [config]="config" [status]="hys?.RTU_Flags || 0"></icons>
                </ion-label>
                <ion-label *ngIf="!nodata" class="tech-header">{{ hys?.CFG_Name }}
                </ion-label>-->
            </ion-item>

            <div class="row" *ngIf="!nodata">
                <!-- mixing valve -->
                <div class="col-md-6 col-mdH-3 col-smBig-6 col-6 col-sm-6 col-lg-3 col-xl-3 maxHeight"
                     *ngIf="((hys?.CFG_Type_H == -1 && hys?.CFG_Type_C == -1 && (hys?.CFG_Type == 1 || hys?.CFG_Type == 2)) || (GRP[hys?.CFG_IdxGRP]?.RTU_SeasonExe == 0 && (hys?.CFG_Type_H == 1 || hys?.CFG_Type_H == 2)) || (GRP[hys?.CFG_IdxGRP]?.RTU_SeasonExe == 1 && (hys?.CFG_Type_C == 1 || hys?.CFG_Type_C == 2)))  else noMixingValve">
                    <ion-item *ngIf="((hys?.CFG_Type_H == -1 && hys?.CFG_Type_C == -1 && (hys?.CFG_Type == 1 || hys?.CFG_Type == 2)) || (GRP[hys?.CFG_IdxGRP]?.RTU_SeasonExe == 0 && (hys?.CFG_Type_H == 1 || hys?.CFG_Type_H == 2)) || (GRP[hys?.CFG_IdxGRP]?.RTU_SeasonExe == 1 && (hys?.CFG_Type_C == 1 || hys?.CFG_Type_C == 2)))" class="w-100">
                        <ion-label class="subtitle ion-hide-sm-down">{{ 'MIXING_VALVE' | translate }}</ion-label>
                        <ion-label>
                            <span class="icon-mixing icon" title="{{ 'MIXING_VALVE' | translate }}"></span>
                            <span *ngIf="GRP[hys?.CFG_IdxGRP]?.RTU_SeasonExe == 0 && hys.CFG_Is2P">
                              {{ (hys?.CFG_IdxMXV_H != -1) ? (MXV[hys?.CFG_IdxMXV_H]?.RTT_Pos | number:'.0') : '--.-' }}
                                %
                          </span>
                            <span *ngIf="GRP[hys?.CFG_IdxGRP]?.RTU_SeasonExe == 1 && hys.CFG_Is2P">
                              {{ (hys?.CFG_IdxMXV_C != -1) ? (MXV[hys?.CFG_IdxMXV_C]?.RTT_Pos | number:'.0') : '--.-' }}
                                %
                          </span>
                            <span *ngIf="hys.CFG_IsHC && !hys.CFG_Is2P">
                              {{ MXV[hys?.CFG_IdxMXV_H]?.RTT_Pos | number:'.0'}}%
                          </span>
                            <span *ngIf="!hys.CFG_IsHC && !hys.CFG_Is2P">
                              {{ MXV[hys?.CFG_IdxMXV_C]?.RTT_Pos | number:'.0' }}%
                          </span>
                        </ion-label>
                    </ion-item>
                </div>

                <ng-template #noMixingValve>
                    <div [class]="blockClasses" class="d-none d-smBig-block"></div>
                </ng-template>

                <div class="col-md-6 col-mdH-3 col-smBig-6 col-6 col-sm-6 col-lg-3 col-xl-3 maxHeight">
                    <ion-item class="w-100">
                        <ion-label
                                class="subtitle ion-hide-sm-down">{{ 'HYDRONIC_SYSTEM_TARGET' | translate }}</ion-label>
                        <ion-label>
                            <span class="far fa-crosshairs icon"
                                  title="{{ 'HYDRONIC_SYSTEM_TARGET' | translate }}"></span>
                            {{ hys?.RTT_TargetTemp | temperature }}
                        </ion-label>
                    </ion-item>
                </div>

                <!-- 4 tubes-->
                <div class="col-md-6 col-mdH-3 col-smBig-6 col-6 col-sm-6 col-lg-3 col-xl-3 maxHeight"
                     *ngIf="!hys?.CFG_Is2P && (hys?.CFG_IsHC && hys?.CFG_CfgAiSup_H!=0 || !hys?.CFG_IsHC && hys?.CFG_CfgAiSup_C!=0)">
                    <ion-item
                            *ngIf="!hys?.CFG_Is2P && (hys?.CFG_IsHC && hys?.CFG_CfgAiSup_H!=0 || !hys?.CFG_IsHC && hys?.CFG_CfgAiSup_C!=0)"
                            class="w-100">
                        <ion-label class="subtitle ion-hide-sm-down">{{ 'SUPPLY_TEMP' | translate }}</ion-label>
                        <ion-label>
                            <span class="icon-supply icon" title="{{ 'SUPPLY_TEMP' | translate }}"></span>
                            {{ hys?.RTT_SupplyTemp | temperature }}
                        </ion-label>
                    </ion-item>
                </div>
                <div class="col-md-6 col-mdH-3 col-smBig-6 col-6 col-sm-6 col-lg-3 col-xl-3 maxHeight"
                     *ngIf="!hys?.CFG_Is2P && ( hys?.CFG_IsHC && hys?.CFG_CfgAiRet_H!=0 || !hys?.CFG_IsHC && hys?.CFG_CfgAiRet_C!=0)">
                    <ion-item
                            *ngIf="!hys?.CFG_Is2P && ( hys?.CFG_IsHC && hys?.CFG_CfgAiRet_H!=0 || !hys?.CFG_IsHC && hys?.CFG_CfgAiRet_C!=0)"
                            class="w-100">
                        <ion-label class="subtitle ion-hide-sm-down">{{ 'RETURN_TEMP' | translate }}</ion-label>
                        <ion-label>
                            <span class="icon-return icon" title="{{ 'RETURN_TEMP' | translate }}"></span>
                            {{ hys?.RTT_ReturnTemp | temperature }}
                        </ion-label>
                    </ion-item>
                </div>
                <div class="col-md-6 col-mdH-3 col-smBig-6 col-6 col-sm-6 col-lg-3 col-xl-3 maxHeight"
                     *ngIf="hys?.CFG_Is2P && (GRP[hys?.CFG_IdxGRP]?.RTU_SeasonExe == 0 && hys?.CFG_CfgAiSup_H!=0 || GRP[hys?.CFG_IdxGRP]?.RTU_SeasonExe == 1 && hys?.CFG_CfgAiSup_C!=0)">
                    <!-- 2 Tubes -->
                    <ion-item
                            *ngIf="hys?.CFG_Is2P && (GRP[hys?.CFG_IdxGRP]?.RTU_SeasonExe == 0 && hys?.CFG_CfgAiSup_H!=0 || GRP[hys?.CFG_IdxGRP]?.RTU_SeasonExe == 1 && hys?.CFG_CfgAiSup_C!=0)"
                            class="w-100">
                        <ion-label class="subtitle ion-hide-sm-down">{{ 'SUPPLY_TEMP' | translate }}</ion-label>
                        <ion-label>
                            <span class="icon-supply icon" title="{{ 'SUPPLY_TEMP' | translate }}"></span>
                            {{ hys?.RTT_SupplyTemp | temperature }}
                        </ion-label>
                    </ion-item>
                </div>

                <div class="col-md-6 col-mdH-3 col-smBig-6 col-6 col-sm-6 col-lg-3 col-xl-3 maxHeight"
                     *ngIf="hys?.CFG_Is2P && ( GRP[hys?.CFG_IdxGRP]?.RTU_SeasonExe == 0 && hys?.CFG_CfgAiRet_H!=0 || GRP[hys?.CFG_IdxGRP]?.RTU_SeasonExe == 1 && hys?.CFG_CfgAiRet_C!=0)">
                    <ion-item
                            *ngIf="hys?.CFG_Is2P && ( GRP[hys?.CFG_IdxGRP]?.RTU_SeasonExe == 0 && hys?.CFG_CfgAiRet_H!=0 || GRP[hys?.CFG_IdxGRP]?.RTU_SeasonExe == 1 && hys?.CFG_CfgAiRet_C!=0)"
                            class="w-100">
                        <ion-label class="subtitle ion-hide-sm-down">{{ 'RETURN_TEMP' | translate }}</ion-label>
                        <ion-label>
                            <span class="icon-return icon" title="{{ 'RETURN_TEMP' | translate }}"></span>
                            {{ hys?.RTT_ReturnTemp | temperature }}
                        </ion-label>
                    </ion-item>
                </div>
            </div>
        </div><!-- fine hydronic system -->

        <!-- inizio ENR -->
        <div class="d-flex flex-column" *ngFor="let enr of ENR" [hidden]="!config?.techModeEnergyResources">
            <ion-item class="item header">
                <div class="d-flex flex-row justify-content-between align-items-center w-100">
                    <div class="d-flex flex-row justify-content-start align-items-center">

                        <ion-toggle color="secondary" *ngIf="nodata" [checked]="enr.PAR_On"
                                    (ionChange)="enrClick($event,enr)" class="arsToggle"></ion-toggle>

                        <ion-label [ngClass]="{'cursorPointer': config.admin}" (click)="goToLink('/system-enr', enr.id)" *ngIf="!nodata" class="tech-header">{{ enr?.CFG_Name }}<i *ngIf="config.admin" class="fal fa-chevron-right icon chevron-link-to-page"></i></ion-label>

                    </div>
                    <div class="d-flex fle-row align-items-center">
                        <ion-label class="icons">
                            <icons [manualIcons]="nodata ? ['fa ' + getParSeasonIcon(getExtendedGrp(enr))] : []"
                                   [MCU]="MCU" [showPowerOff]="showPowerOff(enr)" [config]="config"
                                   [status]="enr?.RTU_Flags || [0]"></icons>
                        </ion-label>
                    </div>
                </div>
            </ion-item>


            <!-- || ( enr?.RTT_H2O_Supply!=NO_VALUE && enr?.RTT_H2O_Supply!=null) || (enr?.RTT_H2O_Return!=NO_VALUE && enr?.RTT_H2O_Return!=null)-->

            <div class="row" *ngIf=" !nodata && (viewEnr(enr) || enr.CFG_CfgAiSupply != 0 || enr.CFG_CfgAiReturn != 0)">

                <ng-container *ngFor="let enrNoDataBlock of enrNoDataBlocks[enr.id]" >

                    <tech-nodata-block [class]="blockClasses" *ngIf="enrNoDataBlock else empty" [techNoDataBlock]="enrNoDataBlock"></tech-nodata-block>

                    <ng-template #empty>
                        <div [class]="blockClasses" class="d-none d-smBig-block"></div>
                    </ng-template>

                </ng-container>

            </div>
        </div>
        <!-- fine ENR -->

        <!-- inizio TNK -->
        <div class="d-flex flex-column" *ngFor="let tnk of TNK" [hidden]="!config?.techModeAccumulationTanks">

            <ion-item class="item header">

                <div class="d-flex flex-row justify-content-between align-items-center w-100">

                    <div class="d-flex flex-row justify-content-start align-items-center">
                        <ion-toggle color="secondary" (ionChange)="tnkClick($event,tnk)" [checked]="tnk.state"
                                    *ngIf="singleDHW" class="arsToggle" disabled></ion-toggle>
                        <ion-label [ngClass]="{'cursorPointer': config.admin}" (click)="goToLink('/system-Tank', tnk.id)" *ngIf="!nodata" class="tech-header">{{ tnk?.CFG_Name }}<i *ngIf="config.admin" class="fal fa-chevron-right icon chevron-link-to-page"></i></ion-label>
                    </div>
                    <div class="d-flex flex-row align-items-center">
                        <ion-label class="icons tnk-icons">
                            <icons [MCU]="MCU" [showPowerOff]="showPowerOff(tnk)" [config]="config"
                                   [status]="tnk?.RTU_Flags || 0"></icons>
                        </ion-label>
                    </div>
                </div>


            </ion-item>

            <div class="row" *ngIf="!nodata">
                <div class="col-md-6 col-mdH-3 col-smBig-6 col-6 col-sm-6 col-lg-3 col-xl-3 maxHeight">
                    <ion-item class="w-100">
                        <ion-label class="subtitle ion-hide-sm-down">{{ 'BUFFER_TANK_TEMP' | translate }}</ion-label>
                        <ion-label>
                            <span class="fal fa-thermometer-half icon"
                                  title="{{ 'BUFFER_TANK_TEMP' | translate }}"></span>
                            {{ tnk?.RTU_Temp | temperature }}
                        </ion-label>
                    </ion-item>
                </div>
                <div class="col-md-6 col-mdH-3 col-smBig-6 col-6 col-sm-6 col-lg-3 col-xl-3 maxHeight"
                     *ngIf="tnk?.PAR_TempMode == 0 || tnk?.PAR_TempMode == 2">
                    <ion-item *ngIf="tnk?.PAR_TempMode == 0 || tnk?.PAR_TempMode == 2" class="w-100">
                        <ion-label class="subtitle ion-hide-sm-down">{{ 'BUFFER_TANK_SET' | translate }}</ion-label>
                        <ion-label>
                            <span class="icon-targettemp icon" title="{{ 'BUFFER_TANK_SET' | translate }}"></span>
                            {{ tnk?.RTU_TargetTemp | temperature }}
                        </ion-label>
                    </ion-item>
                </div>
                <div class="col-md-6 col-mdH-3 col-smBig-6 col-6 col-sm-6 col-lg-3 col-xl-3 maxHeight"
                     *ngIf="tnk?.PAR_TempMode == 1">
                    <ion-item *ngIf="tnk?.PAR_TempMode == 1" class="w-100">
                        <ion-label class="subtitle ion-hide-sm-down">{{ 'BUFFER_TANK_TARGET' | translate }}</ion-label>
                        <ion-label>
                            <span class="far fa-crosshairs icon" title="{{ 'BUFFER_TANK_TARGET' | translate }}"></span>
                            {{ tnk?.RTU_TargetTemp | temperature }}
                        </ion-label>
                    </ion-item>
                </div>
            </div>
        </div>
        <!-- fine TNK -->

        <!-- inizio DHW -->

        <div class="d-flex flex-column" *ngFor="let dhw of DHW" [hidden]="!config?.techModeDomesticHotWater">


            <ion-item class="item header">
                <div class="d-flex flex-row justify-content-between align-items-center w-100">
                    <div class="d-flex flex-row justify-content-start align-items-center">
                        <ion-toggle color="secondary" *ngIf="singleDHW" [checked]="dhw.PAR_OnB"
                                    (ionChange)="dhwClick($event,dhw)" class="arsToggle"></ion-toggle>
                        <ion-label [ngClass]="{'cursorPointer': config.admin}" (click)="goToLink('/system-DHW', dhw.id)" *ngIf="!singleDHW" class="tech-header">{{ dhw?.CFG_Name }}<i *ngIf="config.admin" class="fal fa-chevron-right icon chevron-link-to-page"></i></ion-label>
                    </div>
                    <div class="d-flex flex-row align-items-center">
                        <ion-label class="icons">
                            <icons [MCU]="MCU" [showPowerOff]="showPowerOff(dhw)" [config]="config"
                                   [status]="dhw?.RTU_Flags || 0"></icons>
                        </ion-label>
                    </div>
                </div>


            </ion-item>
            <div class="row" *ngIf="!nodata">
<!-- tofinish -->
                <div *ngIf="dhw?.CFG_CfgAiTemp != 0 || (ENR != undefined && ENR[0]?.CFG_Model === 6)"
                     class="col-md-6 col-mdH-3 col-smBig-6 col-6 col-sm-6 col-lg-3 col-xl-3 maxHeight">
                    <ion-item class="w-100">
                        <ion-label class="subtitle ion-hide-sm-down">{{ 'DHW_TEMP' | translate }}</ion-label>
                        <ion-label>
                            <span class="fal fa-thermometer-half icon" title="{{ 'DHW_TEMP' | translate }}"></span>
                            {{ dhw?.RTU_Temp | temperature }}
                        </ion-label>
                    </ion-item>
                </div>
                <div *ngIf="dhw?.CFG_CfgDiReq==0 && dhw?.CFG_CfgAiTemp != 0 || (ENR != undefined && ENR[0]?.CFG_Model === 6)"
                     class="col-md-6 col-mdH-3 col-smBig-6 col-6 col-sm-6 col-lg-3 col-xl-3 maxHeight">
                    <ion-item class="w-100">
                        <ion-label class="subtitle ion-hide-sm-down">{{ 'DHW_TARGET_TEMP' | translate }}</ion-label>
                        <ion-label>
                            <span class="far fa-crosshairs icon" title="{{ 'TANK_TARGET' | translate }}"></span>
                            {{ dhw?.RTU_TargetTemp | temperature }}
                        </ion-label>
                    </ion-item>
                </div>
                <div *ngIf="dhw?.CFG_CfgAiRec != 0"
                     class="col-md-6 col-mdH-3 col-smBig-6 col-6 col-sm-6 col-lg-3 col-xl-3 maxHeight">
                    <ion-item class="w-100">
                        <ion-label
                                class="subtitle ion-hide-sm-down">{{ 'DHW_RECIRCULATION_TEMP' | translate }}</ion-label>
                        <ion-label>
                            <span class="icon-seasonchange icon"
                                  title="{{ 'DHW_RECIRCULATION_TEMP' | translate }}"></span>
                            {{ dhw?.RTU_TempRec | temperature }}
                        </ion-label>
                    </ion-item>
                </div>
                <div *ngIf="dhw?.CFG_CfgAiRec != 0"
                     class="col-md-6 col-mdH-3 col-smBig-6 col-6 col-sm-6 col-lg-3 col-xl-3 maxHeight">
                    <ion-item class="w-100">
                        <ion-label
                                class="subtitle ion-hide-sm-down">{{ 'DHW_RECIRCULATION_SET' | translate }}</ion-label>
                        <ion-label>
                            <span class="icon-targettemp icon" title="{{ 'DHW_RECIRCULATION_SET' | translate }}"></span>
                            {{ dhw?.PAR_RecTarget | temperature }}
                        </ion-label>
                    </ion-item>
                </div>
            </div>
        </div>
        <!-- fine DHW -->

        <!-- inizio ATU -->
        <div class="d-flex flex-column atuContainer" *ngFor="let atu of ATU; let i = index; trackBy: trackByAtuId"
             [hidden]="!config?.techModeAirSystems">

            <ion-item *ngIf="atu && !isHumidifier(atu) && isArsFromSystemZonePage" class="no-lines">

                <ion-label>{{ars.CFG_Name}}</ion-label>
                <icons [MCU]="MCU" *ngIf="!isATUwithHumidifier(atu)" [showPowerOff]="showPowerOff(atu)"
                       [hasBlackBackground]="hasBlackBackground" [config]="config" [status]="atu?.RTU_Flags || 0"></icons>
                <icons [MCU]="MCU" *ngIf="isATUwithHumidifier(atu)" [showPowerOff]="showPowerOff(atu)" [config]="config"
                       [hasBlackBackground]="hasBlackBackground" [status]="merge(atu?.RTU_Flags, getHumidifier(atu).RTU_Flags)"></icons>
                <span class="icon fa {{getParSeasonIcon(getExtendedGrp(ars))}}"></span>
                <ion-toggle id="toggle-rms-{{customData}}-ars-{{ars.id}}" color="secondary" *ngIf="ars" [checked]="ars.PAR_On"
                            (click)="arsDirectClick($event)" (ionChange)="arsClick($event,ars)"
                            class="arsToggle"></ion-toggle>

            </ion-item>

            <div class="ifAtu d-flex flex-column" *ngIf="atu && !isHumidifier(atu) && !isArsFromSystemZonePage">
                <ion-item class="item header">
                    <div class="d-flex flex-row justify-content-between align-items-center w-100">
                        <div class="d-flex flex-row justify-content-start align-items-center">

                            <ion-label [ngClass]="{'cursorPointer': config.admin}" (click)="goToLink('/system-ARS', atu.id)" *ngIf="!ars" class="tech-header">{{ atu?.CFG_Name }}<i *ngIf="config.admin" class="fal fa-chevron-right icon chevron-link-to-page"></i></ion-label>

                            <ion-toggle color="secondary" *ngIf="ars" [checked]="ars.PAR_On"
                                        (ionChange)="arsClick($event,ars)" class="arsToggle"></ion-toggle>

                        </div>

                        <div class="d-flex fle-row align-items-center" *ngIf="!isATUwithHumidifier(atu)">
                            <ion-label class="icons">
                                <icons [MCU]="MCU" [showPowerOff]="showPowerOff(atu)" [config]="config"
                                       [hasBlackBackground]="hasBlackBackground" [status]="atu?.RTU_Flags || 0"></icons>
                            </ion-label>
                        </div>

                        <div class="d-flex fle-row align-items-center" *ngIf="isATUwithHumidifier(atu)">
                            <ion-label class="icons">
                                <icons [MCU]="MCU" [showPowerOff]="showPowerOff(atu)" [config]="config"
                                       [hasBlackBackground]="hasBlackBackground" [status]="merge(atu?.RTU_Flags, getHumidifier(atu).RTU_Flags)"></icons>
                            </ion-label>
                        </div>
                    </div>
                </ion-item>
                <!--  <div class="notNoData" *ngIf="!nodata">-->

                <div class="row" *ngIf="!nodata">

                    <ng-container *ngFor="let atuNoDataBlock of atuNoDataBlocks[atu.id]" >

                        <tech-nodata-block [class]="blockClasses" *ngIf="atuNoDataBlock else empty" [techNoDataBlock]="atuNoDataBlock"></tech-nodata-block>

                        <ng-template #empty>
                            <div [class]="blockClasses" class="d-none d-smBig-block"></div>
                        </ng-template>

                    </ng-container>

                </div><!-- chiusa row-->


                <!--</div> chiuso no data -->
            </div>
        </div>
        <!-- fine ATU -->

        <!-- inizio FNC -->
        <div class="d-flex flex-column" *ngFor="let fnc of FNC" [hidden]="!config?.techModeDomesticHotWater">
            <ion-item class="item header">
                <div class="d-flex flex-row justify-content-between align-items-center w-100">

                    <div class="d-flex flex-row justify-content-start align-items-center">
                        <ion-toggle color="secondary" *ngIf="singleFNC" [checked]="fnc.PAR_On"
                                    (ionChange)="fncClick($event,fnc)" class="arsToggle"></ion-toggle>
                        <ion-label [ngClass]="{'cursorPointer': config.admin}" (click)="goToLink('/system-FNC', fnc.id)" *ngIf="!singleFNC" class="tech-header">{{ fnc?.CFG_Name }}<i *ngIf="config.admin" class="fal fa-chevron-right icon chevron-link-to-page"></i></ion-label>
                    </div>
                    <div class="d-flex flex-row align-items-center">
                        <ion-label class="icons">
                            <icons [MCU]="MCU" [showPowerOff]="showPowerOff(fnc)" [config]="config" [FNC]="[fnc]"
                                   [status]="fnc?.RTU_Flags || 0"></icons>
                        </ion-label>
                    </div>
                </div>
            </ion-item>

            <div class="row" *ngIf="!nodata">
                <ng-container *ngFor="let fncNoDataBlock of fncNoDataBlocks[fnc.id]" >

                    <tech-nodata-block [class]="blockClasses" *ngIf="fncNoDataBlock else empty" [techNoDataBlock]="fncNoDataBlock"></tech-nodata-block>

                    <ng-template #empty>
                        <div [class]="blockClasses" class="d-none d-smBig-block"></div>
                    </ng-template>
                </ng-container>
            </div>
        </div>
        <!-- fine FNC -->

        <!-- inizio FWA -->
        <div class="d-flex flex-column" *ngFor="let fwa of FWA">

            <ion-item class="item header">
                <div class="d-flex flex-row justify-content-between align-items-center w-100">

                    <div class="d-flex flex-row justify-content-start align-items-center">
                        <ion-label class="tech-header">{{ fwa?.CFG_Name }}</ion-label>
                    </div>
                    <div class="d-flex flex-row align-items-center">
                        <ion-label class="icons">
                            <icons [MCU]="MCU" [showPowerOff]="showPowerOff(fwa)" [config]="config"
                                   [status]="fwa?.RTU_Flags || 0"></icons>
                        </ion-label>
                    </div>
                </div>
            </ion-item>

            <div class="row" *ngIf="!nodata">
                <!-- unused (not present in dashboard -->
            </div>
        </div>
        <!-- fine FNC -->
    </ion-list>
</div>

import { Directive, OnInit, Input, ElementRef,HostListener, EventEmitter, Output,OnChanges } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';


declare var $: any; 

@Directive({
  selector: '[timetravel]'
})
export class TimetravelDirective implements OnChanges {


	@Output() outtime:EventEmitter<string>= new EventEmitter<string>();
  @Output() outampm:EventEmitter<string>= new EventEmitter<string>();

	@Input() selectedtimeFrom:string;
	@Input() selectedtimeTo:string;

	@Input() userClick:boolean;

	@Input() from:number;
	@Input() to:number;

	@Input() fromAmPm:string;
	@Input() toAmPm:string;

	@Input() fromMode:boolean;


 	public userManipulation:boolean;

	public moving:boolean;
	public offsetx:number;
	public totaloffset:number;
	public currentoffset:number;
	public currenttime:any;
	public timeline:any;
	public timeline_width:any;
	public xinitial:number;

  lastHour:number;

  constructor(public el: ElementRef) {
   
   }

   ngOnChanges(inputs): void {


   	setTimeout(()=>{
   		// console.log(`cambiamento, from vale ${this.from} this.userClick vale ${this.userClick}`);

   	if( !this.userManipulation || (this.userClick && !this.moving )){
   		// console.error('userClick vale false, sposto!')
   		  	$(document).ready(() => {
		  	    this.currenttime = $('.current-time')[0];
		        this.timeline = $('.timeline');
		        this.timeline_width = this.timeline[0].offsetWidth;
		      
		      
		       let sectionlength = this.timeline_width / 24;
        	   let newOff=(!this.fromMode) ? this.from*sectionlength : this.to*sectionlength;
		        // console.log('ngOnChanges');


		        if(!this.fromMode){
		        	//from 
		        	 $(this.currenttime).css({
		           		transition: 'transform 0.25s ease',
		           		transform: 'translateX(' + (newOff - 1) + 'px)',
		        	 }); 

		        }

		         if(this.fromMode){
		        	//from 
		        	 $(this.currenttime).css({
		           		transition: 'transform 0.25s ease',
		           		transform: 'translateX(' + (newOff - 1) + 'px)',
		        	 }); 

		        }


		         this.currentoffset = newOff;
         		this.totaloffset = this.currentoffset;

		        this.getTime();

		         
		      

		  	});

   		}

   	},400);
   	




   }


  ngOnInit(){
  

  	// console.warn('ngOnInit TimetravelDirective');
  	// console.log(this.el);
  	this.moving=false;
  	this.offsetx=0;
  	this.totaloffset=0;
  	this.currentoffset= 0;
  	this.xinitial=0;
  	this.userManipulation=false;

  	

  


  	//mi servono i due figli di timeline-container
  	// current-time e timeline



  	$(document).ready(() => {
  	    this.currenttime = $('.current-time')[0];
        this.timeline = $('.timeline');
        this.timeline_width = this.timeline[0].offsetWidth;

        // console.log(`jQuery w timeline ${this.timeline_width}`);
        // console.log('loggo this.currenttime');

         let sectionlength = this.timeline_width / 48;
         // console.log(`sectionlength ${sectionlength} from ${this.from} to ${this.to}`)
         let newOff=(!this.fromMode) ? this.from*sectionlength : this.to*sectionlength;


        if(!this.fromMode){
        	//from 
        	 $(this.currenttime).css({
           		transition: 'transform 0.25s ease',
           		transform: 'translateX(' + (newOff - 1) + 'px)',
        	 }); 

        }

         if(this.fromMode){
        	//from 
        	 $(this.currenttime).css({
           		transition: 'transform 0.25s ease',
           		transform: 'translateX(' + (newOff - 1) + 'px)',
        	 }); 

        }

          this.getTime();

         
      

  	});

  }
              
    pad2(number) {
         return (number < 10 ? '0' : '') + number;
    }

   getTime(){
         let percenttime = (this.currentoffset / this.timeline_width) + 0.01;
         let percentminutes = ((this.currentoffset / this.timeline_width) * 12) + 0.01;
         let minutes = Math.round(percentminutes % 1) * 30;
         let hour = Math.floor(percenttime * 12);
         if (hour === 0) {
           hour = 12;
         } 
         if(!this.fromMode){
         	//from
         	this.selectedtimeFrom = hour + ":" + this.pad2(minutes);


           if(this.lastHour!=undefined)
             if(this.lastHour<12)
               if(Math.floor(percenttime * 12)==12)
                 if(this.userManipulation && (!this.userClick || this.moving ))
                    this.outampm.emit();

         	this.outtime.emit(this.selectedtimeFrom);

           this.lastHour=Math.floor(percenttime * 12);
         }
         if(this.fromMode){
         	//to
             if(this.lastHour!=undefined)
             if(this.lastHour<12)
               if(Math.floor(percenttime * 12)==12)
                 if(this.userManipulation && (!this.userClick || this.moving ))
                    this.outampm.emit();

         	this.selectedtimeTo = hour + ":" + this.pad2(minutes);
         	this.outtime.emit(this.selectedtimeTo);
          this.lastHour=Math.floor(percenttime * 12);
         }

         
         // console.log(`this.selectedtimeFrom ${this.selectedtimeFrom} this.selectedtimeTo ${this.selectedtimeTo}`);
        
    }

    @HostListener('window:resize', ['$event']) onResize(event) {
   	  // console.warn('onResize event');
   	  $(document).ready(() => {
	    this.timeline_width = this.timeline[0].offsetWidth;
	    // console.log(`timeline w aggioranta a  ${ this.timeline_width}`);
		});
	}


	 @HostListener('touchstart', ['$event'])
     @HostListener('document:mousedown', ['$event']) onMouseDown(event) {
    	if (event.type == 'mousedown') {
           this.xinitial = (event as MouseEvent).clientX;
         } else if (event.type == 'touchstart') {
           this.xinitial = (event as TouchEvent).touches[0].clientX;
         }
         this.moving = true;
         this.userManipulation=true;


          $(this.currenttime).css({
           transition: 'none'

         });

         //document.documentElement.style.setProperty('--transform', 'none');
         
         //console.log(`xinitial ${ this.xinitial} moving ${this.moving}`);
         
 	}


     @HostListener('touchmove', ['$event'])
     @HostListener('document:mousemove', ['$event']) onMouseMove(event) {


     	

     	 
     	 		if (this.moving === true) {
	           if (event.type == 'mousemove') {
	             this.offsetx = (event as MouseEvent).clientX - this.xinitial;
	           } else if (event.type == 'touchmove') {
	             this.offsetx = (event as TouchEvent).touches[0].clientX - this.xinitial;
	           }
	           let movex = this.offsetx + this.totaloffset;
	           if (movex >= 0 && movex <= this.timeline_width) {
	           	 //document.documentElement.style.setProperty('--translate','translateX(' + movex + 'px)');
	             $(this.currenttime).css({
	               transform: 'translateX(' + movex + 'px)'
	             });
	             this.currentoffset = movex;
	           } else if (movex < 0) {
	           	 //document.documentElement.style.setProperty('--translate','translateX(0)');
	             $(this.currenttime).css({
	               transform: 'translateX(0)'
	             });
	             this.currentoffset = 0;
	           } else {
	           	//document.documentElement.style.setProperty('--translate','translateX(' + this.timeline_width + 'px)');
	              $(this.currenttime).css({
	               transform: 'translateX(' + this.timeline_width + 'px)'
	             }); 
	             this.currentoffset = this.timeline_width;
	           }
	           this.getTime();
	         }


     	 	
    	
     	

     	 //console.log(event);
 	}
      
     @HostListener('touchend', ['$event'])
     @HostListener('document:mouseup', ['$event']) onMouseUp(event) {
    	 let sectionlength = this.timeline_width / 48;
         let roundsection = Math.round(this.currentoffset / sectionlength);
         let newoffset = roundsection * sectionlength;
         //document.documentElement.style.setProperty('--transform', 'transform 0.25s ease');
        // document.documentElement.style.setProperty('--translate',  'translateX(' + (newoffset - 1) + 'px)');

          $(this.currenttime).css({
           transition: 'transform 0.25s ease',
           transform: 'translateX(' + (newoffset - 1) + 'px)',
         }); 
         this.currentoffset = newoffset;
         this.totaloffset = this.currentoffset;
         setTimeout(() => {
           		this.getTime();
       	 }, 250);
         this.moving = false;
 	}
      
       
    /*  
       
      
       
       element.on('mousedown touchstart', function (event) {
         if (event.type == 'mousedown') {
           scope.xinitial = event.clientX;
         } else if (event.type == 'touchstart') {
           scope.xinitial = event.touches[0].clientX;
         }
         scope.moving = true;
         currenttime.css({
           transition: 'none',
         });
       });
       
       angular.element($window).on('mousemove touchmove', function (event) {
         if (scope.moving === true) {
           if (event.type == 'mousemove') {
             scope.offsetx = event.clientX - scope.xinitial;
           } else if (event.type == 'touchmove') {
             scope.offsetx = event.touches[0].clientX - scope.xinitial;
           }
           var movex = scope.offsetx + scope.totaloffset;
           if (movex >= 0 && movex <= timeline_width) {
             currenttime.css({
               transform: 'translateX(' + movex + 'px)',
             });
             scope.currentoffset = movex;
           } else if (movex < 0) {
             currenttime.css({
               transform: 'translateX(0)',
             });
             scope.currentoffset = 0;
           } else {
             currenttime.css({
               transform: 'translateX(' + timeline_width + 'px)',
             });
             scope.currentoffset = timeline_width;
           }
           scope.getTime();
         }
       });
       
       angular.element($window).on('mouseup touchend', function (event) {
         var sectionlength = timeline_width / 24;
         var roundsection = Math.round(scope.currentoffset / sectionlength);
         var newoffset = roundsection * sectionlength;
         currenttime.css({
           transition: 'transform 0.25s ease',
           transform: 'translateX(' + (newoffset - 1) + 'px)',
         });
         scope.currentoffset = newoffset;
         scope.totaloffset = scope.currentoffset;
         window.setTimeout(function () {
           scope.getTime();
         }, 250);
         scope.moving = false;
       });

       */
     
 

}

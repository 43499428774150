import {ChangeDetectionStrategy, AfterContentInit, Component, EventEmitter, forwardRef, HostListener, Input, OnInit, OnDestroy, Optional, Output, Provider, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Router, ActivatedRoute, Params, RouterEvent,NavigationEnd,NavigationExtras } from '@angular/router';
import {Subscription} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';



export const CHECKBOX_VALUE_ACCESSOR: any = {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ButtonCollapseComponent), multi: true};
const TYPE_CONTROL_ACCESSOR = {
provide: NG_VALUE_ACCESSOR,
useExisting: forwardRef(() => ButtonCollapseComponent),
multi: true
};

@Component({
  selector: 'button-collapse',
  templateUrl: './button-collapse.component.html',
  styleUrls: ['./button-collapse.component.scss'],
  providers: [TYPE_CONTROL_ACCESSOR]
})
export class ButtonCollapseComponent implements AfterContentInit, ControlValueAccessor, OnDestroy {

   public _checked: boolean = false;
    private _init: boolean;
    private _disabled: boolean = false;
    private _labelId: string;
    private _fn: Function;
  

    id: string;

    @Input()
    alarm:boolean;

    // CSS class
    @Input()
    itemClass: string;      
    @Input()
    buttonClass: string;     

    @Input()
    showLabel:string;

    @Input()
    hideLabel:string;

    @Input()
    labelClass: string;   

     @Input()
    trueClass: string;    

    @Input()
    falseClass: string;

    // label
    @Input()
    label: string;

   

     constructor(  protected translate: TranslateService) {}

      @HostListener('click', ['$event'])
    public _click(ev: UIEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        if(!this._disabled)
            this.onChange(!this._checked);
    }

     @Input()
    get checked(): boolean {
        return this._checked;
    }

    set checked(val: boolean) {
        if(!this._disabled){
            this._setChecked(true);
            this.onChange(this._checked);
            this.onTouched();
    		
        } 
       
    } 

    private _setChecked(isChecked: boolean) {
        //console.warn('collapse _setChecked');

        if (isChecked !== this._checked && !this._disabled) {
            this._checked = isChecked;
           
           
            
        }
    }

    writeValue(val: any) {
    
        this._setChecked( val);
    }

    registerOnChange(fn: Function): void {
        this._fn = fn;
        this.onChange = (isChecked: boolean) => {
            fn(isChecked);
            this._setChecked(isChecked);
            this.onTouched();
        };
    }

    registerOnTouched(fn: any) { this.onTouched = fn; }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(val: boolean) {
       
        this._disabled = val;
      
        if(val){
          this._checked=false;
          this.onChange(false)
        }
    }

    setDisabledState(disabled: boolean): void {
         this._disabled = disabled;
    }

    onChange(isChecked: boolean) {
        // used when this input does not have an ngModel or formControlName
       
            this._setChecked(isChecked);
            this.onTouched();
        
    }

    onTouched() {}

    ngAfterContentInit() {
        this._init = true;
    }

    ngOnDestroy() {
      
    }

    private onTouch: Function;
    private onModelChange: Function;

}

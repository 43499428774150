import {Component, Input, OnInit} from '@angular/core';
import {ChangeTableValues} from "../../actions/tables.actions";
import {Table} from "../../models/tables";
import {Store} from "@ngrx/store";
import {AppState} from "../../services/app-state.service";
import {BitArray} from "../../commons/bitarray";

@Component({
  selector: 'toggle-row',
  templateUrl: './toggle-row.component.html',
  styleUrls: ['./toggle-row.component.scss'],
})

export class ToggleRowComponent implements OnInit {

  @Input()
  label:string;

  @Input()
  item: Table;

  @Input()
  table: string;

  @Input()
  property: string;

  @Input()
  store: Store<AppState>;

  @Input()
  bitArray: BitArray;

  @Input()
  bitArrayPosition: number;

  @Input()
  disabled: boolean;

  constructor() { }

  ngOnInit() {}

  onToggle(event: any) {

    this.item[this.property] = event.detail.checked ? 1 : 0;
    this.dispatch();
  }

  onToggleBitArray(event: any) {

    this.bitArray.set(this.bitArrayPosition, event.detail.checked);
    this.item[this.property] = this.bitArray.toNumber();
    this.dispatch();
  }

  dispatch() {

    console.log('id', this.item.id);
    this.store.dispatch(new ChangeTableValues(this.table, this.item.id, {[this.property]: this.item[this.property]}));
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {RMS_Extended} from '../../models/tables-extended';
import {GRP, SCH, AUTO_MODE_STYLE} from '../../models/tables';
import {DUAL_MODE, SchedulationType} from "../../models/config";

@Component({
    selector: 'show-setpoint',
    templateUrl: './show-setpoint.component.html',
    styleUrls: ['./show-setpoint.component.scss'],
})
export class ShowSetpointComponent implements OnInit {

    @Input() rms: RMS_Extended;
    @Input() grp: GRP;
    @Input() sch: SCH;
    @Input() season: number;
    @Input() temperatureExceptionH: boolean;
    @Input() temperatureExceptionC: boolean;

    AUTO_MODE_STYLE = AUTO_MODE_STYLE;
    SchedulationType = SchedulationType;

    constructor() {
    }

    ngOnInit() {
    }

    isAutoModeStyleDualSetpoint() {
        return this.grp?.PAR_AutoModeStyle == AUTO_MODE_STYLE.DUAL_SETPOINT && this.grp?.PAR_Season != 0 && this.grp?.PAR_Season != 1 && this.grp?.PAR_Season != -1;
    }

    isOverride() {

        return this.rms.getSetpointOverrideInfo().isOverride;
    }

    isOverrideDualSetpointH() {

        return this.rms.getSetpointOverrideInfo(null, DUAL_MODE.heating).isOverride;
    }

    isOverrideDualSetpointC() {

        return this.rms.getSetpointOverrideInfo(null, DUAL_MODE.cooling).isOverride;
    }

}

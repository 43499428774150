import { Action } from '@ngrx/store';

/**
 * Instead of passing around action string constants and manually recreating
 * action objects at the point of dispatch, we create services encapsulating
 * each appropriate action group.
 */

export enum AttributeActionTypes {
	LoadAttributes = '[Attributes] Load Attributes',
	ReloadAttributes = '[Attributes] Reload Attributes',
	
	LoadAttributesSuccess = '[Attributes] Load Attributes Success',
	LoadAttributesError = '[Attributes] Load Attributes Error',
    SaveAttributesConfigDispatched = '[Attributes] SaveAttributesConfig Dispatched',
}


export class LoadAttributes implements Action {
    readonly type = AttributeActionTypes.LoadAttributes;
    constructor(){}
}
export class ReloadAttributes implements Action {
    readonly type = AttributeActionTypes.ReloadAttributes;
    constructor(){}
}
export class LoadAttributesSuccess implements Action {
    readonly type = AttributeActionTypes.LoadAttributesSuccess;
    constructor(public data:any){}
}
export class LoadAttributesError implements Action {
    readonly type = AttributeActionTypes.LoadAttributesError;
    constructor(public error:any){}
}

export class SaveAttributesConfigDispatched implements Action {

    readonly type = AttributeActionTypes.SaveAttributesConfigDispatched;

    constructor() {
    }
}


export type AttributesActions = LoadAttributes | ReloadAttributes | LoadAttributesSuccess | LoadAttributesError | SaveAttributesConfigDispatched;

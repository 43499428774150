
export const EMA_TRANSLATIONS = {
"GENERAL_ALARM":"General alarm",
"LOGIC_FREEZED":"Logic freezed",
"COMM_FREEZED":"Communication freezed",
"HISTORIC_FREEZED":"historic freezed",
"COMM_ERROR":"Communication error",
"MODBUS_EXCEPTION":"Modbus exception",
"LOW_PRESSURE":"Low pressure",
"HIGH_PRESSURE":"High pressure",
"AIR_SUPPLY_PROBE_FAILURE":"Air supply probe failure",
"INLET_WATER_PROBE_FAILURE":"Inlet water probe failure",
"EVAPORATOR_PROBE_FAILURE":"Evaporator probe failure",
"CONDENSATOR_PROBE_FAILURE":"Condensator probe failure",
"EMA_ALARM":"Alarm",
"HUMIDITY_PROBE_FAILURE":"Humidity probe failure",
"DIRTY_FILTERS":"Dirty filters",
"GENERAL_WARNING":"General warning",
"DISPLAY_COMM_ERROR":"Display communication error",
"CO2_PROBE_ALARM":"CO2 probe alarm",
"LOW_H20_TEMPERATURE":"Low H2O temp",
"HIGH_H20_TEMPERATURE":"High H2O temp",
"ANTI_FREEZE_WARNING":"Antifreeze warning",
"PROBE_ALARM":"Probe alarm",
"P_TRAP_ALARM":"P-trap alarm",
"ANTI_LEGIONELLA_WARNING":"Antilegionella warning",
"ANTI_LEGIONELLA_ALARM":"Antilegionella alarm",
"ANTI_FREEZE_ALARM":"Antifreeze alarm",
"DHW_TANK_PROBE_FAULT":"DHW tank probe fault",
"RE_CIRCULATION_PROBE_FAULT":"Recirculation probe fault",
"TARGET_TEMPERATURE_NOT_REACHED":"Target temp not reached",
"NO_ER_AVAILABLE":"No H/C source(s) available",
"THERMAL_ACTUATOR_NOT_AVAILABLE":"Thermal actuator not available",
"SUPPLY_TEMPERATURE_PROBE_FAULT":"Supply temp probe fault",
"RETURN_TEMPERATURE_PROBE_FAULT":"Return temp probe fault",
"HIGH_TEMPERATURE_LEVEL_1":"High temp level 1",
 "LOW_TEMPERATURE_WARNING":"Low temp",
"HIGH_TEMPERATURE_LEVEL_2":"High temp level 2",
"HIGH_TEMPERATURE_RESETTING_MIXING_VALVE":"High temp (Resetting Mixing Valve)",
"LOW_TEMPERATURE_RESETTING_MIXING_VALVE":"Low temp (Resetting Mixing Valve)",
"HIGH_TEMPERATURE_LEVEL_3_ACTION_REQUIRED":"High temp level 3 (User action required)",
 "LOW_TEMPERATURE_ACTION_REQUIRED":"Low temp (User action required)",
"SUPPLY_ANTIFREEZE_WARNING":"Supply antifreeze warning",
"SUPPLY_ANTIFREEZE_ALARM":"Supply antifreeze alarm",
"ROOM_ANTIFREEZE_WARNING":"Room antifreeze warning",
"ROOM_ANTIFREEZE_ALARM":"Room antifreeze alarm",
"LOW_TEMPERATURE":"Low temp",
"SOFT_COLD_START_ON":"Soft Cold Start logic activated",
"SUPPLY_CONTROL_LEVEL_1":"Supply control level 1",
"SUPPLY_CONTROL_LEVEL_2":"Supply control level 2",
"THERMOELECTRIC_VALVE_CONTROL_NOT_AVAILABLE":"Thermoelectric valve control not available",
"SEASON_MODE_INCOMPATIBLE_WITH_MAIN_HYDRONIC_SYSTEM":"Season mode incompatible with main hydronic system",
"DYNAMIC_DELTAR_IN_USE":"Dynamic DeltaR in use",
"TEMPERATURE_SENSOR_FAULT":"Temperature sensor fault",
"HUMIDITY_SENSOR_FAULT":"Humidity sensor fault",
"ALARM_POSITION":"Alarm position",
"SERVICE_POSITION":"Service position",
"ERROR_POSITION":"Error position",
"OPERATIVE_TEMPERATURE_SENSOR":"Operative temp sensor",
"AIR_TEMPERATURE_SENSOR":"Air temp sensor",
"HUMIDITY_SENSOR":"Humidity sensor",
"ANTIFREEZE":"Antifreeze",
"IAQ_SENSOR":"iAQ sensor",
"AIR_TEMPERATURE_AND_HUMIDITY_SENSOR":"Air temp and humidity sensor",
"WOOD_FLOOR":"Wood floor",
"HIGH_UMIDITY":"High umidity",
"TEMPERATURE_PROBE":"Temperature probe",
"HIGH_TEMPERATURE":"High temp"
}

    <!--<ion-backdrop></ion-backdrop>-->
    <div class="alert-wrapper noSchModal">
      <div class="alert-head">
        <h2  class="alert-title" *ngIf="title" [innerHTML]="title"></h2>
        
      </div>
       <ion-list class="selectList"  *ngIf="options.length==1 && options[0].value==-1" >
          <ion-item class="no-lines" >
            <ion-label class="alert-message" [innerHTML]="message"></ion-label>
          </ion-item>
       </ion-list>
     
     


           
      <ion-list class="selectList" *ngIf="options.length>1">
        <ion-radio-group (ionChange)="onChangeRadioGroup($event)">
          
          <ion-item *ngFor="let option of options" > 
            <ion-label>{{ option.text }}</ion-label>
            <ion-radio slot="start" (click)="changeType(option)" [ngModelOptions]="{standalone: true}" ngDefaultControl  [checked]="scheduleId == option.value"></ion-radio>
          </ion-item>

         
        </ion-radio-group>
      </ion-list>


         

         




   <div class="alert-button-group" *ngIf="options.length>1">
    
        <button category="alert-button" *ngFor="let b of buttons" (click)="btnClick(b)" class="alert-button" [innerHTML]="b.text">
         
        </button>
     
    </div>   

    <div class="alert-button-group" *ngIf="options.length==1 && options[0].value==-1">
    
        <button category="alert-button" *ngFor="let b of buttonsEmpty" (click)="btnClick(b)" class="alert-button" [innerHTML]="b.text">
         
        </button>
     
    </div>
    </div>
import {Component, Input, OnInit} from '@angular/core';
import {AUTO_MODE_STYLE, GRP, MCU, MCZ, RMS, SCH} from '../../models/tables';
import {Attributes} from '../../models/attributes';
import {RMS_Extended} from '../../models/tables-extended';
import {DUAL_MODE, SchedulationType, Season} from '../../models/config';
import {ZonePage} from "../../pages/zone/zone.page";
import {BitArray} from "../../commons/bitarray";

@Component({
    selector: 'setpoint',
    templateUrl: './setpoint.component.html',
    styleUrls: ['./setpoint.component.scss'],
})
export class SetpointComponent implements OnInit {

    @Input()
    rms: RMS;

    @Input()
    season: number;

    @Input()
    mcz: MCZ;

    @Input()
    state: boolean;

    @Input()
    attrs: Attributes;

    @Input()
    MCU: MCU;

    @Input()
    sch: SCH;

    @Input()
    GRP: GRP[];

    @Input()
    page: ZonePage;

    @Input()
    rms_extended: RMS_Extended;

    public DUAL_MODE = DUAL_MODE;
    public SchedulationType = SchedulationType;

    constructor() {
    }

    ngOnInit() {
    }

    isAutoModeStyleDualSetpoint() {
        return this.GRP[this.rms?.CFG_IdxGRP]?.PAR_AutoModeStyle == AUTO_MODE_STYLE.DUAL_SETPOINT && this.GRP[this.rms?.CFG_IdxGRP]?.PAR_Season != 0 && this.GRP[this.rms?.CFG_IdxGRP]?.PAR_Season != 1 && this.GRP[this.rms?.CFG_IdxGRP]?.PAR_Season != -1;
    }


    computeMin(season: number, mcz: MCZ) {

        return this.page.computeMin(season, mcz, this.MCU, this.rms_extended, this.isAutoModeStyleDualSetpoint());
    }

    computeMax(season: number, mcz: MCZ) {

        return this.page.computeMax(season, mcz, this.MCU, this.rms_extended, this.isAutoModeStyleDualSetpoint());
    }

    onChangeDualSetpoints = (table: string, id: number, property: string, value: any, rms: RMS_Extended) => {

        this.page.supportService.onChangeDualSetpoints(this.MCU, table,id,property,value,rms, this.isAutoModeStyleDualSetpoint());
    }
}

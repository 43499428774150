<ion-app>

    <div *ngIf="showLoaderM()" class="centeredSplash">
        <img src="assets/700x700LoadingStandardIcon.png">
    </div>

    <!-- <div *ngIf="getVip() && getSplash() && isRootPage()" class="centeredSplash">
        <img src="assets/700x700LoadingVipIcon.png">
    </div> -->

    <div class="coverPage bg-spinner-grey" *ngIf="isRootPage()"></div>

    <ion-nav id="nav" swipe-gesture="false"></ion-nav>

    <ion-router-outlet animated="false"></ion-router-outlet>

    <ion-tabs id="ion-tabs" (ionTabsWillChange)="onTabsWillChange($event)">

        <ion-tab-bar *ngIf="userIsInsideASystem() && useBottomTabsNavigation && !alarmMode" slot="bottom" class="customTabBar backDrop"
                     [ngClass]="{'macro-zones-tabs': isMacroZonesTabs(), 'border-top-tabs': tabsHaveBorderTop()}">

            <ion-tab-button *ngIf="!zeroConfFromGuest" [disabled]="!this.connected || this.isReloadingNetwork" [class]="getTabClass(tabNames.SETTINGS)" tab="main-menu">
                <!--<ion-icon name="construct"></ion-icon>-->
                <i *ngIf="connected" class="tab-icon fa-solid fa-screwdriver-wrench"></i>
                <!--<ion-label>{{tabNames.SETTINGS}}</ion-label>-->
            </ion-tab-button>

            <ion-tab-button [disabled]="!this.connected || this.isReloadingNetwork" [class]="getTabClass(tabNames.HOME)" tab="home">
                <!--<ion-icon name="speedometer"></ion-icon>-->
                <i *ngIf="connected" class="tab-icon fa-solid fa-tachometer-alt"></i>
                <i *ngIf="!connected" class="tab-icon fa-solid fa-cloud-slash"></i>
                <!--<ion-label>{{tabNames.HOME}}</ion-label>-->
            </ion-tab-button>

            <ion-tab-button [disabled]="!this.connected || this.isReloadingNetwork" [class]="getTabClass(tabNames.ZONES)" tab="macro-zones">
                <!--<ion-icon name="apps"></ion-icon>-->
                <i *ngIf="connected" class="tab-icon fa-solid fa-grid-round-2"></i>
                <!--<ion-label>{{tabNames.ZONES}}</ion-label>-->
                <!-- <ion-badge>2</ion-badge> --> <!-- could be useful as notification badge, to display the number of zones, or anything else -->
            </ion-tab-button>

        </ion-tab-bar>
    </ion-tabs>
</ion-app>

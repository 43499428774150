import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Tables } from '../models/tables';
import { AppState } from '../services/app-state.service';

/*
 * Format a temperature
 */
@Pipe({name: 'temperature', pure: true })
export class TemperaturePipe implements PipeTransform {

   /* tables: Observable<Tables>;
    temperatureUnit: string = ''; */

    constructor(private store: Store<AppState>) {
        
       /* this.tables = store.select('tables') as Observable<Tables>,
        this.tables.subscribe(data => {
            if (data.MCU.length) {
                this.temperatureUnit = data.MCU[0].PAR_UM == 0 ? 'F' : 'C'
            }
        });*/
    }

    transform(value: any, digits?: number): string {
        if (value === null || value === undefined || isNaN(value) || value == -3276.8 || value == '-3276.8') {
           // return '--.-' + ' '+ 'º' + this.temperatureUnit;
            return '--.-' + 'º';
        }
        if (typeof value !== 'number') {
            value = Number.parseFloat('' + value);
        }
        if (digits === null || digits === undefined) {
            digits = 1;
        }
        //return value.toFixed(digits) + ' ' + 'º' + this.temperatureUnit;
        return value.toFixed(digits) + 'º';
    }

}

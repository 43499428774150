import {trigger, state, style, transition, animate} from '@angular/animations';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    Input,
    QueryList,
} from '@angular/core';
import {AccordionItem} from './directives/accordion-item.directive';
import * as _ from 'lodash';
import {map} from 'rxjs/operators';
import {merge, of} from 'rxjs';

@Component({
    selector: 'accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('contentExpansion', [
            state('expanded', style({height: '*', opacity: 1, visibility: 'visible'})),
            state('collapsed', style({height: '0px', opacity: 0, visibility: 'hidden'})),
            transition('expanded <=> collapsed', animate('200ms cubic-bezier(.37,1.04,.68,.98)')),
        ]),
    ],
})
export class AccordionComponent implements AfterViewInit {
    expanded = new Set<number>();
    /**
     * Decides if the single item will be open at once or not.
     * In collapsing mode, toggling one would collapse others
     */
    @Input() collapsing = true;
    @Input() currentAutoModeStyle: number;

    // if it's true -> the "toggleState" logic won't be executed, and the collapse of an item is calculated inside "updateItems" based on a variable (for example: currentAutoModeStyle)
    @Input() variableControlled: boolean = false;

    @ContentChildren(AccordionItem) items: QueryList<AccordionItem>;

    constructor(private readonly cdr: ChangeDetectorRef) {
    }

    ngAfterViewInit() {

        this.updateItems(false);
    }

    ngOnChanges(inputs): void {

        if (inputs.currentAutoModeStyle?.currentValue >= 0 && !inputs.currentAutoModeStyle.firstChange) {

            this.updateItems(true);
        }
    }

    updateItems(fromAutoModeStyle) {

        merge(this.items.changes, of(this.items))
            .pipe(map(() => this.items.toArray()))
            .subscribe((items) => {

                if (fromAutoModeStyle) {

                    this.expanded.clear();
                }

                for (let i = 0; i < items.length; i++) {

                    if (fromAutoModeStyle) {

                        // I expand (for example) Deadbands only if the selected value is 0 (which is Deadbands)
                        items[i].expanded = items[i].itemAutoModeStyle === this.currentAutoModeStyle;
                    }

                    if (items[i].expanded) {

                        // console.log('expanded: ', i);
                        this.expanded.add(i);
                    }
                }

                this.cdr.detectChanges();
            });
    }

    /**
     * Make the toggle function available to be called from
     * outside.
     * Memoize to prevent extra calls
     * @param index - index of the accordion item
     */
    getToggleState = _.memoize((index: number) => {
        return this.toggleState.bind(this, index);
    });

    toggleState = (index: number) => {

        if (!this.variableControlled) {

            if (this.expanded.has(index)) {

                this.expanded.delete(index);

            } else {

                if (this.collapsing) {

                    this.expanded.clear();
                }

                this.expanded.add(index);
            }
        }
    };
}

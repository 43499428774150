import {Injectable} from '@angular/core';
import {EMPTY, Observable, of} from 'rxjs';
import {Action} from '@ngrx/store';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {BackendCompliantRelease200, BackendNotCompliantRelease200, DisconnectConfig} from '../actions/config.actions';

import {NetworkWay} from '../models/http-status';
import {ReloadingNetworkEnd} from '../actions/http-status.actions';
import {AppState} from '../services/app-state.service';
import {SocketActions, SocketActionTypes, TableLoaded} from '../actions/socket.actions';
import {map, catchError, tap, takeUntil, mergeMap} from 'rxjs/operators';
import {SocketService} from '../services/socket.service';
import {HttpStatusService} from '../services/http-status.service';
import {Store} from '@ngrx/store';

@Injectable()
export class SocketEffects {

    constructor(private actions$: Actions<SocketActions>,
                private httpStatusService: HttpStatusService,
                private socketService: SocketService,
                protected store: Store<AppState>) {
    }

    @Effect()
    tableLoaded$: Observable<any> = this.actions$.pipe(
        ofType(SocketActionTypes.TableLoaded),

        mergeMap(action => {

                return this.socketService.registerEventListener(action['table']);
            }
        )
    );

    @Effect()
    connected$: Observable<any> = this.actions$.pipe(
        ofType(SocketActionTypes.Connected),
        //mi serve un effetto quando la socket si connette per modificare lo stato della app a connessa, nel senso che ha finito di fare il reboot

        mergeMap(action => {
                // qui reloadingNetwork va messo a false
                return of(new ReloadingNetworkEnd());
            }
        )
    );

    @Effect()
    disconnected$: Observable<any> = this.actions$.pipe(
        ofType(SocketActionTypes.Disconnected),
        //quando e se la app avrà una modalità full-offline, e quindi senza l'appoggio di un portale, come recupererò l'ip dopo un cambio verso dhcp?
        //magari forzando il logout, per il momento questa soluzione funziona
        //tap(dis=>{console.log('disconnessione beccata, provo a forzare la connessione in modalità remota ')}),
        map(action => {
            return this.httpStatusService.resetNetUpdated();
        }),

        mergeMap(action => {

                //la resync connection partirà solo se reloadingNetwork vale true
                return this.httpStatusService.resyncConnection(true);
            }
        )
    );

    @Effect()
    checkRemoteConnectionState$: Observable<any> = this.actions$.pipe(
        ofType(SocketActionTypes.CheckRemoteConnectionState),

        mergeMap(action => {

                return this.socketService.checkRemoteConnectionState();
            }
        )
    );

    @Effect()
    checkRemoteConnectionStateSuccess$: Observable<any> = this.actions$.pipe(
        ofType(SocketActionTypes.CheckRemoteConnectionStateSuccess),

        tap(async () => {

            await this.socketService.showToastReconnected();
        }),

        mergeMap((value) => {

            return EMPTY;
        })
    );

    @Effect()
    checkRemoteConnectionStateFail$: Observable<any> = this.actions$.pipe(
        ofType(SocketActionTypes.CheckRemoteConnectionStateFail),

        tap(async () => {

            await this.socketService.noInternetConnectionRedirect();
        }),

        mergeMap((value) => {

            return EMPTY;
        })
    );
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'calibration-start-text',
  templateUrl: './calibration-start-text.component.html',
  styleUrls: ['./calibration-start-text.component.scss'],
})
export class CalibrationStartTextComponent implements OnInit {


  @Input() showStartingText:boolean;
  @Output() showStartingTextChange = new EventEmitter<boolean>();

  constructor() {  /*console.log(`%cconstructor() di CalibrationStartTextComponent`,'font-size:1.2rem;color:red;')*/}

 ngOnInit(){

  // console.log(`%cngOnInit di CalibrationStartTextComponent`,'font-size:1.2rem;color:red;')
 }

}

import {Component, Input, OnInit} from '@angular/core';
import {TMR} from "../../models/tables";

@Component({
  selector: 'timer-icon',
  templateUrl: './timer-icon.component.html',
  styleUrls: ['./timer-icon.component.scss'],
})
export class TimerIconComponent implements OnInit {

  @Input() tmr:TMR;

  constructor() { }

  ngOnInit() {}

}

import {Platform, ActionSheetController, AlertController, ToastController, ModalController} from '@ionic/angular';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';

import {AppState} from '../../services/app-state.service';
import {AbstractConfigPage} from '../abstract-config-page';
import {ChangeTableValues} from '../../actions/tables.actions';
import {SCH, ARS, DHW, GRP, MCZ, TMR, RMS, FNC, ATU} from '../../models/tables';
import {BitArray} from '../../commons/bitarray';
import {DaysSelectorComponent} from './days-selector/days-selector.component';
import {DUAL_MODE, minDifferenceHAndCDualVariable, SchedulationType} from '../../models/config';
import * as _ from 'lodash';
import * as moment from 'moment';
import 'moment/locale/it';

export interface Day {
    index: number;
    text: string;
    schedule: BitArray;
    temperatures: number[];
    temperaturesH: number[];
    temperaturesC: number [];
}

export interface RangeColorCell {
    temperature: number;
    color: string;
    used: boolean;
    selectedColor: string;
}

export interface UsedSchedulation {
    table: string;
    id: number;
    name: string;
    mode?: number;
}

export interface UnusedSchedulation {
    table: string;
    id: number;
    name: string;
    fieldToReset: string,
    mode?: number;
}

export const NO_TEMPERATURE = -32768;

/**
 * Return an array of the used schedulation indexes
 */

export function getUsedSchedulations(ATU: ATU[], ARS: ARS[], DHW: DHW[], GRP: GRP[], MCZ: MCZ[], TMR: TMR[], RMS: RMS[], FNC: FNC[]): UsedSchedulation[][] {

    const result: UsedSchedulation[][] = [];

    function setResult(idx: number, active: boolean, table: string, id: number, name: string, mode?: number) {

        //console.log(`setResult idx ${idx} active ${active} table ${table} id ${id} name ${name} mode ${mode}`);
        if (idx !== null && idx !== undefined && idx != -1 && active && table != 'TMR') {

            if (!result[idx]) {

                result[idx] = [];
            }

            //console.log(`result[${idx}].push({table:${table},id:${id}, name:${name}, mode: ${mode}})`)
            result[idx].push({table: table, id: id, name: name, mode: mode})
        }

        if (idx !== null && idx !== undefined && idx != -1 && active && table == 'TMR' && mode == 1) {

            if (!result[idx]) {

                result[idx] = []
            }

            //console.log(`result[${idx}].push({table:${table},id:${id}, name:${name}, mode: ${mode}})`)
            result[idx].push({table: table, id: id, name: name, mode: mode})
        }
    }

    _.each(ARS, function (ars: ARS) {

        const atu = _.find(ATU, x => x.CFG_IdxARS == ars.id);
        setResult(ars.PAR_IdxSCH_Fc, ars.PAR_FcSchedOn, "ARS", ars.id, atu.CFG_Name);
        setResult(ars.PAR_IdxSCH_Hrv, ars.PAR_HrvSchedOn, "ARS", ars.id, atu.CFG_Name);
        setResult(ars.PAR_IdxSCH_Hum, ars.PAR_HumSchedOn, "ARS", ars.id, atu.CFG_Name);
        setResult(ars.PAR_IdxSCH_Ntd, ars.PAR_NtdSchedOn, "ARS", ars.id, atu.CFG_Name);
    });

    _.each(DHW, function (dhw: DHW) {

        setResult(dhw.PAR_IdxSCH_R, dhw.PAR_TempSchedOn, "DHW", dhw.id, dhw.CFG_Name);
        setResult(dhw.PAR_IdxSCH_T, dhw.PAR_RecSchedOn, "DHW", dhw.id, dhw.CFG_Name);
    });

    _.each(GRP, function (grp: GRP) {

        setResult(grp.PAR_IdxSCH, true, "GRP", grp.id, grp.CFG_Name);
    });

    _.each(MCZ, function (mcz: MCZ) {

        if (mcz.CFG_Valid) {

            if (mcz.id == 0) {
                setResult(mcz.PAR_IdxSCH_C, mcz.PAR_SchedOnC, "MCZ", 0, "All", 1);
                setResult(mcz.PAR_IdxSCH_H, mcz.PAR_SchedOnH, "MCZ", 0, "All", 0);
            } else {
                setResult(mcz.PAR_IdxSCH_C, mcz.PAR_SchedOnC, "MCZ", mcz.id, mcz.CFG_Name, 1);
                setResult(mcz.PAR_IdxSCH_H, mcz.PAR_SchedOnH, "MCZ", mcz.id, mcz.CFG_Name, 0);
            }
        }
    });

    _.each(RMS, function (rms: RMS) {

        setResult(rms.PAR_IdxSCH_C, rms.PAR_SchedOnC, "RMS", rms.id, rms.CFG_Name, 1);
        setResult(rms.PAR_IdxSCH_H, rms.PAR_SchedOnH, "RMS", rms.id, rms.CFG_Name, 0);
    });

    _.each(TMR, function (tmr: TMR) {

        setResult(tmr.PAR_IdxSCH, true, "TMR", tmr.id, tmr.CFG_Name, tmr.PAR_Mode);
    });

    _.each(FNC, function (fnc: FNC) {
        setResult(fnc.PAR_IdxSCH_HRV, fnc.PAR_SchedOn, "FNC", fnc.id, fnc.CFG_Name);
    });

    return result;
}


/**
 * Return an array of the unassigned schedulation indexes
 */
export function getUnassignedSchedulations(ATU: ATU[], ARS: ARS[], DHW: DHW[], GRP: GRP[], MCZ: MCZ[], TMR: TMR[], RMS: RMS[], FNC: FNC[]): UnusedSchedulation[][] {

    const result: UnusedSchedulation[][] = [];

    function setResult(idx: number, active: boolean, table: string, id: number, name: string, toReset: string, mode?: number) {

        // console.log(`setResult idx ${idx} active ${active} table ${table} id ${id} name ${name} toReset ${toReset} mode ${mode}`);
        if (idx !== null && idx !== undefined && idx != -1 && !active) {

            if (!result[idx]) {

                result[idx] = []
            }

            // console.log(`result[${idx}].push({table:${table},id:${id}, name:${name}, mode: ${mode},fieldToReset:${toReset}})`)
            result[idx].push({table: table, id: id, name: name, mode: mode, fieldToReset: toReset})
        }
    }

    _.each(ARS, function (ars: ARS) {

        const atu = _.find(ATU, x => x.CFG_IdxARS == ars.id);
        setResult(ars.PAR_IdxSCH_Fc, ars.PAR_FcSchedOn, "ARS", ars.id, atu.CFG_Name, 'PAR_IdxSCH_Fc');
        setResult(ars.PAR_IdxSCH_Hrv, ars.PAR_HrvSchedOn, "ARS", ars.id, atu.CFG_Name, 'PAR_IdxSCH_Hrv');
        setResult(ars.PAR_IdxSCH_Hum, ars.PAR_HumSchedOn, "ARS", ars.id, atu.CFG_Name, 'PAR_IdxSCH_Hum');
        setResult(ars.PAR_IdxSCH_Ntd, ars.PAR_NtdSchedOn, "ARS", ars.id, atu.CFG_Name, 'PAR_IdxSCH_Ntd');
    });

    _.each(DHW, function (dhw: DHW) {
        setResult(dhw.PAR_IdxSCH_R, dhw.PAR_TempSchedOn, "DHW", dhw.id, dhw.CFG_Name, 'PAR_IdxSCH_Re');
        setResult(dhw.PAR_IdxSCH_T, dhw.PAR_RecSchedOn, "DHW", dhw.id, dhw.CFG_Name, 'PAR_IdxSCH_T');
    });

    _.each(GRP, function (grp: GRP) {
        setResult(grp.PAR_IdxSCH, true, "GRP", grp.id, grp.CFG_Name, 'PAR_IdxSCH');
    });

    _.each(MCZ, function (mcz: MCZ) {

        if (mcz.CFG_Valid) {

            if (mcz.id == 0) {
                setResult(mcz.PAR_IdxSCH_C, mcz.PAR_SchedOnC, "MCZ", 0, "All", 'PAR_IdxSCH_C', 1);
                setResult(mcz.PAR_IdxSCH_H, mcz.PAR_SchedOnH, "MCZ", 0, "All", 'PAR_IdxSCH_H', 0);
            } else {
                setResult(mcz.PAR_IdxSCH_C, mcz.PAR_SchedOnC, "MCZ", mcz.id, mcz.CFG_Name, 'PAR_IdxSCH_C', 1);
                setResult(mcz.PAR_IdxSCH_H, mcz.PAR_SchedOnH, "MCZ", mcz.id, mcz.CFG_Name, 'PAR_IdxSCH_H', 0);
            }
        }
    });

    _.each(RMS, function (rms: RMS) {

        setResult(rms.PAR_IdxSCH_C, rms.PAR_SchedOnC, "RMS", rms.id, rms.CFG_Name, 'PAR_IdxSCH_C', 1);
        setResult(rms.PAR_IdxSCH_H, rms.PAR_SchedOnH, "RMS", rms.id, rms.CFG_Name, 'PAR_IdxSCH_H', 0);
    });

    _.each(TMR, function (tmr: TMR) {

        setResult(tmr.PAR_IdxSCH, true, "TMR", tmr.id, tmr.CFG_Name, 'PAR_IdxSCH');
    });

    _.each(FNC, function (fnc: FNC) {

        setResult(fnc.PAR_IdxSCH_HRV, fnc.PAR_SchedOn, "FNC", fnc.id, fnc.CFG_Name, 'PAR_IdxSCH_HRV');
    });

    return result;
}

export function checkDualVariableMinTempDifferenceHAndReturnIt(temperature, sch, day, i) {

    const temperatureIsValid = temperature !== NO_TEMPERATURE && temperature !== undefined && temperature !== null;
    const coolingScheduleAlsoPresent = sch.PAR_TempSchedC[day * 48 + i] !== NO_TEMPERATURE && sch.PAR_TempSchedC[day * 48 + i] !== undefined && sch.PAR_TempSchedC[day * 48 + i] !== null;

    // console.log('ok sto mettendo heating');
    // console.log('temperature valid: ' + temperatureIsValid, temperature);
    // console.log('sch?.PAR_TempSchedC[day * 48 + i]', sch.PAR_TempSchedC[day * 48 + i]);

    // If the heating temperature is (for example) 69 and the cooling temperature is 70: I set the heating temperature as 70-2 (68).
    if (temperatureIsValid && coolingScheduleAlsoPresent && temperature > sch.PAR_TempSchedC[day * 48 + i] - minDifferenceHAndCDualVariable) {

        // console.log('OK MODIFICO!');
        temperature = sch.PAR_TempSchedC[day * 48 + i] - minDifferenceHAndCDualVariable;
    }

    return temperature;
}

export function checkDualVariableMinTempDifferenceCAndReturnIt(temperature, sch, day, i) {

    const temperatureIsValid = temperature !== NO_TEMPERATURE && temperature !== undefined && temperature !== null;
    const heatingScheduleAlsoPresent = sch?.PAR_TempSchedH[day * 48 + i] !== NO_TEMPERATURE && sch?.PAR_TempSchedH[day * 48 + i] !== undefined && sch?.PAR_TempSchedH[day * 48 + i] !== null;

    // console.log('ok sto mettendo cooling');
    // console.log('temperature valid: ' + temperatureIsValid, temperature);
    // console.log('sch?.PAR_TempSchedH[day * 48 + i]', sch?.PAR_TempSchedH[day * 48 + i]);

    // If the cooling temperature is (for example) 71 and the heating temperature is 70: I set the cooling temperature as 70+2 (72).
    if (temperatureIsValid && heatingScheduleAlsoPresent && temperature < sch?.PAR_TempSchedH[day * 48 + i] + minDifferenceHAndCDualVariable) {

        // console.log('OK MODIFICO!');
        temperature = sch.PAR_TempSchedH[day * 48 + i] + minDifferenceHAndCDualVariable;
    }

    return temperature;
}


export class AbstractSchedulationCommons extends AbstractConfigPage {

    // schedule id
    id: number;
    // schedulations
    SCH: SCH[];

    constructor(protected store: Store<AppState>,
                protected platform: Platform,
                protected alertController: AlertController,
                protected actionSheetController: ActionSheetController,
                protected translate: TranslateService,
                protected modalController: ModalController,
                protected ToastController?: ToastController) {

        super(store, platform, alertController, actionSheetController, translate, ToastController);
    }

    /**
     * Popup for copying the schedulation
     */
    async copySchedulationPopup(day: number, $event?, callback?, dualSetpointSelection?: DUAL_MODE) {

        if ($event) {
            $event.stopPropagation();
        }

        let inputs = [];
        inputs.push({
            type: 'checkbox',
            label: this.translate.instant('ALL_DAYS'),
            value: -1,
            checked: false
        });
        for (let index = 0; index < 7; index++) {
            inputs.push({
                type: 'checkbox',
                label: moment().locale(this.config.language).day(index).format('dddd'),
                value: index,
                checked: false,
                disabled: index == day
            });
        }

        // console.log("lancio modale copy day, loggo vettore inputs");
        // console.log(inputs);


        const modal = await this.modalController.create({

            component: DaysSelectorComponent,
            cssClass: 'daySelectorModal',
            componentProps: {
                'enabled': true,
                'title': this.translate.instant('COPY_DAY_TITLE'),
                'message': this.translate.instant('COPY_DAY_TO'),
                inputs: inputs,
                buttons: [
                    {
                        text: this.translate.instant('SAVE'),
                        handler: ids => {

                            if (ids.length > 0) {

                                const schedule = this.SCH[this.id].PAR_Schedule;

                                let temperatures = this.SCH[this.id].PAR_TempSched;
                                let temperaturesH = this.SCH[this.id].PAR_TempSchedH;
                                let temperaturesC = this.SCH[this.id].PAR_TempSchedC;

                                for (let index of ids) {

                                    let dayTo = Number.parseInt(index);

                                    // schedulation
                                    schedule[dayTo] = schedule[day];

                                    // temperature (Variable)
                                    if (this.SCH[this.id].CFG_Type === SchedulationType.Variable) {

                                        for (let i = 0; i < 48; i++) {

                                            temperatures[dayTo * 48 + i] = temperatures[day * 48 + i];
                                        }
                                    }

                                    // temperature (DualVariable)
                                    if (this.SCH[this.id].CFG_Type === SchedulationType.DualVariable) {

                                        for (let i = 0; i < 48; i++) {

                                            switch (dualSetpointSelection) {

                                                case DUAL_MODE.heating:

                                                    temperaturesH[dayTo * 48 + i] = temperaturesH[day * 48 + i];
                                                    temperaturesH[dayTo * 48 + i] = checkDualVariableMinTempDifferenceHAndReturnIt(temperaturesH[dayTo * 48 + i], this.SCH[this.id], dayTo, i);
                                                    break;

                                                case DUAL_MODE.cooling:

                                                    temperaturesC[dayTo * 48 + i] = temperaturesC[day * 48 + i];
                                                    temperaturesC[dayTo * 48 + i] = checkDualVariableMinTempDifferenceCAndReturnIt(temperaturesC[dayTo * 48 + i], this.SCH[this.id], dayTo, i);
                                                    break;

                                                case DUAL_MODE.heatingAndCooling:

                                                    temperaturesH[dayTo * 48 + i] = temperaturesH[day * 48 + i];
                                                    temperaturesC[dayTo * 48 + i] = temperaturesC[day * 48 + i];
                                                    break;
                                            }
                                        }
                                    }
                                }

                                const values = {PAR_Schedule: schedule, PAR_TempSched: temperatures, PAR_TempSchedH: temperaturesH, PAR_TempSchedC: temperaturesC};
                                this.store.dispatch(new ChangeTableValues('SCH', this.id, values));

                                if (callback) {

                                    callback();
                                }
                            }

                            return ids.length == 0
                        }
                    },
                    {
                        text: this.translate.instant('CANCEL')

                    }

                ]
            }
        });

        modal.onWillDismiss().then(async (retParam: any) => {

            // const { data } = retParam;
        });

        return await modal.present();
    }

    /**
     * Confirm for resetting
     */
    resetSchedulationPopup = (day: number, $event?, callback?, heatingOrCoolingDualMode?: DUAL_MODE): void => {

        this.confirmPopup($event, 'RESET', 'RESET_SCHEDULATION', 'RESET', () => this.resetSchedulation(day, $event, callback, heatingOrCoolingDualMode));
    };

    /**
     * Reset the schedulation for the given day
     */
    resetSchedulation = (day: number, $event?, callback?, heatingOrCoolingDualMode?: DUAL_MODE): void => {

        const sch = this.SCH[this.id];

        if (day == -1) { // all days

            this.confirmOrErasePAR_Schedule(sch, 0, 6, 0, 47, true, heatingOrCoolingDualMode);
            this.confirmOrErasePAR_TempScheds(sch, 'PAR_TempSched', 0, 6, 0, 47, true, heatingOrCoolingDualMode, null)

        } else {

            this.confirmOrErasePAR_Schedule(sch, day, day, 0, 47, true, heatingOrCoolingDualMode);
            this.confirmOrErasePAR_TempScheds(sch, 'PAR_TempSched', day, day, 0, 47, true, heatingOrCoolingDualMode, null)
        }

        const values = {PAR_Schedule: sch.PAR_Schedule, PAR_TempSched: sch.PAR_TempSched, PAR_TempSchedH: sch.PAR_TempSchedH, PAR_TempSchedC: sch.PAR_TempSchedC};
        this.store.dispatch(new ChangeTableValues('SCH', this.id, values));

        if (callback) {

            callback();
        }
    };

    confirmOrErasePAR_Schedule(sch: SCH, dayFrom: number, dayTo: number, hourFrom: number, hourTo: number, isErase: boolean, dualSetpointSelection: DUAL_MODE) {

        const parScheduleBitArraySetValue = !isErase; // if I pass "isErase" as true, I want the set to set the bitArray as false (reset)

        for (let day = dayFrom; day <= dayTo; day++) {

            const bitarray = new BitArray(48).fromNumber(sch.PAR_Schedule[day] || 0);

            for (let i = hourFrom; i <= hourTo; i++) {

                // In a DualVariable, I may not always cancel a schedule during an erase. If, for example I've selected "heating mode", and I'm cancelling a section which has both heating and cooling, only the H temperatures will be reset, but there will still be a schedule (the C one)
                if (isErase && sch.CFG_Type === SchedulationType.DualVariable && dualSetpointSelection !== DUAL_MODE.heatingAndCooling) { // If I've heating & cooling selected, I'm sure I will just reset everything

                    // If I'm resetting H temperatures / schedule -> "PAR_Schedule" will be reset only if there isn't a C temperature too (otherwise I keep it, since there is still a C schedule / temperature)
                    if (dualSetpointSelection === DUAL_MODE.heating && (sch.PAR_TempSchedC[day * 48 + i] === NO_TEMPERATURE || sch.PAR_TempSchedC[day * 48 + i] === null && sch.PAR_TempSchedC[day * 48 + i] === undefined)) {

                        bitarray.set(i, parScheduleBitArraySetValue);
                    }

                    // If I'm resetting C temperatures / schedule -> "PAR_Schedule" will be reset only if there isn't a H temperature too (otherwise I keep it, since there is still a H schedule / temperature)
                    if (dualSetpointSelection === DUAL_MODE.cooling && (sch.PAR_TempSchedH[day * 48 + i] === NO_TEMPERATURE || sch.PAR_TempSchedH[day * 48 + i] === null && sch.PAR_TempSchedH[day * 48 + i] === undefined)) {

                        bitarray.set(i, parScheduleBitArraySetValue);
                    }
                } else {

                    bitarray.set(i, parScheduleBitArraySetValue);
                }
            }

            sch.PAR_Schedule[day] = bitarray.toNumber();
        }
    }

    confirmOrErasePAR_TempScheds(sch: SCH, property: string, dayFrom: number, dayTo: number, hourFrom: number, hourTo: number, isErase: boolean, dualSetpointSelection, selectedVariableTemperature?, dualSetpointButtonClicked?) {

        if (sch.CFG_Type === SchedulationType.Variable || sch.CFG_Type === SchedulationType.DualVariable) {

            let temperature = isErase ? NO_TEMPERATURE : selectedVariableTemperature;

            for (let day = dayFrom; day <= dayTo; day++) {

                for (let i = hourFrom; i <= hourTo; i++) {

                    // in DualVariable schedules, I may only erase heating or cooling parts, if I've selected only H or C as selection
                    if (isErase && sch.CFG_Type === SchedulationType.DualVariable) {

                        switch (dualSetpointSelection) {

                            case DUAL_MODE.heating:

                                sch.PAR_TempSchedH[day * 48 + i] = temperature; // DualVariable (erase H)
                                break;

                            case DUAL_MODE.cooling:

                                sch.PAR_TempSchedC[day * 48 + i] = temperature; // DualVariable (erase C)
                                break;

                            case DUAL_MODE.heatingAndCooling:

                                sch.PAR_TempSchedH[day * 48 + i] = temperature;
                                sch.PAR_TempSchedC[day * 48 + i] = temperature;
                                break;
                        }
                    }

                    else {

                        // I check that if I'm putting both a heating schedule and a cooling schedule in the same time slot, there will always be a 2° difference
                        if (sch.CFG_Type === SchedulationType.DualVariable) {

                            // When I set a temperature: "dualSetpointButtonClicked" is which button I clicked (orange or blue), so it means: am I setting a heating or cooling schedule?
                            switch (dualSetpointButtonClicked) {

                                case DUAL_MODE.heating:

                                    temperature = checkDualVariableMinTempDifferenceHAndReturnIt(temperature, sch, day, i);
                                    break;

                                case DUAL_MODE.cooling:

                                    temperature = checkDualVariableMinTempDifferenceCAndReturnIt(temperature, sch, day, i);
                                    break;
                            }
                        }

                        sch[property][day * 48 + i] = temperature; // Variable (confirm or erase) or DualVariable (confirm)
                    }
                }
            }
        }
    }
}



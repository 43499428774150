import {Component, Input, OnInit} from '@angular/core';
import {IconsComponent} from "../icons.component";

@Component({
  selector: 'alarm-icons',
  templateUrl: './alarm-icons.component.html',
  styleUrls: ['./alarm-icons.component.scss'],
})

export class AlarmIconsComponent implements OnInit {

  @Input()
  IconsComponent: IconsComponent;

  constructor() { }

  ngOnInit() {}
}

<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <back-button-custom (click)="goBack()"></back-button-custom>
        </ion-buttons>

        <ion-buttons slot="end">

            <ng-container *ngIf="config.admin && mcz else noOpenMenuPresent">

                <button class="positionAbsolute go-home" (click)="goHome(router)">
                    <span class="far fa-tachometer-alt icon"></span>
                </button>

                <button (click)="openMenu()" [disabled]="!connected"
                        class="d-flex flex-row justify-content-center align-items-center more positionRelative">
                    <ion-icon name="more"></ion-icon>
                </button>

            </ng-container>

            <ng-template #noOpenMenuPresent>

                <button class="more" (click)="goHome(router)">
                    <span class="far fa-tachometer-alt icon"></span>
                </button>

            </ng-template>

        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-header class="two-lines-header">

    <ion-toolbar class="prev-next-toolbar">
        <ion-buttons slot="start">


            <button (click)="prev()" [disabled]="!connected || validMCZ.length==1"
                    class="disable-hover prev-next-chevron"
                    [ngClass]="{'messana-disabled':(prevId == -1) || validMCZ.length==1}">
                <i class="fal fa-chevron-circle-left"></i>
            </button>
        </ion-buttons>
        <ion-title *ngIf="mcz?.id!=0">{{ mcz?.CFG_Name }}</ion-title>
        <ion-title *ngIf="mcz?.id==0">All</ion-title>

        <ion-buttons slot="end">
            <button (click)="next()" [disabled]="!connected || validMCZ.length==1"
                    class="disable-hover prev-next-chevron"
                    [ngClass]="{'messana-disabled':(nextId == -1) || validMCZ.length==1}">
                <i class="fal fa-chevron-circle-right"></i>
            </button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="macro-zone" [ngClass]="{'searchBarActive':myZones && (myZones.length >10)}">

    <div class="spinner-container" *ngIf="!connected || loading">
        <div class="mac-spinner center cover">
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
        </div>
    </div>

    <!-- mcz header -->
    <ion-list *ngIf="connected && mcz && validMCZ && allMacrozoneChanging && !loading"
              class="macro-zone-controls w-100 d-block rounded-container-not-last">
        <div id="mcz" class="rounded-container margin_bottom_0_important">

            <div class="d-flex flex-row justify-content-between align-items-center w-100">
                <div class="d-flex flex-row justify-content-start align-items-center">
                    <div>
                        <ion-item class="no-lines">
                            <ion-toggle color="secondary" class="zone-main-toggle" [checked]="state"
                                        (ionChange)="stateUpdated($event)"></ion-toggle>
                        </ion-item>
                    </div>
                </div>
                <div>
                    <ion-item class="no-lines">
                        <icons [rms_extended]="mczExtended" [MCU_Par_On]="MCU.PAR_On"
                               [MCU]="MCU" [showPowerOff]="showPowerOff()" [ZON]="mczZones()" [config]="config"
                               [FNC]="myFancoils" [hasBlackBackground]="true"
                               class="align-right" [status]="mcz?.RTU_Flags || 0"></icons>
                    </ion-item>
                </div>
            </div>

            <!-- Setpoint start -->
            <macro-zone-setpoints [macroZonePage]="this"></macro-zone-setpoints>
            <!-- Setpoint end -->

            <!-- nuova versione -->
            <div class="zone-displayWidth d-flex flex-row align-items-center"
                 [ngClass]="{'disabled-item':!state, 'justify-content-between':!MCU?.TEC_Bioset || seasonExe == Season.Heating,'justify-content-center':MCU?.TEC_Bioset && seasonExe == Season.Cooling}">
                <div class="zone-operative-temperature">
                    <span class="fal fa-thermometer-half"></span>
                    <span *ngIf="!MCU?.TEC_Bioset || seasonExe == Season.Heating"
                          class="fixed-size-number">&nbsp;{{ mcz.RTU_AverageT | temperatureNoUnit }}</span>
                    <span *ngIf="MCU?.TEC_Bioset && seasonExe == Season.Cooling"
                          class="fixed-size-number">&nbsp;{{ mcz.getAverageTp(MCU) | temperatureNoUnit }}</span>

                </div>
                <div *ngIf="config?.humidity == 0 && (!MCU?.TEC_Bioset || seasonExe == Season.Heating)"
                     class="zone-humidity">
                    <span class="fal fa-tint"></span>&nbsp;<span
                        class="fixed-size-number" [innerHtml]="mcz.RTU_AverageRH | pct "></span>
                </div>

                <div *ngIf="config?.humidity == 1 && (!MCU?.TEC_Bioset || seasonExe == Season.Heating)"
                     class="zone-humidity">
                    <span class="fal fa-dewpoint"></span>
                    <span class="fixed-size-number">&nbsp;{{ mcz.RTU_AverageDP | temperatureNoUnit }}</span>
                </div>
            </div><!-- chiudo il flex -->


            <div class="positionRelative">
                <schedule
                        [isAutoModeStyleDualSetpoint]="isAutoModeStyleDualSetpoint()"
                        [class]="getScheduleStyle()" [usePopupIsOpenedCheck]="true" (stopSync)="stopLoadCallback()"
                        (openSelection)="scheduleWindow=true"
                        (closeSelection)="scheduleWindow=false" [changingPage]="changingPage"
                        [ngClass]="{'narrow':sch && ((season == 0 && mcz?.PAR_IdxSCH_H != -1 && mcz?.PAR_SchedOnH ) || (season == 1 && mcz?.PAR_IdxSCH_C != -1 && mcz?.PAR_SchedOnC ))}"
                        *ngIf="MCU?.TEC_SPMode" [disabled]="!state" table="MCZ" [item]="mcz" idxVar="PAR_IdxSCH_H"
                        [MCU]="MCU" [SCH]="SCH" (whileChange)="onScheduleChange($event)" [season]="Season.Heating"
                        [disableDispatch]="false" toggle="PAR_SchedOnH">

                </schedule>

                <!-- da testare -->
                <schedule
                        [isAutoModeStyleDualSetpoint]="isAutoModeStyleDualSetpoint()"
                        [class]="getScheduleStyle()" [usePopupIsOpenedCheck]="true" (stopSync)="stopLoadCallback()"
                        (openSelection)="scheduleWindow=true"
                        (closeSelection)="scheduleWindow=false" [changingPage]="changingPage"
                        [ngClass]="{'narrow':sch && ((season == 0 && mcz?.PAR_IdxSCH_H != -1 && mcz?.PAR_SchedOnH ) || (season == 1 && mcz?.PAR_IdxSCH_C != -1 && mcz?.PAR_SchedOnC ))}"
                        *ngIf="season == 0 && !MCU?.TEC_SPMode" [disabled]="!state" table="MCZ" [item]="mcz"
                        idxVar="PAR_IdxSCH_H" [MCU]="MCU" [SCH]="SCH" (whileChange)="onScheduleChange($event)"
                        [season]="Season.Heating" [disableDispatch]="false" toggle="PAR_SchedOnH">

                </schedule>

                <!-- da testare -->
                <schedule
                        [isAutoModeStyleDualSetpoint]="isAutoModeStyleDualSetpoint()"
                        [class]="getScheduleStyle()" [usePopupIsOpenedCheck]="true" (stopSync)="stopLoadCallback()"
                        (openSelection)="scheduleWindow=true"
                        (closeSelection)="scheduleWindow=false" [changingPage]="changingPage"
                        [ngClass]="{'narrow':sch && ((season == 0 && mcz?.PAR_IdxSCH_H != -1 && mcz?.PAR_SchedOnH ) || (season == 1 && mcz?.PAR_IdxSCH_C != -1 && mcz?.PAR_SchedOnC ))}"
                        *ngIf="season == 1 && !MCU?.TEC_SPMode" [disabled]="!state" table="MCZ" [item]="mcz"
                        idxVar="PAR_IdxSCH_C" [MCU]="MCU" [SCH]="SCH" (whileChange)="onScheduleChange($event)"
                        [season]="Season.Cooling" [disableDispatch]="false" toggle="PAR_SchedOnC">
                </schedule>
            </div>

            <!-- inizio schedule  preview del collapse  MCZ -->
            <schedule-graph [currentDateTime]="currentDateTime"
                            [config]="config"
                            *ngIf="currentDateTime.dateTimeAsMoment && sch && state && ((season == 0 && mcz?.PAR_IdxSCH_H != -1 && mcz?.PAR_SchedOnH ) || (season == 1 && mcz?.PAR_IdxSCH_C != -1 && mcz?.PAR_SchedOnC))"

                            [schedule]="sch" [id]="1+'macro-zone'"></schedule-graph>

            <!-- fine schedule preview del collapse -->
            <ion-list *ngIf="MCU.PAR_ES == 1 && (mcz.id !=0 || allMacrozoneChanging) ">
                <ion-item>
                    <ion-label>
                        {{ 'ENERGY_SAVING' | translate }}
                    </ion-label>
                    <ion-toggle color="secondary" [(ngModel)]="esState" (ionChange)="esStateUpdated()"
                                [disabled]="!state"></ion-toggle>
                </ion-item>
            </ion-list>

            <ion-list *ngIf="showOWL && (mcz.id !=0 || mcz.id == 0 && allMacrozoneChanging) ">
                <ion-item>
                    <ion-label>
                        {{ 'OPEN_WINDOW_LOGIC' | translate }}
                    </ion-label>
                    <ion-toggle color="secondary" [(ngModel)]="owlState" [disabled]="!state"
                                (ionChange)="owlStateUpdated()"></ion-toggle>
                </ion-item>
            </ion-list>
        </div><!-- chiuso rounded-container -->
    </ion-list>

    <!-- fine mcz header -->
    <ion-list *ngIf="connected && dataLoaded && noRadiant && !loading"
              class="zone-list">
        <div class="rounded-container rounded-container-zone-list">
            <div *ngFor="let rms_extended of ( myZones | namefilter: query);let i = index; let last=last; let first=first; trackBy: trackById">
                <zone [dualSetPoint]="isAutoModeStyleDualSetpoint()"
                      (updateRealExceptionsH)="updateRealExceptionsH($event,i)"
                      (updateRealExceptionsC)="updateRealExceptionsC($event,i)"
                      [ZON]="rmsZones(rms_extended)" [first]="first" [FNC]="fncZones(rms_extended)"
                      (computed)="computedZone=true"
                      [macrozonepage]="true" [index]="i" [margins]="10" [ngClass]="{'first':first,'last':last}"
                      [last]="last" [hideExceptions]="!allMacrozoneChanging" [rms]="rms_extended"
                      [base]="allMacrozoneChanging" [grp]="GRP[rms_extended.CFG_IdxGRP]" [mcz]="mcz" [MCU]="MCU"
                      [SCH]="SCH"
                      [config]="config" [tiny]="mcz?.id != 0" (click)="showZone(rms_extended)"
                      [updateExceptions]="!exceptionWindow" (swipe)="swipe($event)"
                      [noRadiant]="noRadiant[i]"></zone>
                <div *ngIf="!last" class="bottomLine"></div>
            </div>
        </div>
    </ion-list>
</ion-content>

<ion-footer *ngIf="myZones?.length > 10" class="paddingForTabs"
            [ngClass]="{'backDrop tabs useBottomTabsNavigation':useBottomTabsNavigation}">
    <ion-toolbar [ngClass]="{'padding0': useBottomTabsNavigation}" class="bg-stained-white" position="bottom">
        <ion-searchbar class="bg-white" [(ngModel)]="query"></ion-searchbar>
    </ion-toolbar>
</ion-footer>

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'calibration-table',
  templateUrl: './calibration-table.component.html',
  styleUrls: ['./calibration-table.component.scss'],
})
export class CalibrationTableComponent implements OnInit {



  @Input()
  calibrations:any[];

  constructor() { }

  ngOnInit() {}

}

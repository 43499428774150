import { Injectable } from '@angular/core';
import {MenuAction} from "../components/abstract-base-component";
import {AlertController, ToastController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  private toast;

  constructor(private alertController: AlertController, private toastCtrl: ToastController, private translate: TranslateService) {

  }

  // -------------------------------------------------------------------------------------------------------------------

  async showCustomButtonsWarning(title: string, message: string, actions: MenuAction[]) {

    let buttons = [];

    for (let action of actions) {

      if (action.disabled != true) {

        buttons.push({
          text: this.translate.instant(action.text),
          handler: () => {
            let navTransition = alert.dismiss();
            navTransition.then(() => {
              action.handler();
            });
          },
          icon: action.icon,
          role: action.role,
          cssClass: action["cssClass"]
        });
      }
    }

    const alert = await this.alertController.create({
      cssClass: 'alert-yes-no',
      header: this.translate.instant(title),
      message: this.translate.instant(message),
      buttons: buttons,
      backdropDismiss: false
    });

    console.warn('displaying popup');
    await alert.present();
  }

  // -------------------------------------------------------------------------------------------------------------------

  async presentToast(toastMessage: string, time = 2000) {

    if (this.toast) {

      this.toast.dismiss();
    }

    this.toast = await this.toastCtrl.create({
      message: this.translate.instant(toastMessage),
      position: 'bottom',
      duration: time,
    });

    await this.toast.present();
  }

  async showErrorMessage(err,title?){
    console.log(`showErrorMessage`);
    const alert = await this.alertController.create({
      cssClass: 'alert-yes-no',
      header: (title ? this.translate.instant(title) : this.translate.instant('AUTH_ERROR_TITLE')),
      //message: this.translate.instant('LOGIN_ERROR_SUBTITLE'),
      message:this.translate.instant(err.error.message),
      buttons: ['OK']
    });
    await alert.present();
  }
}

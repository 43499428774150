import {Component, Input, OnInit} from '@angular/core';
import {FNC, MCU, RMS} from "../../models/tables";
import {ARS_Extended} from "../../models/tables-extended";
import {NTD, ZonePage} from "../../pages/zone/zone.page";
import {ChangeTableValues} from "../../actions/tables.actions";
import {Store} from "@ngrx/store";
import {AppState} from "../../services/app-state.service";
import {FlagsGroup, FlagsNTD} from "../../commons/flags";
import {Config} from "../../models/config";
import {ShowPowerOff} from "../tech/tech.component";
import {AlignIconToRadioButton} from "../../models/enum-misc";

@Component({
    selector: 'dehum-collapse',
    templateUrl: './dehum-collapse.component.html',
    styleUrls: ['./dehum-collapse.component.scss'],
})
export class DehumCollapseComponent implements OnInit {

    @Input() zonePage: ZonePage;

    @Input() extensible: boolean;
    @Input() fncDehumification: boolean;
    @Input() radiant: boolean;

    @Input() MCU: MCU;
    @Input() config: Config;
    @Input() showPowerOff: ShowPowerOff;
    @Input() rms: RMS;
    @Input() ars_extended: ARS_Extended;
    @Input() fnc: FNC;

    public dehumVisibility = false;
    public FlagsGroup = FlagsGroup;
    public FlagsNTD = FlagsNTD;

    public alignIconToRadioButton: AlignIconToRadioButton;

    constructor(protected store: Store<AppState>) {
    }

    ngOnInit() {

        this.alignIconToRadioButton = this.zonePage.ios ? AlignIconToRadioButton.iOS : AlignIconToRadioButton.androidAndWeb;
    }

    show() {

        this.dehumVisibility = !this.dehumVisibility;
    }

    disableNtdSetpoint() {

        return !(this.ars_extended?.PAR_NtdMode == NTD.OSP || this.ars_extended?.PAR_NtdMode == NTD.OSP_ES)
    }

    updateRttCanNtd($event) {

        // this.ars_extended.RTT_CanNtd = $event.detail.checked;
        this.store.dispatch(new ChangeTableValues('RMS', this.rms.id, {PAR_NtdOn: $event.detail.checked}));
    }

    ntdIconStyle = (): {} => {
        return {
            'text-align': "right",

            'opacity': (this.rms.RTU_Flags[this.FlagsGroup.NTD] == this.FlagsNTD.ON) ? 1 : 0.3
        }
    }
}

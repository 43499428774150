import {Component, Input, OnInit} from '@angular/core';
import {ZonePage} from "../../pages/zone/zone.page";
import {ARS_Extended} from "../../models/tables-extended";
import {AlignIconToRadioButton} from "../../models/enum-misc";
import {Store} from "@ngrx/store";
import {AppState} from "../../services/app-state.service";

@Component({
    selector: 'humidification-collapse',
    templateUrl: './humidification-collapse.component.html',
    styleUrls: ['./humidification-collapse.component.scss'],
})

export class HumidificationCollapseComponent implements OnInit {

    @Input() zonePage: ZonePage;
    @Input() extensible: boolean;
    @Input() ars_extended: ARS_Extended;

    public humVisibility = false;
    public alignIconToRadioButton: AlignIconToRadioButton;

    constructor(protected store: Store<AppState>) {

    }

    ngOnInit() {

        this.alignIconToRadioButton = this.zonePage.ios ? AlignIconToRadioButton.iOS : AlignIconToRadioButton.androidAndWeb;
    }

    show() {

        this.humVisibility = !this.humVisibility;
    }
}

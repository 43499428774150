<div class="status" *ngIf='status'>

    <ng-container *ngIf="showPowerOff && (!showPowerOff.Element_PAR_On || !showPowerOff.MCU_PAR_On || showPowerOff.showPowerOffIconOnly) else showNormalStatusIcons">

        <div class="d-flex flex-row align-items-center justify-content-end">
            <!-- <span *ngIf="status[FlagsGroup.GEN] == FlagsGEN.OFF" class="fas fa-power-off messana-red icon d-flex flex-row justify-content-center"></span> -->
            <span *ngIf="(!showPowerOff.Element_PAR_On || !showPowerOff.MCU_PAR_On)" class="fas fa-power-off messana-red-important icon d-flex flex-row justify-content-center"></span>

            <!-- show Alarms of a system even when it's powered off -->
            <alarm-icons [IconsComponent]="this"></alarm-icons>
        </div>

    </ng-container>
    <ng-template #showNormalStatusIcons>

        <div class="d-flex flex-row align-items-center justify-content-end" *ngIf="!onlyAlarms && !(onlyEs || onlyInt || onlyHrv || onlyHum || onlyNtd)">

            <span [ngClass]="MCU_Par_On && ((rms_extended.isMcz && rms_extended.PAR_Mode > 0) || (!rms_extended.isMcz && rms_extended.PAR_State === 1)) ? 'messana-green' : 'messana-disabled'" *ngIf="rms_extended && overrideIconIsPresent()" class="far fa-history icon d-flex flex-row justify-content-center"></span>

            <ng-container *ngFor="let icon of manualIcons">
                <span class="{{icon}} icon d-flex flex-row justify-content-center"></span>
            </ng-container>

            <span *ngIf="status[FlagsGroup.SEA] == FlagsSEA.OFF" class="fas fa-circle messana-disabled icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.SEA] == FlagsSEA.H" class="fas fa-circle messana-orange icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.SEA] == FlagsSEA.C" class="fas fa-circle messana-blue icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.SEA] == FlagsSEA.HS" class="fas fa-clock messana-orange icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.SEA] == FlagsSEA.CS" class="fas fa-clock messana-blue icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.SEA] == FlagsSEA.HAAM" class="icon-auto-adapt messana-orange icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.SEA] == FlagsSEA.CAAM" class="icon-auto-adapt messana-blue icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.SEA] == FlagsSEA.SS" class="icon-seasonchange fa-spin icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.SEA] == FlagsSEA.HA" class="icon-auto messana-orange icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.SEA] == FlagsSEA.CA" class="icon-auto messana-blue icon d-flex flex-row justify-content-center"></span>

            <span *ngIf="status[FlagsGroup.ES] == FlagsES.OFF" class="fas fa-leaf messana-disabled icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.ES] == FlagsES.ON" class="fas fa-leaf messana-green icon d-flex flex-row justify-content-center"></span>

            <span *ngIf="status[FlagsGroup.SB] == FlagsSB.OFF" class="icon-set-back messana-disabled icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.SB] == FlagsSB.ON" class="icon-set-back messana-green icon d-flex flex-row justify-content-center"></span>

            <span *ngIf="status[FlagsGroup.TH] == FlagsTH.OFF" [ngClass]="this.zonesTurnedOFF() ? 'icon-radiant-ceiling-disable' : 'icon-radiant-ceiling'" class="messana-disabled icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.TH] == FlagsTH.H" [ngClass]="this.zonesTurnedOFF() ? 'icon-radiant-ceiling-disable messana-disabled' : 'icon-radiant-ceiling messana-orange'" class="icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.TH] == FlagsTH.C" [ngClass]="this.zonesTurnedOFF() ? 'icon-radiant-ceiling-disable messana-disabled' : 'icon-radiant-ceiling messana-blue'" class="icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.TH] == FlagsTH.DIS" class="icon-radiant-ceiling-disable icon messana-disabled"></span>

            <span *ngIf="status[FlagsGroup.FT] == FlagsFT.OFF" [ngClass]="this.zonesTurnedOFF(true) ? 'icon-radiant-floor-disable' : 'icon-radiant-floor'" class="messana-disabled icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.FT] == FlagsFT.H" [ngClass]="this.zonesTurnedOFF(true) ? 'icon-radiant-floor-disable messana-disabled' : 'icon-radiant-floor messana-orange'" class="icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.FT] == FlagsFT.C" [ngClass]="this.zonesTurnedOFF(true) ? 'icon-radiant-floor-disable messana-disabled' : 'icon-radiant-floor messana-blue'" class="icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.FT] == FlagsFT.DIS" class="icon-radiant-floor-disable icon messana-disabled"></span>

            <span *ngIf="status[FlagsGroup.FC] == FlagsFC.OFF" class="icon-fancoil messana-disabled icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.FC] == FlagsFC.H && !isFncFSMOn()" class="icon-fancoil messana-orange icon d-flex flex-row justify-content-center"></span>

            <span *ngIf="(status[FlagsGroup.FC] == FlagsFC.H  && isFncFSMOn() || status[FlagsGroup.FC] == FlagsFC.C && isFncFSMOn())"
                  [ngClass]="{'messana-orange':status[FlagsGroup.FC] == FlagsFC.H,'messana-blue':status[FlagsGroup.FC] == FlagsFC.C}"
                  class="icon-fancoilbl icon d-flex flex-row justify-content-center align-items-center"></span>


            <span *ngIf="status[FlagsGroup.FC] == FlagsFC.C && !isFncFSMOn()" class="icon-fancoil messana-blue icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.FC] == FlagsFC.VOFF" class="icon-fancoil-ventilation messana-disabled icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.FC] == FlagsFC.VON" class="icon-fancoil-ventilation messana-green icon d-flex flex-row justify-content-center"></span>

            <span *ngIf="status[FlagsGroup.INT] == FlagsINT.OFF" class="icon-freecooling messana-disabled icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.INT] == FlagsINT.H" class="icon-freecooling messana-orange icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.INT] == FlagsINT.C" class="icon-freecooling messana-blue icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.INT] == FlagsINT.FC" class="icon-freecooling messana-green icon d-flex flex-row justify-content-center"></span>

            <!-- new hrv icon -->
            <icon-hrv *ngIf="status[FlagsGroup.HRV] == FlagsHRV.OFF" [hasBlackBackground]="hasBlackBackground" [hrvFlag]="status[FlagsGroup.HRV]"></icon-hrv>
            <icon-hrv *ngIf="status[FlagsGroup.HRV] == FlagsHRV.ON" [hasBlackBackground]="hasBlackBackground" [hrvFlag]="status[FlagsGroup.HRV]"></icon-hrv>

            <!-- [ngClass]="{'fa-tint':config?.humidity == 0,'fa-dewpoint':config?.humidity == 1}" -->
            <span *ngIf="status[FlagsGroup.NTD] == FlagsNTD.OFF" class="fal fa-tint messana-disabled icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.NTD] == FlagsNTD.ON" class="icon-dehumid messana-blue icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.NTD] == FlagsNTD.HUM" class="icon-humid messana-blue icon d-flex flex-row justify-content-center"></span>

            <span *ngIf="status[FlagsGroup.SM] == FlagsSM.OFF" class="icon fas fa-moon messana-disabled"></span>
            <span *ngIf="status[FlagsGroup.SM] == FlagsSM.ON" class="icon fas fa-moon messana-nightblue"></span>

            <span *ngIf="status[FlagsGroup.PMP] == FlagsPMP.OFF" class="icon-triangle messana-disabled icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.PMP] == FlagsPMP.RC" class="icon-triangle messana-green icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.PMP] == FlagsPMP.H" class="icon-triangle messana-orange icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.PMP] == FlagsPMP.C" class="icon-triangle messana-blue icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.PMP] == FlagsPMP.ON" class="icon-triangle icon d-flex flex-row justify-content-center"></span>

            <span *ngIf="status[FlagsGroup.TNK] == FlagsTNK.OFF" class="icon-tank messana-disabled icon ">
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                    <span class="path4"></span>
                </span>
            <span *ngIf="status[FlagsGroup.TNK] == FlagsTNK.H" class="icon-tank messana-orange icon " title="Hot Water">
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                    <span class="path4"></span>
                </span>
            <span *ngIf="status[FlagsGroup.TNK] == FlagsTNK.H_EH" class="icon-tank-eh icon " title="Hot Water">
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                    <span class="path4"></span>
                    <span class="path5"></span>
                </span>
            <span *ngIf="status[FlagsGroup.TNK] == FlagsTNK.C" class="icon-tank messana-blue icon ">
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                    <span class="path4"></span>
                </span>

            <span *ngIf="status[FlagsGroup.HP] == FlagsHP.OFF" class="icon icon-heat-pump messana-disabled"></span>
            <span *ngIf="status[FlagsGroup.HP] == FlagsHP.H" class="icon icon-heat-pump messana-orange"></span>
            <span *ngIf="status[FlagsGroup.HP] == FlagsHP.C" class="icon icon-heat-pump messana-blue"></span>

            <span *ngIf="status[FlagsGroup.CH] == FlagsCH.OFF" class="icon icon-chiller messana-disabled"></span>
            <span *ngIf="status[FlagsGroup.CH] == FlagsCH.ON" class="icon icon-chiller messana-blue"></span>

            <span *ngIf="status[FlagsGroup.BL] == FlagsBL.OFF" class="icon icon-boiler  messana-disabled"></span>
            <span *ngIf="status[FlagsGroup.BL] == FlagsBL.ON" class="icon icon-boiler  messana-orange"></span>

            <span *ngIf="status[FlagsGroup.DHW] == FlagsDHW.SB" class="icon-water messana-disabled icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.DHW] == FlagsDHW.OFF" class="icon-waterno icon d-flex flex-row justify-content-center messana-disabled"></span>
            <span *ngIf="status[FlagsGroup.DHW] == FlagsDHW.ON" class="icon-water messana-orange icon d-flex flex-row justify-content-center"></span>

            <span *ngIf="status[FlagsGroup.WND] == FlagsWND.OFF" class="icon-window messana-disabled icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.WND] == FlagsWND.ON" class="icon-window messana-enabled icon d-flex flex-row justify-content-center"></span>

            <span *ngIf="status[FlagsGroup.FWA] == FlagsFWA.OFF" class="far fa-sun-haze messana-disabled icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.FWA] == FlagsFWA.H" class="far fa-sun-haze messana-orange icon d-flex flex-row justify-content-center"></span>

            <span *ngIf="status[FlagsGroup.ExhaustAirExtraction] == FlagsExhaustAirExtraction.ON" class="icon-fan-exhaust messana-green icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.ExhaustAirExtraction] == FlagsExhaustAirExtraction.OFF" class="icon-fan-exhaust messana-disabled icon d-flex flex-row justify-content-center"></span>

            <!--<span *ngIf="showIconInternet()" [ngClass]="{'messana-green':connected,'messana-disabled':!connected}" class="fas fa-globe  icon d-flex flex-row justify-content-center"></span>-->
            <span *ngIf="showIconLAN()" [ngClass]="{'messana-white':connected,'messana-disabled':!connected}" class="fal fa-network-wired  icon d-flex flex-row justify-content-center"></span>

            <span *ngIf="showIconBluetooth()" [ngClass]="this.bluetoothService.isCurrentlyConnected() ? 'messana-blue' : 'messana-disabled'"
                  class="fas fa-bluetooth icon d-flex flex-row justify-content-center"></span>

            <alarm-icons [IconsComponent]="this"></alarm-icons>
        </div>

        <div class="d-flex flex-row align-items-center justify-content-end" *ngIf="onlyEs">
            <span *ngIf="status[FlagsGroup.ES] == FlagsES.OFF" class="fas fa-leaf messana-disabled icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.ES] == FlagsES.ON" class="fas fa-leaf messana-green icon d-flex flex-row justify-content-center"></span>

        </div>

        <div class="d-flex flex-row align-items-center justify-content-end" *ngIf="onlyInt">
            <span *ngIf="status[FlagsGroup.INT] == FlagsINT.OFF" class="icon-freecooling messana-disabled icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.INT] == FlagsINT.H" class="icon-freecooling messana-orange icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.INT] == FlagsINT.C" class="icon-freecooling messana-blue icon d-flex flex-row justify-content-center"></span>
            <span *ngIf="status[FlagsGroup.INT] == FlagsINT.FC" class="icon-freecooling messana-green icon d-flex flex-row justify-content-center"></span>

        </div>


        <div class="d-flex flex-row align-items-center justify-content-end" *ngIf="onlyHum">

            <!-- used in the humidification collapse, because I always want to show an icon, even if it's not active -->
            <ng-container *ngIf="status[FlagsGroup.NTD] === FlagsNTD.DISABLED && forceShowIconIfDisabled else showHumidificationIconActive">

                <span [ngClass]="{'alignIconToRadioButtonIos': alignIconToRadioButton === AlignIconToRadioButton.iOS, 'alignIconToRadioButtonAndroidWeb': alignIconToRadioButton === AlignIconToRadioButton.androidAndWeb, 'd-flex flex-row justify-content-center': alignIconToRadioButton === AlignIconToRadioButton.none}" class="fal fa-tint messana-disabled icon"></span>

            </ng-container>

            <ng-template #showHumidificationIconActive>

                <span *ngIf="status[FlagsGroup.NTD] == FlagsNTD.OFF" [ngClass]="{'alignIconToRadioButtonIos': alignIconToRadioButton === AlignIconToRadioButton.iOS, 'alignIconToRadioButtonAndroidWeb': alignIconToRadioButton === AlignIconToRadioButton.androidAndWeb, 'd-flex flex-row justify-content-center': alignIconToRadioButton === AlignIconToRadioButton.none}" class="fal fa-tint messana-disabled"></span>
                <span *ngIf="status[FlagsGroup.NTD] == FlagsNTD.ON" [ngClass]="{'alignIconToRadioButtonIos': alignIconToRadioButton === AlignIconToRadioButton.iOS, 'alignIconToRadioButtonAndroidWeb': alignIconToRadioButton === AlignIconToRadioButton.androidAndWeb, 'd-flex flex-row justify-content-center': alignIconToRadioButton === AlignIconToRadioButton.none}" class="icon-humid messana-disabled icon"></span>
                <span *ngIf="status[FlagsGroup.NTD] == FlagsNTD.HUM" [ngClass]="{'alignIconToRadioButtonIos': alignIconToRadioButton === AlignIconToRadioButton.iOS, 'alignIconToRadioButtonAndroidWeb': alignIconToRadioButton === AlignIconToRadioButton.androidAndWeb, 'd-flex flex-row justify-content-center': alignIconToRadioButton === AlignIconToRadioButton.none}" class="icon-humid messana-blue icon"></span>
            </ng-template>
        </div>

        <div class="d-flex flex-row align-items-center justify-content-end" *ngIf="onlyNtd">

            <!-- used in the dehumidification collapse, because I always want to show an icon, even if it's not active -->
            <ng-container *ngIf="status[FlagsGroup.NTD] === FlagsNTD.DISABLED && forceShowIconIfDisabled else showDehumidificationIconActive">

                <span [ngClass]="{'alignIconToRadioButtonIos': alignIconToRadioButton === AlignIconToRadioButton.iOS, 'alignIconToRadioButtonAndroidWeb': alignIconToRadioButton === AlignIconToRadioButton.androidAndWeb, 'd-flex flex-row justify-content-center': alignIconToRadioButton === AlignIconToRadioButton.none}" class="fal fa-tint messana-disabled icon"></span>

            </ng-container>

            <ng-template #showDehumidificationIconActive>

                <span *ngIf="status[FlagsGroup.NTD] == FlagsNTD.OFF" [ngClass]="{'alignIconToRadioButtonIos': alignIconToRadioButton === AlignIconToRadioButton.iOS, 'alignIconToRadioButtonAndroidWeb': alignIconToRadioButton === AlignIconToRadioButton.androidAndWeb, 'd-flex flex-row justify-content-center': alignIconToRadioButton === AlignIconToRadioButton.none}" class="fal fa-tint messana-disabled icon"></span>
                <span *ngIf="status[FlagsGroup.NTD] == FlagsNTD.ON" [ngClass]="{'alignIconToRadioButtonIos': alignIconToRadioButton === AlignIconToRadioButton.iOS, 'alignIconToRadioButtonAndroidWeb': alignIconToRadioButton === AlignIconToRadioButton.androidAndWeb, 'd-flex flex-row justify-content-center': alignIconToRadioButton === AlignIconToRadioButton.none}" class="icon-dehumid messana-blue icon"></span>
                <span *ngIf="status[FlagsGroup.NTD] == FlagsNTD.HUM" [ngClass]="{'alignIconToRadioButtonIos': alignIconToRadioButton === AlignIconToRadioButton.iOS, 'alignIconToRadioButtonAndroidWeb': alignIconToRadioButton === AlignIconToRadioButton.androidAndWeb, 'd-flex flex-row justify-content-center': alignIconToRadioButton === AlignIconToRadioButton.none}" class="icon-dehumid messana-disabled icon"></span>
            </ng-template>
        </div>

        <div class="d-flex flex-row align-items-center justify-content-end" *ngIf="onlyHrv">

            <!-- new hrv icon -->
            <ng-container *ngIf="status[FlagsGroup.HRV] == FlagsHRV.DISABLED else showAirQualityIconActive">

                <icon-hrv *ngIf="forceShowIconIfDisabled" [alignIconToRadioButton]="alignIconToRadioButton" [hasBlackBackground]="hasBlackBackground" [hrvFlag]="status[FlagsGroup.HRV]"></icon-hrv>

            </ng-container>

            <ng-template #showAirQualityIconActive>
                <icon-hrv [alignIconToRadioButton]="alignIconToRadioButton" [hasBlackBackground]="hasBlackBackground" [hrvFlag]="status[FlagsGroup.HRV]"></icon-hrv>
            </ng-template>
        </div>

        <div class="d-flex flex-row align-items-center justify-content-end" *ngIf="!(!onlyAlarms && !(onlyEs || onlyInt || onlyHrv || onlyHum))">
            <span *ngIf="status[FlagsGroup.GEN] == FlagsGEN.OFF" class="fas fa-power-off messana-red icon d-flex flex-row justify-content-center"></span>
        </div>

        <div class="d-flex flex-row align-items-center justify-content-end" *ngIf="!(!onlyAlarms && !(onlyEs || onlyInt || onlyHrv || onlyHum))">
            <span *ngIf="status[FlagsGroup.GEN] == FlagsGEN.OFF" class="fas fa-power-off messana-red icon d-flex flex-row justify-content-center"></span>
        </div>

    </ng-template>
</div>


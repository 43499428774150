import {Component, Input, OnInit} from '@angular/core';
import {MacroZonePage} from "../macro-zone.page";
import {DUAL_MODE} from "../../../models/config";
import {MCZ} from "../../../models/tables";

@Component({
    selector: 'macro-zone-setpoints',
    templateUrl: './macro-zone-setpoints.component.html',
    styleUrls: ['./macro-zone-setpoints.component.scss'],
})
export class MacroZoneSetpointsComponent implements OnInit {

    @Input() macroZonePage: MacroZonePage;

    public DUAL_MODE = DUAL_MODE;

    constructor() {

    }

    ngOnInit() {

    }

    isAutoModeStyleDualSetpoint() {

        return this.macroZonePage?.isAutoModeStyleDualSetpoint();
    }

    isOverride(dualSetpointMode: DUAL_MODE = null) {

        return this.macroZonePage?.mczExtended.getSetpointOverrideInfo(null, dualSetpointMode).isOverride;
    }

    computeMin(season: number) {

        return this.macroZonePage?.computeMin(season, this.macroZonePage?.mczExtended, this.macroZonePage?.MCU, this.macroZonePage?.mczExtended, this.isAutoModeStyleDualSetpoint());
    }

    computeMax(season: number) {

        return this.macroZonePage?.computeMax(season, this.macroZonePage?.mczExtended, this.macroZonePage?.MCU, this.macroZonePage?.mczExtended, this.isAutoModeStyleDualSetpoint());
    }
}

<circle-countdown [dualSetpointMode]="dualSetpointMode" [rmsOrMczExtended]="rmsOrMczExtended" [isOverrideSetpoint]="true"></circle-countdown>

<div class="zone-displayWidth">

    <div [ngClass]="{'disabled-item':!state}" style="float:left" class="d-flex flex-row justify-content-center align-items-center w-50">

        <div class="d-flex flex-row align-items-center">
            <span [ngClass]="{'messana-red-light': fromMacrozonePage, 'messana-red': !fromMacrozonePage}" (click)="page.cancelOverride(this.dualSetpointMode)" class="overrideIcon margin0 cursorPointer far fa-xmark messana-red icon cursor-pointer"></span>
        </div>
    </div>

    <div [ngClass]="{'disabled-item':!state}" class="d-flex flex-row justify-content-center align-items-center w-50">

        <div class="d-flex flex-row align-items-center">
            <span [ngClass]="{'messana-blue-light': fromMacrozonePage, 'messana-blue': !fromMacrozonePage}" (click)="page.overrideSetpoint(this.dualSetpointMode, otherDualSetpointModeIsInOverride)" class="overrideIcon margin0 cursorPointer far fa-edit icon cursor-pointer"></span>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';

/**
 * Instead of passing around action string constants and manually recreating
 * action objects at the point of dispatch, we create services encapsulating
 * each appropriate action group.
 */


export enum SocketActionTypes {
	Connect = '[Socket] Connect',
	Connected = '[Socket] Connected',
	Disconnected = '[Socket] Disconnected',
	TableLoaded = '[Socket] TableLoaded',
    CheckRemoteConnectionState = '[Socket] CheckRemoteConnectionState',
    CheckRemoteConnectionStateSuccess = '[Socket] CheckRemoteConnectionStateSuccess',
    CheckRemoteConnectionStateFail = '[Socket] CheckRemoteConnectionStateFail'

}

export class Connect implements Action {
    readonly type = SocketActionTypes.Connect;
    constructor(){}
}
export class Connected implements Action {
    readonly type = SocketActionTypes.Connected;
    constructor(){}
}
export class Disconnected implements Action {
    readonly type = SocketActionTypes.Disconnected;
    constructor(){}
}
export class TableLoaded implements Action {
    readonly type = SocketActionTypes.TableLoaded;
    constructor(public table: string){}
}

export class CheckRemoteConnectionState implements Action {
    readonly type = SocketActionTypes.CheckRemoteConnectionState;
    constructor(){}
}

export class CheckRemoteConnectionStateSuccess implements Action {
    readonly type = SocketActionTypes.CheckRemoteConnectionStateSuccess;
    constructor(){}
}

export class CheckRemoteConnectionStateFail implements Action {
    readonly type = SocketActionTypes.CheckRemoteConnectionStateFail;
    constructor(){}
}


export type SocketActions = Connect | Connected | Disconnected | TableLoaded | CheckRemoteConnectionState | CheckRemoteConnectionStateSuccess | CheckRemoteConnectionStateFail;
export const HIDEW_CALIBRATIONS = {
    "CALIBRATION":"Calibrazione",
    "PAHSE_ONE":"Fase 1",
    "PAHSE_TWO":"Fase 2",
    "PAHSE_THREE":"Fase 3",
    "SUPPLY_FAN":"Ventilatore mandata",
    "SUPPLY_FAN_TABLE":"Ventilatore<br/>mandata&nbsp;%",
    "RETURN_FAN":"Ventilatore estrazione",
    "RETURN_FAN_TABLE":"Ventilatore estrazione&nbsp;%",
    "RECIRCULATION_DUMPER":"Serranda ricircolo",
    "RECIRCULATION_DUMPER_TABLE":"Serranda ricircolo&nbsp;%",
    "REC_DUMPER":"Ser. ricircolo",
    "REC_DUMPER_TABLE":"Ser. ricircolo&nbsp;%",
    "PAST_CALIBRATIONS":"Storico calibrazioni",
    "HIDEW_ATU_CALIBRATION":"Calibrazione HiDew RER - REV",
    "START_CALIBRATION":"Avvia calibrazione",
    "CALIBRATION_PHASE_ONE":"Solo ricircolo - fase 1 - DEUM",
    "CALIBRATION_PHASE_TWO":"Solo ricambio - fase 2 - HRV",
    "CALIBRATION_PHASE_THREE":"Ricircolo + ricambio - fase 3 - DEUM+HRV",
    "CAL_DESC_1":"Le unità sono piccole unità di trattamento aria che ricircolano l'aria ambiente e la ricambiano con quella esterna. Ogni installazione è unica nel suo genere, ed è quindi indispensabile misurare e correggere le portate d'aria della macchina in funzione delle reali condizioni di funzionamento. Di fabbrica infatti le unità sono programmate per offrire una pressione \"fissa\" ma quasi sicuramente non sarà esattamente quella adatta all'installazione. Diventa quindi indispensabile correggere le portate d'aria nelle diverse condizioni di funzionamento:",
    "CAL_F1":"Solo ricircolo (fase 1)",
    "CAL_F2":"Solo ricambio (fase 2)",
    "CAL_F3":"Ricircolo + ricambio (fase 3)",
    "CAL_DESC_2":"Diversamente se la taratura non sarà effettuata o non sarà effettuata correttamente, la RER/REV avrà delle portate d'aria diverse da quelle di progetto e queste pregiudicheranno il corretto funzionamento dell'unità e faranno perdere efficienza al recupero di calore.",
    "CAL_DESC_3":"L'unità, non tarata o non correttamente tarata da personale specializzato, è da ritenersi esclusa dalla garanzia.",
    "CALIBRATION_SESSION_CLOSED_BY_ANOTHER_USER":"La sessione di calibrazione è stata chiusa da un altro utente o è scaduta.",
    "CURRENT_CALIBRATION":"Calibrazione corrente",
    "PREVIOUS_CALIBRATIONS":"Calibrazioni precedenti",
    "PREP_DESC_1":"Preparazione della calibrazione",
    "PREP_STEP_1":"Fare un foro sui tubi 2, 3 e 4 per inserire l’anemometro, vedi il paragrafo dedicato del manuale utente dell'UTA (9.6 PRIMO AVVIAMENTO, TARATURA E CONFIGURAZIONI).",
    "PREP_STEP_2":"I fori devono essere fatti al centro di una sezione retta del tubo, lontani da curve e non troppo vicini agli inserti nelle unità.",
    "ISTR_PHASE_1_TITLE":"Fase 1 - misurazione del flusso d'aria",
    "POINT_P1_1":"Inserire l’anemometro nel tubo 4 (ricircolo), vedi il paragrafo dedicato del manuale utente dell'UTA (9.6 PRIMO AVVIAMENTO, TARATURA E CONFIGURAZIONI). Il flusso d'aria misurato deve corrispondere alla tabella.",
    "POINT_P1_2":"Se l'anemometro legge una portata diversa, con i tasti SINISTRA e DESTRA modifica la velocità della ventola fino a quando non trovi la protata corretta.",
    "SIZE":"Taglia",
    "NOMINAL":"Portata d'aria nominale (mq/h)",
    "ISTR_PHASE_2_TITLE":"Fase 2 - misurazione del flusso d'aria",
    "POINT_P2_1":"Inserire l’anemometro nel tubo 2 (ingresso aria esterna), vedi il paragrafo dedicato del manuale utente dell'UTA (9.6 PRIMO AVVIAMENTO, TARATURA E CONFIGURAZIONI). Il flusso d'aria misurato deve corrispondere alla tabella. ",
    "POINT_P2_2":"Nella sezione Ventilatore Mandata, per raggiungere il valore corretto, premi i tasti SINISTRA e DESTRA per modificare i valori e conseguentemente la velovità del ventilatore di mandata",
    "POINT_P2_3":"Sposta l'anemometro nel tubo 3 (espulsione aria esausta), vedi il paragrafo dedicato del manuale utente dell'UTA (9.6 PRIMO AVVIAMENTO, TARATURA E CONFIGURAZIONI). Il flusso d'aria misurato deve corrispondere alla tabella (vedi tabella punto 1). ",
    "POINT_P2_4":"Nella sezione Ventilatore Estrazione, per raggiungere il valore corretto, premi i tasti SINISTRA e DESTRA per modificare i valori e conseguentemente la velovità del ventilatore di estrazione.",
    "P2_ATTENTION_START":"Attenzione:",
    "P2_ATTENTION":"durante la fase 2 di calibrazione, si raccomanda di tenere i flussi d'aria dei tubi 2 e 3 uguali, altrimenti i flussi non saranno bilanciati. Questo porta al non corretto funzionamento della UTA H /UTA V e ad una bassa efficienza nel recupero del calore.",
    "ISTR_PHASE_3_TITLE":"Fase 3 - misurazione del flusso d'aria",
    "POINT_P3_1":"Inserisci l'anemometro del tubo 2 (ingresso aria esterna), vedi il paragrafo dedicato del manuale utente dell'UTA (9.6 PRIMO AVVIAMENTO, TARATURA E CONFIGURAZIONI).Il flusso d'aria misurato deve corrispondere alla tabella.",
    "POINT_P3_2":"Successivamente, misura il flusso d'aria del tubo 4 (aspirazione aria aimbiente per ricircolo). Il flusso d'aria misurato deve corrispondere alla tabella (vedi tabella punto 1). ",
    "POINT_P3_3":"Per raggiungere questi valori diportata modifica alternativamente, usando i tasti SINISTR e DESTR, i due parametri (ventilatore mandata e serranda riricrolo). ",
    "P3_NOTES":"Note per le regolazioni: ",
    "P3_N_L1":"Aumentando la % del ventilatore di mandata, la somma dei flussi dei tubi 2 e 4 aumenta e vice versa. ",
    "P3_N_L2":"Aumentando la % di apertura della serranda ricircolo, il flusso del tubo 2 viene ridotto e il flusso del tubo 4 aumenta e vice versa. ",
    "P3_WARN_START":"Attenzione:",
    "P3_WARN":"la serranda si muove lentamente; quando modifichi l'apertura, aspetta qualche secondo per il suo corretto posizionamento.",
    "P3_FINAL":"Alla fine della fase 3 di calibrazione, dovresti ottenere 2 valoriuguali per entrambe le portate dei tubi 2 e 4. Di seguito i valoricorrettiper le somme del tubo 2 + tubo 4",

    "BACK_TO_START_PAGE":"Pagina di avvio",
    "GO_TO_PHASE_TWO":"Fase 2",
    "BACK_TO_PHASE_ONE":"Fase 1",
    "GO_TO_PHASE_THREE":"Fase 3",
    "BACK_TO_PHASE_TWO":"Fase 2"



};

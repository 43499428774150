import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {map, catchError, mergeMap, tap} from 'rxjs/operators';

import {AttributesActions, AttributeActionTypes, LoadAttributesSuccess, LoadAttributesError, LoadAttributes, ReloadAttributes, SaveAttributesConfigDispatched} from '../actions/attributes.actions';
import {AttributesService} from '../services/attributes.service';
import {AbstractConfigPage} from "../pages/abstract-config-page";
import {ConfigService} from "../services/config.service";
import {Store} from "@ngrx/store";
import {AppState} from "../services/app-state.service";
import {SaveAttributesConfig} from "../actions/config.actions";

@Injectable()
export class AttributesEffects {

    // The update$ variable is a StateUpdate from ngrx/effects.
    // This is an observable that emits everytime an action flows through the store.

    constructor(private actions$: Actions<AttributesActions>,
                private attributesService: AttributesService,
                private configService: ConfigService,
                private store: Store<AppState>) {
    }

    @Effect()
    load$: Observable<any> = this.actions$.pipe(
        ofType(AttributeActionTypes.LoadAttributes),

        mergeMap(action => {

                return this.attributesService.getAttributes().pipe(
                    tap((data) => {

                        if (data && data.code) {

                            AbstractConfigPage.setCentralizedTableAsInitialized('attrs');
                        }
                    }),

                    // If successful, dispatch success action with result
                    map(data => new LoadAttributesSuccess(data)),
                    // If request fails, dispatch failed action
                    catchError((err) => of(new LoadAttributesError(err)))
                )
            }
        )
    );

    @Effect()
    loadAttributesSuccess$: Observable<any> = this.actions$.pipe(
        ofType(AttributeActionTypes.LoadAttributesSuccess),

        mergeMap(action => {

                this.store.dispatch(new SaveAttributesConfig(action.data));
                return of(new SaveAttributesConfigDispatched());
        })
    );

    @Effect()
    reload$: Observable<any> = this.actions$.pipe(
        ofType(AttributeActionTypes.ReloadAttributes),

        mergeMap(action => {

                return this.attributesService.reload().pipe(
                    // If successful, dispatch success action with result
                    map(data => new LoadAttributesSuccess(data)),
                    // If request fails, dispatch failed action
                    catchError((err) => of(new LoadAttributesError(err)))
                )
            }
        )
    );


}

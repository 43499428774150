import {Injectable} from '@angular/core';

import {Store} from '@ngrx/store';
import {from, Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Attributes} from '../models/attributes';
import {AppState} from './app-state.service';
import {Config} from '../models/config';


import {HttpClient} from '@angular/common/http';

import {handleError} from './support.service';
import {BluetoothService} from "./bluetooth.service";
import {AbstractConfigPage} from "../pages/abstract-config-page";

@Injectable({
    providedIn: 'root'
})
export class AttributesService {

    private config: Observable<Config>;
    private attrs: Observable<Attributes>;
    private cache: Attributes = {};
    private baseUrl: string;
    private loadExcludedKeys: boolean;

    constructor(private http: HttpClient,
                protected store: Store<AppState>,
                private bluetoothService: BluetoothService) {
        this.config = store.select('config') as Observable<Config>;
        this.config.subscribe(data => {
            this.baseUrl = data.baseUrl;
            this.loadExcludedKeys = data.loadExcludedKeys;
        })
        //this.store.dispatch(new LoadConfig());
        // Create a cache for the loaded attributes
        this.attrs = store.select('attrs') as Observable<Attributes>;
        this.attrs.subscribe(data => {
            this.cache = data;
        })
    }

    /**
     * Load the attributes
     */
    getAttributes(): Observable<any> {
        // Return the table from the cache if it is already loaded
        // if (Object.keys(this.cache).length) {
        //     return Observable.of(this.cache);
        // }
        // Load the attributes using the API
        // console.log(this.baseUrl + 'api/attributes' + (this.loadExcludedKeys ? '?loadExcludedKeys=1' : ''))

        if (AbstractConfigPage.isCentralizedTableInitialized('attrs')) {

            // console.warn('Ricarico attributes da getCentralizedTableData');
            return of(AbstractConfigPage.getCentralizedTableData('attrs'));
        }

        if (this.bluetoothService.isConnected()) {

            return from(this.bluetoothService.getDataViaBluetooth('Attributes'));

        } else {

            console.warn('Scarico attributes');

            return this.http.get(this.baseUrl + 'api/attributes' + (this.loadExcludedKeys ? '?loadExcludedKeys=1' : '')).pipe(
                // tap(data=>console.log(data)),
                //  map((res:Response) => res.json().content),
                catchError(handleError));
        }
    }

    /**
     * Flush the attributes cache
     */
    reload() {

        this.cache = {};
        AbstractConfigPage.resetCentralizedAttributesData();

        // return Observable.of([ null ]);

        if (this.bluetoothService.isConnected()) {

            return from(this.bluetoothService.getDataViaBluetooth('Attributes'));

        } else {

            return this.http.get(this.baseUrl + 'api/attributes' + (this.loadExcludedKeys ? '?loadExcludedKeys=1' : '')).pipe(
                // tap(data=>console.log(data)),
                //  map((res:Response) => res.json().content),
                catchError(handleError));
        }
    }

}

import {Component, Input, OnInit} from '@angular/core';
import {ZonePage} from "../../pages/zone/zone.page";
import {ARS_Extended} from "../../models/tables-extended";
import {AlignIconToRadioButton} from "../../models/enum-misc";
import {Store} from "@ngrx/store";
import {AppState} from "../../services/app-state.service";

@Component({
  selector: 'air-quality-collapse',
  templateUrl: './air-quality-collapse.component.html',
  styleUrls: ['./air-quality-collapse.component.scss'],
})
export class AirQualityCollapseComponent implements OnInit {

  @Input() zonePage: ZonePage;
  @Input() extensible: boolean;
  @Input() ars_extended: ARS_Extended;

  public airQualityVisibility = false;
  public alignIconToRadioButton: AlignIconToRadioButton;

  constructor(protected store: Store<AppState>) {

  }

  ngOnInit() {

    this.alignIconToRadioButton = this.zonePage.ios ? AlignIconToRadioButton.iOS : AlignIconToRadioButton.androidAndWeb;
  }

  show() {

    this.airQualityVisibility = !this.airQualityVisibility;
  }
}

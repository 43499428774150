 
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';

/**
 * Instead of passing around action string constants and manually recreating
 * action objects at the point of dispatch, we create services encapsulating
 * each appropriate action group.
 */


export enum BackupActionTypes  {
	LoadBackup='[Backup] Load Backup',
	LoadBackupSuccess='[Backup] Load Backup Success',
	CreateBackup='[Backup] Create Backup',
	RenameBackup='[Backup] Rename Backup',
	DeleteBackup='[Backup] Delete Backup',
	RestoreBackup='[Backup] Restore Backup',
	LoadBackupError='[Backup] Load Backup Error'

}

export class LoadBackup implements Action {
    readonly type = BackupActionTypes.LoadBackup;
    constructor(){}
}
export class LoadBackupSuccess implements Action {
    readonly type = BackupActionTypes.LoadBackupSuccess;
    constructor(public data: any){}
}

export class CreateBackup implements Action {
    readonly type = BackupActionTypes.CreateBackup;
    constructor(public name:string){}
}
export class RenameBackup implements Action {
    readonly type = BackupActionTypes.RenameBackup;
    constructor(public id: number, public name: string){}
}
export class DeleteBackup implements Action {
    readonly type = BackupActionTypes.DeleteBackup;
    constructor(public id: number){}
}
export class RestoreBackup implements Action {
    readonly type = BackupActionTypes.RestoreBackup;
    constructor(public id: number){}
}
export class LoadBackupError implements Action {
    readonly type = BackupActionTypes.LoadBackupError;
    constructor(public error: any){}
}


 
 export type BackupsActions=   LoadBackup | LoadBackupSuccess  | CreateBackup | RenameBackup | DeleteBackup | RestoreBackup | LoadBackupError;
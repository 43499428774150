import {Injectable, NgZone} from '@angular/core';
import {HttpStatus, NetworkStatus, NetworkWay} from '../models/http-status';
import {Store} from '@ngrx/store';
import {LoadTable} from '../actions/tables.actions';
import {EMPTY, from, Observable, of, Subject, Subscription, throwError, timer} from 'rxjs';
import {AppState} from '../services/app-state.service';
import {HttpClient} from '@angular/common/http';
import {Tables} from '../models/tables';
import {catchError, concatMap, debounceTime, first, map, switchMap, takeUntil, tap, timeout} from 'rxjs/operators';
import {CommutationError, CommutationSuccess, CommutationSync, CommutationSyncToRecheck, ReloadingNetworkUpdate, ResyncAlredyyStarted, ResyncCompleted} from '../actions/http-status.actions';
import {BackendCompliantRelease200, BackendNotCompliantRelease200, ChangeConfigValue} from '../actions/config.actions';
import {Config, Debian, EnvironmentType, PlatformMode} from '../models/config';
import {HTTP} from '@ionic-native/http/ngx';
import {fixedEncodeURI, NativeResp} from '../models/http';
import {SocketService} from '../services/socket.service';
import {Platform} from '@ionic/angular';
import {WindowRefService} from '../services/window.service';
import {ConnectionStatus, Network} from '@capacitor/network';
import {BluetoothService} from "./bluetooth.service";
import {PopupService} from "./popup.service";

export interface DeviceData {
    ip: string;
    code: string;
}

@Injectable({
    providedIn: 'root'
})
export class HttpStatusService {

    modality: NetworkWay;
    pendingRequests: number;
    currentRequest: number;
    localIp: string;
    localCode: string;
    IAmProbingLANConnection: boolean;
    IAmProbingIP: boolean;

    currentlyOnWifi: boolean;
    reloadingNetwork: NetworkStatus;

    resyncStarted: boolean;
    netUpdated: boolean;

    status: Observable<HttpStatus>;
    tablesObs: Observable<Tables>;
    tabSub: Subscription;
    commSub: Subscription;
    ipSub: Subscription;
    configObs: Observable<Config>;
    public config: Config = {} as Config;
    platformSubInit: boolean;
    networkHandler: any;
    private window;
    zeroConfSubject: Subject<boolean> = new Subject<boolean>();

    constructor(private windowRefService: WindowRefService, private socketService: SocketService, private http: HttpClient, protected store: Store<AppState>, private nativeHttp: HTTP, private platform: Platform, private ngZone: NgZone, private bluetoothService: BluetoothService, private popupService: PopupService) {

        this.currentlyOnWifi = false;
        this.networkHandler = null;
        this.resyncStarted = false;
        this.netUpdated = false;
        this.store.dispatch(new LoadTable('MCU'));


        this.configObs = store.select('config') as Observable<Config>;

        this.configObs.subscribe(data => {

            if (data.environment !== EnvironmentType.MCU) {

                if ((data.backendCompliantRelease200 && !this.config.backendCompliantRelease200) || data.baseUrl !== this.config.baseUrl) {

                    this.config = {...data};

                    this.probeNetworkStatus().pipe(first()).subscribe((data) => {

                        this.store.dispatch(new CommutationSuccess(data as NetworkWay))
                    });
                }
            }

            this.config = data;
            //console.log(`config udpate in http-status compliat200 vale ${this.config.backendCompliantRelease200}`);
        })

        this.window = this.windowRefService.nativeWindow;


        this.IAmProbingLANConnection = false;
        this.IAmProbingIP = false;

        this.status = store.select('httpStatus') as Observable<HttpStatus>;
        this.tablesObs = store.select('tables') as Observable<Tables>;

        //console.log('NetworkHandlerInit()');
        this.networkHandler = Network.addListener('networkStatusChange', (status: ConnectionStatus) => {

            this.ngZone.run(() => {

                this.dispatchCommutation(status);
            });
        });

        // To stop listening:
        // handler.remove();

        //console.log('sottoscrivo tablesObs');

        this.tablesObs.pipe(debounceTime(700)).subscribe(tables => {
            //console.log('qualcosa è arrivato da tablesObs')
            //console.log('tables vale')
            //console.log(tables);

            if (tables['MCU'] != undefined)
                if (tables['MCU'][0] != undefined)
                    if (tables['MCU'][0].PAR_Network_Status != undefined) {

                        let netState: NetworkStatus = tables['MCU'][0].PAR_Network_Status;
                        //console.log(`table obs net state vale ${netState}`)
                        if (netState == NetworkStatus.Reloading && !this.netUpdated) {
                            //console.log(`ora che table obs net state vale ${netState} lancio ReloadingNetworkUpdate - partira il resync`);
                            this.resyncStarted = false;

                            this.netUpdated = true;
                            this.store.dispatch(new ReloadingNetworkUpdate(netState));
                            //this.store.dispatch(new ChangeTableValues('MCU', 0, {PAR_Network_Status:NetworkStatus.Active}));
                        }

                    }
        })


        this.status.subscribe(snapshot => {

            //console.log(`syncing http status current modalità in service is ${snapshot.modality}, ip : ${this.localIp}, netw.status: ${snapshot.reloadingNetwork}`)
            this.modality = snapshot.modality;
            this.pendingRequests = snapshot.pendingRequests;
            this.currentRequest = snapshot.currentRequest;
            this.reloadingNetwork = snapshot.reloadingNetwork;
            ////console.log(`scrivo sul db ${snapshot.reloadingNetwork} da http-status subscribe`);
            //this.store.dispatch(new ChangeTableValues('MCU', 0, {PAR_Network_Status:snapshot.reloadingNetwork}));

        })
    }

    isBrowserOrRemote(modality) {
        return modality == NetworkWay.Remote || this.config.platform == PlatformMode.Browser;
    }

    isLanAndApp(modality) {
        return (modality == NetworkWay.LAN || modality == NetworkWay.DISCOVERY) && this.config.platform == PlatformMode.App;
    }

    probeCompliantRelease200(fromBleDisconnect = false) {

        return this.status.pipe(first(), switchMap((status) => {

            if (this.bluetoothService.isConnected()) {

                return from(this.bluetoothService.getDataViaBluetooth('api/release200', true));
            }

            if (this.isBrowserOrRemote(status.modality) || fromBleDisconnect) {

                return this.configObs.pipe(first(), switchMap(configData => {

                    console.log('configData.baseUrl', configData.baseUrl);

                    return this.http.get(fixedEncodeURI(configData.baseUrl + 'api/release200')).pipe(
                        timeout(8000),

                        catchError((error) => {

                            return throwError(error);
                        }));
                }));
            }

            //se local uso nativeHttp
            if (this.isLanAndApp(status.modality)) {

                return this.configObs.pipe(first(), switchMap(configData => {

                    console.log('configData.baseUrl', configData.baseUrl);

                    let url = configData.baseUrl + 'api/release200';

                    return from(this.nativeHttp.get(fixedEncodeURI(url), {}, {})).pipe(
                        map(httpResp => {

                                return JSON.parse((httpResp as NativeResp).data);
                            }
                        ), timeout(8000),

                        catchError((error) => {

                            return throwError(error);
                        }));
                }));
            }
        }));
    }

    async backendCheckSocketIoPluginV4(modality) {

        class SocketIoVersionApiResponse {

            version: number;
        }

        const clientSocketIoVersion = 4;

        const checkWarningToastToFire = async (backendVersion) => {

            if (clientSocketIoVersion > backendVersion) {

                await this.popupService.presentToast(`Attention: A critical update is required for your mBox to maintain seamless connectivity. Kindly proceed with the update to ensure continued access.`, 60000);
            }

            if (clientSocketIoVersion < backendVersion) {

                const Play = this.platform.is("android") ? 'Play' : 'App';
                await this.popupService.presentToast(`Attention: A critical update is required for your Messana App to maintain seamless connectivity. Please update to the latest version available on the ${Play} Store.`, 60000);
            }
        }

        const configData = await this.configObs.pipe(first()).toPromise() as Config;
        const url = configData.baseUrl + 'api/socketIoVersion';

        if (this.isBrowserOrRemote(modality)) {

            this.http.get(fixedEncodeURI(url)).toPromise().then((response: SocketIoVersionApiResponse) => {

                console.log('socket-io-http', response);
                checkWarningToastToFire(response.version);

            }).catch((e) => {

                console.log('socket-io-e-browser-remote', e);
                checkWarningToastToFire(2);
            });
        }

        //se local uso nativeHttp
        if (this.isLanAndApp(modality)) {

            this.nativeHttp.get(fixedEncodeURI(url), {}, {}).then((httpResp) => {

                const response = JSON.parse(httpResp.data) as SocketIoVersionApiResponse;
                console.log('socket-io-native-http', response);
                checkWarningToastToFire(response.version);

            }).catch((e) => {

                console.log('socket-io-e-lan-app', e);
                checkWarningToastToFire(2);
            });
        }
    }

    resetNetUpdated(): Observable<any> {
        //console.log('resetNetUpdated()');
        this.netUpdated = false;
        return of(EMPTY);
    }

    _getDeviceData(fromBleDisconnect = false): Observable<DeviceData> {

        return this.probeCompliantRelease200(fromBleDisconnect).pipe(
            switchMap((data) => {

                this.store.dispatch(new BackendCompliantRelease200());

                return this._getDeviceCode().pipe(
                    concatMap(deviceCode => {
                        return this._getIpAddress(deviceCode);
                    }));

            }), catchError((error) => {

                console.error('probeCompliantRelease200 - error!', error);
                this.store.dispatch(new BackendNotCompliantRelease200());
                return of(undefined);
            })
        );
    }

    _getDeviceCode() {

        // console.warn(`_getDeviceCode() verso  ${this.config.baseUrl} api/system/code `)
        //se questa api non ci fosse restituisco undefined

        if (this.config.baseUrl != null) {

            const api = 'api/system/code';

            if (this.config.platform == PlatformMode.App) {
                // console.log('device code url');
                // console.log(url);

                if (this.bluetoothService.isConnected()) {

                    return from(this.bluetoothService.getDataViaBluetooth(api, true)).pipe(
                        timeout(20000),
                        map(bluetoothResponse => {

                            if (bluetoothResponse.code != undefined) {

                                return bluetoothResponse.code;

                            } else {

                                return undefined;
                            }
                        }),

                        catchError(error => {

                            return of(undefined);
                        }));
                }

                return from(this.nativeHttp.get(fixedEncodeURI(this.config.baseUrl + api), {}, {'Authorization': (this.config.currentUser) ? 'Bearer ' + this.config.currentUser.token : null})).pipe(
                    timeout(10000),
                    map(
                        httpResp => {

                            let res = JSON.parse((httpResp as NativeResp).data);
                            // console.log(`_getDeviceCode() code vale ${res['code']}`)

                            if (res.code != undefined) {


                                return res['code'];
                            } else return undefined;
                        }),
                    catchError(error => {

                        // console.log("error while retrieving device code");
                        let errMsg = (error.message) ? error.message :
                            error.status ? `${error.status}` : error.error ? error.error : error.headers ? error.headers : 'native http conn. error';
                        // console.error(errMsg);

                        return of(undefined);

                    }));
            }

        } else
            return of(undefined);
    }


    _getIpAddress(deviceCode) {
        // console.log(`_getIpAddress() verso ${this.config.baseUrl} api/action/network_status `)
        if (this.config.baseUrl != null && this.config.baseUrl != undefined) {

            // console.log(`this.config.baseUrl ${this.config.baseUrl}`);
            const api = 'api/action/network_status';

            if (this.config.platform == PlatformMode.App) {

                if (this.bluetoothService.isConnected()) {

                    return from(this.bluetoothService.getDataViaBluetooth(api, true)).pipe(
                        timeout(10000),
                        map(bluetoothResponse => {

                            return calculateAndGetIP(bluetoothResponse.data);
                        }),

                        catchError(error => {

                            return of(undefined);
                        }));

                } else {

                    return from(this.nativeHttp.get(fixedEncodeURI(this.config.baseUrl + api), {}, {'Authorization': (this.config.currentUser) ? 'Bearer ' + this.config.currentUser.token : null})).pipe(
                        timeout(10000),
                        map(
                            httpResp => {


                                let res = JSON.parse((httpResp as NativeResp).data);
                                // console.log(`_getIpaddress() a pres ${res.content['ip']} debian version ${parseInt(res.content['version_id'])}`)
                                return calculateAndGetIP(res);
                            }),

                        catchError(error => {

                            //console.log("error while retrieving local ip address");
                            let errMsg = (error.message) ? error.message :
                                error.status ? `${error.status}` : error.error ? error.error : error.headers ? error.headers : 'native http conn. error';
                            // console.error(errMsg);

                            return of(undefined);

                        }));
                }

                function calculateAndGetIP(res) {

                    console.log('calculateAndGetIP() -> res is', res);

                    if (res.code === 1) {

                        const ip = res.content['ip'];
                        const interfaceName = res.content['networkInterfaceName'];

                        // Dynamic regex using the network interface name as variable from "res"
                        const filterIp = new RegExp(`${interfaceName}: <[^>]*> [^\n]*\n[^l]*link\\/ether ([^ ]*)[^\n]*\n[^i]*inet ([^\\/]*)\\/([\\d]*)`);

                        const pars = ip.match(filterIp);
                        // console.warn('pars', pars);

                        if (pars) {

                            // console.warn('{ip: pars[2], code: deviceCode}', {ip: pars[2], code: deviceCode});
                            return {'ip': pars[2], 'code': deviceCode};

                        } else {

                            console.log('No matching IP found for the provided interface name');
                            return undefined;
                        }

                    } else {

                        console.log('return undefined!');
                        return undefined;
                    }
                }
            }

        } else {
            //console.log('ramo in cui config base url è vuota')
            return of(undefined);
        }
    }

    resyncConnection(fromDisconnected?: boolean): Observable<any> {
        console.log('resyncConnection()');

        if (this.bluetoothService.isConnected()) {

            return of(new ResyncCompleted());
        }

        return this.configObs.pipe(first(), switchMap(config => {

            this.config = {...config}

            // console.log(`%cResyncConnection() env [0==mcu]:${config.environment}, compliant 200 ${config.backendCompliantRelease200} this.reloadingNetwork vale ${this.reloadingNetwork} this.resyncStarted ${this.resyncStarted}`,'font-size:1.5rem;color:Crimson;')


            if (config.platform == PlatformMode.App && (this.reloadingNetwork == NetworkStatus.Reloading || fromDisconnected) && config.environment != EnvironmentType.MCU) {


                //dovrei fare il resync solo se veramente non raggiungo la mcu via lan

                if (!this.resyncStarted) {
                    this.resyncStarted = true;

                    return from(Network.getStatus()).pipe(switchMap(status => {
                        if (status.connected && status.connectionType == 'wifi') {
                            // console.log('resync: testing NetworkStatus() - wifi from')

                            return this._getDeviceData().pipe(tap(devTap => {
                                    if (devTap != undefined) {
                                        console.log(`resync: ip sottoscritto: ${devTap.ip} devCode sottoscritto ${devTap.code} `);
                                    } else {
                                        console.log('resync: _getDeviceData() ha beccato undef, quindi andro in Remote! ')
                                    }

                                }),
                                switchMap(devData => {
                                    if (devData != undefined) {
                                        this.localIp = devData['ip'];
                                        this.localCode = devData['code'];
                                        console.log('non devo fare nulla , se sono già in LAN... dovrei fare un testi qui');
                                        timer(10000).subscribe(() => {
                                            this.resyncStarted = false;
                                        });

                                        if (this.modality == NetworkWay.LAN) {
                                            console.log(`resync: resyncConnection() NO! sono già in LAN`)
                                            return of(new ResyncCompleted());

                                        } else {
                                            console.log(`resync: resyncConnection() NON sono già in LAN, allora ci vado... ma se non sono nella mia wifi?`)


                                            let url = 'http://' + this.localIp + '/api/action/date';

                                            console.log('resync: faccio la chiamata a date usano ip locale');
                                            console.log('per capire se posso passare a LAN devo fare un dest connettendomi con l\'ip locale alla centralina e vedere se ottengo una risposta');
                                            return from(this.nativeHttp.get(fixedEncodeURI(url), {}, {'Authorization': (this.config.currentUser) ? 'Bearer ' + this.config.currentUser.token : null})).pipe(
                                                timeout(5000),
                                                switchMap(res => {
                                                    //this.IAmProbingLANConnectionSync=false;
                                                    //qui invece dovrei avercela fatta
                                                    console.log(`resync: device raggiunto via ip ${this.localIp} con successo, HO MCU NELLA MIA WIFI`);
                                                    let JSONres = JSON.parse((res as NativeResp).data);
                                                    //console.log(`config.currentMcu ${this.config.currentMcu}`);
                                                    // //console.log(`nativeHttp code: ${JSONres[0].code}`);
                                                    ////console.log(res);
                                                    // this.tabSub.unsubscribe();
                                                    console.log('Commute is goint to spread LAN');
                                                    return of(new CommutationSync(NetworkWay.LAN as NetworkWay))

                                                }),
                                                catchError(error => {
                                                    //this.IAmProbingLANConnectionSync=false;
                                                    console.log(`resync: NON SONO NELLA MIA WIFI!(QUI NON C'E' UNA MCU INLOCALE )failure local connection, device unreachable via local ip ${this.localIp}`);
                                                    let errMsg = (error.message) ? error.message :
                                                        error.status ? `${error.status}` : error.error ? error.error : error.headers ? error.headers : 'native http conn. error';
                                                    // console.error(errMsg);
                                                    if (this.modality != NetworkWay.Remote)
                                                        return of(new CommutationSyncToRecheck(NetworkWay.Remote as NetworkWay));
                                                    else
                                                        return of(new ResyncCompleted());

                                                }));


                                        }

                                    } else {
                                        this.localIp = undefined;
                                        this.localCode = undefined;
                                        timer(10000).subscribe(() => {
                                            this.resyncStarted = false;
                                        });

                                        // console.log(`resync: lancio CommutationSync perchè devData è undefined`);
                                        if (this.modality != NetworkWay.Remote)
                                            return of(new CommutationSyncToRecheck(NetworkWay.Remote as NetworkWay));
                                        else
                                            return of(new ResyncCompleted());
                                    }
                                }));

                        } else if (status.connected && status.connectionType != 'wifi') {
                            // console.log('%cResyncConnection probeNetworkStatus() - phoneConnection - lancio ResyncCompleted','font-size:1.5rem;color:Crimson;')
                            //this.store.dispatch(new CommutationSuccess(NetworkWay.Remote));
                            return of(new ResyncCompleted());
                        }

                    }));  //chiuso return from(Network.getStatus())


                } else {
                    // console.log('%cResyncConnection, IL RESYNC è GIà STATO LANCIATO E QUALCUNO LO STA CHIAMANDO NUOVAMENTE => of(EMPTY)','font-size:1.5rem;color:Crimson;');
                    return of(new ResyncAlredyyStarted());
                }

            } else {
                // console.log('%cResyncConnection, non sono in modalità App, lancio ResyncCompleted','font-size:1.5rem;color:Crimson;');
                return of(new ResyncCompleted());
            }

        })); //chiuso config sub
    }

    probeNetworkStatus(): Observable<NetworkWay> {

        return this.configObs.pipe(first(), switchMap(config => {

            this.config = {...config};

            if (this.bluetoothService.isConnected()) {

                return of(NetworkWay.BLUETOOTH).pipe(tap(status => {

                    this._getDeviceData().pipe(tap(devData => {

                        console.warn('devData', devData);

                        if (devData != null) {

                            this.localIp = devData['ip'];
                            this.localCode = devData['code'];
                        }
                    }));
                }));
            }

            console.log(`probeNetworkStatus | Platform: ${this.config.platform} | Environment: ${this.config.environment} | Compliant200: ${this.config.backendCompliantRelease200}`);

            if (this.config.platform == PlatformMode.App) {

                return from(Network.getStatus()).pipe(switchMap(status => {

                    if (status.connected && status.connectionType == 'wifi' && this.config.environment != EnvironmentType.MCU) {

                        console.log('probeNetworkStatus Mobile - WiFi')

                        return this._getDeviceData().pipe(tap(devTap => {

                                if (devTap) {

                                    console.log(`ip sottoscritto: ${devTap.ip} devCode sottoscritto ${devTap.code} `);

                                } else {

                                    console.log('_getDeviceData() ha beccato undef, quindi andro in Remote! ')
                                }
                            }),

                            switchMap(devData => {

                                if (devData != undefined) {
                                    this.localIp = devData['ip'];
                                    this.localCode = devData['code'];

                                } else {

                                    this.localIp = undefined;
                                    this.localCode = undefined;
                                    timer(10000).subscribe(() => {
                                        this.resyncStarted = false;
                                    });

                                    return of(NetworkWay.Remote);
                                }

                                return this._commuteToLAN().pipe(
                                    takeUntil(this.zeroConfSubject),
                                    tap(tapAction => {
                                        timer(10000).subscribe(() => {
                                            this.resyncStarted = false;
                                        });
                                        console.log(`row 585 action che viene su da _commuteToLAN vale ${tapAction.modality}`)
                                    }),
                                    switchMap(action => {
                                        console.log(`http-status return ${action.modality} action.modality`);
                                        return of(action.modality)
                                    }));
                            }));

                    } else if (status.connected && status.connectionType != 'wifi') {

                        console.log('probeNetworkStatus Mobile - Remote')
                        return of(NetworkWay.Remote);

                    } else if (status.connected && status.connectionType == 'wifi' && this.config.environment == EnvironmentType.MCU) {

                        console.log('probeNetworkStatus Mobile - Discovery')
                        return of(NetworkWay.DISCOVERY);

                    } else if (status.connected && status.connectionType == 'wifi' && this.config.environment != EnvironmentType.MCU) {

                        console.log('probeNetworkStatus Mobile - Remote')
                        return of(NetworkWay.Remote);
                    }
                }));
            }

            return of(NetworkWay.Remote)
        }));
    }

    probeNetworkStatusForLogin(): Observable<NetworkWay> {
        // console.log('probeNetworkStatusForLogin()');

        this.zeroConfSubject.next(true);
        console.error('the zero conf has suppressed the commutation to LAN request!');

        //qui do per scontato di essere in config.environemnt == EnvironmentType.MCU

        return this.configObs.pipe(first(),
            switchMap((configStatus) => {

                if (configStatus.platform == PlatformMode.App || configStatus.platform == PlatformMode.Browser) {
                    // console.log(`ho passato il testo di config.platform, infatti vale ${configStatus.platform}`);


                    return from(Network.getStatus()).pipe(switchMap(status => {
                        // console.log(`Network.getStatus() conn ${status.connected} connType ${status.connectionType}`);


                        if (status.connected && status.connectionType != 'wifi') {
                            // console.log('probeNetworkStatus() - phoneConnection')
                            //devo restituoire remote ma ora per fare dei test con il broswer avevo messo discovery
                            return of(NetworkWay.Remote);
                        } else if (status.connected && status.connectionType == 'wifi') {
                            // console.log('probeNetworkStatus() - wifi')
                            return of(NetworkWay.DISCOVERY);
                        }

                    }));


                } else {
                    // console.log(`%cNon ho passato il testo di config.platform, infatti vale ${this.config.platform}`,'font-size:1.5rem;color:red;');
                    return of(NetworkWay.Remote);
                }

            })) //fine switchMap configStatus
    }

    _commuteToLAN() {
        return this.commuteConnectionToLAN().pipe(
            tap(res => { //console.warn('ho commutato'); //console.log(res)
            }),

            // If successful, dispatch success action with result
            map(data => new CommutationSuccess(data as NetworkWay)),
            // If request fails, dispatch failed action
            catchError((err) => of(new CommutationError(NetworkWay.Remote as NetworkWay)))
        );
    }

    changeBaseUrl(mod: NetworkWay) {

        return this.configObs.pipe(first(), switchMap((config: Config) => {

            // console.log(`changeBaseUrl, attualmente baseUrl vale ${config.baseUrl}`)

            // console.log(`NetworkWay vale ${mod}`);

            if (mod == NetworkWay.DISCOVERY) {
                config.baseUrl = this.window['BASE_URL'];
                this.store.dispatch(new ChangeConfigValue('baseUrl', this.window['BASE_URL']));
                // console.log(`EnvironmentType vale ${this.config.environment}`)
                // console.log(`this.store.dispatch(new ChangeConfigValue('baseUrl',${this.window['BASE_URL']} ));`)
                return of(this.config.baseUrl);
            }

            if (mod == NetworkWay.LAN && this.localIp != undefined) {
                this.store.dispatch(new ChangeConfigValue('baseUrl', 'http://' + this.localIp + '/'));
                // console.log(`EnvironmentType vale ${config.environment}`)
                // console.log(`this.store.dispatch(new ChangeConfigValue('baseUrl',${'http://'+this.localIp+'/'} ));`)
                return of('http://' + this.localIp);
            } //else return of(throwError('unable to change the base url'))


            if (mod == NetworkWay.Remote || mod == NetworkWay.BLUETOOTH) {

                console.warn(config.currentMcu);

                //console.log('changeBaseUrl - Remote');
                let newBaseUrl = '';
                switch (config.environment) {
                    case EnvironmentType.MCU:
                        newBaseUrl = config.baseUrl = '';
                        //config.loginUrl = config.baseUrl + 'api/login';
                        break;
                    case EnvironmentType.Portal:
                        if (config.currentMcu) {
                            newBaseUrl = '/portal/api/apiproxy/' + config.currentMcu + '/';
                        } else {
                            newBaseUrl = null;
                        }
                        //config.loginUrl = '/portal/login';
                        break;
                    case EnvironmentType.App:
                        if (config.currentMcu) {
                            newBaseUrl = config.portalUrl + '/portal/api/apiproxy/' + config.currentMcu + '/';
                        } else {
                            newBaseUrl = null;
                        }
                        //config.loginUrl = config.portalUrl + '/portal/login';
                        break;
                    case EnvironmentType.IonicTest:
                        if (config.currentMcu) {
                            newBaseUrl = config.portalUrl + '/portal/api/apiproxy/' + config.currentMcu + '/';
                        } else {
                            newBaseUrl = null;
                        }
                        //config.loginUrl = config.portalUrl + '/portal/login';
                        break;
                    case EnvironmentType.Test:
                        //config.currentMcu = this.window['CURRENT_MCU'];
                        newBaseUrl = this.window['BASE_URL'];
                        //config.loginUrl = config.portalUrl + '/portal/login';
                        break;
                }

                // console.log(`EnvironmentType vale ${config.environment}`)
                // console.log(`this.store.dispatch(new ChangeConfigValue('baseUrl',${newBaseUrl} ));`)
                this.store.dispatch(new ChangeConfigValue('baseUrl', newBaseUrl));
                return of(newBaseUrl);
            }
        }));
    }

    socketReset() {
        //console.log('socketReset()');
        this.socketService.openSocket();
        return of([null])
    }

    commuteConnectionToLAN() {
        console.log(`commuteConnectionToLAN() mod: vale ${this.modality}`);

        if (this.localIp == undefined || this.localCode == undefined) {
            // console.error('localIp is currently undefined or localCode is current undefined');
            //se c'è qualcosa che non va devo sempre passare per il portale

            return of(NetworkWay.Remote);

        } else {
            // console.error(`localIp is set: ${this.localIp} and localCode is ${this.localCode}`);
        }

        //devo fare commute to Lan solo se sono nella app

        if (this.config.platform == PlatformMode.App && this.config.backendCompliantRelease200 && this.config.environment != EnvironmentType.MCU) {
            // console.log(`commuteConnectionToLAN  `);
            // console.log(`current modality is ${this.modality} this.IAmProbingLANConnection ${this.IAmProbingLANConnection}`);
            // console.log(`test code: ${this.config.currentMcu} == ${this.localCode}`);

            //this.modality==NetworkWay.Remote &&
            if (!this.IAmProbingLANConnection && this.config.currentMcu == this.localCode) {

                this.IAmProbingLANConnection = true;

                let url = 'http://' + this.localIp + '/api/action/date';

                console.log(`faccio la chiamata a date usano ip locale ${this.localIp}`);
                //per capire se posso passare a LAN devo fare un dest connettendomi con l'ip locale alla centralina e vedere se ottengo una risposta
                return from(this.nativeHttp.get(fixedEncodeURI(url), {}, {'Authorization': (this.config.currentUser) ? 'Bearer ' + this.config.currentUser.token : null})).pipe(
                    timeout(8000),
                    switchMap(res => {
                        this.IAmProbingLANConnection = false;
                        //qui invece dovrei avercela fatta
                        // console.log(`device raggiunto via ip ${this.localIp} con successo`);
                        let JSONres = JSON.parse((res as NativeResp).data);
                        //console.log(`config.currentMcu ${this.config.currentMcu}`);
                        // //console.log(`nativeHttp code: ${JSONres[0].code}`);
                        ////console.log(res);
                        // this.tabSub.unsubscribe();
                        //console.log('Commute is goint to spread LAN');
                        return of(NetworkWay.LAN)

                    }),
                    catchError(error => {

                        console.warn('catchError - this.nativeHttp', error);
                        this.IAmProbingLANConnection = false;
                        // console.log(`failure local connection, device unreachable via local ip ${this.localIp}`);
                        let errMsg = (error.message) ? error.message :
                            error.status ? `${error.status}` : error.error ? error.error : error.headers ? error.headers : 'native http conn. error';
                        // console.error(errMsg);
                        //this.tabSub.unsubscribe();
                        return of(NetworkWay.Remote);
                    }));

            } /* else {
		        	if(this.modality==NetworkWay.LAN){

		        		return of(this.modality);
		        	} */
            //in caso io abbia già fatto un probe verso LAN

            if (this.config.currentMcu == this.localCode) {

                return of(this.modality);
            } else {

                console.log(`%cOcchio! ${this.config.currentMcu} è diversa da ${this.localCode}`, 'font-size:2rem;color:red;');
                return of(NetworkWay.Remote)
            }
        }

        console.warn('sono arrivato alla fine: NetworkWay.Remote', this.modality);
        //se non ho fatto nulla di quanto sopra, restituisco la modalità attuale
        // return of(NetworkWay.Remote)
        return of(this.modality);

        // }));
        // return this.http.put(this.baseUrl + 'api/action/' + action, body ,{headers:_headers});
    }

    dispatchCommutation(status, fromBleDisconnect = false) {

        // console.log(`Network status changed from listener: ${status.connected} ${status.connectionType}`);

        // If the Bluetooth is Connected -> I don't care about other connections -> The APP will work through Bluetooth until it's restarted
        if (this.bluetoothService.isConnected()) {

            // console.log('Bluetooth detected');
            return;
        }

        // console.log('Using: ' + status.connectionType);

        if (status.connected && status.connectionType == 'wifi' && this.config.environment != EnvironmentType.MCU) {

            // console.log(`NetworkHandlerInit() - wifi IAmProbingIP ${this.IAmProbingIP}`);
            if (!this.IAmProbingIP) {

                console.warn('this.IAmProbingIP');

                this.IAmProbingIP = true;
                this.ipSub = this._getDeviceData(fromBleDisconnect).subscribe(devData => {
                    this.IAmProbingIP = false;
                    // console.log(`ip sottoscritto: ${devData['ip']} code ${devData['code']} `)
                    this.localIp = devData['ip'];
                    this.localCode = devData['code'];

                    console.warn('after _getDeviceData');
                    this.commSub = this._commuteToLAN().pipe(takeUntil(this.zeroConfSubject)).subscribe((action) => {
                        // console.log(`NetSatChaeHan() _commSub action modality: ${action.modality}`);
                        this.store.dispatch(action);
                        console.warn('this.commSub', this.commSub);

                        if (this.commSub) {

                            this.commSub.unsubscribe();
                        }
                    });
                    this.ipSub.unsubscribe();
                });
            }

        } else if (status.connected && status.connectionType != 'wifi') {
            // console.log('NetworkHandlerInit() phoneConnection');
            this.store.dispatch(new CommutationSuccess(NetworkWay.Remote));

        } else if (status.connected && status.connectionType == 'wifi' && this.config.environment == EnvironmentType.MCU) {
            this.store.dispatch(new CommutationSuccess(NetworkWay.DISCOVERY));
        }
    }
}

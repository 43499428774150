import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from "@angular/common";

import {CollapseComponent} from './collapse/collapse.component';
import {CounterComponent} from './counter/counter.component';
import {IconsComponent} from './icons/icons.component';
import { TimerComponent } from './timer/timer.component';
import {ScheduleOption} from './schedule/schedule-option';
import {ScheduleComponent} from './schedule/schedule.component';
import {ScheduleGraphComponent} from './schedule-graph/schedule-graph.component';
import {TechComponent} from './tech/tech.component';
import {WeatherComponent} from './weather/weather.component';
import {ButtonCollapseComponent} from './button-collapse/button-collapse.component';
import {SelectAllComponent} from './select-all/select-all.component';
import { FormsModule } from '@angular/forms';
import {PipesModule} from '../pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { PastCalibrationsComponent } from './calibration-hidew/past-calibrations/past-calibrations.component';
import { CalibrationStartTextComponent } from './calibration-hidew/calibration-start-text/calibration-start-text.component';
import { CalibrationTableComponent } from './calibration-hidew/calibration-table/calibration-table.component';
import { NavComponentCalibrationHidew } from './calibration-hidew/nav/nav.component';
import { RelatedEntitiesComponent } from './related-entities/related-entities.component';
import {TimerIconComponent} from "./timer-icon/timer-icon.component";
import {IonicModule} from "@ionic/angular";
import {CircleCountdownComponent} from "./circle-countdown/circle-countdown.component";
import {HpControlModeComponent} from "./hp-control-mode/hp-control-mode.component";
import {ToggleRowComponent} from "./toggle-row/toggle-row.component";
import {TechNodataBlockComponent} from "./tech/tech-nodata-block/tech-nodata-block.component";
import {FloorWarmerComponent} from "./floor-warmer/floor-warmer.component";
import {
    SharedErvHrvScheduleComponent
} from "../pages/system-ars/shared-erv-hrv-schedule/shared-erv-hrv-schedule.component";
import {BackButtonCustomComponent} from "./back-button-custom/back-button-custom.component";
import {IconHrvComponent} from "./icon-hrv/icon-hrv.component";
import {SetpointComponent} from './setpoint/setpoint.component';
import {ShowSetpointComponent} from './show-setpoint/show-setpoint.component';
import {MacroZoneSetpointsComponent} from "../pages/macro-zone/macro-zone-setpoints/macro-zone-setpoints.component";
import {ShowZoneOverrideComponent} from "./show-zone-override/show-zone-override.component";
import {DehumCollapseComponent} from "./dehum-collapse/dehum-collapse.component";
import {HumidificationCollapseComponent} from "./humidification-collapse/humidification-collapse.component";
import {AirQualityCollapseComponent} from "./air-quality-collapse/air-quality-collapse.component";
import {CodeValidatorComponent} from './code-validator/code-validator.component';
import {AlarmIconsComponent} from "./icons/alarm-icons/alarm-icons.component";

@NgModule({
    imports: [
        CommonModule,
        PipesModule,
        TranslateModule,
        FormsModule,
        IonicModule
    ],

    declarations: [CodeValidatorComponent,ShowSetpointComponent, SetpointComponent, NavComponentCalibrationHidew, CalibrationTableComponent, CalibrationStartTextComponent, PastCalibrationsComponent, ButtonCollapseComponent, ScheduleOption, TimerComponent, CollapseComponent, CounterComponent, IconsComponent, ScheduleComponent, ScheduleGraphComponent, TechComponent, WeatherComponent, RelatedEntitiesComponent, TimerIconComponent, CircleCountdownComponent, HpControlModeComponent, ToggleRowComponent, TechNodataBlockComponent, FloorWarmerComponent, SharedErvHrvScheduleComponent, BackButtonCustomComponent, IconHrvComponent, MacroZoneSetpointsComponent, ShowZoneOverrideComponent, DehumCollapseComponent, HumidificationCollapseComponent, AirQualityCollapseComponent, AlarmIconsComponent],
    exports: [CodeValidatorComponent,ShowSetpointComponent, SetpointComponent, NavComponentCalibrationHidew, CalibrationTableComponent, CalibrationStartTextComponent, PastCalibrationsComponent, ButtonCollapseComponent, ScheduleOption, CollapseComponent, TimerComponent, CounterComponent, IconsComponent, ScheduleComponent, ScheduleGraphComponent, TechComponent, WeatherComponent, RelatedEntitiesComponent, TimerIconComponent, CircleCountdownComponent, HpControlModeComponent, ToggleRowComponent, FloorWarmerComponent, SharedErvHrvScheduleComponent, BackButtonCustomComponent, IconHrvComponent, MacroZoneSetpointsComponent, ShowZoneOverrideComponent, DehumCollapseComponent, HumidificationCollapseComponent, AirQualityCollapseComponent, AlarmIconsComponent],
	schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ComponentsModule{}

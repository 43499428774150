import {Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {AppState} from '../../services/app-state.service';
import {Store} from '@ngrx/store';
import {Config} from '../../models/config';
import {MCU, HYS, GRP, MXV, ATU, ENR, DHW, TNK, TMR, ARS, FNC, Table, FWA} from '../../models/tables';
import {BitArray} from '../../commons/bitarray';

import {Constants} from '../../commons/const';
import {ARS_Extended, ENR_Extended, GRP_Extended} from "../../models/tables-extended";
import {FlagsGEN, FlagsGroup} from "../../commons/flags";
import * as _ from 'lodash';
import {Router} from "@angular/router";

export class ShowPowerOff {

    Element_PAR_On: boolean; // Some elements, like the TNK, is always ON
    MCU_PAR_On: boolean;
    showPowerOffIconOnly: boolean; // if true -> no other icons will be shown except the power-off (or nothing if MCU_Par_On is true). Useful to show a power-off icon near other status icons

    constructor(MCU_PAR_On, Element_PAR_On = true, showPowerOffIconOnly = false) {

        this.Element_PAR_On = Element_PAR_On;
        this.MCU_PAR_On = MCU_PAR_On;
        this.showPowerOffIconOnly = showPowerOffIconOnly;
    }
}

// ---------------------------------------------------------------------------------------------------------------------

enum TechNoDataBlockPipes {

    nothing = 'nothing',
    temperature = 'temperature',
    temperatureNoUnit = 'temperatureNoUnit',
    pctNoUnit = 'pctNoUnit',
    number_double_digit = 'number_double_digit', // { value | number : '.00' }}
}

export class TechNoDataBlock {

    public label: string;
    public title: string;
    public icon: string;
    public value: any;
    public pipe: TechNoDataBlockPipes;
    public additionalTextAfterValue: string;
    public textAfterValueClass: string;
    public TechNoDataBlockPipes = TechNoDataBlockPipes;

    constructor(label, title, icon, value, pipe, additionalTextAfterValue = '', textAfterValueClass = '') {

        this.label = label;
        this.title = title;
        this.icon = icon + ' icon';
        this.value = value;
        this.pipe = pipe;
        this.additionalTextAfterValue = additionalTextAfterValue;
        this.textAfterValueClass = textAfterValueClass;
    }
}

// ---------------------------------------------------------------------------------------------------------------------

@Component({
    selector: 'tech',
    templateUrl: './tech.component.html',
    styleUrls: ['./tech.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default // OnPush non fa il refresh automatico dei cambiamenti
})

export class TechComponent implements OnInit {


    @Input()
    itemClass: string;

    @Input()
    config: Config;

    @Input()
    HYS: HYS[];

    @Input()
    GRP: GRP[];

    @Input()
    MXV: MXV[];

    @Input()
    ATU: ATU[];

    @Input()
    allATU: ATU[]; //utilzzato per avere il vettore di atu nella pagina Air system

    @Input()
    ENR: ENR[];

    @Input()
    DHW: DHW[];

    @Input()
    TNK: TNK[];

    @Input()
    TMR: TMR[];

    @Input()
    MCU: MCU;

    @Input()
    ars: ARS | ARS_Extended

    @Input()
    FNC: FNC[];

    @Input()
    FWA: FWA[];

    @Input()
    customData: any;

    @Input()
    singleDHW: boolean;


    @Input()
    singleFNC: boolean;

    @Input()
    disabled: boolean;

    @Input()
    nodata: boolean;

    @Input()
    isArsFromSystemZonePage: boolean = false;

    @Input()
    showPowerOffIcon: boolean = false;

    @Input()
    hasBlackBackground: boolean; // Used for the <icon-hrv> component, so I can change the SVG based if I have a white or black background

    @Output()
    onChangeTech = new EventEmitter<Table>();

    @Output()
    onDirectClick = new EventEmitter;

    public NO_VALUE: number;

    public atuNoDataBlocks = [];
    public enrNoDataBlocks = [];
    public fncNoDataBlocks = [];

    public blockClasses = 'col-md-6 col-mdH-3 col-smBig-6 col-6 col-sm-6 col-lg-3 col-xl-3 maxHeight'; // These are the standard classes always used in the blocks of properties

    constructor(protected store: Store<AppState>, public router: Router) {
        this.NO_VALUE = Constants.NO_VALUE;
    }

    ngOnInit() {
    }

    ngOnChanges(inputs): void {

        // Basically only for the Homepage where [nodata]="false"
        if (!this.nodata) {

            if (inputs && inputs.ATU && !_.isEqual(inputs.ATU.currentValue, inputs.ATU.previousValue)) {

                this.atuNoDataBlocks.length = 0;

                for (const atu of this.ATU) {

                    this.atuNoDataBlocks.push(this.getAtuTechNoDataBlocks(atu));
                }
            }

            if (inputs && inputs.ENR && !_.isEqual(inputs.ENR.currentValue, inputs.ENR.previousValue)) {

                this.enrNoDataBlocks.length = 0;

                for (const enr of this.ENR) {

                    this.enrNoDataBlocks.push(this.getEnrTechNoDataBlocks(enr));
                }
            }

            if (inputs && inputs.FNC && !_.isEqual(inputs.FNC.currentValue, inputs.FNC.previousValue)) {

                this.fncNoDataBlocks.length = 0;

                for (const fnc of this.FNC) {

                    this.fncNoDataBlocks.push(this.getFncTechNoDataBlocks(fnc));
                }
            }
        }
    }

    isHighDew(model) {
        return Number(model) == 0;
    }

    isValidCFG(cfgField) {
        return Number(cfgField) != 0;
    }

    isCfgAISupply(fnc: FNC) {
        if (fnc != undefined)
            return fnc.CFG_CfgAiSupply != 0;
        else return false;
    }


    isCfgAIReturn(fnc: FNC) {
        if (fnc != undefined)
            return fnc.CFG_CfgAiReturn != 0;
        else return false;
    }

    merge(f1: number[], f2: number[]) {
        let f = new Array(21).fill(0);
        for (let j = 0; j < f1.length; j++) {
            f[j] = f1[j] || f2[j];
        }
        return [...f];
    }


    viewEnr(enr: ENR): boolean {

        if (enr) {

            return this.isModbusHP(enr) || (enr?.CFG_Ctrl == 1 && enr?.CFG_CtrlMode == 3) || (enr?.CFG_Ctrl == 3 && enr?.CFG_CtrlMode == 2);
        }
    }

   isModbusHP(enr: ENR) {

        return ENR_Extended.isModbusHP(enr);
    }

  isAdvancedModbusHP(enr: ENR) {

      return ENR_Extended.isAdvancedModbusHP(enr);
  }


    isATUwithHumidifier(atu: ATU) {

        if (atu.CFG_IdxATU != -1) {


            if (!this.ars && !this.allATU) {

                return this.ATU[atu.CFG_IdxATU].CFG_Type == 0;
            }

            if (!this.ars && this.allATU) {

                return this.allATU[atu.CFG_IdxATU].CFG_Type == 0;
            }


            if (this.ars && !this.allATU)
                return this.allATU[atu.CFG_IdxATU].CFG_Type == 0;
        }

        return false;
    }

    isHumidifier(atu: ATU) {
        if (atu.CFG_Type == 0 && atu.CFG_CanHum) {
            let foundNTD1200 = false;
            for (let i = 0; i < this.ATU.length; i++) {
                if (!this.ars)
                    if (this.ATU[i]?.CFG_IdxATU == atu.id)
                        foundNTD1200 = true;
                if (this.ars)
                    if (this.allATU[i]?.CFG_IdxATU == atu.id)
                        foundNTD1200 = true;
            }
            return foundNTD1200;
        }
        return false;
    }

    getHumidifier(atu: ATU) {
        if (!this.ars && !this.allATU)
            return this.ATU[atu.CFG_IdxATU];
        if (!this.ars && this.allATU)
            return this.allATU[atu.CFG_IdxATU];
        if (this.ars)
            return this.allATU[atu.CFG_IdxATU];
    }


    checkATU4TempEvap(atu: ATU) {
        return (atu.CFG_Type == 1 || atu.CFG_Type == 11 || atu.CFG_Type == 14 ||
            atu.CFG_Type == 16 || atu.CFG_Type == 34 || atu.CFG_Type == 36
            || atu.CFG_Type == 26 || atu.CFG_Type == 4 || atu.CFG_Type == 9) && atu.CFG_Ctrl == 1
    }

    checkATU4TempCond(atu: ATU) {

        return this.checkATU4TempEvap(atu)
    }

    checkATU4TempAirIn(atu: ATU) {
        var bitarray = new BitArray(24).fromNumber(atu.RTT_State)
        return ((atu.CFG_Type == 1 || atu.CFG_Type == 11 || atu.CFG_Type == 14 || atu.CFG_Type == 16 ||
                atu.CFG_Type == 34 || atu.CFG_Type == 36 || (atu.CFG_Type == 100 && bitarray.get(7)) || (atu.CFG_Type == 102 && bitarray.get(7))) && atu.CFG_Ctrl == 1)
            || (atu.CFG_Type == -1)
    }

    checkATU4HrAirIn(atu: ATU) { //abbiamo tolto tempraneamente, in data 4/11/2020 atu.CFG_Type == 34
        return (atu.CFG_Type == 16 || atu.CFG_Type == 36) && atu.CFG_Ctrl == 1
    }

    checkATU4TempH2oIn(atu: ATU) {
        return (atu.CFG_Type == 1 || atu.CFG_Type == 11 ||
            atu.CFG_Type == 16 || atu.CFG_Type == 34 ||
            atu.CFG_Type == 36 || atu.CFG_Type == 100 || atu.CFG_Type == 102 || atu.CFG_Type == 101) && atu.CFG_Ctrl == 1
    }

    checkATU4ExtTemp(atu: ATU) {
        var bitarray = new BitArray(24).fromNumber(atu.RTT_State)
        return atu.CFG_Type == 34 || atu.CFG_Type == 36 || atu.CFG_Type == 103 || (atu.CFG_Type == 100 && bitarray.get(5) || (atu.CFG_Type == 102 && bitarray.get(5)))
            && atu.CFG_Ctrl == 1
    }

    checkATU4FlowLevel(atu: ATU) {
        return (atu.CFG_Type == 100 || atu.CFG_Type == 102 || atu.CFG_Type == 103) && atu.CFG_Ctrl == 1
    }

    checkATU4Hum(atu: ATU) {
        return (atu.CFG_Type == 100 || atu.CFG_Type == 102) && atu.CFG_Ctrl == 1
    }

    checkATU4EnvTemp(atu: ATU) {
        return (atu.CFG_Type == 100 || atu.CFG_Type == 102 || atu.CFG_Type == 103) && atu.CFG_Ctrl == 1;
    }

    arsDirectClick(event) {

        this.onDirectClick.emit(event);
    }

    arsClick(event, ars: ARS) {
        ars.PAR_On = event.detail.checked;
        this.onChangeTech.emit(ars);
    }

    dhwClick(event, dhw: DHW) {
        dhw.PAR_OnB = event.detail.checked;
        this.onChangeTech.emit(dhw);
    }

    hysClick(event, hys: HYS) {
        hys.PAR_On = event.detail.checked;
        this.onChangeTech.emit(hys);
    }

    fncClick(event, fnc: FNC) {
        // console.warn('emetto fncClick, fnc vale')
        // console.log(fnc);
        fnc.PAR_On = event.detail.checked;
        this.onChangeTech.emit(fnc);
    }

    enrClick(event, enr: ENR) {

        enr.PAR_On = event.detail.checked;
        this.onChangeTech.emit(enr);
    }

    tnkClick(event, tnk: TNK) {
        // console.warn('emetto fncClick, fnc vale')
        // console.log(fnc);
        tnk.state = event.detail.checked;
        this.onChangeTech.emit(tnk);
    }

    getParSeasonIcon(grp: GRP_Extended) {

        if (grp) {

            return grp.getParSeasonIcon();
        }

        // If there isn't a grp (for example with DHW) -> I return the orange circle
        else {

            return 'fa-circle messana-orange';
        }

    }

    getExtendedGrp(element): GRP_Extended {

        if ('grp' in element) {

            return element.grp;
        }

        if (element instanceof ENR_Extended) {

            // If it's extended ENR and has a TNK -> I want the group (can be heating / cooling)
            if (element.tnkList[0]) {

                return element.tnkList[0].grp;
            }

            // If it's extended ENR and has a DHW -> There is no GRP, so I return null. If it's null, I will return an orange circle later
            if (element.dhwList[0]) {

                return null;
            }
        }
    }

    // show(item){
    //   console.log(item)
    // }
    showPowerOff(element) {

        if (!this.showPowerOffIcon) {

            return null;
        }

        let element_PAR_On = true;

        if (element && element.hasOwnProperty('PAR_On') && !element.PAR_On) { // Things like ENR / TNK don't have a PAR_On, so they are always true (if they have a PAR_On i check them)

            element_PAR_On = false;
        }

        if (element instanceof ATU) { // The ATU doesn't have a PAR_On, so I must use the bit 1 (OFF) in the RTU_FLags of the ATU | 0 = enabled (in the enum it's called DISABLED though, because it's used only for the ATU at the moment)

            element_PAR_On = element.RTU_Flags[FlagsGroup.GEN] === FlagsGEN.DISABLED;
        }

        const MCU_PAR_On = this.MCU && this.MCU.PAR_On;

        return new ShowPowerOff(MCU_PAR_On, element_PAR_On);
    }

    getFncTechNoDataBlocks(fnc: FNC): TechNoDataBlock[] {

        const WATER_SUPPLY_TEMPERATURE = 'WATER_SUPPLY_TEMPERATURE'; // Index 2 (position 3) (supply)
        const WATER_RETURN_TEMP = 'WATER_RETURN_TEMP'; // Index 3 (position 4) (return)
        const ROOM_AIR_SUPPLY_TEMPERATURE = 'ROOM_AIR_SUPPLY_TEMPERATURE'; // (alternativa to supply)

        // -------------------------------------------------------------------------------------------------------------
        // --- CREATE ALL BLOCKS ---------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        const defaultBlocks: TechNoDataBlock[] = [];

        // -------------------------------------------------------------------------------------------------------------

        // supply
        if (this.isHighDew(fnc?.CFG_Model) || this.isCfgAISupply(fnc)) {

            defaultBlocks.push(new TechNoDataBlock(WATER_SUPPLY_TEMPERATURE, WATER_SUPPLY_TEMPERATURE, 'icon-supply', fnc?.RTU_TempH2O, TechNoDataBlockPipes.temperature));
        }

        // return
        if (this.isCfgAIReturn(fnc)) {

            defaultBlocks.push(new TechNoDataBlock(WATER_RETURN_TEMP, WATER_RETURN_TEMP, 'icon-return', fnc?.RTU_TempH2OReturn, TechNoDataBlockPipes.temperature));
        }

        // Room air supply temp (alternative)
        if (this.isHighDew(fnc?.CFG_Model)) {

            defaultBlocks.push(new TechNoDataBlock(ROOM_AIR_SUPPLY_TEMPERATURE, ROOM_AIR_SUPPLY_TEMPERATURE, 'fal fa-thermometer-half', fnc?.RTU_TempEnv, TechNoDataBlockPipes.temperature));
        }

        // Room air supply temp (different hiDew model)
        if (!this.isHighDew(fnc?.CFG_Model) && this.isValidCFG(fnc?.CFG_CfgAi)) {

            defaultBlocks.push(new TechNoDataBlock(ROOM_AIR_SUPPLY_TEMPERATURE, ROOM_AIR_SUPPLY_TEMPERATURE, 'fal fa-thermometer-half', fnc?.RTT_SupplyAirT, TechNoDataBlockPipes.temperature));
        }

        return this.rearrangeNoDataBlocks(defaultBlocks, WATER_SUPPLY_TEMPERATURE, WATER_RETURN_TEMP, ROOM_AIR_SUPPLY_TEMPERATURE);
    }

    getEnrTechNoDataBlocks(enr: ENR): TechNoDataBlock[] {

        const H_C_SOURCES_DELIVERY_TEMP = 'H_C_SOURCES_DELIVERY_TEMP'; // Index 2 (position 3) (supply)
        const H_C_SOURCE_RETURN_TEMP = 'H_C_SOURCE_RETURN_TEMP'; // Index 3 (position 4) (return)

        // -------------------------------------------------------------------------------------------------------------
        // --- CREATE ALL BLOCKS ---------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        const defaultBlocks: TechNoDataBlock[] = [];

        // -------------------------------------------------------------------------------------------------------------

        // target temp
        if (this.isModbusHP(enr)) {

            defaultBlocks.push(new TechNoDataBlock('H_C_SOURCE_TARGET_TEMP', 'H_C_SOURCE_TARGET_TEMP', 'far fa-crosshairs', enr?.RTT_TargetTemp, TechNoDataBlockPipes.temperature));
        }

        // supply
        if (enr.CFG_CfgAiSupply != 0 || this.isModbusHP(enr)) {

            defaultBlocks.push(new TechNoDataBlock(H_C_SOURCES_DELIVERY_TEMP, H_C_SOURCES_DELIVERY_TEMP, 'icon-supply', enr?.RTT_H2O_Supply, TechNoDataBlockPipes.temperature));
        }

        // return
        if (enr.CFG_CfgAiReturn != 0 || this.isModbusHP(enr)) {

            defaultBlocks.push(new TechNoDataBlock(H_C_SOURCE_RETURN_TEMP, H_C_SOURCE_RETURN_TEMP, 'icon-return', enr?.RTT_H2O_Return, TechNoDataBlockPipes.temperature));
        }

        // outdoor temp
        if (this.isAdvancedModbusHP(enr)) {

            defaultBlocks.push(new TechNoDataBlock('H_C_SOURCE_OUTDOOR_TEMP', 'H_C_SOURCE_OUTDOOR_TEMP', 'fal fa-thermometer-half', enr?.RTT_OutdoorTemp, TechNoDataBlockPipes.temperature));
        }

        // OutputkW
        if (this.isAdvancedModbusHP(enr)) {

            const gpm = this.MCU?.PAR_UM == 0 ? 'gpm' : 'm3/h';
            const additionalText = 'font-size-09-em';

            defaultBlocks.push(new TechNoDataBlock('H_C_OUTPUT_KW', 'H_C_OUTPUT_KW', 'fa-regular fa-circle-bolt', enr?.RTT_OutputkW, TechNoDataBlockPipes.temperatureNoUnit, 'kW'));
        }

        // water flow
        if (this.isAdvancedModbusHP(enr)) {

            const additionalText = this.MCU?.PAR_UM == 0 ? 'gpm' : 'm3/h';
            const additionalClass = 'font-size-09-em';

            defaultBlocks.push(new TechNoDataBlock('H_C_SOURCE_FLOW', 'H_C_SOURCE_FLOW', 'fa-regular fa-circle-half-stroke fa-rotate-90', enr?.RTT_WaterFlow, TechNoDataBlockPipes.number_double_digit, additionalText, additionalClass));
        }

        // COP (coefficiente di prestazioni)
        if (this.isAdvancedModbusHP(enr)) {

            let icon = 'fa-gauge';

            if (enr?.RTT_COP < 1) {

                icon = 'fa-gauge-low';
            }

            if (enr?.RTT_COP >= 3) {

                icon = 'fa-gauge-high';
            }

            defaultBlocks.push(new TechNoDataBlock('COP', 'COP', 'fa-regular ' + icon, enr?.RTT_COP, TechNoDataBlockPipes.temperatureNoUnit, ': 1'));
        }

        return this.rearrangeNoDataBlocks(defaultBlocks, H_C_SOURCES_DELIVERY_TEMP, H_C_SOURCE_RETURN_TEMP);
    }

    getAtuTechNoDataBlocks(atu: ATU): TechNoDataBlock[] {

        // -------------------------------------------------------------------------------------------------------------

        const ROOM_AIR_SUPPLY_TEMP = 'ROOM_AIR_SUPPLY_TEMP'; // Index 2 (position 3)
        const WATER_SUPPLY_TEMP = 'WATER_SUPPLY_TEMP'; // Index 3 (position 4)

        // -------------------------------------------------------------------------------------------------------------
        // --- CREATE ALL BLOCKS ---------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        const defaultBlocks: TechNoDataBlock[] = [];

        // -------------------------------------------------------------------------------------------------------------

        // 1
        if (this.checkATU4TempEvap(atu)) {

            defaultBlocks.push(new TechNoDataBlock('EVAPORATION_TEMP', 'EVAPORATION_TEMPERATURE', 'icon-vap', atu?.RTT_TempEvap, TechNoDataBlockPipes.temperature));
        }

        // 2
        if (this.checkATU4TempCond(atu)) {

            defaultBlocks.push(new TechNoDataBlock('CONDENSER_TEMP', 'CONDENSER_TEMPERATURE', 'icon-cond', atu?.RTT_TempCond, TechNoDataBlockPipes.temperature));
        }

        // 3
        if (this.checkATU4TempAirIn(atu)) {

            defaultBlocks.push(new TechNoDataBlock(ROOM_AIR_SUPPLY_TEMP, 'ROOM_AIR_SUPPLY_TEMPERATURE', 'fal fa-thermometer-half', atu?.RTU_TempAirIn, TechNoDataBlockPipes.temperature));
        }

        // 4
        if (this.checkATU4HrAirIn(atu) && this.config.isRH(this.config)) {

            defaultBlocks.push(new TechNoDataBlock('ROOM_AIR_SUPPLY_RH_HUM', 'ROOM_AIR_SUPPLY_RH', 'fal fa-tint', atu?.RTU_HrAirIn, TechNoDataBlockPipes.pctNoUnit));
        }

        // 5
        if (this.checkATU4HrAirIn(atu) && this.config.isDP(this.config)) {

            defaultBlocks.push(new TechNoDataBlock('ROOM_AIR_SUPPLY_DP_HUM', 'ROOM_AIR_SUPPLY_DP', 'fal fa-dewpoint', atu?.RTU_DpAirIn, TechNoDataBlockPipes.temperatureNoUnit));
        }

        // 6
        if (this.checkATU4TempH2oIn(atu)) {

            defaultBlocks.push(new TechNoDataBlock(WATER_SUPPLY_TEMP, 'WATER_SUPPLY_TEMPERATURE', 'icon-supply', atu?.RTT_TempH2oIn, TechNoDataBlockPipes.temperature));
        }

        // 7
        if (this.checkATU4ExtTemp(atu)) {

            defaultBlocks.push(new TechNoDataBlock('EXTERNAL_TEMP', 'EXTERNAL_TEMPERATURE', 'wi wi-cloudy-windy', atu?.RTT_TempAsp, TechNoDataBlockPipes.temperature));
        }

        // 8
        if (this.checkATU4EnvTemp(atu)) {

            defaultBlocks.push(new TechNoDataBlock('ENV_TEMP', 'ENVIRONMENT_TEMPERATURE', 'fal fa-thermometer-half', atu?.RTT_Temp1, TechNoDataBlockPipes.temperature));
        }

        // 9
        if (this.checkATU4FlowLevel(atu)) {

            defaultBlocks.push(new TechNoDataBlock('FLOW_LEVEL', 'FLOW_LEVEL', 'fa fa-sort', atu?.PAR_FlowLevel, TechNoDataBlockPipes.nothing));
        }

        // 10
        if (this.checkATU4Hum(atu) && this.config.isRH(this.config)) {

            defaultBlocks.push(new TechNoDataBlock('AIR_HUM_RH', 'AIR_HUMIDITY_RH', 'fal fa-tint', atu?.RTT_HrRep, TechNoDataBlockPipes.pctNoUnit));
        }

        // 11
        if (this.checkATU4Hum(atu) && this.config.isDP(this.config)) {

            defaultBlocks.push(new TechNoDataBlock('AIR_HUM_DP', 'AIR_HUMIDITY_DP', 'fal fa-dewpoint', atu?.RTT_DpRep, TechNoDataBlockPipes.temperatureNoUnit));
        }

        return this.rearrangeNoDataBlocks(defaultBlocks, ROOM_AIR_SUPPLY_TEMP, WATER_SUPPLY_TEMP);
    }

    // used to make the supply always number 3 and return always number 4
    rearrangeNoDataBlocks(defaultBlocks, supplyLabel, returnLabel, alternativeSupplyLabel = null): TechNoDataBlock[] {

        // -------------------------------------------------------------------------------------------------------------
        // --- RE-ARRANGE THE BLOCKS / SECTIONS ------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        let supplyBlock = _.find(defaultBlocks, {label: supplyLabel});

        if (!supplyBlock && alternativeSupplyLabel) {

            supplyLabel = alternativeSupplyLabel;
            supplyBlock = _.find(defaultBlocks, {label: supplyLabel});
        }

        const returnBlock = _.find(defaultBlocks, {label: returnLabel});

        const shiftedBlocksReturned: TechNoDataBlock[] = [null, null, supplyBlock, returnBlock] as TechNoDataBlock[]; // index 2 should be filled with supply, and index 3 with return

        _.remove(defaultBlocks, {label: supplyLabel}); // I can now remove supply and return from the original array
        _.remove(defaultBlocks, {label: returnLabel});

        // -------------------------------------------------------------------------------------------------------------
        // --- Cycle every other block now and fill the "shiftedBlocksReturned" ----------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        for (const block of defaultBlocks) {

            let blockReplaced = false;

            for (let i = 0; i < shiftedBlocksReturned.length; i++) {

                // I fill the first two "null" values with other blocks
                if (!shiftedBlocksReturned[i]) {

                    blockReplaced = true;
                    shiftedBlocksReturned[i] = block;
                    break;
                }
            }

            // If there are already 4 filled slots -> just push
            if (!blockReplaced) {

                shiftedBlocksReturned.push(block);
            }
        }

        // -------------------------------------------------------------------------------------------------------------
        // --- If null values remained at the end -> remove them from the array, ex -> [object, object, n̶u̶l̶l̶, n̶u̶l̶l̶] ----
        // -------------------------------------------------------------------------------------------------------------

        const lastNonNullIndex = _.findLastIndex(shiftedBlocksReturned, (element) => !_.isNil(element));
        const shiftedBlocksReturnedCleaned = _.take(shiftedBlocksReturned, lastNonNullIndex + 1);

        // console.log(shiftedBlocksReturnedCleaned);
        return shiftedBlocksReturnedCleaned;
    }

    async goToLink(link: string, id: number, clickableElement = true) {

        if (this.config.admin && clickableElement) {

            await this.router.navigate([link], {queryParams: {idToOpen: id}});
        }
    }

    // So it doesn't refresh everytime, making the svg animation bugged
    trackByAtuId(index, item) {

        return index; // index or item.id
    }
}

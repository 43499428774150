import {Component} from '@angular/core';
import {Config, DEFAULT_LANGUAGE, PlatformMode} from './models/config';
import {AlertController, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subscription, timer} from 'rxjs';
import {MCU, Tables} from './models/tables';
import {User} from './models/user';
import {AppLoadConfig} from './actions/config.actions';
import {Socket} from './models/socket';
import {Store} from '@ngrx/store';
import {AppState} from './services/app-state.service';
import {WindowRefService} from './services/window.service';
import {Router} from '@angular/router';
import {debounceTime} from 'rxjs/operators';
import * as _ from 'lodash';
import {SplashScreen} from '@capacitor/splash-screen';

import {StatusBar, Style} from '@capacitor/status-bar';

// native stuff
import {MobileAccessibility} from '@ionic-native/mobile-accessibility/ngx';
import {HttpStatus, NetworkStatus} from "./models/http-status";
import {ActionsStatus} from "./models/actions-status";
import {AlertControllerLoaded} from "./actions/actions.actions";

enum TabNames {
    HOME = 'Dashboard',
    ZONES = 'Zones',
    SETTINGS = 'Menu'
}

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})

export class AppComponent {

    protected observables: any; // { [name: string]: Observable<any>} = {};
    protected MCU: MCU;
    protected currentUser: User;
    protected currentMcu: string;

    public connected: boolean = false;
    public window = null;
    public showSplash = false;
    public showVip = false;
    private resizeSubscribe: Subscription;
    private timerSub: Subscription;
    private emptyLoader: Subscription;

    private transitionDelay = 4000;

    private readonly tempConfigSubscription: Subscription;

    public tabNames = TabNames;
    private httpStatus: Observable<HttpStatus>;
    public isReloadingNetwork: boolean;

    private readonly permanentSocketSubscription: Subscription;
    private readonly permanentHttpStatusSub: Subscription;
    private readonly permanentConfigSubscription: Subscription;
    private readonly permanentActionsSubscription: Subscription;
    public zeroConfFromGuest: boolean;
    public useBottomTabsNavigation: boolean;
    public alarmMode: boolean;
    private showBrowserDirectIpLoaderM = true;
    private isVip;
    private readonly platformMode: PlatformMode;

    constructor(
        private platform: Platform,
        protected store: Store<AppState>,
        private router: Router,
        protected translate: TranslateService,
        protected windowRefService: WindowRefService,
        public mobileAccessibility: MobileAccessibility,
        private alertController: AlertController) {

        this.platformMode = this.platform.is('cordova') || this.platform.is('capacitor') ? PlatformMode.App : PlatformMode.Browser;

        this.platform.ready().then(async () => {

            try {

                setTimeout(async () => {

                    await SplashScreen.hide();

                }, 500);


                if (this.platformMode === PlatformMode.App) {

                    this.store.dispatch(new AlertControllerLoaded());
                    await StatusBar.setStyle({style: Style.Dark});

                } else {

                    // Ionic 4 (web) has a bug: the overlay components are registered at runtime. So if my mini-pc disconnects, and I've never seen a  Popup before -> my disconnect popup will never show
                    // Link to GitHub problem: https://stackoverflow.com/questions/66991523/how-to-eagerly-load-ion-modal-in-angular-10
                    const tempModal = await this.alertController.create({
                        cssClass: 'hidden', // this will make it totally invisible
                        animated: false, // this should prevent it from being visible
                        backdropDismiss: false, // disable interaction to prevent unexpected behavior
                        keyboardClose: false, // minimize side-effects
                    });

                    await tempModal.present();
                    await tempModal.dismiss();
                    this.store.dispatch(new AlertControllerLoaded());
                }

            } catch (err) {

                console.log(err);
            }
        });

        document.documentElement.style.setProperty('--window-width', window.innerWidth + 'px');

        this.resizeSubscribe = platform.resize.pipe(debounceTime(300)).subscribe(async () => {

            document.documentElement.style.setProperty('--window-width', window.innerWidth + 'px');
        });

        this.window = this.windowRefService.nativeWindow;
        this.observables = {

            config: store.select('config') as Observable<Config>,
            tables: store.select('tables') as Observable<Tables>,
            socket: store.select('socket') as Observable<Socket>,
            actions: store.select('actions') as Observable<ActionsStatus>
        };

        // This does never unsubscribe because I need the variable "!this.connected" to enable / disable the tabs
        if (!this.permanentSocketSubscription) {

            this.permanentSocketSubscription = this.observables.socket.subscribe(data => {

                this.connected = data.connected;
            });
        }

        this.httpStatus = this.store.select('httpStatus') as Observable<HttpStatus>;

        // This does never unsubscribe because I need the variable "this.reloadingNetwork "to enable / disable the tabs
        if (!this.permanentHttpStatusSub) {

            this.permanentHttpStatusSub = this.httpStatus.subscribe(data => {

                this.isReloadingNetwork = data.reloadingNetwork === NetworkStatus.Reloading;
            });
        }

        // This does never unsubscribe because I need the variable "this.alarmMode" to hide the Tabs if an "Alarms" popup is shown (and also showBrowserDirectIpLoaderM)
        if (!this.permanentActionsSubscription) {

            this.permanentActionsSubscription = this.observables.actions.subscribe(data => {

                this.alarmMode = data.alarmMode;

                if (data.showBrowserDirectIpLoaderM === false) {

                    this.showBrowserDirectIpLoaderM = false;
                }
            });
        }

        // This does never unsubscribe because I need the variable "this.zeroConfFromGuest" to make the Main Menu eventually hidden (and I also need this.useBottomTabsNavigation)
        if (!this.permanentConfigSubscription) {

            this.permanentConfigSubscription = this.observables.config.subscribe(data => {

                this.zeroConfFromGuest = data.zeroConfFromGuest;
                this.useBottomTabsNavigation = data.useBottomTabsNavigation;
                this.isVip = data.currentUser && data.currentUser.vip;

                if (this.useBottomTabsNavigation) {

                    document.documentElement.style.setProperty('--bottom-tabs-navigation', '60px');
                    document.documentElement.style.setProperty('--bottom-tabs-navigation-vip', '50px');
                    document.documentElement.style.setProperty('--bottom-tabs-navigation-less', '50px');
                    document.documentElement.style.setProperty('--bottom-tabs-navigation-tech', '60px');

                    const ion_safe_area_bottom = getComputedStyle(document.documentElement).getPropertyValue('--ion-safe-area-bottom').trim();
                    document.documentElement.style.setProperty('--ion-safe-area-bottom-for-tabs', ion_safe_area_bottom);

                } else {

                    document.documentElement.style.setProperty('--bottom-tabs-navigation', '1rem');
                    document.documentElement.style.setProperty('--bottom-tabs-navigation-vip', '0rem');
                    document.documentElement.style.setProperty('--bottom-tabs-navigation-less', '0rem');
                    document.documentElement.style.setProperty('--bottom-tabs-navigation-tech', '0rem');
                    document.documentElement.style.setProperty('--ion-safe-area-bottom-for-tabs', '0px');
                }
            });
        }

        this.store.dispatch(new AppLoadConfig(this.platformMode === PlatformMode.App));

        this.timerSub = null;
        this.emptyLoader = null;

        this.tempConfigSubscription = this.observables.config.subscribe(data => {

            this.translate.use(data.language || DEFAULT_LANGUAGE);
            this.currentUser = _.clone(data.currentUser);
            this.currentMcu = data.currentMcu;

            if (this.platformMode === PlatformMode.App) {

                if (!data.loaded) {

                    if (!this.emptyLoader) {

                        this.emptyLoader = timer(4250).subscribe(() => {

                            this.showSplash = true;
                        });
                    }
                }

                if (!this.timerSub && !data.currentUser && !data.currentMcu && !data.baseUrl) {

                    this.timerSub = timer(10000).subscribe(() => {

                        if (!this.currentUser || !data.baseUrl) {

                            this.tempConfigSubscription.unsubscribe();
                            this.router.navigateByUrl('/login');
                            this.showSplash = false;
                        }
                    });
                }

                if (this.currentUser) {

                    this.initializeApp();
                }
            }
        });

        this.translate.setDefaultLang(DEFAULT_LANGUAGE);

        if (this.platformMode === PlatformMode.App) {

            this.mobileAccessibility.setTextZoom(100);
        }
    }

    public ngOnDestroy() {

    }

    unsubscribeAll() {

        if (this.tempConfigSubscription) {

            this.tempConfigSubscription.unsubscribe();
        }

        if (this.timerSub) {

            this.timerSub.unsubscribe();
        }
    }

    getSplash() {

        return this.showSplash;
    }

    isDirectIpBrowserBuild() {

        return this.platformMode && this.platformMode === PlatformMode.Browser && this.window['ENVIRON'] == "mcu";
    }

    // Note: on browser from service (https://app.radiantcooling.com/home) it must not appear
    showLoaderM() {

        // Browser direct-ip (https://service.radiantcooling.com:9000/home)
        if (this.isDirectIpBrowserBuild()) {

            return this.showBrowserDirectIpLoaderM;
        }

        // Android / iOS App
        else if (this.platformMode === PlatformMode.App) {

            return this.getSplash() && this.isRootPage();
        }
    }

    isRootPage() {

        return this.router.url == '/';
    }

    isMacroZonesTabs() {

        return this.router.url == '/macro-zones' || this.router.url.split('?')[0] == '/macro-zone';
    }

    userIsInsideASystem() {

        // Used to show the black bar underneath with the 3 icons to quickly navigate to sections
        const url = this.router.url;
        return !url.includes('census') && url !== '/' && url !== '/login' && url !== '/discovery' && url !== '/forgot-password' && url !== '/mcu-select' && url !== '/menu-mcu-select';
    }

    // unused (for now -> don't delete)
    tabIsSelected(tab) {

        switch (tab) {

            case this.tabNames.HOME:
                return this.router.url === '/home';

            case this.tabNames.ZONES:
                return this.router.url === '/macro-zones';

            case this.tabNames.SETTINGS:
                return this.router.url.split('?')[0] === '/main-menu';
        }
    }

    getTabClass(tab) {

        const currentUrl = this.router.url.split('?')[0]; // current url without query params
        const classSelected = 'tab-selected';
        const classSelectedDeep = 'tab-selected-deep';
        const bgTransparent = ' bgTransparent';

        switch (tab) {

            case this.tabNames.HOME:

                if (currentUrl === '/home') {

                    return classSelected + bgTransparent;
                }
                break;

            case this.tabNames.ZONES:

                if (currentUrl === '/macro-zones') {

                    return classSelected + bgTransparent;
                }

                if (currentUrl === '/macro-zone' || currentUrl === '/zone') {

                    return classSelectedDeep + bgTransparent;
                }
                break;

            case this.tabNames.SETTINGS:

                if (currentUrl === '/main-menu') {

                    return classSelected + bgTransparent;
                }

                if (currentUrl !== '/home' && currentUrl !== '/macro-zones' && currentUrl !== '/macro-zone' && currentUrl !== '/zone') {

                    return classSelectedDeep + bgTransparent;
                }

                break;
        }

        return bgTransparent;
    }

    onTabsWillChange(event) {

        console.log(event);
    }

    initializeApp() {

        if (this.currentUser) {

            if (this.currentUser.vip) {

                this.showVip = true;
                this.showSplash = true;

            } else {

                this.showSplash = true;
            }
        }

        this.platform.ready().then(() => {

            if (this.platformMode === PlatformMode.App && this.platform.is("android")) {

                this.transitionDelay = this.transitionDelay * 1.4;
            }

            const unsubscribeAndGoToUrl = (url) => {

                this.tempConfigSubscription.unsubscribe();

                if (this.timerSub) {

                    this.timerSub.unsubscribe();
                }

                this.showSplash = false;
                this.unsubscribeAll();
                this.router.navigateByUrl(url);
            };

            if (!this.currentUser || !this.currentUser.username || this.currentUser.username === '') {

                unsubscribeAndGoToUrl('/login');
            }

            else if (!this.currentMcu) {

                unsubscribeAndGoToUrl('/mcu-select');

            } else {

                this.tempConfigSubscription.unsubscribe();

                if (this.timerSub) {

                    this.timerSub.unsubscribe();
                }

                if (this.showSplash) {

                    setTimeout(() => {

                        this.showSplash = false;
                        this.unsubscribeAll();
                        this.router.navigateByUrl('/home');

                    }, this.transitionDelay)

                } else {

                    this.showSplash = false;
                    this.unsubscribeAll();
                    this.router.navigateByUrl('/home');
                }
            }
        });
    }

    tabsHaveBorderTop() {

        const currentUrl = this.router.url.split('?')[0]; // current url without query params
        return currentUrl === '/home'; // || (currentUrl === '/main-menu' && this.isVip); // the vip footer as been eliminated (you can eliminate this comment)
    }
}

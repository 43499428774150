import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import {NetworkWay,NetworkStatus} from '../models/http-status';

/**
 * Instead of passing around action string constants and manually recreating
 * action objects at the point of dispatch, we create services encapsulating
 * each appropriate action group.
 */
export enum HttpStatusActionTypes {
	OpenHttp = '[OpenHttp] Open Http',
	CloseHttp = '[CloseHttp] Close Http',
	CommutationSuccess = '[CommutationSuccess] Commutation Success',
	CommutationError = '[CommutationError] Commutation Error',
    CommutationErrorRetry = '[CommutationErrorRetry] Commutation Error Retry',
    CommutationSync = '[CommutationSync] Commutation Sync',
    CommutationSyncToRecheck = '[CommutationSyncToRecheck] Commutation Sync To Recheck',
    ResyncCompleted = '[ResyncCompleted] Resync Completed',
    ResyncAlredyyStarted = '[ResyncAlredyyStarted] Resync Alreadt Started',
    ResetCompleted = '[ResetCompleted] Reset Completed',
    ReloadingNetworkStart = '[ReloadingNetworkStart] Reloading Network Start',
    ReloadingNetworkUpdate = '[ReloadingNetworkUpdate] Reloading Network Update',
    ReloadingNetworkEnd = '[ReloadingNetworkEnd] Reloading Network End',
    CurrentDateTimeUpdated = '[CurrentDateTime] CurrentDateTimeUpdated',
	BaseUrlChanged = '[BaseUrlChanged] Base Url Changed',
    BaseUrlChangedWhenSync = '[BaseUrlChangedWhenSync] Base Url Changed When Sync',
	BaseUrlChangedError = '[BaseUrlChangedError] Base Url Changed Error',
    SocketResetSuccess = '[SocketResetSuccess] Socket Reset Success',
    SocketResetSuccessSync = '[SocketResetSuccessSync] Socket Reset Success Sync',
    SocketResetError = '[SocketResetError] Socket Reset Error',
    BluetoothCommuted = '[BluetoothCommuted] Bluetooth Commuted'
}

export class OpenHttp implements Action {
    readonly type = HttpStatusActionTypes.OpenHttp;
    constructor(){}
}
export class CloseHttp implements Action {
    readonly type = HttpStatusActionTypes.CloseHttp;
    constructor(){}
}

export class ResyncCompleted implements Action {
    readonly type = HttpStatusActionTypes.ResyncCompleted;
    constructor(){}
}

export class ResyncAlredyyStarted implements Action {
    readonly type = HttpStatusActionTypes.ResyncAlredyyStarted;
    constructor(){}
}


export class ResetCompleted implements Action {
    readonly type = HttpStatusActionTypes.ResetCompleted;
    constructor(){}
}

export class CommutationSuccess implements Action {
	readonly type = HttpStatusActionTypes.CommutationSuccess;
    constructor(public modality:NetworkWay){
    	// console.log(`new CommutationSuccess() ${modality}`);
    }
}

export class CommutationError implements Action {
    readonly type = HttpStatusActionTypes.CommutationError;
    constructor(public modality:NetworkWay){}
}

export class CommutationErrorRetry implements Action {
    readonly type = HttpStatusActionTypes.CommutationErrorRetry;
    constructor(public modality:NetworkWay){}
}

export class ReloadingNetworkEnd implements Action {
    readonly type = HttpStatusActionTypes.ReloadingNetworkEnd;
    constructor(){}
}

export class CurrentDateTimeUpdated implements Action {
    readonly type = HttpStatusActionTypes.CurrentDateTimeUpdated;
    constructor(){}
}

export class ReloadingNetworkUpdate implements Action {
    readonly type = HttpStatusActionTypes.ReloadingNetworkUpdate;
    constructor(public status:NetworkStatus){}
}

export class ReloadingNetworkStart implements Action {
    readonly type = HttpStatusActionTypes.ReloadingNetworkStart;
    constructor(){}
}

export class CommutationSync implements Action {
    readonly type = HttpStatusActionTypes.CommutationSync;
    constructor(public modality:NetworkWay){
        // console.log(`new CommutationSync() passo a ${modality}`)
    }
}

export class CommutationSyncToRecheck implements Action {
    readonly type = HttpStatusActionTypes.CommutationSyncToRecheck;
    constructor(public modality:NetworkWay){
        // console.log(`new CommutationSyncToRecheck() passo a ${modality}`)
    }
}

export class BaseUrlChanged implements Action {
    readonly type = HttpStatusActionTypes.BaseUrlChanged;
    constructor(){}
}

export class BaseUrlChangedWhenSync implements Action {
    readonly type = HttpStatusActionTypes.BaseUrlChangedWhenSync;
    constructor(){}
}

export class BaseUrlChangedError implements Action {
    readonly type = HttpStatusActionTypes.BaseUrlChangedError;
    constructor(){}
}

export class SocketResetSuccess implements Action {

    readonly type = HttpStatusActionTypes.SocketResetSuccess;
    constructor(){
         // console.log('SocketResetSuccess action')
    }
}

export class SocketResetSuccessSync implements Action {
    readonly type = HttpStatusActionTypes.SocketResetSuccessSync;
    constructor(){}
}

export class SocketResetError implements Action {

    readonly type = HttpStatusActionTypes.SocketResetError;
    constructor(){
           // console.log('SocketResetError action ')
    }
}

export class BluetoothCommuted implements Action {
    readonly type = HttpStatusActionTypes.BluetoothCommuted;
    constructor(){}
}





export type HttpStatusActions = ReloadingNetworkEnd | CurrentDateTimeUpdated | ReloadingNetworkUpdate |CommutationSyncToRecheck |  ReloadingNetworkStart | ResyncCompleted | ResyncAlredyyStarted | ResetCompleted | OpenHttp | CloseHttp | CommutationSuccess | CommutationSync | CommutationError | CommutationErrorRetry | BaseUrlChanged | BaseUrlChangedWhenSync | BaseUrlChangedError | SocketResetSuccess | SocketResetSuccessSync | SocketResetError;

import {ActionReducer, Action} from '@ngrx/store';
import {ActionsActionTypes, ActionsActions} from '../actions/actions.actions';
import {ActionsStatus} from '../models/actions-status';

const initialState: ActionsStatus = {} as ActionsStatus;

/**
 * Actions (reset, reboot, ...)
 * (state management)
 *
 * Reducers are the foundation of any Store or Redux-based application, describing
 * sections of state and their potential transformations based on dispatched
 * action types. It is the combination of your reducers that makes up a
 * representation of application state at any given time.
 *
 * A reducer is a pure function, accepting two arguments, the previous state
 * and an action with a type and optional data (payload) associated with the
 * event.
 */
export function actionsReducer(state: ActionsStatus = initialState, action: ActionsActions): ActionsStatus {

    switch (action.type) {

        case ActionsActionTypes.ExecuteActionSuccess:

            console.log('action.data', action.data);
            return {...state, lastActionResult: action.data, error: null};

        case ActionsActionTypes.ExecuteActionError:

            return {...state, error: action.error};

        case ActionsActionTypes.LoadStatusSuccess:

            return {...state, systemStatus: action.data, error: null};

        case ActionsActionTypes.LoadStatusError:

            return {...state, error: action.error};

        case ActionsActionTypes.AlarmModeOn:

            return {...state, alarmMode: true};

        case ActionsActionTypes.AlarmModeOff:

            return {...state, alarmMode: false};

        case ActionsActionTypes.HideBrowserDirectIpLoaderM:

            return {...state, showBrowserDirectIpLoaderM: false};

        case ActionsActionTypes.AlertControllerLoaded:

            return {...state, alertControllerLoaded: true};

        default:

            return state;
    }
}

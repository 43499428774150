import { Injectable } from '@angular/core';
import {HttpResponse, HttpHeaders} from '@angular/common/http';

import { HttpClient } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { Observable, of , throwError } from 'rxjs';
import { map,catchError, timeout, first, switchMap } from 'rxjs/operators';
import { Weather, Forecast } from '../models/weather';
import { AppState } from './app-state.service';
import { Config } from '../models/config';

import * as _ from 'lodash';
import * as moment from "moment";
import {Moment} from "moment";

/**
 * Download weather information from YAHOO
 */
@Injectable({
  providedIn: 'root'
})
export class WeatherService {

    private config: Observable<Config>;
    private baseUrl: string;
    private portalUrl: string;

    public weather: Weather;
    public previousWeather: Weather; // Used to display the previous loaded weather with opacity and a spinner on top

    public ready:boolean;

    public keyIsPresent:boolean;
    public noValidLocationKey:boolean;
    public checkLocationKey:boolean;
    public timeoutRequest:boolean;
    public weatherTimeout:any;
    public language:string;
    public anavailable:boolean;

    public lastUpdate: Moment; // Now the weather component will reset only every 15 mins (and not everytime you exit the homepage)

    cleanup(){
        // console.error("ho resettato il weather component");
        //this.weather={} as Weather;




        return of(null);

    }

    constructor(private http: HttpClient,
                protected store: Store<AppState>
               )
    {
        this.config = store.select('config') as Observable<Config>;
        this.config.subscribe(data => {
            this.baseUrl = data.baseUrl;
            this.portalUrl = data.portalUrl;
           
        })
       // this.store.dispatch(new LoadConfig());

        this.ready=false;
        this.anavailable=false;
    }

    isLoading(previousWeatherCondition = true){
        return !this.keyIsPresent || !this.checkLocationKey || (!this.getStatus() && !this.previousWeather && previousWeatherCondition);
    }

    resetKey(){
         this.checkLocationKey = false
         this.noValidLocationKey = true
    }

    validateKey(){
            this.checkLocationKey = true
         this.noValidLocationKey = false
    }

    resetTimeout(){
         this.timeoutRequest = false
         clearTimeout(this.weatherTimeout)
    }


    isEmpty(){
        return this.weather.humidity==-1 && this.weather.temperature==-1 && this.weather.dewpoint==-1;
    }


    reset(resetCachedData = false) {
        // console.log('reset viene messo a false')
        this.ready = false;
        clearTimeout(this.weatherTimeout);
        // console.error("il timeout è stato cancellato perchè è stata chiamata la reset!")
        this.keyIsPresent = false;
        //@ts-ignore
        _.set(this.weather, {});
        this.weather = null;
        /*this.weather.humidity=-1;
         this.weather.temperature=-1;
         this.weather.dewpoint=-1; */
        // console.log('è stata chiamata reset e wheaterh vale')
        // console.log(this.weather);
        //this.weather = {} as Weather;
        this.noValidLocationKey = true;
        this.checkLocationKey = false;
        this.timeoutRequest = false
        this.anavailable = false;

        if (resetCachedData) {

            this.lastUpdate = null;
            this.previousWeather = null;
        }
    }

     showErrors(){
        this.ready=false;
         clearTimeout(this.weatherTimeout)
        // console.error("il timeout è stato cancellato dalla showErrors!")
         this.keyIsPresent=true;
         this.weather = {} as Weather;
         this.noValidLocationKey = true;
         this.checkLocationKey = false;
         this.timeoutRequest = true
         this.anavailable=false;

    }

    enable(){
        // console.warn("ORA WEATHER è ABILITATA!!")
        this.ready=true;
    }

    getStatus(){
        return this.ready;
    }

    getWeather(key: string, unit?: string) {
        // console.log("metric",(unit && unit == 'C'))

        return this.config.pipe(first(),switchMap((config:Config)=>{

        let lang=config.language;
        moment.locale(lang);
        let body = {"username":"Messana19", "key": key,"language":lang, "metric": (unit && unit == 'C')}
        let url = this.portalUrl+"/portal/getWeatherKey";
        let _headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let tOut=20000;

        // console.log(`%csto facendo la getWeather con lang che vale ${lang}`,'font-size:1.2rem;color:violet');
        return this.http.post(url, body, {headers:_headers}).pipe(

            timeout(tOut),
            
               map((res:HttpResponse<Weather>) => {
                try {
                    return this.parseResponse(res,unit);
                } catch(ex) {
                    // console.log(ex)
                    return {} as Weather;
                }
            }),
            
            catchError(error=> { 

                this.anavailable=true;
                return throwError(new Error('Weather Service Anavailable'))})

            )
         }));




       // let options = { headers: headers, dontSendToken: true };

    


           
    }

    weatherTimeoutLog(){
        // console.error('weather service Timeout')
    }

    parseResponse(data, unit?: string): Weather {
        // console.log('weatherService.parseResponse()');
        // console.log(data);

        if(data.conditions.Code == 400) {
            return {} as Weather
        }

        try {
            var weatherResponse = data

            let forecast: Forecast[] = [];
            for (var d=0; d<weatherResponse.forecast.DailyForecasts.length; d++) {
                var forecastDay = weatherResponse.forecast.DailyForecasts[d]
                let tempMin = forecastDay.Temperature.Minimum.Value
                let tempMax = forecastDay.Temperature.Maximum.Value
                let unit = forecastDay.Temperature.Minimum.Unit
              
                let day = forecastDay.Day
                let night = forecastDay.Night
                let iaqCategory = forecastDay.AirAndPollen[0].Category
                var iaqValue = forecastDay.AirAndPollen[0].Value
                var iaqCategoryValue = forecastDay.AirAndPollen[0].CategoryValue

                forecast.push(new Forecast("0", forecastDay.Date, moment(forecastDay.Date).format('dddd'),
                            tempMax, tempMin, unit, day.ShortPhrase, this.getIcon(day.Icon),
                            iaqCategory, iaqValue, iaqCategoryValue));
            }

            var temp = weatherResponse.conditions[0].Temperature.Imperial.Value
            var dp = weatherResponse.conditions[0].DewPoint.Imperial.Value
            if(unit && unit == 'C'){
                temp = weatherResponse.conditions[0].Temperature.Metric.Value
                dp = weatherResponse.conditions[0].DewPoint.Metric.Value
            }

            // console.log(`weather temp: ${temp}; dp: ${dp}`);

            return new Weather(
                weatherResponse.conditions[0].RelativeHumidity, // humidity
                temp, // temperature
                dp, // dewpoint
                weatherResponse.conditions[0].WeatherText, // text
                this.getIcon(weatherResponse.conditions[0].WeatherIcon), // icon
                forecast
            );

        } catch(ex) {
            return {} as Weather;
        }
    }

    getIcon(code: number): string {

        switch(code) {
            case 1: return  'wi-day-sunny';
            case 2: return  'wi-day-sunny';
            case 3: return  'wi-day-sunny';
            case 4: return  'wi-day-sunny';
            case 5: return  'wi-day-haze';
            case 6: return  'wi-day-cloudy';
            case 7: return  'wi-cloud';
            case 8: return  'wi-cloudy';
            case 11: return  'wi-fog';
            case 12: return  'wi-showers';
            case 13: return  'wi-day-showers';
            case 14: return  'wi-day-showers';
            case 15: return  'wi-day-rain-mix';
            case 16: return  'wi-thunderstorm';
            case 17: return  'wi-day-thunderstorm';
            case 18: return  'wi-rain';
            case 19: return  'wi-snow';
            case 20: return  'wi-day-snow';
            case 21: return  'wi-day-snow';
            case 22: return  'wi-snow';
            case 23: return  'wi-day-snow';
            case 24: return  'wi-snowflake-cold';
            case 25: return  'wi-sleet';
            case 26: return  'wi-snow-wind';
            case 29: return  'wi-day-snow-thunderstorm';
            case 30: return  'wi-hot';
            case 31: return  'wi-snowflake-cold';
            case 32: return  'wi-windy';
            case 33: return  'wi-night-clear';
            case 34: return  'wi-night-alt-cloudy';
            case 35: return  'wi-night-alt-cloudy';
            case 36: return  'wi-night-alt-cloudy';
            case 37: return  'wi-dust';
            case 38: return  'wi-night-cloudy';
            case 39: return  'wi-night-alt-showers';
            case 40: return  'wi-night-alt-showers';
            case 41: return  'wi-night-thunderstorm';
            case 42: return  'wi-night-alt-thunderstorm';
            case 43: return  'wi-night-snow';
            case 44: return  'wi-night-snow-wind';

        }
        return null;
    }

}

/**
 * Array of bits
 */
export class BitArray {

    private size: number;
    private values: number[];
    private bitSize = 32;

    /**
     * Create a bit array of the given size
     */
    constructor(size) {
        this.size = size;
        this.values = [];
        for (var i = 0; i < Math.ceil(size / this.bitSize); i++) {
            this.values[i] = 0;
        }
    }

    /**
     * Set a bit
     */
    set(index: number, value: boolean): BitArray {
        if (index < 0 || index >= this.size) {
            return this;
        }
        let i = Math.floor(index / this.bitSize);
        if (value) {
            this.values[i] |= 1 << index - i * this.bitSize;

        } else {
            this.values[i] &= ~(1 << index - i * this.bitSize);
        }


        return this;
    }

    /**
     * Get a bit
     */
    get(index: number): boolean {
        if (index < 0 || index >= this.size) {
            return undefined;
        }
        let i = Math.floor(index / this.bitSize);
        return !!(this.values[i] & (1 << index - i * this.bitSize));
    }

    /**
     * Set all the bits to 0
     */
    reset(): BitArray {
        for (let i = 0; i < this.values.length; i++) {
            this.values[i] = 0;
        }
        return this;
    }

    toArray(): boolean[] {
        let result: boolean[] = [];
        for (var i = 0; i < this.size; i++) {
            result.push(this.get(i));
        }
        return result;
    }

    toBinaryString(): string {
        return this.toArray().map(v => v ? '1' : 0).join('');
    }

    toString(): string {
        let digits = this.toArray();
        let outArray = [];
        let power = [1];
        for (let i = 0; i < digits.length; i++) {
            if (digits[i]) {
                outArray = add(outArray, multiply(digits[i], power, 10), 10);
            }
            power = multiply(2, power, 10);
        }

        let out = [];
        for (let i = outArray.length - 1; i >= 0; i--) {
            out.push(outArray[i].toString(10));
        }
        if (out.length) {
            return out.join('');
        } else {
            return '0';
        }
    }

    toNumber(): number {
        return Number.parseInt(this.toString());
    }

    fromString(str: string): BitArray {
        let digits = [];
        for (let i = str.length - 1; i >= 0; i--) {
            let n = parseInt(str[i], 10);
            digits.push(n);
        }
        

        let outArray = [];
        let power = [1];
        for (let i = 0; i < digits.length; i++) {
            if (digits[i]) {
                outArray = add(outArray, multiply(digits[i], power, 2), 2);
            }
            power = multiply(10, power, 2);
        }
        
        outArray = outArray.reverse();
        while (outArray.length < this.size) {
            outArray.unshift(0);
        }
        for (let i=0; i<this.size; i++) {
            this.set(i, outArray[this.size - 1 - i]);
        }

       
        return this;
    }

    fromNumber(num: number): BitArray {
        return this.fromString(String(num));
    }

}

// Adds two arrays for the given base (10 or 16), returning the result.
// This turns out to be the only "primitive" operation we need.
function add(x, y, base) {
    let z = [];
    let n = Math.max(x.length, y.length);
    let carry = 0;
    let i = 0;
    while (i < n || carry) {
        let xi = i < x.length ? x[i] : 0;
        let yi = i < y.length ? y[i] : 0;
        let zi = carry + xi + yi;
        z.push(zi % base);
        carry = Math.floor(zi / base);
        i++;
    }
    return z;
}

// Returns a*x, where x is an array of decimal digits and a is an ordinary
// JavaScript number. base is the number base of the array x.
function multiply(num, x, base) {
    if (num === 0) return [];
    let result = [];
    let power = x;
    while (true) {
        if (num & 1) {
            result = add(result, power, base);
        }
        num = num >> 1;
        if (num === 0) break;
        power = add(power, power, base);
    }

    return result;
}

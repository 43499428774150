import {
    Component,
    Input,
    Output,
    OnInit,
    OnChanges,
    AfterContentInit,
    AfterViewInit,
    ContentChildren,
    QueryList,
    Directive,
    ElementRef,
    ViewChild,
    ViewChildren,
    EventEmitter,
    ChangeDetectorRef
} from '@angular/core';
import {Observable, Observer, Subscription, timer} from 'rxjs';
import {Store} from '@ngrx/store';
import * as _ from 'lodash';

import {Platform, IonSelect, ModalController} from '@ionic/angular';

import {AppState} from '../../services/app-state.service';
import {Attributes} from '../../models/attributes';
import {TablesActions, ChangeTableValues} from '../../actions/tables.actions';
import {Table, SCH, MCU, GRP} from '../../models/tables';
import {ScheduleOptionInterface, ScheduleOption, ScheduleSCHOption, SCH_OPTION_DELTA} from './schedule-option';

export {ScheduleOption} from './schedule-option';
import {BitArray} from '../../commons/bitarray';
import {isApplicableTo} from '../../commons/schedule';
import {ConfigActions} from '../../actions/config.actions';
import {SchedulationType, Season} from '../../models/config';
import {TranslateService} from '@ngx-translate/core';
import {NoScheduleComponent} from '../../components/no-schedule/no-schedule.component';

import {Router, ActivatedRoute, Params, RouterEvent, NavigationEnd} from '@angular/router';


export interface schedParams {
    table: string,
    id: number,
    values: any,
    newMCZ: boolean,
    onlyState: boolean
}

export interface seasonChangeParams {
    shceduleId: number,
    prevScheduleId: number,
    url: string,
    grp: Table,
    i: number
}


@Component({
    selector: 'schedule',
    templateUrl: './schedule.component.html',
    styleUrls: ['./schedule.component.scss'],
})
export class ScheduleComponent implements OnInit, OnChanges, AfterContentInit {


    @ViewChild('selectNotificationList') selectNotificationList: any;


    // table name
    @Input()
    table: string;

    // table name
    @Input()
    targetTable: string;

    // name of the schedule index variable
    @Input()
    idxVar: string;

    @Input()
    changingPage: boolean;

    @Input()
    modeVarVoid: boolean;

    // name of the mode variable
    @Input()
    modeVar: string;

    // name of the mode variable real
    @Input()
    modeVarTarget: string;


    //index i nel nel dei grp sulla pagina season
    @Input()
    grpIdx: number;

    // item
    @Input()
    item: Table;

    // schedule list
    @Input()
    SCH: SCH[];

    // MCU
    @Input()
    MCU: MCU;

    // disabled field
    @Input()
    disabled: boolean;

    // CSS class
    @Input()
    itemClass: string;

    // label
    @Input()
    label: string;

    // current mode (heating or cooling)
    @Input()
    season: Season;

    @Output() stopSync: EventEmitter<any> = new EventEmitter();
    @Output() openSelection: EventEmitter<any> = new EventEmitter();
    @Output() closeSelection: EventEmitter<any> = new EventEmitter();

    // function executed when the value is changed
    @Output()
    whileChange: EventEmitter<schedParams> = new EventEmitter<schedParams>();

    @Output()
    seasonSchedChange: EventEmitter<seasonChangeParams> = new EventEmitter<seasonChangeParams>();

    @Output()
    modeVarEmitter: EventEmitter<Number> = new EventEmitter<Number>();

    // disable change value action dispatch
    @Input()
    disableDispatch: boolean;

    state: boolean = false;

    @Input()
    toggle: string;

    @Input()
    noToggle: boolean;

    @Input()
    showGreyscaleToggle: boolean;

    @Input()
    usePopupIsOpenedCheck: boolean; // Pass this @Input as true if you don't want data to modify your popup <ion-select> once opened (for example: I've a list of schedules, the assigned schedule is the default radio button at start. Someone changes the schedule, my radio button changes, or maybe data is just refreshed, and if I already selected another radio button it's now back to the default one as data has been refreshed along with the Popup)

    selectionOpened = false
    countTrigger = 0

    @Input() isAutoModeStyleDualSetpoint:boolean;


    public opening = false;

    // private throwExceptionAlert:boolean;

    public offText: string;

    private prevScheduleId: number;
    // current selected id
    public _value: number = 0;
    // select options
    public selectOptions: ScheduleOptionInterface[];
    // value of the schedule index variable
    public scheduleId: number;
    // value of the mode variable
    protected mode: number;
    // value of 'modeVar' for enabling schedule
    protected scheduleModeValue: number;
    // 'fixed' select option (schedule children)
    protected _scheduleOptions: QueryList<ScheduleOption>;

    public scheduleSelectID;

    constructor(private cd: ChangeDetectorRef, public router: Router, protected store: Store<AppState>, private platform: Platform, private translate: TranslateService, public modalController: ModalController) {

        this.scheduleSelectID = this.getScheduleID();
    }

    ngOnInit(): void {
        this.offText = '';
        // da rimuovere, solo per testing

        /*       // Prepare the select options
           this.selectOptions = [];
           if (this._scheduleOptions) {
               this._scheduleOptions.forEach(option => {
                   if (option.schedulation) {
                       // this option is not shown
                       this.scheduleModeValue = option.value;
                   } else {
                       this.selectOptions.push(option);
                   }
               });
           }

            this.scheduleId=-1; */

        this.updateOptions();
    }

    ngOnChanges(inputs): void {
        //console.log('schedule.component ngOnChanges inputs vale')
        //console.log(inputs);
        this.updateOptions();
    }

    ngAfterContentInit(): void {
        this.updateOptions();
    }


    @ContentChildren(ScheduleOption)
    private set scheduleOptions(val: QueryList<ScheduleOption>) {
        this._scheduleOptions = val;
        this.updateOptions();
    }


    //table="DHW" [item]="dhw" idxVar="PAR_IdxSCH_T"  [disabled]="!dhwState[i]" toggle="PAR_TempSchedOn"

    protected updateOptions(): void {

        // console.log(`%cthis.selectionOpened vale ${this.selectionOpened}`,'font-size:2rem;color:gold;')
        // console.log(`%cthis.popupIsOpened() vale ${this.popupIsOpened()}`,'font-size:2rem;color:gold;')

        if (this.selectionOpened || (this.usePopupIsOpenedCheck && this.popupIsOpened())) {

            return
        }

        // console.error('updateOptions() partita!')
        if (this.item) {

            //console.log('%ccondizione if this.item è true e this.scheduleId viene definito a ','font-size:1.2rem;color:green;')
            //console.log(this.item[this.idxVar])

            this.scheduleId = this.item[this.idxVar];
            this.prevScheduleId = this.item[this.idxVar];

            //console.log(`l'attuale sched selezionato è ${this.scheduleId}`);
            //console.log(`precedente sched selezionato è ${this.prevScheduleId}`);

            if (this.modeVar) {
                this.mode = this.item[this.modeVar];
                //console.log(`%cMode vale ${this.mode} e scheduleModeValue vale ${this.scheduleModeValue}`,'font-size:1.2rem;color:green');
                if (this.mode == this.scheduleModeValue) {
                    if (this.scheduleId == -1) {
                        this._value = 0; // off
                        //console.log('%c metto this._value a 0','font-size:1.2rem;color:brown');
                    } else {
                        this._value = SCH_OPTION_DELTA + this.scheduleId;
                        //console.log(`%c metto this._value a ${this._value}`,'font-size:1.2rem;color:brown');
                    }
                } else {
                    if (this.table != 'TMR') {
                        this._value = this.mode;
                        //console.log(`%c  table != TMR metto this._value a ${this._value} usando mode`,'font-size:1.2rem;color:green');
                    } else if (this.scheduleId != -1) {
                        this._value = SCH_OPTION_DELTA + this.scheduleId;
                        //console.log(`%c  table == TMR metto this._value a ${this._value} usando SCH_OPTION_DELTA + this.scheduleId`,'font-size:1.2rem;color:green');
                    } else {
                        this._value = -1;
                    }

                }
            } else {
                //console.log(`%cmodeVar è false e  ${this.scheduleId} scheduleComponent, value vale ${this._value}, state vale ${this.state}  selectOptions vale`,'font-size:1.2rem;color:black');
                if (this.scheduleId == -1) {
                    this.mode = -1
                    this._value = -1; // no item selected
                    //console.log(`%c metto this._value a ${this._value}`,'font-size:1.2rem;color:red');
                } else {
                    this._value = SCH_OPTION_DELTA + this.scheduleId;
                    //console.log(`%c metto this._value a ${this._value}`,'font-size:1.2rem;color:blue');
                }
            }
        }
        //console.log(`sono scheduleId vale ${this.scheduleId} scheduleComponent, value vale ${this._value}, state vale ${this.state}  selectOptions vale`);
        // Prepare the select options
        this.selectOptions = [];
        if (this._scheduleOptions) {
            this._scheduleOptions.forEach(option => {
                if (option.schedulation) {
                    // this option is not shown
                    this.scheduleModeValue = option.value;
                } else {
                    this.selectOptions.push(option);
                }
            });
        }
        if (this.SCH) {
            // Filters the schedules applicables to the table
            this.selectOptions = this.selectOptions.concat(
                _.sortBy(this.SCH.filter(sch => isApplicableTo(sch, this.table, this.idxVar, this.season, this.isAutoModeStyleDualSetpoint)), 'CFG_Name')
                    .map(sch => new ScheduleSCHOption(sch)));
        }

        //console.log(`sono scheduleId vale ${this.scheduleId} scheduleComponent, value vale ${this._value}, state vale ${this.state}  selectOptions vale`);
        /* //console.log('this.selectOptions vale::')
         //console.log(this.selectOptions)
         //console.log('::')

         //console.log(`this.selectOptions length vale ${this.selectOptions.length}`) */

        // if(this.selectOptions==[] ) console.warn('la lista delle schedulazioni applicabili è vuota');

        if (this.selectOptions == [] || this._value == -1 || this._value == undefined) {

            //if(this.table.normalize()!='TMR')
            this.selectOptions.push({value: -1, text: this.translate.instant('NO_SCHEDULE')});

            //console.log(`sono scheduleId vale ${this.scheduleId} scheduleComponent, value vale ${this._value}, state vale ${this.state}  selectOptions vale`);
            this.offText = this.translate.instant('NO_SCHED_SELECTED');

        } else if (this._value != -1 && this._value != undefined) {

            this.selectOptions.forEach(option => {

                if (option.value == this._value) {

                    this.offText = option.text;
                }
            });

            // if(this.table.normalize()!='TMR')
            this.selectOptions.push({value: -1, text: this.translate.instant('NO_SCHEDULE')});
        }

        //console.log(`UpdateOptions offTexg vale ${this.offText}`);

        /*console.log('this.toggle')
       //console.log(this.toggle)
       //console.log('schedule options')
       //console.log(this.selectOptions);*/

        //console.log(`sono scheduleId vale ${this.scheduleId} scheduleComponent, value vale ${this._value}, state vale ${this.state}  selectOptions vale`);

        if (this.toggle && this.table != 'TMR') {
            this.state = this.item[this.toggle]
            //console.log(`this.state vale ${this.state}`)

            //  if(this.state && this.scheduleId == -1 ) {
            if ((this.state && isNaN(this.scheduleId)) || (this.state && this.selectOptions.length == 0)) {
                // console.error('state vero ma scheduleId == NaN or la lista degli sched. selezionabili era vuota ')
                this.state = false
                let values = {};
                values[this.toggle] = this.state
                //nuova riga aggiunta dal sync from A.Misuraca 9/1/2020
                if (this.MCU && this.MCU.TEC_SPMode && this.toggle == "PAR_SchedOnH")
                    values["PAR_SchedOnC"] = this.state
                //fine nuova riga
                // console.error('dispatch chiamata (perchè schedule id vale NaN) da updateOptions, avrebbe scritto');
                // console.error(values)
                // console.error(`riga 318 this.store.dispatch(new ChangeTableValues(${this.table},${this.item.id},${values}));`)
                this.store.dispatch(new ChangeTableValues(this.table, this.item.id, values));
                //this.store.dispatch(TablesActions.changeValues(this.table, this.item.id, values));
            }
        } else if (this.toggle && this.table == 'TMR') {
            this.state = (this.item[this.toggle] == 1);

        } else {
            //console.log('sono nel ramo else di if(this.toggle) ')
            this.state = this.scheduleId != -1
        }

        //console.log(`sono scheduleId vale ${this.scheduleId} scheduleComponent, value vale ${this._value}, state vale ${this.state}  selectOptions vale`);
        //console.log(this.selectOptions);

    }


    public getState() {
        return this.state;
    }


    public IAmOnSeasonPage() {
        return this.router.url == '/season';
    }


    protected async seasonClick() {

        if (this.scheduleId == -1) {
            // console.error(`season click this.scheduleId == -1 quindi lancio io la finestra`);
            await this.noSchedulesInSysem();
        }
    }

    public async clickToggle($event) {

        let forward = (this.state != $event.detail.checked);

        this.state = $event.detail.checked;

        //console.log($event);
        if ($event) {
            $event.stopPropagation();
        }

        let values = {};
        //console.log(`this.toggle vale ${this.toggle}`);

        if (this.toggle && forward && this.table != 'TMR') {

            this.stopSync.emit();
            values[this.toggle] = this.state //inizio nuova riga 9/1/2020
            //console.log(`this.MCU.TEC_SPMode vale ${this.MCU.TEC_SPMode}`);

            if (this.MCU && this.MCU.TEC_SPMode && this.toggle == "PAR_SchedOnH") {
                // console.warn(`assegno a values PAR_SchedOnC il valore di ${this.state}`);
                values["PAR_SchedOnC"] = this.state //fine nuova riga
            }

            if (isNaN(this.scheduleId)) {
                // console.error('beccato NaN su scheduleId, ca capire il perchè, cmq l\'ho impostato a -1');
                this.scheduleId = -1;
            }

            if (this.scheduleId == -1) {

                //console.log(this.selectOptions)
                //lo inizializzo di default a

                // console.error(`this.scheduleId == -1 quindi lancio io la finestra e  this.table!='TMR' infatti vale ${this.table}`);
                this.selectionOpened = true;
                this.openSelection.emit();
                await this.noSchedulesInSysem();


                values[this.toggle] = false //inizio nuova riga 9/1/2020
                this.state = false;
                //console.log(`auto switch off sched:  this.MCU.TEC_SPMode vale ${this.MCU.TEC_SPMode}`);
                if (this.MCU && this.MCU.TEC_SPMode && this.toggle == "PAR_SchedOnH") {
                    // console.warn(`auto switch off sched:  assegno a values PAR_SchedOnC il valore di ${this.state}`);
                    values["PAR_SchedOnC"] = false //fine nuova riga
                }
            }

            //non mi devo occupare dell'idx sull'on-off marx86 12/11/2020 a seguito di call con Luca e Andrea
            if (this.MCU && this.MCU.TEC_SPMode && (this.toggle == "PAR_SchedOnH" || this.toggle == "PAR_SchedOnC")) {

                values['PAR_IdxSCH_C'] = this.scheduleId;
                values['PAR_IdxSCH_H'] = this.scheduleId;

            } else {

                values[this.idxVar] = this.scheduleId;
            }

            // console.error(`sto per chiamare la dispatch per ${this.table} , ${this.item.id} e values vale`);
            //console.log(values);


            if (this.state || (!this.state && this.scheduleId != -1)) {
                // console.error(`riga 433  this.store.dispatch(new ChangeTableValues(${this.table},${this.item.id},${values}));`);
                this.store.dispatch(new ChangeTableValues(this.table, this.item.id, values));

            } else if (!this.selectionOpened) {

                let onOffValues = {};
                onOffValues[this.toggle] = this.state //inizio nuova riga 9/1/2020
                //console.log(`onOffValues: this.MCU.TEC_SPMode vale ${this.MCU.TEC_SPMode}`);
                if (this.MCU && this.MCU.TEC_SPMode && this.toggle == "PAR_SchedOnH") {
                    // console.warn(`onOffValues:assegno a values PAR_SchedOnC il valore di ${this.state}`);
                    onOffValues["PAR_SchedOnC"] = this.state //fine nuova riga
                }
                // console.error('dispatch di riga 370');
                // console.error(`riga 446  this.store.dispatch(new ChangeTableValues(${this.table},${this.item.id},${onOffValues}));`);
                this.store.dispatch(new ChangeTableValues(this.table, this.item.id, onOffValues));
            }


            if (!this.changingPage) {
                // console.error(`320 this.changingPage è falso , setTimeout(() => this.whileChange.emit`)
                //this.throwExceptionAlert=true;
                setTimeout(() => {
                        //console.log('this.whileChange.emit')
                        this.whileChange.emit({
                            table: (this.targetTable) ? this.targetTable : this.table,
                            id: this.item.id,
                            values: values,
                            newMCZ: false,
                            onlyState: true
                        })
                    }
                    , 500);
            }


            // this.store.dispatch(TablesActions.changeValues(this.table, this.item.id, values));

            if (this.state && this.scheduleId == -1) {
                //console.log('----------------------------')
                // console.error('condizione state e sched id== -1 beccata!')

                //this.select1.registerOnChange(this.onChangeSelect)

                //console.log(`ho messo scheduleId a ${this.scheduleId}`)
                this.countTrigger++
                //qui dovrei aprire la fiestra del select... ma su ionic 4... non sembra si possa fare?
            }

        } else if (this.toggle && forward && this.table == 'TMR') {

            this.stopSync.emit();
            values[this.toggle] = (this.state ? 1 : 2); //inizio nuova riga 9/1/2020
            //console.log(`this.MCU.TEC_SPMode vale ${this.MCU.TEC_SPMode}`);

            if (isNaN(this.scheduleId)) {
                // console.error('beccato NaN su scheduleId, ca capire il perchè, cmq l\'ho impostato a -1');
                this.scheduleId = -1;
            }

            if (this.scheduleId == -1) {


                //console.log(this.selectOptions)
                //lo inizializzo di default a


                // console.error(`this.scheduleId == -1 quindi lancio io la finestra`);
                this.selectionOpened = true;
                this.openSelection.emit();
                await this.noSchedulesInSysem();


                values[this.toggle] = 2 //inizio nuova riga 9/1/2020
                this.state = false;
                //console.log(`auto switch off sched:  this.MCU.TEC_SPMode vale ${this.MCU.TEC_SPMode}`);
            }

            values[this.idxVar] = this.scheduleId;

            // console.error(`sto per chiamare la dispatch per ${this.table} , ${this.item.id} e values vale`);
            //console.log(values);


            if (this.state || (!this.state && this.scheduleId != -1)) {
                // console.error(`riga 433  this.store.dispatch(new ChangeTableValues(${this.table},${this.item.id},${values}));`);
                this.store.dispatch(new ChangeTableValues(this.table, this.item.id, values));

            } else if (!this.selectionOpened) {

                let onOffValues = {};
                onOffValues[this.toggle] = this.state //inizio nuova riga 9/1/2020
                //console.log(`onOffValues: this.MCU.TEC_SPMode vale ${this.MCU.TEC_SPMode}`);

                // console.error('dispatch di riga 370');
                // console.error(`riga 446  this.store.dispatch(new ChangeTableValues(${this.table},${this.item.id},${onOffValues}));`);
                this.store.dispatch(new ChangeTableValues(this.table, this.item.id, onOffValues));
            }

            if (!this.changingPage) {
                // console.error(`320 this.changingPage è falso , setTimeout(() => this.whileChange.emit`)
                //this.throwExceptionAlert=true;
                setTimeout(() => {
                        //console.log('this.whileChange.emit')
                        this.whileChange.emit({
                            table: (this.targetTable) ? this.targetTable : this.table,
                            id: this.item.id,
                            values: values,
                            newMCZ: false,
                            onlyState: true
                        })
                    }
                    , 500);
            }

            // this.store.dispatch(TablesActions.changeValues(this.table, this.item.id, values));

            if (this.state && this.scheduleId == -1) {
                //console.log('----------------------------')
                // console.error('condizione state e sched id== -1 beccata!')


                //this.select1.registerOnChange(this.onChangeSelect)


                //console.log(`ho messo scheduleId a ${this.scheduleId}`)
                this.countTrigger++
                //qui dovrei aprire la fiestra del select... ma su ionic 4... non sembra si possa fare?


            }

        } //fine else TMR

        /*  else {
              //quando questa compoentnte viene usata per TMR
            if(this.scheduleId == -1){
                 this.selectionOpened=true;
                 this.openSelection.emit();
                 await this.noSchedulesInSysem();
              }
          } */
    }


    getCurrentSelectValue(value) {
        //console.log(`current selct value is ${val}`);
    }

    popupIsOpened() {

        const element = document.getElementById(this.scheduleSelectID);

        if (element) {

            return element.getAttribute('aria-expanded') === 'true'; // the attribute 'aria-expanded' is a string
        }

        return false;
    }

    clickNotificationNumber(event) {
        this.selectNotificationList.interface = 'popover';
        this.selectNotificationList.open(event);
    }

    /**
     * This method is executed when the user selects a value
     */
    public onChangeSelect(value) {

        //console.log(`scheduleId+delta:${this.scheduleId+SCH_OPTION_DELTA}, value: ${value}`);
        if ((this.scheduleId + SCH_OPTION_DELTA) != value && ((this.scheduleId != value) || value < SCH_OPTION_DELTA)) {
            //console.log(`test if => passed`)
            this.stopSync.emit();
            // console.error('onChangeSelect() chiamata! evento:' )
            //console.log(value);


            // this.cd.detectChanges();
            this.scheduleId = value;
            if (this.scheduleId == -1) {
                // console.error('metto state a false');
                this.state = false;

                setTimeout(() => {
                    //this.cd.detectChanges();
                }, 1500);

            } else {
                this.state = true;
                // console.error('metto state a true');
                setTimeout(() => {
                    // this.cd.detectChanges();
                }, 1500);
            }


            if (this._value != value && !isNaN(value)) {
                // console.warn('CAMBIAMENTO SIGNIFICATIVO,PROPAGO...');


                if (this.countTrigger > 0 && this.table == "MCZ") {
                    this.countTrigger--
                    return
                }
                var newMCZ = false
                if (this.selectionOpened) {
                    newMCZ = true
                }

                // console.error('this.selectionOpened viene messa a false')
                this.selectionOpened = false
                this.closeSelection.emit();
                this.countTrigger = 0

                /* if(this._value == -1 && $event){
                     this._value = $event
                 }*/
                this._value = value;
                //console.log(`%c metto this._value a ${value}`,'font-size:1.2rem;color:#006400;')

                if (this.table.normalize() != 'TMR') {
                    if (this._value < SCH_OPTION_DELTA) {
                        this.mode = this._value;
                        // console.error('   this.scheduleId = -1;')
                        this.scheduleId = -1;


                    } else {
                        //console.log(`%cmetto mode a ${this.scheduleModeValue} e this.scheduleId a ${this._value - SCH_OPTION_DELTA}`,'font-size:1.2rem;color:#006400;')
                        this.mode = this.scheduleModeValue;
                        this.scheduleId = this._value - SCH_OPTION_DELTA;
                    }

                } else { // for TMR
                    if (this._value < SCH_OPTION_DELTA) {
                        this.mode = 0;
                        // console.error('   this.scheduleId = -1;')
                        this.scheduleId = -1;


                    } else {
                        //console.log(`%cmetto mode a ${this.scheduleModeValue} e this.scheduleId a ${this._value - SCH_OPTION_DELTA}`,'font-size:1.2rem;color:#006400;')
                        this.mode = this.scheduleModeValue;
                        this.scheduleId = this._value - SCH_OPTION_DELTA;
                    }

                }


                let values = {};


                if (this.modeVar && !this.modeVarVoid) {
                    if (this.modeVarTarget)
                        values[this.modeVarTarget] = this.mode;
                    else
                        values[this.modeVar] = this.mode;
                } //inizio nuova riga 9/1/2020
                if (this.MCU && (this.MCU.TEC_SPMode || this.isAutoModeStyleDualSetpoint) && (this.toggle == "PAR_SchedOnH" || this.toggle == "PAR_SchedOnC")) {
                    //console.log(`metto PAR_IdxSCH_C e PAR_IdxSCH_H a ${this.scheduleId}`);
                    values['PAR_IdxSCH_C'] = this.scheduleId;
                    values['PAR_IdxSCH_H'] = this.scheduleId;
                    values['PAR_SchedOnH'] = this.state;
                    values['PAR_SchedOnC'] = this.state;
                    if (this.scheduleId == -1) {
                        //console.log('metto PAR_SchedOnH e PAR_SchedOnC a false')
                        values['PAR_SchedOnH'] = false;
                        values['PAR_SchedOnC'] = false;

                    }

                } else {
                    values[this.idxVar] = this.scheduleId;
                    if (this.scheduleId == -1 && this.table.normalize() != 'TMR') {
                        //console.log(`metto values[${this.toggle}] a false ovver ${values[this.toggle]}`)

                        values[this.toggle] = false;
                    } else {
                        //console.log(`%cQui table vale TMR, non ho messo ${this.toggle} a false`,'font-size:1.2rem;color:blue');
                    }
                }


                if (!this.disableDispatch) {
                    if (this.targetTable) {
                        //console.log(`%conChangeSelect chiamo new ChangeTableValues(${this.targetTable},${this.item.id},${values}`,'font-size:0.9rem;color:blue;')
                        //console.log(`%criga 606  this.store.dispatch(new ChangeTableValues(${this.table},${this.item.id},${values}));`,'font-size:0.9rem;color:blue;');
                        //console.log(`%cmodeVarEmitter this.modeVar: ${this.modeVar}`,'font-size:0.9rem;color:Chocolate');
                        this.modeVarEmitter.emit(values[this.modeVar]);
                        ////console.log(`this.modeVarEmitter.emit(${values[this.modeVar]});`)
                        this.store.dispatch(new ChangeTableValues(this.targetTable, this.item.id, values));
                    } else {

                        // console.warn(`onChangeSelect chiamo new ChangeTableValues(${this.table},${this.item.id}, values valgono `)
                        //console.log(values)
                        this.updateSeasonSCH(this.scheduleId, this.router.url);
                        // console.error(`riga 615  this.store.dispatch(new ChangeTableValues(${this.table},${this.item.id},${values}));`);
                        //console.log(`%cmodeVarEmitter (riga 618) this.modeVar: ${this.modeVar}`,'font-size:0.9rem;color:Chocolate');
                        this.modeVarEmitter.emit(values[this.modeVar]);
                        ////console.log(`this.modeVarEmitter.emit(${values[this.modeVar]});`)
                        this.store.dispatch(new ChangeTableValues(this.table, this.item.id, values));
                    }

                }


                if (!this.changingPage) {
                    //if(!this.throwExceptionAlert){

                    // console.error(`%c416 this.changingPage è falso, setTimeout(() => this.whileChange.emit`,'font-size:0.9rem;color:DarkMagenta');
                    setTimeout(() => {
                            ////console.log('values vale:')
                            //console.log(values)
                            //console.log(` this.whileChange.emit{ table:${(this.targetTable)?this.targetTable:this.table}, id:${this.item.id}, values:${values}, newMCZ: ${newMCZ},onlyState:${false} `)
                            this.whileChange.emit({
                                table: (this.targetTable) ? this.targetTable : this.table,
                                id: this.item.id,
                                values: values,
                                newMCZ: newMCZ,
                                onlyState: false
                            })
                        }
                        , 500);

                    // }
                }


            }

        } // if scheduleId!=value


    }


    /***
     schelta scehdulazione da componente no-schedule

     **/



    ionViewWillShow() {
        //console.log('schedule - ionViewWillShow');
    }


    changeFired(event) {
        //console.log('changeFired');
        //console.log(event);
    }

    /**
     * This method is executed when the user selects a value
     */
    protected applySelectValue(value) {
        //this.cd.detectChanges();
        this.scheduleId = value;
        if (this.scheduleId == -1) {
            // console.error('metto state a false');
            this.state = false;
            setTimeout(() => {
                this.cd.detectChanges();
            }, 1500);
        } else {
            this.state = true;
            // console.error('metto state a true');
            this.stopSync.emit();
            setTimeout(() => {
                this.cd.detectChanges();
            }, 1500);
        }
        // this.updateOptions();


        // console.error('applySelectValue() chiamata! evento:' )
        //console.log(value);
        // this.scheduleId=value;

        /* let elem:HTMLElement=document.getElementById('switch') as HTMLElement;

         if(this.scheduleId==-1){
             console.error('lancio click');
             setTimeout(()=>{
                 elem.click();
             },2000)

         }*/

        if (!isNaN(value)) {
            //if(this._value!=value && !isNaN(value)){
            // console.warn('CAMBIAMENTO SIGNIFICATIVO,PROPAGO...');


            if (this.countTrigger > 0 && this.table == "MCZ") {
                this.countTrigger--
                return
            }
            var newMCZ = false
            if (this.selectionOpened) {
                newMCZ = true
            }

            // console.error('this.selectionOpened viene messa a false')
            this.selectionOpened = false
            this.closeSelection.emit();
            this.countTrigger = 0

            /* if(this._value == -1 && $event){
                 this._value = $event
             }*/
            this._value = value;

            //console.log(`%c metto this._value a ${value}`,'font-size:1.2rem; color:brown');

            if (this._value < SCH_OPTION_DELTA) {
                this.mode = this._value;
                // console.error('   this.scheduleId = -1;')
                this.scheduleId = -1;


            } else {
                //console.log(`metto mode a ${this.scheduleModeValue} e this.scheduleId a ${this._value - SCH_OPTION_DELTA}`)
                this.mode = this.scheduleModeValue;
                this.scheduleId = this._value - SCH_OPTION_DELTA;
            }


            let values = {};


            if (this.modeVar && !this.modeVarVoid) {
                if (this.modeVarTarget)
                    values[this.modeVarTarget] = this.mode;
                else
                    values[this.modeVar] = this.mode;
            } //inizio nuova riga 9/1/2020

            if (this.table.normalize() != 'TMR')
                values[this.toggle] = this.state;
            if (this.MCU && (this.MCU.TEC_SPMode || this.isAutoModeStyleDualSetpoint) && (this.toggle == "PAR_SchedOnH" || this.toggle == "PAR_SchedOnC")) {
                //console.log(`metto PAR_IdxSCH_C e PAR_IdxSCH_H a ${this.scheduleId}`);
                values['PAR_IdxSCH_C'] = this.scheduleId;
                values['PAR_IdxSCH_H'] = this.scheduleId;
                values['PAR_SchedOnH'] = true;
                values['PAR_SchedOnC'] = true;

                if (this.scheduleId == -1) {
                    //console.log('metto PAR_SchedOnH e PAR_SchedOnC a false')
                    values['PAR_SchedOnH'] = false;
                    values['PAR_SchedOnC'] = false;

                }

            } else {
                values[this.idxVar] = this.scheduleId;
                if (this.scheduleId == -1) {
                    //console.log(`metto values[this.toggle] a false ovver ${values[this.toggle]}`)
                    if (this.table.normalize() != 'TMR')
                        values[this.toggle] = false;
                }
            }


            if (!this.disableDispatch) {
                if (this.targetTable) {
                    // console.error(`riga 772 new ChangeTableValues(${this.targetTable},${this.item.id},${values}`)
                    this.store.dispatch(new ChangeTableValues(this.targetTable, this.item.id, values));
                } else {

                    // console.warn(`onChangeSelect chiamo new ChangeTableValues(${this.table},${this.item.id}, values valgono `)
                    //console.log(values)
                    // console.error(`riga 779 new ChangeTableValues(${this.table},${this.item.id},${values}`)
                    this.store.dispatch(new ChangeTableValues(this.table, this.item.id, values));
                }

            }


            if (!this.changingPage) {
                // console.error(`416 this.changingPage è falso, setTimeout(() => this.whileChange.emit`)
                setTimeout(() => {
                        //console.log('riga 790 this.whileChange.emit')
                        this.whileChange.emit({
                            table: (this.targetTable) ? this.targetTable : this.table,
                            id: this.item.id,
                            values: values,
                            newMCZ: newMCZ,
                            onlyState: false
                        })
                    }
                    , 500);


            }
        }


        this.cd.detectChanges();

    }


    protected changeToggle(ev: any) {
        //console.log(`change toggle, checked vale `);
        //console.log(ev);
        this.state = ev.checked;
    }


    //questo metodo serve a risettare la modalità H/C a manual in base al gruppo della sched su cui si ha fatto click
    //questo metodo lo avevo implementato per forzare a modalità manual in caso che l'utente avesse scelto "no schedule" su schedulazione in pagina season
    //ma parnado con andrea, abbiamo scelto di disabilitare l'opzione no-schedule su pagina season
    private updateSeasonSCH(scheduleId: number, url: string) {
        if (scheduleId == -1 && url == '/season') {
            // console.warn('scheduleId vale 1 e url vale season');
            this.seasonSchedChange.emit({
                shceduleId: -1,
                prevScheduleId: this.prevScheduleId,
                url: 'season',
                grp: this.item,
                i: this.grpIdx
            })
        } else {
            // console.error(`schedule id vale ${scheduleId} url vale ${url}`)
        }

    }


    async noSchedulesInSysem($event?) {

        // console.error(`noSchedulesInSysem called when ${Math.floor(Date.now()/1000)}`);

        const modal = await this.modalController.create({

            component: NoScheduleComponent,
            cssClass: 'noScheduleModal noScheduleModalLastButtonEmpty',
            componentProps: {
                'options': this.selectOptions,
                'scheduleId': this.scheduleId,
                'title': this.translate.instant('SCH'),
                'message': this.translate.instant('NO_SCH_IN_SYSTEM')

            },
            backdropDismiss: false
        });

        modal.onWillDismiss().then(async (retParam: any) => {
            //console.log(retParam);
            const {data} = retParam;
            this.selectionOpened = false;
            this.closeSelection.emit();
            //GESTISCO LA CREAZIONE DELLA NUOVA ZONA
            //console.log(`la scelta è `);
            //console.log(data);


            this.updateSeasonSCH(data.scheduleId, this.router.url);


            if (data.updateScheduleId)
                this.applySelectValue(data.scheduleId);
            if (data.createNewSchedulation) {
                //this.router.navigate(['/main-menu'],{queryParams:{newSchedule:true,reqVersion:true}});
                this.router.navigate(['/schedules'], {queryParams: {newSchedule: true}});
                // vai alla pagina di creazione nuova schedulazione

            }

        });
        return await modal.present();
    }

    getScheduleID(number = 0) {

        const elementId = "scheduleSelect-" + number;
        const scheduleElement = document.getElementById(elementId);

        if (scheduleElement) {

            return this.getScheduleID(number+1);
        }

        else {

            return elementId;
        }
    }
}

// generated by /api/ts

import {NetworkStatus} from './http-status';

import {Constants} from '../commons/const';

import {Season} from "./config";
import {ChangeTableValues} from "../actions/tables.actions";


// tslint:disable:variable-name
export const TABLES = ['ARS', 'ATU', 'DHW', 'ENR', 'EXP', 'FLS', 'FNC', 'GRP', 'HYS', 'MCU', 'MCZ', 'MXV', 'ODS', 'PMP', 'PRS', 'RMS', 'SCH', 'SHT', 'TMR', 'TNK', 'TRG', 'ZON', 'TMP', 'GEN', 'MRM', 'MNF', 'DVV', 'CMN', 'FWA'];

export interface Table {
    id: number;
    CFG_Name: string;
}

export enum FNC_TYPE {
  RADIATOR,
  DIGITAL_ON_OFF,
  DIGITAL_MULTISPEED,
  ANALOG,
  DIGITAL_MULTIFUNCTION
}

export enum AUTO_MODE_STYLE {
  DEADBAND,
  SETPOINT_DIFF,
  DUAL_SETPOINT
}

export enum DIFF_MODE_SEASON_REF {
  HEATING,
  COOLING
}

export interface FSM {
    RTT_FsmState: number;
}

export class ARS_CFG implements Table {
    constructor(
        public id: number = null,
        public CFG_IdxGRP: number = 0,
        public CFG_Name: string = '',
        public CFG_CanWDDP: boolean = false
    ) {
    }
}

// Air system
export class ARS extends ARS_CFG {

    constructor(
        public PAR_FcIntTempAct: number = 72,
        public PAR_FcIntTempDeact: number = 59,
        public PAR_FcMode: number = 0,
        // Analogic ERV
        public PAR_AirExtractionMode: number = 0,
        public PAR_AirExtractionSchedOn: boolean = false,
        public PAR_AirExtractionOn: boolean = true,
        public PAR_SetAirExtractionRH: number = 30,
        public PAR_SetAirExtractionDP: number = 60,
        public PAR_IdxSCH_AirExtraction: number = -1,
        public PAR_HrvMode: number = 1,
        public PAR_HumMode: number = 1,
        public PAR_IdxSCH_Fc: number = -1,
        public PAR_IdxSCH_Hrv: number = -1,
        public PAR_IdxSCH_Hum: number = -1,
        public PAR_IdxSCH_Ntd: number = -1,
        public PAR_IdxSCH_Ci: number = -1,
        public PAR_IntegEnab: boolean = false,
        public PAR_NtdMode: number = 1,
        public PAR_On: boolean = true,
        public PAR_DiffIntegOff: number = 0,
        public PAR_DiffIntegOn: number = 3,
        public PAR_SetDehumRH: number = 60,
        public PAR_SetDehumDP: number = 60,
        public PAR_SetHumRH: number = 30,
        public PAR_SetHumDP: number = 60,
        public RTT_AirInTemp: number = -3276.8,
        public RTT_CanFc: boolean = false,
        public RTT_CanHrv: boolean = false,
        public RTT_CanHum: boolean = false,
        public RTT_CanInteg: boolean = false,
        public RTT_CanNtd: boolean = false,
        public RTU_FreecReq: boolean = false,
        public RTU_HrvReq: boolean = false,
        public RTU_HumReq: boolean = false,
        public RTU_InRangeFc: boolean = false,
        public RTU_InRangeHrv: boolean = false,
        public RTU_InRangeHum: boolean = false,
        public RTU_InRangeInteg: boolean = false,
        public RTU_InRangeNtd: boolean = false,
        public RTU_IntegReq: boolean = false,
        public RTU_NtdReq: number = 0,
        public TEC_DiffAirInC: number = -2,
        public TEC_DiffAirInH: number = 2,
        public TEC_FcOutDpLimit: number = 68,
        public TEC_FcOutTempLimit: number = 68,
        public TEC_SetDehumMax: number = 80,
        public TEC_SetDehumMin: number = 40,
        public TEC_SetHumMax: number = 50,
        public TEC_SetHumMin: number = 20,
        public TEC_SetTempAirInC: number = 77,
        public TEC_SetTempAirInH: number = 70,
        public TEC_TempAirMode: number = 0,
        public RTU_HrvIR: boolean = false,
        public RTU_HumIR: boolean = false,
        public RTU_NtdIR: boolean = false,
        public RTU_FcIR: boolean = false,
        public RTU_AirExtractionIR: boolean = false,
        public RTU_Flags: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        // public PAR_Mode_HRV: number = 0,
        public PAR_Mode_FC: number = 0,
        public PAR_HrvES: boolean = false,
        public PAR_SetCO2: number = 1000,
        public PAR_HrvSchedOn: boolean = false,
        public PAR_NtdSchedOn: boolean = false,
        public PAR_HumSchedOn: boolean = false,
        public PAR_FcSchedOn: boolean = false,
        public PAR_CiSchedOn: boolean = false,
        public PAR_NtdOn: boolean = false,
        public PAR_HumOn: boolean = false,
        public PAR_HrvOn: boolean = false,
        public PAR_FcOn: boolean = false,
        public RTU_NtdOn: boolean = false,
        public RTU_HumOn: boolean = false,
        public RTU_HrvOn: boolean = false,
        public RTU_IntegOn: boolean = false,
        public RTU_FcOn: boolean = false,
        public TEC_DiffTempForHum: number = 2,
        public PAR_WDDP_On: boolean = false
    ) {
        super();
    }
}

export class ATU_CFG implements Table {
    constructor(
        public id: number = null,
        public CFG_CanBathS: boolean = false,
        public CFG_CanFc: boolean = false,
        public CFG_CanHrv: boolean = false,
        public CFG_CanHum: number = 0,
        public CFG_CanInteg: boolean = false,
        public CFG_CanNtd: number = 2,
        public CFG_CfgDiAlarm: number = 0,
        public CFG_CfgDoFc: number = 0,
        public CFG_CfgDoHead: number = 0,
        public CFG_CfgDoHrv: number = 0,
        public CFG_CfgDoHum: number = 0,
        public CFG_CfgDoInteg: number = 0,
        public CFG_CfgDoNtd: number = 0,
        public CFG_CfgDoOn: number = 0,
        public CFG_Ctrl: number = 0,
        public CFG_DiCmAlarm: number = 0,
        public CFG_EnabBdDi: boolean = false,
        public CFG_IdxARS: number = null,
        public CFG_IdxATU: number = -1,
        public CFG_IdxHYS: number = null,
        public CFG_MbAddr: number = 0,
        public CFG_Name: string = '',
        public CFG_Type: number = 0,
        //Flow
        public CFG_AirFlowIn: number = -1,
        public CFG_AirFlowOut: number = -1,
        public CFG_AirFlowInMin: number = -1,
        public CFG_AirFlowInMax: number = -1,
        public CFG_AirFlowOutMin: number = -1,
        public CFG_AirFlowOutMax: number = -1,
        public CFG_AirFlowOutBMin: number = -1,
        public CFG_AirFlowOutBMax: number = -1,
        public CFG_PrbOnBoard: boolean = false,
        public CFG_IdxMXV: number = -1
    ) {

    }
}

// Air handling unit
export class ATU extends ATU_CFG {

    constructor(
        public PAR_FlowLevel: number = 0,
        // Analogic ERV
        public PAR_HrvStaticSpeed: number = 5,
        public PAR_HrvHighSpeed: number = 8,
        public PAR_HrvHighSetCO2: number = 1000,
        public PAR_HrvLowSpeed: number = 2,
        public PAR_HrvLowSetCO2: number = 500,
        public PAR_HrvSleepSpeed: number = 2,
        public PAR_HrvBathExhaustSpeed: number = 9,
        public PAR_HrvHumSPMaxRuntime: number = 0,
        public PAR_HrvPresenceMaxRuntime: number = 0,
        public PAR_HrvPresenceSpeed: number = 5,
        public PAR_UnitSize: number = 300,
        public RTT_AfReq: boolean = false,
        public RTT_CanVOC: boolean = null,
        public RTT_CfgHW: number = 0,
        public RTT_CommStat: string = "0/0",
        public RTT_DiState: number = 0,
        public RTT_DoState: number = 0,
        public RTT_FanInSpeed: number = null,
        public RTT_FanOutSpeed: number = null,
        public RTT_FlowPostT: number = 0,
        public RTT_FlowPreT: number = 0,
        public RTT_HrAsp: number = null,
        public RTT_DpAsp: number = null,
        public RTT_DpRep: number = null,
        public RTT_HrRep: number = null,
        public RTT_PosShutFc: number = 0,
        public RTT_PosShutR: number = 0,
        public RTT_Serial: string = '',
        public RTT_State: number = 0,
        public RTT_Temp1: number = null,
        public RTT_Temp2: number = null,
        public RTT_Temp3: number = null,
        public RTT_Temp4: number = null,
        public RTT_TempAsp: number = null,
        public RTT_TempComp: number = null,
        public RTT_TempCond: number = null,
        public RTT_TempEvap: number = null,
        public RTT_TempH2oIn: number = null,
        public RTT_VOC: number = null,
        public RTU_BathOn: boolean = false,
        public RTU_FcOn: boolean = false,
        public RTU_HrAirIn: number = null,
        public RTU_DpAirIn: number = null,
        public RTU_HrvLevel: number = 50,
        public RTU_HrvOn: boolean = false,
        public RTU_HumOn: boolean = false,
        public RTU_IntegOn: boolean = false,
        public RTU_NtdOn: boolean = false,
        public RTU_TempAirIn: number = null,
        public TEC_DirtyFilterDelay: number = 0,
        public TEC_Enable: boolean = true,
        public TEC_EnableV3: boolean = false,
        public TEC_FcFlowIn: number = 0,
        public TEC_FcFlowOut: number = 0,
        public TEC_FiltersCleaning: boolean = false,
        public TEC_HrvFree: number = null,
        public TEC_MaxHrvPos: number = 100,
        public TEC_MaxPreValvePos: number = 100,
        public TEC_MaxTempIntH: number = 110,
        public TEC_MinHrvPos: number = 0,
        public TEC_MinPreValvePos: number = 0,
        public TEC_MinTempIntC: number = 56,
        public TEC_PresF7: boolean = false,
        public TEC_ResetAlarms: boolean = false,
        public TEC_TempFree0: number = 50,
        public TEC_TempFree100: number = 40,
        public TEC_TempMinExtFc: number = 60,
        public TEC_CalStep: number = 0,
        public TEC_CalSupplyFan1: number = 0,
        public TEC_CalSupplyFan2: number = 0,
        public TEC_CalSupplyFan3: number = 0,
        public TEC_CalExaustFan2: number = 0,
        public TEC_CalDumperRec3: number = 0,
        public MEM_Calibrations: any[] = [],
        public MEM_FilterUse: string = '',
        public RTU_Flags: number[] = [],
        //Alarms
        public RTU_ErrComm: boolean = false,
        public RTU_AlarmW1: number = -1,
        public RTU_AlarmW2: number = -1,
        public RTT_ErrModbus: number = -1,
        public RTU_TankTempWarning: boolean = false,
        //Flow
        public PAR_AirFlowIn: number = null,
        public PAR_AirFlowOut: number = null,
        public PAR_AirFlowOutB: number = null,
        public RTU_AirFlowIn: number = null,
        public RTU_AirFlowOut: number = null,
        public RTU_AirFlowOutB: number = null,
        public PAR_AirFlowInMin: number = -1,
        public PAR_AirFlowInMax: number = -1,
        public PAR_AirFlowOutMin: number = -1,
        public PAR_AirFlowOutMax: number = -1,
        public PAR_AirFlowOutBMin: number = -1,
        public PAR_AirFlowOutBMax: number = -1,
        public PAR_SleepMode: number = -1,
        public PAR_SleepModeFrom: number = -1,
        public PAR_SleepModeTo: number = -1,
        public RTU_SleepModeIR: any
    ) {
        super();
    }
}

export class DHW_CFG implements Table {

    constructor(
        public id: number = null,
        public CFG_CfgAiRec: number = 0,
        public CFG_CfgAiTemp: number = 0,
        public CFG_CfgDiReq: number = 0,
        public CFG_CfgDoRec: number = 0,
        public CFG_CfgDoValve: number = 0,
        public CFG_DiCmReq: number = 0,
        public CFG_DoCmValve: number = 0,
        public CFG_IdxENR: number[] = [],
        public CFG_Name: string = '',
    ) {
    }
}

// Domestic hot water
export class DHW extends DHW_CFG implements FSM {

    constructor(
        public PAR_IdxSCH_R: number = -1,
        public PAR_IdxSCH_T: number = -1,
        public PAR_Legionella: boolean = false,
        public PAR_On: number = 1,
        public PAR_On_Bool: boolean = true,
        public PAR_RecTarget: number = 110,
        public PAR_Setback: boolean = true,
        public PAR_SetbackSetTemp: number = 30,
        public PAR_TempTarget: number = 122,
        public RTT_ExternalReq: boolean = false,
        public RTT_FsmState: number = 0,
        public RTU_Errors: number = 0,
        public RTU_RecState: boolean = false,
        public RTU_Status: number = 0,
        public RTU_TargetTemp: number = Constants.NO_VALUE,
        public RTU_Temp: number = null,
        public RTU_TempRec: number = null,
        public TEC_AfrAlarmTime: number = 15,
        public TEC_AfrDiff: number = 10,
        public TEC_AfrMinOnTime: number = 5,
        public TEC_AfrTemp: number = 50,
        public TEC_DiffDHW = 2,
        public TEC_ERL: number = 0,
        public TEC_ErDelay: number = 2,
        public TEC_MaxTimeOff: number = 60,
        public TEC_MaxTimeOn: number = 60,
        public TEC_RecDiff: number = 4,
        public TEC_SecErAct: number = 10,
        public TEC_SecErMinOn: number = 5,
        public TEC_OffTempDiff: number = 20,
        public TEC_OnTempDiff: number = 20,
        public RTU_DhwIR: boolean = false,
        public RTU_RecIR: boolean = false,
        public RTU_Flags: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        public RTU_Alarms: number = 0,
        public PAR_Mode_T: number = 0, //used only on IF
        public PAR_Mode_R: number = 0, //used only on IF
        public PAR_OnB: boolean = false, //used only on IF
        public PAR_TempSchedOn: boolean = false,
        public PAR_RecSchedOn: boolean = false
    ) {
        super();
    }
}


//EMail Alert
/*
* public PAR_Exclude:number[]=new Array(30).fill(0), //array di 30 elementi, da 0 a 29, a me servono gli indici da 1 a 29, inizializzo a 0, quindi nulla viene escluso

*/

export class EMA_CFG implements Table {
    constructor(
        public id: number = null,
        public CFG_Name: string = '',
        public CFG_Valid: boolean = true,
    ) {
    }
}

export class EMA extends EMA_CFG {
    constructor(
        public PAR_Addr: string = '',
        public PAR_Recipient: string = '',
        public PAR_Active: boolean = true,
        public PAR_OnEvent: boolean = true,
        public PAR_Period: number = 1440,
        public PAR_Exclude: number[] = new Array(30).fill(0),
        public RTU_LastSend: string = ''
    ) {
        super();
    }
}

export class ENR_CFG implements Table {
    constructor(
        public id: number = null,
        public CFG_AW: boolean = false,
        public CFG_CfgAo: number = 0,
        public CFG_CfgDiAlarm: number = 0,
        public CFG_CfgDiEnable: number = 0,
        public CFG_CfgDoActC: number = 0,
        public CFG_CfgDoActDHW: number = 0,
        public CFG_CfgDoActH: number = 0,
        public CFG_CfgDoOn: number = 0,
        public CFG_CfgDoSeason: number = 0,
        public CFG_CfgDoValve: number = 0,
        public CFG_Ctrl: number = 0,
        public CFG_CtrlMode: number = 0,
        public CFG_DiCmAlarm: number = 0,
        public CFG_DiCmEnable: number = 0,
        public CFG_DoCmAct: number = 0,
        public CFG_DoCmSeason: number = 0,
        public CFG_MbAddr: number = 0,
        public CFG_Model: number = -1,
        public CFG_Name: string = '',
        public CFG_Priority: number = 3,
        public CFG_T_0V_C: number = 48,
        public CFG_T_0V_H: number = 48,
        public CFG_T_10V_C: number = 40,
        public CFG_T_10V_H: number = 140,
        public CFG_Type: number = 0,
        public CFG_VOff: number = 0,
        public CFG_VOn: number = 10,
        public CFG_ValveRunTime: number = 120,
        public CFG_CfgAiSupply: number = 0,
        public CFG_CfgAiReturn: number = 0
    ) {

    }
}

// Energy resource
export class ENR extends ENR_CFG {

    constructor(
        public PAR_On: boolean = true,
        public PAR_TargetTempMode: number = 1,
        public PAR_TargetTempModeDHW: number = 1,
        public RTT_AnalogPerc: number = 0,
        public RTT_COP: number = 0,
        public RTT_Enabled: boolean = false,
        public RTT_H2O_Return: number = -3276.8,
        public RTT_H2O_Supply: number = -3276.8,
        public RTT_Modulation: number = 0,
        public RTT_OutdoorTemp: number = -3276.8,
        public RTT_OutputkW: number = 0,
        public RTT_TargetTemp: number = -3276.8,
        public RTT_WaterFlow: number = 0,
        public RTU_EnergyReq: boolean = false,
        public RTU_EnergyReqDHW: boolean = false,
        public TEC_AnalogDelta: number = 60,
        public TEC_ExtLimitDiff: number = 4,
        public TEC_ExtLimitTemp: number = 40,
        public TEC_IdxSCH: number = -1,
        public TEC_MaxPercInt: number = 20,
        public TEC_MinPercInt: number = 2,
        public TEC_TargetTempDHW: number = 48,
        public TEC_TargetTempH: number = 40,
        public TEC_TargetTempC: number = 8,
        public RTU_EnrIR: boolean = false,
        //Alarms
        public RTU_ErrComm: boolean = false,
        public RTU_Alarm: number = -1,
        public RTU_Flags: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        public RTT_ErrModbus: number = -1,
        // -------------------------------------------------------------------------------------------------------------
        // --- Phoenix Model Parameters --------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        // -------------------------------------------------------------------------------------------------------------
        // --- State Parameters ----------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        // --- O ---
        public RTU_OutputStatus: number = 0,
        public RTU_EEVStep: number = Constants.NO_VALUE,
        public RTU_EVI_EEVStep: number = Constants.NO_VALUE,
        // --- S ---
        public RTU_StateStatus: number = 0,
        public RTU_Alarms: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        // --- T ---
        public RTU_AirCondWaterSupplyH: number = Constants.NO_VALUE,
        public RTU_AirCondWaterReturnH: number = Constants.NO_VALUE,
        public RTU_MixerWaterReturn: number = Constants.NO_VALUE,
        public RTU_DHWSupply: number = Constants.NO_VALUE,
        public RTU_DHWReturn: number = Constants.NO_VALUE,
        public RTU_CompPhase: number = Constants.NO_VALUE,
        public RTU_BusVoltage: number = Constants.NO_VALUE,
        public RTU_IPMTemp: number = Constants.NO_VALUE,
        public RTU_DWHTankTemp: number = Constants.NO_VALUE,
        public RTU_CoilTemp: number = Constants.NO_VALUE,
        public RTU_SuctionTemp: number = Constants.NO_VALUE,
        public RTU_TankTemp: number = Constants.NO_VALUE,
        public RTU_ExhaustTemp: number = Constants.NO_VALUE,
        public RTU_AntifreezeTempRead: number = Constants.NO_VALUE,
        public RTU_InputCurrent: number = Constants.NO_VALUE,
        public RTU_RoomTemp: number = Constants.NO_VALUE,
        public RTU_IPMProtectionTempOverH: number = Constants.NO_VALUE,
        public RTU_InputVoltage: number = Constants.NO_VALUE,
        public RTU_EVISupply: number = Constants.NO_VALUE,
        public RTU_EVIReturn: number = Constants.NO_VALUE,
        public RTU_Pressure: number = Constants.NO_VALUE,
        public RTU_CompTargetFreq: number = Constants.NO_VALUE,
        public RTU_CompRunFreq: number = Constants.NO_VALUE,
        public RTU_InverterBoardMaxFreq: number = Constants.NO_VALUE,
        public RTU_Fan1Speed: number = Constants.NO_VALUE,
        public RTU_Fan2Speed: number = Constants.NO_VALUE,
        // -------------------------------------------------------------------------------------------------------------
        // --- Controls Parameters -------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        public TEC_ManualControl = 0,
        // --- H ---
        public TEC_AutoStart: number = 1,
        public TEC_FanCtrlComInvBoard: number = Constants.NO_VALUE, // Commented for now in the html (modbus map not found)
        public TEC_CoolingMode: number = 0,
        public TEC_UnitCommMode: number = 1,
        public TEC_UnitAddress: number = 1,
        public TEC_EVIEnabled: number = 3,
        public TEC_HotwaterEnable: number = 1,
        public TEC_UM: number = 0,
        public TEC_SilenceMode: number = 0,
        public TEC_ElectricHeaterEnergyStage: number = 3,
        public TEC_ThreeWayValvePolarity: number = 0,
        public TEC_RunningStateCode: number = 0,
        public TEC_AirConditionerTempChoice: number = 0,
        public TEC_HydraulicModule: number = 0,
        public TEC_PumpType: number = 0,
        public TEC_ForcedTime: number = 120,
        public TEC_RemoteFunction: number = Constants.NO_VALUE, // Commented for now in the html (modbus map not found)
        public TEC_WeatherCompensation: number = Constants.NO_VALUE, // Commented for now in the html (modbus map not found)

        // --- A ---
        public TEC_ShutdownAmbientTemp: number = -25,
        public TEC_AntifreezeTempWrite: number = 4,
        public TEC_AntifreezeTempDiff: number = 3,
        public TEC_ExhaustTempProtectSetup: number = 120,
        public TEC_LowVoltagePressureSensor: number = 0,
        public TEC_OutletWaterLowProtection: number = Constants.NO_VALUE, // Commented for now in the html (modbus map not found)
        public TEC_SensorType: number = 0,
        public TEC_AntifreezeMinTemp: number = 4,
        public TEC_TempDiffALimitFreq: number = Constants.NO_VALUE, // Commented for now in the html (modbus map not found)
        public TEC_TempDiffBLimitFreq: number = Constants.NO_VALUE, // Commented for now in the html (modbus map not found)

        // --- D ---
        public TEC_StartDefrostSetpoint: number = -7,
        public TEC_ExitDefrostSetpoint: number = 13,
        public TEC_DefrostCycle: number = 45,
        public TEC_MaxDefrostTime: number = 8,
        public TEC_ElectricHeaterCtrl: number = 0,
        public TEC_SlidingDefrostAmbTemp: number = 2,
        public TEC_SlidingDefrostATDiff: number = 14,
        public TEC_SlidingDefrostCTDiff: number = 10,
        public TEC_SlidingDefrostMinCT: number = -30,
        public TEC_DefrosFreq: number = 70,
        public TEC_DefrostMinInletWaterTemp: number = Constants.NO_VALUE, // Commented for now in the html (modbus map not found)

        // --- E ---
        public TEC_EEV1AdjustMode: number = 1,
        public TEC_EEV1TargetSuperheatDegree: number = 5,
        public TEC_EEV1InitialStep: number = 350,
        public TEC_EEVMinStep: number = 100,
        public TEC_CoolStep: number = 200,
        public TEC_EVI1AdjustMode: number = 1,
        public TEC_EVI1nitialStep: number = 350,
        public TEC_EVITargetSuperheatDegree: number = 3,
        public TEC_EVIMinStep: number = 100,
        public TEC_DefrostStep: number = 480,
        public TEC_CoolTargetSuperheat: number = 3,
        // --- F ---
        public TEC_FanMotorType: number = 3, // _dev_ Warning: type "2" should be "bypassed" as there isn't a value associated with it
        public TEC_CoilTempMaxC: number = 50,
        public TEC_CoilTempMinC: number = 10,
        public TEC_CoilTempMaxH: number = 10,
        public TEC_CoilTempMinH: number = 20,
        public TEC_FanQuantity: number = 0,
        public TEC_FanMotorSpeedC: number = 300,
        public TEC_FanMotorSpeedMinH: number = 300,
        public TEC_TimerMute: number = 0,
        public TEC_ManualCtrlFanSpeed: number = 0,
        public TEC_FanRateSpeed: number = 600,
        public TEC_FanSpeedMaxC: number = 700,
        public TEC_FanSpeedMaxH: number = 700,
        // --- P ---
        public TEC_Power: number = 1,
        public TEC_RunningMode: number = 2,
        public TEC_RunningIntTime: number = 30,
        public TEC_RunningDuration: number = 3,
        public TEC_DHWPumpWorkMode: number = 0,
        public TEC_StartWaterPumpOffMode: number = 0,
        public TEC_WaterPumpTotalStopRunTime: number = Constants.NO_VALUE, // Commented for now in the html (modbus map not found)

        // --- R ---
        public TEC_HotWaterSetpoint: number = 55,
        public TEC_PowerOnReturnDiffH: number = 2,
        public TEC_ConstantTempPowerOffTempDiffH: number = 1,
        public TEC_SetpointMinC: number = 8,
        public TEC_SetpointMaxC: number = 28,
        public TEC_SetpointMinH: number = 15,
        public TEC_SetpointMaxH: number = 50,
        public TEC_ReturnDiffExitingHighTempMode: number = 2,
        public TEC_CompONLowAT: number = -5,
        public TEC_CompOFFLowAT: number = -23,
        public TEC_LowATMaxCompAim: number = 40,
        public TEC_CompONHighAT: number = 55,
        public TEC_CompOFFHighAT: number = 55,
        public TEC_HighATMaxCompAim: number = 45,
        public TEC_ElectricHeaterFunctions: number = 0,
        public TEC_PowerOnReturnDiffC: number = 2,
        public TEC_ConstTempPowerOffTempDiffC: number = 1,
        public TEC_HotWaterSetpointMin: number = 15,
        public TEC_HotWaterSetpointMax: number = 58,
        public TEC_AutoRestartAmbTempH: number = 10,
        public TEC_AmbTempMainPump: number = 2,
        public TEC_AmbTempHCircPump: number = 2,
        public TEC_PowerOnReturnDiffTNK: number = 5,
        public TEC_StandbyTempDiffTNK: number = 2,
        public TEC_WaterHTempMax: number = 55,
        public TEC_WaterHTempMaxUnderLow: number = 50,
        public TEC_WaterHTempMaxUnderHigh: number = 40,
        public TEC_ElectricHeaterStartAmbTemp: number = -10,
        public TEC_OutletWaterTempMaxDiff: number = 5,
        // --- G (unused) ---
        public TEC_AntiLegTargetTemp: number = 63,
        public TEC_AntiLegDuration: number = 0,
        public TEC_AntiLegStartHour: number = 1,
        public TEC_AntiLegCycle: number = 1,
        public TEC_AntiLegEnab: number = 1,
        // --- C (unused) ---
        public TEC_CompManualFreq: number = 0,
        public TEC_CompMinFreq: number = 30,
        public TEC_CompMaxFreq: number = 90,
        public TEC_ComModelSel: number = 90,
        public TEC_CompMinFreqLowAmbTempC: number = 50,
        public TEC_CompFreqCtrlMode: number = 1,
        public TEC_FreqResonanceP1: number = 0,
        public TEC_FreqResonanceP2: number = 0,
        public TEC_FreqResonanceP3: number = 0
    ) {
        super();
    }
}

export class EXP_CFG implements Table {
    constructor(
        public id: number = null,
        public CFG_MbAddr: number = 0,
        public CFG_Name: string = '',
        public CFG_Type: number = 0
    ) {

    }
}

// Expansion
export class EXP extends EXP_CFG {

    constructor(
        public RTT_DiStatus: number = 0,
        public RTT_DoStatus: number = 0,
        public RTT_ErrModbus: number = 0,
        public RTU_ErrComm: boolean = false
    ) {
        super();
    }
}

export class FLS_CFG implements Table {
    constructor(
        public id: number = null,
        public CFG_Name: string = '',
        public CFG_Type: number = null
    ) {
    }
}

// Flow sensor
export class FLS extends FLS_CFG {

    constructor(
        public RTT_Flow: number = null,
        public RTT_Temperature: number = null,
        public TEC_MaxFlow: number = null,
        public TEC_MinFlow: number = null,
        //Alarms
        public RTU_Alarms: number = 0
    ) {
        super();
    }
}

export class FWA_CFG implements Table {

    constructor(
        public id: number = null,
        public CFG_Name: string = '',
        public CFG_Type: number = 0,
        public CFG_CfgAi: number[] = [],
        public CFG_CfgDoAct: number[] = [],
        public CFG_IdxPMP: number = 0,
        public CFG_IdxHYS: number = 0,
        public CFG_IdxRMSs: number[] = []
    ) {
    }
}

export class FWA extends FWA_CFG {

    constructor(

        public PAR_On: boolean = true,
        public PAR_SetTemp: number = 25,
        public PAR_IdxSCH: number = -1,
        public PAR_SchedOn: boolean = false,
        public PAR_DiffCal: number = 3,
        public TEC_TempMode: number = 0,
        public RTU_ThermOn: boolean = false,
        public RTU_CalibratedTemp: number = Constants.NO_VALUE,
        public RTU_SensorTemp: number = Constants.NO_VALUE,
        public RTU_SetTemp: number = Constants.NO_VALUE,
        public RTU_Alarm: number = 0,
        public RTU_Flags: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    ) {
        super();
    }
}


export class FNC_CFG implements Table {
    constructor(
        public id: number = null,
        public CFG_CfgDoHead: number = null,
        public CFG_IdxHYS: number = 0,
        public CFG_IdxHYS_H: number = 0,
        public CFG_IdxHYS_C: number = 0,
        public CFG_IdxGRP: number = -1,
        public CFG_IdxPMP: number = -1,
        public CFG_IdxRMS: number[] = null,
        public CFG_ModeFC: number = 0,
        public CFG_Model: number = -1,
        public CFG_Name: string = '',
        public CFG_Type: number = 1,
        public CFG_CfgAi: number = 0,
        public CFG_CfgAiSupply: number = 0,
        public CFG_CfgAiReturn: number = 0,
        public CFG_CfgDoElectricHeat: number = 0,
        public CFG_MaxLevel: number = -1,
    ) {
    }
}

// Fan coil
export class FNC extends FNC_CFG implements FSM {
    constructor(
        public PAR_On: boolean = true,
        public PAR_ModeFC: number = 0,
        public RTT_HeadAvail: boolean = true,
        public RTT_FadDelayRaised: boolean = false,
        public RTU_DehumOn: number = 0,
        public RTU_IntegOn: number = 0,
        public RTU_ThermOn: number = 0,
        public RTU_TempEnv: number = -3276.8,
        public RTU_TempH2O: number = -3276.8,
        public RTU_TempH2OReturn: number = 0,
        public TEC_ForceOn: boolean = null,
        // public PAR_Manual_H: boolean = false,
        // public PAR_Manual_C: boolean = false,
        // public PAR_ManualLevel_H: number = -1,
        // public PAR_ManualLevel_C: number = -1,
        public PAR_ManualH: boolean = false,
        public PAR_ManualC: boolean = false,
        public PAR_ManualLevelH: number = -1,
        public PAR_ManualLevelC: number = -1,
        public PAR_MaxSpeed: number = -1,
        public PAR_DiffAoH: number = -1,
        public PAR_DiffAoC: number = -1,
        public PAR_OnHRV: boolean = true,
        public PAR_LevelHRV: number = null,
        public PAR_IdxSCH_HRV: number = -1,
        public TEC_HumLevel: number = 100,
        public TEC_DehumLevel: number = 50,
        public RTU_Flags: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        public RTU_ErrComm: boolean = false,
        public RTU_Error: number = 0,
        public RTU_FadWarning: boolean = false,
        public RTU_CrsWarning: boolean = false,
        public PAR_SchedOn: boolean = false,
        public RTT_FsmState: number = 0,
        public RTU_ElectricHeaterReq: boolean = false,
        public RTT_SupplyAirT: number = -3276.8
    ) {
        super();
    }
}

export class GRP_CFG implements Table {
    constructor(
        public id: number = null,
        public CFG_CanAAC: boolean = false,
        public CFG_CfgDiSeason: number = 0,
        public CFG_DiCmSeason: number = 0,
        public CFG_DoCmSeason: number = 0,
        public CFG_IdxDHW: number = -1,
        public CFG_IdxTNK_C: number = -1,
        public CFG_IdxTNK_H: number = -1,
        public CFG_Name: string = '',
        public CFG_SeasonMode: number = 1,
        public CFG_IdxDVV_Exe: number = -1,
        public CFG_HccLock: number = 0
    ) {
    }
}

// Group
export class GRP extends GRP_CFG {

    constructor(
        public PAR_AAC: number = 0,
        public PAR_ChgToExe: boolean = false,
        public PAR_IdxSCH: number = -1,
        public PAR_RetToRef: boolean = false,
        public PAR_Season: number = 0,
        public PAR_AutoModeStyle: number = 0,
        public PAR_DiffModeSeasonRef: number = 0,
        public PAR_SeasonHC: number = 0, //0 heating - 1 cooling
        public RTT_FsmSeason: number = null,
        public RTU_OnAAC: boolean = false,
        public RTU_OnSC: boolean = false,
        public RTU_SeasonExe: number = -1,
        public RTU_SeasonRef: number = -1,
        public TEC_AacDiffC: number = 6,
        public TEC_AacDiffH: number = 6,
        public TEC_AacDiffOff: number = 2,
        public TEC_AacFixSupply1: number = 50,
        public TEC_AacFixSupply2: number = 60,
        public TEC_AacMinTimeRS: number = 2,
        public TEC_AacMinTimeTS: number = 2,
        public TEC_AacPercMax: number = 80,
        public TEC_AacPercMin: number = 20,
        public TEC_AacSupplyEnab: boolean = true,
        public TEC_ERL: number = 0,
        public TEC_ErlDIff: number = 2,
        public TEC_SCDelayPost: number = 2,
        public TEC_SCDelayPre: number = 2,
        public TEC_SecErAct: number = 10,
        public TEC_SecErMinOn: number = 5
    ) {
        super();
    }
}

export class HYS_CFG implements Table {
    constructor(
        public id: number = null,
        public CFG_CfgAiRet_C: number = 0,
        public CFG_CfgAiRet_H: number = 0,
        public CFG_CfgAiSup_C: number = 0,
        public CFG_CfgAiSup_H: number = 0,
        public CFG_HeadActTime: number = 420,
        public CFG_IdxENR: number[] = [],
        public CFG_IdxGRP: number = 0,
        public CFG_IdxHYS: number = 0,
        public CFG_IdxMXV_C: number = 0,
        public CFG_IdxMXV_H: number = 0,
        public CFG_IdxTNK_C: number = -1,
        public CFG_IdxTNK_H: number = -1,
        public CFG_IdxPMP_C: number = 0,
        public CFG_IdxPMP_H: number = 0,
        public CFG_Is2P: boolean = true,
        public CFG_IsHC: boolean = true,
        public CFG_Name: string = '',
        public CFG_Type: number = 1,
        public CFG_Type_H: number = -1,
        public CFG_Type_C: number = -1
    ) {
    }
}

// Hydraulic system
export class HYS extends HYS_CFG implements FSM {

    constructor(
        public PAR_On: boolean = true,
        public RTT_DewPoint: number = -3276.8,
        public RTT_FsmState: number = 0,
        public PAR_FwaWst: number = 100,
        public RTT_OnFromSV: boolean = true,
        public RTT_PumpState: boolean = false,
        // public RTT_PumpStatus: boolean = false,
        public RTT_ReturnTemp: number = -3276.8,
        public RTT_SupplyTemp: number = -3276.8,
        public RTT_TargetTemp: number = -3276.8,
        public TEC_AFE: boolean = true,
        public TEC_AfrSupAlarmTime: number = 15,
        public TEC_AfrSupMinOnTime: number = 5,
        public TEC_AfrSupTemp: number = 40,
        public TEC_AfrSupTempDiff: number = 9,
        public TEC_CLExtTemp1: number = 30,
        public TEC_CLExtTemp2: number = 60,
        public TEC_CLSupValue1: number = 110,
        public TEC_CLSupValue2: number = 95,
        public TEC_CSL: boolean = false,
        public TEC_DeltaR: number = -6,
        public TEC_DiffLAT: string = "1.0",
        public TEC_DirSupDiff: number = 4,
        public TEC_DynamicDR: boolean = false,
        public TEC_ErDelay: number = 15,
        public TEC_FixedSupply: number = 120,
        public TEC_HSL: number = 2,
        public TEC_HTDelayL1: number = 5,
        public TEC_HTDiffL1: number = 10,
        public TEC_HTDiffL2: number = 10,
        public TEC_HTMaxTime: number = 30,
        public TEC_HtDelayAlarmL1: number = 5,
        public TEC_LTSupplyDelay: number = 5,
        public TEC_LTSupplyTemp: number = 45,
        public TEC_OnOffDiff: number = 4,
        public TEC_OnOffLogic: boolean = false,
        // public TEC_ResetAlarm: boolean = false,
        public TEC_SupplyCtrlDelay: number = 5,
        public TEC_SupplyCtrlDiff1: number = 20,
        public TEC_SupplyCtrlDiff2: number = 20,
        public TEC_SupplyCtrlTO: number = 60,
        public TEC_SupplyHrvC: number = 55,
        public TEC_SupplyHrvH: number = 95,
        public TEC_SupplyNtd: number = 55,
        public TEC_TargetTempMaxC: number = 86,
        public TEC_TargetTempMaxH: number = 158,
        public TEC_TargetTempMinC: number = 46,
        public TEC_TargetTempMinH: number = 76,
        public TEC_FadDiff: number = 4,
        public TEC_FadMinTempH: number = 100,
        public TEC_FadMaxTempC: number = 65,
        public TEC_FadDelay: number = 20,
        public TEC_FadOn: boolean = false,
        public RTU_Flags: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        public RTU_Alarms: number = 0,
        public PAR_OnB: boolean = false, //used only on IF
        public TEC_DelayActOn: boolean = false,
        public TEC_DelayActDiffH: number = -3276.8,
        public TEC_DelayActDiffC: number = -3276.8,
        public TEC_DelayActMaxWaitTime: number = 30,
        public RTU_DelayActFsm: number = 0,
        public RTU_DelayActFsmTS: number = 0
    ) {
        super();
    }
}

export class MCU_CFG implements Table {
    constructor(
        public id: number = null,
        public CFG_GenAlarmType: number = 0,
        public CFG_CfgDiGenAlarm: number = null,
        public CFG_Code: string = '',
        public CFG_DataExp: number = 5,
        public CFG_DiCmGenAlarm: number = 0,
        public CFG_DiCmOwl: number = 0,
        public CFG_DiCmPres: number = 0,
        public CFG_CfgDiOn: number = 0,
        public CFG_ExTimeout: number = 900,
        public CFG_HWType: number = 2,
        public CFG_N_ARS: number = 0,
        public CFG_N_ATU: number = 0,
        public CFG_N_CMN: number = 0,
        public CFG_N_DHW: number = 0,
        public CFG_N_ENR: number = 0,
        public CFG_N_EXP: number = 0,
        public CFG_N_FLS: number = 0,
        public CFG_N_FWA: number = 0,
        public CFG_N_FNC: number = 0,
        public CFG_N_GRP: number = 1,
        public CFG_N_HYS: number = 1,
        public CFG_N_MCZ: number = 1,
        public CFG_N_MXV: number = 0,
        public CFG_N_ODS: number = 0,
        public CFG_N_PMP: number = 0,
        public CFG_N_PRS: number = 0,
        public CFG_N_RMS: number = 0,
        public CFG_N_SHT: number = 0,
        public CFG_N_TMR: number = 0,
        public CFG_N_TNK: number = 0,
        public CFG_N_TRG: number = 0,
        public CFG_N_ZON: number = 0,
        public CFG_N_TMP: number = 0,
        public CFG_Name: string = ''
    ) {
    }
}

// Master control unit
export class MCU extends MCU_CFG {

    constructor(
        public PAR_Network_Status: NetworkStatus = NetworkStatus.Active,
        public PAR_DNS1: string = "8.8.8.8",
        public PAR_DNS2: string = "8.8.4.4",
        public PAR_DiffHumES: number = 10,
        public PAR_DiffTempES: number = 10,
        public PAR_ES: number = 0,
        public PAR_DiffHumSB: number = 10,
        public PAR_DiffTempSB: number = 10,
        public PAR_SB: boolean = true,
        public PAR_Host_Name: string = "messana",
        public PAR_MAC_Addr: string = "",
        public PAR_VPN_Ip_Addr: string = "",
        public PAR_IP_Addr: string = "192.168.2.10",
        public PAR_IP_Gtw: string = "192.168.2.1",
        public PAR_IP_Mask: string = "255.255.255.0",
        public PAR_IP_Mode: number = 0,
        public PAR_Lang: number = 0,
        public PAR_On: boolean = true,
        public PAR_RhMode: boolean = true,
        public PAR_TechMode: number = 0,
        public PAR_UM: number = 0,
        public RTT_NIOVersion: string = '',
        public RTT_SWVersion: string = '',
        public TEC_HystHum: number = 3,
        public TEC_HystTemp: string = "0.4",
        public TEC_LogicMode: number = 0,
        public showConfiguration: boolean = false,
        public RTU_Flags: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        public PAR_SchSetTemp: number = 77,
        public PAR_SchSetTempH: number = 77,
        public PAR_SchSetTempC: number = 77,
        public RTT_SWVersionL: string = '0.0.1',
        public RTT_SWVersionC: string = '0.0.1',
        public RTT_SWVersionH: string = '0.0.1',
        public RTT_SWVersionA: string = '0.0.1',
        public RTT_SWVersionA_Req: string = '0.0.1',
        public RTT_SWVersionA_Opt: string = '0.0.1',
        public RTT_SWFrontEnd: string = '0.0.1',
        public RTU_GenAlarm: boolean = null,
        public RTT_EmersonReboot: boolean = false,
        public PAR_Place: string = '',
        public PAR_Address: string = '',
        public PAR_State: string = '',
        public PAR_City: string = '',
        public PAR_Country: string = '',
        public PAR_ZipCode: string = '',
        public TEC_SPMode: boolean = false,
        public PAR_FlowRateUM: number = 0, // 0 = m3/h - 1 = cfm
        public PAR_FwaConflictTO: number = 30,
        public PAR_FwaInCooling: boolean = false,
        public PAR_FwaInCoolingES: boolean = false,
        public PAR_FwaOhDiffH: number = 0,
        public PAR_FwaOn: boolean = true,
        public PAR_SpHumZ: boolean = false,
        public PAR_SpDehZ: boolean = false,
        public PAR_SpIaqZ: boolean = false,
        public PAR_AccuWeatherKey: string = "",
        public PAR_ReturnDashboardT: number = 3, // never

        public TEC_RHMax: number = null,
        public TEC_DPMax: number = null,
        public TEC_ShowIAQ: boolean = false,
        public TEC_StartupTS: Date = new Date(),
        public TEC_Bioset: boolean = false,
        public TEC_BiosetDiff: number = 0,
        public TEC_BiosetHystTemp: number = 0,
        public TEC_BiosetDeltaRUConst: number = 0,
        public TEC_BiosetAngularMomentum: number = 0,
        public radiantSystem: boolean = true, //only for UI
        public hydronicSystem: boolean = true //only for UI
    ) {
        super();
    }
}

export class MCZ_CFG implements Table {
    constructor(
        public id: number = null,
        public CFG_CfgDiEnab: number = 0,
        public CFG_DiCmEnab: number = 0,
        public CFG_IdxGRP: number = 0,
        public CFG_Name: string = '',
        public CFG_Valid: boolean = true,
        public CFG_ViewOrder: number = 0,
    ) {
    }
}

// Macrozone
export class MCZ extends MCZ_CFG {

    constructor(
        // public PAR_DiffAtt: number = 6,
        // PAR_DiffAtt non più gestito a livello di MacroZona, comunicato da AFerrarelli 16/3/2020 via Asana
        public PAR_IdxSCH_C: number = -1,
        public PAR_IdxSCH_H: number = -1,
        public PAR_Mode: number = 2,
        public PAR_OWL: number = 0,
        public PAR_SOL: boolean = false,
        public PAR_SetTempC: number = null,
        public PAR_SetTempH: number = null,
        public RTU_AverageDP: number = -3276.8,
        public RTU_AverageRH: number = -3276.8,
        public RTU_AverageT: number = -3276.8,
        public RTU_InRange: boolean = false,
        public TEC_AFE: boolean = true,
        public TEC_AfrZnTemp: number = null,
        public TEC_CloseWindowTime: number = 30,
        public TEC_DeltaRMax: number = 8,
        public TEC_DiEnabMode: number = 0,
        public TEC_DiffAo: number = 6,
        public TEC_OpenWindowTime: number = 120,
        public TEC_PrsBsOffTime: number = 0,
        public TEC_PrsBsOnTime: number = 0,
        public TEC_PrsOffTime: number = 0,
        public TEC_PrsOnTime: number = 0,
        public TEC_SetTempMaxC: number = null,
        public TEC_SetTempMaxH: number = null,
        public TEC_SetTempMinC: number = null,
        public TEC_SetTempMinH: number = null,
        // PAR_ES viene utilizzato, lo lasciamo
        public PAR_ES: number = 0,
        public RTU_Flags: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        public RTU_Alarms: number = 0,
        public PAR_SchedOnH: boolean = false,
        public PAR_SchedOnC: boolean = false,

        // override
        public PAR_SpoSetTemp: number = 70,
        public PAR_SpoPeriod: number = 0,
        public PAR_SpoStart: number = 0,
        public RTU_SpoRemain: number = 0,

        public PAR_SpoSetTempH: number = 70,
        public PAR_SpoPeriodH: number = 0,
        public PAR_SpoStartH: number = 0,
        public RTU_SpoRemainH: number = 0,

        public PAR_SpoSetTempC: number = 70,
        public PAR_SpoPeriodC: number = 0,
        public PAR_SpoStartC: number = 0,
        public RTU_SpoRemainC: number = 0
    ) {
        super();
    }

    getAverageTp(mcu: MCU) {
        // tslint:disable-next-line:align
        if (this.RTU_AverageT !== undefined && this.RTU_AverageRH !== undefined && mcu && mcu.TEC_BiosetAngularMomentum != 0) {
            //return this.RTU_AverageT + this.RTU_AverageDP - mcu.TEC_BiosetDiff;
          let deltaRU = this.RTU_AverageRH - mcu.TEC_BiosetDeltaRUConst;

          return (deltaRU > 0 ? this.RTU_AverageT + Math.round(deltaRU/mcu.TEC_BiosetAngularMomentum) :  this.RTU_AverageT - Math.abs(Math.round(deltaRU/mcu.TEC_BiosetAngularMomentum)));

        } else
          return Constants.NO_VALUE;
    }
}

export class MXV_CFG implements Table {
    constructor(
        public id: number = null,
        public CFG_CfgAo: number = 0,
        public CFG_CfgDoClose: number = 0,
        public CFG_CfgDoOpen: number = 0,
        public CFG_CoolingMax: number = 100,
        public CFG_CoolingMin: number = 73,
        public CFG_DeadZoneMax: number = 73,
        public CFG_DeadZoneMin: number = 47,
        public CFG_HeatingMax: number = 46,
        public CFG_HeatingMin: number = 20,
        public CFG_ModeC: number = 0,
        public CFG_ModeH: number = 0,
        public CFG_Name: string = '',
        public CFG_RunTime: number = 90,
        public CFG_Type: number = 1,
        public CFG_VClose: number = 0,
        public CFG_VOpen: number = 10
    ) {
    }
}

// Mixer
export class MXV extends MXV_CFG {

    constructor(
        public RTT_Pos: number = null,
        public RTT_VOut: number = null,
        public TEC_Derivative: number = 15000,
        public TEC_Integral: number = 52,
        public TEC_MaxPercInt: number = 10,
        public TEC_MinPercInt: number = 2,
        public TEC_Proportional: number = 500,
        public TEC_ReposDelta: number = 60
    ) {
        super();
    }
}

export class ODS_CFG implements Table {
    constructor(
        public id: number = null,
        public CFG_CfgAi: number = 0,
        public CFG_MbAddr: number = 0,
        public CFG_Name: string = '',
        public CFG_Type: number = 0
    ) {
    }
}

// Outdoor sensor
export class ODS extends ODS_CFG {

    constructor(
        public RTT_SensTemp: number = null,
        public RTU_DewPoint: number = null,
        public RTU_Hum: number = null,
        public RTU_Temp: number = null,
        //Alarms
        public RTU_Alarms: number = 0,
        public RTU_ErrComm: boolean = false,
        public RTT_ErrModbus: number = 0
    ) {
        super();
    }
}

export class PMP_CFG implements Table {
    constructor(
        public id: number = null,
        public CFG_CfgAo: number = 0,
        public CFG_CfgDi1: number = 0,
        public CFG_CfgDi2: number = 0,
        public CFG_CfgDo1: number = 0,
        public CFG_CfgDo2: number = 0,
        public CFG_Ctrl: number = 0,
        public CFG_DiCmAlarm: number = 0,
        public CFG_MbAddr: number = 0,
        public CFG_Model: number = 0,
        public CFG_Name: string = '',
        public CFG_Type: number = 0
    ) {
    }
}

// Pump
export class PMP extends PMP_CFG {

    constructor(
        public RTT_CommStat: string = "0/0",
        public RTT_ErrComm: boolean = null,
        public RTT_ErrType: number = null,
        public RTT_ErrTypeSl: number = null,
        public RTT_ServiceMsg: number = 0,
        public RTT_Speed: number = 0,
        public RTT_State: number = 0,
        public RTT_StateDiag: number = 0,
        public RTT_SteteSl: number = 0,
        public RTU_Act: boolean = false,
        public TEC_Enable: boolean = true,
        public TEC_IdxSCH: number = -1,
        public TEC_TpDelta: number = 24,
        public TEC_TpMode: number = 3,
        //Alarms
        public RTU_Alarm: boolean = false,
        public RTU_ErrComm: boolean = false,
        public RTT_ErrModbus: number = 0,
        public RTT_ErrorMsg: number = 0
    ) {
        super();
    }
}

export class PRS_CFG implements Table {
    constructor(
        public id: number = null,
        public CFG_Name: string = '',
        public CFG_Type: number = null
    ) {
    }
}

// Pressure sensor
export class PRS extends PRS_CFG {

    constructor(
        public RTU_Pressure: number = null
    ) {
        super();
    }
}

export class RMS_CFG implements Table {
    constructor(
        public id: number = null,
        public CFG_CfgDiOwl: number = null,
        public CFG_CfgDiPres: number = null,
        public CFG_IdxARS: number = -1,
        public CFG_IdxSecondaryARS: number = -1,
        public CFG_IdxATU: number = -1,
        public CFG_IdxGRP: number = 0,
        public CFG_IdxMCZ: number = 0,
        public CFG_IdxSHT: number = -1,
        public CFG_IdxFNC: number = -1,
        public CFG_MbAddr: number = null,
        public CFG_Name: string = '',
        public CFG_Type: number = 0,
        public CFG_ViewOrder: number = null,
        public CFG_IdxTMR1: number = -1,
        public CFG_IdxTMR2: number = -1
    ) {
    }
}

// Room
export class RMS extends RMS_CFG {

    constructor(
        public PAR_FcEnab: boolean = false,
        public PAR_IdxSCH_C: number = -1,
        public PAR_IdxSCH_H: number = -1,
        public PAR_IntegEnab: number = -1,
        // public PAR_IntegEnab: boolean = false,
        public PAR_Mode: number = 3,
        public PAR_SetBackDiff: number = 6,
        public PAR_SetSchedC: number = 77,
        public PAR_SetSchedH: number = 70,
        public PAR_SetTempC: number = null,
        public PAR_SetTempES_C: number = 70,
        public PAR_SetTempES_H: number = 70,
        public PAR_SetTempH: number = null,
        public PAR_State: number = 1,
        public PAR_Timer: string = '',
        public PAR_SPDiff_C: number = 0,
        public PAR_SPDiff_H: number = 0,
        public PAR_NtdOn: boolean = true,
        public RTT_SensTemp: number = null,
        public RTT_Type: number = 0,
        public RTU_AirTemp: number = null,
        public RTU_CO2: number = null,
        public RTU_DewPoint: number = null,
        public RTU_Errors: number = 0,
        public RTU_Hum: number = null,
        public RTU_OpTemp: number = null,
        public RTU_VOC: number = null,
        public RTU_IsHC: boolean = false,
        public TEC_AacWeight: number = 1,
        public TEC_ErrorsMask: number = -1,
        public TEC_OwlWeight: number = 1,
        public PAR_ES: number = 0,
        public RTU_RsIR: any = false,
        public RTU_Flags: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        //Alarms
        public RTU_Alarms: number = 0,
        public RTU_ErrComm: boolean = false,
        public RTU_MTouchErrComm: boolean = false,
        public RTT_ErrModbus: number = 0,
        public RTU_AFR: number = 0,
        public RTU_WFA: boolean = false,
        public PAR_DiffIntegOff: number = 0,
        public PAR_DiffIntegOn: number = 3,
        public PAR_SetDehumRH: number = 60,
        public PAR_SetDehumDP: number = 60,
        public PAR_SetHumRH: number = 30,
        public PAR_SetHumDP: number = 60,
        public RTU_ThermOn: number = 0,
        public PAR_SchedOnH: boolean = false,
        public PAR_SchedOnC: boolean = false,
        public PAR_HrvOn: boolean = false,
        public PAR_BiosetDiffDehum: number = 0,
        public PAR_BiosetCellarEfct: number = 0,
        public PAR_BiosetArtificialEfct: number = 0,

        public TEC_HumMaxLogic: boolean = false,
        public RTU_HighHum: boolean = false,

        // override
        public PAR_SpoSetTemp: number = 70,
        public PAR_SpoPeriod: number = 0,
        public PAR_SpoStart: number = 0,
        public RTU_SpoRemain: number = 0,

        public PAR_SpoSetTempH: number = 70,
        public PAR_SpoPeriodH: number = 0,
        public PAR_SpoStartH: number = 0,
        public RTU_SpoRemainH: number = 0,

        public PAR_SpoSetTempC: number = 70,
        public PAR_SpoPeriodC: number = 0,
        public PAR_SpoStartC: number = 0,
        public RTU_SpoRemainC: number = 0
    ) {
        super();
    }


    getTp(mcu: MCU) {
        // tslint:disable-next-line:align max-line-length
        if (this.RTU_DewPoint !== undefined && this.RTU_OpTemp !== undefined && mcu && this.RTU_Hum!== Constants.NO_VALUE && this.RTU_OpTemp !== Constants.NO_VALUE && mcu.TEC_BiosetAngularMomentum != 0) {
          let deltaRU = this.RTU_Hum - mcu.TEC_BiosetDeltaRUConst;

          return (deltaRU > 0 ? this.RTU_OpTemp + Math.round(deltaRU/mcu.TEC_BiosetAngularMomentum) :  this.RTU_OpTemp - Math.abs(Math.round(deltaRU/mcu.TEC_BiosetAngularMomentum)));
        } else {
            return Constants.NO_VALUE;
        }
    }

    scheduleIsOn(MCU: MCU, season: number): boolean {

        let property = 'PAR_SchedOnC';

        if (MCU.TEC_SPMode || season == Season.Heating) {

            property = 'PAR_SchedOnH';
        }

        return this[property];
    }
}

export class SCH_CFG implements Table {
    constructor(
        public id: number = null,
        public CFG_Name: string = '',
        public CFG_Type: number = 0,
        public CFG_Valid: boolean = true
    ) {
    }
}

// Schedule
export class SCH extends SCH_CFG {

    constructor(
        public PAR_ApplyTo: number = 1023,
        public PAR_Schedule: number[] = null,
        public PAR_Season: number = 1,
        public PAR_TempSched: number[] = null,
        public PAR_TempSchedH: number[] = null,
        public PAR_TempSchedC: number[] = null,
        public PAR_DualSetpointSelection: number = 2,
        public RTU_On: boolean = false,
        public RTU_SetPoint: number = null,
        public RTU_SetPointH: number = null,
        public RTU_SetPointC: number = null

) {
        super();
    }
}

export class SHT_CFG implements Table {
    constructor(
        public id: number = null,
        public CFG_CfgDo: number = 0,
        public CFG_DoCm: number = 0,
        public CFG_Name: string = ''
    ) {
    }
}

// Shutter
export class SHT extends SHT_CFG {

    constructor(
        public RTU_On: boolean = false,
        public TEC_Weight: number = 1
    ) {
        super();
    }
}

export class TMR_CFG implements Table {
    constructor(
        public id: number = null,
        public CFG_Type: number = 0,
        public CFG_CfgDo: number = 0,
        public CFG_DoCm: number = 0,
        public CFG_Name: string = ''
    ) {
    }
}


// Timer
export class TMR extends TMR_CFG {

    constructor(
        public PAR_IdxSCH: number = -1,
        public PAR_Mode: number = 1,
        public PAR_ForcePeriod: number = 0,
        public PAR_ForceStart: number = 0,
        public RTU_ForceRemain: number = 0,
        public RTU_On: boolean = false
    ) {
        super();
    }
}

export class TNK_CFG implements Table {
    constructor(
        public id: number = null,
        public CFG_CfgAi: number = 0,
        public CFG_IdxGRP: number = -1,
        public CFG_Name: string = '',
        public CFG_IdxENR: number[] = [],
        public CFG_CanHC: number = 3
    ) {
    }
}

// Tank
export class TNK extends TNK_CFG {

    constructor(
        public PAR_Mode: number = 1,
        public PAR_State: number = 2,
        public PAR_TempMode: number = 1,
        public RTU_Status: number = 2,
        public RTU_TargetTemp: number = -3276.8,
        public RTU_Temp: number = -3276.8,
        public TEC_AFE: boolean = true,
        public TEC_AfrAlarmTime: number = 15,
        public TEC_AfrDiff: number = 10,
        public TEC_AfrMinOnTime: number = 5,
        public TEC_AfrTemp: number = 50,
        public TEC_CLExtTemp1: number = 30,
        public TEC_CLExtTemp2: number = 60,
        public TEC_CLValue1: number = 110,
        public TEC_CLValue2: number = 95,
        public TEC_DiffCompC: number = 6,
        public TEC_DiffCompH: number = 6,
        public TEC_DiffTankH: number = 2,
        public TEC_DiffTankC: number = 2,
        public TEC_DiffTemp: number = 4,
        public TEC_ERL: number = 0,
        public TEC_HTLimit: number = 160,
        public TEC_SecErAct: number = 10,
        public TEC_SecErMinOn: number = 5,
        public TEC_TempTargetOffC: number = 60,
        public TEC_TempTargetOnC: number = 45,
        public TEC_TempTargetOnH: number = 120,
        public RTU_Flags: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        //Alarms
        public RTU_Alarms: number = 0,
        public state = true //Only for GUI
    ) {
        super();
    }
}

export class TRG_CFG implements Table {
    constructor(
        public id: number = null,
        public CFG_CfgAo: number = 0,
        public CFG_CfgDo: number = 0,
        public CFG_Expr: string = '',
        public CFG_Name: string = ''
    ) {
    }
}

// Trigger
export class TRG extends TRG_CFG {

    constructor(
        public PAR_Enable: boolean = true,
        public PAR_SetTemp: number = 86,
        public RTU_AoValue: number = 0,
        public RTU_DoState: boolean = false,
        public TEC_Hyst: number = 2
    ) {
        super();
    }
}

export class ZON_CFG implements Table {
    constructor(
        public id: number = null,
        public CFG_BaseDR: number = 1.5,
        public CFG_IdxHYS: number = 0,
        public CFG_Floor: boolean = false,
        public CFG_IdxPMP: number = -1,
        public CFG_IdxRMS: number = 0,
        public CFG_InclDR: number = -1.5,
        public CFG_Name: string = '',
        public CFG_TerminalType: number = 0,
        public CFG_Type: number = 3
    ) {
    }
}

// Zone
export class ZON extends ZON_CFG {

    constructor(
        public PAR_On: boolean = true,
        public PAR_Type: number = 0,
        public RTT_DynamicDR: number = 0,
        public RTT_OnOffState: number = 0,
        public TEC_Coeff: number = 1,
        public TEC_CoeffType: number = 0,
        public TEC_DeltaIL: number = 15,
        public TEC_DeltaR: number = 0,
        public TEC_ForceOn: boolean = false,
        public PAR_ES: boolean = false,
        public RTU_Flags: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        public RTT_HeadAvail: boolean = true
    ) {
        super();
    }
}

export class TMP_CFG implements Table {
    constructor(
        public id: number = null,
        public CFG_Name: string = "",
        public CFG_Description: string = "",
        public CFG_Basic: boolean = false,
        public CFG_Valid: boolean = true,
        public CFG_ViewOrder: number = null
    ) {
    }
}

// Template
export class TMP extends TMP_CFG {

    constructor(
        public TEC_Keys: string[] = [],
        public PAR_Active: boolean = true,
        public PAR_Multi: boolean = false
    ) {
        super();
    }
}

// Pre-Defined Template
export class PDT implements Table {

    constructor(
        public id: number = null,
        public CFG_Name: string = "",
        public CFG_Description: string = "",
        public CFG_Basic: boolean = false,
        public CFG_Valid: boolean = true,
        public CFG_ViewOrder: number = null,
        public TEC_Keys: string[] = [],
        public PAR_Active: boolean = true,
        public PAR_Multi: boolean = false,
        public PAR_ValidForAdmin: boolean = false
    ) {
    }
}

// PRE-FILLED Template
export class PFT implements Table {

    constructor(
        public id: number = null,
        public CFG_Name: string = "",
        public CFG_Description: string = "",
        public TEC_Idx_PDT: number = null,
        public TEC_Idx_Objs: number[] = [],
        public TEC_Idx_Int: number
    ) {
    }
}

// MY Template
export class MYT implements Table {

    constructor(
        public id: number = null,
        public CFG_Name: string = "",
        public CFG_Description: string = "",
        public CFG_Valid: boolean = true,
        public CFG_ViewOrder: number = null,
        public TEC_Keys: string[] = [],
        public PAR_Active: boolean = true,
        public PAR_Multi: boolean = false
    ) {
    }
}

export class GEN_CFG implements Table {
    constructor(
        public id: number = null,
        public CFG_Name: string = "",
        public CFG_Param: string = "",
        public CFG_Index: number = null,
        public CFG_Representation: number = null,
        public CFG_Um: string = ""
    ) {
    }
}

// Generic
export class GEN extends GEN_CFG {

    constructor(
        public PAR_Values: number = null,
        public PAR_Label: string[] = []
    ) {
        super();
    }
}


export function getClassByName(name: string): any {
    switch (name) {
        case 'ARS':
            return ARS;
        case 'ATU':
            return ATU;
        case 'DHW':
            return DHW;
        case 'ENR':
            return ENR;
        case 'EXP':
            return EXP;
        case 'FLS':
            return FLS;
        case 'FNC':
            return FNC;
        case 'FWA':
            return FWA;
        case 'GRP':
            return GRP;
        case 'HYS':
            return HYS;
        case 'MCU':
            return MCU;
        case 'MCZ':
            return MCZ;
        case 'MXV':
            return MXV;
        case 'ODS':
            return ODS;
        case 'PMP':
            return PMP;
        case 'PRS':
            return PRS;
        case 'RMS':
            return RMS;
        case 'SCH':
            return SCH;
        case 'SHT':
            return SHT;
        case 'TMR':
            return TMR;
        case 'TNK':
            return TNK;
        case 'TRG':
            return TRG;
        case 'ZON':
            return ZON;
        case 'TMP':
            return TMP;
        case 'PFT':
            return PFT;
        case 'PDT':
            return PDT;
        case 'MYT':
            return MYT;
        case 'GEN':
            return GEN;
        case 'EMA':
            return EMA;
    }
}

export function getClassByNameCFG(name: string): any {
    switch (name) {
        case 'ARS':
            return ARS_CFG;
        case 'ATU':
            return ATU_CFG;
        case 'DHW':
            return DHW_CFG;
        case 'ENR':
            return ENR_CFG;
        case 'EXP':
            return EXP_CFG;
        case 'FLS':
            return FLS_CFG;
        case 'FNC':
            return FNC_CFG;
        case 'FWA':
            return FWA_CFG;
        case 'GRP':
            return GRP_CFG;
        case 'HYS':
            return HYS_CFG;
        case 'MCU':
            return MCU_CFG;
        case 'MCZ':
            return MCZ_CFG;
        case 'MXV':
            return MXV_CFG;
        case 'ODS':
            return ODS_CFG;
        case 'PMP':
            return PMP_CFG;
        case 'PRS':
            return PRS_CFG;
        case 'RMS':
            return RMS_CFG;
        case 'SCH':
            return SCH_CFG;
        case 'SHT':
            return SHT_CFG;
        case 'TMR':
            return TMR_CFG;
        case 'TNK':
            return TNK_CFG;
        case 'TRG':
            return TRG_CFG;
        case 'ZON':
            return ZON_CFG;
        case 'TMP':
            return TMP_CFG;
        case 'GEN':
            return GEN_CFG;
        case 'EMA':
            return EMA_CFG;
    }
}


export enum TableNames {
    ARS,
    ATU,
    DHW,
    ENR,
    EXP,
    FLS,
    FNC,
    GRP,
    HYS,
    MCU,
    MCZ,
    MXV,
    ODS,
    PMP,
    PRS,
    RMS,
    SCH,
    SHT,
    TMR,
    TNK,
    TRG,
    ZON,
    TMP,
    PDT,
    PFT,
    MYT,
    GEN,
    EMA,
    FWA
}

export class Tables {

    constructor(
        public loaded: Array<boolean> = new Array(TableNames.EMA + 1).fill(false),
        public ARS: ARS[] = [],  // Air system
        public ATU: ATU[] = [],  // Air treatment unit
        public DHW: DHW[] = [],  // Domestic hot water
        public ENR: ENR[] = [],  // Energy resource
        public EXP: EXP[] = [],  // Expansion
        public FLS: FLS[] = [],  // Flow sensor
        public FNC: FNC[] = [],  // Fan coil
        public GRP: GRP[] = [],  // Group
        public HYS: HYS[] = [],  // Hydraulic system
        public MCU: MCU[] = [],  // Master control unit
        public MCZ: MCZ[] = [],  // Macrozone
        public MXV: MXV[] = [],  // Mixer
        public ODS: ODS[] = [],  // Outdoor sensor
        public PMP: PMP[] = [],  // Pump
        public PRS: PRS[] = [],  // Pressure sensor
        public RMS: RMS[] = [],  // Room
        public SCH: SCH[] = [],  // Schedule
        public SHT: SHT[] = [],  // Shutter
        public TMR: TMR[] = [],  // Timer
        public TNK: TNK[] = [],  // Tank
        public TRG: TRG[] = [],  // Trigger
        public ZON: ZON[] = [],  // Zone
        public TMP: TMP[] = [],  // Template
        public PDT: PDT[] = [],  // Template Predefined
        public PFT: PFT[] = [],  // Template Pre-filled
        public MYT: MYT[] = [],  // Template My
        public GEN: GEN[] = [],
        public EMA: EMA[] = [],  //Email Alert profile
        public FWA: FWA[] = [], // Floor warmer
        public error: any = null
    ) {
    }
}

// ChangeTableValues returns this class instead of the state (in order to update only the exact property)

class ChangedValues {

    constructor(public property, public value) {
    }
}

// _dev_ (unused) this Class is unused (the code is commented) but it could be used to only update the property received from the backend instead of re-assigning every table again when I receive some data
// For more info read the (commented) code on the subscribe: "this.subscriptions.set('tables', this.observables.tables.subscribe"
// You can safely delete this class (and ChangedValues clsass above too) and the associated data types (and the comments) if this implementation will be discarded in the future
export class ChangedTableValues {

    public table: string;
    public id: number;
    public changedValues: ChangedValues[] = [];

    constructor(action: ChangeTableValues) {

        // data from reducer (action)
        if (action) {

            this.table = action.table;
            this.id = action.id;

            for (const property of Object.keys(action.values)) {

                this.changedValues.push(new ChangedValues(property, action.values[property]));
            }
        }
    }
}

export const ALARMS_TRANSLATIONS = {
    "ANTI_FREEZE_ON": "Anti-freeze Acceso",
    "ENVIRONMENT_TEMPERATURE_PROBE_ALARM": "Allarme sonda temperatura ambiente",
    "WATER_PROBE_ALARM": "Allarme sonda acqua",
    "HOT_WATER_PROBE_ALARM": "Allarme sonda acqua calda",
    "ENVIRONMENT_HUMIDITY_PROBE_ALARM": "Allarme sonda umidità ambiente",
    "PROBE_ALARM": "Allarme sonda",
    "CONDENSATE_DRAINAGE_FAILURE": "Guasto scarico condensa. Controlla immediatamente le linee di condensa e dell'acqua dell'unità aria",
    "DIRTY_FILTER": "Filtro sporco",
    "GENERAL_ALARM": "Allarme generale",
    "LOW_PRESSURE": "Bassa pressione",
    "HIGH_PRESSURE": "Alta pressione",
    "GENERIC_ALARM": "Allarme generico",
    "RECOVERY_FILTER_ALARM": "Allarme filtro ripresa",
    "EXTERNAL_AIR_FILTER_ALARM": "Allarme filtro aria esterna",
    "INLET_FAN_INVERTER_ALARM": "Allarme inverter ventilatore immissione",
    "EXPULSION_FAN_INVERTER_ALARM": "Allarme inverter ventilatore espulsione",
    "HIGH_PRESSURE_PROBE_ALARM": "Allarme sonda alta pressione",
    "LOW_PRESSURE_PROBE_ALARM": "Allarme sonda bassa pressione",
    "DIFF_AIR_PRESSURE_TRANSDUCER_ALARM": "Allarme trasduttore pressione diff.aria",
    "HUMIDITY_PROBE_ALARM": "Allarme sensore umidità",
    "SUPPLY_NTC_PROBE_ALARM": "Allarme sonda NTC mandata",
    "ETERNAL_AIR_NTC_PROBE_ALARM": "Allarme sonda NTC aria esterna",
    "INTERNAL_BATTERY_NTC_PROBE_ALARM": "Allarme sonda NTC batteria interna",
    "EXTERNAL_BATTERY_NTC_PROBE_ALARM": "Allarme sonda NTC batteria esterna",
    "PHASE_SEQUENCE_ALARM": "Allarme sequenza fasi",
    "COMPRESSOR_INVERTER_ALARM": "Allarme inverter compressore",
    "ANTI_FREEZE": "Anticongelamento",
    "FREON_MISSING": "Freon mancante",
    "PROBE_FAILURE": "Fallimento sonda",
    "ERROR_ON_FLOW_VALUE": "Errore su valore di flusso",
    "ERROR_ON_TEMPERATURE_VALUE": "Errore su valore di temperatura",
    "HIGH_FLOW": "Alto flusso",
    "LOW_FLOW": "Basso flusso",
    "SUPPLY_ANTIFREEZE": "Anticongelamento in ingresso",
    "ROOM_ANTIFREEZE": "Anticongelamento stanza",
    "SUPPLY_TEMP_DOESNT_REACH_THE_TARGET": "La temperatura di mandata non raggiunge l'obiettivo",
    "ENERGY_RESOURCE_NOT_AVAILABLE": "Risorsa energetica non disponibile",
    "OPERATIVE_TEMPERATURE_SENSOR_FAULT": "Fallimento sensore temperatura operativa",
    "OPERATIVE_TEMPERATURE_SENSOR_FAILURE": "Fallimento sensore temperatura operativa",
    "AIR_TEMPERATURE_SENSOR_FAULT": "Fallimento sensore temperatura aria",
    "OPERATIVE_TEMPERATURE_SENSOR_OUT_OF_RANGE": "Sensore temperatura operativa fuori range",
    "AIR_TEMPERATURE_SENSOR_FAILURE": "Fallimento sensore temperatura aria",
    "AIR_TEMPERATURE_SENSOR_OUT_OF_RANGE": "Sensore temperatura aria fuori range",
    "RH_SENSOR_FAILURE": "Fallimento sensore umidità relativa",
    "RH_SENSOR_OUT_OF_RANGE": "Sensore umidità relativa fuori range",
    "CO2_SENSOR_FAILURE": "CO2 sensor failure",
    "CO2_SENSOR_OUT_OF_RANGE": "Sensore CO2 fuori range",
    "VOC_SENSOR_FAILURE": "Fallimento sensore VOC",
    "VOC_SENSOR_OUT_OF_RANGE": "Sensore VOC fuori range",
    "GENERIC_FAILURE": "Guasto generico",
    "CO2_MODULE_COMMUNICATION_ERROR": "Errore comunicazione modulo CO2",
    "CO2_MEASUREMENT_ERROR": "Errore misurazione CO2",
    "NTC_MEASUREMENT_ERROR": "Errore misurazione NTC",
    "RH_TEMPERATURE_MODULE_COMMUNICATION_ERROR": "Errore comunicazione modulo temperatura/umidità rel.",
    "RH_MEASUREMENT_ERROR": "Errore misurazione umidità relativa",
    "TEMPERATURE_MEASUREMENT_ERROR": "Errore misurazione temperature",
    "OUTPUT_CONFIGURATION_ERROR": "Errore configurazione output",
    "TEMPERATURE_PROBE_FAILURE": "Guasto sonda temperatura",
    "MODBUS_EXCEPTION_CODE": "Codice eccezione Modbus",
    "OUTSIDE_TEMPERATURE_UNDER_LIMIT_HP": "Temperatura esterna sotto il limite per PdC aria aria",
    "HIGH_HUMIDITITY": "Umidità elevata",
    "WOOD_FLOOR_SENSOR_ALARM": "Allarme sensore pavimento in legno",
    "CAL_WARNING": "Calibrazione in corso",
    "FIREFIGHTING_ALARM": "Allarme antincendio",
    "PURESSURE_ALARM": "Alarme alta pressione - controlla il tuo rilevatore Axiom",
    "FWA_OVERHEATING": "Surriscaldamento nella stanza con il pavimento riscaldato.",
    "FWA_THERMAL_REQ_INHIBITED": "Richiesta termica inibita dalle impostazioni di risparmio energetico.",
    "FWA_THERMAL_REQ_INHIBITED_RELATED": "Richiesta termica inibita dalle impostazioni di risparmio energetico - un sensore di ambiente correlato è spento.",
    "FWA_THERMAL_REQ_INHIBITED_COOLING": "Richiesta termica inibita - una richiesta di raffreddamento è stata prioritaria.",
    "FWA_THERMAL_REQ_INHIBITED_MAX_RUNTIME_1": "Richiesta termica inibita - tempo massimo di esecuzione raggiunto",
    "FWA_THERMAL_REQ_INHIBITED_MAX_RUNTIME_2": "durante una richiesta di raffreddamento simultanea.",
    "ATU_TNK_TEMP_WARNING":"La temperatura dell'accumulo non ha ancora raggiunto il livello appropriato richiesto per il corretto funzionamento dell'UTA",
    "HYS_DELAY_ACT_VALIDATE_OPTIMAL":"La pompa del sistema idronico non è stata ancora attivata a causa della temp. non ottimale del serbatoio",
    "HYS_DELAY_ACT_SUBOPTIMAL":"La pompa del sistema idronico è stata attivata nonostante la temperatura del serbatoio non sia ottimale"
}

import {Moment} from "moment/moment";
import {interval, Subscription} from "rxjs";
import * as moment from "moment";
import {SupportService} from "../services/support.service";

export class CurrentDateTime {

    public dateTimeAsMoment: Moment;
    public dateTimeAsString: string = ''; // If a page needs a different string for displaying a date, you can create another and update it in the function "this.setCurrentDateTimeAsString()"
    private dataSyncSubscription: Subscription;
    private language: string;
    private syncingDataWithBackend: boolean = false;
    private supportService: SupportService;

    constructor() {

        this.keepDataSynced();
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- UPDATE LANGUAGE ---------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    setLanguage(language) {

        this.language = language;
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- KEEP DATA SYNCED --------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    private keepDataSynced() {

        if (!this.dataSyncSubscription) {

            this.dataSyncSubscription = interval(1000).subscribe(async () => {

                if (this.dateTimeAsMoment && this.language) {

                    this.dateTimeAsMoment.locale(this.language).add(1, 'second');
                    this.setCurrentDateTimeAsString();

                    // Check if javascript execution was halted (on mobile the date is refreshed, while on browser it's not if you minimize chrome and return)
                    // backendTime / currentTime -> represent the sum of the current minutes and current seconds (of both the device time and the backend time), as seconds
                    const backendTime = Number(moment.utc(this.dateTimeAsMoment).format('mm')) * 60 + Number(moment.utc(this.dateTimeAsMoment).format('ss'));
                    const currentTime = Number(moment.utc().format('mm')) * 60 + Number(moment.utc().format('ss'));

                    // If there is more than 3 seconds of desync -> I update the data
                    // Explanation: I don't care of timezones, it can be 05:23:45 on the backend and 09:23:45 on the device. The data should always be updated with extreme precision (exact second) but if the javascript was halted (maybe the web TAB was inactive for 1 hour) the minutes / seconds won't correspond anymore -> so I call a GET update to the backend server
                    const timeIsDesynced = currentTime > backendTime + 3 || backendTime > currentTime + 3;

                    // console.log(timeIsDesynced, this.syncingDataWithBackend);

                    // If the current time is now different from the backend time (3 seconds are enough to be considered desynced already) -> it means that the date desynced (maybe the web tab was inactive) -> so I update the data again from the server
                    if (timeIsDesynced && this.supportService && !this.syncingDataWithBackend) {

                        console.log('Syncing Time!');

                        this.syncingDataWithBackend = true;
                        // console.log('this.syncingDataWithBackend', this.syncingDataWithBackend);
                        await this.supportService.updateCurrentDateTime();
                        this.syncingDataWithBackend = false;
                        // console.log('this.syncingDataWithBackend', this.syncingDataWithBackend);
                    }
                }
            });
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- CONVERT A MOMENT DATE TO STRING -----------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    private setCurrentDateTimeAsString() {

        if (!this.dateTimeAsMoment || !this.language) {

            return '';
        }

        let currentDate = this.dateTimeAsMoment.format('LLLL');

        // Customization of the Date Display - Day
        const defaultDayDisplay = this.dateTimeAsMoment.format('dddd');
        let customDayDisplay = defaultDayDisplay.substring(0, 3);

        // Customization of the Date Display - Year
        const year_full = this.dateTimeAsMoment.format("YYYY");
        let defaultYearDisplay = '';
        const customYearDisplay = ` ${year_full}&nbsp;&nbsp;&nbsp;`;

        // EN and IT dates are slightly modified from moment's standard date display
        switch (this.language) {

            case 'en':

                defaultYearDisplay = `, ${year_full}`;
                currentDate = currentDate.replace(defaultDayDisplay, customDayDisplay).replace(defaultYearDisplay, customYearDisplay);
                break;

            case 'it':

                customDayDisplay += ',';
                defaultYearDisplay = ` ${year_full}`;

                currentDate = currentDate.replace(defaultDayDisplay, customDayDisplay).replace(defaultYearDisplay, customYearDisplay);
                break;
        }

        this.dateTimeAsString = currentDate;
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- RESET DATA (useful for example when changing MCU) -----------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    resetCurrentDateTime() {

        this.dateTimeAsMoment = null;
        this.dateTimeAsString = '';
    }

    // -----------------------------------------------------------------------------------------------------------------

    setSupportService(supportService: SupportService) {

        this.supportService = supportService;
    }
}

<div class="bottomLine"></div>

<collapse class="scheduleCollapse" [(ngModel)]="showCollapse"
          [itemClass]="'topLine cursorPointer'" [ngModelOptions]="{standalone: true}" ngDefaultControl
          label="{{ hpControlModeVariables.hpControlModeTitleLabel | translate }}"
          [disabled]="false">
</collapse>


<ion-radio-group *ngIf="showCollapse" [(ngModel)]="enr[hpControlModeVariables.PAR_TargetTempMode]"
                 [ngModelOptions]="{standalone: true}">

    <!-- <ion-item class="no-lines">
        <ion-label class="label-title">
            {{ hpControlModeVariables.hpControlModeTitleLabel | translate }}
        </ion-label>
    </ion-item> -->

    <ion-item class="no-lines hrv-indent">
        <ion-label
                class="label-radio font-weight-500">{{ hpControlModeVariables.hpFixedTargetTempLabel | translate }}</ion-label>
        <ion-radio (click)="context.onClickRadioAndDispatch('ENR', enr, hpControlModeVariables.PAR_TargetTempMode, 0)"
                   [value]="0"></ion-radio>
    </ion-item>

    <!-- Fixed Temperature -->
    <counter [inDisabled]="enr[hpControlModeVariables.PAR_TargetTempMode] !== 0" itemClass="no-lines hrv-indent" temperature
             table="ENR" [item]="enr"
             var="{{hpControlModeVariables.TEC_TargetTempH}}"
             label="{{ hpControlModeVariables.hpTargetTempHLabel | translate }}"
             [attrs]="attrs" [MCU]="MCU" [whileChange]="context.onChange"></counter>

    <div *ngIf="showWarningSetpointBelowDHW" class="collapse-info-counter no-lines">
        <i class="fa-solid fa-circle-exclamation yellow-messana-warning margin-warning-icon"></i><i>{{ 'DHW_SETPOINT_BELOW_WARNING' | translate}} ({{enr.dhwList[0].RTU_TargetTemp}}°).</i>
    </div>

    <div *ngIf="showWarningSetpointBelowTNK && enr.tnkList[0].grp.RTU_SeasonExe === 0" class="collapse-info-counter no-lines">
        <i class="fa-solid fa-circle-exclamation yellow-messana-warning margin-warning-icon"></i><i>{{ 'TNK_SETPOINT_BELOW_WARNING' | translate}} ({{enr.tnkList[0].RTU_TargetTemp}}°).</i>
    </div>

    <counter [inDisabled]="enr[hpControlModeVariables.PAR_TargetTempMode] !== 0"
             *ngIf="hpControlModeVariables.TEC_TargetTempC" itemClass="no-lines noBot hrv-indent" temperature table="ENR"
             [item]="enr"
             var="{{hpControlModeVariables.TEC_TargetTempC}}"
             label="{{ hpControlModeVariables.hpTargetTempCLabel | translate }}"
             [attrs]="attrs" [MCU]="MCU" [whileChange]="context.onChange"></counter>

    <div *ngIf="showWarningSetpointBelowTNK && enr.tnkList[0].grp.RTU_SeasonExe === 1" class="collapse-info-counter no-lines">
        <i class="fa-solid fa-circle-exclamation yellow-messana-warning margin-warning-icon"></i><i>{{ 'TNK_SETPOINT_ABOVE_WARNING' | translate}} ({{enr.tnkList[0].RTU_TargetTemp}}°).</i>
    </div>

    <ion-item class="no-lines hrv-indent">
        <ion-label
                class="label-radio font-weight-500">{{ hpControlModeVariables.hpFollowLabel | translate }}</ion-label>
        <ion-radio (click)="context.onClickRadioAndDispatch('ENR', enr, hpControlModeVariables.PAR_TargetTempMode, 1)"
                   [value]="1"></ion-radio>
    </ion-item>

    <!--  Follow TNK / DHW -->
    <counter [inDisabled]="enr[hpControlModeVariables.PAR_TargetTempMode] !== 1"
             itemClass="no-lines hrv-indent {{hpControlModeVariables.diffCompHSublabel ? 'padding-bottom-0' : ''}}" temperature
             table="{{hpControlModeVariables.diffTable}}" [item]="hpControlModeVariables.diffItem"
             var="{{hpControlModeVariables.TEC_DiffTankH}}"
             label="{{ hpControlModeVariables.diffCompHLabel | translate }}" [attrs]="attrs" [MCU]="MCU"
             [whileChange]="context.onChange"></counter>

    <div *ngIf="hpControlModeVariables.diffCompHSublabel"
         [ngClass]="{'item-disabled' : enr[hpControlModeVariables.PAR_TargetTempMode] !== 1}"
         class="collapse-info-counter no-lines">
        <span>{{ hpControlModeVariables.diffCompHSublabel | translate}}</span>
    </div>

    <counter [inDisabled]="enr[hpControlModeVariables.PAR_TargetTempMode] !== 1"
             *ngIf="hpControlModeVariables.TEC_DiffTankC" itemClass="hrv-indent no-lines" temperature
             table="{{hpControlModeVariables.diffTable}}" [item]="hpControlModeVariables.diffItem"
             var="{{hpControlModeVariables.TEC_DiffTankC}}"
             label="{{ hpControlModeVariables.diffCompCLabel | translate }}" [attrs]="attrs" [MCU]="MCU"
             [whileChange]="context.onChange"></counter>

</ion-radio-group>

<div class="bottomLine"></div>
import { Injectable } from '@angular/core';
import { Observable,of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, Effect,ofType } from '@ngrx/effects';
import { map,catchError,mergeMap,tap } from 'rxjs/operators';


import { AppState } from '../services/app-state.service';
import { ActionsActions,ExecuteActionSuccess,ExecuteActionError,ExecuteAction,ActionsActionTypes,LoadStatusSuccess,LoadStatusError } from '../actions/actions.actions';
import { ActionsService } from '../services/actions.service';

@Injectable()
export class ActionsEffects {

    // The actions$ variable is a StateUpdate from ngrx/effects.
    // This is an observable that emits everytime an action flows through the store.
    constructor(private actions$: Actions<ActionsActions>,
                private actionsService: ActionsService) {
    }

    @Effect()
       executeAction$: Observable<any> = this.actions$.pipe(
        ofType(ActionsActionTypes.ExecuteAction),
         //tap(action=>console.log(action)),
        mergeMap(action => {
             
               return this.actionsService.executeAction(action['action'], action['values']).pipe(
                 //tap(res =>{ console.warn('risultati azione su backend: '); console.log(res) }),

                  // If successful, dispatch success action with result
                  map(data => new ExecuteActionSuccess(data)),
                  // If request fails, dispatch failed action
                  catchError((err) => of(new ExecuteActionError( err)))
              )
            }
        )
    );


    @Effect()
       loadStatus$: Observable<any> = this.actions$.pipe(
        ofType(ActionsActionTypes.LoadStatus),
      
        mergeMap(action => {
             
               return this.actionsService.loadStatus().pipe(
                  // If successful, dispatch success action with result
                  map(data => new LoadStatusSuccess(data)),
                  // If request fails, dispatch failed action
                  catchError((err) => of(new LoadStatusError( err)))
              )
            }
        )
    );







}

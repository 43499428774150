import { Injectable } from '@angular/core';

import { 
  HttpInterceptor, HttpRequest, HttpResponse, 
  HttpHandler, HttpEvent, HttpErrorResponse 
} from '@angular/common/http';

import { HttpClient } from '@angular/common/http';

import { map,tap,takeUntil } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import {Observable} from 'rxjs';
import {finalize } from 'rxjs/operators';
import { AppState } from '../services/app-state.service';
import { Config } from '../models/config';
import { HttpStatus } from '../models/http-status';
import { OpenHttp, CloseHttp } from '../actions/http-status.actions';
import {HttpCancelService} from './http-cancel.service';


@Injectable({
  providedIn: 'root'
})
export class AuthHttpService implements HttpInterceptor {

    private config: Observable<Config>;
    private httpStatus: Observable<HttpStatus>;
    private token: string;
    public pendingRequests: number = 0;

    constructor(protected http:HttpClient,
                protected store: Store<AppState>,
                private httpCancelService: HttpCancelService
              )
    {
        // console.warn('AUTH-HTTP.SERVICE.CONSTRUCTOR()!');
        this.config = store.select('config') as Observable<Config>;
        this.config.subscribe(data => {
            this.token = data.currentUser ? data.currentUser.token : null;
        })
        this.httpStatus = store.select('httpStatus') as Observable<HttpStatus>;
        //this.store.dispatch(new LoadConfig());
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
         //console.warn('AUTH-HTTP.SERVICE.INTERCEPT()!');
        //gestisco chiamate solo di questo tipo, con questo header e non senza token
        //da vedere in futuro


       // console.log('intercept: req vale')
        //console.log(req);
        const modified = req.clone({headers: req.headers.set('Authorization', 'Bearer '+this.token)});
        this.pendingRequests++
       
         this.store.dispatch(new OpenHttp());




        let toSend:HttpRequest<any>;
        if(modified.url.includes('login')){
            // console.log("LOGIN !!");
            toSend=req;
        }else {
            toSend=modified;
            //console.log('imposto http header bearer su chiamata http');
        }

         


        if(!toSend.url.includes('login') && !toSend.url.includes('logout') && !toSend.url.includes('alive'))
        return next.handle(toSend).pipe(
           
            tap((event) => {

              
                //console.log(`OpenHttp pending request vale ${this.pendingRequests}`);
                
             
                 
               
                  }),

            takeUntil(this.httpCancelService.onCancelPendingRequests())

                 ,
                 map(event => {
        if (event instanceof HttpResponse ) {
           // console.warn("http responce")
              //console.log(event);
        }         
        return event;
    }),
                 
             finalize(() => {

                this.pendingRequests--;
                this.store.dispatch(new CloseHttp());
                //console.log(`CloseHttp, pending requests vale ${this.pendingRequests}`)
                }
             )
                


            );
    else 
        return next.handle(toSend).pipe(
           
            tap((event) => {

              
                //console.log(`OpenHttp pending request vale ${this.pendingRequests}`);
                
             
                 
               
                  }),
                 map(event => {
        if (event instanceof HttpResponse ) {
           // console.warn("http responce")
              //console.log(event);
        }         
        return event;
    }),
                 
             finalize(() => {

                this.pendingRequests--;
                this.store.dispatch(new CloseHttp());
                //console.log(`CloseHttp, pending requests vale ${this.pendingRequests}`)
                }
             )
                


            );
    }

    
}
import { Component, OnInit, Input,HostListener } from '@angular/core';
import { Table, GRP } from '../../models/tables';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AbstractBaseComponent } from '../abstract-base-component';
import {  Platform,ModalController} from '@ionic/angular';
import { AppState } from '../../services/app-state.service';
import {ActionSheetController, AlertController } from '@ionic/angular';


import { ToastController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser'

export enum KEY_CODE {
  ESCAPE = 27,
  ENTER = 13
}

@Component({
  selector: 'app-acceptance',
  templateUrl: './acceptance.component.html',
  styleUrls: ['./acceptance.component.scss'],
})
export class AcceptanceComponent extends AbstractBaseComponent  implements OnInit {

  
  @Input() title:string;
  @Input() message:string;


     async presentToast(toastMessage: string){
        let toast = await this.toastCtrl.create({
            cssClass:(!this.app)?'termsToast':'',
            message: toastMessage,
            duration:5000,
            position: 'bottom'
        });
        await toast.present();
    }
 

	private descId: string;
	private enabled: boolean;
    private hdrId: string;
    private id: number;
    private lastClick: number;
    private msgId: string;
    private subHdrId: string;
    public loaded:boolean;
    public statement:string;
   
    
    // schedulation type
    

   
    public subTitle:string;
    public buttons:any[];

 

    public _acceptance:boolean;

  constructor(protected store: Store<AppState>,
                protected platform: Platform,
                protected alertController: AlertController,
                protected actionSheetController: ActionSheetController,
               
                protected translate:TranslateService, public modalController:ModalController, protected toastCtrl: ToastController,
                protected sanitized?: DomSanitizer) {

   

     super(store, platform, alertController, actionSheetController, translate,toastCtrl,sanitized);
      this.loaded=false;
     this.platform.ready().then(() => {
       this.loaded=true;
     });

  	this.subTitle='';
  	
  	this.buttons=[
               
                {
                  text: this.translate.instant('CONTINUE'),
                  handler: async (retParams) => {
                  			 // console.log(retParams);
                  			 if(retParams.accepted){
                  			 	 // console.log('cliccato bottone continue')
	                  		 	 this.modalController.dismiss({
								      'process': true,
								      ...retParams
								    });
                  			 } else {
                  			 	this.presentToast(this.translate.instant('MUST_ACCEPT_USE_TERMS_ERROR'))
                  			 }
                  			
                  }

                },
                /* {
                    text: this.translate.instant('VIEW_TERMS'),
                    handler: async () => {
                    	console.log('cliccato bottone terms');
                        this.modalController.dismiss({
                    'process': false
                  });
                    }
                } */
            ];


        this.id = (++alertIds);
        this.descId = '';
        this.hdrId = 'alert-hdr-' + this.id;
        this.subHdrId = 'alert-subhdr-' + this.id;
        this.msgId = 'alert-msg-' + this.id;
        this.lastClick = 0;
       

        if (this.message) {
            this.descId = this.msgId;

        } else if (this.subTitle) {
            this.descId = this.subHdrId;
        }
   }

   handleAcceptance(event){
   	this._acceptance=event.detail.checked;

       setTimeout(()=>{
			// console.log(`dopo il click acceptane vale ${this._acceptance} e  event vale`);
			// console.log(event);
       },1500)
    

   		
   }
/*

 // ionic 4 non permette di gestire event emitter su controller
   openTermsOfUse(){
     this.url="https://radiantcooling.com/terms-of-use";
     this.showTermsOfUse.emit(true);


   }

   openPrivacy(){
     this.url="https://radiantcooling.com/privacy-policy";
     this.showPrivacy.emit(true);
   }
*/

  ionViewWillEnter(){
    this.statement=this.translate.instant('TERMS_LABEL');
  }


   ionViewDidEnter() {
   	// console.warn('ionViewLoaded')
    
        this._acceptance=false;
        this.enabled = true;
    }

    ionViewLoaded() {
    	// console.warn('ionViewLoaded')
        this.enabled = true;
      /*  this.buttons = this.buttons.map(button => {
            if (typeof button === 'string') {
                return { text: button };
            }
            return button;
        });*/
    }


  ngOnInit() {
  	// console.warn('ngOnInit')
  	 this.enabled = true;
  	this._acceptance=false;
  }

   btnClick(button: any) {
        if (!this.enabled) {
        	// console.log(`enabled vale ${this.enabled}`)
            return;
        }

      

        if (button.handler) {
        	// console.log('button.handler era definito')
            // a handler has been provided, execute it
            // pass the handler the values from the inputs
            button.handler(this.getValues()) 
        }

       
    }

    @HostListener('body:keyup', ['$event'])
    public _keyUp(ev: KeyboardEvent) {
    	ev.preventDefault();
    	// console.log(ev.keyCode);

        if (this.enabled) {
            if (ev.keyCode === KEY_CODE.ENTER) {
                if (this.lastClick + 1000 < Date.now()) {
                    // do not fire this click if there recently was already a click
                    // this can happen when the button has focus and used the enter
                    // key to click the button. However, both the click handler and
                    // this keyup event will fire, so only allow one of them to go.
                    // console.debug('alert, enter button');
                    let button = this.buttons[this.buttons.length - 1];
                    this.btnClick(button);
                }

            } else if (ev.keyCode === KEY_CODE.ESCAPE) {
                // console.debug('alert, escape button');
                 this.modalController.dismiss({
								      'toSave': false
            });
        }
    }
}

 getValues() {
 	// console.log(`accepted vale ${this._acceptance};`)
 	
        return { accepted: this._acceptance };
   
    }




}

let alertIds = -1;



       
        



<div [ngClass]="{'alignIconToRadioButtonIos': alignIconToRadioButton === AlignIconToRadioButton.iOS, 'alignIconToRadioButtonAndroidWeb': alignIconToRadioButton === AlignIconToRadioButton.androidAndWeb, 'd-flex flex-row justify-content-center': alignIconToRadioButton === AlignIconToRadioButton.none}" class="icon icon-hrv-component">
    <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1280 1280">

        <g>
            <path [ngStyle]="{'fill': 'url(#animate-gradient-non-symmetry-inverse-'+id+')'}" class="st0_left_arrow" d="M389.3,522.4h-33.9v130.4L136.7,434.2L358.4,208v131.2h30.9c186.2,0,265.4,178.7,329.5,321.2
		S825,886.5,896.7,886.5h220.9v183.2H896.7c-196,2.3-281.2-183.2-345.3-334C494.1,606.8,451.8,522.4,389.3,522.4L389.3,522.4z"/>
        </g>

        <g>
            <path [ngStyle]="{'fill': 'url(#animate-gradient-non-symmetry-'+id+')'}" class="st1_right_arrow " d="M386.8,1094.8c-1.2,0-2.5,0-3.7,0H137.3V861.5h245.9c17,0,40.5-5.6,71.4-49.1c27-38,53.9-96,83.7-162.3
			l1.2-2.7c32.4-72,69.1-153.7,122.2-218.1c29.8-36.2,61.4-63.1,96.5-82.2c40-21.8,84.5-32.8,132.3-32.8h5.9V146.8l281.8,287.6
			L899.6,713.2V547.4l-8.9,0c-12.8,0-33.2,4.9-62.4,47.5c-25.4,37.1-50.8,92.5-76.8,150.9c-33.9,79.8-71.6,163.2-125.8,229.1
			c-31.1,37.7-64.1,65.8-100.9,85.8C483,1083.3,436.6,1094.8,386.8,1094.8z"/>
            <path class="st2_right_arrow_border_white" [ngClass]="{'st2_right_arrow_border_black': hasBlackBackground}" d="M921.5,208l221.7,226.2L924.6,652.8V522.4h-33.9v0c-62.6,0-104.8,84.4-162.1,213.4
			c-63.7,149.9-148.3,334-341.8,334c-1.2,0-2.4,0-3.5,0H162.3V886.5h220.9c71.6,0,113.8-83.7,177.9-226.2
			c64.1-142.5,143.2-321.2,329.5-321.2h30.9V208 M871.5,85.5V208v81.7c-44.8,2.6-86.9,14.5-125.2,35.3c-38,20.7-72,49.6-103.9,88.3
			c-55.2,67-92.7,150.3-125.7,223.8l-1.2,2.7c-29.3,65.1-55.6,121.9-81.3,158.1c-27.4,38.6-44,38.6-51,38.6H162.3h-50v50v183.2v50
			h50h220.6c1.3,0,2.6,0,3.8,0c54,0,104.5-12.5,149.9-37.2c39.1-21.2,75.6-52.1,108.2-91.9c56.3-68.4,94.8-153.6,129.4-235
			c24.4-54.9,50.3-111.4,74.4-146.7c7.2-10.5,16.4-22.3,25.7-29.6v73.4v120.7l85.4-85.4l218.6-218.6l35-35l-34.6-35.4L957.3,173
			L871.5,85.5L871.5,85.5z"/>
        </g>

        <defs>
            <linearGradient id="animate-gradient-non-symmetry-{{id}}" x1="0%" y1="0%" x2="100%" y2="0" spreadMethod="reflect">
                <stop offset="0" [attr.stop-color]="colorGreen"></stop>
                <stop offset="0.33" [attr.stop-color]="colorGreen"></stop>
                <stop offset="0.66" [attr.stop-color]="colorBackground"></stop>
                <stop offset="1" [attr.stop-color]="colorBackground"></stop>
                <animate attributeName="x1" values="0%;200%" dur="4s" repeatCount="indefinite"></animate>
                <animate attributeName="x2" values="100%;300%" dur="4s" repeatCount="indefinite"></animate>
            </linearGradient>

            <linearGradient id="animate-gradient-non-symmetry-inverse-{{id}}" x1="0%" y1="0%" gradientTransform="rotate(-180)" x2="100%" y2="0" spreadMethod="reflect">
                <stop offset="0" [attr.stop-color]="colorBackground"></stop>
                <stop offset="0.33" [attr.stop-color]="colorBackground"></stop>
                <stop offset="0.66" [attr.stop-color]="colorGreen"></stop>
                <stop offset="1" [attr.stop-color]="colorGreen"></stop>
                <animate attributeName="x1" values="0%;200%" dur="4s" repeatCount="indefinite"></animate>
                <animate attributeName="x2" values="100%;300%" dur="4s" repeatCount="indefinite"></animate>
            </linearGradient>

        </defs>
    </svg>
</div>
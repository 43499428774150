//From ParamsDoc.xslt
export enum FlagsGroup { TH = 0, ES, SB, HRV, NTD, INT, WND, DHW, ENR, ALR, SEA, GEN, PMP, TNK, FS, FT, FC, SM, BL, CH, HP, FWA, ExhaustAirExtraction}

						//	3     0   1    2    0    1    0    0    3    3    4    0    4    3    0   0   0   0   0   0   3

export enum FlagsTH  { DISABLED = 0, OFF, H, C, DIS }
export enum FlagsES  { DISABLED = 0, OFF, ON }
export enum FlagsSB  { DISABLED = 0, OFF, ON}
export enum FlagsHRV { DISABLED = 0, OFF, ON}
export enum FlagsNTD { DISABLED = 0, OFF, ON, HUM} //DIS, OFF, NTD, HUM
//Heating - Cooling - FreeCooling
export enum FlagsINT { DISABLED = 0, OFF, H, C, FC}
export enum FlagsWND { DISABLED = 0, OFF, ON}
export enum FlagsDHW { DISABLED = 0, OFF, SB, ON}
export enum FlagsENR { DISABLED = 0, OFF, H, C}
//First Level, Second Level
export enum FlagsALR { DISABLED = 0, OFF, ONE, SND}
//Heating/Cooling, H/C on Schedule, H/C auto-adaptative mode, Season Switch, H/C Auto
export enum FlagsSEA { DISABLED = 0, OFF, H, C, HS, CS, HAAM, CAAM, SS, HA, CA }
export enum FlagsGEN { DISABLED = 0, OFF}
//On Recircular, On Heating, On Cooling, On Generic
export enum FlagsPMP { DISABLED = 0, OFF, RC, H, C, ON}
export enum FlagsTNK { DISABLED = 0, OFF, H, C, H_EH}
export enum FlagsFS  { DISABLED = 0, OFF, ON}
//On Heating, On Cooling
export enum FlagsFT  { DISABLED = 0, OFF, H, C, DIS}
//On Heating, On Cooling, On Convective Heating, On Convective Cooling
export enum FlagsFC  { DISABLED = 0, OFF, H, C, VOFF, VON}
export enum FlagsSM  { DISABLED = 0, OFF, ON}

export enum FlagsBL  { DISABLED = 0, OFF, ON}
export enum FlagsCH  { DISABLED = 0, OFF, ON}
export enum FlagsHP  { DISABLED = 0, OFF, H, C}
export enum FlagsFWA  { DISABLED = 0, OFF, H }
export enum FlagsExhaustAirExtraction { DISABLED = 0, OFF, ON}
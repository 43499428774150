export const AUTH_TRANSLATIONS_EN = {
  "AUTH_ERROR_TITLE":"Authentication error.",
  "INVALID_CREDENTIALS":"Invalid credentials.",
  "MISSING_CREDENTIALS":"Missing credentials.",
  "AUTHENTICATION_FAILED":"Incorrect username or password.",
  "NO_USER_FOUND":"No user found with the given username/e-mail address.",
  "INVALID_OR_EXPIRED":"Invalid or expired code",
  "INVALID_EMAIL_ADDRESS_FORMAT":"Invalid e-mail address format",
  "VALIDATION_SUCCESSFUL":"Your code has been successfully validated",
  "MASTER_STATUS_VALIDATED_REGISTERED_SUCCESS":"Your registration as a master user is complete.",
  "MASTER_STATUS_VALIDATED_REGISTERED_FAILURE":"We encountered an issue and couldn't complete your master status registration.",
  "CHECK_SYNCH_MASTER_EXCEPTION":"Oops! There was an issue while validating your master status.",
  "EMAIL_UNDEF_ON_CODE_VALIDATION":"Email could not be sent, your e-mail address is undefined, please visit https://messana.tech/open-a-support-case/",
  "EMAIL_NOT_SENT":"Generic error: email could not be sent.",
  "GENERAL_FAILURE":"Oops! Something went wrong, please visit https://messana.tech/open-a-support-case/ to report the issue.",
  "TRY_LATER":"Too many failed attempts. Try again in 5 minutes.",
  "TOO_MANY_ATTEMPTS":"Too many failed attempts.",
  "REMAINING_SECONDS":"remaining seconds",
  "EXCEPTION_ON_VALIDATION":"An exception occurred during the code validation process.",

}

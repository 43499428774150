import {Component, Input, OnInit} from '@angular/core';
import {ENR_Extended} from "../../models/tables-extended";
import {DHW, MCU, TNK} from "../../models/tables";
import {Attributes} from "../../models/attributes";
import {AbstractConfigPage} from "../../pages/abstract-config-page";
import {Constants} from "../../commons/const";

export enum HpControlModeTypes {

    tnk = 'tnk',
    dhw = 'dhw'
}

class HpControlModeVariables {

    public PAR_TargetTempMode: string;
    public hpControlModeTitleLabel: string;
    public hpFixedTargetTempLabel: string;
    public hpFollowLabel: string;
    public TEC_TargetTempH: string;
    public TEC_TargetTempC: string;
    public hpTargetTempHLabel: string;
    public hpTargetTempCLabel: string;
    public diffTable: string;
    public diffItem: TNK | DHW;
    public TEC_DiffTankH: string;
    public TEC_DiffTankC: string;
    public diffCompHLabel: string;
    public diffCompHSublabel: string;
    public diffCompCLabel: string;

    constructor(PAR_TargetTempMode, hpControlModeTitleLabel, hpFixedTargetTempLabel, hpFollowLabel, TEC_TargetTempH, TEC_TargetTempC, hpTargetTempHLabel, hpTargetTempCLabel, diffTable, diffItem, TEC_DiffTankH, TEC_DiffTankC, diffCompHLabel, diffCompHSublabel, diffCompCLabel) {

        this.PAR_TargetTempMode = PAR_TargetTempMode;
        this.hpControlModeTitleLabel = hpControlModeTitleLabel;
        this.hpFixedTargetTempLabel = hpFixedTargetTempLabel;
        this.hpFollowLabel = hpFollowLabel;
        this.TEC_TargetTempH = TEC_TargetTempH;
        this.TEC_TargetTempC = TEC_TargetTempC;
        this.hpTargetTempHLabel = hpTargetTempHLabel;
        this.hpTargetTempCLabel = hpTargetTempCLabel;
        this.diffTable = diffTable;
        this.diffItem = diffItem;
        this.TEC_DiffTankH = TEC_DiffTankH;
        this.TEC_DiffTankC = TEC_DiffTankC;
        this.diffCompHLabel = diffCompHLabel;
        this.diffCompHSublabel = diffCompHSublabel;
        this.diffCompCLabel = diffCompCLabel;

    }
}

@Component({
    selector: 'hp-control-mode',
    templateUrl: './hp-control-mode.component.html',
    styleUrls: ['./hp-control-mode.component.scss'],
})

export class HpControlModeComponent implements OnInit {

    @Input()
    enr: ENR_Extended;

    @Input()
    MCU: MCU;

    @Input()
    attrs: Attributes;

    @Input()
    context: AbstractConfigPage;

    @Input()
    controlModeType: HpControlModeTypes;

    @Input()
    showWarningSetpointBelowDHW: boolean;

    @Input()
    showWarningSetpointBelowTNK: boolean;

    public hpControlModeVariables: HpControlModeVariables;

    public showCollapse = false;

    constructor() {


    }

    ngOnInit() {

        switch (this.controlModeType) {

            case HpControlModeTypes.tnk:

                this.hpControlModeVariables = new HpControlModeVariables(
                    'PAR_TargetTempMode',
                    'HEAT_PUMP_CONTROL_MODE_H_C',
                    'HP_FIXED_TARGET_TEMP',
                    'HP_FOLLOW_BUFFER_TANK',
                    'TEC_TargetTempH',
                    'TEC_TargetTempC',
                    'HEAT_PUMPS_TARGET_TEMP_HEATING',
                    'HEAT_PUMPS_TARGET_TEMP_COOLING',
                    'TNK',
                    this.enr.tnkList[0],
                    'TEC_DiffTankH',
                    'TEC_DiffTankC',
                    'DIFF_COMP_H',
                    null,
                    'DIFF_COMP_C');
                break;

            case HpControlModeTypes.dhw:

                this.hpControlModeVariables = new HpControlModeVariables(
                    'PAR_TargetTempModeDHW',
                    'HEAT_PUMP_CONTROL_MODE_DHW',
                    'HP_FIXED_TARGET_TEMP',
                    'HP_FOLLOW_DHW',
                    'TEC_TargetTempDHW',
                    null,
                    'SET_POINT',
                    null,
                    'DHW',
                    this.enr.dhwList[0],
                    'TEC_DiffDHW',
                    null,
                    'DIFF_COMP',
                    'HP_DIFF_COMP_DHW_SUBLABEL',
                    null);
                break;
        }
    }
}

import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from "@angular/common";
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';



import {PopOverComponent} from './pop-over.component';


@NgModule({
	imports: [CommonModule,TranslateModule,FormsModule],
	declarations:[PopOverComponent],
	exports:[PopOverComponent],
	schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class PopOverModule{}
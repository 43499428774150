import {ActionReducer, Action} from '@ngrx/store';
import {SocketActions, SocketActionTypes} from '../actions/socket.actions';
import {Socket} from '../models/socket';
import * as _ from 'lodash';

const initialState: Socket = new Socket();

/**
 * Socket reducer
 * (state management)
 *
 * Reducers are the foundation of any Store or Redux-based application, describing
 * sections of state and their potential transformations based on dispatched
 * action types. It is the combination of your reducers that makes up a
 * representation of application state at any given time.
 *
 * A reducer is a pure function, accepting two arguments, the previous state
 * and an action with a type and optional data (payload) associated with the
 * event.
 */
export function socketReducer(state: Socket = initialState, action: SocketActions): Socket {

    var newState: Socket;

    switch (action.type) {

        case SocketActionTypes.Connected:

            return {...state, connected: true};

        case SocketActionTypes.Disconnected:

            return {...state, connected: false};

        case SocketActionTypes.TableLoaded:
            return {...state, loadedTables: [...new Set([...state.loadedTables, action.table])]};

        default:
            return state;
    }
}

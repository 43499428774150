import {Component, Input, OnInit} from '@angular/core';
import {MCZ_Extended, RMS_Extended, TMR_Extended} from "../../models/tables-extended";
import {interval, Subscription} from "rxjs";
import {TMR} from "../../models/tables";
import {TranslateService} from "@ngx-translate/core";
import {DUAL_MODE} from "../../models/config";

@Component({
    selector: 'circle-countdown',
    templateUrl: './circle-countdown.component.html',
    styleUrls: ['./circle-countdown.component.scss'],
})

export class CircleCountdownComponent implements OnInit {

    @Input()
    isOverrideSetpoint: boolean;

    @Input()
    rmsOrMczExtended: RMS_Extended | MCZ_Extended; // used if: isOverrideSetpoint

    @Input()
    Spo_Period: string; // used if: isOverrideSetpoint

    @Input()
    isOverrideTimer: boolean;

    @Input()
    tmr_extended: TMR_Extended; // used if: isOverrideTimer

    @Input()
    overTime: number; // used if: isOverrideTimer

    @Input()
    disabledOverride: boolean; // used if: isOverrideTimer

    @Input()
    isTimerIcon: boolean;

    arc: string; // used if: isTimerIcon
    ray: string; // used if: isTimerIcon

    overrideSetpointDateText: string; // used if: isOverrideSetpoint

    percentConsumedSub: Subscription;

    @Input()
    dualSetpointMode: DUAL_MODE; // 0: heating override (dual setpoint), 1: cooling override (dual setpoint), null / undefined: normal override without dual setpoint.

    constructor(private translate:TranslateService) {
    }

    ngOnInit() {

        const updateData = () => {

            let arc;
            let ray;

            if (this.isOverrideSetpoint) {

                const setpointOverrideInfo = this.rmsOrMczExtended.getSetpointOverrideInfo(this.rmsOrMczExtended.getCorrectOverridePropertyValues(this.dualSetpointMode).PAR_SpoPeriod, this.dualSetpointMode);

                arc = setpointOverrideInfo.arc;
                ray = setpointOverrideInfo.ray;
                this.overrideSetpointDateText = this.formatOverrideSetpointDateText(setpointOverrideInfo.currentOverrideDateTime);
            }

            else if (this.isOverrideTimer) {

                arc = this.getTimerConsumed(false);
                ray = this.getTimerConsumed(true);
            }

            else if (this.isTimerIcon) {

                arc = this.getTimerConsumed(false);
            }

            this.arc = this.describeArc(12, 12, 10, 0, arc);
            this.ray = `${ray} 31.4`;

            if (!arc && this.isTimerIcon) {

                this.arc = undefined;
            }
        }

        this.percentConsumedSub = interval(100).subscribe(() => {

            updateData();
        });

        updateData();
    }

    ngOnDestroy() {

        if (this.percentConsumedSub) {

            this.percentConsumedSub.unsubscribe();
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- SET POINT OVERRIDE ------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    formatOverrideSetpointDateText(overrideDateTime) {

        const D = this.translate.instant('DAY_UNIT').toUpperCase();
        const H = this.translate.instant('HOUR_UNIT').toUpperCase();
        const m = this.translate.instant('MINUTE_UNIT');
        const s = this.translate.instant('SECOND_UNIT');

        if (overrideDateTime.days > 0) {

            // Se sono 2 giorni e 2 ore, voglio leggere 2D / 2H. Ma se sono 1 giorno e 15min, voglio leggere 1D / 1H (quindi se hours è 0 voglio comunque leggere 1)
            const hours = overrideDateTime.hours > 0 ? overrideDateTime.hours : 1;

            return `${overrideDateTime.days}${D} / ${hours}${H}`;
        }

        else if (overrideDateTime.hours > 0) {

            // const hours = overrideDateTime.hours >= 10 ? overrideDateTime.hours : '0'+overrideDateTime.hours;
            return `${overrideDateTime.hours}${H.toLowerCase()} : ${overrideDateTime.minutes}${m}`;
        }

        else if (overrideDateTime.minutes > 0) {

            return `${overrideDateTime.minutes}${m}`;
        }

        else {

            return `${overrideDateTime.seconds}${s}`;
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- TIMER OVERRIDE / TIMER ICON ---------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    getTimerConsumed(isRay) {

        if (this.isTimerIcon && this.tmr_extended.PAR_ForcePeriod === 0) {

            return null;
        }

        //coloro i cerchi
        if (this.tmr_extended.PAR_ForcePeriod === this.overTime || this.isTimerIcon) {

            const now = Math.floor(Date.now() / 1000);
            const timePassedFromStart = (now - this.tmr_extended.PAR_ForceStart);

            if (timePassedFromStart > 15) {

                const consumed = this.tmr_extended.PAR_ForcePeriod - this.tmr_extended.RTU_ForceRemain;

                //calcolo la percentuale di consumato
                const percConsumed = consumed * 100 / this.tmr_extended.PAR_ForcePeriod;

                if (isRay) {

                    return percConsumed * 31.4 / 100;
                } else {

                    return 360 / 100 * percConsumed;
                }
            }

            else {

                return 0;
            }
        }

        else {

            if (isRay) {

                return '31.4';

            } else {

                return 359;
            }
        }
    }

    // -----------------------------------------------------------------------------------------------------------------

    activeButton(tmr_extended: TMR, time: number) {

        return tmr_extended.PAR_ForcePeriod == time;
    }

    // -----------------------------------------------------------------------------------------------------------------

    getStrokeZone(rmsIsDisabled) {

        if (rmsIsDisabled) {

            return '#DDD';

        } else {

            return '#50D787';
        }
    }

    // -----------------------------------------------------------------------------------------------------------------

    getStrokeTimer(tmr_extended: TMR, time: number): string {

        if (tmr_extended.PAR_ForcePeriod == time) {

            return '#50D787';

        } else {

            return '#ddd';
        }
    }

    // -----------------------------------------------------------------------------------------------------------------

    drawFillZone(rmsIsDisabled) {

        if (rmsIsDisabled) {

            return '#DDDDDD22';

        }

        // macro-zone
        else if (this.rmsOrMczExtended.isMcz) {

            return '#E5FBEE';
        }

        // zone
        else {

            return '#E5FBEEAA';
        }
    }

    // -----------------------------------------------------------------------------------------------------------------

    drawFillTimer(tmr_extended: TMR, time: number): string {

        if (tmr_extended.PAR_ForcePeriod == time) {

            return '#E5FBEE';

        } else {

            return '#fff';
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- TIMER ICON --------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    strokeTimerState(tmr_extended: TMR_Extended) {

        if (!tmr_extended.timeHolder) {

            tmr_extended.timeHolder = Math.floor(Date.now() / 1000);
            tmr_extended.inRangeState = true;
        }

        const now = Math.floor(Date.now() / 1000);

        //Off
        if (tmr_extended.PAR_Mode == 0 && tmr_extended.PAR_ForcePeriod == 0) {

            return '#ddd';
        }
        if (tmr_extended.PAR_Mode == 2) {

            return tmr_extended.liteOrange;
        }

        if (tmr_extended.PAR_ForcePeriod != 0) {

            return tmr_extended.intenseOrange;
        }

        if (tmr_extended.PAR_Mode == 1 && tmr_extended.PAR_ForcePeriod == 0) {

            //sch inRange
            if (tmr_extended.PAR_IdxSCH != -1 && tmr_extended.schedule) {

                if (tmr_extended.schedule.RTU_On) {

                    if (now - tmr_extended.timeHolder > 1) {

                        tmr_extended.inRangeState = !tmr_extended.inRangeState;
                        tmr_extended.timeHolder = Math.floor(Date.now() / 1000);
                    }

                    if (tmr_extended.inRangeState) {

                        return tmr_extended.liteOrange;
                    }

                    else {

                        return tmr_extended.intenseOrange;
                    }
                }

                //sch outOfRange
                if (!tmr_extended.schedule.RTU_On) {

                    return '#ddd';
                }
            }

            else {

                return '#ddd';
            }
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- SUPPORT -----------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    polarToCartesian(centerX, centerY, radius, angleInDegrees) {

        const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

        return {
            x: centerX + (radius * Math.cos(angleInRadians)),
            y: centerY + (radius * Math.sin(angleInRadians))
        };
    }

    // -----------------------------------------------------------------------------------------------------------------

    describeArc(x, y, radius, startAngle, endAngle) {

        const start = this.polarToCartesian(x, y, radius, endAngle);
        const end = this.polarToCartesian(x, y, radius, startAngle);

        const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

        return [
            "M", start.x, start.y,
            "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
        ].join(" ");
    }

    rmsOrMczIsTurnedOff() {

        if (this.rmsOrMczExtended instanceof RMS_Extended) {


            return this.rmsOrMczExtended?.PAR_State === 0;
        }

        else if (this.rmsOrMczExtended instanceof MCZ_Extended) {


            return this.rmsOrMczExtended?.PAR_Mode === 0;
        }
    }
}

<div class="new-schedule">

    <ion-title [ngClass]="{'from':!fromMode,'to':fromMode}">
        {{ (mode == Mode.Edit ? 'EDIT_SCHEDULE' : 'NEW_SCHEDULE') | translate }}
    </ion-title>


</div>


<ion-content class="new-schedule positionRelative">


    <input type="checkbox" id="switch" (click)="fromToClick()" [(ngModel)]="fromMode" [ngModelOptions]="{standalone: true}" ngDefaultControl>


    <div class="app">
        <div class="wrapper d-flex flex-column justify-content-start align-items-center" [ngClass]="{'from':!fromMode,'to':fromMode}">
            <!-- Middle -->

            <!-- .var css class doesn't actually exist, so it's not used -->
            <div [ngClass]="{'var':sch?.CFG_Type == SchedulationType.Variable}" class="content">

                <label for="switch">
                    <div class="toggle"></div>
                    <div class="names">
                        <div class="light"><i class="icons fad fa-lightbulb-on" style="--fa-primary-color: #ffcc00; --fa-primary-opacity: 0.40; --fa-secondary-color: #ffcc00; --fa-secondary-opacity: 0.80;"></i>From</div>
                        <div class="dark"><i class="icons fad fa-lightbulb" style="--fa-primary-opacity: 0.20; --fa-secondary-opacity: 0.30;"></i>To</div>
                    </div>
                </label>

                <div class="time-picker"></div>
            </div>
            <counter class="w-100" *ngIf="sch?.CFG_Type == SchedulationType.Variable" temperature [step]="step" [attrs]="attrs" table="MCU" [item]="MCU" [value]="temperature" [min]="min" [max]="max" [whileChange]="onChangeTemperature" [schedule]="1"></counter>
            <counter class="w-100" *ngIf="sch?.CFG_Type == SchedulationType.DualVariable" label="TEMPERATURE_H" temperature [step]="step" [attrs]="attrs" table="MCU" [item]="MCU" [value]="temperatureH" [min]="min" [max]="max" [whileChange]="onChangeTemperatureH" [schedule]="1"></counter>
            <counter class="w-100" *ngIf="sch?.CFG_Type == SchedulationType.DualVariable" label="TEMPERATURE_C" temperature [step]="step" [attrs]="attrs" table="MCU" [item]="MCU" [value]="temperatureC" [min]="min" [max]="max" [whileChange]="onChangeTemperatureC" [schedule]="1"></counter>

            <div class="container" [ngClass]="{'am': fromAmPm == 'am' && !fromMode || toAmPm == 'am' && fromMode, 'pm': fromAmPm == 'pm' && !fromMode || toAmPm == 'pm' && fromMode }">
                <div class="timepicker-container-outer" [fromMode]="fromMode" [fromAmPm]="fromAmPm" [toAmPm]="toAmPm" [from]="initTime(from)" [to]="initTime(to)" [selectedtimeFrom]="timeFrom" [userClick]="userClick" [selectedtimeTo]="timeTo" timetravel (outtime)="updateTime($event)"
                     (outampm)="updateAmPm()">
                    <div class="timepicker-container-inner">
                        <div class="timeline-container">
                            <div class="current-time">
                                <div *ngIf="!fromMode" class="actual-time">{{ timeFrom }}</div>
                                <div *ngIf="fromMode" class="actual-time">{{ timeTo }}</div>
                            </div>
                            <div class="timeline">
                            </div>
                            <div class="hours-container">
                                <div class="hour-mark" *ngFor="let hour of getHours(); trackBy: trackByFn;"></div>
                            </div>
                        </div>
                        <div *ngIf="!fromMode" class="display-time">{{ timeFrom }} </div><!--{{ fromAmPm }}-->
                        <div *ngIf="fromMode" class="display-time">{{ timeTo }} </div><!-- {{ toAmPm }} -->
                        <div class="am-pm-container">
                            <div class="am-pm-button" (click)="changetime('am')">am</div>
                            <div class="am-pm-button" (click)="changetime('pm')">pm</div>
                        </div>
                    </div>
                </div>
            </div><!-- container time-picker -->

            <div class="day-buttons-new d-flex flex-row justify-content-center align-items-center">
                <button class="day-button" [ngClass]="{'active':days[0]}" [disabled]="disableDays" (click)="toggleDay(0)">{{ labels[0] }}</button>
                <button class="day-button" [ngClass]="{'active':days[1]}" item-right [disabled]="disableDays" (click)="toggleDay(1)">{{ labels[1] }}</button>
                <button class="day-button" [ngClass]="{'active':days[2]}" item-right [disabled]="disableDays" (click)="toggleDay(2)">{{ labels[2] }}</button>
                <button class="day-button" [ngClass]="{'active':days[3]}" item-right [disabled]="disableDays" (click)="toggleDay(3)">{{ labels[3] }}</button>
                <button class="day-button" [ngClass]="{'active':days[4]}" item-right [disabled]="disableDays" (click)="toggleDay(4)">{{ labels[4] }}</button>
                <button class="day-button" [ngClass]="{'active':days[5]}" item-right [disabled]="disableDays" (click)="toggleDay(5)">{{ labels[5] }}</button>
                <button class="day-button" [ngClass]="{'active':days[6]}" item-right [disabled]="disableDays" (click)="toggleDay(6)">{{ labels[6] }}</button>

            </div>

            <div class="day-buttons-new d-flex flex-row justify-content-center align-items-center">
                <button class="day-all" [ngClass]="{'active':allWorkDays}" [disabled]="disableDays" (click)="toggleDay(-2)">{{ 'WORK' | translate }}</button>
                <button class="day-all" [ngClass]="{'active':allDays}" [disabled]="disableDays" (click)="toggleDay(-1)">{{ 'ALL_DAYS' | translate }}</button>
            </div>
            <!-- Bottom -->
            <!--<div class="cancel">
              <p>Cancel</p>
              <div class="continue">
                <div class="save"><i class="far fa-pencil"></i></div>
              </div>
            </div>-->

            <!-- all schedule types, except Dual Variables and Season -->
            <div class="alert-button-group w-100 d-flex flex-row justify-content-between align-items-center" *ngIf="sch?.CFG_Type !== SchedulationType.Season && sch?.CFG_Type !== SchedulationType.DualVariable">

                <button category="alert-button squared-messana-button scheduleButton" (click)="cancel()">
                    <i aria-hidden="true" class="fa fa-times"></i>
                </button>

                <button category="alert-button squared-messana-button scheduleButton" (click)="ok()" [disabled]="!valid">
                    <i aria-hidden="true" class="fa fa-check"></i>
                </button>
            </div>

            <!-- DualVariable -->
            <div class="alert-button-group w-100 d-flex flex-row justify-content-between align-items-center" *ngIf="sch?.CFG_Type === SchedulationType.DualVariable">

                <div>
                    <button (click)="cancel()">
                        <i aria-hidden="true" class="fa fa-times"></i>
                    </button>
                </div>

                <div>
                    <button (click)="ok(null, null, DUAL_MODE.heating)" [disabled]="!valid">
                        <i class="fas fa-circle messana-orange" aria-hidden="true"></i>
                    </button>
                </div>

                <div>
                    <button (click)="ok(null, null, DUAL_MODE.cooling)" [disabled]="!valid">
                        <i class="fas fa-circle messana-blue" aria-hidden="true"></i>
                    </button>
                </div>

            </div>

            <!-- Season -->
            <div class="alert-button-group d-flex flex-row justify-content-between align-items-center w-100" *ngIf="sch?.CFG_Type === SchedulationType.Season">
                <div>
                    <button category="alert-button" class="season-button squared-messana-button scheduleButton" (click)="cancel()">
                        <i aria-hidden="true" class="fa fa-times"></i>
                    </button>
                </div>
                <div>
                    <button category="alert-button" class="season-button squared-messana-button scheduleButton" (click)="ok(0)" [disabled]="!valid">
                        <i class="fas fa-circle messana-blue" aria-hidden="true"></i>
                    </button>
                </div>
                <div>
                    <button category="alert-button" class="season-button squared-messana-button scheduleButton" (click)="ok(1)" [disabled]="!valid">
                        <i class="fas fa-circle messana-orange" aria-hidden="true"></i>
                    </button>
                </div>
            </div>

        </div><!-- chiuso flex-column-->
    </div><!-- chisua la classse app -->


</ion-content>
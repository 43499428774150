<div class="d-flex dynamic-flex justify-content-center align-items-center">

    <!-- Overrides (normal) -->
    <show-zone-override *ngIf="!isAutoModeStyleDualSetpoint() && rms_extended.getSetpointOverrideInfo().isOverride" [rmsOrMczExtended]="rms_extended" [page]="page" [dualSetpointMode]="null" [state]="state"></show-zone-override>

    <!-- Overrides (heating of dual setpoint) -->
    <show-zone-override *ngIf="isAutoModeStyleDualSetpoint() && rms_extended.getSetpointOverrideInfo(null, DUAL_MODE.heating).isOverride" [rmsOrMczExtended]="rms_extended" [page]="page" [dualSetpointMode]="DUAL_MODE.heating" [state]="state"></show-zone-override>

    <!-- Non overrides -->
    <counter class="setPointCounter" *ngIf="((season === 0 && !isAutoModeStyleDualSetpoint() && !rms_extended.getSetpointOverrideInfo().isOverride) || (isAutoModeStyleDualSetpoint() && !rms_extended.autoModeRmsModeNotPresent(0) && !rms_extended.getSetpointOverrideInfo(null, DUAL_MODE.heating).isOverride)) && !rms.PAR_SchedOnH"
             [dualSetPoint]="isAutoModeStyleDualSetpoint()"
             [receiveDebounce]="0"
             [staticSeason]="0"
             [context]="rms_extended"
             [min]="computeMin(0,mcz)"
             [max]="computeMax(0,mcz)" [large]="true" temperature table="RMS"
             [item]="rms" var="PAR_SetTempH" [inDisabled]="!state" [changableSetpoint]="true"
             [attrs]="attrs" [MCU]="MCU"
             [whileChange]="onChangeDualSetpoints" itemClass="no-lines"></counter>

    <counter class="setPointCounter" *ngIf="((season === 1 && !isAutoModeStyleDualSetpoint() && !rms_extended.getSetpointOverrideInfo().isOverride) || (isAutoModeStyleDualSetpoint() && !rms_extended.autoModeRmsModeNotPresent(1) && !rms_extended.getSetpointOverrideInfo(null, DUAL_MODE.cooling).isOverride)) && !rms.PAR_SchedOnC"
             [dualSetPoint]="isAutoModeStyleDualSetpoint()"
             [receiveDebounce]="0"
             [staticSeason]="1"
             [context]="rms_extended"
             [min]="computeMin(1,mcz)" [changableSetpoint]="true"
             [max]="computeMax(1,mcz)" [large]="true" temperature table="RMS"
             [item]="rms" var="PAR_SetTempC" [inDisabled]="!state" [attrs]="attrs" [MCU]="MCU"
             [whileChange]="onChangeDualSetpoints" itemClass="no-lines"></counter>

    <counter class="setPointCounter" *ngIf="((season === 0 && !isAutoModeStyleDualSetpoint() && !rms_extended.getSetpointOverrideInfo().isOverride) || (isAutoModeStyleDualSetpoint() && !rms_extended.autoModeRmsModeNotPresent(0) && !rms_extended.getSetpointOverrideInfo(null, DUAL_MODE.heating).isOverride)) && rms.PAR_SchedOnH && sch?.CFG_Type !== SchedulationType.Variable && sch?.CFG_Type !== SchedulationType.DualVariable"
             [dualSetPoint]="isAutoModeStyleDualSetpoint()"
             [receiveDebounce]="0"
             [staticSeason]="0"
             [context]="rms_extended"
             [min]="computeMin(0,mcz)"
             [max]="computeMax(0,mcz)" [large]="true" temperature table="RMS"
             [item]="rms" var="PAR_SetTempH" [inDisabled]="!state" [changableSetpoint]="true"
             [attrs]="attrs" [MCU]="MCU"
             [whileChange]="onChangeDualSetpoints" itemClass="no-lines"></counter>

    <counter class="setPointCounter" *ngIf="((season === 1 && !isAutoModeStyleDualSetpoint() && !rms_extended.getSetpointOverrideInfo().isOverride) || (isAutoModeStyleDualSetpoint() && !rms_extended.autoModeRmsModeNotPresent(1) && !rms_extended.getSetpointOverrideInfo(null, DUAL_MODE.cooling).isOverride)) && rms.PAR_SchedOnC && sch?.CFG_Type !== SchedulationType.Variable && sch?.CFG_Type !== SchedulationType.DualVariable"
             [dualSetPoint]="isAutoModeStyleDualSetpoint()"
             [receiveDebounce]="0"
             [staticSeason]="1"
             [context]="rms_extended"
             [min]="computeMin(1,mcz)"
             [max]="computeMax(1,mcz)" [large]="true" temperature table="RMS"
             [item]="rms" var="PAR_SetTempC" [inDisabled]="!state" [changableSetpoint]="true"
             [attrs]="attrs" [MCU]="MCU"
             [whileChange]="onChangeDualSetpoints" itemClass="no-lines"></counter>

    <!-- Variable schedule (Variable schedule can not be present if isAutoModeStyleDualSetpoint) -->
    <ng-container *ngIf="sch?.CFG_Type === SchedulationType.Variable && !isAutoModeStyleDualSetpoint()">

        <counter class="setPointCounter" *ngIf="season === 0 && rms.PAR_SchedOnH && !rms_extended.getSetpointOverrideInfo().isOverride"
                 [dualSetPoint]="false"
                 [receiveDebounce]="0"
                 [staticSeason]="0"
                 [context]="rms_extended"
                 [large]="true" temperature var="RTU_SetPoint"
                 [rtu]="true" [changableSetpoint]="true" [inDisabled]="!state"
                 [staticValue]="sch?.RTU_SetPoint" [MCU]="MCU" [whileChange]="onChangeDualSetpoints"
                 itemClass="no-lines scheduled"></counter>

        <counter class="setPointCounter" *ngIf="season === 1 && rms.PAR_SchedOnC && !rms_extended.getSetpointOverrideInfo().isOverride"
                 [dualSetPoint]="false"
                 [receiveDebounce]="0"
                 [staticSeason]="0"
                 [context]="rms_extended"
                 [large]="true" temperature var="RTU_SetPoint"
                 [rtu]="true" [changableSetpoint]="true" [inDisabled]="!state"
                 [staticValue]="sch?.RTU_SetPoint" [MCU]="MCU" [whileChange]="onChangeDualSetpoints"
                 itemClass="no-lines scheduled"></counter>
    </ng-container>

    <!-- Dual Variable schedule (Dual Variable schedule can only be present when isAutoModeStyleDualSetpoint) -->
    <ng-container *ngIf="sch?.CFG_Type === SchedulationType.DualVariable && isAutoModeStyleDualSetpoint()">

        <counter class="setPointCounter" *ngIf="rms.PAR_SchedOnH && !rms_extended.autoModeRmsModeNotPresent(0) && !rms_extended.getSetpointOverrideInfo(null, DUAL_MODE.heating).isOverride"
                 rtuClassColor="messana-orange"
                 [dualSetPoint]="true"
                 [receiveDebounce]="0"
                 [staticSeason]="0"
                 [context]="rms_extended"
                 [large]="true" temperature var="RTU_SetPointH"
                 [rtu]="true" [changableSetpoint]="true" [inDisabled]="!state"
                 [staticValue]="sch?.RTU_SetPointH" [MCU]="MCU" [whileChange]="onChangeDualSetpoints"
                 itemClass="no-lines scheduled"></counter>

        <counter class="setPointCounter" *ngIf="rms.PAR_SchedOnC && !rms_extended.autoModeRmsModeNotPresent(1) && !rms_extended.getSetpointOverrideInfo(null, DUAL_MODE.cooling).isOverride"
                 rtuClassColor="messana-blue"
                 [dualSetPoint]="true"
                 [receiveDebounce]="0"
                 [staticSeason]="0"
                 [context]="rms_extended"
                 [large]="true" temperature var="RTU_SetPointC"
                 [rtu]="true" [changableSetpoint]="true" [inDisabled]="!state"
                 [staticValue]="sch?.RTU_SetPointC" [MCU]="MCU" [whileChange]="onChangeDualSetpoints"
                 itemClass="no-lines scheduled"></counter>
    </ng-container>

    <!-- Overrides (cooling of dual setpoint) -->
    <show-zone-override *ngIf="isAutoModeStyleDualSetpoint() && rms_extended.getSetpointOverrideInfo(null, DUAL_MODE.cooling).isOverride" [rmsOrMczExtended]="rms_extended" [page]="page" [dualSetpointMode]="DUAL_MODE.cooling" [state]="state"></show-zone-override>

</div>
import {
    Component,
    Input,
    OnInit,
    OnDestroy,
    OnChanges,
    AfterContentInit
} from '@angular/core';
import {Store} from '@ngrx/store';
import * as _ from 'lodash';

import {AppState} from '../../services/app-state.service';
import {SCH} from '../../models/tables';
import {BitArray} from '../../commons/bitarray';
import {Config, DUAL_MODE, SchedulationType} from '../../models/config';
import {Platform} from '@ionic/angular';
import {NavigationEnd, Router} from '@angular/router';

//da fare
//import { AbstractSchedulationCommons, Day, Range, NO_TEMPERATURE, RangeColorCell } from '../../pages/schedule/commons';

import {TranslateService} from '@ngx-translate/core';
import * as c3 from 'c3';
import {NO_TEMPERATURE} from '../../pages/schedule/commons';
import {CurrentDateTime} from "../../models/current-date-time";
import {
    AxesOptions,
    ChartAPI,
    ChartConfiguration,
    ChartType, Data,
    GridOptions,
    Padding,
    PrimitiveArray,
    YAxisConfigurationWithTime
} from "c3";
import {interval, Subscription, timer} from "rxjs";
import {Attributes} from "../../models/attributes";

@Component({
    selector: 'schedule-graph',
    templateUrl: './schedule-graph.component.html',
    styleUrls: ['./schedule-graph.component.scss'],
})

export class ScheduleGraphComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {

    //Schedule
    @Input()
    schedule: SCH;

    @Input()
    inputClass: string;

    @Input()
    id: string;

    @Input()
    currentDateTime: CurrentDateTime;
    @Input()
    disabled: boolean; // if disabled -> the schedule will be grey

    @Input()
    isAnalogicErvEco2Graph: boolean; // if true -> it will display an "area" graph with the fan speed / eco2 data of the ATU

    @Input()
    lowFanSpeed: number; // used in the "isAnalogicErvEco2Graph"

    @Input()
    highFanSpeed: number; // used in the "isAnalogicErvEco2Graph"

    @Input()
    lowSetCo2: number; // used in the "isAnalogicErvEco2Graph"

    @Input()
    highSetCo2: number; // used in the "isAnalogicErvEco2Graph"

    // attributes
    @Input()
    attrs: Attributes; // used in the "isAnalogicErvEco2Graph"

    @Input()
    config: Config;

    private chartAM: ChartAPI;
    private chartPM: ChartAPI;

    private currentDateRegionAMPM: string;
    private currentDateRegionFrom: string;
    private currentDateRegionTo: string;
    private dayNames: string[] = [];


    protected hoursAM: string[] = [];
    protected hoursPM: string[] = [];
    protected currentDayOnOffPM: string[] = [];
    protected currentDayOnOffAM: string[] = [];
    public days: any[];


    protected currentHeatingAM: string[] = [];
    protected currentHeatingPM: string[] = [];
    protected currentCoolingAM: string[] = [];
    protected currentCoolingPM: string[] = [];

    private todayIdx: number;

    public currentDayIdx = -1;
    public currentDayLabel: string;
    public isWeb;
    private chartUIFixSubscription: Subscription;
    private svgFixedAtFirstOpen: boolean = false; // The first time I open a Chart in a few systems (ex: dhw) the chart may be cropped, and I need to resize it immediately
    public SchedulationType = SchedulationType;
    public DUAL_MODE = DUAL_MODE;
    private routerSub: Subscription;
    private mustReloadChartAfterNavigationEnd = false;

    public PAR_DualSetpointSelection: DUAL_MODE;

    constructor(
        private platform: Platform,
        public router: Router,
        protected store: Store<AppState>,
        protected translate: TranslateService) {

        this.isWeb = !this.platform.is('cordova') && !this.platform.is('capacitor');
    }

    ngOnInit() {

        this.routerSub = this.router.events.subscribe(event => {

            // Everytime I click back to a page with a schedule, or I simply enter a page with a schedule -> I reload the schedule (on ngOnChanges)
            if (event instanceof NavigationEnd) {

                this.mustReloadChartAfterNavigationEnd = true;
            }
        });
    }

    ngOnChanges(inputs): void {

        if (inputs) {

            if (this.isAnalogicErvEco2Graph) {

                let reloadChart = false;

                if (inputs.lowSetCo2 && inputs.lowSetCo2.currentValue !== inputs.lowSetCo2.previousValue) {

                    reloadChart = true;
                }

                if (inputs.highSetCo2 && inputs.highSetCo2.currentValue !== inputs.highSetCo2.previousValue) {

                    reloadChart = true;
                }

                if (inputs.lowFanSpeed && inputs.lowFanSpeed.currentValue !== inputs.lowFanSpeed.previousValue) {

                    reloadChart = true;
                }

                if (inputs.highFanSpeed && inputs.highFanSpeed.currentValue !== inputs.highFanSpeed.previousValue) {

                    reloadChart = true;
                }

                if (reloadChart) {

                    console.log('reloading Analogic ERV chart!');
                    this.createChart();
                }


            } else if (inputs.schedule && inputs.schedule.previousValue && inputs.schedule.currentValue) {

                // Updating Chart everytime I open a page with a schedule (or go back to a page with a schedule clicking back)
                if (this.mustReloadChartAfterNavigationEnd) {

                    this.mustReloadChartAfterNavigationEnd = false;
                    this.createChart();
                }

                // Reloading Chart if the schedule has changed
                if (inputs.schedule.previousValue.id !== inputs.schedule.currentValue.id) {

                    this.createChart();
                }
            }
        }

    }

    ngOnDestroy() {

        this.chartUIFixSubscription?.unsubscribe();
        this.routerSub?.unsubscribe();
    }

    modifySCH(sch: SCH) {

        // console.log(sch);
        this.router.navigate(['/schedule'], {queryParams: {id: sch.id}});
    }

    createChart() {

        // -------------------------------------------------------------------------------------------------------------
        // --- DEFINE DRAW C3 CHART (function) -------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        const drawChart = (isAM: boolean, tempArray?, min?, mid?, max?, lowerMin?, xFormat?) => {

            // ---------------------------------------------------------------------------------------------------------
            // --- AM or PM properties ---------------------------------------------------------------------------------
            // ---------------------------------------------------------------------------------------------------------

            const AM = isAM ? 'AM' : 'PM';
            const A = isAM ? 'A' : 'P'; // used to draw the 12A -> 12P label
            const P = isAM ? 'P' : 'A'; // used to draw the 12A -> 12P label

            const hours = isAM ? this.hoursAM : this.hoursPM;
            const currentDayOnOff = isAM ? this.currentDayOnOffAM : this.currentDayOnOffPM;

            // ---------------------------------------------------------------------------------------------------------
            // --- Specific schedule chart's properties ----------------------------------------------------------------
            // ---------------------------------------------------------------------------------------------------------

            const padding: Padding = {top: 10, right: 30, bottom: 10, left: 20}; // su variable c'è padding con left 50 ma non ha senso (rivedere i padding)
            let grid: GridOptions;
            let color_pattern = ['#39cb74'];

            let axis_y: YAxisConfigurationWithTime = {max: 1, min: 0, show: false};
            let types: { [key: string]: ChartType } = {[AM]: 'area-step'};
            let columns = [['x'].concat(hours), [AM].concat(currentDayOnOff)] as Array<[string, ...PrimitiveArray]>;

            if (this.schedule) {

                switch (this.schedule.CFG_Type) {

                    case SchedulationType.Variable:
                    case SchedulationType.DualVariable:

                        if (this.schedule.CFG_Type === SchedulationType.DualVariable) {

                            color_pattern = this.PAR_DualSetpointSelection === this.DUAL_MODE.cooling ? ['#3a99d9'] : ['#e47e30'];
                        }

                        grid = {y: {show: true}};
                        padding.left = 40;

                        // ---------------------------------------------------------------------------------------------
                        // If there is an empty Variable schedule -> the values will be NaN, and you'll read NaN in the schedule Y axis -> we prefer to have an empty text
                        if (tempArray.length === 0) {

                            min = '';
                            mid = '';
                            max = '';
                        }

                        axis_y = {
                            show: true, padding: {top: 0, bottom: 0}, min: lowerMin, max: max,
                            tick: {
                                values: [min, mid, max]
                            }
                        };
                        break;

                    case SchedulationType.Season:

                        const currentHeating = isAM ? this.currentHeatingAM : this.currentHeatingPM;
                        const currentCooling = isAM ? this.currentCoolingAM : this.currentCoolingPM;

                        color_pattern = ['#ff7f0e', '#aec7e8'];
                        axis_y.min = 0.5;

                        // I need to create 2 new variables (instead of using AM + 'a'), otherwise the typescript compiler of chart c3 hates me
                        const AMa = isAM ? 'AMa' : 'PMa';
                        const AMb = isAM ? 'AMb' : 'PMb';

                        types = {[AMa]: 'area-step', [AMb]: 'area-step'};
                        columns = [['x'].concat(hours), [AM + 'a'].concat(currentHeating), [AM + 'b'].concat(currentCooling)] as Array<[string, ...PrimitiveArray]>;
                        break;

                    case SchedulationType.OnOff:
                        break;
                }
            }

            // ---------------------------------------------------------------------------------------------------------
            // --- FULL CONFIGURATION ----------------------------------------------------------------------------------
            // ---------------------------------------------------------------------------------------------------------

            let bindto = '#chart' + AM + this.id;

            // ---------------------------------------------------------------------------------------------------------

            let axis: AxesOptions = {

                x: {

                    type: 'timeseries',
                    tick: {

                        format: x => {

                            x = x as Date;

                            let hour: any = x.getHours();

                            if (hour === 0) {

                                hour = `12${A}`;

                            } else if (hour === 12) {

                                hour = `12${P}`;
                            }

                            return hour;
                        },

                        culling: {
                            max: 13
                        },
                        fit: true,
                        outer: false
                    },

                    padding: {left: 0, right: 0}
                },
                y: axis_y
            };

            // ---------------------------------------------------------------------------------------------------------

            let data: Data = {

                x: 'x', xFormat: xFormat, // how the date is parsed,
                types: types,
                columns: columns
            }

            // ---------------------------------------------------------------------------------------------------------

            let showPoint = true;
            let height = 80;

            // ---------------------------------------------------------------------------------------------------------
            // --- ANALOGIC ERV ECO2 GRAPH -----------------------------------------------------------------------------
            // ---------------------------------------------------------------------------------------------------------

            if (this.isAnalogicErvEco2Graph) {

                bindto = '#chartAnalogicHRV' + this.id;
                padding.left = 40; // Since I need a lot of space to draw (for example) 85.5%, I increase the padding
                padding.right = 10; // I don't have any label on the right, so I can restrict it

                // -----------------------------------------------------------------------------------------------------

                const maxCo2Value = this.attrs['ATU']['PAR_HrvHighSetCO2']['Max'];
                const maxFanSpeedValue = this.attrs['ATU']['PAR_HrvHighSpeed']['Max'];

                // -----------------------------------------------------------------------------------------------------

                axis = {
                    x: {
                        min: 0,
                        max: maxCo2Value, // this is the highest possible in the attribute (high)
                        tick: {
                            values: [this.lowSetCo2, this.highSetCo2],  // X-axis tick values (they are the low / high eco2 values)
                            outer: false
                        }
                    },
                    y: {
                        min: 0,
                        max: maxFanSpeedValue, // this is the highest possible in the attribute (high)
                        tick: {
                            values: [this.lowFanSpeed, this.highFanSpeed],  // Y-axis tick values (they are the low / high fan speed values)
                            outer: false,
                            format: (value) => {

                                return value + '%' // Add "%" text to the fan speed value
                            }
                        },
                    }
                };

                // -----------------------------------------------------------------------------------------------------

                const columns = [
                    ['x', 0, this.lowSetCo2, this.highSetCo2, maxCo2Value],  // X-axis data points (The third one and fourth one must be replaced by low / high co2 values, the last one is the max)
                    ['y', this.lowFanSpeed, this.lowFanSpeed, this.highFanSpeed, this.highFanSpeed]  // Y-axis data points (The first 2 are the fan low speed value, the last 2 the high speed value)
                ] as Array<[string, ...PrimitiveArray]>;

                // -----------------------------------------------------------------------------------------------------

                data = {
                    x: 'x',
                    columns: columns,
                    type: 'area',
                    colors: {
                        y: color_pattern[0]  // fill color
                    },
                }

                // -----------------------------------------------------------------------------------------------------

                showPoint = false;
                height = 120;
            }

            // ---------------------------------------------------------------------------------------------------------
            // --- DRAW CHART (ChartConfiguration) ---------------------------------------------------------------------
            // ---------------------------------------------------------------------------------------------------------

            const config: ChartConfiguration = {

                bindto: bindto,
                legend: {show: false},
                tooltip: {show: false},
                size: {height: height},
                color: {pattern: color_pattern},
                padding: padding,
                grid: grid,
                point: {show: showPoint},
                axis: axis,

                line: {step: {type: "step-after"}},

                data: data,

                onresized: () => {

                    console.log('onresized');

                    if (isAM) {

                        // Both the AM and PM chart will receive "onresized" -> but since "this.createChart()" recreate BOTH -> I only recreate it (one time) when it's triggered from the AM chart (otherwise I would call this.createChart 2 times)
                        this.createChart();
                    }
                },
            }

            // ---------------------------------------------------------------------------------------------------------
            // --- CREATE CHART ----------------------------------------------------------------------------------------
            // ---------------------------------------------------------------------------------------------------------

            if (isAM) {

                if (this.chartAM) {

                    this.chartAM.destroy();
                }

                this.chartAM = c3.generate(config);

            } else {

                if (this.chartPM) {

                    this.chartPM.destroy();
                }

                this.chartPM = c3.generate(config);
            }
        }

        // -------------------------------------------------------------------------------------------------------------
        // --- Assign: PAR_DualSetpointSelection -----------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        if (this.schedule && this.PAR_DualSetpointSelection === undefined) {

            this.PAR_DualSetpointSelection = this.schedule.PAR_DualSetpointSelection;
        }

        // -------------------------------------------------------------------------------------------------------------
        // --- ANALOGIC ERV (ECO2 GRAPH) -------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        // If I want the "isAnalogicErvEco2Graph" I only need to draw 1 graph (and I don't need any other data)
        if (this.isAnalogicErvEco2Graph) {

            drawChart(true);

        } else {

            // ---------------------------------------------------------------------------------------------------------
            // --- NORMAL SCHEDULE GRAPH -------------------------------------------------------------------------------
            // ---------------------------------------------------------------------------------------------------------

            this.populateHours();

            // ---------------------------------------------------------------------------------------------------------
            // --- CONDITIONS ------------------------------------------------------------------------------------------
            // ---------------------------------------------------------------------------------------------------------

            // this.todayIdx could be 0, so don't use "if (!this.todayIdx)" because it could return (and show an empty schedule)
            if (this.todayIdx === undefined || this.todayIdx === null || !this.schedule) {

                return
            }

            // ---------------------------------------------------------------------------------------------------------
            // --- DEFINE VARIABLES ------------------------------------------------------------------------------------
            // ---------------------------------------------------------------------------------------------------------

            if (this.currentDayIdx === -1) {

                this.currentDayIdx = this.todayIdx;
            }

            this.days = [];

            const xFormat = '%H:%M';

            const tempArray = [];

            for (let day = 0; day < 7; day++) {

                // label
                let text = this.dayNames[day];

                // schedule
                let schedule = new BitArray(48).fromNumber(this.schedule.PAR_Schedule[day] || 0);

                if (this.schedule.CFG_Type === SchedulationType.DualVariable) {

                    for (let i = 0; i <= 47; i++) {

                        // Cooling mode selected in schedule-graph (if there isn't a cooling temperature, I clear that piece of schedule, because it means it was meant for heating)
                        if (this.PAR_DualSetpointSelection === DUAL_MODE.cooling) {

                            if (this.schedule.PAR_TempSchedC[day * 48 + i] === NO_TEMPERATURE || this.schedule.PAR_TempSchedC[day * 48 + i] === null && this.schedule.PAR_TempSchedC[day * 48 + i] === undefined) {

                                schedule.set(i, false);
                            }
                        }

                        // Heating mode selected in schedule-graph (DUAL_MODE.heating and DUAL_MODE.heatingAndCooling) -> when there isn't a heating temperature, I clear that piece of schedule, because it means it was meant for cooling)
                        else {

                            if (this.schedule.PAR_TempSchedH[day * 48 + i] === NO_TEMPERATURE || this.schedule.PAR_TempSchedH[day * 48 + i] === null && this.schedule.PAR_TempSchedH[day * 48 + i] === undefined) {

                                schedule.set(i, false);
                            }
                        }
                    }
                }

                // scheduled temperatures
                let temperatures = [];

                const displayTemperatures = [];

                if (this.schedule.CFG_Type === SchedulationType.Variable || this.schedule.CFG_Type === SchedulationType.DualVariable) {

                    let property = 'PAR_TempSched';

                    if (this.schedule.CFG_Type === SchedulationType.DualVariable) {

                        property = this.PAR_DualSetpointSelection === DUAL_MODE.cooling ? 'PAR_TempSchedC' : 'PAR_TempSchedH';
                    }

                    temperatures = _.slice(this.schedule[property], 48 * day, 48 * (day + 1));

                    for (let i = 0; i < temperatures.length; i++) {

                        if (temperatures[i] != NO_TEMPERATURE && !_.includes(tempArray, temperatures[i])) {

                            tempArray.push(temperatures[i]);
                        }
                    }
                }

                this.days.push({
                    index: day, text: text, schedule: schedule,
                    temperatures: temperatures, displayTemperatures: displayTemperatures
                });
            }

            let max = _.max(tempArray);
            let min = _.min(tempArray);
            let mid: any = (max + min) / 2;
            let lowerMin: any = min - ((max - min < 1) ? 1 : Math.floor((max - min) / 4));

            if (lowerMin === min) {

                lowerMin--;
            }

            // -------------------------------------------------------------------------------------------------------------

            const currentDay = this.days[this.currentDayIdx];
            this.currentDayLabel = currentDay.text;

            const currentDayAM = currentDay.schedule.toArray().slice(0, 25);
            const currentDayPM = (currentDay.schedule.toArray().slice(24, 48));

            currentDayPM.push(currentDayAM[0]);

            // -------------------------------------------------------------------------------------------------------------
            // --- DEFINE SPECIFIC VARIABLES (BASED ON SCHEDULE) -----------------------------------------------------------
            // -------------------------------------------------------------------------------------------------------------

            switch (this.schedule.CFG_Type) {

                case SchedulationType.Variable:
                case SchedulationType.DualVariable:

                    const currentDayAMTemps = currentDay.temperatures.slice(0, 25);
                    const currentDayPMTemps = currentDay.temperatures.slice(24, 48);
                    currentDayPMTemps.push(currentDayAMTemps[0]);

                    this.currentDayOnOffAM = _.flatMap(currentDayAM, function (b: boolean, i) {
                        return (b) ? String(currentDayAMTemps[i]) : String(min - 3)
                    });

                    this.currentDayOnOffPM = _.flatMap(currentDayPM, function (b: boolean, i) {
                        return (b) ? String(currentDayPMTemps[i]) : String(min - 3)
                    });
                    break;

                case SchedulationType.Season:

                    this.currentHeatingAM = _.flatMap(currentDayAM, function (b: boolean) {
                        return (!b) ? '1' : '0'
                    });

                    this.currentHeatingPM = _.flatMap(currentDayPM, function (b: boolean) {
                        return (!b) ? '1' : '0'
                    });

                    this.currentCoolingAM = _.flatMap(currentDayAM, function (b: boolean) {
                        return (b) ? '1' : '0'
                    });

                    this.currentCoolingPM = _.flatMap(currentDayPM, function (b: boolean) {
                        return (b) ? '1' : '0'
                    });
                    break;

                case SchedulationType.OnOff:

                    this.currentDayOnOffAM = _.flatMap(currentDayAM, function (b: boolean) {
                        return (b) ? '1' : '0'
                    });

                    this.currentDayOnOffPM = _.flatMap(currentDayPM, function (b: boolean) {
                        return (b) ? '1' : '0'
                    });
                    break;
            }

            drawChart(true, tempArray, min, mid, max, lowerMin, xFormat);
            drawChart(false, tempArray, min, mid, max, lowerMin, xFormat);
        }

        // -------------------------------------------------------------------------------------------------------------
        // --- Update the UI of the schedules' ticks + active regions --------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        this.activeRegions();
        this.changeTicksUI();
    }

    populateHours() {

        if (!this.currentDateTime.dateTimeAsMoment) {

            return;
        }

        const dateTimeAsMoment = _.cloneDeep(this.currentDateTime.dateTimeAsMoment);
        const MCUToday = dateTimeAsMoment.format('dddd');
        const hour = dateTimeAsMoment.hour() % 12;

        this.hoursAM = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30',
            '10:00', '10:30', '11:00', '11:30', '12:00'];

        this.hoursPM = this.hoursAM;

        if (dateTimeAsMoment.minute() >= 30) {

            this.currentDateRegionFrom = hour + ":30";
            this.currentDateRegionTo = (hour + 1) + ":00";

        } else {

            this.currentDateRegionFrom = hour + ":00";
            this.currentDateRegionTo = hour + ":30";
        }

        this.currentDateRegionAMPM = dateTimeAsMoment.format('A');

        for (let day = 0; day < 7; day++) {

            this.dayNames[day] = dateTimeAsMoment.day(day).format('dddd');

            if (this.dayNames[day] === MCUToday) {

                this.todayIdx = day;
            }
        }

        this.dayNames[this.todayIdx] = this.dayNames[this.todayIdx] + " (" + this.translate.instant('TODAY') + ")";

        if (this.todayIdx != 0) {

            this.dayNames[this.todayIdx - 1] = this.dayNames[this.todayIdx - 1] + " (" + this.translate.instant('YESTERDAY') + ")"
        }

        if (this.todayIdx != 6) {

            this.dayNames[this.todayIdx + 1] = this.dayNames[this.todayIdx + 1] + " (" + this.translate.instant('TOMORROW') + ")"
        }
    }

    prevDay() {

        this.currentDayIdx--;
        this.createChart();
    }

    nextDay() {

        this.currentDayIdx++;
        this.createChart();
    }

    ngAfterContentInit(): void {
    }

    ngAfterViewInit() {

        // Adding a timer fixes the "ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked" error on {{currentDayLabel}} -> which isn't actually an error, but an annoying compliant in the console
        timer(0).subscribe(() => {

            console.log('ngAfterViewInit');
            this.createChart();

            if (!this.chartUIFixSubscription) {

                this.chartUIFixSubscription = interval(100).subscribe(() => {

                    this.changeTicksUI(); // calling changeTicksUI often may be useful in the long term -> sometimes changing Tabs or inspecting with Chrome make some UI changes disappear
                    this.checkAndResizeChart(); // This is useful only the first time -> sometimes the chart load but the Chart is cropped
                    this.checkIfActiveRegionChanged();
                });
            }
        });
    }

    checkAndResizeChart() {

        if (this.svgFixedAtFirstOpen) {

            return;
        }

        const fixSvg = (elementId) => {

            const htmlElement = document.getElementById(elementId + this.id);

            if (htmlElement) {

                htmlElement.querySelectorAll('svg').forEach((svg) => {

                    const widthParent = htmlElement.offsetWidth;
                    const widthSvg = Number(svg.getAttribute('width'));

                    if (widthParent > 0) {

                        if (widthSvg > widthParent) {

                            this.createChart();
                        }

                        this.svgFixedAtFirstOpen = true;
                    }
                });
            }
        }

        const elementId = this.isAnalogicErvEco2Graph ? 'chartAnalogicHRV' : 'chartAM';
        fixSvg(elementId);
    }

    changeTicksUI() {

        const changeUI = (element) => {

            if (!element) {

                return;
            }

            // ---------------------------------------------------------------------------------------------------------
            // --- Change the active-region <rect> -> I want it narrower, and then I push it up changing the y ---------
            // ---------------------------------------------------------------------------------------------------------

            element.querySelectorAll('.active-region > rect').forEach((rect) => {

                const alreadyModified = rect.getAttribute('modified');

                // When I've changed the UI of ther box once -> I don't want to change it anymore (since the width becomes half of the current width, calling this function again would make it one quarter of the width ecc..)
                if (!alreadyModified) {

                    const currentX = Number(rect.getAttribute('x'));
                    const currentWidth = Number(rect.getAttribute('width'));

                    const newWidth = currentWidth / 2;
                    const widthDifference = currentWidth - newWidth; // I want the "box" width to be half the size of the one that the plugin assigned
                    const newX = currentX + (widthDifference / 2); // After reducing the width of a box, I have to shift it forward to center it between the tick lines -> for example: x:0 width:12 (12 pixel total) will become x:3 width:6 (3-6-3 is still 12px total, compared to 0-12-0)

                    const yValue = this.schedule.CFG_Type === SchedulationType.OnOff ? -5 : -3;

                    rect.setAttribute('y', String(yValue));
                    rect.setAttribute('modified', "true");
                    rect.setAttribute('width', String(newWidth));
                    rect.setAttribute('x', String(newX));
                }

                // Triangle alternative to the "green box" for the schedule UI current time

                /* <svg x="148" y="-5" width="10" height="26" viewBox="0 0 100 100" id="triangle">
                    <polygon points="50 175, 100 100, 0 100"></polygon>
                </svg> */
            });

            // ---------------------------------------------------------------------------------------------------------
            // --- Change the ticks' UI of the schedule (based if the tick determines an hour or half an hour)  --------
            // ---------------------------------------------------------------------------------------------------------

            // const chartExists = document.getElementById(`chartAM${this.id}`);
            element.querySelectorAll('.tick').forEach((tickElement) => {

                const lineElement = tickElement.querySelector('line');
                const textElement = tickElement.querySelector('text');

                // The lines that display an hour text (es: 2) has its display as 'block' (otherwise it's none)
                if (textElement.style.display === 'block') {

                    // the lines with an hour text will be longer than "half-an-hour" lines (I have to change the y property of the line element to do it - for now it's 50% longer)
                    lineElement.setAttribute('y2', '9');
                    textElement.setAttribute('y', '12');
                }

                // If the hour is hidden (so it's a half an hour tick) -> I decrease its opacity
                else if (textElement.style.display === 'none') {

                    lineElement.classList.add('scheduleTickGrey');
                }
            });
        }

        const elementAM = document.getElementById('chartAM' + this.id);
        const elementPM = document.getElementById('chartPM' + this.id);

        changeUI(elementAM);
        changeUI(elementPM);
    }

    checkIfActiveRegionChanged() {

        const currentDateRegionFrom = this.currentDateRegionFrom;
        const currentDateRegionTo = this.currentDateRegionTo;

        this.populateHours();

        // console.log('checkIfActiveRegionChanged');

        // If the half an hour flashing rectangle has changed (because now from 13:29 it's 13:31) -> I update the active regions
        if (currentDateRegionFrom !== this.currentDateRegionFrom && currentDateRegionTo !== this.currentDateRegionTo) {

            this.createChart();
        }
    }

    // -------------------------------------------------------------------------------------------------------------
    // --- ACTIVE REGIONS ------------------------------------------------------------------------------------------
    // -------------------------------------------------------------------------------------------------------------

    activeRegions() {

        if (this.todayIdx === this.currentDayIdx) {

            if (this.currentDateRegionAMPM === 'AM' && this.chartAM) {

                this.chartAM.regions([{
                    start: this.currentDateRegionFrom,
                    end: this.currentDateRegionTo,
                    class: 'active-region'
                }]);
            } else if (this.chartPM) {

                this.chartPM.regions([{
                    start: this.currentDateRegionFrom,
                    end: this.currentDateRegionTo,
                    class: 'active-region'
                }]);
            }
        }
    }

    changeDualVariableScheduleToHeating() {

        this.PAR_DualSetpointSelection = DUAL_MODE.heating;
        this.createChart();
    }

    changeDualVariableScheduleToCooling() {

        this.PAR_DualSetpointSelection = DUAL_MODE.cooling;
        this.createChart();
    }
}

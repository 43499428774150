<!-- rounded container -->
<div *ngIf="ars_extended && ars_extended.RTT_CanHrv && zonePage.loaded && zonePage.isIaqAirSetpoint(ars_extended) && zonePage.showCO2() && !zonePage.noRadiant" class="rounded-container" [ngClass]="{'opened': airQualityVisibility || !extensible}">

    <ion-list-header class=" bg-header text-black positionRelative">

        <div *ngIf="extensible" class="positionAbsolute timer-arrow cursorPointer">
            <i (click)="show()" class="far" [ngClass]="{'fa-chevron-up': airQualityVisibility, 'fa-chevron-down': !airQualityVisibility}"></i>
        </div>

        <div class="d-flex flex-row justify-content-between align-items-center w-100">

            <div class="d-flex flex-row justify-content-start align-items-center">

                <div class="d-flex flex-row align-items-center">

                    <!-- "alignIconToRadioButton" fixes the air quality icon not aligned with radio buttons (the fix is slightly different between Android / Web and iOS) -->
                    <!-- forceShowIconIfDisabled: normally, air quality icons are shown only if active. In this case, since it's used in the collapse's header, I want to make sure it's shown even if it's disabled -->
                    <icons [MCU]="zonePage.MCU" [showPowerOff]="zonePage.showPowerOff()" [config]="zonePage.config"
                           [status]="zonePage.rms?.RTU_Flags || [0]" [alignIconToRadioButton]="alignIconToRadioButton"
                           [forceShowIconIfDisabled]="true" onlyHrv="true"></icons>
                </div>

                <div class="d-flex flex-row align-items-center">
                    <ion-label class="text-black ars-title">
                        <div [ngClass]="{'messana-disabled': !ars_extended.PAR_On || !ars_extended.PAR_HrvOn}" class="title-tmr">{{ 'AIR_QUALITY' | translate }}</div>
                    </ion-label>
                </div>
            </div>

            <div class="d-flex flex-row justify-content-end align-items-center">
                <ion-item lines="none">

                    <ion-toggle color="secondary" [checked]="zonePage.hrvState" (ionChange)="zonePage.hrvStateUpdated($event)"></ion-toggle>
                </ion-item>
            </div>
        </div>
    </ion-list-header>

    <div *ngIf="airQualityVisibility || !extensible" class="positionRelative" [ngClass]="{'withSublabel':ars_extended && ars_extended.RTT_CanHrv && zonePage.loaded && zonePage.isIaqAirSetpoint(ars_extended) && zonePage.showCO2() && !zonePage.noRadiant}">

        <counter [inDisabled]="!ars_extended.PAR_On || !ars_extended.PAR_HrvOn"
                 label="{{'ON_SET_POINT_CO2' | translate}}" subLabel="true" table="RMS" [item]="zonePage.rms"
                 var="PAR_SetCO2" [attrs]="zonePage.attrs" [MCU]="zonePage.MCU" [changableSetpoint]="true"
                 [whileChange]="zonePage.onChange" itemClass="no-lines padding-right-counter padding-top-1-5-rem"></counter>

        <span [ngClass]="{'disabled': !ars_extended.PAR_On || !ars_extended.PAR_HrvOn}" class="sublabel paddingSide-16 sublabelNoAbsolute"
              [innerHTML]="zonePage.translate.instant('C02_SUBTITLE')"></span>
    </div>
</div>

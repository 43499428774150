export enum NetworkWay {

    // connection through service...
    Remote = '[Remote] Remote Connection through API Proxy',
    // connection through local ip address
    LAN = '[LAN] Direct connection with Local Ip Address',
    DISCOVERY = '[LAN] Direct connection with hostname', // use the property "zeroConfFromGuest" from Config to know if you've loaded a local system from GUEST
    BLUETOOTH = '[BLUETOOTH] Direct connection with Bluetooth',
    ERROR = "[ERROR] Commutation error"
}

export enum NetworkStatus {
    Reloading = '[Reloading]',
    Active = '[Active]'
}

export class HttpStatus {

    constructor(
        public currentRequest: number = 0,
        public pendingRequests: number = 0,
        public modality: NetworkWay = NetworkWay.Remote,
        public reloadingNetwork: NetworkStatus = NetworkStatus.Active

    ) {
        // console.log(`costruttore httpStatus currentRequest ${currentRequest}, pendingRequests ${pendingRequests} modality ${modality}  reloadingNetwork ${reloadingNetwork}`)
    }
}

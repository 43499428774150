import {
    ChangeDetectionStrategy,
    AfterContentInit,
    Component,
    EventEmitter,
    forwardRef,
    HostListener,
    Input,
    OnInit,
    OnDestroy,
    Optional,
    Output,
    Provider,
    ViewEncapsulation
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Router, ActivatedRoute, Params, RouterEvent, NavigationEnd, NavigationExtras} from '@angular/router';
import {Subscription} from 'rxjs';
import {Table} from "../../models/tables";
import {ToggleProperties} from "../../models/toggle-properties";

/*import { Form } from '@ionic/angular/util/form';
import { Item } from '@ionic/angular/components/item/item';
import { isTrueProperty } from 'ionic-angular/util/util';*/

//vecchia versione
//export const CHECKBOX_VALUE_ACCESSOR = new Provider( NG_VALUE_ACCESSOR, {useExisting: forwardRef(() => CollapseComponent), multi: true});


//nuova versione


export const CHECKBOX_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CollapseComponent),
    multi: true
};
const TYPE_CONTROL_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CollapseComponent),
    multi: true
};


@Component({
    selector: 'collapse',
    templateUrl: './collapse.component.html',
    styleUrls: ['./collapse.component.scss'],

    providers: [TYPE_CONTROL_ACCESSOR]
})
export class CollapseComponent implements AfterContentInit, ControlValueAccessor, OnDestroy {

    // questa componente è da capire bene
    public _checked: boolean = false;
    private _init: boolean;
    private _disabled: boolean = false;
    private _labelId: string;
    private _fn: Function;

    id: string;

    // CSS class
    @Input()
    itemClass: string;

    @Input()
    labelClass: string;

    @Input()
    trueClass: string;

    @Input()
    falseClass: string;

    // label
    @Input()
    label: string;

    // label
    @Input()
    dateString: string;

    @Input()
    showIcon: boolean;

    @Input()
    icon: string;

    @Input()
    toggleProperties: ToggleProperties;

    @Input()
    iconBeforeLabel: string;

    @Output()
    onChangeToggle = new EventEmitter<Table>();

    /* @Output()
    ionChange: EventEmitter<CollapseComponent> = new EventEmitter<CollapseComponent>(); */

    // i tipi Form e Item non mi sembrano esistere su ionic 5

    constructor(
        /*private _form: Form,
        @Optional() private _item: Item*/
    ) {

        /* _form.register(this);

         if (_item) {
             this.id = 'chk-' + _item.registerInput('checkbox');
             this._labelId = 'lbl-' + _item.id;
             this._item.setCssClass('item-checkbox', true);
         }*/
    }

    @HostListener('click', ['$event'])
    public _click(ev: UIEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        if (!this._disabled)
            this.onChange(!this._checked);
    }

    @Input()
    get checked(): boolean {
        return this._checked;
    }

    set checked(val: boolean) {

        if (!this._disabled) {
            this._setChecked(true);
            this.onChange(this._checked);
            this.onTouched();

        }
        //isTrueProperty non sembra esistere più
        //this._setChecked(isTrueProperty(val));
    }

    private _setChecked(isChecked: boolean) {
        //console.warn('collapse _setChecked');

        if (isChecked !== this._checked && !this._disabled && (isChecked != null && isChecked != undefined)) {
            // console.log(`_setChecked(isChecked vale ${isChecked})`);
            this._checked = isChecked;
            //console.warn(`ho messo this._checked a ${this._checked}`);
            if (this._init) {
                //this.ionChange.emit(this);
            }
            //this._item && this._item.setCssClass('item-checkbox-checked', isChecked);
        }
    }

    writeValue(val: any) {
        //console.warn(`collapse writeValue val vale ${val}`);
        //isTrueProperty non sembra esistere più
        //  this._setChecked( isTrueProperty(val) );
        //scrivo il valore
        this._setChecked(val);
    }

    registerOnChange(fn: Function): void {
        this._fn = fn;
        this.onChange = (isChecked: boolean) => {
            fn(isChecked);
            this._setChecked(isChecked);
            this.onTouched();
        };
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(val: boolean) {
        //console.log(`set disabled chiamato val vale ${val} e _disabled vale ${this._disabled}`);
        //isTrueProperty non sembra esistere più

        //this._disabled = isTrueProperty(val);
        this._disabled = val;
        // this._item && this._item.setCssClass('item-checkbox-disabled', this._disabled);
        if (val) {
            this._checked = false;
            this.onChange(false)
        }
    }

    setDisabledState(disabled: boolean): void {
        this._disabled = disabled;
    }

    onChange(isChecked: boolean) {
        // used when this input does not have an ngModel or formControlName

        this._setChecked(isChecked);
        this.onTouched();
    }

    onTouched() {
    }

    ngAfterContentInit() {

        this._init = true;
        // console.log('_init', this._init);
    }

    ngOnDestroy() {

        // console.warn('ngOnDestroy - collapse.component');
    }

    private onTouch: Function;
    private onModelChange: Function;

    onToggleChange(event) {

        this.onChangeToggle.emit(event.detail.checked);
    }

    preventCollapse($event: MouseEvent) {

        $event.stopPropagation();
    }
}

import { Injectable } from '@angular/core';
import { Observable,of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, Effect,ofType } from '@ngrx/effects';
import { map,catchError,mergeMap,tap } from 'rxjs/operators';

import { AppState } from '../services/app-state.service';
import { BackupsActions,BackupActionTypes,LoadBackup,LoadBackupSuccess,LoadBackupError } from '../actions/backup.actions';
import { BackupsService } from '../services/backups.service';


@Injectable()
export class BackupsEffects {

    // The update$ variable is a StateUpdate from ngrx/effects.
    // This is an observable that emits everytime an action flows through the store.

    constructor(private actions$: Actions<BackupsActions>,
                private backupsService: BackupsService) {
    }


    logLoad(data){
      // console.log("backup-effect,load(),data vale");
      // console.log(data);
    }

  @Effect()
       load$: Observable<any> = this.actions$.pipe(
        ofType(BackupActionTypes.LoadBackup),
      
        mergeMap(action => {
             
               return this.backupsService.getBackups().pipe(
                  // If successful, dispatch success action with result
                  // tap(data=>this.logLoad(data)),
                  map(data => new LoadBackupSuccess(data)),
                  // If request fails, dispatch failed action
                  catchError((err) => of(new LoadBackupError( err)))
              )
            }
        )
    );

  @Effect()
       create$: Observable<any> = this.actions$.pipe(
        ofType(BackupActionTypes.CreateBackup),
      
        mergeMap(action => {
             
               return this.backupsService.createBackup(action['name']).pipe(
                  // If successful, dispatch success action with result
                  map(data => new LoadBackupSuccess(data)),
                  // If request fails, dispatch failed action
                  catchError((err) => of(new LoadBackupError( err)))
              )
            }
        )
    );


  
  
  @Effect()
       rename$: Observable<any> = this.actions$.pipe(
        ofType(BackupActionTypes.RenameBackup),
      
        mergeMap(action => {
             
               return this.backupsService.renameBackup(action['id'],action['name']).pipe(
                  // If successful, dispatch success action with result
                  map(data => new LoadBackupSuccess(data)),
                  // If request fails, dispatch failed action
                  catchError((err) => of(new LoadBackupError( err)))
              )
            }
        )
    );


  @Effect()
       delete$: Observable<any> = this.actions$.pipe(
        ofType(BackupActionTypes.DeleteBackup),
      
        mergeMap(action => {
             
               return this.backupsService.deleteBackup(action['id']).pipe(
                  // If successful, dispatch success action with result
                  map(data => new LoadBackupSuccess(data)),
                  // If request fails, dispatch failed action
                  catchError((err) => of(new LoadBackupError( err)))
              )
            }
        )
    );


  
  

   

  @Effect()
       restore$: Observable<any> = this.actions$.pipe(
        ofType(BackupActionTypes.RestoreBackup),
      
        mergeMap(action => {
             
               return this.backupsService.restoreBackup(action['id']).pipe(
                  // If successful, dispatch success action with result
                   // tap(data=>this.logLoad(data)),
                  map(data => new LoadBackupSuccess(data)),
                  // If request fails, dispatch failed action
                  catchError((err) => of(new LoadBackupError( err)))
              )
            }
        )
    );




}

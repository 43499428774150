import { Component, OnInit, Input, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';
import { ScheduleOptionInterface, ScheduleOption, ScheduleSCHOption, SCH_OPTION_DELTA } from '../schedule/schedule-option';

export enum KEY_CODE {
  ESCAPE = 27,
  ENTER = 13
}

@Component({
  selector: 'no-schedule-component',
  templateUrl: './no-schedule.component.html',
  styleUrls: ['./no-schedule.component.scss'],
})
export class NoScheduleComponent implements OnInit {

  @Input() title:string;
  @Input() message:string;
  @Input() scheduleId:number;
  @Input() options:ScheduleOptionInterface[];

  public buttons:any[];
  public buttonsEmpty:any[];

  private enabled: boolean;

   private lastClick: number;




 constructor(private translate:TranslateService, public modalController:ModalController) {
   this.buttonsEmpty=[
               
                {
                  text: '<i class="fa fa-plus"></i>',
                  handler: async (retParams) => {
                      
                      
                         this.modalController.dismiss({
                      'createNewSchedulation': true,
                      'updateScheduleId':false,
                      ...retParams
                    });
                  }

                },
                 {
                    text: this.translate.instant('CANCEL'),
                    handler: async (retParams) => {
                        this.modalController.dismiss({
                    'createNewSchedulation': false,
                    'updateScheduleId':false,
                     ...retParams
                  });
                    }
                }
            ];
     
        this.buttons=[
               
                {
                  text: this.translate.instant('OK'),
                  handler: async (retParams) => {
                      
                      
                         this.modalController.dismiss({
                      'createNewSchedulation': false,
                      'updateScheduleId':true,
                      ...retParams
                    });
                  }

                },
                 {
                    text: this.translate.instant('CANCEL'),
                    handler: async (retParams) => {
                        this.modalController.dismiss({
                    'createNewSchedulation': false,
                     'updateScheduleId':false,
                     ...retParams
                  });
                    }
                }
            ];
   


  
   }

   onChangeRadioGroup($event){
     // console.log($event);
   }

   ionViewDidEnter() {
    
        this.enabled = true;
    }

    ionViewLoaded() {

    
    

       
     
    }

    changeType(option:ScheduleOptionInterface){

        this.scheduleId = option.value;
    

       // console.log(`la scelta è ${this.scheduleId}`);
   }



  ngOnInit() {}

   btnClick(button: any) {
        if (!this.enabled) {
            return;
        }

      

        if (button.handler) {
            // a handler has been provided, execute it
            // pass the handler the values from the inputs
            button.handler(this.getValues()) 
        }

       
    }

    @HostListener('body:keyup', ['$event'])
    public _keyUp(ev: KeyboardEvent) {
    	ev.preventDefault();
    	// console.log(ev.keyCode);

        if (this.enabled) {
            if (ev.keyCode === KEY_CODE.ENTER) {
                if (this.lastClick + 1000 < Date.now()) {
                    // do not fire this click if there recently was already a click
                    // this can happen when the button has focus and used the enter
                    // key to click the button. However, both the click handler and
                    // this keyup event will fire, so only allow one of them to go.
                    // console.debug('alert, enter button');
                    let button = this.buttons[this.buttons.length - 1];
                    this.btnClick(button);
                }

            } else if (ev.keyCode === KEY_CODE.ESCAPE) {
                // console.debug('alert, escape button');
                 this.modalController.dismiss({
								      'createNewSchedulation': false
            });
        }
    }
	}

 getValues() {
 	
        return {scheduleId:this.scheduleId};
       // return { groupId: this.groupId, name: this.name };
   
    }



 



}

<ion-item class="no-lines calibration-text">


  <div class="w-100 d-flex flex-row justify-content-center align-items-center ">
      <div>
        <p>{{'CAL_DESC_1' | translate}}</p>
        <ul>
            <li>{{'CAL_F1' | translate}}</li>
            <li>{{'CAL_F2' | translate}}</li>
            <li>{{'CAL_F3' | translate}}</li>
        </ul>
        <p class="last-section">{{'CAL_DESC_2' | translate}}
            <br/>
            {{'CAL_DESC_3' | translate}}
        </p>
    </div>
  </div>
</ion-item>
<!--<collapse class="scheduleCollapse" [(ngModel)]="showStartingText" (ngModelChange)="showStartingTextChange.emit(showStartingText)" [itemClass]="'topLine'"  [ngModelOptions]="{standalone: true}" name="startingCalibrationText" ngDefaultControl  label="{{'PREP_DESC_1' | translate}}"
></collapse>-->
<ion-item class="no-lines calibration-text">
  <ion-label>{{'PREP_DESC_1' | translate}}</ion-label>
</ion-item>

<ion-item  class="no-lines calibration-text">
    <div class="w-100 d-flex flex-row justify-content-center align-items-center">
        <div>
          <ol>
              <li>{{'PREP_STEP_1' | translate}}</li>
              <li>{{'PREP_STEP_2' | translate}}</li>
             
          </ol>
         
      </div>
    </div>
</ion-item>

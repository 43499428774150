<!-- rounded container -->
<div *ngIf="ars_extended && ars_extended.realCanHum && zonePage.loaded && zonePage.showHumSetpoint(ars_extended) && !zonePage.noRadiant" class="rounded-container" [ngClass]="{'opened': humVisibility || !extensible}">

    <ion-list-header class=" bg-header text-black positionRelative">

        <div *ngIf="extensible" class="positionAbsolute timer-arrow cursorPointer">
            <i (click)="show()" class="far" [ngClass]="{'fa-chevron-up': humVisibility, 'fa-chevron-down': !humVisibility}"></i>
        </div>

        <div class="d-flex flex-row justify-content-between align-items-center w-100">

            <div class="d-flex flex-row justify-content-start align-items-center">

                <div class="d-flex flex-row align-items-center">

                    <!-- "alignIconToRadioButton" fixes the humidification button not aligned with radio buttons (the fix is slightly different between Android / Web and iOS) -->
                    <!-- forceShowIconIfDisabled: normally, humidification icons are shown only if active. In this case, since it's used in the collapse's header, I want to make sure it's shown even if it's disabled -->
                    <icons [MCU]="zonePage.MCU" [showPowerOff]="zonePage.showPowerOff()" [config]="zonePage.config"
                           [status]="zonePage.rms?.RTU_Flags || [0]" [alignIconToRadioButton]="alignIconToRadioButton"
                           [forceShowIconIfDisabled]="true" onlyHum="true"></icons>
                </div>

                <div class="d-flex flex-row align-items-center">
                    <ion-label class="text-black ars-title">
                        <div class="title-tmr">{{ 'HUMIDIFICATION' | translate }}</div>
                    </ion-label>
                </div>
            </div>

            <div class="d-flex flex-row justify-content-end align-items-center">
                <ion-item lines="none">

                    <ion-toggle color="secondary" [disabled]="true" [checked]="ars_extended.RTT_CanHum"
                                (ionChange)="zonePage.updateRttCanHum($event, ars_extended)"></ion-toggle>
                </ion-item>
            </div>
        </div>
    </ion-list-header>

    <div *ngIf="humVisibility || !extensible">

        <counter [inDisabled]="!ars_extended.PAR_On || !ars_extended.PAR_HumOn || zonePage.disableHumSetpiont(ars_extended)"
                 (radioClickEmitter)="zonePage.changeRhModeDP($event)"
                 [radio]="ars_extended.PAR_On" [radioChecked]="zonePage.MCU.PAR_RhMode" temperature
                 [disabledSP]="!zonePage.MCU.PAR_RhMode"
                 label="{{ 'DEW_POINT_TEMP' | translate }}" table="RMS" [item]="zonePage.rms" var="PAR_SetHumDP"
                 [attrs]="zonePage.attrs" [MCU]="zonePage.MCU" [changableSetpoint]="true"
                 [whileChange]="zonePage.onChange" itemClass="no-lines padding-right-counter paddingSide-16 padding-top-1-5-rem"></counter>

        <counter [inDisabled]="!ars_extended.PAR_On || !ars_extended.PAR_HumOn || zonePage.disableHumSetpiont(ars_extended)"
                 (radioClickEmitter)="zonePage.changeRhModeHum($event)"
                 [radio]="ars_extended.PAR_On" [radioChecked]="!zonePage.MCU.PAR_RhMode" percent
                 [disabledSP]="zonePage.MCU.PAR_RhMode"
                 label="{{ 'RELATIVE_HUMIDITY' | translate }}" table="RMS" [item]="zonePage.rms" var="PAR_SetHumRH"
                 [attrs]="zonePage.attrs" [MCU]="zonePage.MCU" [changableSetpoint]="true"
                 [whileChange]="zonePage.onChange" itemClass="no-lines padding-right-counter paddingSide-16"></counter>
    </div>
</div>

import { Component, OnInit, Input } from '@angular/core';
import * as lo from 'lodash';
import * as moment from 'moment';


@Component({
  selector: 'past-calibrations',
  templateUrl: './past-calibrations.component.html',
  styleUrls: ['./past-calibrations.component.scss'],
})
export class PastCalibrationsComponent implements OnInit {


  @Input() calibrations: any[];
  @Input() loaded: boolean;


  public: any[];
  public showPrev: boolean;

  constructor() {


   }
   show() {
     this.showPrev = !this.showPrev;
   }


   get ordered_calibrations(): any[] {
    return JSON.parse(JSON.stringify(lo.orderBy(this.calibrations, ['unix'], ['desc'])));
   }

   get previous_calibrations(): any[] {
    const ordered = JSON.parse(JSON.stringify(lo.orderBy(this.calibrations,  ['unix'], ['desc'])));
    const prev = [];
    for (let i = 0; i < this.calibrations.length; i++) {
       if (i > 0) {
         prev.push(ordered[i]);
       }
     }
    return prev;
   }

  ngOnInit() {
    this.showPrev = false;
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import {TranslateLoader, TranslateModule,TranslateService} from '@ngx-translate/core';
/*
 * Format a boolean as On or Off
 */
@Pipe({name: 'onoff'})
export class OnOffPipe implements PipeTransform {

    constructor(protected translate: TranslateService) {
    }

    transform(value: boolean): string {
        return value ? this.translate.instant('ON') : this.translate.instant('OFF');
    }

}

export const CENSUS_TRANSLATIONS_EN = {

  "NEW_USERNAME":"New Username",
  "USERNAME":"Username",

  "CENSUS_COMPLETED":"Census Completed",
  "CENSUS_EXCEPTION":"Census Process Interrupted by an Exception",
  "CENSUS_FAILURE":"Census Process Unsuccessful",
  "MASTER_NS_SYNCHRONIZED_IS_OTHER_USER":"",
  "MASTER_NS_SYNCHRONIZED_IS_CURRENT_USER":"",
  "VALIDATION_ERROR_ALERT_TITLE":"Validation Error",
  "VALIDATION_CODE_GENERATION":"Validation Code Generation Error",
  "ERROR_CREDENTIALS":"Credentials Error",



  "CENSUS_HEADER_MASTER_PAGE_01":"Census Process (1/",
  "CENSUS_HEADER_RES_SER_PAGE_01":"Census Process (1/",
  "CENSUS_HEADER_RES_SER_PAGE_02":"Census Process (2/",
  "CENSUS_HEADER_RES_SER_PAGE_03":"Census Process (3/",
  "CENSUS_HEADER_RES_SER_PAGE_04":"Census Process (",
  "CENSUS_HEADER_MASTER_PAGE_02":"Census Process (2/",
  "CENSUS_HEADER_MASTER_PAGE_CONFIRMATION":"Census Process (2/",
  "CENSUS_HEADER_USER_NOT_DESIGNATED":"Census Process (2/",
  "CENSUS_HEADER_MASTER_PAGE_VALIDATION":"Census Process (3/",
  "CENSUS_HEADER_MASTER_PAGE_MAIL_ADDRESS_FORMAT_01":"Census Process (3/",
  "CENSUS_HEADER_MASTER_PAGE_MAIL_ADDRESS_FORMAT_02":"Census Process (4/",
  "CENSUS_HEADER_MASTER_PAGE_USERNAME_UPDATED":"Census Process (5/",
  "CENSUS_HEADER_MASTER_PAGE_DATA_REVIEW":"Census Process (4/",
  "CENSUS_HEADER_USER_PAGE_DATA_REVIEW_VALID_MAIL":"Census Process (3/",
  "CENSUS_HEADER_USER_PAGE_DATA_REVIEW_NON_VALID_MAIL":"Census Process (4/",


  "CENSUS_EXPLAIN_MASTER_DATA_REVIEW_TITLE" : "Personal Data Review",
  "UPDATE_PERSONAL_DATA":"Update Personal Data",
  "CONFIRM_DATA":"Confirm personal data",


  "CENSUS_EXPLAIN_MASTER_TITLE":"Maser User Census",
  "CENSUS_EXPLAIN_RES_SER_PAGE_01_TITLE":"Messana Users Census",
  "CENSUS_EXPLAIN_RES_SER_PAGE_02_TITLE":"Census process: residence / service users",
  "CENSUS_EXPLAIN_RES_SER_PAGE_03_TITLE":"Census process: e-mail address collection",
  "CENSUS_EXPLAIN_RES_SER_PAGE_04_TITLE":"Census process completion",
  "CENSUS_EXPLAIN_MASTER_CONFIRMATION_TITLE":"Master User Confirmation",
  "CENSUS_EXPLAIN_MASTER_QUERY_TITLE":"Maser User Query",
  "CENSUS_EXPLAIN_MASTER_MAIL_ADDRESS_FORMAT_01_TITLE":"Username Format Compliance",
  "CENSUS_EXPLAIN_MASTER_MAIL_ADDRESS_FORMAT_02_TITLE":"Account Activation",
  "CENSUS_EXPLAIN_MASTER_VALIDATION_CODE_VERIFICATION_TITLE":"Code Verification",
  "CENSUS_EXPLAIN_MASTER_VALIDATION_TITLE":"Account Verification",
  "CENSUS_EXPLAIN_MASTER_USERNAME_UPDATE_TITLE":"Username Update Completed",

  "CENSUS_EXPLAIN_RES_SER_CATEGORY_01":"<p>We are pleased to confirm that the Master User for this system has been successfully identified. However, your user account has not yet been classified into a specific category.</p>" +

    "<p>To proceed with the user census, we kindly request your assistance in selecting your user type. You have the option to choose between two categories:</p>" +

    "<ul>" +
    "    <li>Residence User: this category includes individuals who regularly reside at the location of the Messana Control System, such as family members, housemates, or renters.</li>" +
    "    <li>Service User: this category pertains to external contractors, technicians, engineers, or professionals who require access to the Messana Control System for maintenance, design, troubleshooting, or other service-related activities.</li>" +
    "</ul>"+
    "<p>Please take a moment to review and confirm your appropriate user type by filling out the form below.</p>",

  "CENSUS_EXPLAIN_RES_SER_01":" <p>We are reaching out to inform you about an important update regarding our Control System Platform and the users associated with it. To ensure accurate and efficient management of your system, we are conducting a user census. This process involves verifying and updating the list of users who have access to your Messana Control System.</p>" +
    "    <p>As part of this census, we need to confirm the details of all users, including the following user types:</p>\n" +
    "    <ul>" +
    "        <li>" +
    "            Master User: the Master User is the primary contact and administrator for the Control System, holding the highest level of authority and responsibility. This includes managing financial aspects and having exclusive authority to add, modify, or remove both Residence and Service Users. To unlock the census for Residence Users and Service Users, verification of the Master User is required.\n" +
    "        </li>" +
    "        <li>" +
    "            Residence Users: individuals who regularly reside at the location of the Messana Control System, such as family members, housemates, or renters. They have a permanent or semi-permanent presence in the home and are granted access by the Master User.\n" +
    "        </li>\n" +
    "        <li>\n" +
    "            Service Users: external contractors, technicians, engineers, or professionals who require access to the Messana Control System for maintenance, design, troubleshooting, or other service-related activities. Their access is managed and granted by the Master User.\n" +
    "        </li>\n" +
    "    </ul>" +
    "    <p>As part of this census, if necessary, users will be asked to verify and update their email address.</p>"+
    "<p>Your cooperation in updating this information is greatly appreciated. Please take a moment to review and confirm the following details related to your user status within our system:</p>"+
    "<ul>"+
    "<li>User Type: [ Master User / Residence User / Service User ]</li>"+
    "<li>Role Description (if applicable): family members, housemates, or renters for Residence User; external contractor, technician, engineer, professional for Service User.</li>"+
    "</ul>",

  "START_USER_CENSUS":"Start User Census",
  "CENSUS_EXPLAIN_MASTER_01":"<p>We are reaching out to inform you about an important update regarding our Control System Platform and the users associated with it.</p>" +
    "   <p>To ensure accurate and efficient management of your system, we are conducting a user census. This process involves verifying and updating the list of users who have access to your Messana Control System. As part of this census, we need to confirm the details of all users, including the following user types:</p>"+
    "<ul>"+
    "<li>Master User: the Master User is the primary contact and administrator for the Control System, holding the highest level of authority and responsibility. This includes managing financial aspects and having exclusive authority to add, modify, or remove both Residence and Service Users. To unlock the census for Residence Users and Service Users, verification of the Master User is required.</li>"+
    "<li>Residence Users: individuals who regularly reside at the location of the Messana Control System, such as family members, housemates, or renters. They have a permanent or semi-permanent presence in the home and are granted access by the Master User.</li>"+
    "<li>Service Users: external contractors, technicians, engineers, or professionals who require access to the Messana Control System for maintenance, design, troubleshooting, or other service-related activities. Their access is managed and granted by the Master User.</li>"+
    "</ul>"+
    "<p>As part of this census, if necessary, users will be asked to verify and <b>update their username to match the email address format</b> required by our system.</p>"+
    "<p>Your cooperation in updating this information is greatly appreciated. Please take a moment to review and confirm the following details related to your user status within our system:</p>"+
    "<ul>"+
    "<li>User Type: [ Master User / Residence User / Service User ]</li>"+
    "<li>Role Description (if applicable): family members, housemates, or renters for Residence User; external contractor, technician, engineer, professional for Service User.</li>"+
    "</ul>",

  "CENSUS_EXPLAIN_NO_CHANGE":"<p>Please note that your personal data <b>cannot</b> be changed.</p>",
  "CENSUS_EXPLAIN_MASTER_02":"If you have any questions regarding your user status or personal data, please do not hesitate to reach out to our support team at ",
  "OUR_SUPPORT_PAGE":"our support page",
  "CENSUS_EXPLAIN_MASTER_QUERY_DEAR_USER":"Dear ",
  "CENSUS_EXPLAIN_MASTER_QUERY_01_A":"we are currently in the process of identifying the Master User for the Control System ",

  "CENSUS_EXPLAIN_USER_NOT_DESIGNATED":"<p>Based on our system, it appears that you are not designated as the Master User for this specific Control System. The Master User holds the highest level of authority and responsibilities within the system, including acting as the main point of contact, managing financial aspects, and having exclusive authority over user management.</p>"+
  "<p>If you are not the intended Master User for this system, please notify the designated individual to initiate the confirmation process.</p>"+
    "<p>Should you have any inquiries or need further clarification regarding your role or responsibilities, please feel free to contact our support team via our <a href='https://messana.tech/open-a-support-case/' target='_blank'>ticket page</a>.</p>",


  "CENSUS_EXPLAIN_MASTER_QUERY_01_B":"According to our records, this role has not been designated yet.",
  "CENSUS_EXPLAIN_MASTER_QUERY_01_C":"<br><p>As you are currently logged in, we kindly request your confirmation if you are the intended Master User for this specific Control System. The Master User holds the highest level of authority and responsibilities within the system, including acting as the main point of contact, managing financial aspects, and having exclusive authority over user management.<br>" +
    "<br>Your confirmation will assist us in maintaining accurate records and ensuring that our services align with your needs. If you are not the Master User for this system, please inform the designated Master User to initiate the census process.</p>",
   "CENSUS_EXPLAIN_MASTER_QUERY_01_D":"If you have any questions or require further clarification regarding your role or responsibilities, please do not hesitate to reach out to our support team via our ",
    "SUPPORT_CASE_PAGE":"support case page",
  "QUIT_USER_CENSUS":"Quit User Census",
  "THANKS_COOP":"Thank you for your cooperation.",
  "START_MASTER_USER_CONFIRMATION":"Confirm Master User",
  "SEND_VERIFICATION_CODE":"Send Verification Code",
  "RESEND_VERIFICATION_CODE":"Resend Verification Code",
  "CENSUS_EXPLAIN_MAIL_ADDRESS_FORMAT_01":"<p>We noticed that your current username is not in the format of an email address, which is required by our system's standards for authentication and communication. To proceed, we kindly ask you to update your username by selecting a valid email address.</p>" +
    "<p>Please note that you will need to enter your <b>current</b> password in the password field to verify your identity during this update process.</p>"+
    "<p>Once you've updated your username, we will send a 6-digit verification code to the provided email address for authentication purposes. This code will be used to validate and confirm your identity securely.</p>",
  "CENSUS_EXPLAIN_MAIL_ADDRESS_FORMAT_02":"<p>An email has just been sent to the email address you provided: {{e- mail-address}}. Inside this email, you will find a 6-digit verification code that is required to complete the ongoing process.</p>" +
    "<p>Please check your inbox (and also your spam/junk folder if necessary) for the email containing the verification code. Please copy the 6-digit verification code and paste it in the designated field below to proceed:</p>",
  "CENSUS_EXPLAIN_MAIL_ADDRESS_FORMAT_02_NOT_RECEIVED":"<p>If you do not receive the email within a few minutes, you can request to resend the verification code.</p>",

  "CENSUS_EXPLAIN_MASTER_VALIDATION":"<p>To ensure the security of your account and verify your identity, we kindly ask you to authenticate again. Once you enter your credentials and press the 'Send Verification Code' button, a 6-digit verification code will be sent to the email address associated with your username.</p>" +
    "<p>Please check your email inbox (and spam/junk folder if necessary) for the email containing the verification code. After receiving the code, enter it into the designated fields below to proceed with the verification.</p>",

  "CENSUS_EXPLAIN_MASTER_VALIDATION_VALID_ACCOUNT_01":"<p>An email has just been sent to the email address you provided:</p>",
  "CENSUS_EXPLAIN_MASTER_VALIDATION_VALID_ACCOUNT_02":"<p>Inside this email, you will find a 6-digit verification code that is required to complete the ongoing process.</p>",
  "CENSUS_EXPLAIN_MASTER_VALIDATION_VALID_ACCOUNT_03":"<br><p>Please check your inbox (and also your spam/junk folder if necessary) for the email containing the verification code. Please copy the 6-digit verification code and paste it in the designated field below to proceed:</p>",
  "CENSUS_EXPLAIN_MASTER_VALIDATION_VALID_ACCOUNT_04":"<p>If you do not receive the email within a few minutes, you can request to resend the verification code.</p>",




  "CENSUS_EXPLAIN_MASTER_PAGE_USERNAME_UPDATED":"<p>Your new username has been successfully updated.</p>"+
  "<p>In order to finalize this change and proceed with the current procedure, we will now sign you out of your account.</p>"+
  "</p>Please log in again using your updated username and password to continue with the ongoing process.</p>",
  "CENSUS_EXPLAIN_MASTER_CONFIRMATION_01":"According to our records, you are currently identified as the Master User for the selected Control System",
  "CENSUS_EXPLAIN_MASTER_CONFIRMATION_02":"<p>As the Master User, you hold the highest level of authority and access within this system. Your responsibilities include acting as the main point of contact, managing financial aspects related to the system, and having exclusive authority over user management.</p>" +
    "<p>To ensure accuracy and security, we kindly request your confirmation regarding this information. Please let us know if you acknowledge your role as the Master User for the specified system. Your confirmation will help us maintain accurate records and ensure that our services align with your needs.</p>" +
    "<p>If you have any questions or require further clarification regarding your role or responsibilities, please do not hesitate <a href='https://messana.tech/open-a-support-case/' target='_blank'>to reach</a> out to our support team.</p>",
  "USER_TYPE": "User Type",
  "RESIDENCE_USER": "Residence User",
  "SERVICE_USER": "Service User",
  "RESIDENCE_TYPE": "Residence Details",
  "FAMILY_MEMBER": "Family Member",
  "HOUSEMATE": "Housemate",
  "RENTER": "Renter",
  "SERVICE_TYPE": "Service Details",
  "EXTERNAL_CONTRACTOR": "External Contractor",
  "TECHNICIAN": "Technician",
  "ENGINEER": "Engineer",
  "PROFESSIONAL": "Professional",
  "NULL_KEY":"",
  "CHOOSE_USER_TYPE": "Choose User Type",
  "CHOOSE_RESIDENCE_TYPE": "Choose Residence Type",
  "CHOOSE_SERVICE_TYPE": "Choose Service Type",
  "CONFIRM_SERVICE_USER_CATEGORY":"Confirm service user catgory",
  "CONFIRM_RESIDENCE_USER_CATEGORY":"Confirm residence user category",
  "CENSUS_EXPLAIN_RES_SER_MAIL_ADDRESS_FORMAT_01":"<p>According to our records, we do not have an email address associated with your account."+
  "We require an email address for security reasons and to facilitate password reset functionality."+
  "Upon providing us with your email address, we will validate it by sending you a 6-digit verification code."+
  "Upon clicking the “Send Verification Code” button, please check your email inbox (including your spam/junk folder if needed) for the email containing the verification code."+
  "Once you receive the code, enter it into the designated spaces below.</p>",
  "CENSUS_EXPLAIN_RES_SER_COMPLETION":"<p>We are delighted to inform you that the classification process has been completed.</p>" +

    "<p>Your user account has now been classified into the appropriate category based on your selection. If you have any questions or require further assistance regarding your user status or system access, please feel free to reach out to our support team.</p>" +

    "<p>Thank you once again for your participation in ensuring the accurate management of your Messana Control System.</p>",
  "CENSUS_ERROR_INSTRUCTIONS":"<p>To help us better understand the issue you're experiencing, we invite you to take a screenshot of the current page and send it to us by opening a support ticket. This will allow our support team to investigate the matter more effectively and provide you with a prompt resolution.</p>" +
    "<p>We appreciate your cooperation and apologize for any inconvenience this may have caused.</p>",
  "CODE_SENT":"Verification code sent.",
  "CODE_NOT_SENT":"An error occurred while sending verification code.",
  "RESIDENCE_STATUS_COMPLETE":"Your registration as a residence user is complete.",
  "SERVICE_STATUS_COMPLETE":"Your registration as a service user is complete.",
  "CENSUS_EXPLAIN_DATA_REVIEW_01":"<p>If your account is associated with a company or business entity, please provide the name and surname of the legally authorized representative.<br>"+
    "This person should be able to handle payments and obligations on behalf of the company.</p>" +
    "<p>Please ensure that the name and surname provided match the personal data of the individual who is authorized to use a company/personal payment card or other payment methods.</p>",

  "CENSUS_EXPLAIN_DATA_REVIEW_USER_01":"<p>If your account is associated with a company or business entity, please provide the name and surname of the legally authorized representative.</p>",

  "FIRST_NAME":"Name",
  "LAST_NAME":"Last name",
  "COMPANY_NAME":"Company name",
  "COMPANY_RELATED":"Is this account associated with a company or business entity?",
  "ROLE_NAME":"Position or role in company (e.g., CEO, CFO, Managing Director)",
  "CENSUS_EXPLAIN_DATA_REVIEW_PERSON_02":"<p>Please ensure that the name and surname provided <b>match</b> the name on the debit or credit card that will be used for payments associated with this account.</p>",
  "UPDATE_COMPLETED":"Update Completed",
  "UPDATE_EXCEPTION":"Update Exception",
  "EXCEPTION_CONTACT_WITHOUT_CUSTOMER":"[exception] contact without customer;",
  "CLIENT_ANOMALY":"[exception] client anomaly;",
  "USER_ANOMALY":"[exception] the provided personal data did not match with our records;",
  "MASTER_ATTEMPT": "In an attempt to match a master user, the following events have occurred:",
  "CURRENT_USER_DATA_UPDATED":"Personal data successfully updated.",
  "CURRENT_USER_DATA_CONFIRMED":"Personal data successfully confirmed.",
  "PHONE_NUMBER":"Phone number",


}


<ion-item lines="none" [ngClass]="{'disabled-item':disabled}" class="schedule item {{ itemClass }} toggle-item">
    <div class="d-flex flex-row justify-content-between align-items-center w-100">
        <div class="d-flex flex-row align-items-center justify-content-start">
            <div>
                <span *ngIf="!noToggle" [ngClass]="{'messana-disabled': (disabled || scheduleId == -1 || !state)}" class="fa fa-calendar marginRight05"></span>
            </div>

            <ion-label hidden="true">{{'SCHEDULE' | translate }}</ion-label>
            <!-- <ion-label *ngIf="disabled">il campo disabled in input è a true </ion-label>
             <ion-label *ngIf="!disabled">il campo disabled in input è a false </ion-label> -->
            <div>
                <!-- <ion-select class="paddingLeft-1" [hidden]="(!state || scheduleId ==-1) && !noToggle" [value]="_value" (ionChange)="onChangeSelect($event.detail.value)" [disabled]="disabled && toggoleDisabled" #select1>  ho tolto -->

                <!-- HO TOLTO DA HIDDEN && table!='TMR' -->
                <ion-select *ngIf="scheduleSelectID" okText="{{'OK' | translate}}" id="{{scheduleSelectID}}" interface="alert" (click)="getCurrentSelectValue(_value)" [value]="_value" (ionChange)="onChangeSelect($event.detail.value)" [hidden]="(!state) && !noToggle || _value==-1 "
                            [disabled]="disabled">
                    <ion-select-option *ngFor="let option of selectOptions" [value]="option.value">
                        <span [ngClass]="{'messana-disabled':disabled}">{{ option.text }}</span>
                    </ion-select-option>
                    <!-- questa da dove l'ho tirata fuori? -->
                    <!--<ion-select-option [value]="-1"><span> {{ 'DISABLED' | translate }}</span></ion-select-option>-->
                </ion-select>
            </div>

            <!-- class="paddingLeft-1" qui nel div sotto, l'ho tolto per motivi di allineamento su /system-ARS -->
            <!-- ho tolto da if && table!='TMR' per permettere di avere il no-sch-selected su schedulazione per TMR -->

            <div>
                <!--<span>state {{state}}</span>
                <span>noToggle {{noToggle}}</span>
                <span>_value {{_value}}</span>-->
                <ion-label *ngIf="(!state) && (!noToggle || _value==-1) " class="messana-disabled toggle-label paddingLeft-02">
                    <!-- {{ 'DISABLED' | translate }} -->{{offText}}
                    <!--&nbsp;<span *ngIf="noToggle" class="selectTriangle" (click)="seasonClick()" >&#9662;</span> lo avevo messo per permettere un click da opzione no schedule -->
                </ion-label>

            </div>
        </div>

        <div class="d-flex flex-row justify-content-end align-items-center">
            <div>
                <ion-item class="noPaddingRight d-flex flex-row justify-content-center align-items-center">

                    <ion-toggle [ngClass]="{'greyscale': showGreyscaleToggle}" *ngIf="!noToggle" id="switch" [checked]="state" color="secondary" (change)="changeFired($event)"
                                (ionChange)="clickToggle($event)" [disabled]="disabled"></ion-toggle>
                    <!-- (ionChange)="changeToggle($event.detail)"  -->
                </ion-item>
            </div>
        </div>
    </div>
</ion-item>


<!-- ho tolto da ion-select class="paddingLeft-1", se servisse lo rimetto, però su system-ARS il padding-left dovrà essere a zero -->
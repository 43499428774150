import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'namefilter',
    pure: false
})
export class NameFilterPipe implements PipeTransform {
    transform(values: any[], query: string) {
        if (!values == null || values.length == 0 || !query) {
            return values;
        }
        if(values[0].CFG_Name)
          return values.filter(item => item.CFG_Name && item.CFG_Name.toLowerCase().indexOf(query.toLowerCase()) > -1);
        else
            return values.filter(item => item.name && item.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
    }
}

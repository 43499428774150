<ion-header>
    <ion-toolbar>
        <ion-title>{{ 'OVERRIDE_SETPOINT_TITLE' | translate }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="setpoint-override positionRelative">

    <div class="app">
        <div class="wrapper from d-flex flex-column justify-content-start align-items-center">

            <div>

                <br>
                <p class="text-align-center">{{ 'OVERRIDE_TEMP_SETPOINT' | translate }}</p>
                <br>

                <!-- bind(this) is used to say: use the context of this component, not the counter one -->
                <counter class="w-100"

                         temperature
                         [large]="true"
                         [icon]="'fal fa-temperature-half'"
                         table="MCU" [item]="MCU"
                         [value]="this.SpoSetTemp"
                         [step]="this.SpoSetTempStep"
                         [max]="this.SpoSetTempMax"
                         [min]="this.SpoSetTempMin"
                         [attrs]="this.attrs" [MCU]="MCU"
                         [whileChange]="onChangeSetPoint.bind(this)"
                         itemClass="no-lines"></counter>

                <br>
                <br>
                <!-- <p>{{ 'OVERRIDE_SETPOINT_PERIOD_TITLE' | translate }}</p>
                <br> -->

                <div class="text-align-center">
                    <button (click)="openOverridePeriodPicker()"
                            class="standard-messana-button">{{ 'SET_TIME' | translate }}</button>
                </div>


                <p class="ion-margin-top text-align-center">{{SpoPeriodText}}</p>

                <br>

                <p class="w-100 setpointOverrideText">{{'OVERRIDE_SETPOINT_PERIOD_TEXT_1' | translate}} {{SpoSetTemp}}
                    ° {{'OVERRIDE_SETPOINT_PERIOD_TEXT_2' | translate}} {{SpoPeriodText}} {{'OVERRIDE_SETPOINT_PERIOD_TEXT_3' | translate}} {{previousSetTemp}}
                    °.</p>

                <p *ngIf="dualSetpointOverrideMinimumDifferenceViolation.present" class="w-100 setpointOverrideText messana-red"><br>{{dualSetpointOverrideMinimumDifferenceViolation.text | translate}}</p>

                <p *ngIf="dualSetpointOverrideUpdateTimeViolation.present" class="w-100 setpointOverrideText messana-red"><br>{{dualSetpointOverrideUpdateTimeViolation.text | translate}}</p>

                <div class="setpointOverrideButtonsContainer alert-button-group w-100 d-flex flex-row justify-content-between align-items-center">

                    <button class="setpointOverrideButton" (click)="dismiss()">
                        <i aria-hidden="true" class="fa fa-times"></i>
                    </button>

                    <button class="setpointOverrideButton" (click)="save()" [disabled]="saveDisabled()">
                        <i aria-hidden="true" class="fa fa-check"></i>
                    </button>

                </div>
            </div>
        </div>
    </div>
</ion-content>

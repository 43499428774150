import { Injectable } from '@angular/core';
import {HttpHeaders} from '@angular/common/http';



export function fixedEncodeURI(str) {
    return encodeURI(str).replace(/%5B/g, '[').replace(/%5D/g, ']');
  }


export interface NativeResp {
    data:string;
}







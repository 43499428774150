import { Pipe, PipeTransform } from '@angular/core';
import {TranslateLoader, TranslateModule,TranslateService} from '@ngx-translate/core';

/*
 * Format a boolean as Yes or No
 */
@Pipe({name: 'yesno'})
export class YesNoPipe implements PipeTransform {

    constructor(protected translate: TranslateService) {
    }

    transform(value: boolean): string {
        return value ? this.translate.instant('YES') : this.translate.instant('NO');
    }

}

import {Component, OnInit, Input, HostListener} from '@angular/core';
import {Table, GRP} from '../../models/tables';
import {TranslateService} from '@ngx-translate/core';
import {ModalController} from '@ionic/angular';


export enum KEY_CODE {
    ESCAPE = 27,
    ENTER = 13
}

@Component({
    selector: 'new-macrozone',
    templateUrl: './new-macrozone.component.html',
    styleUrls: ['./new-macrozone.component.scss'],
})
export class NewMacrozoneComponent implements OnInit {

    @Input() GRP: GRP[];
    @Input() title: string;
    @Input() message: string;
    @Input() placeholder: string;
    @Input() placeholderEmail: string;

    private descId: string;
    private enabled: boolean;
    private hdrId: string;
    private id: number;
    private lastClick: number;
    private msgId: string;
    private subHdrId: string;

    // email
    public email: string = ''; // for EMA
    // name
    public name: string = '';
    // schedulation type
    public groupId: number = 0;


    public subTitle: string;
    public buttons: any[];

    constructor(private translate: TranslateService, public modalController: ModalController) {
        this.subTitle = '';
        //this.title=this.translate.instant('NEW_MACRO_ZONE');
        //this.message=//this.translate.instant('NEW_MACRO_ZONE_TEXT');
        this.buttons = [

            {
                text: this.translate.instant('SAVE'),
                handler: async (retParams) => {


                    this.modalController.dismiss({
                        'toSave': true,
                        ...retParams
                    });
                }

            },
            {
                text: this.translate.instant('CANCEL'),
                handler: async () => {
                    this.modalController.dismiss({
                        'toSave': false
                    });
                }
            }
        ];


        this.id = (++alertIds);
        this.descId = '';
        this.hdrId = 'alert-hdr-' + this.id;
        this.subHdrId = 'alert-subhdr-' + this.id;
        this.msgId = 'alert-msg-' + this.id;
        this.lastClick = 0;


        if (this.message) {
            this.descId = this.msgId;

        } else if (this.subTitle) {
            this.descId = this.subHdrId;
        }
    }

    changeType(grp: GRP) {

        this.groupId = grp.id;


        // console.log(`il gruppo zona vale ${this.groupId}`);
    }

    ionViewDidEnter() {
        /*   let activeElement: any = document.activeElement;
           if (document.activeElement) {
               activeElement.blur();
           }

           let focusableEle = this._elementRef.nativeElement.querySelector('input,button');
           if (focusableEle) {
               focusableEle.focus();
           }*/
        this.enabled = true;
    }

    ionViewLoaded() {

        /*  this.buttons = this.buttons.map(button => {
              if (typeof button === 'string') {
                  return { text: button };
              }
              return button;
          });*/
    }


    ngOnInit() {
    }

    btnClick(button: any) {

        if (!this.enabled) {
            return;
        }

        if (button.handler) {
            // a handler has been provided, execute it
            // pass the handler the values from the inputs
            button.handler(this.getValues())
        }


    }

    @HostListener('body:keyup', ['$event'])
    public _keyUp(ev: KeyboardEvent) {
        ev.preventDefault();
        // console.log(ev.keyCode);

        if (this.enabled) {
            if (ev.keyCode === KEY_CODE.ENTER) {
                if (this.lastClick + 1000 < Date.now()) {
                    // do not fire this click if there recently was already a click
                    // this can happen when the button has focus and used the enter
                    // key to click the button. However, both the click handler and
                    // this keyup event will fire, so only allow one of them to go.
                    // console.debug('alert, enter button');
                    let button = this.buttons[this.buttons.length - 1];
                    this.btnClick(button);
                }

            } else if (ev.keyCode === KEY_CODE.ESCAPE) {
                // console.debug('alert, escape button');
                this.modalController.dismiss({
                    'toSave': false
                });
            }
        }
    }

    getValues() {

        return {groupId: this.groupId, name: this.name, email: this.email};

    }


    /* title: this.translate.instant('NEW_MACRO_ZONE'),
             message: this.translate.instant('NEW_MACRO_ZONE_TEXT'),
               buttons: [
                   {
                       text: this.translate.instant('CANCEL')
                   },
                   {
                     text: this.translate.instant('SAVE'),
                     handler:async data => {
                         let navTransition = popup.dismiss();
                         if(!data.name || !this.validMCZName(data.name)){
                            await this.presentToast(this.translate.instant('MCZ_USED_NAME'));
                             return false;
                         }
                         else
                         this.createNewMacroZone(data.name, data.groupId);
                     }

                   }
               ],

               */

}

let alertIds = -1;

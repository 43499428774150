import {Injectable} from '@angular/core';
import {NetworkWay} from '../models/http-status';
import {Action} from '@ngrx/store';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {map, catchError, mergeMap, switchMap, tap, concatMap, delay, first} from 'rxjs/operators';
import {Observable, of, from, EMPTY, timer, empty} from 'rxjs';
import {AppState} from '../services/app-state.service';
import {
    BackendCompliantRelease200,
    BackendNotCompliantRelease200,
    ConfigActionTypes,
    SaveConfig,
    ReloadConfigSuccess,
    DisconnectConfigSuccess,
    DisconnectConfig,
    DisconnectConfigCompleted,
    LogoutConfigSuccess,
    LogoutConfigCompleted,
    AppLoadConfig,
    AppLoadConfigSuccess,
    ChangeConfigValue,
    AppLoadConfigZeroConfSuccess, McuConfig
} from '../actions/config.actions';

import {
    CleanupTable,
    CleanupTableWhenChangeMcu,
    CleanupTableWhenDiscovery
} from '../actions/tables.actions';

import {AttributesActions} from '../actions/attributes.actions';
import {ConfigService} from '../services/config.service';
import {AttributesService} from '../services/attributes.service';
import {TablesService} from '../services/tables.service';
import {WindowRefService} from '../services/window.service';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {HttpStatusService} from '../services/http-status.service';
import {
    CommutationError,
    CommutationSuccess,
} from '../actions/http-status.actions';

import {WeatherService} from '../services/weather.service';
import {SocketService} from '../services/socket.service';
import {ScannerService} from "../services/scanner.service";
import {Config} from "../models/config";
import {SupportService} from "../services/support.service";
import {Network} from "@capacitor/network";
import {Platform} from '@ionic/angular';
import {BluetoothService} from "../services/bluetooth.service";
import {McuItem} from '../pages/mcu-select/mcu-select.page';

@Injectable()
export class ConfigEffects {

    constructor(public store: Store<AppState>,
                private router: Router,
                private socketService: SocketService,
                private actions$: Actions<AttributesActions>,
                private configService: ConfigService,
                private attributesService: AttributesService,
                private tablesService: TablesService,
                protected windowRefService: WindowRefService,
                public httpStatusService: HttpStatusService,
                private weatherService: WeatherService,
                private scannerService: ScannerService,
                private supportService: SupportService,
                private platform: Platform
    ) {
    }

    browserBuildEarlyNavigation(config: Config, isApp: boolean) {

        from(this.platform.ready()).pipe(first()).subscribe(() => {

            if (!isApp) {

                if (!config.currentUser) {

                    this.router.navigate(['/login']);

                } else if (!config.currentUser.username || config.currentUser.username == '') {

                    this.router.navigate(['/login']);

                } else if (!config.currentMcu) {

                    this.router.navigate(['/mcu-select']);

                } else {

                    this.router.navigate(['/home']);
                }
            }
        });
    }


    @Effect()
    appload$: Observable<any> = this.actions$.pipe(
        ofType(ConfigActionTypes.AppLoadConfig),

        mergeMap(action => {

                const appLoadConfigAction = action as AppLoadConfig;

                return this.configService.getConfig().pipe(
                    map((data) => {

                        this.browserBuildEarlyNavigation(data, appLoadConfigAction.isApp);

                        if (appLoadConfigAction.isApp && data.useLocalSystemAtStartup && !BluetoothService.isConnected) {

                            return new AppLoadConfigZeroConfSuccess(data);

                        } else {

                            return new AppLoadConfigSuccess(data);
                        }
                    })
                )
            }
        )
    );

    @Effect()
    reloadConfig$: Observable<any> = this.actions$.pipe(
        ofType(ConfigActionTypes.ReloadConfig),
        tap(() => {
            // console.log('%cReloadConfig','font-size:1.5rem;color:BlueViolet;')
        }),

        mergeMap(action => {

            return this.configService.getConfig().pipe(
                tap((tapData) => {
                    // console.log('%cReloadConfig tap, config che arriva da configService.getConfig() vale ','font-size:1.1rem;color:BlueViolet;');
                    // console.log(tapData);
                }),
                // If successful, dispatch success action with result
                map(data => new ReloadConfigSuccess(data))
            )
        })
    );

    @Effect()
    appLoadConfigZeroConfSuccess$: Observable<any> = this.actions$.pipe(
        ofType(ConfigActionTypes.AppLoadConfigZeroConfSuccess),

        tap((dataLoaded: AppLoadConfigZeroConfSuccess) => {

            const config: Config = dataLoaded.data;

            const mcuCodes = [];

            // If I'm not logged-in the currentUser is null
            if (config.currentUser) {
                const allMcuArrays: McuItem[][] = [
                config.currentUser.userOf || [],
                config.currentUser.guestOf || [],
                config.currentUser.masterOf || [],
                config.currentUser.residenceOf || [],
                config.currentUser.serviceOf || []
              ];

                allMcuArrays.forEach(mcuArray => {
                    mcuArray.forEach(mcu => {
                        if (!mcuCodes.includes(mcu.code)) {
                            mcuCodes.push(mcu.code);
                        }
                    });
                });
            }

            // console.log(mcuCodes);
            Network.getStatus().then((status) => {

                this.scannerService.checkNetworkStatus(dataLoaded.data, true, status, this.httpStatusService, this.router, true, mcuCodes);

                Network.addListener('networkStatusChange', (status) => {

                    this.scannerService.checkNetworkStatus(dataLoaded.data, false, status, this.httpStatusService, this.router, true, mcuCodes);
                });
            });
        }),

        mergeMap((value) => {

            return EMPTY;
        }),
    );

    @Effect()
    apploadConfigSuccess$: Observable<any> = this.actions$.pipe(
        ofType(ConfigActionTypes.AppLoadConfigSuccess),

        mergeMap(action => {

            return this.httpStatusService.probeCompliantRelease200().pipe(
                map((response) => {

                    return new BackendCompliantRelease200();
                }),

                catchError((error) => {

                    return of(new BackendNotCompliantRelease200());
                })
            )
        }),

        mergeMap(action => {

            this.store.dispatch(action);

            return this.httpStatusService.probeNetworkStatus().pipe(
                // If successful, dispatch success action with result
                map(data => new CommutationSuccess(data as NetworkWay)),

                // If request fails, dispatch failed action
                catchError((error) => of(new CommutationError(NetworkWay.Remote)))
            )
        })
    );

    @Effect()
    changeValue$: Observable<any> = this.actions$.pipe(
        ofType(ConfigActionTypes.ChangeConfigValue),
        tap(action => {
            if (((action as unknown) as ChangeConfigValue).option == 'currentMcu' && ((action as unknown) as ChangeConfigValue).value == null) {
                this.socketService.closeSocket();
                // console.log(`%cAbort Open Socket!`,'font-size:1.25rem;color:brown;');
            }
            // console.log('change value action vale')
            // console.log(action);
        }),


        mergeMap(action => {

                return this.configService.saveConfig().pipe(
                    map(() => new SaveConfig())
                )
            }
        )
    );


    @Effect()
    login$: Observable<any> = this.actions$.pipe(
        ofType(ConfigActionTypes.LoginConfig),
        tap(() => {
            // console.log('%c[LoginConfigEffect]','font-size:1.5rem;color:DarkGoldenRod;');
        }),

        concatMap((data) => {
            // console.log("%c[LoginConfigEffect] chiamato effetto da LoginConfig loggo data che arriva da concatMap",'font-size:1.5rem;color:DarkGoldenRod;');
            // console.log(data);


            return this.configService.saveConfig().pipe(
                tap(() => {

                    // console.log("%c[LoginConfigEffect] Questo è il tap dopo configService.saveConfig(), che restituisce null, da qui o vado su mcu-select o su home ",'font-size:1.5rem;color:DarkGoldenRod;');
                    if (this.windowRefService.nativeWindow['ENVIRON'] != 'mcu')
                        this.router.navigate(['/mcu-select'])
                    else
                        this.router.navigate(['/home'])


                }),

                map(() => new SaveConfig())
            );


        }),
        concatMap(action => {

            // console.log('merge map e faccio dispatch di SaveConfig da LoginConfig effect')
            // console.log(action);
            //faccio dispatch di CleanupTableWhenChangeMcu che mi arriva sopra
            this.store.dispatch(action);
            return timer(5000).pipe(
                switchMap((t) => {
                    return this.httpStatusService.probeCompliantRelease200().pipe(
                        map(() => new BackendCompliantRelease200()),
                        catchError(err => of(new BackendNotCompliantRelease200()))
                    );
                }));
        })
    );


    @Effect()
    backendCompliantRelease200$: Observable<any> = this.actions$.pipe(
        ofType(ConfigActionTypes.BackendCompliantRelease200),

        mergeMap(action => {

                return this.configService.saveConfig().pipe(
                    map(() => new SaveConfig())
                )
            }
        )
    );


    @Effect()
    discvoeryLogin$: Observable<any> = this.actions$.pipe(
        ofType(ConfigActionTypes.DiscoveryLoginConfig),
        tap(() => {

            console.log('DiscoveryLoginConfig');
            this.weatherService.reset();

            // console.log('%c[DiscoveryLoginConfigEffect]','font-size:1.5rem;color:DarkGoldenRod;');
        }), mergeMap((data) => {

            this.supportService.resetCurrentDateTime();

            return this.configService.saveConfig().pipe(
                map(() => new CleanupTableWhenDiscovery()),
                catchError(err => of(new CleanupTableWhenDiscovery()))
            )
        }),

        concatMap((action) => {
            // console.log("%c[DiscoveryLoginConfigEffect] chiamato effetto da DiscoveryLoginConfig loggo data che arriva da concatMap",'font-size:1.5rem;color:DarkGoldenRod;');
            this.store.dispatch(action);

            return timer(5000).pipe(
                tap(() => {

                    // console.log("%c[DiscoveryLoginConfigEffect] Questo è il tap dopo configService.saveConfig(), che restituisce null, da qui o vado su mcu-select o su home ",'font-size:1.5rem;color:DarkGoldenRod;');
                    if (this.windowRefService.nativeWindow['ENVIRON'] != 'mcu')
                        this.router.navigate(['/mcu-select'])
                    else
                        this.router.navigate(['/home'])


                }),

                switchMap((t) => {
                    return this.httpStatusService.probeCompliantRelease200().pipe(
                        map(() => new BackendCompliantRelease200()),
                        catchError(err => of(new BackendNotCompliantRelease200()))
                    );
                }));


        })
    );


    @Effect()
    updateUser$: Observable<any> = this.actions$.pipe(
        ofType(ConfigActionTypes.UpdateUser),
        tap(() => {
            // console.log('update user effect')
        }),


        switchMap((data) => {

                return this.configService.saveConfig().pipe(
                    map(() => new SaveConfig())
                );

            }
        )
    );

// questa mi serve quando chiamo LocingConfig da menu-mcu-select

    @Effect()
    loginMenuMcuSelect$: Observable<any> = this.actions$.pipe(
        ofType(ConfigActionTypes.LoginConfigMenuMcuSelect),
        // tap(data => console.log("chiamato effetto da LoginConfigMenuMcuSelect", data)),

        mergeMap((data) => {
            //console.log("chiamato effetto da LoginConfig", action);
            return this.configService.saveConfig().pipe(
                tap(() => this.router.navigate(['/menu-mcu-select'])),
                // tap(data =>console.log("chiamato effetto da LoginConfig", data) ),
                map(() => new SaveConfig())
            );

        }),
        mergeMap(action => {
            // console.log('merge map e faccio dispatch di SaveConfig')
            // console.log(action);
            //faccio dispatch di CleanupTableWhenChangeMcu che mi arriva sopra
            this.store.dispatch(action);

            return timer(5000).pipe(
                switchMap((t) => {
                    return this.httpStatusService.probeCompliantRelease200().pipe(
                        map(() => new BackendCompliantRelease200()),
                        catchError(err => of(new BackendNotCompliantRelease200()))
                    );
                }));

        })
    );


    @Effect()
    logout$: Observable<any> = this.actions$.pipe(
        ofType(ConfigActionTypes.LogoutConfig),
        tap(() => {
            // console.log('logout effect')
        }),

        mergeMap(action => {

                return this.configService.logout().pipe(
                    // If successful, dispatch success action with result
                    // tap(() => console.log('configService logout eseguita')),
                    map(() => new LogoutConfigSuccess()),
                    catchError(err => of(new LogoutConfigSuccess()))
                )
            }
        )
    );


    @Effect()
    logoutSuccess$: Observable<any> = this.actions$.pipe(
        ofType(ConfigActionTypes.LogoutConfigSuccess),
        tap(() => {
            // console.log('loug success effect')
        }),
        mergeMap(action => {

                return this.configService.saveConfig().pipe(
                    tap(() => { /*console.log(' router navigate login')*/
                        this.router.navigate(['/login'])
                    }),
                    map(() => new LogoutConfigCompleted())
                );
            }
        )
    );


    //gestione disconnessione da modalità connessione locale diretta


    @Effect()
    disconnect$: Observable<any> = this.actions$.pipe(
        ofType(ConfigActionTypes.DisconnectConfig),
        tap(() => {
            // console.log('disconnect effect')
        }),

        mergeMap(action => {

                const disconnectConfigAction = action as DisconnectConfig;

                return this.configService.disconnect().pipe(
                    // If successful, dispatch success action with result
                    // tap(() => console.log('configService disconnect eseguita')),
                    map(() => new DisconnectConfigSuccess(disconnectConfigAction.zeroConfFromGuest)),
                    catchError(err => of(new DisconnectConfigSuccess(disconnectConfigAction.zeroConfFromGuest)))
                )
            }
        )
    );


    @Effect()
    disconnectSuccess$: Observable<any> = this.actions$.pipe(
        ofType(ConfigActionTypes.DisconnectConfigSuccess),
        tap(() => {
            // console.log('disconnect success effect')
        }),
        mergeMap(action => {

                const disconnectConfigSuccessAction = action as DisconnectConfigSuccess;

                return this.configService.saveConfig().pipe(
                    tap(() => {

                        if (disconnectConfigSuccessAction.zeroConfFromGuest) {

                            this.router.navigate(['/login'])
                        } else {

                            this.router.navigateByUrl('/mcu-select');
                        }

                    }),
                    map(() => new DisconnectConfigCompleted())
                );
            }
        )
    );


    @Effect()
    changeMcu$: Observable<any> = this.actions$.pipe(
        ofType(ConfigActionTypes.McuConfig),

        tap(() => {

            this.weatherService.reset(true);
            // console.log('change mcu effect');
            //this.store.dispatch(new CleanupTable());
            //console.log('___new CleanupTable()_____');
        }),

        mergeMap(action => {

            const mcuConfig = action as McuConfig;

            if (mcuConfig.resetCurrentDateTime) {

                this.supportService.resetCurrentDateTime();
            }

            //console.log('mergeMap e lancio saveconfig, action vale')
            //console.log(action)

            return this.configService.saveConfig().pipe(
                map(() => new CleanupTableWhenChangeMcu()),
                catchError(err => of(new CleanupTableWhenChangeMcu()))
            )
        }),
        mergeMap(action => {
            // console.log('merge map e faccio dispatch di CleanupTableWhenChangeMcu per poi lanciare la backendCompliant/not action vale')
            // console.log(action);
            //faccio dispatch di CleanupTableWhenChangeMcu che mi arriva sopra

            this.store.dispatch(action);

            return timer(5000).pipe(
                switchMap((t) => {
                    return this.httpStatusService.probeCompliantRelease200().pipe(
                        map(() => new BackendCompliantRelease200()),
                        catchError(err => of(new BackendNotCompliantRelease200()))
                    );
                }));
        })
    );

    @Effect()
    configMode$: Observable<any> = this.actions$.pipe(
        ofType(ConfigActionTypes.ModeConfig),
        tap(() => {
            // console.log('config mode effect')
        }),

        mergeMap(action => {

                return this.attributesService.reload().pipe(
                    map(() => new CleanupTable()),
                    catchError(err => of(new CleanupTable()))
                    //questo cathError l'ho aggiunto io marx86
                )
            }
        )
    );

    @Effect()
    saveAttributesConfig: Observable<any> = this.actions$.pipe(
        ofType(ConfigActionTypes.SaveAttributesConfig),

        mergeMap(action => {

                return this.configService.saveConfig().pipe(
                    map(() => new SaveConfig())
                );
            }
        )
    );

    @Effect()
    SaveAppCachedVersionConfig: Observable<any> = this.actions$.pipe(
        ofType(ConfigActionTypes.SaveAppCachedVersionConfig),

        mergeMap(action => {

                return this.configService.saveConfig().pipe(
                    map(() => new SaveConfig())
                );
            }
        )
    );
}

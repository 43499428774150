
export class Socket {

    constructor(
       public error: any = null,
       public loadedTables: string[] = [],
       public connected: boolean = false,
    ) {}

}

import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {ActionSheetController, AlertController, Platform} from '@ionic/angular';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {Socket} from '../../models/socket';
import {interval, Observable, of, Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {Config, DUAL_MODE, PlatformMode} from '../../models/config';
import {NetworkWay} from '../../models/http-status';
import {AppState} from '../../services/app-state.service';
import {AbstractBaseComponent} from '../abstract-base-component';
import {FNC, FSM, MCU, ZON} from '../../models/tables';
import {FNC_FSM} from '../../commons/fsm';
import {FlagsALR, FlagsBL, FlagsCH, FlagsDHW, FlagsENR, FlagsES, FlagsExhaustAirExtraction, FlagsFC, FlagsFS, FlagsFT, FlagsFWA, FlagsGEN, FlagsGroup, FlagsHP, FlagsHRV, FlagsINT, FlagsNTD, FlagsPMP, FlagsSB, FlagsSEA, FlagsSM, FlagsTH, FlagsTNK, FlagsWND} from '../../commons/flags';
import {BluetoothService} from "../../services/bluetooth.service";
import {MCZ_Extended, RMS_Extended, ZON_Extended} from "../../models/tables-extended";
import {ShowPowerOff} from "../tech/tech.component";
import {AlignIconToRadioButton} from "../../models/enum-misc";

/**
 * Show the status icons
 */
@Component({
    selector: 'icons',
    templateUrl: './icons.component.html',
    styleUrls: ['./icons.component.scss'],
})

export class IconsComponent extends AbstractBaseComponent implements OnChanges {


    socketSub: Subscription;
    socketObs: Observable<Socket>;

    @Output()
    alertEvent: EventEmitter<any> = new EventEmitter();

    @Output()
    numberEmitter: EventEmitter<number> = new EventEmitter<number>();

    @Input()
    status: number[] | 0;

    @Input()
    logIcons: boolean;

    @Input()
    config: Config;

    @Input()
    modality: NetworkWay;

    @Input()
    onlyAlarms: boolean;

    @Input()
    onlyHum: boolean;

    @Input()
    onlyInt: boolean;

    @Input()
    onlyHrv: boolean;

    @Input()
    onlyNtd: boolean;

    @Input()
    onlyEs: boolean;

    @Input()
    FNC: FSM[] | FNC[] = [];

    @Input()
    rms_extended: RMS_Extended | MCZ_Extended;

    @Input()
    ZON: ZON[] = [];

    @Input()
    zon: ZON_Extended;

    @Input()
    MCU_Par_On: boolean; // Used for icons that don't use FLAGS to show if they are disabled, and need to be displayed as disabled because MCU is OFF (for example: setpoint override)

    @Input()
    showPowerOff: ShowPowerOff; // If I pass this input (which is a class), and the property inside "MCU_Par_On" is false -> I want to show the power-off icon instead of the status icons

    @Input()
    manualIcons: string[] = []; // If I want to manually pass any icon I want as a string in a tech component -> I can do it here (used for adding the group circle icon in the Heat Pumps section only, because it can't be extracted from the flags)

    @Input()
    hasBlackBackground: boolean; // Used for the <icon-hrv> component, so I can change the SVG based if I have a white or black background

    @Input()
    forceShowIconIfDisabled: boolean;

    // Used for "onlyNtd", onlyHum, onlyHrv
    @Input()
    alignIconToRadioButton: AlignIconToRadioButton = AlignIconToRadioButton.none; // Fixes the dehumidification icon not aligned with radio buttons (Android and Web fixes is slightly different from the iOS one)

    public AlignIconToRadioButton = AlignIconToRadioButton;

    FNC_FSM = FNC_FSM;

    FlagsGroup = FlagsGroup;
    FlagsES = FlagsES;
    FlagsTH = FlagsTH;
    FlagsSB = FlagsSB;
    FlagsHRV = FlagsHRV;
    FlagsNTD = FlagsNTD;
    FlagsINT = FlagsINT;
    FlagsWND = FlagsWND;
    FlagsDHW = FlagsDHW;
    FlagsENR = FlagsENR;
    FlagsALR = FlagsALR;
    FlagsSEA = FlagsSEA;
    FlagsGEN = FlagsGEN;
    FlagsPMP = FlagsPMP;
    FlagsTNK = FlagsTNK;
    FlagsFS = FlagsFS;
    FlagsFT = FlagsFT;
    FlagsFC = FlagsFC;
    FlagsSM = FlagsSM;
    FlagsBL = FlagsBL;
    FlagsCH = FlagsCH;
    FlagsHP = FlagsHP;
    FlagsFWA = FlagsFWA;
    FlagsExhaustAirExtraction = FlagsExhaustAirExtraction

    connected: boolean;
    computeSub: Subscription;

    @Input()
    MCU: MCU;


    constructor(protected store: Store<AppState>,
                protected platform: Platform,
                protected alertController: AlertController,
                protected actionSheetController: ActionSheetController,
                protected translate: TranslateService,
                public bluetoothService: BluetoothService) {
        super(store, platform, alertController, actionSheetController, translate);


        this.connected = false;


        this.socketObs = store.select('socket') as Observable<Socket>;
        this.socketSub = this.socketObs.subscribe(data => {
            this.connected = data.connected;
        });


        if (!status || status == "")
            this.status = new Array(21).fill(0)
    }


    isFncFSMOn() {
        let on = false;
        if (this.FNC != undefined)
            for (let i = 0; i < this.FNC.length; i++) {
                if (this.FNC[i].RTT_FsmState == FNC_FSM.ON || (this.FNC[i] as FNC).RTU_ElectricHeaterReq)
                    return true;
            }
        return on;
    }


    computeNIcons() {

        return interval(1000).pipe(switchMap(e => {

            let count = 0;

            if (Array.isArray(this.status)) {

                for (let i = 0; i < this.status.length; i++) {

                    if (this.status[i] != 0) {

                        count++;
                    }
                }
            }

            //console.log(`count vale ${count}`)
            return of(count);
        }))
    }

    showIconInternet() {
        if (this.config != undefined) {
            if (this.config.platform == PlatformMode.App && this.modality == NetworkWay.Remote)
                return true;


        }

        return false;
    }

    showIconLAN() {

        if (this.config && this.config.platform === PlatformMode.App && (this.modality === NetworkWay.LAN || this.modality === NetworkWay.DISCOVERY)) {

            return true;
        }

        return false;
    }

    showIconBluetooth() {

        if (this.config && this.config.platform === PlatformMode.App && this.modality == NetworkWay.BLUETOOTH) {

            return true;
        }
        return false;
    }

    zonesTurnedOFF(isRadiantFloor = false) {

        // -------------------------------------------------------------------------------------------------------------

        const zonIsTurnedOff = (zon: ZON) => {

            if (isRadiantFloor && zon.CFG_Floor && !zon.PAR_On) {

                return true;
            }

            if (!isRadiantFloor && !zon.CFG_Floor && !zon.PAR_On) {

                return true;
            }

            return false;
        }

        // -------------------------------------------------------------------------------------------------------------

        // If I just want to check a single zon (for example when I'm checking the radiant icon inside system-zone)
        if (this.zon) {

            return zonIsTurnedOff(this.zon);
        }

        // If instead of a zon I passed a ZON input -> I want to check if every ZON (grouped as floor or ceiling) is disabled
        else {

            for (const zon of this.ZON) {

                // In this case (when I display the radiant icon in the zone page of macro-zones page) -> I want to show the OFF icon ONLY if every ZON is OFF
                // So, if I find only 1 ZON turned on, I return false (so I won't display a slashed turned off icon)
                if (!zonIsTurnedOff(zon)) {

                    return false;
                }
            }

            return this.ZON.length > 0; // When I'm loading a new system, "this.ZON" is [], so I don't want to show the icon as slashed while the system is still loading
        }
    }

    fireEvent() {
        this.alertEvent.emit();
    }

    ngOnDestroy() {
        //console.error('icons ngOnDestroy');
        if (this.socketSub != undefined) {
            this.socketSub.unsubscribe();
        }
        if (this.computeSub != undefined)
            this.computeSub.unsubscribe()
    }

    ngOnInit() {

        //console.log('ngOnInit sottoscrive computeNIcons()')

        this.computeSub = this.computeNIcons().subscribe((c: number) => {
            //console.log(`icons emette ${c}`);
            this.numberEmitter.emit(c);
        });

        if (!this.MCU) {

            console.warn('Attenzione: Manca MCU!', this.MCU);
        }

        /* if(this.logIcons){
             console.log("status");
             console.log(this.status);
             console.log('FlagsGroup.HRV');
             console.log(this.FlagsGroup.HRV);
             console.log('FlagsGroup');
             console.log(this.FlagsGroup);
             console.log('FlagsHRV');
             console.log(this.FlagsHRV);

             console.log('status[FlagsGroup.HRV] == FlagsHRV.OFF');
             console.log(this.status[this.FlagsGroup.HRV] == this.FlagsHRV.OFF);
             console.log('onlyHrv');
             console.log(this.onlyHrv);
             console.log('!onlyAlarms && !(onlyEs || onlyInt || onlyHrv || onlyHum || onlyNtd)');
             console.log(!this.onlyAlarms && !(this.onlyEs || this.onlyInt || this.onlyHrv || this.onlyHum || this.onlyNtd));
             console.log(`onlyAlarms ${this.onlyAlarms}`);
             console.log(`onlyEs ${this.onlyEs}`);
             console.log(`onlyInt ${this.onlyInt}`);
             console.log(`onlyHrv ${this.onlyHrv}`);
             console.log(`onlyHum ${this.onlyHum}`);
             console.log(`onlyNtd ${this.onlyNtd}`);

         }*/

    }

    ngOnChanges(): void {
    }

    overrideIconIsPresent() {

        const isAutoModeStyleDualSetpoint = this.rms_extended.isAutoModeStyleDualSetpoint();

        if (isAutoModeStyleDualSetpoint) {

            return this.rms_extended.getSetpointOverrideInfo(null, DUAL_MODE.heating).isOverride ||  this.rms_extended.getSetpointOverrideInfo(null, DUAL_MODE.cooling).isOverride;
        }

        else {

            return this.rms_extended.getSetpointOverrideInfo().isOverride
        }
    }
}

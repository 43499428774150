import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';



import { PopoverPageSCH } from './popover-page-sch';

import {ComponentsModule} from '../../components/components.module';
import { TranslateModule } from '@ngx-translate/core';
import {PipesModule} from '../../pipes/pipes.module';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ComponentsModule,
    PipesModule
   
  
  ],
  declarations: [PopoverPageSCH],
  exports:[PopoverPageSCH],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class PopoverPageSCHModule {}

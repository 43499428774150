import {ConfigActionTypes, ConfigActions} from '../actions/config.actions';
import {Config, EnvironmentType} from '../models/config';
import {User} from '../models/user';
import * as _ from 'lodash';

const initialState: Config = new Config();

/**
 * Change the config
 * (state management)
 *
 * Reducers are the foundation of any Store or Redux-based application, describing
 * sections of state and their potential transformations based on dispatched
 * action types. It is the combination of your reducers that makes up a
 * representation of application state at any given time.
 *
 * A reducer is a pure function, accepting two arguments, the previous state
 * and an action with a type and optional data (payload) associated with the
 * event.
 */
export function configReducer(state: Config = initialState, action: ConfigActions): Config {

    var newState: Config;

    switch (action.type) {

        case  ConfigActionTypes.AppLoadConfig: {
            // console.log('AppLoadConfig Reducer lo stato vale:')
            // console.log(state);

            return {
                ...state,
                loading: true,
                remoteTimeoutDispatched: false
            };
        }
        case  ConfigActionTypes.BackendCompliantRelease200: {
            // console.log('Backend Compliant Release 2.0.0')
            return {
                ...state,
                backendCompliantRelease200: true
            };
        }
        case  ConfigActionTypes.BackendNotCompliantRelease200: {
            // console.log('Backend Not Compliant Release 2.0.0')
            return {
                ...state,
                backendCompliantRelease200: false
            };
        }

        case ConfigActionTypes.ReloadConfig: {
            // console.log('qualcuno chiama LoadConfig tramite ReloadConfig')
            return {
                ...state,
                loading: true
            };
        }
        case ConfigActionTypes.ReloadConfigSuccess: {
            // console.log('reducer ReloadConfigSuccess Reloading COMPLETATO')
            return {...state, ...action.data, loaded: true, loading: false, busy: false};
        }

        case ConfigActionTypes.AppLoadConfigSuccess: {

            return {...state, ...action.data, loaded: true, loading: false, busy: false};
        }

        case ConfigActionTypes.AppLoadConfigZeroConfSuccess: {

            return {...state, ...action.data, loaded: false, loading: true, busy: false};
        }

        case ConfigActionTypes.ChangeConfigValue: {
            newState = {...state};
            newState[action.option] = action.value;
            return newState;
        }

        case ConfigActionTypes.TimeoutConfig: {
            newState = {...state};
            newState.remoteTimeoutDispatched = true;
            return newState;
        }

        case ConfigActionTypes.LoginConfig: {
             console.log('reducer ConfigActionTypes.LoginConfig, currentUser vale')
             console.log(action.currentUser);
            newState = {...state, currentUser: action.currentUser, currentMcu: action.currentMcu};
            // console.log(`newState.environment:${newState.environment}, newState.currentMcu:${newState.currentMcu}, newState.baseUrl:${newState.baseUrl}, newState.portalUrl:${newState.portalUrl}`)
            // recalculate the baseUrl and admin
            newState.baseUrl = calculateBaseUrl(newState.environment, newState.currentMcu, newState.baseUrl, newState.portalUrl);
            newState.admin = calculateAdmin(newState.currentUser, newState.currentMcu);
            console.log(`PRIMA DEL RETURN`);
           console.log(`newState vale `);
           console.log(newState);
            // console.log(`newState.environment:${newState.environment}, newState.currentMcu:${newState.currentMcu}, newState.baseUrl:${newState.baseUrl}, newState.portalUrl:${newState.portalUrl}`)
            newState.remoteTimeoutDispatched = false;
            return newState;
        }

        case ConfigActionTypes.DiscoveryLoginConfig: {
            // console.log('reducer ConfigActionTypes.DiscoveryLoginConfig, currentUser vale')
            // console.log(action.currentUser.username);

            newState = _.cloneDeep(state);

            if (!action.fromAutoSwitch) {

                newState.currentUser = action.currentUser;
            }

            newState.currentMcu = action.currentMcu;
            newState.zeroConfFromGuest = !action.fromAutoSwitch;

            // console.log(`newState.environment:${newState.environment}, newState.currentMcu:${newState.currentMcu}, newState.baseUrl:${newState.baseUrl}, newState.portalUrl:${newState.portalUrl}`)
            // recalculate the baseUrl and admin
            newState.baseUrl = calculateBaseUrl(newState.environment, action.currentMcu, newState.baseUrl, newState.portalUrl);
            newState.admin = calculateAdmin(action.currentUser, action.currentMcu, newState.zeroConfFromGuest);
            // console.log(`PRIMA DEL RETURN`);
            // console.log(`newState.environment:${newState.environment}, newState.currentMcu:${newState.currentMcu}, newState.baseUrl:${newState.baseUrl}, newState.portalUrl:${newState.portalUrl}`)
            newState.remoteTimeoutDispatched = false;

            return newState;
        }

        case ConfigActionTypes.LoginConfigMenuMcuSelect: {
            newState = {...state, currentUser: action.currentUser, currentMcu: action.currentMcu};
            // recalculate the baseUrl and admin
            newState.baseUrl = calculateBaseUrl(newState.environment, newState.currentMcu, newState.baseUrl, newState.portalUrl);
            newState.admin = calculateAdmin(newState.currentUser, newState.currentMcu);
            newState.remoteTimeoutDispatched = false;
            return newState;
        }


        case ConfigActionTypes.UpdateUser: {
            newState = {...state, currentUser: action.currentUser};
            // console.log('reducer ConfigActionTypes.UpdateUser il nuovo stato sarà')
            // console.log(newState)

            return newState;
        }

        case ConfigActionTypes.DisconnectConfig: {

            newState = _.cloneDeep(state);

            if (action.zeroConfFromGuest) {

                newState.currentUser = null;
            }

            return newState;
        }

        case ConfigActionTypes.DisconnectConfigSuccess: {

            newState = _.cloneDeep(state);

            if (action.zeroConfFromGuest) {

                newState.currentUser = null;
                newState.currentMcu = null;
                newState.zeroConfFromGuest = false;
            }

            // recalculate the baseUrl and admin
            newState.baseUrl = calculateBaseUrl(newState.environment, newState.currentMcu, newState.baseUrl, newState.portalUrl);
            newState.admin = calculateAdmin(newState.currentUser, newState.currentMcu);
            return newState;
        }

        case ConfigActionTypes.LogoutConfig: {
            newState = {...state, currentUser: null};
            return newState;
        }

        case ConfigActionTypes.LogoutConfigSuccess: {
            newState = {...state, currentUser: null, currentMcu: null};
            // recalculate the baseUrl and admin
            newState.baseUrl = calculateBaseUrl(newState.environment, newState.currentMcu, newState.baseUrl, newState.portalUrl);
            newState.admin = calculateAdmin(newState.currentUser, newState.currentMcu);
            return newState;
        }

        case ConfigActionTypes.McuConfig: {
            // console.warn("McuConfig Reducer: lo stato attuale vale")
            // console.log(state);
            newState = {...state, currentMcu: action.mcuName};
            // recalculate the baseUrl and admin
            newState.baseUrl = calculateBaseUrl(newState.environment, newState.currentMcu, newState.baseUrl, newState.portalUrl);
            newState.admin = calculateAdmin(newState.currentUser, newState.currentMcu);
            // console.warn("McuConfig Reducer: cambio di mcu, reducer, il nuovo stato vale")
            // console.log(newState);
            newState.remoteTimeoutDispatched = false;
            return newState;
        }

        case ConfigActionTypes.BusyConfig: {
            newState = {...state, busy: true};
            return newState;
        }

        case ConfigActionTypes.IdleConfig: {
            newState = {...state, busy: false};
            return newState;
        }


        case ConfigActionTypes.ModeConfig: {
            // console.log('reducer ModeConfig, lo stato vale');
            // console.log(state);
            return {...state, loadExcludedKeys: true};
        }

        case ConfigActionTypes.SaveAttributesConfig: {

            newState = _.cloneDeep(state);
            newState.attributes = action.attributes;
            return newState;
        }

        case ConfigActionTypes.SaveAppCachedVersionConfig: {

            newState = _.cloneDeep(state);
            newState.appCurrentVersionCached = action.appVersion;
            return newState;
        }

        default:
            return state;
    }

}

/**
 * Calculate the base url for the given environment and MCU
 */
export function calculateBaseUrl(environment: EnvironmentType, currentMcu: string, baseUrl: string, portalUrl: string): string {
    // console.log('calculateBaseUrl()');
    // console.log(`environment vale ${environment}`);
    switch (environment) {
        case EnvironmentType.MCU:
            return baseUrl;
        case EnvironmentType.Portal:
            if (currentMcu) {
                // console.warn(`viene calcolata baseUrl e vale ${'/portal/api/apiproxy/' + currentMcu + '/'}`);
                return '/portal/api/apiproxy/' + currentMcu + '/';
            }
            break;
        case EnvironmentType.App:
        case EnvironmentType.IonicTest:
            if (currentMcu) {
                // console.warn(`viene calcolata baseUrl e vale ${portalUrl + '/portal/api/apiproxy/' + currentMcu + '/'}`)
                return portalUrl + '/portal/api/apiproxy/' + currentMcu + '/';
            }
            break;
    }
    // console.log(`calculateBaseUrl restituisce null perchè evidentemente currentMcu vale null: ${currentMcu}`);
    return null;
}

/**
 * Calculate if the logged user is admin of the selected MCU
 */
export function calculateAdmin(currentUser: User, currentMcu: string, zeroConfFromGuest: boolean = false): boolean {

    if (!currentUser || !currentMcu || zeroConfFromGuest) {

        console.log('sono nel return false');

        return false;

    } else {

        // Define which roles are considered admin roles
        const adminRoles = ['userOf', 'masterOf', 'residenceOf','serviceOf'];

        return adminRoles.some(role =>
          currentUser[role]?.some(x => x.code === currentMcu)
        );

        //return currentUser.userOf.find(x => x.code === currentMcu) !== undefined;
    }
}






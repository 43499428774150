<div class="d-flex dynamic-flex justify-content-center align-items-center">

    <!-- Overrides (normal) -->
    <show-zone-override [fromMacrozonePage]="true" *ngIf="!isAutoModeStyleDualSetpoint() && isOverride()" [rmsOrMczExtended]="macroZonePage?.mczExtended" [page]="macroZonePage" [dualSetpointMode]="null" [state]="macroZonePage?.state"></show-zone-override>

    <!-- Overrides heating (dual setpoint) -->
    <show-zone-override [fromMacrozonePage]="true" *ngIf="isAutoModeStyleDualSetpoint() && isOverride(DUAL_MODE.heating)" [rmsOrMczExtended]="macroZonePage?.mczExtended" [page]="macroZonePage" [dualSetpointMode]="DUAL_MODE.heating" [state]="macroZonePage?.state"></show-zone-override>

    <!-- Non overrides -->
    <counter class="setPointCounter" *ngIf="macroZonePage?.MCU?.TEC_SPMode && !isAutoModeStyleDualSetpoint() && !isOverride() && !macroZonePage?.mcz.PAR_SchedOnH"
             [context]="macroZonePage?.mcz"
             [min]="computeMin(0)" [max]="computeMax(0)" [large]="true" temperature
             table="MCZ" [changableSetpoint]="true"
             [item]="macroZonePage?.mcz" var="PAR_SetTempH" [inDisabled]="!macroZonePage?.state" [attrs]="macroZonePage?.attrs" [MCU]="macroZonePage?.MCU"
             (changeEvent)="macroZonePage?.onTemperatureChange(0)" [dueTime]="2000" itemClass="no-lines"
             [whileChange]="macroZonePage?.onChangeDualSetpoints"></counter>

    <counter class="setPointCounter" *ngIf="!macroZonePage?.MCU?.TEC_SPMode && ((macroZonePage?.season === 0 && !isAutoModeStyleDualSetpoint() && !isOverride()) || (isAutoModeStyleDualSetpoint() && !isOverride(DUAL_MODE.heating))) && !macroZonePage?.mcz.PAR_SchedOnH"
             [staticSeason]="0" [context]="macroZonePage?.mcz"
             [dualSetPoint]="macroZonePage?.isAutoModeStyleDualSetpoint()"
             [min]="computeMin(0)" [max]="computeMax(0)" [large]="true" temperature
             table="MCZ" [changableSetpoint]="true"
             [item]="macroZonePage?.mcz" var="PAR_SetTempH" [inDisabled]="!macroZonePage?.state" [attrs]="macroZonePage?.attrs" [MCU]="macroZonePage?.MCU"
             (changeEvent)="macroZonePage?.onTemperatureChange(0)" [dueTime]="2000" itemClass="no-lines"
             [whileChange]="macroZonePage?.onChangeDualSetpoints"></counter>

    <counter class="setPointCounter" *ngIf="!macroZonePage?.MCU?.TEC_SPMode && ((macroZonePage?.season === 1 && !isAutoModeStyleDualSetpoint() && !isOverride()) || (isAutoModeStyleDualSetpoint() && !isOverride(DUAL_MODE.cooling))) && !macroZonePage?.mcz.PAR_SchedOnC"
             [staticSeason]="1" [context]="macroZonePage?.mcz"
             [dualSetPoint]="macroZonePage?.isAutoModeStyleDualSetpoint()"
             [min]="computeMin(1)" [max]="computeMax(1)" [large]="true" temperature
             table="MCZ" [changableSetpoint]="true"
             [item]="macroZonePage?.mcz" var="PAR_SetTempC" [inDisabled]="!macroZonePage?.state" [attrs]="macroZonePage?.attrs" [MCU]="macroZonePage?.MCU"
             (changeEvent)="macroZonePage?.onTemperatureChange(1)" [dueTime]="2000" itemClass="no-lines"
             [whileChange]="macroZonePage?.onChangeDualSetpoints"></counter>

    <!-- Schedule On (not variable / dual variable) -->
    <counter class="setPointCounter" *ngIf="macroZonePage?.MCU?.TEC_SPMode && !isAutoModeStyleDualSetpoint() && !isOverride() && macroZonePage?.mcz.PAR_SchedOnH && macroZonePage?.targetSCH?.CFG_Type !== macroZonePage?.SchedulationType.Variable && macroZonePage?.targetSCH?.CFG_Type !== macroZonePage?.SchedulationType.DualVariable"
             [min]="computeMin(0)" [max]="computeMax(0)" [large]="true" temperature
             [context]="macroZonePage?.mcz" table="MCZ" [changableSetpoint]="true"
             [item]="macroZonePage?.mcz" var="PAR_SetTempH" [inDisabled]="!macroZonePage?.state" [attrs]="macroZonePage?.attrs" [MCU]="macroZonePage?.MCU"
             (changeEvent)="macroZonePage?.onTemperatureChange(0)" [dueTime]="2000" itemClass="no-lines"
             [whileChange]="macroZonePage?.onChangeDualSetpoints"></counter>

    <counter class="setPointCounter"
             *ngIf="!macroZonePage?.MCU?.TEC_SPMode && ((macroZonePage?.season === 0 && !isAutoModeStyleDualSetpoint() && !isOverride()) || (isAutoModeStyleDualSetpoint() && !isOverride(DUAL_MODE.heating))) && macroZonePage?.mcz.PAR_SchedOnH && macroZonePage?.targetSCH?.CFG_Type !== macroZonePage?.SchedulationType.Variable && macroZonePage?.targetSCH?.CFG_Type !== macroZonePage?.SchedulationType.DualVariable"
             [staticSeason]="0" [context]="macroZonePage?.mcz"
             [dualSetPoint]="macroZonePage?.isAutoModeStyleDualSetpoint()"
             [min]="computeMin(0)" [max]="computeMax(0)" [large]="true" temperature
             table="MCZ" [changableSetpoint]="true"
             [item]="macroZonePage?.mcz" var="PAR_SetTempH" [inDisabled]="!macroZonePage?.state" [attrs]="macroZonePage?.attrs" [MCU]="macroZonePage?.MCU"
             (changeEvent)="macroZonePage?.onTemperatureChange(0)" [dueTime]="2000" itemClass="no-lines"
             [whileChange]="macroZonePage?.onChangeDualSetpoints"></counter>

    <counter class="setPointCounter"
             *ngIf="!macroZonePage?.MCU?.TEC_SPMode && ((macroZonePage?.season === 1 && !isAutoModeStyleDualSetpoint() && !isOverride()) || (isAutoModeStyleDualSetpoint() && !isOverride(DUAL_MODE.cooling))) && macroZonePage?.mcz.PAR_SchedOnC && macroZonePage?.targetSCH?.CFG_Type !== macroZonePage?.SchedulationType.Variable && macroZonePage?.targetSCH?.CFG_Type !== macroZonePage?.SchedulationType.DualVariable"
             [staticSeason]="1" [context]="macroZonePage?.mcz"
             [dualSetPoint]="macroZonePage?.isAutoModeStyleDualSetpoint()"
             [min]="computeMin(1)" [max]="computeMax(1)" [large]="true" temperature
             table="MCZ" [changableSetpoint]="true"
             [item]="macroZonePage?.mcz" var="PAR_SetTempC" [inDisabled]="!macroZonePage?.state" [attrs]="macroZonePage?.attrs" [MCU]="macroZonePage?.MCU"
             (changeEvent)="macroZonePage?.onTemperatureChange(1)" [dueTime]="2000" itemClass="no-lines"
             [whileChange]="macroZonePage?.onChangeDualSetpoints"></counter>

    <!-- Variable Schedule On (Variable schedule can not be present if isAutoModeStyleDualSetpoint) -->
    <ng-container *ngIf="macroZonePage?.targetSCH?.CFG_Type === macroZonePage?.SchedulationType.Variable && !isAutoModeStyleDualSetpoint()">

        <counter class="setPointCounter" *ngIf="macroZonePage?.MCU?.TEC_SPMode && !isOverride() && macroZonePage?.mcz.PAR_SchedOnH" [large]="true"
                 temperature var="RTU_SetPoint" [changableSetpoint]="true"
                 [rtu]="true" [item]="macroZonePage?.mcz" [staticValue]="macroZonePage?.targetSCH?.RTU_SetPoint" [MCU]="macroZonePage?.MCU"
                 (changeEvent)="macroZonePage?.onTemperatureChange(0)" [dueTime]="2000"
                 itemClass="no-lines scheduled" [whileChange]="macroZonePage?.onChangeDualSetpoints"></counter>

        <counter class="setPointCounter" *ngIf="!macroZonePage?.MCU?.TEC_SPMode && macroZonePage?.season === 0 && !isOverride() && macroZonePage?.mcz.PAR_SchedOnH"
                 [staticSeason]="0" [dualSetPoint]="false" [large]="true"
                 temperature var="RTU_SetPoint" [changableSetpoint]="true"
                 [rtu]="true" [item]="macroZonePage?.mcz" [staticValue]="macroZonePage?.targetSCH?.RTU_SetPoint" [MCU]="macroZonePage?.MCU"
                 (changeEvent)="macroZonePage?.onTemperatureChange(0)" [dueTime]="2000"
                 itemClass="no-lines scheduled" [whileChange]="macroZonePage?.onChangeDualSetpoints"></counter>

        <counter class="setPointCounter" *ngIf="!macroZonePage?.MCU?.TEC_SPMode && macroZonePage?.season === 1 && !isOverride() && macroZonePage?.mcz.PAR_SchedOnC"
                 [staticSeason]="1" [dualSetPoint]="false" [large]="true"
                 temperature var="RTU_SetPoint" [changableSetpoint]="true"
                 [rtu]="true" [item]="macroZonePage?.mcz" [staticValue]="macroZonePage?.targetSCH?.RTU_SetPoint" [MCU]="macroZonePage?.MCU"
                 (changeEvent)="macroZonePage?.onTemperatureChange(1)" [dueTime]="2000"
                 itemClass="no-lines scheduled" [whileChange]="macroZonePage?.onChangeDualSetpoints"></counter>
    </ng-container>

    <!-- Dual Variable schedule (Dual Variable schedule is only present when isAutoModeStyleDualSetpoint) -->
    <ng-container *ngIf="macroZonePage?.targetSCH?.CFG_Type === macroZonePage?.SchedulationType.DualVariable && isAutoModeStyleDualSetpoint()">

        <counter *ngIf="macroZonePage?.mcz.PAR_SchedOnH && !isOverride(DUAL_MODE.heating)" class="setPointCounter" rtuClassColor="messana-orange"
                 [staticSeason]="0" [dualSetPoint]="true" [large]="true"
                 temperature var="RTU_SetPointH" [changableSetpoint]="true"
                 [rtu]="true" [item]="macroZonePage?.mcz" [staticValue]="macroZonePage?.targetSCH?.RTU_SetPointH" [MCU]="macroZonePage?.MCU"
                 (changeEvent)="macroZonePage?.onTemperatureChange(0)" [dueTime]="2000"
                 itemClass="no-lines scheduled" [whileChange]="macroZonePage?.onChangeDualSetpoints"></counter>

        <counter *ngIf="macroZonePage?.mcz.PAR_SchedOnC && !isOverride(DUAL_MODE.cooling)" class="setPointCounter" rtuClassColor="messana-blue"
                 [staticSeason]="1" [dualSetPoint]="true" [large]="true"
                 temperature var="RTU_SetPointC" [changableSetpoint]="true"
                 [rtu]="true" [item]="macroZonePage?.mcz" [staticValue]="macroZonePage?.targetSCH?.RTU_SetPointC" [MCU]="macroZonePage?.MCU"
                 (changeEvent)="macroZonePage?.onTemperatureChange(1)" [dueTime]="2000"
                 itemClass="no-lines scheduled" [whileChange]="macroZonePage?.onChangeDualSetpoints"></counter>
    </ng-container>

    <!-- Overrides cooling (dual setpoint) -->
    <show-zone-override [fromMacrozonePage]="true" *ngIf="isAutoModeStyleDualSetpoint() && isOverride(DUAL_MODE.cooling)" [rmsOrMczExtended]="macroZonePage?.mczExtended" [page]="macroZonePage" [dualSetpointMode]="DUAL_MODE.cooling" [state]="macroZonePage?.state"></show-zone-override>
</div>

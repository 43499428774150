import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
    transform(items: any[], field : string, value : string, query: string): any[] {
        if (!items) return [];
        return items.filter(it => it[field] == value && (!query || (it.Name || it.var).toLowerCase().indexOf(query.toLowerCase()) > -1));
    }

}

<ion-item class="item no-lines">

  <div class="d-flex justify-content-between align-items-center w-100 ">

    <div class="d-flex flex-row justify-content-start align-items-center">
      <div>
        <span>{{ label | translate }}</span>
      </div>
    </div>

    <div>
      <ion-toggle [disabled]="disabled" *ngIf="!bitArray" color="secondary" [checked]="item[property]" (ionChange)="onToggle($event)"></ion-toggle>
      <ion-toggle [disabled]="disabled" *ngIf="bitArray" color="secondary" [checked]="bitArray.get(bitArrayPosition)" (ionChange)="onToggleBitArray($event)"></ion-toggle>
    </div>
  </div>
</ion-item>
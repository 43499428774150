import { Component, OnInit,Input } from '@angular/core';
import { Table } from 'src/app/models/tables';

@Component({
  selector: 'related-entities',
  templateUrl: './related-entities.component.html',
  styleUrls: ['./related-entities.component.scss'],
})
export class RelatedEntitiesComponent implements OnInit {

  @Input() show:boolean;
  @Input() entity:Array<Table>;

  @Input() noLabel:string;
  @Input() singleLabel:string;
  @Input() pluralLabel:string;
  @Input() iconInput:string;
  @Input() showAll:boolean;

  constructor() { }

  ngOnInit() {}

}

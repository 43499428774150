export const AUTH_TRANSLATIONS_IT = {
  "AUTH_ERROR_TITLE": "Errore di autenticazione.",
  "INVALID_CREDENTIALS": "Credenziali non valide.",
  "MISSING_CREDENTIALS": "Credenziali mancanti.",
  "AUTHENTICATION_FAILED": "Nome utente o password errati.",
  "NO_USER_FOUND": "Nessun utente trovato con il nome utente o l'indirizzo e-mail fornito.",
  "INVALID_OR_EXPIRED": "Codice non valido o scaduto.",
  "INVALID_EMAIL_ADDRESS_FORMAT": "Formato dell'indirizzo e-mail non valido.",
  "VALIDATION_SUCCESSFUL": "Il tuo codice è stato convalidato con successo.",
  "MASTER_STATUS_VALIDATED_REGISTERED_SUCCESS": "La tua registrazione come utente master è completa.",
  "MASTER_STATUS_VALIDATED_REGISTERED_FAILURE": "Abbiamo riscontrato un problema e non siamo riusciti a completare la tua registrazione come utente master.",
  "CHECK_SYNCH_MASTER_EXCEPTION": "Ops! Si è verificato un problema durante la validazione del tuo stato master.",
  "EMAIL_UNDEF_ON_CODE_VALIDATION": "Impossibile inviare l'e-mail, il tuo indirizzo e-mail è indefinito, visita https://messana.tech/open-a-support-case/",
  "EMAIL_NOT_SENT": "Errore generico: impossibile inviare l'e-mail.",
  "GENERAL_FAILURE": "Ops! Qualcosa è andato storto, visita https://messana.tech/open-a-support-case/ per segnalare il problema.",
  "TRY_LATER": "Troppi tentativi falliti. Riprova tra 5 minuti.",
  "TOO_MANY_ATTEMPTS": "Troppi tentativi falliti.",
  "REMAINING_SECONDS": "secondi rimanenti",
  "EXCEPTION_ON_VALIDATION": "Si è verificata un'eccezione durante il processo di convalida del codice."
}

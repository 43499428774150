import {Injectable} from '@angular/core';
import * as _ from 'lodash';

import {Observable, of, from, EMPTY, throwError} from 'rxjs';
import {Store} from '@ngrx/store';
import {Storage} from '@ionic/storage';
import {Platform} from '@ionic/angular';
import {map, catchError, switchMap, take, tap} from 'rxjs/operators';
import {interval} from 'rxjs';

import {User} from '../models/user';
import {ConfigActions, AppLoadConfig, ChangeConfigValue} from '../actions/config.actions';

import {
    HttpInterceptor, HttpRequest, HttpResponse,
    HttpHandler, HttpEvent, HttpErrorResponse, HttpHeaders
} from '@angular/common/http';


import {HttpClient} from '@angular/common/http';

import {Config, EnvironmentType, PORTAL_URL, PlatformMode} from '../models/config';
import {AppState} from './app-state.service';
import {AuthHttpService} from './auth-http.service';
import {WindowRefService} from '../services/window.service';

import {handleError} from './support.service';
import {BluetoothService} from "./bluetooth.service";
import {HttpStatusService} from "./http-status.service";

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    private observable: Observable<Config>;
    private cache: Config = {} as Config;
    // private storage: LocalStorage;
    private baseUrl: string;
    private window;

    constructor(private http: HttpClient,
                protected store: Store<AppState>,
                protected platform: Platform,
                private storage: Storage,
                protected windowRefService: WindowRefService,
                private bluetoothService: BluetoothService,
                private httpStatusService: HttpStatusService) {

        this.window = this.windowRefService.nativeWindow;
        //this.storage = new LocalStorage(Storage);

        // Create a cache for the loaded config
        this.observable = store.select('config') as Observable<Config>;

        //pipe(switchMap(data=>this.slowDown(data)))
        this.observable.subscribe(data => {
            // console.log('config service, arrivato subscribe data vale')
            // console.log(data);
            for (let key in data) {

                this.cache[key] = data[key];
            }
        })
    }


    slowDown(data) {
        return interval(5000).pipe(
            map(i => data),
            take(1));
    }


    private getEnvironment(): EnvironmentType {
        if (this.window['ENVIRON'] == 'test') {
            // console.log('Environment = Test');
            return EnvironmentType.Test;
        } else if (this.window['LiveReloadPlugin_IonicGoToUrl'] !== undefined) {
            // console.log('Environment = IonicTest');
            return EnvironmentType.IonicTest;
        } else if (this.window['ENVIRON'] == 'portal') {
            // console.log('Environment = Portal');
            return EnvironmentType.Portal;
        } else if (this.window['ENVIRON'] == 'app') {
            // console.log('Environment = App');
            return EnvironmentType.App;
        } else {
            // console.log('Environment = MCU');
            return EnvironmentType.MCU;
        }
    }

    /**
     * Load the attributes
     */
    getConfig(): Observable<Config> {
        return from(this.platform.ready()).pipe(
            switchMap(plat => {
                let _platform: PlatformMode;

                if (this.platform.is("android")) {

                    this.cache['isAndroid'] = true;
                }

                if (this.platform.is("ios")) {

                    this.cache['isIos'] = true;
                }

                if (this.platform.is("cordova") || this.platform.is("capacitor")) {

                    _platform = PlatformMode.App;
                }

                else {

                    _platform = PlatformMode.Browser;
                }

                const zeroConfFromGuest = this.cache.zeroConfFromGuest;
                const remoteTimeoutDispatched = this.cache.remoteTimeoutDispatched;

                // Return the table from the cache if it is already loaded
                if (this.cache.loaded) {

                    if (this.cache.baseUrl == null || this.cache.baseUrl == '' || this.cache.baseUrl == undefined) {

                        this.cache.loaded = false;

                    } else if (this.cache.baseUrl.length == 0) {

                        this.cache.loaded = false;
                    }

                    this.cache['platform'] = _platform;
                    this.cache.admin = BluetoothService.isConnected ? true : this.cache.admin; // When I'm using Bluetooth --> I always want to be an admin of my local system

                    if (this.cache.loaded) {
                        // console.warn('ho preso config da  this.cache');
                        // console.log(`config.baseUrl ${this.cache.baseUrl} loginUrl ${this.cache.loginUrl}`);
                        return of(this.cache);
                    }
                }

                return from(this.storage.get('config')).pipe(
                    // tap(()=>{console.log('from storage get config eseguito ')}),
                    map((data: string) => {
                        let config = (data !== null ? JSON.parse(data) : {}) as Config;

                        config.platform = _platform;
                        config.environment = this.getEnvironment();
                        config.portalUrl = this.window['PORTAL_URL'] || PORTAL_URL;
                        switch (config.environment) {
                            case EnvironmentType.MCU:


                                if (config.platform == PlatformMode.Browser) {
                                    this.baseUrl = config.baseUrl = '';
                                    config.loginUrl = config.baseUrl + 'api/login';
                                }

                                if (config.platform == PlatformMode.App) {
                                    this.baseUrl = config.baseUrl = this.window['BASE_URL'];
                                    config.loginUrl = config.baseUrl + 'api/login';
                                    // console.log(`env MCU-APP baseUrl ${config.baseUrl} loginUrl ${config.loginUrl}`);
                                }


                                break;
                            case EnvironmentType.Portal:
                                if (config.currentMcu) {
                                    this.baseUrl = config.baseUrl = '/portal/api/apiproxy/' + config.currentMcu + '/';
                                } else {
                                    this.baseUrl = config.baseUrl = null;
                                }
                                config.loginUrl = '/portal/login';
                                config.termsUrl = '/portal/terms';
                                config.addFavUrl = '/portal/addfav';
                                config.getFavUrl = '/portal/getFav';
                                config.removeFavUrl = '/portal/removefav';
                                break;
                            case EnvironmentType.App:
                                if (config.currentMcu) {
                                    this.baseUrl = config.baseUrl = config.portalUrl + '/portal/api/apiproxy/' + config.currentMcu + '/';
                                } else {
                                    this.baseUrl = config.baseUrl = null;
                                }
                                config.loginUrl = config.portalUrl + '/portal/login';
                                config.termsUrl = config.portalUrl + '/portal/terms';
                                config.addFavUrl = config.portalUrl + '/portal/addfav';
                                config.getFavUrl = config.portalUrl + '/portal/getFav';
                                config.removeFavUrl = config.portalUrl + '/portal/removefav';
                                break;
                            case EnvironmentType.IonicTest:
                                if (config.currentMcu) {
                                    this.baseUrl = config.baseUrl = config.portalUrl + '/portal/api/apiproxy/' + config.currentMcu + '/';
                                } else {
                                    this.baseUrl = config.baseUrl = null;
                                }
                                config.loginUrl = config.portalUrl + '/portal/login';
                                config.termsUrl = config.portalUrl + '/portal/terms';
                                config.addFavUrl = config.portalUrl + '/portal/addfav';
                                config.getFavUrl = config.portalUrl + '/portal/getFav';
                                config.removeFavUrl = config.portalUrl + '/portal/removefav';
                                break;
                            case EnvironmentType.Test:
                                config.currentMcu = this.window['CURRENT_MCU'];
                                config.baseUrl = this.window['BASE_URL'];
                                config.loginUrl = config.portalUrl + '/portal/login';
                                config.termsUrl = config.portalUrl + '/portal/terms';
                                config.addFavUrl = config.portalUrl + '/portal/addfav';
                                config.getFavUrl = config.portalUrl + '/portal/getFav';
                                config.removeFavUrl = config.portalUrl + '/portal/removefav';
                                break;
                        }

                        //  console.log('base url = ' + this.baseUrl);
                        //  console.log('login url = ' + config.loginUrl);
                        config.admin = BluetoothService.isConnected ? true : config.admin; // When I'm using Bluetooth --> I always want to be an admin of my local system
                        config.loaded = true;

                        config.zeroConfFromGuest = zeroConfFromGuest ? zeroConfFromGuest : false;
                        config.remoteTimeoutDispatched = remoteTimeoutDispatched ? remoteTimeoutDispatched : false;

                        // console.warn('ho preso config dal json')
                        return config;
                    }));
            }));
    }

    /**
     * Store the config
     */
    saveConfig(): Observable<any> {

        return from(this.platform.ready()).pipe(
            switchMap(plat => {
                let _platform: PlatformMode;
                if (this.platform.is("android"))
                    this.cache['isAndroid'] = true;

                if (this.platform.is("ios"))
                    this.cache['isIos'] = true;

                if (this.platform.is("cordova") || this.platform.is("capacitor")) {
                    _platform = PlatformMode.App;
                } else
                    _platform = PlatformMode.Browser;

                this.cache['platform'] = _platform;

                const cacheCopy = _.cloneDeep(this.cache);

                //da gestire il salvataggio aggiornato /portal/erms-of-use

                // console.log('save config, sto salvando cache che vale')
                // console.log(this.cache)

                // console.log('this.cache', this.cache);
                this.storage.set('config', JSON.stringify(cacheCopy));
                return of(null);

            }));


    }


    disconnect() {
        this.window['ENVIRON'] = 'app';
        this.cache['environment'] = this.getEnvironment();

        this.cache['loginUrl'] = this.cache['portalUrl'] + '/portal/login';

        this.cache['baseUrl'] = null;

        this.store.dispatch(new ChangeConfigValue('baseUrl', null));
        this.store.dispatch(new ChangeConfigValue('loginUrl', this.cache['portalUrl'] + '/portal/login'));

        this.store.dispatch(new ChangeConfigValue('environment', EnvironmentType.App));

        this.storage.set('config', JSON.stringify(this.cache));
        this.store.dispatch(new AppLoadConfig(true));
        return of(EMPTY);
    }

    /**
     * Logout (delete the bearer token)
     */
    logout() {

        return from(this.bluetoothService.disconnectFromBluetoothAndResetModality(this.httpStatusService)).pipe(
            switchMap(() => {

                this.storage.set('config', JSON.stringify(this.cache));

                if (this.baseUrl === null) {

                    return of([null]);
                }

                let body = JSON.stringify({action: 'logout'});
                let _headers = new HttpHeaders({'Content-Type': 'application/json'});

                return this.http.post(this.baseUrl + 'api/logout', body, {headers: _headers}).pipe(
                    map(() => EMPTY),
                    catchError((error: any) => of([null])));
            })
        );
    }
}

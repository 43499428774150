import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FlagsHRV} from "../../commons/flags";
import {AlignIconToRadioButton} from "../../models/enum-misc";

@Component({
    selector: 'icon-hrv',
    templateUrl: './icon-hrv.component.html',
    styleUrls: ['./icon-hrv.component.scss']
})

export class IconHrvComponent implements OnInit {

    @Input()
    hrvFlag: number;

    @Input()
    hasBlackBackground: boolean;

    @Input()
    alignIconToRadioButton: AlignIconToRadioButton = AlignIconToRadioButton.none;

    public AlignIconToRadioButton = AlignIconToRadioButton;

    public colorBackground: string;
    public colorGreen: string;
    public id = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);

    constructor() {
    }

    ngOnInit() {

        this.changeColors();
    }

    ngOnChanges(inputs): void {

        this.changeColors();
    }

    changeColors() {

        switch (this.hrvFlag) {

            case FlagsHRV.ON:

                this.colorGreen = '#39cb74';
                this.colorBackground = 'white';

                if (this.hasBlackBackground) {

                    this.colorBackground = '#313131';
                }
                break;

            case FlagsHRV.DISABLED:
            case FlagsHRV.OFF:

                this.colorGreen = '#dedede';
                this.colorBackground = '#dedede';

                if (this.hasBlackBackground) {

                    this.colorGreen = '#666666';
                    this.colorBackground = '#666666';
                }
                break;
        }
    }
}

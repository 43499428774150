import {Injectable} from '@angular/core';
import {Observable, of, from, EMPTY, throwError} from 'rxjs';
import {Action} from '@ngrx/store';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {map, catchError, mergeMap, switchMap, tap, debounceTime, share} from 'rxjs/operators';

import {NetworkWay} from '../models/http-status';
import {AppState} from '../services/app-state.service';
import {SocketActions, TableLoaded} from '../actions/socket.actions';
import {Router, ActivatedRoute, Params, RouterEvent, NavigationEnd} from '@angular/router';
import {HttpStatusService} from '../services/http-status.service';
import {CleanupWeatherCompleted} from '../actions/config.actions';
import {Store} from '@ngrx/store';
import {
    TablesActionTypes,
    TablesActions,
    ChangeTableValues,
    CleanupTableCompleted,
    ChangeTableAttributes,
    ChangeTableMultipleItems,
    ChangeTableMultipleAttributes,
    LoadTable,
    LoadARS,
    LoadSuccessARS,
    LoadATU,
    LoadEMA,
    LoadSuccessEMA,
    LoadSuccessATU,
    LoadDHW,
    LoadSuccessDHW,
    LoadENR,
    LoadSuccessENR,
    LoadEXP,
    LoadSuccessEXP,
    LoadFLS,
    LoadSuccessFLS,
    LoadFNC,
    LoadSuccessFNC,
    LoadFWA,
    LoadSuccessFWA,
    LoadGRP,
    LoadSuccessGRP,
    LoadHYS,
    LoadSuccessHYS,
    LoadMCU,
    LoadSuccessMCU,
    LoadMCZ,
    LoadSuccessMCZ,
    LoadMXV,
    LoadSuccessMXV,
    LoadODS,
    LoadSuccessODS,
    LoadPMP,
    LoadSuccessPMP,
    LoadPRS,
    LoadSuccessPRS,
    LoadRMS,
    LoadSuccessRMS,
    LoadSCH,
    LoadSuccessSCH,
    LoadSHT,
    LoadSuccessSHT,
    LoadTMR,
    LoadSuccessTMR,
    LoadTNK,
    LoadSuccessTNK,
    LoadTRG,
    LoadSuccessTRG,
    LoadZON,
    LoadSuccessZON,
    LoadTMP,
    LoadSuccessTMP,
    LoadPFT,
    LoadSuccessPFT,
    LoadPDT,
    LoadSuccessPDT,
    LoadMYT,
    LoadSuccessMYT,
    LoadGEN,
    LoadSuccessGEN,
    LoadTableError,
    CleanupTable,
    SetMultipleItemsCompleted,
    SetCompleted,
    CleanupTableWhenDiscovery
} from '../actions/tables.actions';
import {SocketService} from '../services/socket.service';
import {TablesService} from '../services/tables.service';
import {WeatherService} from '../services/weather.service';
import {
    CommutationError,
    CommutationSuccess,
    OpenHttp,
    HttpStatusActionTypes,
    BaseUrlChanged,
    BaseUrlChangedError
} from '../actions/http-status.actions';
import {TableNames} from '../models/tables';
import {LoadTableState} from '../actions/tables.actions';
import {ThrowStmt} from '@angular/compiler';


@Injectable()
export class TablesEffects {

    constructor(private httpStatusService: HttpStatusService, private actions$: Actions<TablesActions>,
                private tablesService: TablesService, private weatherService: WeatherService,
                protected store: Store<AppState>, private router: Router
    ) {
    }


    @Effect()
    loadTable$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadTable),
        // tap(action => console.log(`sto per lanciare l'azione sulla tabella ${action['type']}`)),

        // tap(action => console.log(`sto per lanciare l'azione sulla tabella ${action['table']}`)),

        mergeMap(action => {


                switch (('[Tables] Load ' + action['table']) as TablesActionTypes) {

                    case (TablesActionTypes.LoadARS) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadARS()');
                        this.store.dispatch(new LoadTableState('ARS', TableNames.ARS, false));
                        return of(new LoadARS());


                    case (TablesActionTypes.LoadATU) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadATU()');
                        this.store.dispatch(new LoadTableState('ATU', TableNames.ATU, false));
                        return of(new LoadATU());


                    case (TablesActionTypes.LoadDHW) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadDHW()');
                        this.store.dispatch(new LoadTableState('DHW', TableNames.DHW, false));
                        return of(new LoadDHW());


                    case (TablesActionTypes.LoadENR) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadENR()');
                        this.store.dispatch(new LoadTableState('ENR', TableNames.ENR, false));
                        return of(new LoadENR());


                    case (TablesActionTypes.LoadEXP) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadEXP()');
                        this.store.dispatch(new LoadTableState('EXP', TableNames.EXP, false));
                        return of(new LoadEXP());


                    case (TablesActionTypes.LoadFLS) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadFLS()');
                        this.store.dispatch(new LoadTableState('FLS', TableNames.FLS, false));
                        return of(new LoadFLS());


                    case (TablesActionTypes.LoadFNC) as TablesActionTypes:
                        // console.warn('tab-eff ora restituisco observable di new LoadFNC()');
                        this.store.dispatch(new LoadTableState('FNC', TableNames.FNC, false));
                        return of(new LoadFNC());

                    case (TablesActionTypes.LoadFWA) as TablesActionTypes:
                        // console.warn('tab-eff ora restituisco observable di new LoadFWA()');
                        this.store.dispatch(new LoadTableState('FWA', TableNames.FWA, false));
                        return of(new LoadFWA());

                    case (TablesActionTypes.LoadGRP) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadGRP()');
                        this.store.dispatch(new LoadTableState('GRP', TableNames.GRP, false));
                        return of(new LoadGRP());


                    case (TablesActionTypes.LoadHYS) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadHYS()');
                        this.store.dispatch(new LoadTableState('HYS', TableNames.HYS, false));
                        return of(new LoadHYS());


                    case (TablesActionTypes.LoadMCU) as TablesActionTypes:
                        // console.warn('ora restituisco observable di new LoadMCU()');
                        this.store.dispatch(new LoadTableState('MCU', TableNames.MCU, false));
                        return of(new LoadMCU());


                    case (TablesActionTypes.LoadMCZ) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadMCZ()');
                        this.store.dispatch(new LoadTableState('MCZ', TableNames.MCZ, false));
                        return of(new LoadMCZ());


                    case (TablesActionTypes.LoadMXV) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadMXV()');
                        this.store.dispatch(new LoadTableState('MXV', TableNames.MXV, false));
                        return of(new LoadMXV());


                    case (TablesActionTypes.LoadODS) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadODS()');
                        this.store.dispatch(new LoadTableState('ODS', TableNames.ODS, false));
                        return of(new LoadODS());


                    case (TablesActionTypes.LoadPMP) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadPMP()');
                        this.store.dispatch(new LoadTableState('PMP', TableNames.PMP, false));
                        return of(new LoadPMP());


                    case (TablesActionTypes.LoadPRS) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadPRS()');
                        this.store.dispatch(new LoadTableState('PRS', TableNames.PRS, false));
                        return of(new LoadPRS());


                    case (TablesActionTypes.LoadRMS) as TablesActionTypes:
                        // console.warn('tab-eff ora restituisco observable di new LoadRMS()');
                        this.store.dispatch(new LoadTableState('RMS', TableNames.RMS, false));
                        return of(new LoadRMS());


                    case (TablesActionTypes.LoadSCH) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadSCH()');
                        this.store.dispatch(new LoadTableState('SCH', TableNames.SCH, false));
                        return of(new LoadSCH());


                    case (TablesActionTypes.LoadSHT) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadSHT()');
                        this.store.dispatch(new LoadTableState('SHT', TableNames.SHT, false));
                        return of(new LoadSHT());


                    case (TablesActionTypes.LoadTMR) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadTMR()');
                        this.store.dispatch(new LoadTableState('TMR', TableNames.TMR, false));
                        return of(new LoadTMR());


                    case (TablesActionTypes.LoadTNK) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadTNK()');
                        this.store.dispatch(new LoadTableState('TNK', TableNames.TNK, false));
                        return of(new LoadTNK());


                    case (TablesActionTypes.LoadTRG) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadTRG()');
                        this.store.dispatch(new LoadTableState('TRG', TableNames.TRG, false));
                        return of(new LoadTRG());


                    case (TablesActionTypes.LoadZON) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadZON()');
                        this.store.dispatch(new LoadTableState('ZON', TableNames.ZON, false));
                        return of(new LoadZON());


                    case (TablesActionTypes.LoadTMP) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadTMP()');
                        this.store.dispatch(new LoadTableState('TMP', TableNames.TMP, false));
                        return of(new LoadTMP());


                    case (TablesActionTypes.LoadPFT) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadPFT()');
                        this.store.dispatch(new LoadTableState('PFT', TableNames.PFT, false));
                        return of(new LoadPFT());


                    case (TablesActionTypes.LoadPDT) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadPDT()');
                        this.store.dispatch(new LoadTableState('PDT', TableNames.PDT, false));
                        return of(new LoadPDT());


                    case (TablesActionTypes.LoadMYT) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadMYT()');
                        this.store.dispatch(new LoadTableState('MYT', TableNames.MYT, false));
                        return of(new LoadMYT());


                    case (TablesActionTypes.LoadGEN) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadGEN()');
                        this.store.dispatch(new LoadTableState('GEN', TableNames.GEN, false));
                        return of(new LoadGEN());

                    case (TablesActionTypes.LoadEMA) as TablesActionTypes:
                        //console.warn('ora restituisco observable di new LoadGEN()');
                        this.store.dispatch(new LoadTableState('EMA', TableNames.EMA, false));
                        return of(new LoadEMA());


                    default:
                        return of(new LoadTableError(action['table'], new Error('Invalid table ' + action['table'])));


                }


            }
        )
    );

    @Effect()
    loadARS$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadARS),

        mergeMap(action => {

                return this.tablesService.get('ARS', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessARS(data)),
                    catchError(err => of(new LoadTableError('ARS', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessARS$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessARS),

        map(() => new TableLoaded('ARS'))
    );

    @Effect()
    loadEMA$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadEMA),

        mergeMap(action => {

                return this.tablesService.get('EMA', action['force']).pipe(
                    // If successful, dispatch success action with result
                    //tap(data => {console.log('%cEma Vale','color:blue'); console.log(data)}),
                    map((data) => new LoadSuccessEMA(data)),
                    catchError(err => of(new LoadTableError('EMA', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessEMA$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessEMA),

        map(() => new TableLoaded('EMA'))
    );


    @Effect()
    loadATU$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadATU),

        mergeMap(action => {

                return this.tablesService.get('ATU', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessATU(data)),
                    catchError(err => of(new LoadTableError('ATU', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessATU$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessATU),

        map(() => new TableLoaded('ATU'))
    );


    @Effect()
    loadDHW$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadDHW),

        mergeMap(action => {

                return this.tablesService.get('DHW', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessDHW(data)),
                    catchError(err => of(new LoadTableError('DHW', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessDHW$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessDHW),

        map(() => new TableLoaded('DHW'))
    );


    @Effect()
    loadENR$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadENR),

        mergeMap(action => {

                return this.tablesService.get('ENR', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessENR(data)),
                    catchError(err => of(new LoadTableError('ENR', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessENR$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessENR),

        map(() => new TableLoaded('ENR'))
    );


    @Effect()
    loadEXP$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadEXP),

        mergeMap(action => {

                return this.tablesService.get('EXP', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessEXP(data)),
                    catchError(err => of(new LoadTableError('EXP', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessEXP$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessEXP),

        map(() => new TableLoaded('EXP'))
    );


    @Effect()
    loadFLS$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadFLS),

        mergeMap(action => {

                return this.tablesService.get('FLS', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessFLS(data)),
                    catchError(err => of(new LoadTableError('FLS', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessFLS$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessFLS),

        map(() => new TableLoaded('FLS'))
    );


    @Effect()
    loadFNC$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadFNC),

        mergeMap(action => {

                return this.tablesService.get('FNC', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessFNC(data)),
                    catchError(err => of(new LoadTableError('FNC', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessFNC$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessFNC),

        map(() => new TableLoaded('FNC'))
    );

    @Effect()
    loadFWA$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadFWA),

        mergeMap(action => {

                return this.tablesService.get('FWA', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessFWA(data)),
                    catchError(err => of(new LoadTableError('FWA', err)))
                )
            }
        )
    );

    @Effect()
    loadSuccessFWA$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessFWA),

        map(() => new TableLoaded('FWA'))
    );


    @Effect()
    loadGRP$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadGRP),

        mergeMap(action => {

                return this.tablesService.get('GRP', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessGRP(data)),
                    catchError(err => of(new LoadTableError('GRP', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessGRP$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessGRP),

        map(() => new TableLoaded('GRP'))
    );


    @Effect()
    loadHYS$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadHYS),

        mergeMap(action => {

                return this.tablesService.get('HYS', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessHYS(data)),
                    catchError(err => of(new LoadTableError('HYS', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessHYS$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessHYS),

        map(() => new TableLoaded('HYS'))
    );


    @Effect()
    loadMCU$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadMCU),

        mergeMap(action => {

                return this.tablesService.get('MCU', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessMCU(data)),
                    catchError(err => of(new LoadTableError('MCU', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessMCU$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessMCU),

        map(() => new TableLoaded('MCU'))
    );


    @Effect()
    loadMCZ$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadMCZ),

        mergeMap(action => {

                return this.tablesService.get('MCZ', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessMCZ(data)),
                    catchError(err => of(new LoadTableError('MCZ', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessMCZ$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessMCZ),

        map(() => new TableLoaded('MCZ'))
    );


    @Effect()
    loadMXV$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadMXV),

        mergeMap(action => {

                return this.tablesService.get('MXV', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessMXV(data)),
                    catchError(err => of(new LoadTableError('MXV', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessMXV$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessMXV),

        map(() => new TableLoaded('MXV'))
    );


    @Effect()
    loadODS$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadODS),

        mergeMap(action => {

                return this.tablesService.get('ODS', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessODS(data)),
                    catchError(err => of(new LoadTableError('ODS', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessODS$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessODS),

        map(() => new TableLoaded('ODS'))
    );


    @Effect()
    loadPMP$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadPMP),

        mergeMap(action => {

                return this.tablesService.get('PMP', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessPMP(data)),
                    catchError(err => of(new LoadTableError('PMP', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessPMP$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessPMP),

        map(() => new TableLoaded('PMP'))
    );


    @Effect()
    loadPRS$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadPRS),

        mergeMap(action => {

                return this.tablesService.get('PRS', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessPRS(data)),
                    catchError(err => of(new LoadTableError('PRS', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessPRS$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessPRS),

        map(() => new TableLoaded('PRS'))
    );


    @Effect()
    loadRMS$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadRMS),

        mergeMap(action => {

                return this.tablesService.get('RMS', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessRMS(data)),
                    catchError(err => of(new LoadTableError('RMS', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessRMS$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessRMS),

        map(() => new TableLoaded('RMS'))
    );


    @Effect()
    loadSCH$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSCH),

        mergeMap(action => {

                return this.tablesService.get('SCH', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessSCH(data)),
                    catchError(err => of(new LoadTableError('SCH', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessSCH$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessSCH),

        map(() => new TableLoaded('SCH'))
    );


    @Effect()
    loadSHT$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSHT),

        mergeMap(action => {

                return this.tablesService.get('SHT', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessSHT(data)),
                    catchError(err => of(new LoadTableError('SHT', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessSHT$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessSHT),

        map(() => new TableLoaded('SHT'))
    );


    @Effect()
    loadTMR$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadTMR),

        mergeMap(action => {

                return this.tablesService.get('TMR', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessTMR(data)),
                    catchError(err => of(new LoadTableError('TMR', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessTMR$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessTMR),

        map(() => new TableLoaded('TMR'))
    );


    @Effect()
    loadTNK$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadTNK),

        mergeMap(action => {

                return this.tablesService.get('TNK', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessTNK(data)),
                    catchError(err => of(new LoadTableError('TNK', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessTNK$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessTNK),

        map(() => new TableLoaded('TNK'))
    );


    @Effect()
    loadTRG$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadTRG),

        mergeMap(action => {

                return this.tablesService.get('TRG', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessTRG(data)),
                    catchError(err => of(new LoadTableError('TRG', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessTRG$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessTRG),

        map(() => new TableLoaded('TRG'))
    );


    @Effect()
    loadZON$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadZON),

        mergeMap(action => {

                return this.tablesService.get('ZON', action['force']).pipe(
                    // tap(d=>console.log(d)),
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessZON(data)),
                    catchError(err => of(new LoadTableError('ZON', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessZON$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessZON),

        map(() => new TableLoaded('ZON'))
    );


    @Effect()
    loadTMP$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadTMP),

        mergeMap(action => {

                return this.tablesService.get('TMP', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessTMP(data)),
                    catchError(err => of(new LoadTableError('TMP', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessTMP$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessTMP),

        map(() => new TableLoaded('TMP'))
    );


    @Effect()
    loadPFT$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadPFT),

        mergeMap(action => {

                return this.tablesService.get('PFT', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessPFT(data)),
                    catchError(err => of(new LoadTableError('PFT', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessPFT$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessPFT),

        map(() => new TableLoaded('PFT'))
    );


    @Effect()
    loadPDT$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadPDT),

        mergeMap(action => {

                return this.tablesService.get('PDT', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessPDT(data)),
                    catchError(err => of(new LoadTableError('PDT', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessPDT$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessPDT),

        map(() => new TableLoaded('PDT'))
    );


    @Effect()
    loadMYT$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadMYT),

        mergeMap(action => {

                return this.tablesService.get('MYT', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessMYT(data)),
                    catchError(err => of(new LoadTableError('MYT', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessMYT$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessMYT),

        map(() => new TableLoaded('MYT'))
    );


//Gen

    @Effect()
    loadGEN$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadGEN),

        mergeMap(action => {

                return this.tablesService.get('GEN', action['force']).pipe(
                    // If successful, dispatch success action with result
                    map((data) => new LoadSuccessGEN(data)),
                    catchError(err => of(new LoadTableError('GEN', err)))
                )
            }
        )
    );


    @Effect()
    loadSuccessGEN$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.LoadSuccessGEN),

        map(() => new TableLoaded('GEN'))
    );


//fine gen


    @Effect()
    changeValues$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.ChangeTableValues),

        switchMap(action => {
            // payload.remote is true if this action is executed from a server request
            // so we don't have to send the action back to the server
            if (action['remote']) {
                return EMPTY;
            } else {

                return this.tablesService.set(action['table'], action['id'], action['values']).pipe(
                    //tap(()=> console.log("setMultiple items completed")),
                    map(() => new SetCompleted())
                );
            }
        }));


    @Effect()
    changeMultipleItems$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.ChangeTableMultipleItems),

        switchMap(action => {


                return this.tablesService.setMultipleItems(action['items']).pipe(
                    // tap(()=> console.log("setMultiple items completed")),
                    map(() => new SetMultipleItemsCompleted())
                );
            }
        ));


    @Effect()
    cleanup$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.CleanupTable),

        switchMap(action => {

                return this.tablesService.cleanup().pipe(
                    // tap(()=> console.log("clean up  effect fired")),
                    map(() => new CleanupTableCompleted()));
            }
        ));

    @Effect()
    cleanupTableWhenChangeMcu$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.CleanupTableWhenChangeMcu),

        mergeMap(action => {

            return this.tablesService.cleanup().pipe(

                catchError((error)=> {return throwError(`[CleanupTableWhenChangeMcu - ERROR] ${error}`)}),
                map(() => new CleanupTableCompleted()));
        }),

        switchMap(action => {

                this.store.dispatch(action);

                return this.httpStatusService.probeNetworkStatus().pipe(

                    // If successful, dispatch success action with result
                    map(data => new CommutationSuccess(data as NetworkWay)),
                    // If request fails, dispatch failed action
                    catchError((err) => of(new CommutationError(NetworkWay.Remote)))
                );

            }
        ));


    @Effect()
    cleanupTableWhenDiscovery$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.CleanupTableWhenDiscovery),
        mergeMap(action => {

            return this.tablesService.cleanup().pipe(
                // tap(()=> console.log("clean up  effect fired when change mcu")),
                map(() => new CleanupTableCompleted()));

        }),
    );


    @Effect()
    cleanupCompleted$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.CleanupTableCompleted),

        switchMap(action => {

                return this.weatherService.cleanup().pipe(
                    // tap(()=> console.log("clean up weather effect fired")),
                    map(() => new CleanupWeatherCompleted()));
            }
        ));


    @Effect()
    changeMultipleAttributes$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.ChangeTableMultipleAttributes),

        switchMap(action => {


                return this.tablesService.setMultipleAttributes(action['items']).pipe(
                    // tap(()=> console.log("set Multiple Attribute effect fired")),
                    map(() => new SetMultipleItemsCompleted()));
            }
        ));


    @Effect()
    changeAttributes$: Observable<any> = this.actions$.pipe(
        ofType(TablesActionTypes.ChangeTableAttributes),

        switchMap(action => {
                if (action['remote']) {
                    return EMPTY;
                } else {
                    return this.tablesService.setAtt(action['table'], action['values']).pipe(
                        // tap(() => console.log("set Attribute effect fired")),
                        map(() => new SetCompleted()));
                }
            }
        ));
}

  
   



import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'nav-calibration-hidew',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponentCalibrationHidew implements OnInit {



  @Output()
  prev:EventEmitter<any> = new EventEmitter();

  @Output()
  next:EventEmitter<any> = new EventEmitter();

  @Input() prevText:string;
  @Input() nextText:string;

  @Input() exit:boolean;

  @Input() disabled:boolean;

  constructor() { }

  ngOnInit() {}


  prevEmitter(){
    this.prev.emit();
  }
  nextEmitter(){
    this.next.emit();
  }

}

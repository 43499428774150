    <!--<ion-backdrop></ion-backdrop>-->
    <div class="alert-wrapper noSchModal">
      <div class="alert-head">
      	<!--<div class="selectAllButton" (click)="selectAll()">{{'SELECT_ALL' | translate }}</div>-->
        <h2  class="alert-title" *ngIf="title" [innerHTML]="title"></h2>



        
      </div>
     <!--  <ion-list class="selectList"  *ngIf="options.length==1 && options[0].value==-1" >
          <ion-item class="no-lines" >
            <ion-label class="alert-message" [innerHTML]="message"></ion-label>
          </ion-item>
       </ion-list>-->
     
     <ion-list>
     	 <ion-item class="no-lines">
         	<ion-label>{{'SELECT_ALL' | translate }}</ion-label>
         	 <ion-checkbox color="tertiary" (ionChange)="selectAll($event)"  slot="start" [(ngModel)]="selectAllValue"  [ngModelOptions]="{standalone: true}" ngDefaultControl [checked]="selectAllValue"  ></ion-checkbox>
         </ion-item>
     </ion-list>


           
      <ion-list class="selectList" >
        <!--<ion-radio-group (ionChange)="onChangeRadioGroup($event)">-->
          
          <ion-item class="no-lines" *ngFor="let option of options" > 
            <ion-label>{{ option.name }}</ion-label>
            <ion-checkbox color="tertiary" (ionChange)="datachanged($event,option)"  slot="start" [(ngModel)]="option.isChecked"  [ngModelOptions]="{standalone: true}" ngDefaultControl [checked]="option.isChecked"  ></ion-checkbox><!-- [checked]="scheduleId == option.idx" (click)="changeType(option)" -->
          </ion-item>

         
       <!--</ion-radio-group>-->
      </ion-list>


         

         




   <div class="alert-button-group" >
    
        <button category="alert-button" *ngFor="let b of buttons" (click)="btnClick(b)" class="alert-button" [innerHTML]="b.text">
         
        </button>
     
    </div>   

   <!-- <div class="alert-button-group" *ngIf="options.length==1 && options[0].value==-1">
    
        <button category="alert-button" *ngFor="let b of buttonsEmpty" (click)="btnClick(b)" class="alert-button" [innerHTML]="b.text">
         
        </button>
     
    </div> -->
    </div>
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';

import {Observable, of, EMPTY, timer, Subject, Subscription} from 'rxjs';
import {AppState} from './app-state.service';
import {LoadTable, ChangeTableValues} from '../actions/tables.actions';
import {
    Connected,
    Disconnected,
    CheckRemoteConnectionState,
    CheckRemoteConnectionStateSuccess, CheckRemoteConnectionStateFail
} from '../actions/socket.actions';

import {Config, EnvironmentType, PlatformMode} from '../models/config';
import {Socket} from '../models/socket';
import {DisconnectConfig} from '../actions/config.actions';
import * as _ from 'lodash';

import {distinctUntilChanged, first, switchMap} from 'rxjs/operators';

// prevent receiving notification about recent changed values for RECENT_CHANGES_TIMEOUT ms
export const RECENT_CHANGES_TIMEOUT = 2000;
import {HttpCancelService} from './http-cancel.service';
import {AbstractConfigPage} from "../pages/abstract-config-page";
import {Router} from "@angular/router";
import {AbstractBaseComponent} from "../components/abstract-base-component";
import {ActionSheetController, AlertController, Platform, ToastController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {BluetoothService} from "./bluetooth.service";
import {ScannerService} from "./scanner.service";
import {PopupService} from "./popup.service";
import {WindowRefService} from "./window.service";
import {io} from "socket.io-client";

@Injectable({
    providedIn: 'root'
})
export class SocketService extends AbstractBaseComponent {

    protected observables: any; // { [name: string]: Observable<any>} = {};
    private baseUrl: string;
    private socket: any; // socketIO
    private loadedTables: string[] = [];
    private eventListenerTables: string[] = [];
    private environment: EnvironmentType;
    private recentChanges: any = {}; // key: 'table.id.key', value: timestamp - prevent receiving notification about recent changed values
    private disconnectSocketTimerAlert: Subscription;
    private zeroConfFromGuest;
    private platformMode:PlatformMode
    private disconnectPopupIsCurrentlyDisplayed = false;

    constructor(protected store: Store<AppState>,
                private httpCancelService: HttpCancelService,
                private router: Router,
                protected platform: Platform,
                protected alertController: AlertController, protected actionSheetController: ActionSheetController, protected translate: TranslateService,
                private bluetoothService: BluetoothService,
                private scanner: ScannerService,
                private popupService: PopupService,
                private windowRefService: WindowRefService,
                protected toastCtrl?: ToastController) {

        super(store, platform, alertController, actionSheetController, translate);

        this.observables = {
            config: store.select('config') as Observable<Config>,
            socket: store.select('socket') as Observable<Socket>
        }

        this.observables.config.subscribe(data => {

            this.environment = data.environment;
            let baseUrlChanged: boolean = this.baseUrl != data.baseUrl;
            this.baseUrl = data.baseUrl;
            this.zeroConfFromGuest = data.zeroConfFromGuest;
            this.platformMode = data.platform;

            // close the socket on user logout
            if (this.socket && !data.currentUser) {

                this.loadedTables = [];
                this.closeSocket();
            }

            // open the socket if it is closed and the user is logged
            else if (data.currentUser && this.socket == null) {

                this.loadedTables = [];
                this.openSocket();

            }

            // reopen the socket if the base url is changed and the user is logged
            else if (data.currentUser && baseUrlChanged) {

                this.openSocket();
            }
        });

        this.observables.socket.pipe(distinctUntilChanged((prev, curr) => _.isEqual((prev as Socket).loadedTables.sort(), (curr as Socket).loadedTables.sort()))).subscribe(data => {

            this.loadedTables = Array.from(new Set(data.loadedTables));
        });
    }

    /**
     * Open a socket
     */
    openSocket = (): void => {

        this.closeSocket();

        if (this.baseUrl !== null && this.baseUrl !== undefined) {

            let host = '/';
            let path = undefined;

            if (!this.baseUrl) {
                host = '/';
                path = undefined;
            }

            else if (this.baseUrl.substring(0, 4) == 'http') {

                let index = this.baseUrl.indexOf('/', 8); // index of first '/' after 'http://' or 'https://'
                host = this.baseUrl.substring(0, index);
                path = this.baseUrl.substring(index) + 'socket.io';
            }

            else {
                host = '/';
                path = this.baseUrl + 'socket.io';
            }

            this.socket = io(host, {

                path: path,
                reconnection: true,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 4000,
                reconnectionAttempts: Infinity
            });

            this.socket.on('disconnect', () => {

                console.warn('SOCKET - DISCONNECT');
                console.log('this.router.url', this.router.url);

                // If I'm connected with Bluetooth, and the socket disconnects -> I don't care of the 'disconnect' event (I'm connected to the Bluetooth socket, until I choose to disconnect or the connection is resumed if it's bluetooth emergency)
                if (this.bluetoothService.isConnected()) {

                    return;
                }

                this.store.dispatch(new Disconnected());

                if (!this.disconnectSocketTimerAlert) {

                    this.disconnectSocketTimerAlert = timer(20000).subscribe(async () => {

                        const canShowDisconnectPopup = await this.canShowDisconnectPopup();
                        console.warn(canShowDisconnectPopup);

                        if (canShowDisconnectPopup) {

                            await this.showPopupDisconnected();
                        }
                    });
                }
            });

            this.socket.on('connect', () => {

                console.warn('SOCKET - CONNECT');

                if (this.bluetoothService.isConnected()) {

                    return;
                }

                if (this.disconnectSocketTimerAlert) {

                    this.disconnectSocketTimerAlert.unsubscribe();
                    this.disconnectSocketTimerAlert = null;
                }

                this.store.dispatch(new Connected());

                // Reload the tables when the socket reconnect
                AbstractConfigPage.resetCentralizedTableData();

                // Only re-loading the tables I've already loaded
                for (let table of this.loadedTables) {

                    //console.log('carico le tabelle');
                    if (table != 'Attributes' && table != 'Config' && table != 'Backups') {

                        // console.log(`da this.socket.on('connect' sto lanciando una LoadTable su ${table}`);
                        this.store.dispatch(new LoadTable(table, true)); // force is actually deprecated
                    }
                }
            });

            // Register the listeners
            for (let table of this.eventListenerTables) {

                this.socket.on(table, event => this.eventListener(table, event));
            }
        }
    }

    /**
     * Close the socket
     */
    closeSocket = (): void => {

        if (this.socket) {

            //chiudo chiamate pendenti
            this.httpCancelService.cancelPendingRequests();

            this.socket.disconnect();
            this.socket = null;
        }
    }


    $subject = new Subject<boolean>();

    /**
     * This method is invoked when a SocketIO event for 'table' is received
     */
    eventListener = (table, event): void => {

        let values = {};
        values[event.var] = event.value;

        // the recentChanges object prevent receiving notification about recent changed values
        let property = table + '.' + Number.parseInt(event.id) + '.' + event.var;

        // If I'm listening to changes that I've done myself in the app (not external) -> I can ignore them (they are already updated in the app)

        if ((!this.recentChanges[property] || this.recentChanges[property].expiration < Date.now())) {

            if (!(this.recentChanges[property]?.fromInAppSetValue) || this.recentChanges[property] == undefined) {

                this.store.dispatch(new ChangeTableValues(table, Number.parseInt(event.id), values, true));
            }
        }

        if (this.recentChanges[property] && this.recentChanges[property].fromInAppSetValue) {

            if (this.recentChanges[property].$obs == null) {

                this.recentChanges[property].$obs = timer(5000);

            } else {

                this.recentChanges[property].$obs.subscribe((data) => {

                    console.log(`${property}) richiamo tabella a posteriore per risolvere conflitto`);
                    this.store.dispatch(new LoadTable(table, true));

                    timer(10000).subscribe((() => {
                        this.recentChanges[property].$obs = null;
                    }));
                });
            }
        }

        if (this.recentChanges[property] && this.recentChanges[property].fromInAppSetValue) {

            timer(4000).subscribe(() => {
                this.recentChanges[property].fromInAppSetValue = false;
                console.log(`recentChanges[${property}].fromInAppSetValue = false`);
            });
        }
    }

    /**
     * Register a listener for event on the loaded table
     */
    registerEventListener = (table) => {

        if (_.indexOf(this.eventListenerTables, table) != -1) {
            return EMPTY;
        }
        this.eventListenerTables.push(table);

        if (this.socket) {
            // console.warn(`registro EventListener su ${table}`);
            this.socket.on(table, event => this.eventListener(table, event));
        } else {
            // console.error(`errore durante registrazione eventListener su ${table}`);
        }
        return EMPTY;
    }

    /**
     * Emit a message on the socket
     */
    emit = (eventName, data): void => {
        if (this.socket) {

            if (eventName == 'tableSetItemValues') {

                let expiration = Date.now() + RECENT_CHANGES_TIMEOUT;

                for (let key in data.values) {
                    // the recentChanges object prevent receiving notification about recent changed values
                    let property = data.table + '.' + data.id + '.' + key;

                    if (!this.recentChanges.hasOwnProperty(property)) {

                        this.recentChanges[property] = {expiration: expiration, fromInAppSetValue: true, $obs: null}; // fromInAppSetValue: you are listening changes from InApp changes
                    } else {

                        this.recentChanges[property] = {...this.recentChanges[property], expiration: expiration};
                    }
                }
            }
            this.socket.emit(eventName, data);
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- Disconnection from Socket support functions -----------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    // --- After socket disconnect -> Popup "no internet connection" ---------------------------------------------------
    async showPopupDisconnected(forceShowPopupFromHome = false) {

        if (this.disconnectPopupIsCurrentlyDisplayed) {

            return;
        }

        const actions = [];

        actions.push({
            text: 'OK',
            handler: () => {

                this.disconnectPopupIsCurrentlyDisplayed = false;
                this.store.dispatch(new CheckRemoteConnectionState());
            },
            cssClass: 'blue-ion-button'
        });

        const configObservable = this.store.select('config') as Observable<Config>;

        configObservable.pipe(first()).subscribe(async (config: Config) => {

            // In the Homepage (ony the first time I enter) I've my own BLE "disconnect" Popup -> So I don't want to show this popup in the homepage if I haven't shown the "Bluetooth Emergency Popup" already
            console.warn('config.remoteTimeoutDispatched', config.remoteTimeoutDispatched);

            // I don't want the Popup in these sections (and also I don't want it in the /settings-on-off and /settings-network -> because if I reboot or change network settings I don't want to receive this popup)
            if (this.router.url.includes('wizard') || this.router.url === '/login' || this.router.url === '/forgot-password' || this.router.url === '/mcu-select' || this.router.url === '/menu-mcu-select' || this.router.url === '/settings-on-off' || this.router.url === '/settings-network' || this.router.url === '/discovery') {

                return;
            }

            if (this.router.url === '/home' && !config.remoteTimeoutDispatched && !forceShowPopupFromHome) {

                console.warn('Bloccato ad HOME');
                return;
            }

            this.disconnectPopupIsCurrentlyDisplayed = true;
            await this.popupService.showCustomButtonsWarning('NO_INTERNET_POPUP_TITLE', 'NO_INTERNET_POPUP_MESSAGE', actions);
        });
    }

    // --- After clicking "OK" the system will check if there's connection ---------------------------------------------
    checkRemoteConnectionState(): Observable<any> {

        return this.observables.socket.pipe(first(), switchMap(data => {

            const socket = data as Socket;
            console.log('socket', socket);

            if (socket.connected) {

                return of(new CheckRemoteConnectionStateSuccess());

            } else {

                return of(new CheckRemoteConnectionStateFail());
            }
        }));
    }

    async showToastReconnected() {

        const text = this.translate.instant('INTERNET_CONNECTION_RESTORED');
        await this.presentToast(text, 3000);
    }

    // --- If, after clicking "OK", the system is offline -> redirect to mcu-select ------------------------------------
    async noInternetConnectionRedirect() {

        // On direct-ip from Browser
        if (this.platformMode === PlatformMode.Browser && this.environment === EnvironmentType.MCU) {

            await this.showPopupDisconnected();
        }

        else {

            const text = this.translate.instant('INTERNET_CONNECTION_UNAVAILABLE');
            await this.presentToast(text, 3000);

            // Zeroconf (Guest or Auto-switch)
            if (this.environment === EnvironmentType.MCU && this.platformMode === PlatformMode.App) {

                // The "DisconnectConfig" will either redirect you later to '/login' (zeroConfFromGuest) or '/mcu-select' (auto-switch)
                this.store.dispatch(new DisconnectConfig(this.zeroConfFromGuest));
            }

            // App or Browser Portal in a system
            else {

                await this.router.navigate(['/mcu-select']);
            }
        }
    }

    async canShowDisconnectPopup() {

        const data = await this.observables.config.pipe(first()).toPromise();

        // There must be a "data.currentUser" (only after login)
        if (data.currentUser) {

            return true;
        }

        return false;
    }
}

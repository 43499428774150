import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import {from, Observable} from 'rxjs';
import { Attributes } from '../models/attributes';
import { AppState } from './app-state.service';
import { Config } from '../models/config';
import {BluetoothService} from "./bluetooth.service";


@Injectable({
  providedIn: 'root'
})
export class ActionsService {

    private cache: Attributes = {};
    private config: Observable<Config>;
    private baseUrl: string;

    constructor(private http: HttpClient,
                protected store: Store<AppState>,
                private bluetoothService: BluetoothService)
    {
        this.config = store.select('config') as Observable<Config>;
        this.config.subscribe(data => {
            this.baseUrl = data.baseUrl;
        })
        //this.store.dispatch(new LoadConfig());
    }

    /**
     * Execute an action
     */

    executeAction(action: string, values: any) {

        // console.log(`executeAction action: ${action} values: `)
        // console.log(values);

        if (this.bluetoothService.isConnected()) {

            const api = 'api/action';

            if (values) {

                values["action"] = action;
            }

            else {

                values = {action: action};
            }

            return from(this.bluetoothService.getDataViaBluetooth(api,true, values));
        }

        let body = JSON.stringify(values || {});
        let _headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        //let options = new HttpRequest('OPTIONS',this.baseUrl + 'api/action/' + action,{ headers: _headers});

        return this.http.put(this.baseUrl + 'api/action/' + action, body ,{headers:_headers});
    }

    /**
     * Load the system status
     */
    loadStatus() {

        const api = 'api/action/status';

        if (this.bluetoothService.isConnected()) {

            return from(this.bluetoothService.getDataViaBluetooth(api,true));
        }

        return this.http.get(this.baseUrl + api);//.pipe(
          //  map((res:Response) => res.json().content));
    }

}

<div class="alert-wrapper newMacrozoneModal">

    <div class="alert-head">
        <h2 class="alert-title" *ngIf="title" [innerHTML]="title"></h2>
    </div>
    <div class="alert-message" [innerHTML]="message"></div>

    <div class="alert-input-group">
        <div class="alert-input-wrapper">
            <ion-list class="border">
                <ion-item *ngFor="let i of inputs" class="no-lines">
                    <ion-checkbox slot="start" [name]="i.label" (click)="do(i)" ngDefaultControl [(ngModel)]="i.checked" [ngModelOptions]="{standalone: true}" (ionChange)="cbClick($event,i)"
                                  [checked]="i.checked" [disabled]="i.disabled" class="alert-tappable alert-checkbox">

                    </ion-checkbox>

                    <ion-label>{{i.label}}</ion-label>
                </ion-item>
            </ion-list>
        </div>
    </div>

    <div class="alert-button-group">

        <button category="alert-button" *ngFor="let b of buttons" (click)="btnClick(b)" class="alert-button">
            {{b.text}}
        </button>
    </div>
</div>
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

/*
 * Sort an array by a given property
 */
@Pipe({ name: 'sort' })
export class SortPipe implements PipeTransform {

    transform(array: Array<any>, key: string): Array<any> {
        return _.sortBy(array, key)
    }

}

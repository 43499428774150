import {ActionReducer, Action} from '@ngrx/store';
import {HttpStatusActions, HttpStatusActionTypes} from '../actions/http-status.actions';
import {HttpStatus, NetworkStatus} from '../models/http-status';

const initialState: HttpStatus = new HttpStatus();

/**
 * Http Status reducer
 * (state management)
 *
 * Reducers are the foundation of any Store or Redux-based application, describing
 * sections of state and their potential transformations based on dispatched
 * action types. It is the combination of your reducers that makes up a
 * representation of application state at any given time.
 *
 * A reducer is a pure function, accepting two arguments, the previous state
 * and an action with a type and optional data (payload) associated with the
 * event.
 */
export function httpStatusReducer(state: HttpStatus = initialState, action: HttpStatusActions): HttpStatus {

    var newState: HttpStatus;

    switch (action.type) {

        case HttpStatusActionTypes.OpenHttp:
            // console.log('PENDING');
            // console.log(state.pendingRequests + 1);
            return {
                ...state,
                pendingRequests: state.pendingRequests + 1,
                currentRequest: (state.currentRequest + 1) % 1000
            };

        case HttpStatusActionTypes.CloseHttp:
            // console.log('PENDING');
            // console.log(state.pendingRequests - 1);
            return {...state, pendingRequests: state.pendingRequests - 1};

        case HttpStatusActionTypes.CommutationSuccess:
            // console.log('CommutationSuccess Reducer');
            // console.log(action.modality);
            console.log('action.modality', action.modality);
            return {...state, modality: action.modality};

        case HttpStatusActionTypes.ReloadingNetworkEnd:
            // console.log('ReloadingNetworkEnd');

            return {...state, reloadingNetwork: NetworkStatus.Active};

        case HttpStatusActionTypes.ResetCompleted:

            return {...state};

        case HttpStatusActionTypes.CurrentDateTimeUpdated:

            return {...state};

        case HttpStatusActionTypes.ReloadingNetworkUpdate:
            // console.log('ReloadingNetworkUpdate');

            return {...state, reloadingNetwork: action.status};

        case HttpStatusActionTypes.ReloadingNetworkStart:
            // console.log('ReloadingNetworkStart');

            return {...state, reloadingNetwork: NetworkStatus.Reloading};


        case HttpStatusActionTypes.CommutationError:
            // console.log(`CommutationError, going to ${ action.modality}`);

            return {...state, modality: action.modality};

        case HttpStatusActionTypes.CommutationErrorRetry:
            // console.log(`CommutationError, going to ${ action.modality}`);

            return {...state, modality: action.modality};

        case HttpStatusActionTypes.CommutationSync:
            // console.log(`CommutationSync, going from ${state.modality} to ${action.modality}` );

            return {...state, modality: action.modality};

        case HttpStatusActionTypes.CommutationSyncToRecheck:
            // console.log(`CommutationSyncToRecheck, going from ${state.modality} to ${action.modality}` );

            return {...state, modality: action.modality};

        default:
            return state;
    }
}

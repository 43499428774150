import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { TableNames } from '../models/tables';


export interface ChangeValuesRequest {
    table: string; // table name
    id: number;    // item id
    values: any;   // new item values
}

export interface ChangeAttributesRequest {
    table: string; // table name
    values: any;   // new item values
}






export enum TablesActionTypes {
    ChangeTableValues = '[Tables] Change Values',
    ChangeTableAttributes = '[Tables] Change Attributes',
    ChangeTableMultipleItems = '[Tables] Change Multiple Items',
    SetMultipleItemsCompleted = '[Tables] Change Multiple Items Completed',
    ChangeTableMultipleAttributes = '[Tables] Change Multiple Attributes',
    LoadTable = '[Tables] Load Table',
    LoadTableState = '[Tables] Load Table State',
    LoadARS = '[Tables] Load ARS',
    LoadSuccessARS = '[Tables] Load Success ARS',
    LoadEMA = '[Tables] Load EMA',
    LoadSuccessEMA = '[Tables] Load Success EMA',
    LoadATU = '[Tables] Load ATU',
    LoadSuccessATU = '[Tables] Load Success ATU',
    LoadDHW = '[Tables] Load DHW',
    LoadSuccessDHW = '[Tables] Load Success DHW',
    LoadENR = '[Tables] Load ENR',
    LoadSuccessENR = '[Tables] Load Success ENR',
    LoadEXP = '[Tables] Load EXP',
    LoadSuccessEXP = '[Tables] Load Success EXP',
    LoadFLS = '[Tables] Load FLS',
    LoadSuccessFLS = '[Tables] Load Success FLS',
    LoadFNC = '[Tables] Load FNC',
    LoadSuccessFNC = '[Tables] Load Success FNC',
    LoadFWA = '[Tables] Load FWA',
    LoadSuccessFWA = '[Tables] Load Success FWA',
    LoadGRP = '[Tables] Load GRP',
    LoadSuccessGRP = '[Tables] Load Success GRP',
    LoadHYS = '[Tables] Load HYS',
    LoadSuccessHYS = '[Tables] Load Success HYS',
    LoadMCU = '[Tables] Load MCU',
    LoadSuccessMCU = '[Tables] Load Success MCU',
    LoadMCZ = '[Tables] Load MCZ',
    LoadSuccessMCZ = '[Tables] Load Success MCZ',
    LoadMXV = '[Tables] Load MXV',
    LoadSuccessMXV = '[Tables] Load Success MXV',
    LoadODS = '[Tables] Load ODS',
    LoadSuccessODS = '[Tables] Load Success ODS',
    LoadPMP = '[Tables] Load PMP',
    LoadSuccessPMP = '[Tables] Load Success PMP',
    LoadPRS = '[Tables] Load PRS',
    LoadSuccessPRS = '[Tables] Load Success PRS',
    LoadRMS = '[Tables] Load RMS',
    LoadSuccessRMS = '[Tables] Load Success RMS',
    LoadSCH = '[Tables] Load SCH',
    LoadSuccessSCH = '[Tables] Load Success SCH',
    LoadSHT = '[Tables] Load SHT',
    LoadSuccessSHT = '[Tables] Load Success SHT',
    LoadTMR = '[Tables] Load TMR',
    LoadSuccessTMR = '[Tables] Load Success TMR',
    LoadTNK = '[Tables] Load TNK',
    LoadSuccessTNK = '[Tables] Load Success TNK',
    LoadTRG = '[Tables] Load TRG',
    LoadSuccessTRG = '[Tables] Load Success TRG',
    LoadZON = '[Tables] Load ZON',
    LoadSuccessZON = '[Tables] Load Success ZON',
    LoadTMP = '[Tables] Load TMP',
    LoadSuccessTMP = '[Tables] Load Success TMP',
    LoadPFT = '[Tables] Load PFT',
    LoadSuccessPFT = '[Tables] Load Success PFT',
    LoadPDT = '[Tables] Load PDT',
    LoadSuccessPDT = '[Tables] Load Success PDT',
    LoadGEN = '[Tables] Load GEN',
    LoadSuccessGEN = '[Tables] Load Success GEN',
    LoadMYT = '[Tables] Load MYT',
    LoadSuccessMYT = '[Tables] Load Success MYT',
    LoadTableError = '[Tables] Load Error',
    CleanupTable = '[Tables] Cleanup',
    CleanupTableWhenChangeMcu = '[Tables] Cleanup Table When Change Mcu',
    CleanupTableWhenDiscovery = '[Tables] Cleanup Table When Discovery',
    SetCompleted = '[Tables] Set Completed',
    CleanupTableCompleted = '[Tables] Cleaup Completed',
   
}





  /*
     * Change values of an item
     * The param remote is true if this action is executed from a server request
     */
export class ChangeTableValues implements Action {
    readonly type = TablesActionTypes.ChangeTableValues;
    constructor(public table: string,public  id: number, public values: any,public  remote: boolean = false){}
}
    /*
     * Change multiple items
     * The param remote is true if this action is executed from a server request
     */
export class ChangeTableMultipleItems implements Action {
    readonly type = TablesActionTypes.ChangeTableMultipleItems;
    constructor(public items: ChangeValuesRequest[]){}
}

     /**
     * Load a table
     */

 export class LoadTable implements Action {
   // readonly type = '[Tables] Load '+this.table; 
    readonly type = TablesActionTypes.LoadTable;
    constructor(public table: string, public force: boolean = false){}
}


export class LoadTableState implements Action {
    // readonly type = '[Tables] Load Table State'+this.table; 
     readonly type = TablesActionTypes.LoadTableState;
     constructor(public table: string, public name:TableNames,public loaded:boolean){}
 }


  /*  static load(table: string, force: boolean = false): Action {

  		// questo comportamento va implementato, magari nel reducer?

        let type = TablesActions['LOAD_' + table];
        if (!type) {
            throw new Error('Invalid table ' + table);
        } else {
            return { type: type, payload: { force: force } };
        }
    }
*/
export class LoadARS implements Action {
    readonly type = TablesActionTypes.LoadARS;
    constructor(){}
}
export class LoadSuccessARS implements Action {
    readonly type = TablesActionTypes.LoadSuccessARS;
    constructor(public data:any){}
}

export class LoadEMA implements Action {
    readonly type = TablesActionTypes.LoadEMA;
    constructor(){
      //console.log('%cLoadEMA()','font-size:1.5rem;color:BlueViolet');
    }
}
export class LoadSuccessEMA implements Action {
    readonly type = TablesActionTypes.LoadSuccessEMA;
    constructor(public data:any){}
}

export class LoadATU implements Action {
    readonly type = TablesActionTypes.LoadATU;
    constructor(){}
}
export class LoadSuccessATU implements Action {
    readonly type = TablesActionTypes.LoadSuccessATU;
    constructor(public data:any){
     //

       //console.warn("ATU loaded")
      

    /*  let atuPrint=Array();
     
     //console.log(data);
     for(let r of data){
         atuPrint.push({"id":r.id,"name":r.CFG_Name,"idxARS":r.CFG_IdxARS});
     }
     console.log(atuPrint);*/
       // 
    }

 }


export class LoadDHW implements Action {
    readonly type = TablesActionTypes.LoadDHW;
    constructor(){}
}
export class LoadSuccessDHW implements Action {
    readonly type = TablesActionTypes.LoadSuccessDHW;
    constructor(public data:any){
     //
 }
}

export class LoadENR implements Action {
    readonly type = TablesActionTypes.LoadENR;
    constructor(){}
}
export class LoadSuccessENR implements Action {
    readonly type = TablesActionTypes.LoadSuccessENR;
    constructor(public data:any){
    // 
 }
}

export class LoadEXP implements Action {
    readonly type = TablesActionTypes.LoadEXP;
    constructor(){}
}
export class LoadSuccessEXP implements Action {
    readonly type = TablesActionTypes.LoadSuccessEXP;
    constructor(public data:any){ }
}

export class LoadFLS implements Action {
    readonly type = TablesActionTypes.LoadFLS;
    constructor(){}
}
export class LoadSuccessFLS implements Action {
    readonly type = TablesActionTypes.LoadSuccessFLS;
    constructor(public data:any){ 
      //  
    }
}

export class LoadFNC implements Action {
    readonly type = TablesActionTypes.LoadFNC;
    constructor(){}
}
export class LoadSuccessFNC implements Action {
    readonly type = TablesActionTypes.LoadSuccessFNC;
    constructor(public data:any){ 
       // console.warn("fnc loaded")
       // console.log(data);
        //
    }
}

export class LoadFWA implements Action {
    readonly type = TablesActionTypes.LoadFWA;
    constructor(){}
}
export class LoadSuccessFWA implements Action {

    readonly type = TablesActionTypes.LoadSuccessFWA;
    constructor(public data:any){
    }
}

export class LoadGRP implements Action {
    readonly type = TablesActionTypes.LoadGRP;
    constructor(){}
}
export class LoadSuccessGRP implements Action {
    readonly type = TablesActionTypes.LoadSuccessGRP;
    constructor(public data:any){ 
        //
    }
}

export class LoadHYS implements Action {
    readonly type = TablesActionTypes.LoadHYS;
    constructor(){}
}
export class LoadSuccessHYS implements Action {
    readonly type = TablesActionTypes.LoadSuccessHYS;
    constructor(public data:any){ 
        //
    }
}

export class LoadMCU implements Action {
    readonly type = TablesActionTypes.LoadMCU;
    constructor(){}
}
export class LoadSuccessMCU implements Action {
    readonly type = TablesActionTypes.LoadSuccessMCU;
    constructor(public data:any){ 
        //
    }
}

export class LoadMCZ implements Action {
    readonly type = TablesActionTypes.LoadMCZ;
    constructor(){}
}
export class LoadSuccessMCZ implements Action {
    readonly type = TablesActionTypes.LoadSuccessMCZ;
    constructor(public data:any){ 
        //
    }
}

export class LoadMXV implements Action {
    readonly type = TablesActionTypes.LoadMXV;
    constructor(){}
}
export class LoadSuccessMXV implements Action {
    readonly type = TablesActionTypes.LoadSuccessMXV;
    constructor(public data:any){ 
        //
    }
}

export class LoadODS implements Action {
    readonly type = TablesActionTypes.LoadODS;
    constructor(){}
}
export class LoadSuccessODS implements Action {
    readonly type = TablesActionTypes.LoadSuccessODS;
    constructor(public data:any){
     //
 }
}

export class LoadPMP implements Action {
    readonly type = TablesActionTypes.LoadPMP;
    constructor(){}
}
export class LoadSuccessPMP implements Action {
    readonly type = TablesActionTypes.LoadSuccessPMP;
    constructor(public data:any){ 
      //  
    }
}

export class LoadPRS implements Action {
    readonly type = TablesActionTypes.LoadPRS;
    constructor(){}
}
export class LoadSuccessPRS implements Action {
    readonly type = TablesActionTypes.LoadSuccessPRS;
    constructor(public data:any){ 
       // 
    }
}

export class LoadRMS implements Action {
    readonly type = TablesActionTypes.LoadRMS;
    constructor(){}
}
export class LoadSuccessRMS implements Action {
    readonly type = TablesActionTypes.LoadSuccessRMS;
    constructor(public data:any){ 
         /* console.warn("'RMS loaded")
         console.log(data)
       console.warn("log sintetico RMS")

     let rmsPrint=Array();
   
     //console.log(data);
     for(let r of data){
         rmsPrint.push({"id":r.id,"name":r.CFG_Name,"idxARS":r.CFG_IdxARS});
     }
     console.log(rmsPrint);*/
       // 
    }
}

export class LoadSCH implements Action {
    readonly type = TablesActionTypes.LoadSCH;
    constructor(){}
}
export class LoadSuccessSCH implements Action {
    readonly type = TablesActionTypes.LoadSuccessSCH;
    constructor(public data:any){
    // 
 }
}

export class LoadSHT implements Action {
    readonly type = TablesActionTypes.LoadSHT;
    constructor(){}
}
export class LoadSuccessSHT implements Action {
    readonly type = TablesActionTypes.LoadSuccessSHT;
    constructor(public data:any){ 
     //   
    }
}

export class LoadTMR implements Action {
    readonly type = TablesActionTypes.LoadTMR;
    constructor(){}
}
export class LoadSuccessTMR implements Action {
    readonly type = TablesActionTypes.LoadSuccessTMR;
    constructor(public data:any){ 
        //
    }
}

export class LoadTNK implements Action {
    readonly type = TablesActionTypes.LoadTNK;
    constructor(){}
}
export class LoadSuccessTNK implements Action {
    readonly type = TablesActionTypes.LoadSuccessTNK;
    constructor(public data:any){ }
}

export class LoadTRG implements Action {
    readonly type = TablesActionTypes.LoadTRG;
    constructor(){}
}
export class LoadSuccessTRG implements Action {
    readonly type = TablesActionTypes.LoadSuccessTRG;
    constructor(public data:any){ }
}

export class LoadZON implements Action {
    readonly type = TablesActionTypes.LoadZON;
    constructor(){}
}
export class LoadSuccessZON implements Action {
    readonly type = TablesActionTypes.LoadSuccessZON;
    constructor(public data:any){

    /* console.warn("'ZON loaded")
      let zonPrint=Array();
   
     //console.log(data);
     for(let r of data){
         zonPrint.push({"id":r.id,"name":r.CFG_Name,"IdxRMS":r.IdxRMS});
     }
     console.log(zonPrint);*/
     }
}

export class LoadTMP implements Action {
    readonly type = TablesActionTypes.LoadTMP;
    constructor(){}
}
export class LoadSuccessTMP implements Action {
    readonly type = TablesActionTypes.LoadSuccessTMP;
    constructor(public data:any){ }
}

export class LoadPFT implements Action {
    readonly type = TablesActionTypes.LoadPFT;
    constructor(){}
}
export class LoadSuccessPFT implements Action {
    readonly type = TablesActionTypes.LoadSuccessPFT;
    constructor(public data:any){ }
}

export class LoadPDT implements Action {
    readonly type = TablesActionTypes.LoadPDT;
    constructor(){}
}
export class LoadSuccessPDT implements Action {
    readonly type = TablesActionTypes.LoadSuccessPDT;
    constructor(public data:any){ }
}

export class LoadMYT implements Action {
    readonly type = TablesActionTypes.LoadMYT;
    constructor(){}
}
export class LoadSuccessMYT implements Action {
    readonly type = TablesActionTypes.LoadSuccessMYT;
    constructor(public data:any){ }
}

export class LoadGEN implements Action {
    readonly type = TablesActionTypes.LoadGEN;
    constructor(){}
}
export class LoadSuccessGEN implements Action {
    readonly type = TablesActionTypes.LoadSuccessGEN;
    constructor(public data:any){ }
}




export class LoadTableError implements Action {
    readonly type = TablesActionTypes.LoadTableError;
    constructor(public table: string, public error: any){}
}

export class CleanupTable implements Action {
    readonly type = TablesActionTypes.CleanupTable;
    constructor(){}
}

export class CleanupTableWhenChangeMcu implements Action {
    readonly type = TablesActionTypes.CleanupTableWhenChangeMcu;
    constructor(){}
}

export class CleanupTableWhenDiscovery implements Action {
    readonly type = TablesActionTypes.CleanupTableWhenDiscovery;
    constructor(){}
}

export class CleanupTableCompleted implements Action {
    readonly type = TablesActionTypes.CleanupTableCompleted;
    constructor(){}
}

export class SetMultipleItemsCompleted implements Action {
    readonly type = TablesActionTypes.SetMultipleItemsCompleted;
    constructor(){}
}
export class SetCompleted implements Action {
    readonly type = TablesActionTypes.SetCompleted;
    constructor(){}
}



    /*
    ATT Modify
    */
    /*
     * Change values of an attribute
     * The param remote is true if this action is executed from a server request
     */

export class ChangeTableAttributes implements Action {
    readonly type = TablesActionTypes.ChangeTableAttributes;
    constructor(public table: string, public values: any, public remote: boolean = false){}
}

  /*
     * Change multiple attributes
     * The param remote is true if this action is executed from a server request
     */

export class ChangeTableMultipleAttributes implements Action {
    readonly type = TablesActionTypes.ChangeTableMultipleAttributes;
    constructor(public items: ChangeAttributesRequest[]){}
}
    


export type TablesActions = LoadTableState |  ChangeTableValues | 
	ChangeTableAttributes | 
    ChangeTableMultipleItems | 
    ChangeTableMultipleAttributes | 
    LoadTable | 
LoadARS | LoadSuccessARS |  LoadATU | LoadSuccessATU |  LoadDHW | LoadSuccessDHW |  LoadENR | LoadSuccessENR |  LoadEXP | LoadSuccessEXP |  LoadFLS | LoadSuccessFLS |  LoadFNC | LoadSuccessFNC |  LoadFWA | LoadSuccessFWA |  LoadGRP | LoadSuccessGRP |  LoadHYS | LoadSuccessHYS |  LoadMCU | LoadSuccessMCU |  LoadMCZ | LoadSuccessMCZ |  LoadMXV | LoadSuccessMXV |  LoadODS | LoadSuccessODS |  LoadPMP | LoadSuccessPMP |  LoadPRS | LoadSuccessPRS |  LoadRMS | LoadSuccessRMS |  LoadSCH | LoadSuccessSCH |  LoadSHT | LoadSuccessSHT |  LoadTMR | LoadSuccessTMR |  LoadTNK | LoadSuccessTNK |  LoadTRG | LoadSuccessTRG |  LoadZON | LoadSuccessZON |  LoadTMP | LoadSuccessTMP |  LoadPFT | LoadSuccessPFT |  LoadPDT | LoadSuccessPDT |  LoadMYT | LoadSuccessMYT |
LoadTableError | CleanupTable | CleanupTableWhenChangeMcu | CleanupTableWhenDiscovery | CleanupTableCompleted | SetMultipleItemsCompleted | SetCompleted | LoadGEN | LoadSuccessGEN | LoadSuccessEMA;




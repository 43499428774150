import { Input, Directive, ElementRef } from '@angular/core';
import { Table, SCH } from '../../models/tables';

export const SCH_OPTION_DELTA = 1000;

export interface ScheduleOptionInterface {
    value: number;
    text: string;
}

/**
 * Option component for options like "Always on", "Always off"
 */
@Directive({
    selector: 'schedule-option'
})
export class ScheduleOption implements ScheduleOptionInterface {

    @Input()
    public value: number;
    public schedulation: boolean = false; // this option indicates the value used for enabling the schedulation

    constructor(private _elementRef: ElementRef) {
        if (_elementRef.nativeElement.hasAttribute('schedulation')) {
            this.schedulation = true;
        }
    }

    get text(): string {
        return this._elementRef.nativeElement.textContent;
    }

}

/**
 * Option for SCH items
 */
export class ScheduleSCHOption {

    constructor(public sch: SCH) {
    }

    get value(): number {
        return SCH_OPTION_DELTA + this.sch.id;
    }

    get text(): string {
        return this.sch.CFG_Name;
    }

}


<!-- sch hrv -->
<schedule [usePopupIsOpenedCheck]="true"
          [ngClass]="{'narrow':loaded && ars.RTT_CanHrv && ars.PAR_HrvSchedOn && existSCH}"
          [class]="scheduleStyle" *ngIf="ars.RTT_CanHrv"
          [disabled]="!ars.PAR_On || !ars.PAR_HrvOn" table="ARS" [item]="ars"
          toggle="PAR_HrvSchedOn"
          idxVar="PAR_IdxSCH_Hrv" [MCU]="MCU" [SCH]="SCH" itemClass="indent-half">
  <schedule-option [value]="2" schedulation>{{ 'SCHEDULE' | translate }}</schedule-option>
</schedule>

<schedule-graph [config]="config"
        *ngIf="currentDateTime.dateTimeAsMoment && ars.PAR_HrvSchedOn && ars.PAR_HrvOn && ars.PAR_On && ars.RTT_CanHrv"
        [currentDateTime]="currentDateTime"
        [inputClass]="'no-lines'"
        [schedule]="schedule"
        [id]="id"></schedule-graph>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
    transform(value, args?: string[]): any {
        let keys = [];
        for (let key in value) {
            keys.push({ key: key, info: key.substring(0, 3), var: key.substring(4), value: value[key] });
        }
        return keys;
    }
}

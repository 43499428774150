import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pctNoUnit'
})
export class PctNoUnitPipe implements PipeTransform {

    transform(value: any, digits: number): string {
        if (value === null || value === undefined || isNaN(value) || value == -3276.8 || value == '-3276.8') {
            return '--.-';
        }
        if (typeof value !== 'number') {
            value = Number.parseFloat('' + value);
        }
        if (digits === null || digits === undefined) {
            digits = 1;
        }
        return value.toFixed(digits);
    }

}



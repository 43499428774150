import * as _ from 'lodash';
import * as moment from 'moment';
import 'moment/locale/it';

// index	 24h	 12h
// --------------------------
//   0 -->   0:00    12:00 am
//   1 -->   0:30    12:30 am
//   2 -->   1:00     1:00 am
//   3 -->   1:30     1:30 am
//   4 -->   2:00     2:00 am
//   5 -->   2:30     2:30 am
//   6 -->   3:00     3:00 am
//   7 -->   3:30     3:30 am
//   8 -->   4:00     4:00 am
//   9 -->   4:30     4:30 am
//  10 -->   5:00     5:00 am
//  11 -->   5:30     5:30 am
//  12 -->   6:00     6:00 am
//  13 -->   6:30     6:30 am
//  14 -->   7:00     7:00 am
//  15 -->   7:30     7:30 am
//  16 -->   8:00     8:00 am
//  17 -->   8:30     8:30 am
//  18 -->   9:00     9:00 am
//  19 -->   9:30     9:30 am
//  20 -->  10:00    10:00 am
//  21 -->  10:30    10:30 am
//  22 -->  11:00    11:00 am
//  23 -->  11:30    11:30 am
//  24 -->  12:00    12:00 pm
//  25 -->  12:30    12:30 pm
//  26 -->  13:00     1:00 pm
//  27 -->  13:30     1:30 pm
//  28 -->  14:00     2:00 pm
//  29 -->  14:30     2:30 pm
//  30 -->  15:00     3:00 pm
//  31 -->  15:30     3:30 pm
//  32 -->  16:00     4:00 pm
//  33 -->  16:30     4:30 pm
//  34 -->  17:00     5:00 pm
//  35 -->  17:30     5:30 pm
//  36 -->  18:00     6:00 pm
//  37 -->  18:30     6:30 pm
//  38 -->  19:00     7:00 pm
//  39 -->  19:30     7:30 pm
//  40 -->  20:00     8:00 pm
//  41 -->  20:30     8:30 pm
//  42 -->  21:00     9:00 pm
//  43 -->  21:30     9:30 pm
//  44 -->  22:00    10:00 pm
//  45 -->  22:30    10:30 pm
//  46 -->  23:00    11:00 pm
//  47 -->  23:30    11:30 pm

/**
 * Hour to index
 * '12:30' -> 25
 */
export function hourToIndex(hour: string): number {
    var s: string[] = (hour || '').split(':');
    if (s.length != 2) {
        return 0;
    }
    var h: number = parseInt(s[0]);
    var m: number = parseInt(s[1]);
    var t: number = (m == 30) ? 1 : 0;
    return (h * 2) + t;
}

/**
 * Index to hour
 * 25 -> '12:30'
 */
export function indexToHour(index: number): string {
    let h:string = '' + Math.floor(index / 2);
    let m:string = index % 2 == 0 ? '00' : '30';
    return h + ':' + m;
}

/**
 * Hour to index every 15'
 * '12:30' -> 50
 */
 export function hourToIndex15(hour: string): number {
     var s: string[] = (hour || '').split(':');
     if (s.length != 2) {
         return 0;
     }
     var h: number = parseInt(s[0]);
     var m: number = parseInt(s[1]);
     var t:number
     switch(m){
       case 0: t = 0;break
       case 15: t = 1;break
       case 30: t = 2;break
       case 45: t = 3
     }
     return (h * 4) + t;
 }

 /**
  * Index to hour every 15'
  * 50 -> '12:30'
  */
 export function indexToHour15(index: number): string {
     var h:string = '' + Math.floor(index / 4);
     if(parseInt(h)<10) h = "0" + h
     var m:string
     switch(index % 4){
       case 0: m = '00';break
       case 1: m = '15';break
       case 2: m = '30';break
       case 3: m = '45'
     }

     return h + ':' + m;
 }

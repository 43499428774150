import {Component, Input, OnInit} from '@angular/core';
import {RMS_Extended} from "../../../models/tables-extended";
import {TechNoDataBlock} from "../tech.component";

@Component({
  selector: 'tech-nodata-block',
  templateUrl: './tech-nodata-block.component.html',
  styleUrls: ['./tech-nodata-block.component.scss'],
})
export class TechNodataBlockComponent implements OnInit {

  @Input() techNoDataBlock: TechNoDataBlock;

  constructor() { }

  ngOnInit() {}

}

import { ActionReducer, Action } from '@ngrx/store';
import { TablesActions, ChangeValuesRequest, TablesActionTypes } from '../actions/tables.actions';
import {Tables, MCU, TableNames, ChangedTableValues} from '../models/tables';
import { getClassByName } from '../models/tables';
import * as _ from 'lodash';
import {deepCopy} from "../deprecated/util";

const initialState: Tables = new Tables();


export function tablesReducer (state: Tables = initialState, action: TablesActions):Tables | ChangedTableValues {

    var newState: Tables;
   // console.log("tablesReducer state: ",state);
    switch(action.type) {

        //posso passare il nome della tabella, anzi dell'enum e poi metto state[loaded][nomeTabella]=false
       	case TablesActionTypes.LoadTableState:

            newState={...state};

            newState['loaded'][action.name]=action.loaded;
           

    		return {...newState, error: null };

    	
       	case TablesActionTypes.LoadSuccessARS:

            newState = {...state};

            newState['loaded'][TableNames.ARS]=true;
    		return {...newState, ARS: action.data, error: null };

		case TablesActionTypes.LoadSuccessATU:

            newState = {...state};

            newState['loaded'][TableNames.ATU]=true;
           
		    return {...newState, ATU: action.data, error: null };

		case TablesActionTypes.LoadSuccessDHW:
            newState = {...state};

            newState['loaded'][TableNames.DHW]=true;
		    return {...newState, DHW: action.data, error: null };

		case TablesActionTypes.LoadSuccessENR:
            newState = {...state};

            newState['loaded'][TableNames.ENR]=true;
		    return {...newState, ENR: action.data, error: null };

		case TablesActionTypes.LoadSuccessEXP:
            newState = {...state};

            newState['loaded'][TableNames.EXP]=true;
		    return {...newState, EXP: action.data, error: null };

		case TablesActionTypes.LoadSuccessFLS:
            newState = {...state};

            newState['loaded'][TableNames.FLS]=true;
		    return {...newState, FLS: action.data, error: null };

		case TablesActionTypes.LoadSuccessFNC:
            newState = {...state};

            newState['loaded'][TableNames.FNC]=true;
		    return {...newState, FNC: action.data, error: null };

        case TablesActionTypes.LoadSuccessFWA:
            newState = {...state};

            newState['loaded'][TableNames.FWA]=true;
            return {...newState, FWA: action.data, error: null };

		case TablesActionTypes.LoadSuccessGRP:
            newState = {...state};

            newState['loaded'][TableNames.GRP]=true;
		    return {...newState, GRP: action.data, error: null };

		case TablesActionTypes.LoadSuccessHYS:
            newState = {...state};

            newState['loaded'][TableNames.HYS]=true;
		    return {...newState, HYS: action.data, error: null };

		case TablesActionTypes.LoadSuccessMCU:
            newState = {...state};

            newState['loaded'][TableNames.MCU]=true;
		    return {...newState, MCU: action.data, error: null };

		case TablesActionTypes.LoadSuccessMCZ:
            newState = {...state};

            newState['loaded'][TableNames.MCZ]=true;
		    return {...newState, MCZ: action.data, error: null };

		case TablesActionTypes.LoadSuccessMXV:
            newState = {...state};

            newState['loaded'][TableNames.MXV]=true;
		    return {...newState, MXV: action.data, error: null };

		case TablesActionTypes.LoadSuccessODS:
            newState = {...state};

            newState['loaded'][TableNames.ODS]=true;
		    return {...newState, ODS: action.data, error: null };

		case TablesActionTypes.LoadSuccessPMP:
            newState = {...state};

            newState['loaded'][TableNames.PMP]=true;
		    return {...newState, PMP: action.data, error: null };

		case TablesActionTypes.LoadSuccessPRS:
            newState = {...state};

            newState['loaded'][TableNames.PRS]=true;
		    return {...newState, PRS: action.data, error: null };

		case TablesActionTypes.LoadSuccessRMS:
            newState = {...state};

            newState['loaded'][TableNames.RMS]=true;
		    return {...newState, RMS: action.data, error: null };

		case TablesActionTypes.LoadSuccessSCH:
            newState = {...state};

            newState['loaded'][TableNames.SCH]=true;
		    return {...newState, SCH: action.data, error: null };

		case TablesActionTypes.LoadSuccessSHT:
            newState = {...state};

            newState['loaded'][TableNames.SHT]=true;
		    return {...newState, SHT: action.data, error: null };

		case TablesActionTypes.LoadSuccessTMR:
            newState = {...state};

            newState['loaded'][TableNames.TMR]=true;
		    return {...newState, TMR: action.data, error: null };

		case TablesActionTypes.LoadSuccessTNK:
            newState = {...state};

            newState['loaded'][TableNames.TNK]=true;
		    return {...newState, TNK: action.data, error: null };

		case TablesActionTypes.LoadSuccessTRG:
            newState = {...state};

            newState['loaded'][TableNames.TRG]=true;
		    return {...newState, TRG: action.data, error: null };

		case TablesActionTypes.LoadSuccessZON:
            newState = {...state};

            newState['loaded'][TableNames.ZON]=true;
		    return {...newState, ZON: action.data, error: null };

		case TablesActionTypes.LoadSuccessTMP:
            newState = {...state};

            newState['loaded'][TableNames.TMP]=true;
		    return {...newState, TMP: action.data, error: null };

		case TablesActionTypes.LoadSuccessPFT:
            newState = {...state};

            newState['loaded'][TableNames.PFT]=true;
		    return {...newState, PFT: action.data, error: null };

		case TablesActionTypes.LoadSuccessPDT:
            newState = {...state};

            newState['loaded'][TableNames.PDT]=true;
		    return {...newState, PDT: action.data, error: null };

		case TablesActionTypes.LoadSuccessMYT:
            newState = {...state};

            newState['loaded'][TableNames.MYT]=true;
		    return {...newState, MYT: action.data, error: null };

		case TablesActionTypes.LoadSuccessGEN:
            newState = {...state};

            newState['loaded'][TableNames.GEN]=true;
		    return {...newState, GEN: action.data, error: null };

        case TablesActionTypes.LoadSuccessEMA:
            newState = {...state};

            newState['loaded'][TableNames.EMA]=true;
            return {...newState, EMA: action.data, error: null };




 		case TablesActionTypes.ChangeTableValues: {

             /*if (action.remote && !_.isEqual(state, initialState)) {

                 console.log(action.values);
                 return new ChangedTableValues(action);
             }*/

            newState = _.cloneDeep(state);
            let values: any[] = Object.assign([], state[action.table]);
            let klass = getClassByName(action.table);
            values[action.id] = Object.assign(new klass(), values[action.id], action.values);
            newState[action.table] = values;
            newState.error = null;
            return newState;
        }

        case TablesActionTypes.CleanupTableCompleted: {
        	// console.log('CleanupTableCompleted reducer')
        	return state;
        }

        

        case TablesActionTypes.ChangeTableMultipleItems: {
            newState = {...state};
            for (let item of action.items) {
                let values: any[] = Object.assign([], newState[item.table]);
                let klass = getClassByName(item.table);
                values[item.id] = Object.assign(new klass(), values[item.id], item.values);
                newState[item.table] = values;
            }
            newState.error = null;
            return newState;
        }

        case TablesActionTypes.LoadTableError:
            return state;

        case TablesActionTypes.CleanupTableWhenChangeMcu:

        	 newState = {...initialState, error: null };
            _.forEach(state, (key, value) => {
                newState[key] = [];
            });
            newState['MCU'] = [ new MCU() ];
            newState['MCU'][0].CFG_Code = 'loading';
            newState.loaded = new Array(TableNames.EMA + 1).fill(false);
            return newState;

        case TablesActionTypes.CleanupTableWhenDiscovery:
            // console.log('CleanupTableWhenDiscovery reducer');

             newState = {...initialState, error: null };
            _.forEach(state, (key, value) => {
                newState[key] = [];
            });
            newState['MCU'] = [ new MCU() ];
            newState['MCU'][0].CFG_Code = 'loading';
            newState.loaded = new Array(TableNames.EMA + 1).fill(false);
            return newState;


        case TablesActionTypes.CleanupTable:
        // console.log('CleanupTable reducer');
            newState = {...initialState, error: null };
            _.forEach(state, (key, value) => {
                newState[key] = [];
            });
            newState['MCU'] = [ new MCU() ];
            newState['MCU'][0].CFG_Code = 'loading';
            newState.loaded = new Array(TableNames.EMA + 1).fill(false);
            return newState;

        case TablesActionTypes.ChangeTableAttributes: {
            newState = {...state};
            let values: any[] = Object.assign([], state[action.table]);
           
            newState[action.table] = values;
            newState.error = null;
            return newState;
        }

        default:
            return state;
    }
 }
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from "@angular/common";
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';



import {NewMacrozoneComponent} from './new-macrozone.component';
import {IonicModule} from "@ionic/angular";


@NgModule({
	imports: [CommonModule, TranslateModule, FormsModule, IonicModule],
	declarations:[NewMacrozoneComponent],
	exports:[NewMacrozoneComponent],
	schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class NewMacrozoneModule{}
import {Component, Input, OnInit} from '@angular/core';
import {ModalController, PickerController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {MCU} from "../../../models/tables";
import {Attributes} from "../../../models/attributes";
import {MCZ_Extended, RMS_Extended} from "../../../models/tables-extended";
import * as moment from "moment";
import {ChangeTableValues} from "../../../actions/tables.actions";
import {Store} from "@ngrx/store";
import {AppState} from "../../../services/app-state.service";
import {Config, DUAL_MODE} from "../../../models/config";
import {interval, Subscription} from "rxjs";
import * as _ from 'lodash';
import {ZonePage} from "../zone.page";
import {MacroZonePage} from "../../macro-zone/macro-zone.page";
import {DualSetpointOverrideMinimumDifferenceViolation} from "../../../models/variousGroupedModels";

@Component({
    selector: 'app-override-setpoint',
    templateUrl: './override-setpoint.component.html',
    styleUrls: ['./override-setpoint.component.scss'],
})

export class OverrideSetpointComponent implements OnInit {

    @Input() MCU: MCU;
    @Input() table: string; // 'RMS' or 'MCZ' for now
    @Input() rmsOrMczExtended: RMS_Extended | MCZ_Extended;
    @Input() attrs: Attributes;
    @Input() config: Config;
    @Input() isApp: boolean;
    @Input() dualSetpointMode: DUAL_MODE;
    @Input() otherDualSetpointModeIsInOverride: boolean;

    @Input() page: ZonePage | MacroZonePage;

    public previousSetTemp: number;
    public SpoSetTemp: number;
    public SpoPeriod: number = 30; // in minutes (default if there isn't an override: 30 mins)
    private SpoPeriodChanged: boolean; // When I open an already active setpoint override -> I want to show the current override time, unless I manually change the time (SpoPeriodChanged = true)

    public SpoSetTempMin: number;
    public SpoSetTempMax: number;
    public SpoSetTempStep: number;

    public SpoPeriodMin: number;
    public SpoPeriodMax: number;
    public SpoPeriodStep: number;

    public SpoPeriodText = '';
    private dataUpdateSubscription: Subscription;

    public dualSetpointOverrideMinimumDifferenceViolation = new DualSetpointOverrideMinimumDifferenceViolation();
    public dualSetpointOverrideUpdateTimeViolation = new DualSetpointOverrideMinimumDifferenceViolation();

    constructor(
        protected translate: TranslateService,
        protected modalController: ModalController,
        private pickerCtrl: PickerController,
        private store: Store<AppState>) {
    }

    ngOnInit() {

        if (this.dualSetpointMode === DUAL_MODE.heating || this.rmsOrMczExtended.setpointCounterIsPAR_SetTempH()) {

            this.SpoSetTemp = this.rmsOrMczExtended.PAR_SetTempH;
        }

        if (this.dualSetpointMode === DUAL_MODE.cooling || this.rmsOrMczExtended.setpointCounterIsPAR_SetTempC()) {

            this.SpoSetTemp = this.rmsOrMczExtended.PAR_SetTempC;
        }

        const RTU_SetPoint = this.rmsOrMczExtended.setpointCounterIsRTU_SetPoint(this.dualSetpointMode);

        if (RTU_SetPoint) {

            this.SpoSetTemp = this.rmsOrMczExtended.sch[RTU_SetPoint]; // RTU_SetPoint, RTU_SetPointH or RTU_SetPointC, depending on the schedule and season.
        }

        // fix the -3276 visual bug
        if (this.SpoSetTemp <= -1000) {

            this.SpoSetTemp = this.rmsOrMczExtended.getCorrectOverridePropertyValues(this.dualSetpointMode).PAR_SpoSetTemp;
        }

        this.previousSetTemp = this.SpoSetTemp;

        // If there is already an override: I show it
        if (this.rmsOrMczExtended.getSetpointOverrideInfo(null, this.dualSetpointMode).isOverride) {

            // Override Temp
            this.SpoSetTemp = this.rmsOrMczExtended.getCorrectOverridePropertyValues(this.dualSetpointMode).PAR_SpoSetTemp;

            // Override Period (rounder to the upper 15 mins)
            const overrideDateTimeSet = this.rmsOrMczExtended.getSetpointOverrideInfo(this.rmsOrMczExtended.getCorrectOverrideProperties(this.dualSetpointMode).PAR_SpoPeriod, this.dualSetpointMode).currentOverrideDateTime;
            const SpoPeriod = (overrideDateTimeSet.days * 24 * 60) + (overrideDateTimeSet.hours * 60) + overrideDateTimeSet.minutes;

            // Round the SpoPeriod to the upper 15 mins
            this.SpoPeriod = Math.ceil(SpoPeriod / 15) * 15;
        }

        const PAR_SpoSetTemp = this.rmsOrMczExtended.getCorrectOverrideProperties(this.dualSetpointMode).PAR_SpoSetTemp;
        const PAR_SpoPeriod = this.rmsOrMczExtended.getCorrectOverrideProperties(this.dualSetpointMode).PAR_SpoPeriod;

        this.SpoSetTempMin = this.attrs[this.table][PAR_SpoSetTemp]['Min'];
        this.SpoSetTempMax = this.attrs[this.table][PAR_SpoSetTemp]['Max'];
        this.SpoSetTempStep = this.attrs[this.table][PAR_SpoSetTemp]['Step'];

        // When in auto mode dual setpoint, and the other setpoint is also in override, I set the min and max of the override temperature based on the other setpoint. For example, heating override with cooling setpoint override to 80 -> I can't set a heating override more than 78
        if (this.otherDualSetpointModeIsInOverride) {

            const difference = this.MCU.PAR_UM === 0 ? 2 : 1; // fahrenheit or celsius

            switch (this.dualSetpointMode) {

                // if there is no cooling setpoint (so the override shouldn't exist too) -> I can set the heating setpoint however I want (this check is only for RMS, not MCZ). Otherwise, to the max of cooling override -2 or -1
                case DUAL_MODE.heating:

                    if (this.rmsOrMczExtended instanceof MCZ_Extended || (this.rmsOrMczExtended instanceof RMS_Extended && !this.rmsOrMczExtended.autoModeRmsModeNotPresent(1))) {

                        this.SpoSetTempMax = this.rmsOrMczExtended.PAR_SpoSetTempC - difference;
                    }

                    break;

                case DUAL_MODE.cooling:

                    if (this.rmsOrMczExtended instanceof MCZ_Extended || (this.rmsOrMczExtended instanceof RMS_Extended && !this.rmsOrMczExtended.autoModeRmsModeNotPresent(0))) {

                        this.SpoSetTempMin = this.rmsOrMczExtended.PAR_SpoSetTempH + difference;
                    }
                    break;
            }
        }

        if (!this.SpoSetTempStep) {

            this.SpoSetTempStep = 0.5;
        }

        this.SpoPeriodMin = this.attrs[this.table][PAR_SpoPeriod]['Min'];
        this.SpoPeriodMax = this.attrs[this.table][PAR_SpoPeriod]['Max'];
        this.SpoPeriodStep = this.attrs[this.table][PAR_SpoPeriod]['Step'];

        this.dataUpdateSubscription = interval(100).subscribe(() => {

            this.updateSpoPeriodText();
        });

        this.updateSpoPeriodText();
    }

    ngOnDestroy() {

        if (this.dataUpdateSubscription) {

            this.dataUpdateSubscription.unsubscribe();
        }
    }

    async dismiss() {

        await this.modalController.dismiss({});
    }

    saveDisabled() {

        return this.SpoPeriod === 0;
    }

    onChangeSetPoint(SpoSetTemp) {

        this.SpoSetTemp = parseFloat(SpoSetTemp);
        this.dualSetpointOverrideMinimumDifferenceViolation = this.page.getDualSetpointOverrideMinimumDifferenceViolation(this.otherDualSetpointModeIsInOverride, this.dualSetpointMode, this.MCU, this.SpoSetTemp, this.rmsOrMczExtended);

        const setpointOverrideModal = document.querySelector('.SetpointOverrideModal');

        if (this.dualSetpointOverrideMinimumDifferenceViolation.present) {

            setpointOverrideModal?.classList.add('OverrideTallerModal');
        } else {

            setpointOverrideModal?.classList.remove('OverrideTallerModal');
        }
    }

    async openOverridePeriodPicker() {

        class PickerOption {

            public value;
            public text;

            constructor(value, text, context) {

                this.value = value;
                this.text = `${value} ${context.translate.instant(text)}`;
            }
        }

        // -------------------------------------------------------------------------------------------------------------

        const days: PickerOption[] = [];
        const hours: PickerOption[] = [];
        const minutes: PickerOption[] = [];

        for (let i = 0; i <= 7; i++) {

            days.push(new PickerOption(i, 'day' + this.getS(i), this));
        }

        for (let i = 0; i <= 23; i++) {

            hours.push(new PickerOption(i, 'hour' + this.getS(i), this));
        }

        for (let i = 0; i < 60; i += 15) {

            minutes.push(new PickerOption(i, 'min', this));
        }

        // -------------------------------------------------------------------------------------------------------------

        const SpoPeriodObject = this.rmsOrMczExtended.getSetpointOverrideInfo(this.SpoPeriod, this.dualSetpointMode).overrideDateTimeSet;

        // -------------------------------------------------------------------------------------------------------------

        console.log(SpoPeriodObject);

        if (this.isApp) {

            const theme = this.config.isAndroid ? 'dark' : 'light'; // theme property only works on Android, as of cordova-wheel-selector 1.1.7

            const data = {
                days: days,
                hours: hours,
                minutes: minutes,
            };

            const defaultIndexDays = _.findIndex(data.days, (day) => day.value === SpoPeriodObject.days);
            const defaultIndexHours = _.findIndex(data.hours, (hour) => hour.value === SpoPeriodObject.hours);
            const defaultIndexMinutes = _.findIndex(data.minutes, (minute) => minute.value === Math.ceil(SpoPeriodObject.minutes / 15) * 15);

            const config = {
                title: this.translate.instant('OVERRIDE_SET_TIME'),
                items: [
                    [data.days],
                    [data.hours],
                    [data.minutes]
                ],

                defaultItems: {
                    0: data.days[defaultIndexDays].text,
                    1: data.hours[defaultIndexHours].text,
                    2: data.minutes[defaultIndexMinutes].text
                },

                positiveButtonText: this.translate.instant('CONFIRM'),
                negativeButtonText: this.translate.instant('CANCEL'),
                displayKey: 'text',
                theme: theme
            };

            //@ts-ignore
            window.SelectorCordovaPlugin.showSelector(config, (result) => {

                console.log('result', result);

                const days_value = data.days[result[0].index].value;
                const hours_value = data.hours[result[1].index].value;
                const minutes_value = data.minutes[result[2].index].value;

                this.SpoPeriod = (days_value * 24 * 60) + (hours_value * 60) + minutes_value;
                this.SpoPeriodChanged = true;
                this.checkSpoPeriodUpdated();

            }, () => {

                console.log('Cancelled');
            });
        } else {

            const picker = await this.pickerCtrl.create({

                columns: [
                    {
                        name: 'days',
                        options: days,
                        selectedIndex: SpoPeriodObject.days
                    },
                    {
                        name: 'hours',
                        options: hours,
                        selectedIndex: SpoPeriodObject.hours
                    },
                    {
                        name: 'minutes',
                        options: minutes,
                        selectedIndex: Math.round(SpoPeriodObject.minutes / 15)
                    },
                ],
                buttons: [
                    {
                        text: this.translate.instant('CANCEL'),
                        role: 'cancel',
                    },
                    {
                        text: this.translate.instant('CONFIRM'),
                        handler: (value) => {

                            this.SpoPeriod = (value.days.value * 24 * 60) + (value.hours.value * 60) + value.minutes.value;
                            this.SpoPeriodChanged = true;
                            this.checkSpoPeriodUpdated();
                        },
                    },
                ],

                backdropDismiss: false,
                mode: 'ios',
                cssClass: 'overridePicker',
                animated: false
            });

            await picker.present();
        }
    }

    // -----------------------------------------------------------------------------------------------------------------

    // add the "s" if it's plural -> nothing if it's 1 (day vs days)
    getS(i) {

        return i !== 1 ? 's' : '';
    }

    // -----------------------------------------------------------------------------------------------------------------

    updateSpoPeriodText() {

        let SpoPeriodObject = this.rmsOrMczExtended.getSetpointOverrideInfo(this.SpoPeriod, this.dualSetpointMode).overrideDateTimeSet;

        if (this.rmsOrMczExtended.getSetpointOverrideInfo(null, this.dualSetpointMode).isOverride && !this.SpoPeriodChanged) {

            SpoPeriodObject = this.rmsOrMczExtended.getSetpointOverrideInfo(this.rmsOrMczExtended.getCorrectOverrideProperties(this.dualSetpointMode).PAR_SpoPeriod, this.dualSetpointMode).currentOverrideDateTime;
        }

        const days = SpoPeriodObject.days;
        const hours = SpoPeriodObject.hours;
        const minutes = SpoPeriodObject.minutes;

        let minutes_text = '';
        let hours_text = '';
        let days_text = '';

        if (minutes) {

            minutes_text = `${minutes} ${this.translate.instant('minute' + this.getS(minutes))}`;
        }

        if (hours) {

            hours_text = `${hours} ${this.translate.instant('hour' + this.getS(hours))}`;

            if (minutes) {

                hours_text += ' and ';
            }
        }

        if (days) {

            days_text = `${days} ${this.translate.instant('day' + this.getS(days))}`;

            if (minutes && hours) {

                days_text += ', ';
            } else if (minutes || hours) {

                days_text += ' and ';
            }
        }

        let text = days_text + hours_text + minutes_text;

        // 0 minutes
        if (!text) {

            text = `0 ${this.translate.instant('minutes')}`;
        }

        this.SpoPeriodText = text;
    }

    // -----------------------------------------------------------------------------------------------------------------

    async save() {

        const PAR_SpoStart = Math.floor(moment().unix());
        const minDifference = this.MCU.PAR_UM === 0 ? 2 : 1;

        this.store.dispatch(new ChangeTableValues(this.table, this.rmsOrMczExtended.id, {[this.rmsOrMczExtended.getCorrectOverrideProperties(this.dualSetpointMode).PAR_SpoSetTemp]: this.SpoSetTemp}));

        // If an override violate the 2° difference -> I start 2 overrides.
        const checkDualSetpointViolationAndStartLinkedOverride = (rmsOrMcz: RMS_Extended | MCZ_Extended, table: string) => {

            const dualSetpointOverrideMinimumDifferenceViolation = this.page.getDualSetpointOverrideMinimumDifferenceViolation(null, this.dualSetpointMode, this.MCU, this.SpoSetTemp, rmsOrMcz);

            switch (this.dualSetpointMode) {

                case DUAL_MODE.heating:

                    if (rmsOrMcz instanceof RMS_Extended && !rmsOrMcz.autoModeRmsModeNotPresent(DUAL_MODE.cooling) || rmsOrMcz instanceof MCZ_Extended) {

                        if (dualSetpointOverrideMinimumDifferenceViolation.present) {

                            this.store.dispatch(new ChangeTableValues(table, rmsOrMcz.id, {[this.rmsOrMczExtended.getCorrectOverrideProperties(DUAL_MODE.cooling).PAR_SpoSetTemp]: this.SpoSetTemp + minDifference}));
                        }

                        // Logic similar to the one marked with a **
                        if ((this.SpoPeriodChanged && this.dualSetpointOverrideUpdateTimeViolation.present) || (dualSetpointOverrideMinimumDifferenceViolation.present && !rmsOrMcz.getSetpointOverrideInfo(null, DUAL_MODE.cooling).isOverride)) {

                            this.store.dispatch(new ChangeTableValues(table, rmsOrMcz.id, {[rmsOrMcz.getCorrectOverrideProperties(DUAL_MODE.cooling).PAR_SpoPeriod]: this.SpoPeriod}));
                            this.store.dispatch(new ChangeTableValues(table, rmsOrMcz.id, {[rmsOrMcz.getCorrectOverrideProperties(DUAL_MODE.cooling).PAR_SpoStart]: PAR_SpoStart}));
                        }
                    }

                    break;

                case DUAL_MODE.cooling:

                    if (rmsOrMcz instanceof RMS_Extended && !rmsOrMcz.autoModeRmsModeNotPresent(DUAL_MODE.heating) || rmsOrMcz instanceof MCZ_Extended) {

                        if (dualSetpointOverrideMinimumDifferenceViolation.present) {

                            this.store.dispatch(new ChangeTableValues(table, rmsOrMcz.id, {[this.rmsOrMczExtended.getCorrectOverrideProperties(DUAL_MODE.heating).PAR_SpoSetTemp]: this.SpoSetTemp - minDifference}));
                        }

                        // Logic similar to the one marked with a **
                        if ((this.SpoPeriodChanged && this.dualSetpointOverrideUpdateTimeViolation.present) || (dualSetpointOverrideMinimumDifferenceViolation.present && !rmsOrMcz.getSetpointOverrideInfo(null, DUAL_MODE.heating).isOverride)) {

                            this.store.dispatch(new ChangeTableValues(table, rmsOrMcz.id, {[rmsOrMcz.getCorrectOverrideProperties(DUAL_MODE.heating).PAR_SpoPeriod]: this.SpoPeriod}));
                            this.store.dispatch(new ChangeTableValues(table, rmsOrMcz.id, {[rmsOrMcz.getCorrectOverrideProperties(DUAL_MODE.heating).PAR_SpoStart]: PAR_SpoStart}));
                        }
                    }
                    break;
            }
        }

        checkDualSetpointViolationAndStartLinkedOverride(this.rmsOrMczExtended, this.table);

        if (this.rmsOrMczExtended instanceof MCZ_Extended) {

            for (const rms of this.rmsOrMczExtended.rmsArrayOfMcz) {

                // The override from a Macro-Zone to a Zone must be propagated only if the Zone is Radiant, and actually has a Heating or Cooling terminal, depending on the type of Override launched
                if (!rms.autoModeRmsModeNotPresent(this.dualSetpointMode) && !this.page.supportService.noRadiant(rms, this.page.ZON, this.page.FNC, this.page.HYS, this.page.GRP, null)) {

                    this.store.dispatch(new ChangeTableValues('RMS', rms.id, {[rms.getCorrectOverrideProperties(this.dualSetpointMode).PAR_SpoSetTemp]: this.SpoSetTemp}));
                    checkDualSetpointViolationAndStartLinkedOverride(rms, 'RMS');

                    // I check that, if there are 2 dual setpoint overrides active, they actually respect the 2° difference. Otherwise, I adjust them (for example, I could put a zone to 80° / 82° override, then activate a 74° macro-zone override: in this case, the zone is changed to 72° / 74°, instead of 80° / 74°, which is wrong)
                    switch (this.dualSetpointMode) {

                        case DUAL_MODE.heating:

                            if (rms.getSetpointOverrideInfo(null, DUAL_MODE.cooling).isOverride) {

                                if (rms.PAR_SpoSetTempC < this.SpoSetTemp + minDifference) {

                                    this.store.dispatch(new ChangeTableValues('RMS', rms.id, {'PAR_SpoSetTempC': this.SpoSetTemp + minDifference}));
                                }
                            }
                            break;

                        case DUAL_MODE.cooling:

                            if (rms.getSetpointOverrideInfo(null, DUAL_MODE.heating).isOverride) {

                                if (rms.PAR_SpoSetTempH > this.SpoSetTemp - minDifference) {

                                    this.store.dispatch(new ChangeTableValues('RMS', rms.id, {'PAR_SpoSetTempH': this.SpoSetTemp - minDifference}));
                                }
                            }
                            break;
                    }
                }
            }
        }

        // If there is already an override, but I haven't changed its period -> don't change the SpoPeriod and leave the current one**
        if (this.rmsOrMczExtended.getSetpointOverrideInfo(null, this.dualSetpointMode).isOverride && !this.SpoPeriodChanged) {

            await this.dismiss();
            return;
        }

        this.store.dispatch(new ChangeTableValues(this.table, this.rmsOrMczExtended.id, {[this.rmsOrMczExtended.getCorrectOverrideProperties(this.dualSetpointMode).PAR_SpoPeriod]: this.SpoPeriod}));
        this.store.dispatch(new ChangeTableValues(this.table, this.rmsOrMczExtended.id, {[this.rmsOrMczExtended.getCorrectOverrideProperties(this.dualSetpointMode).PAR_SpoStart]: Math.floor(PAR_SpoStart)}));

        if (this.rmsOrMczExtended instanceof MCZ_Extended) {

            for (const rms of this.rmsOrMczExtended.rmsArrayOfMcz) {

                if (!rms.autoModeRmsModeNotPresent(this.dualSetpointMode) && !this.page.supportService.noRadiant(rms, this.page.ZON, this.page.FNC, this.page.HYS, this.page.GRP, null)) {

                    this.store.dispatch(new ChangeTableValues('RMS', rms.id, {[rms.getCorrectOverrideProperties(this.dualSetpointMode).PAR_SpoPeriod]: this.SpoPeriod}));
                    this.store.dispatch(new ChangeTableValues('RMS', rms.id, {[rms.getCorrectOverrideProperties(this.dualSetpointMode).PAR_SpoStart]: Math.floor(PAR_SpoStart)}));
                }
            }
        }

        await this.dismiss();
    }

    checkSpoPeriodUpdated() {

        this.dualSetpointOverrideUpdateTimeViolation = new DualSetpointOverrideMinimumDifferenceViolation();

        switch (this.dualSetpointMode) {

            case DUAL_MODE.heating:

                // If the Set Time of Heating is higher of the cooling's override -> I treat it like I'm cancelling the cooling override (because the cooling override will finish before the heating's one)
                if (this.SpoPeriod * 60 > this.rmsOrMczExtended.RTU_SpoRemainC) {

                    const updateTimeOverrideText = 'DUAL_SETPOINT_UPDATE_TIME_HEATING_INCREASE_OVERRIDE_WARNING';

                    // I check if the 2° difference is respected in the event of cooling override finishing before the heating one. Otherwise, dualSetpointOverrideUpdateTimeViolation.present will be true, the red message will show, and I'll change both timers (the same applies for the next pieces of code)
                    this.dualSetpointOverrideUpdateTimeViolation = this.page.getDualSetpointOverrideMinimumDifferenceViolation(null, DUAL_MODE.cooling, this.MCU, this.rmsOrMczExtended.PAR_SetTempC, this.rmsOrMczExtended, false, true, updateTimeOverrideText);
                }

                // If the Set Time of Heating is lower of the cooling's override -> I treat it like I'm cancelling the heating override (because the heating override will finish before the cooling's one)
                else if (this.SpoPeriod * 60 < this.rmsOrMczExtended.RTU_SpoRemainC) {

                    const updateTimeOverrideText = 'DUAL_SETPOINT_UPDATE_TIME_HEATING_DECREASE_OVERRIDE_WARNING';
                    this.dualSetpointOverrideUpdateTimeViolation = this.page.getDualSetpointOverrideMinimumDifferenceViolation(null, DUAL_MODE.heating, this.MCU, this.rmsOrMczExtended.PAR_SetTempH, this.rmsOrMczExtended, false, true, updateTimeOverrideText);
                }

                break;

            case DUAL_MODE.cooling:

                if (this.SpoPeriod * 60 > this.rmsOrMczExtended.RTU_SpoRemainH) {

                    const updateTimeOverrideText = 'DUAL_SETPOINT_UPDATE_TIME_COOLING_INCREASE_OVERRIDE_WARNING';
                    this.dualSetpointOverrideUpdateTimeViolation = this.page.getDualSetpointOverrideMinimumDifferenceViolation(null, DUAL_MODE.heating, this.MCU, this.rmsOrMczExtended.PAR_SetTempH, this.rmsOrMczExtended, false, true, updateTimeOverrideText);
                } else if (this.SpoPeriod * 60 < this.rmsOrMczExtended.RTU_SpoRemainH) {

                    const updateTimeOverrideText = 'DUAL_SETPOINT_UPDATE_TIME_COOLING_DECREASE_OVERRIDE_WARNING';
                    this.dualSetpointOverrideUpdateTimeViolation = this.page.getDualSetpointOverrideMinimumDifferenceViolation(null, DUAL_MODE.cooling, this.MCU, this.rmsOrMczExtended.PAR_SetTempC, this.rmsOrMczExtended, false, true, updateTimeOverrideText);
                }
                break;
        }

        const setpointOverrideModal = document.querySelector('.SetpointOverrideModal');

        if (this.dualSetpointOverrideUpdateTimeViolation.present) {

            setpointOverrideModal?.classList.add('OverrideTallerModal');
        } else {

            setpointOverrideModal?.classList.remove('OverrideTallerModal');
        }
    }
}

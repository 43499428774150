import {Action} from '@ngrx/store';

/**
 * Instead of passing around action string constants and manually recreating
 * action objects at the point of dispatch, we create services encapsulating
 * each appropriate action group.
 */

export enum ActionsActionTypes {
    ExecuteAction = '[Actions] Execute Action',
    ExecuteActionSuccess = '[Actions] Execute Action Success',
    ExecuteActionError = '[Actions] Execute Action Error',
    LoadStatus = '[Actions] Load Status',
    LoadStatusSuccess = '[Actions] Load Status Success',

    LoadStatusError = '[Actions] Load Status Error',
    AlarmModeOn = '[Actions] Activate Alarm Mode To show the Popup',
    AlarmModeOff = '[Actions] Deactivate Alarm Mode To hide the Popup',
    HideBrowserDirectIpLoaderM = '[Actions] Hide the M loader in the direct-ip browser',
    AlertControllerLoaded = '[Actions] Alert Controller Loaded (useful for direct-ip browsers)',
}

export class ExecuteAction implements Action {
    readonly type = ActionsActionTypes.ExecuteAction;

    constructor(public action: string, public values: any = null) {

    }
}


export class ExecuteActionSuccess implements Action {
    readonly type = ActionsActionTypes.ExecuteActionSuccess;

    constructor(public data: any = null) {
    }
}

export class ExecuteActionError implements Action {
    readonly type = ActionsActionTypes.ExecuteActionError;

    constructor(public error: any) {
    }
}

export class LoadStatus implements Action {
    readonly type = ActionsActionTypes.LoadStatus;

    constructor() {
    }
}

export class LoadStatusSuccess implements Action {
    readonly type = ActionsActionTypes.LoadStatusSuccess;

    constructor(public data: any = null) {
    }
}

export class LoadStatusError implements Action {
    readonly type = ActionsActionTypes.LoadStatusError;

    constructor(public error: any) {
    }
}

export class AlarmModeOn implements Action {
    readonly type = ActionsActionTypes.AlarmModeOn;

    constructor() {
    }
}

export class AlarmModeOff implements Action {
    readonly type = ActionsActionTypes.AlarmModeOff;

    constructor() {
    }
}

export class HideBrowserDirectIpLoaderM implements Action {

    readonly type = ActionsActionTypes.HideBrowserDirectIpLoaderM;

    constructor() {
    }
}

export class AlertControllerLoaded implements Action {

    readonly type = ActionsActionTypes.AlertControllerLoaded;

    constructor() {
    }
}

export type ActionsActions =
    ExecuteAction |
    ExecuteActionSuccess |
    ExecuteActionError |
    LoadStatus |
    LoadStatusSuccess |
    LoadStatusError |
    AlarmModeOn |
    AlarmModeOff |
    HideBrowserDirectIpLoaderM |
    AlertControllerLoaded;


import {Injectable} from '@angular/core';
import {Action} from '@ngrx/store';
import {User} from '../models/user';

const PORTAL_PROXY_URL = 'http://localhost:9200/apiproxy';

/**
 * Instead of passing around action string constants and manually recreating
 * action objects at the point of dispatch, we create services encapsulating
 * each appropriate action group.
 */


export enum ConfigActionTypes {
    AppLoadConfig = '[AppConfig] App Load Config',
    ReloadConfig = '[Config] Reload Config',
    LoginConfigMenuMcuSelect = '[Config] Load Config From Menu Mcu Select',
    SaveConfig = '[Config] Save Config',
    ReloadConfigSuccess = '[Config] Reload Config Success',
    AppLoadConfigSuccess = '[AppConfig] App Load Config Success',
    AppLoadConfigZeroConfSuccess = '[AppConfig] App Load ZeroConf Config Success',
    LogoutConfigCompleted = '[Config] Logout Config Completed',
    ChangeConfigValue = '[Config] Change Config Value',
    LoginConfig = '[Config] Login Config',
    DiscoveryLoginConfig = '[Config] Discovery Login Config',
    LogoutConfig = '[Config] Logout Config',
    LogoutConfigSuccess = '[Config] Logout Config Success',

    DisconnectConfig = '[Config] Disconnect Config',
    DisconnectConfigSuccess = '[Config] Disconnect Config Success',
    DisconnectConfigCompleted = '[Config] Disconnect Config Completed',
    McuConfig = '[Config] Mcu Config',
    BusyConfig = '[Config] Busy Config',
    BusyLogged = '[Config] Busy Logged',
    IdleConfig = '[Config] Idle Config',
    ModeConfig = '[Config] Mode Config',
    CleanupWeatherCompleted = '[Config] Weather Cleanup Config',
    UpdateUser = '[Config] Update User',
    OpenTerms = '[Config] Open Terms',
    BackendCompliantRelease200 = '[Config] Backend Compliant Release 200',
    BackendNotCompliantRelease200 = '[Config] Backend Not Compliant Release 200',
    TimeoutConfig = '[Config] Timeout Config',
    SaveAttributesConfig = '[Config] SaveAttributesConfig',
    SaveAppCachedVersionConfig = '[Config] Save App Cached Version Config'
}

export class ReloadConfig implements Action {
    readonly type = ConfigActionTypes.ReloadConfig;

    constructor() {
    }
}

export class AppLoadConfig implements Action {
    readonly type = ConfigActionTypes.AppLoadConfig;

    constructor(public isApp: boolean) { // App or Browser
    }
}

export class BackendCompliantRelease200 implements Action {
    readonly type = ConfigActionTypes.BackendCompliantRelease200;

    constructor() {
    }
}

export class BackendNotCompliantRelease200 implements Action {
    readonly type = ConfigActionTypes.BackendNotCompliantRelease200;

    constructor() {
    }
}

export class LoginConfigMenuMcuSelect implements Action {
    readonly type = ConfigActionTypes.LoginConfigMenuMcuSelect;

    constructor(public currentUser: any, public currentMcu?: any) {
    }
}

export class UpdateUser implements Action {
    readonly type = ConfigActionTypes.UpdateUser;

    constructor(public currentUser: any) {
    }
}

export class ReloadConfigSuccess implements Action {
    readonly type = ConfigActionTypes.ReloadConfigSuccess;

    constructor(public data: any) {
    }
}

export class AppLoadConfigSuccess implements Action {
    readonly type = ConfigActionTypes.AppLoadConfigSuccess;

    constructor(public data: any) {
    }
}

export class AppLoadConfigZeroConfSuccess implements Action {
    readonly type = ConfigActionTypes.AppLoadConfigZeroConfSuccess;

    constructor(public data: any) {
    }
}

export class SaveConfig implements Action {
    readonly type = ConfigActionTypes.SaveConfig;

    constructor() {
    }
}

export class ChangeConfigValue implements Action {
    readonly type = ConfigActionTypes.ChangeConfigValue;

    constructor(public option: string, public value: any) {
    }
}

export class LoginConfig implements Action {
    readonly type = ConfigActionTypes.LoginConfig;

    constructor(public currentUser: User, public currentMcu: any) {
         console.log('LoginConfig constructor, currentUser vale');
         //console.log(`user:${currentUser} currentMcu ${currentMcu}`);
      console.log(currentUser);
    }
}

export class DiscoveryLoginConfig implements Action {
    readonly type = ConfigActionTypes.DiscoveryLoginConfig;

    constructor(public currentUser: any, public currentMcu: any, public fromAutoSwitch: boolean) {
        // console.log('DiscoveryLoginConfig constructor, currentUser vale');
        // console.log(`user:${currentUser.username} currentMcu ${currentMcu}`);
    }
}

export class OpenTerms implements Action {
    readonly type = ConfigActionTypes.OpenTerms;

    constructor() {
    }
}

//disconnect from LAN/WLAN
export class DisconnectConfig implements Action {
    readonly type = ConfigActionTypes.DisconnectConfig;

    constructor(public zeroConfFromGuest: boolean) {
    }
}

export class DisconnectConfigSuccess implements Action {
    readonly type = ConfigActionTypes.DisconnectConfigSuccess;

    constructor(public zeroConfFromGuest: boolean) {
    }
}

export class DisconnectConfigCompleted implements Action {
    readonly type = ConfigActionTypes.DisconnectConfigCompleted;

    constructor() {
    }
}


export class LogoutConfig implements Action {
    readonly type = ConfigActionTypes.LogoutConfig;

    constructor() {
    }
}

export class LogoutConfigSuccess implements Action {
    readonly type = ConfigActionTypes.LogoutConfigSuccess;

    constructor() {
    }
}

export class LogoutConfigCompleted implements Action {
    readonly type = ConfigActionTypes.LogoutConfigCompleted;

    constructor() {
    }
}

export class McuConfig implements Action {
    readonly type = ConfigActionTypes.McuConfig;

    constructor(public mcuName: string, public resetCurrentDateTime: boolean = true) {
    }
}

export class BusyConfig implements Action {
    readonly type = ConfigActionTypes.BusyConfig;

    constructor() {
    }
}

export class BusyLogged implements Action {
    readonly type = ConfigActionTypes.BusyLogged;

    constructor() {
    }
}

export class IdleConfig implements Action {
    readonly type = ConfigActionTypes.IdleConfig;

    constructor() {
    }

}

export class ModeConfig implements Action {
    readonly type = ConfigActionTypes.ModeConfig;

    constructor() {
    }
}

export class CleanupWeatherCompleted implements Action {
    readonly type = ConfigActionTypes.CleanupWeatherCompleted;

    constructor() {
    }
}

export class TimeoutConfig implements Action {
    readonly type = ConfigActionTypes.TimeoutConfig;

    constructor() {
    }
}

export class SaveAttributesConfig implements Action {

    readonly type = ConfigActionTypes.SaveAttributesConfig;

    constructor(public attributes) {
    }
}

export class SaveAppCachedVersionConfig implements Action {

    readonly type = ConfigActionTypes.SaveAppCachedVersionConfig;

    constructor(public appVersion: string) {
    }
}

export type ConfigActions = AppLoadConfig | ReloadConfig | LoginConfigMenuMcuSelect | SaveConfig |
    ReloadConfigSuccess | AppLoadConfigSuccess | AppLoadConfigZeroConfSuccess |
    ChangeConfigValue |
    LoginConfig | DiscoveryLoginConfig |
    LogoutConfig |
    LogoutConfigSuccess |
    LogoutConfigCompleted |
    DisconnectConfigSuccess |
    DisconnectConfig |
    DisconnectConfigCompleted |
    McuConfig |
    BusyConfig | BusyLogged |
    IdleConfig | CleanupWeatherCompleted |
    ModeConfig | UpdateUser | OpenTerms | BackendCompliantRelease200 | BackendNotCompliantRelease200 | TimeoutConfig | SaveAttributesConfig | SaveAppCachedVersionConfig;

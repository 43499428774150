
export const TRANSLATIONS = {

    "ACCESS_MODALITY": "Access modality",
    "NAVIGATION": "Navigation",
    "NAVIGATION_DESCRIPTION": "Enable bottom tabs navigation",
    "ADD_ZONES": "Add zones",
    "ADDRESS": "Address",
    "ADVANCED_SETTINGS": "Advanced settings",
    "ADVANCED_CONF_MODE":"Advanced conf. mode",
    "ADVANCED_OPERATIONS":"Advanced operations",
    "SWITCH_LOCAL_SYSTEM_STARTUP": "Automatically switch to local system at startup",
    "ENTER_ADVANCED_CONF_MODE":"Enter the code to access advanced conf. mode.",
    "WARNINGS_HOME": "Warnings",
    "WARNING":"Warning",
    "AIR_SYSTEM": "Air System",
    "AIR_UNIT_SCHEDULE": "Air unit schedule",
    "AIR_UNITS": "Air units",
    "ALARM": "Alarms",
    "ALARMS_HOME": "Alarms",
    "ALERT": "Warning",
    "ALL": "All",
    "ALL_RIGHTS_RESERVED":"All Rights Reserved",
    "ALL_DAYS": "All",
    "ALL_ZONES": "All",
    "ALWAYS_OFF": "Always OFF",
    "ALWAYS_ON": "Always ON",
    "APPLY_SCHEDULE_TO": "Apply to",
    "APPLIED_TO":"Applied to",
    "APPLY_SCHEDULE_TO_MSG": "This schedule can be visible and applied to the following categories",
    "ASSIGN_NEW_NAME": "Assign new name",
    "ASSIGN_ZONE": "Assign zone",
    "ASSIGN_ZONE_TEXT": "Assign zone to this macrozone",
    "AUTO": "Auto",
    "AUTO_ADAPTATIVE_COMFORT_MODE": "Adaptive comfort",
    "AUTO_ADAPTATIVE_COMFORT_OFF": "Auto-adaptive comfort OFF",
    "AUTO_ADAPTATIVE_COMFORT_ON": "Auto-adaptive comfort ON",
    "AUTO_CHANGEOVER": "Auto changeover settings",
    "AUTO_MODE_SETTINGS":"Auto mode settings",
    "DEADBAND_MODE":"Deadband",
    "HC_SETPOINT_DIFF_MODE":"H/C setpoint differentials",
    "DUAL_SETPOINTS_MODE":"(Beta) Dual setpoint",
    "HEATING_SEASON_REF":"Heating reference season",
    "COOLING_SEASON_REF":"Cooling reference season",
    "AUTO_CHANGEOVER_DIFF": "Delta T",
    "AUTO_CHANGEOVER_OVER_DIFF": "+ ΔT",
    "AUTO_CHANGEOVER_SUBTITLE_OLD": "The system automatically switches between heating and cooling to keep your home temp at a specific setpoint within a set range  (deadband: [(SetPoint–ΔT),(SetPoint+ΔT)]).",
    "AUTO_CHANGEOVER_SUBTITLE":"The system automatically switches between heating and cooling through zone voting logic. Depending on its temp and SetPoint, each zone can express a weighted vote for heating (H), cooling (C), or none (neutral) contingent on two differential temps +ΔT and -ΔT applied to each SetPoint, thus creating a temp range (deadband: [(SetPoint–ΔT),(SetPoint+ΔT)]). Based on the number of votes, the logic decides the operating mode. Zones with a temp above the deadband vote for C, below the deadband for H, and within the deadband are considered neutral and do not vote.",
    "AUTO_CHANGEOVER_DUAL_SETPOINT_SUBTITLE":"The system automatically switches between heating and cooling through zone voting logic. Depending on its temp and SetPoints (Heating and Cooling SetPoints), each zone can express a weighted vote for heating (H), cooling (C), or none (neutral) contingent on two SetPoints (Heating and Cooling SetPoints), thus creating a temp range [deadband:Heating SetPoint, Cooling SetPoint]. Based on the number of votes, the logic decides the operating mode. Zones with a temp above the Cooling SetPoint vote for C, below the Heating SetPoint for H, and within the deadband are considered neutral and do not vote.",

    "AUTO_CHANGEOVER_4PIPE_SUBTITLE": "The system automatically satisfies heating and cooling requests in each room to keep the temp around a specific SetPoint within a set range (deadband: [(SetPoint–ΔT),(SetPoint+ΔT)]).",
    "AUTO_SP_DIFF_SUBTITLE":"The system automatically satisfies heating and cooling requests in each room to keep the temp around a specific SetPoint within a set range (SetPoint +/-  H/C SetPoint ΔT).",
    "DUAL_SETPOINT_SUBTITLE":"The system automatically satisfies heating and cooling requests in each room to keep the temp over a SetPoint (H) in heating and under a SetPoint (C) in cooling.",
    "AUTO_DEADBAND_4PIPE":"Dead band settings:",

    "AUTO_CHANGEOVER_UNDER_DIFF": "- ΔT",
    "HC_DIFF":"heating/cooling differentials",
    "AUX":"Auxiliary",
    "C_DIFF":"Cooling differential (C mode)",
    "H_DIFF":"Heating differential (H mode)",
    "C_DIFF_SUBTITLE_1":"This cooling differential is added to the zone setpoint for the activation of the",
    "C_DIFF_SUBTITLE_2":"in cooling mode to keep the temp below setpoint + differential",
    "H_DIFF_SUBTITLE_1":"This heating differential is subtracted to the zone setpoint for the",
    "H_DIFF_SUBTITLE_2":" in heating mode to keep the temp above setpoint - differential",
     "AVERAGE": "Average",
    "BACKUP": "Backup/Restore",
    "BACK_TO_LOGIN":"back to login",
    "BACKUPS": "Backup/Restore",
    "BIOSET_SETTINGS":"Bioset settings",

    "BLE_BLUETOOTH_DISABLED_TITLE": "Bluetooth is off",
    "BLE_BLUETOOTH_DISABLED_MESSAGE": "Bluetooth has been turned off. Turn it on and click OK or return to the system selection",
    "BLE_BLUETOOTH_DISABLED_SCAN_MESSAGE": "The Bluetooth is off, please turn it on",
    "BLE_CONNECT":"Connect",
    "BLE_CONNECT_VIA_BLUETOOTH":"Connect via Bluetooth",
    "BLE_CONNECTING_VIA_BLUETOOTH":"Connecting..",
    "BLE_CONNECTING_VIA_BLUETOOTH_FULL":"Connecting via Bluetooth..",
    "BLE_PERMISSION_DENIED": "Unauthorized location permissions. Change them in your device's app permissions",
    "BLE_CONNECTED_VIA_BLUETOOTH":"Connected via Bluetooth",
    "BLE_DISABLED_TOAST":"Bluetooth is disabled. Turn ON the Bluetooth",
    "BLE_DISCONNECT_FROM_BLUETOOTH": "Disconnect from Bluetooth",
    "BLE_DISCONNECT_FROM_BLUETOOTH_MANUAL": "Do you want to disconnect from Bluetooth? If you disconnect you will be redirected to the system selection page",
    "BLE_DISCONNECTING_FROM_BLUETOOTH": "Disconnecting..",
    "BLE_DISCONNECTED_FROM_BLUETOOTH": "Disconnected from Bluetooth",
    "BLE_EMERGENCY_TITLE": "Remote connection unavailable",
    "BLE_EMERGENCY_TEXT_BROWSER": "No remote connection to your system.<br><br>You can use the Messana APP from your smartphone and connect to your system via Bluetooth.",
    "BLE_EMERGENCY_TEXT_1": "No remote connection to your system. You can try to go back to the",
    "BLE_EMERGENCY_TEXT_2": "or you can use a bluetooth emergency connection staying closer than 15 feet from your mBox.",
    "BLE_SYSTEM_SELECTION": "system selection",
    "BLE_LOGIN_PAGE": "login page",
    "BLE_EMERGENCY_RESTORED_TITLE": "Internet Connection restored",
    "BLE_EMERGENCY_RESTORED_MESSAGE": "Your internet connection has been restored. Do you want to reconnect or keep using the Bluetooth?",
    "BLE_EMERGENCY_RESTORED_ACTION_RECONNECT": "Reconnect",
    "BLE_EMERGENCY_RESTORED_ACTION_KEEP": "Keep Bluetooth",
    "BLE_EMERGENCY_RESTORED_POSTPONE": "Postpone by 10 minutes",
    // "BLE_EMERGENCY_RESTORED_POSTPONE_TOAST": "Postponeed by 10 minutes",
    "BLE_LOW_RSSI_NOTIFICATION":"You are too far from the mBox, please stay closer than 15 feet",
    "BLE_OUT_OF_RANGE_UNSTABLE":"Bluetooth connection is unstable or out of range",
    "BLE_POPUP_DETAIL_TEXT": "Please stay in range (< 15 feet) and turn on your Bluetooth before connecting. The Bluetooth connection is available only on systems equipped with bluetooth hardware.",
    "BLE_REQUEST_BLOCKED": "Can't handle this request. Please try again",
    "BLE_RETRY": "Retry",
    "BLE_SAVE_FAILED": "Operation Failed",
    "BLE_SAVE_FAILED_TEXT": "The Bluetooth failed to execute this request. Do you want to retry?",
    "BLE_SCAN":"SCAN",
    "BLE_UNAVAILABLE":"Bluetooth Unavailable",
    "BLE_UNAVAILABLE_TEXT":"The device couldn't find a Bluetooth server. Do you want to scan again?",

    "DEHUM_DIFF":"semi-band differential",
    "MAX_DEHUM":"Humidity limit to avoid cellar effect",
    "MIN_DEHUM":"Humidity limit to avoid artificial effect",
    "BUFFER_TANK_CONTROL_MODE": "Buffer tank control mode",
    "BUFFER_TANK_COOLING_MAX_TEMP": "Always maintain temp below",
    "BUFFER_TANK_COOLING_TARGET": "Cooling setpoint temp",
    "BUFFER_TANK_FIXED_POINT": "Fixed temp",
    "BUFFER_TANK_HEATING_TARGET": "Heating setpoint temp",
    "BUFFER_TANK_SET": "Tank setpoint temp",
    "BUFFER_TANK_SET_MENU": "Buffer tank setpoint temp",
    "BUFFER_TANK_TARGET": "Tank target temp",
    "BUFFER_TANK_TARGET_MENU": "Buffer tank target temp",
    "BUFFER_TANK_TEMP": "Buffer tank temp",
    "BUFFER_TANK_TEMP_MENU": "Buffer tank temp",
    "BUFFER_TANKS": "Buffer tanks",
    "CANCEL": "Cancel",
    "MAIN_MENU":"Main menu",
    "CELSIUS": "Celsius (C)",
    "CHANGING_MODE": "Changing",
    "CITY": "City",
    "CL_EXT_TEMP1": "X1 outdoor temp",
    "CL_EXT_TEMP2": "X2 outdoor temp",
    "CL_VALUE1": "Y1 heating buffer tank setpoint",
    "CL_VALUE2": "Y2 heating buffer tank setpoint",
    "CLOSE":"Close",
    "COMFORT_RANGE": "Comfort range",
    "COMMUNICATION_ERROR": "Communication error",
    "M_TOUCH_COMMUNICATION_ERROR": "mTouch communication error",
    "CONDENSER_TEMPERATURE": "Condenser temp",
    "CONDENSER_TEMP": "Condenser temp",
    "CONFIGURATION": "Configuration",
    "COP": "COP",
    "COPYRIGHT":"Copyright © 2024 Messana Inc.",
    "ADDRSS_CONFIGURATION":"IP address configuration",
    "HOSTNAME": "Hostname",
    "CONFIRM": "Confirm",
    "CHANGE":"Change",
    "CONFIRM_SEASON_MODE": "This operation might take a few minutes and can not be interrupted. Do you wish to continue?",
    "CONFIRM_SEASON_AUTO_MODE": "To operate in Auto mode the system requires common setpoints and the heating setpoints will be applied. The room schedules used in heating will be applied to cooling mode. All cooling setpoints and schedule settings will be lost. This operation is not reversible. This operation might take a few minutes and can not be interrupted. Do you wish to continue?",
    "CONFIRM_SCHEDULE": "Several temp selected. Are you sure to set this temp?",
    "CONFIRM_SEASON": "Are you sure to set this season mode?",
    "CONFIRM_AAC_CHANGE": "Are you sure? This operation could temporally switch the H/C mode",
    "COOLING_MODE": "Cooling",
    "COOLING_SUPPRESSD_1":"Cooling request ignored in",
    "COOLING_SUPPRESSD_2":"due to a concurrent heating request in the shared room",
    "COPY_DAY": "Copy daily schedule",
    "COPY_DAY_HEATING": "Copy daily heating sched.",
    "COPY_DAY_COOLING": "Copy daily cooling sched.",
    "COPY_DAY_TITLE": "Copy daily schedule",
    "COPY_DAY_TO": "Copy schedule to the following days:",
    "COUNTRY": "Country",
    "DAYS": "Days",
    "DAY_UNIT":"d",
    "HOUR_UNIT": "h",
    "MINUTE_UNIT": "m",
    "SECOND_UNIT": "s",
    "DEFINE_ZONE": "Define zone H/C mode",
    "DEHUMIDIFICATION": "Dehumidification",
    "DEHUMIDIFICATION_INFO": "The dehumidification is a process in which excess air moisture (latent heat) is removed. Since the effect of the dehumidification is cooling, typically this function is only available when the system is set in cooling mode. In radiant cooling systems, this is an is an essential function to maintain the dew point down and avoid condensation.",
    "DEHUMIDIFICATION_LEVEL_INFO":"When there are concurrent calls for both dehumidification and cooling from a fan coil, the higher speed setting of the two functions shall be used.",
  "CUSTOM_TEMPALTES_BTN":"Default TMP script",
    "DELETE": "Delete",
    "DELETED": "deleted",
    "DELETE_BACKUP_CONFIRM": "Are you sure to delete the backup?",
    "DELETE_BLOCK": "Delete Block",
    "DELETE_MACRO_ZONE": "Delete macrozone",
    "DELETE_PERIOD": "Delete daily period",
    "DELETE_SCHEDULATION": "Are you sure to delete the schedule ",
    "DELETE_SCHEDULATION_INTERVAL": "Delete schedule period",
    "DEW_POINT": "Dew point",
    "DHW_DIFF_OFF": "Off differential",
    "DHW_DIFF_ON": "On differential",
    "DHW_DIFF_DESC_OFF": "The DHW request stops when the DHW tank temp reaches",
    "DHW_DIFF_DESC_ON_1": "When the water temp goes below",
    "DHW_DIFF_DESC_ON_2": "activates the DHW request to bring the water temp up to",
    "DHW_DIFF_DESC_ON_3": "(target temp).",
    "DHW_RECIRCULATION_ON": "Recirculation on",
    "DHW_RECIRCULATION_SET": "Recirculation setpoint",
    "DHW_RECIRCULATION_SET_MENU": "Recirculation pump setpoint",
    "DHW_RECIRCULATION_TEMP": "Recirculation temp",
    "DHW_RECIRCULATION_TEMP_MENU": "DHW Recirculation temp",
    "DHW_SET_TEMP": "DHW setpoint",
    "DHW_SET_TEMP_MENU": "DHW setpoint temp",
    "DHW_SETBACK": "DHW setback",
    "DHW_SETBACK_SETPOINT": "Setback setpoint",
    "DHW_SETBACK_SETPOINT_DESCRIPTION": "During off schedule time the DHW tank is maintained at the Setback setpoint temp of",
    "DHW_SETPOINT_BELOW_WARNING": "Setpoint below the DHW target temp",
    "TNK_SETPOINT_BELOW_WARNING": "Setpoint below the buffer tank target temp",
    "TNK_SETPOINT_ABOVE_WARNING": "Setpoint above the buffer tank target temp",
    "DHW_TARGET_TEMP": "DHW target temp",
    "DHW_TEMP": "DHW temp",
    "DHW_TEMP_MENU": "DHW tank temp",
    "DHW_TEMP_SCHEDULE_ON": "No hot water available during off schedule time.",
    "DHW_SYSTEMS":"DHW systems",
    "DIFF_COMP": "DHW differential",
    "DIFF_COMP_C": "Cooling differential",
    "DIFF_COMP_H": "Heating differential",
    "DIFF_LEVEL": "Differential",
    "FILTER_USAGE":"Filter usage",
    "DIRTY_FILTER":"Dirty filter",
    "DIRTY_FILTER_INFO":"To properly clean a Messana Air Units' filters, use a vacuum or air compressor to vacuum or blow the debris from the filter. Only replace the filter if obvious signs of deterioration are present. For third-party air units, please refer to the manufacturers instructions.",
    "MAX_SPEED_DIFF_LEVEL": "Max speed differential",
    "DIFFERENT_SCHEDULATION": "Different schedule",
    "DISABLED": "Disabled",
    "DISCONNECT":"Disconnect",
    "DISCONNECT_TITLE":"Disconnection",
    "DISCONNECT_CONFIRM":"Are you sure to disconnect from the current system?",
    "DISCONNECT_BUTTON":"Disconnect",
    "NO_SCHED_SELECTED":"No sched. selected",
    "NO_SCHEDULE":"-- No schedule --",
    "NO_USER_FOUND":"No user found with the given username/e-mail address",
    "DOMESTIC_HOT_WATER_SYSTEMS": "Domestic hot water system",
    "EDIT_DAY": "Modify daily schedule",
    "EDIT_SCHEDULE": "Edit schedule",
    "EMPTY_NAME": "Empty name",
    "ENERGY_SAVING": "Energy saving",
    "ENERGY_SAVING_DIFFERENTIAL": "Energy saving differential",
    "ENGLISH": "English",
    "ERR_CODE": "Use mConfig to load the configuration files",
    "ERR_CODE_TITLE": "Device not configured",
    "ERR_CODE_UPDATE": "Your system requires a newer version of this app",
    "ERR_CODE_TITLE_UPDATE": "WARNING",
    "EVAPORATION_TEMPERATURE": "Evaporator temp",
    "EVAPORATION_TEMP": "Evaporator temp",
    "EVERY_DAY":"every day",
    "EVERY_2_DAYS":"every 2 days",
    "EVERY_WEEK":"every week",
    "EXCEPTION_KEEP": "Keep",
    "EXCEPTION_MESSAGE": "Do you want to override exceptions?",
    "EXCEPTION_OVERRIDE": "Override",
    "EXCEPTION_TITLE": "Exceptions",
    "EXTERNAL_TEMPERATURE": "Fresh air intake temp",
    "EXTERNAL_TEMP": "Fresh air intake temp",
    "FAHRENHEIT": "Fahrenheit (F)",
    "FAN_COILS": "Fan coils",
    "FILTER_ALARM_RESET":"Reset",
    "DIRTY_FILTER_INFO_START":"The filter is exhausted, please press the",
    "DIRTY_FILTER_INFO_END":"button when you have cleaned or replaced the filter.",
    "FILTER_DELAY":"Filter cleaning interval",
    "FNC_INTEGRATION": "Fan coil integration",
    "FNC_HEATING_INTEGRATION": "Heating integration",
    "FNC_COOLING_INTEGRATION": "Cooling integration",
    "FOLLOW_HEATING_COOLING_LOADS": "Follow heating/cooling loads",
    "FOLLOWING_ZONE": "Following zone",
    "FORCED":"forced",
    "FORGOT_PASSWORD":"Forgot password?",
    "SIGN_IN": "Sign in",
    "FREE_COOLING": "Free cooling",
    "FREE_COOLING_ACTIVATION_LIMIT": "Activation limit",
    "FREE_COOLING_END_LIMIT": "End limit",
    "FROM": "From",
    "FULL_ADDRESS": "Full address",
    "GENERAL_SETTINGS": "General",
    "GO_TO":"Go to ",
    "GUEST":"Guest",
    "H":"H",
    "C":"C",
    "H_C_SOURCE_RETURN_TEMP": "Return temp",
    "H_C_SOURCES": "Heating/Cooling  sources",
    "H_C_SOURCES_DELIVERY_TEMP": "Supply temp",
    "H_C_SOURCE_FLOW":"Pump flow",
    "H_C_SOURCE_MODULATION":"Modulation",
    "H_C_OUTPUT_KW": "Output",
    "H_C_SOURCE_TARGET_TEMP":"Target temp",
    "H_C_SOURCE_OUTDOOR_TEMP":"Outdoor temp",
    "H_SLASH_C":"H/C",
    "H_SHORT": "H",
    "C_SHORT": "C",
    "H_AND_C_SHORT": "H & C",
    "HEAT_PUMPS": "Heat pumps",
    "ENERGY_SOURCES": "Energy sources",
    "HEAT_PUMP_CONTROL_MODE_H_C": "H/C heat pump control mode",
    "HEAT_PUMP_CONTROL_MODE_DHW": "DHW HP control mode",
    "HEAT_PUMPS_TARGET_TEMP" : "Target temp",
    "HEAT_PUMPS_TARGET_TEMP_HEATING" : "Target temp heating",
    "HEAT_PUMPS_TARGET_TEMP_COOLING" : "Target temp cooling",
    "HP_FIXED_TARGET_TEMP": "Fixed temp",
    "HP_FOLLOW_BUFFER_TANK": "Follow buffer tank",
    "HP_FOLLOW_DHW": "Follow DHW target temp",
    "HP_CONTROL_PARAMETERS" : "Control parameters",
    "HP_DIFF_COMP_DHW_SUBLABEL": "This differential is added to the DHW target temp.",
    "HP_STATE_PARAMETERS" : "State parameters",
    "HEAT_RECOVER_VENTILATION": "Heat recover ventilation",
    "HEAT_RECOVER_VENTILATION_ES": "Energy saving",
    "HEAT_RECOVER_VENTILATION_ES_SUBTITLE": "Does not use energy sources during HRV",
    "HEAT_RECOVER_VENTILATION_ES_SUBTITLE_MOBILE": "No use of energy sources in HRV",
    "HEATING_AND_COOLING": "Heating and cooling",
    "HEATING_MODE": "Heating",
    "HOME": "Home",
    "day": "day",
    "days": "days",
    "hour": "hour",
    "hours": "hours",
    "minutes": "minutes",
    "minute": "minute",
    "min": "min",
    "Hour": "Hour",
    "HUMIDIFICATION": "Humidification",
    "HUMIDIFICATION_INFO": "The humidification is a process in which air moisture (latent heat) is added. Typically this function is only available when the system is set in heating during the winter time when the outdoor humidity is low.",
    "HUMIDIFICATION_LEVEL_INFO":"When there are concurrent calls for both humidification and heating from a fan coil, the higher speed setting of the two functions shall be used.",
    "HUMIDITY": "Humidity",
    "REL_HUMIDITY":"Rel. humidity",
    "RELATIVE_HUMIDITY":"Relative humidity",
    "HYDRONIC_SYSTEM_TARGET": "Target temp",
    "HYDRONIC_SYSTEMS": "Hydronic systems",
    "INTEGRATION": "Convective integration",
    "INTEGRATION_OFF": "OFF at ",
    "INTEGRATION_ON": "ON at ",
    "INTERNET_CONNECTION_RESTORED": "Internet connection restored",
    "INTERNET_CONNECTION_UNAVAILABLE": "Internet connection unavailable",
    "INVALID_IP_ADDRESS": "Invalid IP address",
    "INVALID_HOSTNAME": "Valid characters for hostnames are letters from a to z, the digits from 0 to 9, and the hyphen (-). A hostname may not start with a hyphen.",
    "IP_ADDRESS": "IP address",
    "VPN_IP_ADDRESS": "VPN IP address",
    "MAC_ADDRESS": "MAC address",
    "IP_ADDRESS_IS_REQUIRED": "IP address is required",
    "HOSTNAME_IS_REQUIRED": "Hostname is required",
    "ITALIAN": "Italian",
    "LANGUAGE": "Language",
    "LAN_DISCOVERY_TITLE":"Local controls discovery",
    "LOCAL_CONNECT": "Local Connect",
    "LOGIN_EMAIL_LABEL": "Email",
    "EMAIL_LABEL":"Email address",
    "LOGIN_ERROR_SUBTITLE": "Invalid email or password",
    "LOGIN_ERROR_TITLE": "Login error!",
    "LOGIN_PAGE_TITLE": "Messana Login",
    "LOGIN_PASSWORD_LABEL": "Password",
    "LOGIN_USERNAME_LABEL": "Username",
    "LOGOUT": "Logout",
    "LOGOUT_CONFIRM": "Are you sure to sign out from",
    "MACRO_ZONES": "Macrozones",

    "MANUAL": "Manual",
    "Manual": "Manual",
    "MANUAL_CHANGEOVER": "Manual changeover settings",
    "MANUAL_CHANGEOVER_SUBTITLE": "The user decide when to switch between heating and cooling manually or through a temporary automatic switch (adaptive comfort).",
    "MANUAL_CHANGEOVER_SUBTITLE_4PIPE": "The user decide when to switch between heating and cooling manually.",
    "MANUAL_LEVEL": "Manual level",
    "MANUAL_HUM_LEVEL":"Humidification level",
    "MANUAL_DEHUM_LEVEL":"Dehumidification level",
    "MANUAL_LEVEL_SETTINGS": "Manual level settings",
    "NETWORK_SETTINGS": "Network settings",
    "MAIN_GRP": "Main group",
    "RELATED_GRP": "Related Group",
    "MANUALLY": "Manual",
    "MAX_SUPPLY_TEMP": "Max supply temp (H)",
    "MAX_SUPPLY_TEMP_MOBILE": "Max supply temp (H)",
    "MAX_SUPPLY_TEMP_DESC":"Hydronic system maximum target temperature when a radiant floor or ceiling performs heating.",
    "MCU_CONNECT_OLD": "Go to service.radiantcooling.com",
    "MCU_SELECT_LABEL": "Select your system",
    "MCU_SELECT_PAGE_TITLE": "Select your system",
    "MCZ_USED_NAME": "Name empty or already exists",
    "TMP_USED_NAME": "Name empty or already exists",
    "MIN_SUPPLY_TEMP": "Min supply temp (C)",
    "MIN_SUPPLY_TEMP_MOBILE": "Min supply temp (C)",
    "MIN_SUPPLY_TEMP_DESC":"Hydronic system minimum target temperature when a radiant floor or ceiling performs cooling.",
     "RADIANT_SECTION":"Radiant hydronic system settings",
    "MIXING_VALVE": "Mixing valve",
    "NETWORK": "Network",
    "NETWORK_DEVICE_INFO": "Device info",
    "RELOADING_NETWORK":"Reloading network, please wait",
    "UPDATING_NETWORK_PARAMETERS":"Updating network parameters, please wait",
    "NETWORK_UPDATE": "Network configuration updated",
    "NEVER":"never",
    "Never":"Never",
    "NEW_BACKUP": "Create new backup",
    "NEW_BACKUP_TEXT": "Assing a name to the new backup",
    "NEW_MACRO_ZONE": "New macrozone",
    "NEW_MACRO_ZONE_SELECT_GROUP": "Select the group for the new macrozone",
    "NEW_MACRO_ZONE_TEXT": "Enter a name for this new macrozone",
    "NEW_SCHEDULE": "New schedule",
    "NO_INTERNET_POPUP_MESSAGE": "You have no internet connection, or the connection to the mBox is unavailable.",
    "NO_INTERNET_POPUP_TITLE": "Connection unavailable",
    "NO_SCH_IN_SYSTEM":"This system has no schedules, you must create a new schedule before applying it.",
    "NEW_SCHEDULE_PROFILE": "New schedule profile",
    "NEW_SCHEDULE_PROFILE_TEXT": "Assign a name and select a type below for the new schedule profile",
    "NEW_SCHEDULE_SEASON_TEXT": "Assign a name for the new season schedule profile",
    "NEW_EMA_PROFILE": "New e-mail alert profile",
    "NEW_EMA_PROFILE_TEXT": "Assign an e-mail (mandatory) and a name for the new e-mail alert profile",
    "EMA_DELETE_MESSAGE": "Do you want to delete this e-mail alert profile?",
    "EMA_EMAIL_INVALID": "Invalid e-mail",
    "EMA_EMAIL": "Alert e-mail (mandatory)",
    "EMA_NAME": "Alert name",
    "EMAIL_ALERTS":"E-mail alerts",
    "TITLE_EMA_PAGE":"E-mail alert profiles",
    "DETAILS_EMA_PAGE":"E-mail alert configuration",
    "EMA_SETTINGS":"Alert settings",
    "EMA_EMAIL_ADDRESS":"E-mail address",
    "EMA_RECIPIENT":"Recipient name",
    "EMA_ON_EVENT":"Send alert on event arising",
    "EMA_ACTIVE":"Active",
    "EMA_DELIVERY_FREQUENCY":"Report delivery frequency",
     "EMA_PERIODIC_DESCRIPTION":"The periodic mail is sent ",
     "EMA_PERIODIC_DESCRIPTION_NEVER":"The periodic e-mail is disabled.",
     "EMA_ACTIVE_DESCRIPTION":"The e-mail alert profile is active.",
      "EMA_EVENT_HAPPENS_DESCRIPTION":"The email is sent when the event happens.",
    "NEW_TIMER": "New timer",
    "NO": "No",
    "OFF": "OFF",
    "OLD_MCU_ROW1": "You have selected a system based on a previous version of the control unit that is not supported",
    "OLD_MCU_ROW2": "To access your system please login to",
    "OLD_MCU_ROW3": "If you are interested in upgrading your system, please contact us at",
    "ON": "ON",
    "OFF_BELOW_STATIC_TEXT": "OFF at setpoint -",
    "OFF_ABOVE_STATIC_TEXT": "OFF at setpoint +",
    "ON_ABOVE": "ON above",
    "ON_ABOVE_STATIC_TEXT": "ON above setpoint +",
    "ON_BELOW": "ON below",
    "ON_BELOW_STATIC_TEXT": "ON below setpoint -",
    "ON_OFF_POINT": "ON/OFF point",
    "ON_PARTIALIZED": "On - thermal function deactivated and air system partialized",
    "ON_SCHEDULING_WITH_FREE_COOLING_REQUEST_TO_AHU": "On schedule with free cooling request to AHU",
    "ON_SCHEDULING_WITH_HRV_REQUEST_TO_AHU": "On schedule with HRV request to AHU",
    "ON_THERMAL_DEACTIVATED_AIR_OFF": "ON - thermal function deactivated and air system full off",
    "ON_THERMAL_DECTIVATED": "ON - only thermal function dectivated",
    "ON_ZONE_SCHEDULING": "On zone schedule",
    "OPEN_WINDOW_LOGIC": "Open window logic",
    "OPERATIVE": "Operative",
    "OPERATIVE_MODES": "Operative modes",
    "OUTDOOR_TEMPERATURE_COMPENSATION": "Outdoor temp compensation",
    "PRIMARY_DNS": "Primary DNS",
    "PRIVACY_POLICY":"Privacy Policy",
    "CONTACT_SUPPORT": "Contact support",
    "KNOWLEDGE_BASE": "Knowledge base",
    "HELP": "Help",
    "PUMP_STATUS": "Pump status",
    "RADIANT": "Radiant",
    "REBOOT": "Reboot system",
    "REBOOT_CONFIRM": "Are you sure you want to reboot the system?",
    "REBOOT_ONGOING": "Reboot in progress...",
    "RELATED_ROOM": "Related zone",
    "RELATED_ROOMS": "Related zones",
    "SEARCH":" and search for your location (zipcode/country)",
    "SEARCHING_ZEROCONF_DEVICES" : "Searching for local devices..",
    "SEARCHING_ZEROCONF_DEVICE_FOUND" : "mBox found in local network",
    "SEARCHING_ZEROCONF_DEVICE_FOUND_TEXT_1": "A new local device has been found. Info:",
    "SEARCHING_ZEROCONF_DEVICE_FOUND_TEXT_2": "Do you want to connect to this device?",
    "ZEROCONF_DEVICE_LOADING": "Loading local device..",
    "SERVED_ROOMS": "Served zones",
    "SERVED_ROOM": "Served zone",
    "SERVER_IS_DOWN":"We will be down for a short time for maintenance.<br><br>Please come back later",
    "SET": "Set",
    "SET_TIME": "Set time",
    "SET_POINT": "Setpoint",
    "HEATING_SET_POINT": "Heating setpoint",
    "COOLING_SET_POINT": "Cooling setpoint",
    "STARTUP":"Startup",
    "NO_RELATED_ROOMS": "No related zone",
    "NO_SERVED_ROOMS": "No served zones",

    "SHOW_FANCOILS":"Show fancoils",
    "HIDE_FANCOILS":"Hide fancoils",

    "RELATED_AIR_UNITS":"Related air units",
    "RELATED_AIR_UNIT":"Related air unit",
    "NO_RELATED_AIR_UNITS":"No related air unit",

    "RELATED_ATUS":"Related ATUs",
    "RELATED_ATU":"Related ATU",
    "NO_RELATED_ATU":"No related ATU",

    "RELATED_ARSS":"Related Air systems",
    "RELATED_ARS":"Related Air system",
    "NO_RELATED_ARS":"No related Air system",

    "RELATED_TANKS":"Related buffer tanks",
    "RELATED_TANK":"Related buffer tank",
    "NO_RELATED_TANK":"No related buffer tank",


    "NO_RELATED_ENTITIES":"System not used",
    "RELATED_FANCOILS":"Related fancoils",
    "REL_FANCOILS":"Rel. fancoils",
    "RELATED_FANCOIL":"Related fancoil",
    "NO_RELATED_FANCOIL":"No related fancoil",


    "NO_RELATED_HYDRONICSYSTEM":"No related hydronic system",
    "RELATED_HYDRONICSYSTEM":"Related hydronic system",
    "RELATED_HYDRONICSYSTEMS":"Related hydronic systems",


    "REL_FANCOIL":"Rel. fancoil",
    "RELATIVE": "Relative",
    "REMOVE_EXCEPTIONS": "Clean exceptions",
    "REMOVE_EXCEPTIONS_TEXT": "Are you sure you want to clean exceptions?",
    "RENAME": "Rename",
    "RENAME_MACRO_ZONE": "Rename macrozone",
    "RENAME_MACRO_ZONE_TEXT": "Edit macrozone name",
    "RENAME_SCHEDULATION_TEXT": "Assign schedule profile name",
    "RENAME_SCHEDULE": "Rename",
    "RENAME_TEXT": "Assign a new name",
    "RENAME_TIMER": "Rename timer",
    "RENAME_TIMER_TEXT": "Assign timer name",
    "RENAME_ZONE": "Rename zone",
    "RENAME_ZONE_TEXT": "Edit zone name",
    "RENAME_TEMPLATE_TEXT": "Assign template name",
    "REORDER_MACRO_ZONES": "Reorder macrozones",
    "REORDER_ZONES": "Reorder zones",
    "RESET": "Reset",
    "RESET_HEATING": "Reset heating",
    "RESET_COOLING": "Reset cooling",
    "RESET_ALARM_WARNING_POPUP_TEXT": "Are you sure you want to reset the",
    "RESET_ERROR_TITLE":"Error",
    "RESET_OVERRIDE_TITLE": "Cancel Override",
    "RESET_OVERRIDE_MESSAGE": "Do you want to cancel the current Override?",
    "RESET_USER_MAIL_LABEL":"Username/e-mail address",
    "RESET_TROUBLE":"Trouble signing in?",
    "RESET_SOLUTION":"Don't worry, just enter your username/email address and we'll send you a link to reset your password",
    "RESET_SENT_TITLE":"Reset e-mail sent!",
    "RESET_SENT_MESSAGE":"A link to reset your password has been sent to you on ",
    "RESET_DAY_SCHEDULE": "Reset daily schedule",
    "RESET_DAY_SCHEDULE_HEATING": "Reset daily heating sched.",
    "RESET_DAY_SCHEDULE_COOLING": "Reset daily cooling sched.",
    "RESET_SCHEDULATION": "Are you sure to reset schedule?",
    "RESTART": "Restart Logics ",
    "RESTART_CONFIRM": "Are you sure you want to restart the logics?",
    "RESTART_ONGOING": "Logics restart in progress...",
    "RESET_ALARMS_TOAST": "Reset in progress..",
    "RESTORE_BACKUP": "Restore",
    "RESTORE_BACKUP_CONFIRM": "Are you sure to restore the backup?",
    "RETURN_TEMP": "Return temp",
    "WATER_RETURN_TEMP": "Water return temp",
    "ROOM_AIR_SUPPLY_RH": "Supply air humidity RH",
    "ROOM_AIR_SUPPLY_DP": "Supply air humidity DP",
    "ROOM_AIR_SUPPLY_RH_HUM": "Supply air RH",
    "ROOM_AIR_SUPPLY_DP_HUM": "Supply air DP",
    "ROOM_AIR_SUPPLY_TEMPERATURE": "Room air supply temp",
    "ROOM_AIR_SUPPLY_TEMP": "Room air supply temp",
    "ROOM_OFF": "OFF",
    "ROOM_ON": "ON",
    "ROUTER": "Router",
    "SAN_F":"San Francisco location key is ",
    "SAVE": "Save",
    "SAVE_GO": "Save and Go",
    "SCH_PREVIEW": "Show/Hide schedule",
    "SHOW": "Show",
    "HIDE": "Hide",
    "SCHEDULE": "Schedule",
    "SCHEDULE_CHANGEOVER": "Schedule changeover settings",
    "SCHEDULE_CHANGEOVER_SUBTITLE": "The system switches between heating and cooling following a weekly schedule.",
    "SCHEDULE_DISABLED": "disabled",
    "SCHEDULE_DYNAMIC": "Dynamic Schedulation",
    "SCHEDULE_NAME": "Schedule name",
    "SCHEDULE_PROFILES": "Schedules",
    "SCHEDULE_TYPE": "Schedule type",
    "SCHEDULE_TYPE_ON_OFF": "General (ON/OFF)",
    "SCHEDULE_TYPE_ON_OFF_DESCRIPTION": "Temperature and timers",
    "SCHEDULE_TYPE_SEASON": "H/C changeover",
    "SCHEDULE_TYPE_VARIABLE": "Zones (variable temp)",
    "SCHEDULE_TYPE_DUAL_VARIABLE":"Zones (dual var temp)",
    "SCHEDULE_TYPE_VARIABLE_SHORT": "Variable temp",
    "SCHEDULE_TYPE_DUAL_VARIABLE_SHORT":"Dual set variable temp",
    "SEASON_CHANGEOVER": "Heating/Cooling changeover",
    "SEASON_CHANGING": "Heating/Cooling changeover",
    "SEASON_MODE": "H/C mode",
    "SECONDARY_DNS": "Secondary DNS",
    "SET_DEW_POINT": "Set dew point",
    "DEW_POINT_TEMP":"Dew point temp",
    "SELECT_TERMINAL_RENAME": "Select the Terminal to rename",
    "SELECT_TIMER_RENAME": "Select the Timer to rename",
    "SET_HUMIDITY": "Set relative humidity",
    "SET_TEMPERATURE": "Set",
    "SETBACK": "Setback",
    "SETBACK_DIFFERENTIAL": "Setback differential",
    "SETTINGS": "Settings",
    "SETUP": "Setup",
    "STANDARD": "Standard",
    "STATE": "State",
    "STATUS": "Status",
    "SUBNET_MASK": "Subnet mask",
    "SUPPLY_TEMP": "Supply temp",
    "SUBMIT":"Submit",
    "SYSTEM": "System",
    "SYSTEM_HANDLED_BY_DI":"System power handled by external device (Diginal Input)",


    "SYSTEM_TEMPALTES_SCRIPT_GEN":"Default templates script generation",
    "MBOX":"mBox",
    "SYSTEM_DESC_1": "It does not shut off electrical power to any of the controlled equipment. It is a \"",
    "SYSTEM_DESC_2": "software power off",
    "SYSTEM_DESC_3": "\" that put the system in standby and prevent any equipment activation. The system remain in standby until the user manually set it back to on (symbol ON). The user interface remain active and reads all the sensors. The user can make changes in the settings.",
    "SYSTEM_INFO": "System info",
    "SYSTEM_SETTINGS": "System settings",
    "SYSTEM_NAME": "System name",
    "SYSTEM_ON_OFF": "System power",
    "SYSTEMS": "Systems",
    "TANK": "Buffer Tank",
    "MAINTENANCE_TEMP":"Maintenance temp",
    "TECH_MODE": "Dashboard",
    "TEMP_UNITS": "Temperature unit",
    "TEMPERATURE": "Temperature",
    "TEMPERATURE_H": "Heating temperature",
    "TEMPERATURE_C": "Cooling temperature",
    "TEMPERATURE_DIFFERENTIAL_FOR_ATTENUATION": "Temperature differential for attenuation",
    "TERMS_OF_USE_TITLE":"Terms of Use and Privacy Policy",
    "TERMS_OF_USE_MESSAGE_START":"To continue, you must agree to our ",
    "TERMS_OF_USE_MESSAGE_AND":" and the ",
    "TERMS_OF_USE":"Terms of Use",
    "TERMS_LABEL":"I have read and agree to the Terms of Use and the Privacy Policy",
    "MUST_ACCEPT_USE_TERMS":"Our Terms of Use have changed. You will be logged out in order to let you read and accept our new Terms of Use.",
    "MUST_ACCEPT_USE_TERMS_ERROR":"To continue, you must agree to our Terms of Use and the Privacy Policy.",
    "AGREE_TERMS":"I agree",
    "VIEW_TERMS":"Terms",
    "VIP_LABEL": "Vip user",
    "TARGET_TEMP":"Target temp",
    "THE_SYSTEM_IS_OFF": "System OFF",
    "THE_UNITY": "The unit",
    "TIMER": "Timer",
    "TIMER_STATUS": "Timer status",
    "TIMERS": "Timers",
    "TO": "To",
    "TODAY": "Today",
    "TOMORROW": "Tomorrow",
    "TYPE_CONFIG_CODE":"type access code",
    "TYPE_MCU": "Messana Control Units",
    "TYPE_ATU": "Air Treatment Units",
    "TYPE_EXP": "Expansion Modules",
    "TYPE_FLS": "Flow Sensors",
    "TYPE_FNC": "Fan Coils",
    "TYPE_HYS": "Hydronic Systems",
    "TYPE_MXV": "Mixing Valves",
    "TYPE_ODS": "Oudoor Sensors",
    "TYPE_PRS": "Pressure Sensors",
    "TYPE_SCH": "Schedules",
    "TYPE_SHT": "Shutters",
    "TYPE_TNK": "Buffer Tanks",
    "TYPE_TRG": "Triggers",
    "TYPE_ZON": "Areas",
    "TYPE_ARS": "Air Units",
    "TYPE_DHW": "Domestic Hot Water Systems",
    "TYPE_ENR": "Heating/Cooling Sources",
    "TYPE_GRP": "Groups",
    "TYPE_MCZ": "Macrozones",
    "TYPE_PMP": "Pumps",
    "TYPE_RMS": "Zones",
    "TYPE_TMR": "Timers",
    "TYPE_TMP": "Templates",
    "TYPE_GEN": "Generics",
    "TYPE_FWA":"Floor Warmer Areas",
    "TYPE_MRM":"Mechanical Rooms",
    "TYPE_MNF":"Manifolds",
    "TYPE_DVV":"Diverting Valves",
    "TYPE_CMN":"Cool Automation Devices",
    "UNABLE_TO_DELETE_SCHEDULATION": "Schedule in use, cannot delete",
    "UPDATE": "Update",
    "UPDATE_NETWORK_CONFIGURATION": "Update",
    "RESTORE_NETWORK_CONFIGURATION":"Restore",
    "USED_NAME": "Empty or already used name",
    "USED_ON_OTHER_SEASON": "Could be used in another season",
    "USING_DHCP": "DHCP",
    "WATER_SUPPLY_TEMPERATURE": "Water supply temp",
    "WATER_SUPPLY_TEMP": "Water supply temp",
    "WEEKLY_SCHEDULE": "Weekly schedule",
    "NO_LOCAL_DEVICES_FOUND": "No local devices found",
    "NO_WIFI":"No WiFi connection detected. Please turn on your WiFi connection to discover your local devices.",
    "WARNING_OTHER_SERVED_ZONES": "serves other zones too. Are you sure you want to proceed?",
    "WORK": "Work",
    "YES": "Yes",
    "YESTERDAY": "Yesterday",
    "YOUR_ZONES": "My zones",
    "GOING_TO":"Going to perform your desires<br>please wait",
    "ZIP_CODE": "Zip code",
    "ZONE_REQUEST": "Zone request",
    "ZONE_TERMINALS": "Zone terminals",
    "ZONES": "Zones",
    "HISTORY": "Graphs",
    "HISTORY_NOT_FOUND":"Historical graph data not found",
    "HISTORY_PAGE":"Param/Data selection",
    "PAR_HISTORY":"Parameters",
    "RTU_HISTORY":"Run-time Data",
    "DISCARD": "Discard",
    "SHOW_SETTINGS": "Settings",
    "TEMPLATE": "Template",
    "INTERVAL_TYPE":"Interval Type",
    "DATE": "Date",
    "CREATE_CHART":"Create Chart",
    "GRAPH_PAGE": "Chart Viewer",
    "SHOW_POINTS":"Show points",
    "INTERPOLATION":"Interpolation",
    "LINEAR":"Linear interpolation",
    "MONOTONE":"Cubic spline",
    "LINEAR-CLOSED":'Linear-closed',
    "BASIS":'B-spline',
    "BASIS-OPEN":'Basis-open',
    "BASIS-CLOSED":'Basis-closed',
    "BUNDLE":'Bundle',
    "CARDINAL":'Cardinal',
    "CARDINAL-OPEN":'Cardinal-open',
    "CARDINAL-CLOSED":'Cardinal-closed',
    "TABLE": "Data Structure",
    "NOT_SUPPORTED_MESSAGE": "Not yet supported",
    "BASIC_TEMPLATES": "Predefined Templates",
    "MY_TEMPLATES": "My Templates",
    "NEW_TEMPLATE_PAGE": "New template",
    "TMP_NAME": "Template Name",
    "TMP_DESCRIPTION": "Description",
    "TMP_KEYS": "Template Keys",
    "TEMPLATES_PAGE":"Manage templates",
    "GO_SETTINGS": "Param/Data selection",
    "GO_CUSTOM_TEMPALTES":"Edit system templates",
    "GO_ADVANCED_SETTINGS":"Advanced settings",
    "GO_TEMPLATES": "Manage templates",
    "NEW_TEMPLATE": "New template",
    "EDIT_TEMPLATE_PAGE":"Edit template",
    "EMAIL_UNDEF":'Email could not be sent, your e-mail address is undefined, please contact us at info@radiantcooling.com',
    "EMAIL_NOT_SENT":'Generic error: email could not be sent',
    "SHOW_TEMPLATE_PAGE":"Show template",
    "CHECK_PASSWORD": "Check Password",
    "TECHNICAL_PASSWORD": "Please insert your technical password",
    "TECHNICAL": "Technical",
    "ALARMS":"Alarms",
    "ALARMS_WARNINGS":"Alarms & warnings button",
    "CHANGE_PASSWORD":"Change Password",
    "SELECT_NEW_DATA": "Key Lists",
    "ACTIVE_KEYS": "Keys",
    "ONE_DAY": "1 Day",
    "OK":"Ok",
    "TWO_DAYS": "2 Days",
    "SEVEN_DAYS": "7 Days",
    "FOURTEEN_DAYS": "14 Days",
    "MONTH": "Monthly",
    "ONE_WEEK": "Weekly",
    "TWO_WEEKS": "Biweekly",
    "SAVE_TEMPLATE": "Save Template",
    "CREATE_TEMPLATE": "Create Template",
    "SHOW_TODAY_TEMPS": "Show today temps",
    "NEW_SCHEDULE_FROM_DUPLICATE": "Assign a name for the new duplicated schedule",
    "SCHEDULE_SEASON_CHANGE" : "Schedule Changed",
    "EXTEND_SCHEDULE_MESSAGE" : "Extend this schedule to other season?",
    "SAME_SCHEDULE_MESSAGE" : "Keep same schedule between seasons?",
    "EXTEND_SCHEDULE" : "Extend",
    "KEEP_SCHEDULE" : "Keep",
    "KEEP_ZOOM":"Keep zoom",
    "DUPLICATE": "Duplicate",
    "COPY_OF" : "Copy of ",
    "DISABLE_SCHEDULE" : "Disable",
    "APPLY_SCHEDULE" : "Apply",
    "SEASON_SCHEDULE":"Season schedule",
    "SCHEDULE_SEASON_DISABLED" : "Disable schedule also on the ",
    "SCHEDULE_SEASON_CHANGE1" : "Apply this variable schedule '",
    "SCHEDULE_SEASON_CHANGE2" : "' also to the ",
    "SCHEDULE_SEASON_CHANGE3" : " mode",
    "FLOW_LEVEL" : "HRV speed level",
    "ENVIRONMENT_TEMPERATURE" : "Return air temp",
    "ENVIRONMENT_TEMP": "Fresh air intake temp",
    "AIR_HUMIDITY_RH" : "Fresh air intake humidity RH",
    "AIR_HUM_RH" : "Return air RH",
    "AIR_HUMIDITY" : "Fresh air RH",
    "AIR_HUMIDITY_DP" : "Fresh air intake humidity DP",
    "AIR_HUM_DP" : "Return air DP",
    "ENV_TEMP" : "Return air temp",
    "SCHEDULE_CHANGEOVER_DISABLED" : "No season schedule found",
    "CHECK_CONNECTION": "Check Connection",
    "CONNECTION_TIMEOUT": "Connection timeout",
    "CONTACT":"Contact us",
    "CONTINUE":"Continue",
    "NO_RESULT_DATA" : "Data not available",
    "NO_DATA": "Keys not available",
    "NO_DEVICE":"No device has been found.",
    "NOT_AVAILABLE":"Not Available",
    "TEMPLATE_CREATED": "Template Created",
    "TEMPLATE_SAVED": "Template Saved",
    "AUTO_ADAPT_COMFORT_TITLE": "Adaptive comfort explained",
    "ZONE_WEIGHT":"Zone list and weights",
    "AAC_DETAIL":"Adaptive Comfort",
//    "AAC_DETAIL":"Adaptive comfort settings",
    "SET_TEMP_AIR_IN_H": "Supply air setpoint (H)",
    "SET_TEMP_AIR_IN_C": "Supply air setpoint (C)",
    "CHANGE_VALUE_FOR" : "Change Value for",
    "SETPOINT_SEASONALLY": "Dual setpoints",
    "SETPOINT_UNIQUE" : "Common setpoints",
    "SETPOINT_MODE": "Zone temp setpoints",
    "KEEP_SETPOINTS" :"Keep",
    "OVERRIDE_SETPOINTS": "Override",
    "OVEERRIDE": "Override",
    "OVERRIDE_SETPOINT": "Override setpoint",
    "OVERRIDE_SETPOINT_HEATING": "Override heating setpoint",
    "OVERRIDE_SETPOINT_COOLING": "Override cooling setpoint",
    "OVERRIDE_SET_TIME":"Set override time",
    "OVERRIDE_SETPOINT_TITLE": "Override Settings",

    // "OVERRIDE_SETPOINT_PERIOD_TITLE": "Time span",
    "OVERRIDE_SETPOINT_PERIOD_TEXT_1": "The new desired",
    "OVERRIDE_SETPOINT_PERIOD_TEXT_2": "setpoint will be applied for the next",
    "OVERRIDE_SETPOINT_PERIOD_TEXT_3": "regardless of the schedule. Then the setpoint will be switched back to",
    "OVERRIDE_TEMP_SETPOINT": "Temperature Setpoint",
    "RANGE_VALOR": "Please enter value in the range",
    "RANGE_ERROR": "Value outside the interval",
    "RESET_DEFAULT" : "Reset to Factory Default",
    "CONFIRM_RESET_DEFAULT": "Are you sure? This operation will change all attributes.This operation may require additional time. Please come back to the page in a few moments.",
    "DELETE_TEMPLATE" : "Delete Template",
    "MCU":"Messana Control Unit",
    "ATU": "Air Treatment Unit",
    "EXP": "Expansion Module",
    "FLS": "Flow sensor",
    "FNC": "Fan Coil",
    "FWA": "Floor warmer",
    "HYS": "Hydronic System",
    "MXV": "Mixing Valve",
    "ODS": "Outdoor Sensor",
    "PRS": "Pressure Sensor",
    "SCH": "Schedule",
    "SHT": "Shutter",
    "TNK": "Buffer Tank",
    "TRG": "Trigger",
    "ZON": "Area",
    "ARS": "Air Unit",
    "DHW": "Domestic Hot Water System",
    "ENR": "Heating/Cooling Source",
    "GRP": "Group",
    "MCZ": "Macrozone",
    "PMP": "Pump",
    "RMS": "Zone",
    "TMR": "Timer",
    "TMP": "Template",
    "RETURN_TODAY": "Return to today",
    "FLOW_UNITS": "Airflow unit",
    "CFM": "Cubic feet per minute (cfm)",
    "M3H": "Cubic meter per hour (m³/h)",
    "FLOW_RATE": "HRV airflow setting",
    "INLET_FLOW": "Supply air",
    "OUTLET_FLOW": "Exhaust air",
    "OUTLET_FLOW_BATH":"Outlet bath flow",
    "SLEEP_MODE": "Sleep mode",
    "IN_SPEEDS" : "Supply air speed",
    "OUT_OF_SCHEDULE": "Out of schedule",
    "OUT_SPEEDS" : "Exhaust air speed",
    "OUTB_SPEEDS" : "Exhaust bath air speed",
    "HIGHTER" : "Highter",
    "HIGH" : "High",
    "MEDIUM" : "Medium",
    "LOCATION_KEY_SENTENCE":"Your location key is the code you find in the address of the browser navigation bar",
    "LOW" : "Low",
    "LOWER" : "Lower",
    "SLEEP_MODE_FROM" : "From",
    "SLEEP_MODE_TO" : "To",
    "MAX_IN_SPEED" : "MAX speed",
    "MIN_IN_SPEED" : "MIN speed",
    "MAX_OUT_SPEED" : "MAX speed",
    "MIN_OUT_SPEED" : "MIN speed",
    "MAX_OUTB_SPEED" : "MAX speed",
    "MIN_OUTB_SPEED" : "MIN speed",
    "IN_OUT_SPEED_MESSAGE" : "To avoid depressurization set the exhaust fan speed lower than supply speed",
    "SPEED_LOW_LIMIT" : "Fan speed at half speed down to 300",
    "MACROZONE_NAME": "Macrozone name",
    "VENTILATION": "Ventilation",
    "FAN_SPEED": "Fan Speed",
    "FAN_COIL_MODE_H": "Mode (Heating)",
    "FAN_COIL_MODE_C": "Mode (Cooling)",
    "MANUAL_LEVEL_H": "Manual level",
    "MANUAL_LEVEL_C": "Manual level",
    "MANUAL_H": "Manual",
    "MANUAL_C": "Manual",
    "AUTO_H": "Auto",
    "AUTO_C": "Auto",
    "DIFF_LEVEL_H": "Differential to 100%",
    "DIFF_LEVEL_C": "Differential to 100%",
    "MAX_SPEED":"Max speed",
    "DELTA_HC": "Differential between H and C setpoints",
    "ACTIVATIONS": "Activations",
    "HEATING_PLUS_COOLING":"Heating & Cooling",
    "ONLY_HEATING" : "Heating only",
    "ONLY_COOLING" : "Cooling only",
    "ZONE_SETPOINT":"zone setpoint",
    "ON_SET_POINT_HUM":"On zone setpoint",
    "ON_SET_POINT_NTD":"On zone setpoint",
    "ON_SET_POINT_NTD_SUBTITLE": "Dehumidification always on.",
    "ENERGY_SAVING_NTD":"Energy saving",
    "ON_SET_POINT_CO2" :"setpoint",
    "SET_DEW_POINT_CO2": "Set dew point",
    "NTD_ES_SUBTITLE" : "Dehumidification request ON only when the zone is calling for cooling.",
    "NTD_ES_SUBTITLE_MOBILE" : "Dehum. request ON only on zone cooling.",
    "C02_SUBTITLE_ROW1_ZONE" : "to set setpoints for each zone served by the unit",
    "C02_SUBTITLE_ROW1" : "Applied to all served zones with IAQ sensor",
    //"C02_SUBTITLE" : "<700 <i class=\"far fa-laugh-beam messana-green cleanIcon\"></i> Excellent, <1000 <i class=\"far fa-smile messana-yellow cleanIcon\"></i> Good, <1600 <i class=\"far fa-meh messana-orange cleanIcon\"></i> Fair",
    "C02_SUBTITLE" : "<700 <i class=\"far fa-laugh-beam messana-green cleanIcon pr-1\"></i> <1000 <i class=\"far fa-smile messana-yellow cleanIcon pr-1\"></i> <1600 <i class=\"far fa-meh messana-orange cleanIcon\"></i>",
    "SETPOINT_MODE_HUM": "Humidity setpoints for humidification",
    "SETPOINT_MODE_DEH": "Humidity setpoints for dehumidification",
    "SETPOINT_MODE_IAQ": "Air quality setpoints",
    "SETPOINT_GLOBALLY": "Managed by air units",
    "SETPOINT_ZONES": "Managed by zones",
    "AIR_QUALITY": "Air Quality",
    "DATE_FROM": "Date from",
    "DATE_TO": "Date to",
    "CUSTOM_DAY": "Custom",
    "CUSTOMIZE_YOUR_PARAMETERS":"Set whether or not to see points on charts and specify your preferred interpolation function.",
    "EX":"Example: ",
    "EXIT_GRAPH_TITLE":"Warning",
    "EXIT_GRAPH_MSG":"Are you sure you want to exit?",
    "EXIT": "Exit",
    "DEHUM_INT_AIRFLOW_SETTINGS": "Dehum/Int airflow setting",
    "DEHUM_INT_AIRFLOW_TEXT1": "The speeds below are used to prevent evaporator to freeze due insufficient airflow during dehumidification and/or integration functions.",
    "DEHUM_INT_AIRFLOW_TEXT2": "The unit will run to the highest speeds between speeds set in the HRV function and the speeds below.",
    "CHANGE_MACROZONES_SETPOINTS": "Edit setpoint limits",
    "MACROZONES_SETPOINTS": "Setpoint limits",
    "SETTEMP_MIN_COMMON": "Setpoint MIN",
    "SETTEMP_MAX_COMMON": "Setpoint MAX",
    "SETTEMP_MIN_H": "Setpoint MIN (H)",
    "SETTEMP_MIN_C": "Setpoint MIN (C)",
    "SETTEMP_MAX_H": "Setpoint MAX (H)",
    "SETTEMP_MAX_C": "Setpoint MAX (C)",
    "SETPOINT_SEASONALLY_LIMIT": "Dual setpoints (H and C)",
    "SETPOINT_UNIQUE_LIMIT" : "Common setpoints",
    "VIA_TIMER": "Via Timer",

    "CONFIRM_UPDATE_NETWORK": "Are you sure?",
    "REMOVE_FROM_MACROZONE": "Remove",
    "REMOVAL":"Removal",
    "REMOVE":"Remove",
    "REMOVE_FROM_MACROZONE_TITLE": "Remove from macrozone",
    "REMOVE_FROM_MACROZONE_TEXT": "Are you sure you want to remove this zone from macrozone?",
    "FANCOIL_DIFFERENTIAL": "Max speed differential",
    "FANCOIL_SETTINGS":"Fancoil settings",
    "FAD":"Fancoil activation delay",
    "FAD_DESC": "Delay the fan activation until the supply temp reaches a Min act temp, in heating (H), or a Max act temp in cooling (C), to avoid cold or hot air start.",
    "FAD_MIN_SUPPLY_TEMP_H":"Min supply temp (H)",
    "FAD_MIN_SUPPLY_TEMP_H_DESC": "In heating, the fan starts after the supply temp climbs above",
    "FAD_MAX_SUPPLY_TEMP_C":"Max supply temp (C)",
    "FAD_MAX_SUPPLY_TEMP_C_DESC": "In cooling, the fan starts after the supply temp drops below",
    "FAD_DELAY":"Switch on max delay",
    "FAD_DELAY_ON_DESC_1": "Wait up to",
    "FAD_DELAY_ON_DESC_2": "for the supply temp to reach the conditions above. After",
    "FAD_DELAY_ON_DESC_3": "the fan starts regardless.",
    "FAD_DELAY_OFF_DESC": "The fan will not start if the conditions above are not satisfied.",
    "FAD_DIFF_DESKTOP":"Switch off temp differential",
    "FAD_DIFF_MOBILE":"Switch off differential",
    "FAD_DIFF_DESC_1": "The fan stops if the supply temp drops below",
    "FAD_DIFF_DESC_2": ", in heating, or above",
    "FAD_DIFF_DESC_3": "in cooling.",
    "FAD_WARNING":"The fan coil didn't turn on with the desired temp.",
    "FHS":"Fancoil hydronic system settings",
    "FHS_SUPPLY_TEMP_H":"Target temp water supply (H)",
    "FHS_SUPPLY_TEMP_C":"Target temp water supply (C)",
    "FHS_SUPPLY_TEMP_DEHUM":"Target temp water supply (Dehum)",
    "FHS_SUPPLY_TEMP_H_MOBILE":"Target temp water supply (H)",
    "FHS_SUPPLY_TEMP_C_MOBILE":"Target temp water supply (C)",
    "FHS_SUPPLY_TEMP_DEHUM_MOBILE":"Temp water supply (Dehum)",
    "FHS_SUPPLY_TEMP_H_DESC":"Hydronic system target temperature when a fan coil performs heating.",
    "FHS_SUPPLY_TEMP_C_DESC":"Hydronic system target temperature when a fan coil performs cooling.",
    "FHS_SUPPLY_TEMP_DEHUM_DESC":"Hydronic system target temperature when a fan coil performs dehum.",
    "FHS_DESC":"Set the supply temperatures for the fan coils associated to this hydronic system.",
    "CRS_WARNING":"The fan coil didn't satisfy a cooling request because there is a concurrent heating request in a shared room.",
    "ACCUWEATHER_LOCATION_KEY":"AccuWeather Location Key",
    "ACCUWEATHER_NOT_FOUND": "AccuWeather location key not found",
    "ACCUWEATHER_CHECK_SETTINGS": "Check Settings/General/AccuWeather Location Key",
    "ACCUWEATHER_INVALID": "AccuWeather location key is invalid",
    "ACCUWEATHER_UNAVAILABLE": "AccuWeather is currently unavailable",
    "RETURN_TO_DASHBOARD_TIMEOUT": "After",
    "RETURN_TO_DASHBOARD": "Return to dashboard after inactivity",
    "UNATHORIZED_USER": "Unauthorized user",
    "DIFF": "diff",
    "AVG_AIR_TMP": "Humidity supply air setpoint",
    "AVG_AIR_TMP_MOBILE": "Supply air setp.",
    "DP_MAX": "Max room dewpoint",
    "RH_MAX": "Max room RH",
    "AVG_DIFF_AIR_H": "Average of zones' heating setpoints + diff",
    "AVG_DIFF_AIR_C": "Average of zones' cooling setpoints + diff ",
    "ROOMS_RH_MAX": "Stop humidification if humidity in a zone exceed",
    "SELECTED_ROOMS": "Selected zones",
    "SELECT_ALL":"------- Select all -------",
    "MAX_ROOM_HUM" : "Max room humidity",
    "SET_OUTDOOR_DELTA_T":"Outdoor/Indoor delta T",
    "SET_OFF_DELTA_T":"Off delta T",
    "USER_PREFERENCES": "User preferences",
    "USER_PREF_TITLE": "These preferences are stored locally within the user's browser in each device.",
    "GENERAL_TITLE": "These settings are stored locally in the control unit.",
    "WRONG_SELECTION": "Wrong selection",
    "WRONG_CODE":"Wrong code",
    "WRONG_SELECTION_MSG": "The values will be restored",
    "ZONES_MACROZONES": "Zones/Macrozones",
    "SETPOINT_SEASONALLY_TITLE": "Setpoints can be maintained separated (dual) between heating and cooling modes or unified (common), one unique set of setpoints for both heating and cooling.",
    "SETPOINT_SEASONALLY_EXPL": "With common setpoints and independent heating and cooling terminals that can be activated simultaneously, a differential between heating and cooling setpoints must be maintained to avoid simultaneous activations. ",
    "CONFIRM_SP_MODE_OFF": "The common setpoints will be applied to two distinctive set of setpoints for heating and cooling and will be kept separated. Do you wish to continue?",
    "CONFIRM_SP_MODE_ON": "The heating setpoints will be applied to the common setpoints. The room schedules used in heating will be applied to cooling mode. All cooling setpoints and schedule settings will be lost. This operation is not reversible. Do you wish to continue?",
    "GRAPHS":"Graphs",
    "HUM_SETPOINT_EXPL": "Humidity setpoints can be set at air unit level, and they are applied to each zone served by the air unit (Managed by air units), or can be set individually for each zone (Managed by zones).",
    "NTD_SETPOINT_EXPL": "Humidity setpoints can be set at air unit level, and they are applied to each zone served by the air unit (Managed by air units), or can be set individually for each zone (Managed by zones).",
    "IAQ_SETPOINT_EXPL": "Air quality setpoints can be set at air unit level, and they are applied to each zone served by the air unit (Managed by air units), or can be set individually for each zone (Managed by zones).",
    "CMN":"CoolMasterNet Comm Service",
    "STOP_CMN":"Stop",
    "START_CMN":"Start",
    "RESTART_CMN":"Restart",
    "STOP_CMN_CONFIRM":"Are you sure you want to stop the CoolMasterNet service?",
    "START_CMN_CONFIRM":"Are you sure you want to start the CoolMasterNet service?",
    "RESTART_CMN_CONFIRM":"Are you sure you want to restart the CoolMasterNet service?",
    "STOP_CMN_ONGOING":"Stopping CoolMasterNet...",
    "START_CMN_ONGOING":"Starting CoolMasterNet...",
    "RESTART_CMN_ONGOING":"Restarting CoolMasterNet...",
    "UNIT_PROBES":'Unit probes',
    "PROBES_ON_BOARD":"on board",
    "PROBES_ON_DISPLAY":"on display",
    /* Analogic ATU */
    "CURRENT_FAN_SPEED": "Manual fan speed",
    "LOW_FAN_SPEED": "Low fan speed",
    "HIGH_FAN_SPEED": "High fan speed",
    "LOW_ECO_2_SETPOINT": "Low eCO<sub>2</sub> setpoint",
    "HIGH_ECO_2_SETPOINT": "High eCO<sub>2</sub> setpoint",
    "SLEEP_MODE_DESC": "During the sleep mode hours the fan speed will be automatically reduced to the speed as set below.",
    "SLEEP_MODE_FAN_SPEED": "Sleep mode fan speed",
    "BATHROOM_EXHAUST": "Bathroom exhaust",
    "BATHROOM_EXHAUST_DESC": "The bathroom exhaust feature is used when an ERV or HRV has its exhausts ducted to bathrooms. When the humidity in a bathroom associated to an ERV/HRV exceeds the humidity setpoint the ERV/HRV will go to max fan speed until the humidity drops below the setpoint or the maximum run time is reached. If an occupancy or presence sensor is installed it can also be used to trigger the ERV/HRV to run at a specific speed and for a maximum amount of run time.",
    "BATHROOM_MODE_FAN_SPEED": "Bathroom mode fan speed",
    "HUMIDITY_SETPOINT": "Humidity setpoint",
    "MAXIMUM_RUN_TIME": "Max runtime",
    "PRESENCE": "Presence",
    "PRESENCE_FAN_SPEED": "Presence fan speed",
    "ANALOGIC_ERV_IS_IAQ_WARNING": "IAQ setpoint by zones not supported with analogic ATU.",
    "HRV_LOW_SPEED_DESC_1": "Low speed: Unit fan speed when detected eCO2 levels fall below the Low eCO2 setpoint. It ranges between",
    "HRV_LOW_SPEED_DESC_2": "(or less than the High Fan Speed minus 20%) of the fan's maximum capacity",
    "HRV_HIGH_SPEED_DESC_1": "Unit fan speed when detected eCO2 levels exceed the High eCO2 setpoint. It ranges between",
    "HRV_HIGH_SPEED_DESC_2": "(or more than the Low Fan Speed plus 20%) and",
    "HRV_HIGH_SPEED_DESC_3": "of the fan’s maximum capacity",
    "HRV_LOW_ECO2_DESC_1": "This is the threshold below which the unit operates at Low Fan Speed. It ranges from",
    "HRV_LOW_ECO2_DESC_2": "(or less than the High eCO2 setpoint minus 200)",
    "HRV_HIGH_ECO2_DESC_1": "This is the threshold above which the unit operates at High Fan Speed. It ranges from",
    "HRV_HIGH_ECO2_DESC_2": "(or more than the Low eCO2 setpoint plus 200) up to",
    "DEFAULT_VALUE": "default value",
    "ERV_HRV_TITLE": "Air quality adaptive fan speed control",
    "ERV_HRV_DESCRIPTION": "The speed of the ERV/HRV unit adjusts according to the detected eCO2 levels, which is a measure of indoor air quality. When eCO2 levels fall below the Low eCO2 setpoint, the unit operates at a Low speed. Conversely, when eCO2 levels exceed the High eCO2 setpoint, the unit operates at a High speed. For intermediate eCO2 levels, the unit linearly modulates the speed between the Low and High speeds. NOTE: the level considered is always the highest eCO2 reading among all the zones served by the unit, thus guaranteeing adequate ventilation even in the worst-ventilated zones.",
    "UNIT_SIZE": "Unit size",
    "AIRFLOW": "Airflow",
    "UNIT_SIZE_DESC": "Set the size of the  ERV / HRV unit in cfm.",
    "AIRFLOW_DESC": "Evaluated based on the unit size and the current fan speed %.",
    "AND": "and",
    "to": "to",
    /* Floor warmer */
    "FLOOR_TEMPERATURE": "Floor Temperature",
    "SETPOINT": "Setpoint",
    "FLOOR_WARMER_MAX_SUPPLY_TEMP": "Floor warmer max supply temp",
    "FLOOR_SENSOR_TEMP": "Floor sensor temp",
    "CALIBRATION_DIFFERENTIAL": "Calibration differential",
    "CALIBRATION_DIFFERENTIAL_DESC": "Measure the floor surface temp with IR thermometer and apply the differential to the sensor reading.",
    "LOGICS": "Logics",
    "PROJECT_NUMBER": "Project Number",
    "ENABLE_WHEN_COOLING": "Enable when cooling",
    "ENABLE_WHEN_COOLING_ES": "Enable with Energy Saving",
    "ENABLE_WHEN_COOLING_ES_DESC": "When a related room sensor is satisfying a cooling request.",
    "OVERHEATING_DIFFERENTIAL": "Over-heating differential",
    "OVERHEATING_DIFFERENTIAL_DESC": "When a room sensor is under a heating request, the floor warmer stops if the temp measured by the room sensor is over the room sensor setpoint by",
    "MAX_OPERATIVE_TIME_COOLING": "Max op. time when cooling",
    "DUAL_SETPOINT_MODE": "Dual setpoint mode",
    "CHANGEOVER_SCHEDULES_WARNING_1": "<br><br><b>Warning:</b> You have",
    "CHANGEOVER_SCHEDULES_WARNING_1_ALT": "You have",
    "CHANGEOVER_WARNING_VARIABLE": "Variable",
    "CHANGEOVER_WARNING_DUAL_VARIABLE": "Dual Variable",
    "CHANGEOVER_SCHEDULES_WARNING_2": "schedules applied to zones or macro zones. All",
    "CHANGEOVER_SCHEDULES_WARNING_3": "schedules currently assigned will be unassigned.",
    "DUAL_SETPOINT_HEATING_OVERRIDE_HIGH_TEMP_WARNING": "Warning: this heating override setpoint is higher than the expected minimum difference with the cooling setpoint. If you confirm the override, the cooling setpoint will also be overridden, and both overrides might be linked.",
    "DUAL_SETPOINT_COOLING_OVERRIDE_LOW_TEMP_WARNING": "Warning: this cooling override setpoint is lower than the expected minimum difference with the heating setpoint. If you confirm the override, the heating setpoint will also be overridden, and both overrides might be linked.",
    "DUAL_SETPOINT_CANCEL_HEATING_OVERRIDE_WARNING": "Warning: if you cancel this heating setpoint override, you will automatically cancel the cooling setpoint override as well.",
    "DUAL_SETPOINT_CANCEL_COOLING_OVERRIDE_WARNING": "Warning: if you cancel this cooling setpoint override, you will automatically cancel the heating setpoint override as well.",
    "DUAL_SETPOINT_UPDATE_TIME_HEATING_INCREASE_OVERRIDE_WARNING": "Warning: if you increase the time of this heating setpoint override, you will also increase the time of the cooling setpoint override.",
    "DUAL_SETPOINT_UPDATE_TIME_COOLING_INCREASE_OVERRIDE_WARNING": "Warning: if you increase the time of this cooling setpoint override, you will also increase the time of the heating setpoint override.",
    "DUAL_SETPOINT_UPDATE_TIME_HEATING_DECREASE_OVERRIDE_WARNING": "Warning: if you decrease the time of this heating setpoint override, you will also decrease the time of the cooling setpoint override.",
    "DUAL_SETPOINT_UPDATE_TIME_COOLING_DECREASE_OVERRIDE_WARNING": "Warning: if you decrease the time of this cooling setpoint override, you will also decrease the time of the heating setpoint override.",
    "WHEN_ENABLED": "When enabled",
    "ENERGY_SAVING_DESCRIPTION_GENERAL": "all room sensors' target temperatures are decreased (for heating) or increased (for cooling) by",
    "ENERGY_SAVING_DESCRIPTION_DEHUMIDIFICATION_RELATIVE": "All room sensors' target dehumidification relative humidity are increased by",
    "ENERGY_SAVING_DESCRIPTION_DEHUMIDIFICATION_DEW_POINTS": "All room sensors' target dehumidification dew points are increased by",
    "ENERGY_SAVING_DESCRIPTION_HUMIDIFICATION_RELATIVE": "All room sensors' target humidification relative humidity are decreased by",
    "ENERGY_SAVING_DESCRIPTION_HUMIDIFICATION_DEW_POINTS": "All room sensors' target humidification dew points are decreased by",
    "ENERGY_SAVING_DESCRIPTION_FWA_GENERAL_1": "All the floor warmers' target temperature are decreased by",
    "ENERGY_SAVING_DESCRIPTION_FWA_GENERAL_2": "A floor warmer area request is suppressed if a related room sensor is off and energy saving is enabled",
    "ENERGY_SAVING_DESCRIPTION_FWA_IN_COOLING": "A floor warmer area request is suppressed if the connected hydronic system of a related room sensor is in cooling mode and energy saving is enabled"
};

<div class="rounded-container" [ngClass]="{'opened':tmrVisibility || !extensible}"> <!-- rounded container -->

    <ion-list-header class=" bg-header text-black positionRelative">

        <div *ngIf="extensible" class="positionAbsolute timer-arrow cursorPointer">
            <i (click)="show()" id="timer-icon" class="far"
               [ngClass]="{'fa-chevron-up':tmrVisibility,'fa-chevron-down':!tmrVisibility}"></i>
        </div>

        <!-- stava in ion-list-header [ngClass]="{'system-border-top':mustHaveBorder(i)}" -->
        <div class="d-flex flex-row justify-content-between align-items-center w-100">
            <div class="d-flex flex-row justify-content-start align-items-center">

                <circle-countdown [tmr_extended]="tmr_extended" [isTimerIcon]="true"></circle-countdown>

                <div class="d-flex flex-row align-items-center">
                    <ion-label class="text-black ars-title">
                        <div class="title-tmr">
                            {{ tmr.CFG_Name }}
                        </div>

                    </ion-label>
                </div>
            </div>

            <div class="d-flex flex-row justify-content-end align-items-center">
                <ion-item lines="none">
                    <ion-toggle color="secondary" [disabled]="false" [checked]="tmrState"

                                (ionChange)="updateTmrState($event)"></ion-toggle>
                </ion-item>
            </div>
        </div>
    </ion-list-header>

    <div *ngIf="tmrVisibility || !extensible">

        <!-- timer sch section (modeVarEmitter)="updateTimerState($event,i)"-->

        <schedule [disabled]="tmr.PAR_Mode === 0" [usePopupIsOpenedCheck]="true" [class]="tmrScheduleStyle(tmr)" toggle="PAR_Mode" [ngClass]="{'narrow':tmr.PAR_IdxSCH!=-1}"
                  table="TMR" [item]="tmr" modeVar="PAR_Mode" idxVar="PAR_IdxSCH" [SCH]="SCH" [MCU]="MCU"
                  [noToggle]="false">

            <schedule-option [value]="1" schedulation>{{ 'SCHEDULE' | translate }}</schedule-option>

        </schedule>

        <!-- <div class="d-flex flex-row justify-content-center align-items-center">
            <button-collapse buttonClass="schedule-messana-button" *ngIf="tmr.PAR_IdxSCH!=-1"

                             [(ngModel)]="schModeTMR"
                             label="{{ 'SCH_PREVIEW' | translate }}" [ngModelOptions]="{standalone: true}"
                             itemClass="no-lines"></button-collapse>
        </div> -->

        <schedule-graph [currentDateTime]="currentDateTime" *ngIf="tmr.PAR_Mode === 1 && tmr.PAR_IdxSCH !== -1 && currentDateTime.dateTimeAsMoment"
                        [config]="config" [schedule]="SCH[tmr.PAR_IdxSCH]" [id]="i+'timer'"></schedule-graph>

        <!-- end of timer sch section-->

        <ion-item lines="none">
            <!-- <div class="d-flex flex-column justify-content-center align-items-center w-100"> -->
            <div class="d-flex justify-content-between align-items-center w-100">
                <div class="d-flex flex-row justify-content-start align-items-center">
                    <div class="d-flex flex-row justify-content-start align-items-center">
                        <span class="far fa-history fa-flip-horizontal"
                              [ngClass]="{'messana-green':isOverrideActive(tmr)}"></span>
                    </div>
                    <div>
                        {{ 'OVEERRIDE' | translate }}
                    </div>
                </div>
                <div class="right-align">
                    <div (click)="resetOverride(tmr)"
                         class="tmrCircleReset d-flex flex-row justify-content-center align-items-center"><i
                            class="fad fa-history"
                            [ngClass]="{'can-override cursorPointer':canResetOverride(tmr),'no-override':!canResetOverride(tmr)}"
                            style="--fa-primary-opacity: 0;  --fa-secondary-opacity:1; font-size:2rem; margin:0;"></i>
                    </div>
                </div>
            </div>
            <!-- </div> -->

        </ion-item>

        <ion-item lines="none">
            <div class="d-flex justify-content-between align-items-center w-100 timeRow">

                <circle-countdown *ngFor="let overTime of overrideArray" (click)="forcePeriod(tmr,overTime)"
                                  [disabledOverride]="disabledOverride(tmr)" [tmr_extended]="tmr_extended"
                                  [overTime]="overTime"
                                  [isOverrideTimer]="true"></circle-countdown>
            </div>

        </ion-item>
    </div>
</div>

import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from "@angular/common";
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';



import {AcceptanceComponent} from './acceptance.component';
import {IonicModule} from "@ionic/angular";


@NgModule({
    imports: [CommonModule, TranslateModule, FormsModule, IonicModule],
	declarations:[AcceptanceComponent],
	exports:[AcceptanceComponent],
	schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AcceptanceModule{}
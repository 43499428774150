import {AttributesActions, AttributeActionTypes} from '../actions/attributes.actions';
import {Attributes} from '../models/attributes';

const initialState: Attributes = {} as Attributes;

/**
 * Attributes reducer
 * (state management)
 *
 * Reducers are the foundation of any Store or Redux-based application, describing
 * sections of state and their potential transformations based on dispatched
 * action types. It is the combination of your reducers that makes up a
 * representation of application state at any given time.
 *
 * A reducer is a pure function, accepting two arguments, the previous state
 * and an action with a type and optional data (payload) associated with the
 * event.
 */
export function attributesReducer(state: Attributes = initialState, action: AttributesActions): Attributes {

    switch (action.type) {

        case AttributeActionTypes.LoadAttributesSuccess:

            return {...state, ...action.data};

        case AttributeActionTypes.LoadAttributesError:
            return {};

        default:
            return state;
    }
}

import {Injectable} from '@angular/core';
import {Zeroconf} from '@ionic-native/zeroconf/ngx';
import {NetworkInterface} from '@ionic-native/network-interface/ngx';
import * as _ from 'lodash';

import {of, interval, Subscription, from, timer, Observable, forkJoin, throwError, Subject} from 'rxjs';
import {
    concatMap,
    timeout,
    catchError,
    delay,
    startWith,
    switchMap,
    map,
    toArray,
    mergeMap,
    tap,
    first, takeUntil
} from 'rxjs/operators';
import {SupportService} from './support.service';

import {Network, ConnectionStatus} from '@capacitor/network';
import {ToastController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {Store} from "@ngrx/store";
import {AppState} from "./app-state.service";
import {HttpStatusService} from "./http-status.service";
import {Config, EnvironmentType} from "../models/config";
import {AppLoadConfigSuccess, ChangeConfigValue, DiscoveryLoginConfig} from "../actions/config.actions";
import {CommutationError, CommutationSuccess} from "../actions/http-status.actions";
import {NetworkWay} from "../models/http-status";
import {HttpHeaders} from "@angular/common/http";
import {User} from "../models/user";
import {ZeroconfDevice} from "../models/zeroconfDevice";
import {PopupService} from "./popup.service";
import {BluetoothService} from "./bluetooth.service";

@Injectable({
    providedIn: 'root'
})

export class ScannerService {

    public currentLocalSystemIP = ''; // used in the option "SWITCH_LOCAL_SYSTEM_STARTUP"

    private modality: NetworkWay;
    private config: Observable<Config>;
    private httpNetworkStatusForLogSub: Subscription;
    private zeroconfSubscription: Subscription;
    private zeroconfTimeoutSubscription: Subscription;
    private toast;
    private useLocalSystemAtStartup;
    private currentMcu;

    constructor(public zeroconf: Zeroconf,
                private store: Store<AppState>,
                private supportService: SupportService,
                private toastController: ToastController,
                private popupService: PopupService,
                private translate: TranslateService,
                private bluetoothService: BluetoothService,
                private networkInterface: NetworkInterface) {

        this.config = this.store.select('config') as Observable<Config>;

        this.config.subscribe(data => {

            this.useLocalSystemAtStartup = data.useLocalSystemAtStartup;
            this.currentMcu = data.currentMcu;
        });
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- UNWATCH ZEROCONF --------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    reInitZeroConf() {

        return this.zeroconf.reInit().then(() => {

            this.zeroconf.watchAddressFamily = 'ipv4';

            if (this.httpNetworkStatusForLogSub) {

                this.httpNetworkStatusForLogSub.unsubscribe();
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- CLICK MBOX (DISCOVERY) OR FIRST AUTO-LOGIN DETECTED ---------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    // fromAutoSwitch: from zeroconf auto-detection
    updateSelectedValue(device: ZeroconfDevice, httpStatusService: HttpStatusService, fromAutoSwitch = false) {

        if (!device.loading) {

            device.loading = true;

            this.store.dispatch(new ChangeConfigValue('baseUrl', `http://${device.address}/`));
            this.store.dispatch(new ChangeConfigValue('loginUrl', `http://${device.address}/api/login`));
            this.store.dispatch(new ChangeConfigValue('environment', EnvironmentType.MCU));

            window['ENVIRON'] = 'mcu';
            window['BASE_URL'] = `http://${device.address}/`;

            this.httpNetworkStatusForLogSub = httpStatusService.probeNetworkStatusForLogin().pipe(first(),

                tap(res => {

                    console.log('subscription activated', res);
                    this.modality = res;
                }),

                map(data => new CommutationSuccess(data as NetworkWay)),

                catchError((err) => of(new CommutationError(NetworkWay.Remote))),

                mergeMap(action => {
                    this.store.dispatch(action);
                    return this.config;
                }),

                switchMap(configData => {

                    let body = {username: "ADMIN", password: "Messana"};
                    let _headers = new HttpHeaders({'Content-Type': 'application/json'});
                    let url = configData.loginUrl;

                    return this.supportService.superHttpPost(this.modality, url, body, {
                        headers: _headers,
                        withCredentials: false
                    });

                })).subscribe(data => this.logSuccessOffline(data, device, fromAutoSwitch),

                err => {
                    console.log(err);
                });
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- NETWORK STATUS (SCAN FOR LOCAL DEVICES)  --------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    // firstLoad: first detection of the auto-switch (it happens at startup. firstLoad = false happens when the listener finds a system and will give you a popup)
    async checkNetworkStatus(dataLoaded, firstLoad, status: ConnectionStatus, httpStatusService, router, mustCheckMcuCodes = false, McuCodesAvailable = []) {

        // -------------------------------------------------------------------------------------------------------------
        // --- POPUP (NEW DEVICE FOUND) --------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        const fireLocalDeviceFoundPopup = async (zeroconfDevice: ZeroconfDevice) => {

            const actions = [];

            actions.push({
                text: 'YES',
                handler: async () => {

                    // await this.presentToast('ZEROCONF_DEVICE_LOADING', 3000); // disabled the toast on: 26/01/2023
                    this.updateSelectedValue(zeroconfDevice, httpStatusService, true);
                },
                cssClass: 'blue-ion-button'
            });

            actions.push({
                text: 'NO',
                cssClass: 'blue-ion-button'
            });

            const message = `${this.translate.instant('SEARCHING_ZEROCONF_DEVICE_FOUND_TEXT_1')}<br><br>${this.translate.instant('HOSTNAME')}: ${zeroconfDevice.hostname}<br>${this.translate.instant('IP_ADDRESS')}: ${zeroconfDevice.address}<br><br>${this.translate.instant('SEARCHING_ZEROCONF_DEVICE_FOUND_TEXT_2')}`;
            await this.popupService.showCustomButtonsWarning('SEARCHING_ZEROCONF_DEVICE_FOUND', message, actions);
        }

        // -------------------------------------------------------------------------------------------------------------
        // --- Check if there's a new local device --------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        // console.warn('status.connected', status.connected);
        // console.warn('status.connectionType', status.connectionType);

        // I don't want to load any local device / show a popup if I'm in the loading page
        // console.warn(router.url);

        if (router.url === '/login') {

            return;
        }

        if (status.connected && status.connectionType == 'wifi' && this.useLocalSystemAtStartup && httpStatusService.modality !== NetworkWay.BLUETOOTH && !this.bluetoothService.isConnecting() && !this.bluetoothService.isDisconnecting()) {

            // console.log('launching toast');
            await this.reInitZeroConf();

            const maxScanTime = 8000;
            // await this.presentToast('SEARCHING_ZEROCONF_DEVICES', maxScanTime); // disabled the toast on: 26/01/2023

            // ---------------------------------------------------------------------------------------------------------

            if (this.zeroconfSubscription) {

                this.zeroconfSubscription.unsubscribe();
            }

            if (this.zeroconfTimeoutSubscription) {

                this.zeroconfTimeoutSubscription.unsubscribe();
            }

            // ---------------------------------------------------------------------------------------------------------

            this.zeroconfTimeoutSubscription = timer(maxScanTime).subscribe(async () => {

                if (this.zeroconfSubscription) {

                    this.zeroconfSubscription.unsubscribe();
                }

                if (firstLoad) {

                    this.store.dispatch(new AppLoadConfigSuccess(dataLoaded));
                }

                // await this.presentToast('NO_LOCAL_DEVICES_FOUND', 3000); // disabled the toast on: 26/01/2023
            });

            // ---------------------------------------------------------------------------------------------------------

            this.zeroconfSubscription = this.zeroconf.watch('_http._tcp.', 'local.').subscribe(async zeroconfResult => {

                if (zeroconfResult.action === "resolved" && zeroconfResult.service.ipv4Addresses.length > 0) {

                    console.warn('zeroconfResult', zeroconfResult);
                    let ip = zeroconfResult.service.ipv4Addresses[0];
                    // let ip = zeroconfResult.service.txtRecord.ip;

                    // console.log('mustCheckMcuCodes', mustCheckMcuCodes);
                    // console.log('McuCodesAvailable', McuCodesAvailable);
                    // console.log('mcuCode', zeroconfResult.service.txtRecord.mcuCode);

                    // If it's from auto-switch -> mustCheckMcuCodes is true -> I can only load the local system if I've the access (McuCodesAvailable are the MCUs that I have access -> data.currentUser.userOf)
                    if (mustCheckMcuCodes && !_.includes(McuCodesAvailable, zeroconfResult.service.txtRecord.mcuCode)) {

                        // If the mcuCode returned from the system isn't included in the MCUs code that I can see -> ignore the local system
                        return;
                    }

                    if (this.currentMcu === zeroconfResult.service.txtRecord.mcuCode) {

                        return;
                    }

                    if (this.currentLocalSystemIP !== ip) {

                        this.zeroconfSubscription.unsubscribe();
                        this.zeroconfTimeoutSubscription.unsubscribe();
                        this.currentLocalSystemIP = ip;
                        console.log('this.currentLocalSystemIP', ip);

                        let zeroconfDevice = new ZeroconfDevice(ip, zeroconfResult.service.txtRecord.hostname, zeroconfResult.service.txtRecord.mcuCode);
                        await this.presentToast('SEARCHING_ZEROCONF_DEVICE_FOUND', 3000);

                        // At startup, if I find a local device -> I connect it right away
                        if (firstLoad) {

                            this.updateSelectedValue(zeroconfDevice, httpStatusService, true);
                        }

                        // After the first scan, I show a toast for 3 seconds and then show a Popup with the new local device found (maybe the user is on another system and doesn't want to automatically switch)
                        else {

                            timer(3000).subscribe(async () => {

                                await fireLocalDeviceFoundPopup(zeroconfDevice);
                            });
                        }
                    }
                }
            });

        } else {

            if (this.useLocalSystemAtStartup && httpStatusService.modality !== NetworkWay.BLUETOOTH && !this.bluetoothService.isConnecting() && !this.bluetoothService.isDisconnecting()) {

                // await this.presentToast('NO_WIFI', 6000); // disabled the toast on: 26/01/2023
            }

            // If I don't find a system at startup -> Dispatch AppLoadConfigSuccess() normally
            if (firstLoad) {

                // console.log('calling: AppLoadConfigSuccess');
                this.store.dispatch(new AppLoadConfigSuccess(dataLoaded));
            }
            // return this.configService.getConfig().pipe(map(data => new AppLoadConfigSuccess(data)));
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- LOG-IN WITH LOCAL SYSTEM ------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    async logSuccessOffline(data, device: ZeroconfDevice, fromAutoSwitch) {

        // console.log('logSuccessOffline');

        if (this.httpNetworkStatusForLogSub) {

            this.httpNetworkStatusForLogSub.unsubscribe();
        }

        let user = new User();

        user.username = '';
        user.token = data.token;
        user.userOf = data.userOf;
        user.guestOf = data.guestOf;
        user.admin = data.admin;
        user.vip = data.vip;

        let mcu = device.mcuCode;

        // The old fallback system to the zero-conf (direct-ip search) doesn't have a MCU
        if (!mcu) {

            if (data.currentMcu) {

                mcu = data.currentMcu;

            } else if (user.userOf.length === 1 && user.guestOf.length === 0) {

                mcu = user.userOf[0].code;

            } else if (user.userOf.length === 0 && user.guestOf.length === 1) {

                mcu = user.guestOf[0].code;
            }

            console.log('mcu-fallback-system', mcu);
        }

        this.store.dispatch(new DiscoveryLoginConfig(user, mcu, fromAutoSwitch));
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- WI-FI (TOAST) -----------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    async presentToast(toastMessage: string, duration = 6500) {

        if (this.toast) {

            this.toast.dismiss();
        }

        this.toast = await this.toastController.create({
            message: this.translate.instant(toastMessage),
            position: 'bottom',
            duration: duration,
        });
        await this.toast.present();
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- WI-FI (CHECK) -----------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    isWifiOn() {
        return from(Network.getStatus()).pipe(
            switchMap((status: ConnectionStatus) => {
                if (status.connected && status.connectionType == 'wifi') {
                    return of(true);
                } else return of(false);
            }));
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- CHECK AUTO-CONNECTION (ZEROCONF) AFTER NETWORK SETTINGS UPDATE ----------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    checkAutoConnectionAfterNetworkUpdate(isZeroConf, ipFoundSubject) {

        const changeIpAddress = (ip) => {

            this.store.dispatch(new ChangeConfigValue('baseUrl', `http://${ip}/`));
            this.store.dispatch(new ChangeConfigValue('loginUrl', `http://${ip}/api/login`));
            window['BASE_URL'] = `http://${ip}/`;
        }

        if (isZeroConf) {

            return this.zeroconf.watch('_http._tcp.', 'local.').subscribe(async zeroconfResult => {

                if (zeroconfResult.action === "resolved" && zeroconfResult.service.ipv4Addresses.length > 0) {

                    const ip = zeroconfResult.service.ipv4Addresses[0];
                    this.currentLocalSystemIP = ip;
                    changeIpAddress(ip);
                    ipFoundSubject.next(true);
                    console.error('found IP from ZeroConf and blocked old-direct IP search', ip);
                }
            });

        } else {

            return this.discoverDevicesOldSystem().pipe(

                delay(10000),
                takeUntil(ipFoundSubject)).subscribe(

                (dualArray: ZeroconfDevice[]) => {

                    console.log(dualArray);

                    for (let dual of dualArray) {

                        if (dual.address !== '') {

                            console.log('found IP from old direct-ip', dual.address);
                            changeIpAddress(dual.address);
                            break;
                        }
                    }
                });
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- FALLBACK OLD DIRECT-IP SEARCH -------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    // Users should only use zero-conf (from 08/2022) for guest direct-ip connections, but if they don't have an updated mini-pc they will still use the old system as a fallback
    discoverDevicesOldSystem() {

        return from(Network.getStatus()).pipe(
            switchMap((status: ConnectionStatus) => {

                if (status.connected && status.connectionType == 'wifi') {

                    return from(this.networkInterface.getWiFiIPAddress()).pipe(
                        // tap(address=>{console.log(`IP: ${address.ip}, Subnet: ${address.subnet}`)}),

                        concatMap(complexAddress => {

                                let subnet = complexAddress.subnet;
                                let subnetParts = subnet.split('.');
                                let three = false;
                                let two = false;
                                let one = false;

                                if (Number(subnetParts[3]) == 0) {

                                    three = true;
                                }

                                if (Number(subnetParts[2]) == 0) {

                                    two = true;
                                }

                                if (Number(subnetParts[1]) == 0) {

                                    one = true;
                                }

                                let add = complexAddress.ip;
                                let parts = add.split('.');

                                let totalAddresses: string[] = [];

                                if (three && !two && !one) {

                                    for (let end = 0; end < 256; end++) {

                                        let address = `${parts[0]}.${parts[1]}.${parts[2]}.${end}`;
                                        totalAddresses.push(address);
                                    }
                                }

                                if (three && two && !one) {

                                    for (let end = 0; end < 256; end++) {

                                        for (let scnd = 0; scnd < 256; scnd++) {
                                            let address = `${parts[0]}.${parts[1]}.${scnd}.${end}`;
                                            totalAddresses.push(address);
                                        }
                                    }
                                }

                                if (three && two && one) {

                                    for (let end = 0; end < 256; end++) {

                                        for (let scnd = 0; scnd < 256; scnd++) {
                                            for (let first = 0; first < 256; first++) {
                                                let address = `${parts[0]}.${first}.${scnd}.${end}`;
                                                totalAddresses.push(address);
                                            }
                                        }
                                    }
                                }

                                return from(totalAddresses).pipe(mergeMap(singleAddress => {

                                    return from(this.supportService.checkValidMcu(singleAddress).pipe(
                                        tap(foundDevice => {
                                            // console.log(`%cfound messana mcu with address ${foundDevice.address} and hostname ${foundDevice.hostname}`,'font-size:0.9rem;color:blue;')

                                        }), catchError(error => {

                                            // console.log(error);
                                            return of(new ZeroconfDevice('', '', null)) as Observable<ZeroconfDevice>;
                                        })));


                                }), toArray())
                            }
                        ));
                }

                if (status.connected && status.connectionType != 'wifi') {

                    //notificare all'utente la wifi spenta
                    return of(new ZeroconfDevice('', '', null)) as Observable<ZeroconfDevice>;
                }
            }));
    }
}

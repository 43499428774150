export const RITTER_THERMAL_PLUS_ALARMS_TRANSLATIONS = {
  "WRONG_PHASE": "Fase Errata",
  "MISSING_PHASE": "Fase Mancante",
  "WATER_FLOW_FAILURE": "Malfunzionamento del Flusso d'Acqua",
  "COMMUNICATION_FAILURE": "Malfunzionamento della Comunicazione",
  "EMERGENCY_FAILURE": "Malfunzionamento di Emergenza",
  "OUT_OF_USE_TIME": "Fuori Uso",
  "WATER_TANK_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura del Serbatoio d'Acqua",
  "WATER_INLET_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura dell'Ingresso dell'Acqua",
  "INDOOR_AMBIENT_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura Ambientale Interna",
  "OUTDOOR_AMBIENT_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura Ambientale Esterna",
  "DHW_RETURN_WATER_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura del Ritorno dell'Acqua Calda Sanitaria",
  "WATER_OUTLET_TEMP_TOO_LOW_IN_COOLING_MODE": "Temperatura dell'Uscita dell'Acqua Troppo Bassa in Modalità di Raffreddamento",
  "WATER_LEVEL_SWITCH_FAILURE": "Guasto al Interruttore del Livello dell'Acqua",
  "WATER_OUTLET_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura dell'Uscita dell'Acqua",
  "WATER_OUTLET_TEMP_TOO_HIGH_IN_HEATING_MODE_LARGE_TEMP_DIFFERENCE": "Temperatura dell'Uscita dell'Acqua Troppo Alta in Modalità di Riscaldamento con Ampia Differenza di Temperatura",
  "BETWEEN_WATER_INLET_AND_OUTLET": "tra Ingresso e Uscita dell'Acqua",
  "AMBIENT_TEMP_TOO_LOW": "Temperatura Ambiente Troppo Bassa",
  "INDOOR_AMBIENT_HUMIDITY_FAILURE": "Guasto all'Umidità Ambientale Interna",
  "PHASE_SEQUENCE_DIP_SWITCH_ERROR": "Errore del Commutatore DIP della Sequenza di Fasi",
  "WATER_PUMP_1_FAILURE": "Guasto alla Pompa dell'Acqua 1",
  "WATER_PUMP_2_FAILURE": "Guasto alla Pompa dell'Acqua 2",
  "LOW_WATER_FLOW": "Flusso d'Acqua Basso",
  "PHASE_SEQUENCE_DISCONNECTED": "Sequenza di Fasi Disconnessa",
  "EXPANSION_BOARD_COMMUNICATION_FAILURE": "Malfunzionamento della Comunicazione della Scheda di Espansione",
  "PLATE_HEAT_EXCHANGER_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura dello Scambiatore di Calore a Piastra",
  "FAN_BOARD_1_COMMUNICATION_FAILURE": "Malfunzionamento della Comunicazione della Scheda Ventola 1",
  "FAN_BOARD_2_COMMUNICATION_FAILURE": "Malfunzionamento della Comunicazione della Scheda Ventola 2",
  "CASCADE_MODEL_MISMATCH": "Conflitto del Modello a Cascata",
  "SOLAR_WATER_HEATER_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura del Riscaldatore Solare dell'Acqua",
  "AHS_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura AHS",
  "BUFFER_TANK_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura del Serbatoio Buffer",
  "TOTAL_WATER_OUTLET_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura Totale dell'Uscita dell'Acqua",
  "ZONE_1_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura della Zona 1",
  "HIGH_PRESSURE_SWITCH_FAILURE": "Guasto all'Interruttore di Pressione Alta",
  "LOW_PRESSURE_SWITCH_FAILURE": "Guasto all'Interruttore di Pressione Bassa",
  "HIGH_PRESSURE_TOO_HIGH": "Pressione Alta Troppo Alta",
  "HIGH_PRESSURE_TOO_LOW": "Pressione Alta Troppo Bassa",
  "EXHAUST_PRESSURE_TOO_HIGH": "Pressione di Scarico Troppo Alta",
  "CURRENT_PROTECTION": "Protezione dalla Corrente",
  "COIL_PRESSURE_TOO_HIGH": "Pressione della Bobina Troppo Alta",
  "COIL_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura della Bobina",
  "SUCTION_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura dell'Aspirazione",
  "EXHAUST_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura di Scarico",
  "ECONOMIZER_INLET_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura dell'Ingresso dell'Economizzatore",
  "ECONOMIZER_OUTLET_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura dell'Uscita dell'Economizzatore",
  "FAN_DRIVE_BOARD_COMMUNICATION_FAILURE": "Malfunzionamento della Comunicazione della Scheda di Controllo della Ventola",
  "FAN_FAILURE": "Guasto alla Ventola",
  "COOLING_COIL_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura della Bobina di Raffreddamento",
  "HIGH_PRESSURE_SENSOR_FAILURE": "Guasto al Sensore di Pressione Alta",
  "LOW_PRESSURE_SENSOR_FAILURE": "Guasto al Sensore di Pressione Bassa",
  "MIDDLE_PRESSURE_SWITCH_FAILURE": "Guasto all'Interruttore di Pressione Media",
  "COIL_TEMP_TOO_HIGH": "Temperatura della Bobina Troppo Alta",
  "COMPRESSOR_DRIVE_BOARD_COMMUNICATION_FAILURE": "Malfunzionamento della Comunicazione della Scheda di Controllo del Compressore",
  "IPM_OVERCURRENT_IPM_MODULE_PROTECTION": "Sovracorrente IPM/Protezione del Modulo IPM",
  "COMPRESSOR_DRIVER_FAILURE": "Guasto al Driver del Compressore",
  "COMPRESSOR_OVERCURRENT": "Sovracorrente del Compressore",
  "INPUT_VOLTAGE_MISSING_PHASE": "Fase Mancante della Tensione di Ingresso",
  "IPM_CURRENT_SAMPLING_FAILURE": "Malfunzionamento del Campionamento Corrente IPM",
  "POWER_COMPONENT_OVERHEATING_AND_SHUTDOWN": "Surriscaldamento del Componente di Alimentazione e Arresto",
  "PRE-CHARGE_FAILURE": "Malfunzionamento della Pre-carica",
  "DC_BUS_OVERVOLTAGE": "Sovratensione del Bus DC",
  "DC_BUS_UNDERVOLTAGE": "Sottotensione del Bus DC",
  "AC_INPUT_UNDERVOLTAGE": "Sottotensione dell'Ingresso CA",
  "AC_INPUT_OVERVOLTAGE": "Sovratensione dell'Ingresso CA",
  "INPUT_VOLTAGE_SAMPLING_FAILURE": "Malfunzionamento del Campionamento della Tensione di Ingresso",
  "DSP_AND_PFC_COMMUNICATION_FAILURE": "Malfunzionamento della Comunicazione DSP e PFC",
  "BOARD_RADIATOR_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura del Radiatore della Scheda",
  "DSP_AND_COMMUNICATE_BOARD_COMMUNICATION_FAILURE": "Malfunzionamento della Comunicazione della Scheda DSP e di Comunicazione",
  "COMMUNICATION_FAILURE_WITH_MOTHERBOARD": "Malfunzionamento della Comunicazione con la Scheda Madre",
  "COMPRESSOR_OVERCURRENT_ALARM": "Allarme Sovracorrente del Compressore",
  "COMPRESSOR_WEAK_MAGNETIC_PROTECTION_ALARM": "Allarme di Protezione Magnetica Debole del Compressore",
  "PIM_OVERHEAT_ALARM_PFC_OVERHEAT_ALARM": "Allarme di Surriscaldamento PIM/Allarme di Surriscaldamento PFC",
  "PFC_OVERHEAT_ALARM": "Allarme di Surriscaldamento PFC",
  "AC_INPUT_OVERCURRENT_ALARM": "Allarme Sovracorrente dell'Ingresso CA",
  "EEPROM_ERROR_ALARM": "Allarme Errore EEPROM",
  "EEPROM_REFRESH_COMPLETE": "Aggiornamento EEPROM Completato",
  "TEMPERATURE_SENSING_FAILURE_LIMIT_ALARM": "Allarme Limite Malfunzionamento Rilevazione Temperatura",
  "AC_UNDERVOLTAGE_FREQUENCY_LIMIT_PROTECTION_ALARM": "Allarme Protezione Limite di Frequenza Sottotensione CA",
  "IPM_MODULE_OVERHEAT_AND_SHUTDOWN": "Surriscaldamento del Modulo IPM e Arresto",
  "COMPRESSOR_MISSING_PHASE": "Fase Mancante del Compressore",
  "COMPRESSOR_OVERLOAD": "Sovraccarico del Compressore",
  "INPUT_CURRENT_SAMPLING_FAILURE": "Malfunzionamento del Campionamento Corrente di Ingresso",
  "PIM_SUPPLY_VOLTAGE_FAILURE": "Guasto alla Tensione di Alimentazione PIM",
  "PRE-CHARGE_CIRCUIT_VOLTAGE_FAILURE": "Guasto alla Tensione del Circuito di Pre-carica",
  "EEPROM_FAILURE": "Guasto EEPROM",
  "AC_INPUT_OVERVOLTAGE_FAILURE": "Guasto Sovratensione dell'Ingresso CA",
  "MICROELECTRONICS_FAILURE": "Guasto delle Microelettroniche",
  "COMPRESSOR_TYPE_CODE_FAILURE": "Guasto al Codice Tipo Compressore",
  "CURRENT_SAMPLING_SIGNAL_OVERCURRENT": "Sovracorrente del Segnale di Campionamento di Corrente",
  "HIGH_PRESSURE_SWITCH_2_FAILURE": "Guasto all'Interruttore di Pressione Alta 2",
  "LOW_PRESSURE_SWITCH_2_FAILURE": "Guasto all'Interruttore di Pressione Bassa 2",
  "HIGH_PRESSURE_2_TOO_HIGH": "Pressione Alta 2 Troppo Alta",
  "HIGH_PRESSURE_2_TOO_LOW": "Pressione Alta 2 Troppo Bassa",
  "EXHAUST_PRESSURE_2_TOO_HIGH": "Pressione di Scarico 2 Troppo Alta",
  "CURRENT_2_PROTECTION": "Protezione Sovracorrente 2",
  "COIL_2_PRESSURE_TOO_HIGH": "Pressione della Bobina 2 Troppo Alta",
  "COIL_2_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura della Bobina 2",
  "SUCTION_2_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura dell'Aspirazione 2",
  "EXHAUST_2_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura di Scarico 2",
  "ECONOMIZER_2_INLET_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura dell'Ingresso dell'Economizzatore 2",
  "ECONOMIZER_2_OUTLET_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura dell'Uscita dell'Economizzatore 2",
  "FAN_DRIVE_2_BOARD_COMMUNICATION_FAILURE": "Malfunzionamento della Comunicazione della Scheda di Controllo Ventola 2",
  "FAN_2_FAILURE": "Guasto alla Ventola 2",
  "COOLING_2_COIL_TEMP_SENSOR_FAILURE": "Guasto al Sensore di Temperatura della Bobina di Raffreddamento 2"
}

<div *ngIf="loaded" class="alert-wrapper newMacrozoneModal">
    <div class="alert-head">
        <h2 class="alert-title" *ngIf="title" [innerHTML]="title"></h2>
    </div>

    <div class="alert-message">
        {{'TERMS_OF_USE_MESSAGE_START' | translate}}
        <a href="https://messana.tech/terms-of-use" target="_blank">{{'TERMS_OF_USE' | translate}}</a>{{'TERMS_OF_USE_MESSAGE_AND' | translate}} <a
            href="https://messana.tech/privacy-policy" target="_blank">{{'PRIVACY_POLICY' | translate}}</a>

        <!--<a (click)="openTermsOfUse()">{{'TERMS_OF_USE' | translate}}</a>{{'TERMS_OF_USE_MESSAGE_AND' | translate}} <a (click)="openPrivacy()">{{'PRIVACY_POLICY' | translate}}</a>-->
    </div>

    <ion-list class="border">

        <div class="d-flex flex-row justify-content-start align-items-center">
            <div>
                <ion-item class="noPaddingRight no-lines">
                    <ion-checkbox (ionChange)="handleAcceptance($event)" [(ngModel)]="_acceptance" [ngModelOptions]="{standalone: true}" ngDefaultControl></ion-checkbox>
                </ion-item>
            </div>
            <div class="noPaddingLeft">
                <!--<ion-item class="no-lines">
                      <ion-label class="noMargin text-uppercase">{{statement}}</ion-label>


                    </ion-item>-->
                <p class="text-uppercase statement">{{statement}}</p>

            </div>
        </div>
    </ion-list>

    <div class="alert-button-group">

        <button category="alert-button" *ngFor="let b of buttons" (click)="btnClick(b)" class="alert-button">
            {{b.text}}
        </button>
    </div>
</div>
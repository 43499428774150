




      <ion-item class="no-lines w-100"> 
        <div id="button-navigation-calibration" class="padding-for-shadow button-padding-top w-100 d-flex flex-row justify-content-center">
                    
          <!--<div class="start-calibration-button-padding w-20 d-flex flex-row justify-content-between">
                <div >
                    <button class="chartButton squared-messana-button"  (click)="goStartCalibration(currentATU)" >
                        <i class="fal fa-angle-left"  aria-hidden="true"></i>
                    </button>
                </div>
                <div>
                    <button class="chartButton squared-messana-button"  (click)="goPhaseTwoCalibration(currentATU)" >
                        <i class="fal fa-angle-right"  aria-hidden="true"></i>
                    </button>
                </div>
            </div>-->

            <div class="d-flex flex-row justify-content-between w-50">
              <div class="positionRelative">
                <div class="loader"></div>
                <button [disabled]="disabled" [ngClass]="{'disabled':disabled}" class="standard-messana-button d-flex flex-row justify-content-center align-items-center" (click)="prevEmitter()" ><i class="fal fa-angle-left"  aria-hidden="true"></i>&nbsp;{{prevText | uppercase }}</button>
              </div>
              <div class="positionRelative">
                <div class="loader"></div>
                <button [disabled]="disabled" [ngClass]="{'disabled':disabled}" class="standard-messana-button d-flex flex-row justify-content-center align-items-center" (click)="nextEmitter()">{{nextText | uppercase}}&nbsp;<i *ngIf="!exit" class="fal fa-angle-right"  aria-hidden="true"></i><i *ngIf="exit" class="fal fa-sign-out-alt"  aria-hidden="true"></i></button>
              </div>
            </div>
          


           
        </div>
      </ion-item> 
  
  
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FWA, MCU, SCH} from "../../models/tables";
import {Config, SchedulationType} from "../../models/config";
import {ChangeTableValues} from "../../actions/tables.actions";
import {Store} from "@ngrx/store";
import {AppState} from "../../services/app-state.service";
import {CurrentDateTime} from "../../models/current-date-time";
import {Attributes} from "../../models/attributes";
import {AbstractConfigPage} from "../../pages/abstract-config-page";

@Component({
    selector: 'floor-warmer',
    templateUrl: './floor-warmer.component.html',
    styleUrls: ['./floor-warmer.component.scss'],
})
export class FloorWarmerComponent implements OnInit {

    @Input() MCU: MCU;
    @Input() SCH: SCH[];
    @Input() config: Config;
    @Input() attrs: Attributes;
    @Input() context: AbstractConfigPage;

    @Input() fwaState: boolean;

    @Input() fwa: FWA;
    @Input() fwaVisibility: boolean;
    @Input() extensible: boolean;

    @Input()
    currentDateTime: CurrentDateTime;

    @Output() updateFwaVisibility: EventEmitter<boolean> = new EventEmitter();

    public readonly SchedulationType = SchedulationType;

    constructor(protected store: Store<AppState>) {
    }

    ngOnInit() {
    }

    show() {

        this.fwaVisibility = !this.fwaVisibility;
        this.updateFwaVisibility.emit(this.fwaVisibility);
    }

    updateFwaState($event) {

        this.fwa.PAR_On = $event.detail.checked;
        this.store.dispatch(new ChangeTableValues('FWA', this.fwa.id, {PAR_On: this.fwa.PAR_On}));
    }

    fwaScheduleStyle(fwa: FWA): string {

        if (this.SCH && fwa.PAR_IdxSCH != -1) {

            if (this.SCH[fwa.PAR_IdxSCH] && this.SCH[fwa.PAR_IdxSCH].RTU_On) {

                return 'thermal-in-range';
            }

            return 'thermal-disabled';

        } else {

            return 'thermal-disabled';
        }
    }
}

import {Component, HostListener, OnInit, ViewChild} from '@angular/core';


import {
    ActionSheetController,
    NavParams,
    Platform,
    AlertController,
    ToastController,
    IonContent, ModalController
} from '@ionic/angular';


import {debounceTime} from 'rxjs/operators';

import {Observable, Subscription, timer} from 'rxjs';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';

import {ARS, ATU, AUTO_MODE_STYLE, ENR, FNC, FWA, GRP, HYS, MCU, MCZ, RMS, SCH, TMR, TNK, ZON} from '../../models/tables';

import {ActivatedRoute, Router} from '@angular/router';

import * as _ from 'lodash';
import {isApplicableTo} from '../../commons/schedule';

import {Config, DUAL_MODE, MacroZoneMode, SchedulationType, Season, ZoneMode, ZoneState} from '../../models/config';
import {AppState} from '../../services/app-state.service';
import {IdleConfig} from '../../actions/config.actions';
import {AbstractConfigPage} from '../abstract-config-page';
import {WindowRefService} from '../../services/window.service';

//to do martedì 11/06/2019
import {Location} from '@angular/common';

import {ChangeTableMultipleItems, ChangeTableValues, ChangeValuesRequest} from '../../actions/tables.actions';

import * as moment from 'moment';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {SupportService} from '../../services/support.service';
import {ShowPowerOff} from '../../components/tech/tech.component';
import {MCZ_Extended, RMS_Extended} from '../../models/tables-extended';
import {OverrideSetpointComponent} from "../zone/override-setpoint/override-setpoint.component";


declare var $: any;


/*export const selectedZones = (mcz_id:number,id:number)=> createSelector(
  state => state["tables"]["RMS"],
  (rms) => mcz_id == 0 ?  _.sortBy(rms, 'CFG_ViewOrder') : _.sortBy(rms.filter( zone => zone.CFG_IdxMCZ == id), 'CFG_ViewOrder')
)



export const mcuSel = createSelector(
  state => state["tables"]["MCU"],
  (data) => data[0]
)
export const mczSel = createSelector(
  state => state["tables"]["MCZ"],
  (data) => data
)
export const rmsSel = createSelector(
  state => state["tables"]["RMS"],
  (data) => data
)
export const grpSel = createSelector(
  state => state["tables"]["GRP"],
  (data) => data
)
export const hysSel = createSelector(
  state => state["tables"]["HYS"],
  (data) => data
)
export const fncSel = createSelector(
  state => state["tables"]["FNC"],
  (data) => data
)
export const zonSel = createSelector(
  state => state["tables"]["ZON"],
  (data) => data
)
export const schSel = createSelector(
  state => state["tables"]["SCH"],
  (data) => data
)

export const configSel = createSelector(
  state => state["config"],
  (data) => data
)*/


@Component({
    selector: 'app-macro-zone',
    templateUrl: './macro-zone.page.html',
    styleUrls: ['./macro-zone.page.scss'],
})
export class MacroZonePage extends AbstractConfigPage implements OnInit {

    TABLES = ['Attributes', 'Config', 'MCU', 'RMS', 'MCZ', 'GRP', 'SCH', 'ZON', 'HYS', 'FNC', 'TMR', 'ARS', 'ATU', 'ENR', 'TNK', 'FWA'];
    MCU: MCU = null;
    MCZ: MCZ[] = []; // Macro zones
    RMS: RMS[] = []; // Zones
    GRP: GRP[] = []; // Groups
    SCH: SCH[] = []; // Schedulations
    ZON: ZON[] = []; // Zones
    FNC: FNC[] = []; // Fan Coils
    HYS: HYS[] = []; // Hydronic Systems
    TMR: TMR[] = [];
    ARS: ARS[] = [];
    ATU: ATU[] = [];
    ENR: ENR[] = [];
    TNK: TNK[] = [];
    FWA: FWA[] = [];

    rooms: RMS[] = []

    noRadiant: boolean[];

    private realExceptionsH: boolean = false;
    private realExceptionsC: boolean = false;


    id: number;
    state: boolean; // 0 off, 1 energy saving, 2 on
    season: Season; // 0 = heating
    seasonExe: Season;
    techMode: boolean = false;
    dataLoaded = false;
    executeCallback = false;
    temperatureExceptions: number[] = []; // ids of the rooms with temperature exceptions
    temperatureExceptions_H: number[] = []; // ids of the rooms with temperature exceptions for setpointH
    temperatureExceptions_C: number[] = []; // ids of the rooms with temperature exceptions for setpointC

    withoutTemperatureExceptions: RMS[] = []; // ids of the rooms without temperature exceptions
    withoutTemperatureExceptions_H: RMS[] = []; // ids of the rooms without temperature exceptions for setpointH
    withoutTemperatureExceptions_C: RMS[] = []; // ids of the rooms without temperature exceptions for setpointC
    schedulationExceptions: number[] = []; // ids of the rooms with schedulation exceptions
    timeoutHandle = null; // used for detecting a swipe from a click
    owlState: boolean; // open window logic
    showOWL: boolean // show open window logic if at least one room have owl sensor
    esState: boolean; // energy saving
    window = null;


    public query: string;

    scheduleWindow: boolean;

    // Export for template
    Season = Season;

    //next and prev mcz id
    nextId: number = -1; // -1 not show button
    prevId: number = -1; // -1 not show button

    targetSCH: SCH;
    validMCZ: MCZ[]
    myZones: RMS_Extended[];
    selectedZoneObs: Observable<RMS[]>;
    // showPrevAll: boolean

    //
    forceDefaultDate: boolean = false
    firstLoad = false
    loading = true

    schLoaded: boolean = false;
    schMode = false;

    exceptionWindow: boolean = false;


    loadFromOnInit = false

    executingForce: boolean = false;


    mcz: MCZ;
    mczExtended: MCZ_Extended;

    emptySchedules: boolean
    allMacrozoneChanging: boolean

    tick;
    triggerTick;


    // date time members data

    /* hoursAM = []
    hoursPM = [] */


    currentDayIdx: number = -1;
    todayIdx: number = -1;
    dayNames: string[] = [];

    currentDateRegionFrom;
    currentDateRegionTo;
    currentDateRegionAMPM;

    hourDifference;
    currentDate;
    dateInterval;
    itLang = false;
    public changingPage: boolean = false;

    computedZone: boolean;


    dynamicMargin: number;
    mobileDynamicMargin: number;

    subNavigation: Subscription;
    resizeSubscribe: Subscription;
    protected observables: any;
    public SchedulationType = SchedulationType;

    @ViewChild(IonContent) ionContent: IonContent;


    constructor(
        private http: HttpClient,
        public router: Router, private activatedRoute: ActivatedRoute,
        protected store: Store<AppState>,
        protected platform: Platform,
        protected alertController: AlertController,
        protected actionSheetController: ActionSheetController,
        protected translate: TranslateService,
        protected windowRefService: WindowRefService,
        protected location: Location,
        public screenOrientation: ScreenOrientation,
        public supportService: SupportService,
        protected modalCtrl: ModalController,
        protected toastController?: ToastController,
    ) {
        super(store, platform, alertController, actionSheetController, translate, toastController);
        this.window = this.windowRefService.nativeWindow;


    }

    public manageMargins() {

        if (!this.computedZone) {
            setTimeout(() => {
                this.manageMargins();
            }, 1500)
        } else

            setTimeout(() => {


                    let height = 0;
                    if (document.getElementById("mcz-macro-zone") != null && document.getElementById("mcz-macro-zone") != undefined)
                        height = document.getElementById("mcz-macro-zone").offsetHeight;


                    // console.warn(`mcz ha height: ${height}`)

                    /* let offsetSched=190;
                     if(!this.schMode)
                         offsetSched=0;*/

                    if (this.validMCZ != undefined) {
                        if (this.id > 0 || (this.mcz.id == 0 && this.validMCZ.length == 1)) {


                            /* this.dynamicMargin=330+offsetSched;
                            this.mobileDynamicMargin=400+offsetSched; */
                            this.dynamicMargin = height + 20;
                            this.mobileDynamicMargin = height + 20;

                        } else {
                            this.dynamicMargin = 0;
                            this.mobileDynamicMargin = 0;
                        }
                    }


                    //console.log(`aggiorno i margini a ${this.dynamicMargin} ${this.mobileDynamicMargin}`);


                    document.documentElement.style.setProperty('--margin-mcz', this.dynamicMargin.toString() + 'px');
                    document.documentElement.style.setProperty('--mobile-margin-mcz', this.mobileDynamicMargin.toString() + 'px');
                }


                , 200);

    }


    ngOnDestroy() {
        if (this.resizeSubscribe != undefined)
            this.resizeSubscribe.unsubscribe();
    }

    ionViewWillEnter() {

        if (this.platform.is('cordova') || this.platform.is('capacitor')) {

            this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
        }

        this.computedZone = false;

        // console.log(`macro-zone.page, ionViewWillEnter, socketconnessa? ${this.connected}, id vale: ${this.id}`);
        this.scheduleWindow = false;
        this.store.dispatch(new IdleConfig());
        this.resizeSubscribe = this.platform.resize.pipe(debounceTime(300)).subscribe(async () => {

            this.manageMargins();


        });
    }

    ionViewDidEnter(): void {

        this.id = Number(this.activatedRoute.snapshot.queryParamMap.get("id"));
        this.executeCallback = true;
        this.load(this.TABLES, this.forceLoad.bind(this));
    }

    ionViewWillLeave() {
        if (this.platform.is('cordova') || this.platform.is('capacitor')) {

            this.screenOrientation.unlock();
        }

        this.executeCallback = false;
        this.loadFromOnInit = false
        if (this.resizeSubscribe != undefined)
            this.resizeSubscribe.unsubscribe();
    }

    loadCallback = (): void => {
        if (this.allTablesLoaded() && this.executeCallback) {
            this.forceLoad()
        }


    }

    forceLoad = () => {

        // console.log(`forceload - this.executeCallback: ${this.executeCallback} scheduleWindow:${this.scheduleWindow}`);

        if (this.executeCallback && !this.scheduleWindow) {

            if (this.isMacroZonePage()) {

                if (this.id === null) return;

                this.mcz = this.MCZ[this.id];

                const rmsExtendedArray: RMS_Extended[] = [];

                for (const rms of this.RMS) {

                    const rmsExtended = RMS_Extended.createExtendedRMS(this.RMS, rms, this.GRP, this.ZON, this.MCZ, this.SCH, this.FNC, this.HYS, this.TMR, this.ARS, this.ATU, this.ENR, this.TNK, this.FWA);
                    rmsExtendedArray.push(rmsExtended);
                }

                this.mczExtended = MCZ_Extended.createExtendedMCZ(this.mcz, rmsExtendedArray, this.GRP, this.SCH);

                this.emptySchedules = this.getEmptySchedules()

                this.state = this.mcz.PAR_Mode != MacroZoneMode.Off;
                this.season = this.GRP[this.mcz.CFG_IdxGRP].RTU_SeasonRef;
                this.seasonExe = this.GRP[this.mcz.CFG_IdxGRP].RTU_SeasonExe;
                this.owlState = this.mcz.PAR_OWL != 0;
                this.esState = this.mcz.PAR_ES != 0;

                if (this.MCU.CFG_Code == "2017-187" && this.android) {

                    this.tick = moment();

                    if (!this.triggerTick) this.calculateMyZones();

                    else { //era 5 secondi

                        let t = this.triggerTick.add(40, 'seconds');
                        if (t.isAfter(this.tick)) this.calculateMyZones();
                    }
                } else {

                    this.calculateMyZones()
                }


                //navigation on macrozones
                this.validMCZ = _.sortBy(this.MCZ.filter(mcz => mcz.id == 0 || mcz.CFG_Valid), 'CFG_ViewOrder')

                //console.warn(`la lungheza di validMCZ è ${this.validMCZ}`);

                this.allMacrozoneChanging = this.getAllMacrozoneChanging();

                if (this.validMCZ.length != 1) {

                    for (let i = 0; i < this.validMCZ.length; i++) {

                        if (this.validMCZ[i].id == this.id) {

                            /* this.nextId= mczIdArray[(mczIdArray.indexOf(this.id)+1) % mczIdArray.length];
                             this.prevId= mczIdArray[(mczIdArray.indexOf(this.id)-1) % mczIdArray.length]; */
                            // this.prevId = (i==0)? -1:this.validMCZ[i-1].id
                            // this.nextId = (i==this.validMCZ.length-1)? -1:this.validMCZ[i+1].id
                            break;
                        }
                    }
                } else {

                    this.prevId = this.nextId = -1;
                }

                if (this.season == 0 && this.mcz.PAR_IdxSCH_H != -1) {

                    this.targetSCH = this.SCH[this.mcz.PAR_IdxSCH_H];
                }

                if (this.season == 1 && this.mcz.PAR_IdxSCH_C != -1) {

                    this.targetSCH = this.SCH[this.mcz.PAR_IdxSCH_C];
                }

                this.dataLoaded = true;

                if (!this.firstLoad) {

                    this.loading = false;
                }

                this.firstLoad = true

                // this.showPrevAll = !this.all && this.id == 0 && validMCZ.length > 1
                // console.warn(`forseLoad terminata, this.dataLoaded vale ${this.dataLoaded}`);
                this.manageMargins();

            } else {

                // console.error('NON ESEGUO LA CALLBACK PER BOOLEANI FALSI');
            }
        }

        if (this.useBottomTabsNavigation) {

            // search bar present
            if (this.myZones?.length > 10) {

                document.documentElement.style.setProperty('--bottom-tabs-navigation-search-bar', '115px');
                document.getElementById('ion-tabs').classList.add('ion-tabs-search-bar');

            } else {

                document.documentElement.style.setProperty('--bottom-tabs-navigation-search-bar', '50px');
                document.getElementById('ion-tabs').classList.remove('ion-tabs-search-bar');
            }

        } else {

            document.documentElement.style.setProperty('--bottom-tabs-navigation-search-bar', '0px');
        }
    }

    updateCssVars() {
        //console.log('updateCssVars()');

        setTimeout(() => {


            let offsetSched = 190;
            if (!this.schMode)
                offsetSched = 0;


            if (this.id > 0 || (this.mcz.id == 0 && this.validMCZ.length == 1)) {


                this.dynamicMargin = 330 + offsetSched;
                this.mobileDynamicMargin = 400 + offsetSched;
            } else {
                this.dynamicMargin = 0;
                this.mobileDynamicMargin = 0;
            }


            // console.log(`this.dynamicMargin vale ${this.dynamicMargin}`)

            document.documentElement.style.setProperty('--margin-mcz', this.dynamicMargin.toString() + 'px');
            document.documentElement.style.setProperty('--mobile-margin-mcz', this.mobileDynamicMargin.toString() + 'px');


        }, 2500);


    }


    /**
     * Current macro zone
     */
    // get mcz(): MCZ {
    //     return this.MCZ[this.id];
    // }

    get sch(): SCH {
        try {

            let sch = (this.season == 0) ? this.SCH[this.MCZ[this.id].PAR_IdxSCH_H] : this.SCH[this.MCZ[this.id].PAR_IdxSCH_C];

            if (!sch) {
                this.schLoaded = false;
            } else {
                this.schLoaded = true; //mi serve??
            }

            return sch;
            //  this.currentSch;
        } catch (ex) {
            return null;
        }
    }

    /**
     * The list of fancoils of this macrozone
     */
    public get myFancoils(): FNC[] {
        return this.supportService.getFNCbyMCZ(this.FNC, this.RMS, this.mcz);

    }


    calculateMyZones() {

        this.myZones = this.getMyZones()

        this.noRadiant = new Array(this.myZones.length);

        for (let i = 0; i < this.myZones.length; i++) {

            const rms = this.myZones[i]
            const zones = this.rmsZones(rms)
            const fancoils = this.fncZones(rms)

            this.noRadiant[i] = this.checkNoRadiant(zones, fancoils)
        }

        // calculate the exceptions
        this.calculateRoomsWithExceptions();
        this.triggerTick = moment()
    }

    /**
     * The list of zones of this macrozone
     */
    getMyZones(): RMS_Extended[] {

        let myZones: RMS[];

        if (this.mcz.id == 0) {

            myZones = this.RMS;

        } else {

            myZones = this.RMS.filter(zone => zone.CFG_IdxMCZ == this.id);
        }

        this.showOWL = _.find(myZones, rms => rms.CFG_CfgDiOwl != 0) != null;
        const myZonesSorted = _.sortBy(myZones, 'CFG_ViewOrder');

        const myZonesSortedExtended: RMS_Extended[] = [];

        for (const rms of myZonesSorted) {

            myZonesSortedExtended.push(RMS_Extended.createExtendedRMS(this.RMS, this.RMS[rms.id], this.GRP, this.ZON, this.MCZ, this.SCH, this.FNC, this.HYS, this.TMR, this.ARS, this.ATU, this.ENR, this.TNK, this.FWA));
        }

        return myZonesSortedExtended;
    }

    getEmptySchedules(): boolean {

        return this.SCH.filter(sch => isApplicableTo(sch, "MCZ", "PAR_IdxSCH_H", this.season, this.isAutoModeStyleDualSetpoint())).length == 0;
    }

    getAllMacrozoneChanging(): boolean {
        if (!this.mcz) return false
        if (!this.validMCZ) return false
        if (this.mcz.id != 0) return true
        return this.mcz.id == 0 && this.validMCZ.length == 1
    }

    /**
     * Switch ON/OFF
     */
    stateUpdated = ($event): void => {
        this.stopLoadCallback();
        this.state = $event.detail.checked;

        // data not yet loaded;
        if (!this.dataLoaded) {

            return;
        }

        this.mcz.PAR_Mode = this.state ? MacroZoneMode.On : MacroZoneMode.Off;

        const requests: ChangeValuesRequest[] = [];

        requests.push({
            table: 'MCZ',
            id: this.id,
            values: {PAR_Mode: this.mcz.PAR_Mode}
        });

        let state: ZoneState = this.mcz.PAR_Mode == MacroZoneMode.On ? ZoneState.On : ZoneState.Off;

        for (const rms of this.myZones) {

            requests.push({
                table: 'RMS',
                id: rms.id,
                values: {PAR_State: state}
            });
        }

        // Reset every override when a macro-zone is turned off
        if (this.mcz.PAR_Mode === MacroZoneMode.Off) {

            this.resetOverride('PAR_SpoPeriod', 'RTU_SpoRemain');
            this.resetOverride('PAR_SpoPeriodH', 'RTU_SpoRemainH');
            this.resetOverride('PAR_SpoPeriodC', 'RTU_SpoRemainC');
        }

        this.store.dispatch(new ChangeTableMultipleItems(requests));

        timer(700).subscribe(() => {

            this.startLoadCallback();
        });
    }

    onChangeDualSetpoints = async (table: string, id: number, property: string, value: any, mcz: MCZ) => {

        const hasChangedOtherSetpoint = this.supportService.onChangeDualSetpoints(this.MCU, table, id, property, value, mcz, this.isAutoModeStyleDualSetpoint());

        if (hasChangedOtherSetpoint) {

            await this.onTemperatureChange(null, false);
        }
    }

    /**
     * Temperature changed
     */
    async onTemperatureChange(staticSeason: number, showPopup = true) {

        this.calculateMyZones();

        this.exceptionWindow = true;

        // data not yet loaded
        if (!this.dataLoaded) {

            return;
        }

        let exceptionsPresent = true;

        if (this.isAutoModeStyleDualSetpoint()) {

            if (this.temperatureExceptions_H.length === 0 && this.temperatureExceptions_C.length === 0) {

                exceptionsPresent = false;
                this.updateRoomsTemperature(true, 0);
                this.updateRoomsTemperature(true, 1);
            }
        } else {

            if (this.temperatureExceptions_H.length == 0 && this.season == Season.Heating) {

                exceptionsPresent = false;
                this.updateRoomsTemperature(true, staticSeason);

            } else if (this.temperatureExceptions_C.length == 0 && this.season == Season.Cooling) {

                exceptionsPresent = false;
                this.updateRoomsTemperature(true, staticSeason);
            }
        }

        if (exceptionsPresent && showPopup) {

            let popup = await this.alertController.create({
                cssClass: 'exception',
                header: this.translate.instant('EXCEPTION_TITLE'),
                message: this.translate.instant('EXCEPTION_MESSAGE'),
                buttons: [
                    {
                        text: this.translate.instant('EXCEPTION_KEEP'),
                        handler: () => {

                            if (this.isAutoModeStyleDualSetpoint()) {

                                // Update only the rooms without exceptions (both H & C)
                                this.updateRoomsTemperature(false, 0);
                                this.updateRoomsTemperature(false, 1);
                                this.forceUpdateRoomsTemperature(staticSeason);

                            } else {

                                // Update only the rooms without exceptions
                                this.updateRoomsTemperature(false, staticSeason);
                            }
                        }
                    },
                    {
                        text: this.translate.instant('EXCEPTION_OVERRIDE'),
                        handler: () => {

                            const navTransition = popup.dismiss();

                            navTransition.then(() => {

                                if (this.isAutoModeStyleDualSetpoint()) {

                                    // Override temperature (delete all the temperature exceptions -> both H & C)
                                    this.updateRoomsTemperature(true, 0);
                                    this.updateRoomsTemperature(true, 1);

                                } else {

                                    // Override temperature (delete all the temperature exceptions)
                                    this.updateRoomsTemperature(true, staticSeason);
                                }

                            });
                        }
                    }
                ]
            });

            await popup.present();
        }

        this.realExceptionsH = false;
        this.realExceptionsC = false;
    }

    // This will check and eventually force change temperature exceptions, even when pressing "keep" in the popup, if a zone doesn't respect the 2° difference after a macro-zone dual setpoints change.
    forceUpdateRoomsTemperature(mode: DUAL_MODE) {

        // take every rms that has an exception.
        const exceptions = _.union(this.temperatureExceptions_H, this.temperatureExceptions_C);
        const rmsArrayWithExceptions = _.filter(this.RMS, rms => exceptions.includes(rms.id));

        for (const rms of rmsArrayWithExceptions) {

            const minDifference = this.MCU.PAR_UM === 0 ? 2 : 1; // fahrenheit or celsius

            if (mode === DUAL_MODE.heating) {

                if (rms.PAR_SetTempH > rms.PAR_SetTempC - minDifference) {

                    const values = {PAR_SetTempC: rms.PAR_SetTempH + minDifference};
                    this.store.dispatch(new ChangeTableValues('RMS', rms.id, values));
                }
            }

            if (mode === DUAL_MODE.cooling) {

                if (rms.PAR_SetTempC < rms.PAR_SetTempH + minDifference) {

                    const values = {PAR_SetTempH: rms.PAR_SetTempC - minDifference};
                    this.store.dispatch(new ChangeTableValues('RMS', rms.id, values));
                }
            }
        }
    }

    /**
     * Update the temperature of the
     * rooms without (temperature) exceptions
     */
    updateRoomsTemperature = (all: boolean, staticSeason: number): void => {

        let requests: ChangeValuesRequest[] = [];

        if (this.MCU.TEC_SPMode) {

            this.mcz.PAR_SetTempC = this.mcz.PAR_SetTempH;

            requests.push({table: 'MCZ', id: this.id, values: {PAR_SetTempH: this.mcz.PAR_SetTempH, PAR_SetTempC: this.mcz.PAR_SetTempC}});

        } else {

            if ((!this.isAutoModeStyleDualSetpoint() && this.season == Season.Heating) || (this.isAutoModeStyleDualSetpoint() && staticSeason == 0)) {

                requests.push({table: 'MCZ', id: this.id, values: {PAR_SetTempH: this.mcz.PAR_SetTempH}});

            } else if ((!this.isAutoModeStyleDualSetpoint() && this.season == Season.Cooling) || (this.isAutoModeStyleDualSetpoint() && staticSeason == 1)) {

                requests.push({table: 'MCZ', id: this.id, values: {PAR_SetTempC: this.mcz.PAR_SetTempC}});
            }
        }

        let roomsWithoutExceptions: RMS[] = [];
        let roomsWithoutExceptions_H: RMS[] = [];
        let roomsWithoutExceptions_C: RMS[] = [];

        if (!this.isAutoModeStyleDualSetpoint()) {

            roomsWithoutExceptions = all ? this.myZones : (this.season == Season.Heating ? this.withoutTemperatureExceptions_H : this.withoutTemperatureExceptions_C);
        }

        if (this.isAutoModeStyleDualSetpoint()) {

            roomsWithoutExceptions_H = all ? this.myZones : this.withoutTemperatureExceptions_H;
            roomsWithoutExceptions_C = all ? this.myZones : this.withoutTemperatureExceptions_C;
        }

        /****************************************************************
         ********  modalità ordinaria !this.isAutoModeStyleDualSetpoint()
         ****************************************************************/
        if (!this.isAutoModeStyleDualSetpoint()) {

            for (let rms of roomsWithoutExceptions) {

                if (this.MCU.TEC_SPMode) {

                    this.mcz.PAR_SetTempC = this.mcz.PAR_SetTempH;

                    requests.push({table: 'RMS', id: rms.id, values: {PAR_SetTempH: this.mcz.PAR_SetTempH, PAR_SetTempC: this.mcz.PAR_SetTempC}});

                } else {

                    if (this.season == Season.Heating) {

                        rms.PAR_SetTempH = this.mcz.PAR_SetTempH;

                        requests.push({table: 'RMS', id: rms.id, values: {PAR_SetTempH: this.mcz.PAR_SetTempH}});

                    } else if (this.season == Season.Cooling) {

                        rms.PAR_SetTempC = this.mcz.PAR_SetTempC;

                        requests.push({table: 'RMS', id: rms.id, values: {PAR_SetTempC: this.mcz.PAR_SetTempC}});
                    }
                }
            }
        }

        /****************************************************************
         ********  modalità dual setpoint this.isAutoModeStyleDualSetpoint()
         ****************************************************************/

        if (this.isAutoModeStyleDualSetpoint()) {

            if (staticSeason == Season.Heating) {

                for (let rms of roomsWithoutExceptions_H) {

                    rms.PAR_SetTempH = this.mcz.PAR_SetTempH;
                    requests.push({table: 'RMS', id: rms.id, values: {PAR_SetTempH: this.mcz.PAR_SetTempH}});
                }
            }

            if (staticSeason == Season.Cooling) {

                for (let rms of roomsWithoutExceptions_C) {

                    rms.PAR_SetTempC = this.mcz.PAR_SetTempC;
                    requests.push({table: 'RMS', id: rms.id, values: {PAR_SetTempC: this.mcz.PAR_SetTempC}});
                }
            }
        }

        this.store.dispatch(new ChangeTableMultipleItems(requests));
        this.exceptionWindow = false;
    }

    /**
     * Update the temperature of the
     * rooms without (temperature) exceptions
     */
    updateRoomsSchedulation = (table: string, id: number, values: any, all: boolean, onlyState: boolean): void => {

        this.startLoadCallback();

        // update the macrozone - no? the MCZ has already been updated
        for (const prop in values) {

            this.mcz[prop] = values[prop];
        }

        let requests: ChangeValuesRequest[] = [];

        if (this.MCU.TEC_SPMode || this.isAutoModeStyleDualSetpoint()) {

            if (!onlyState) {

                this.mcz.PAR_IdxSCH_H = values.PAR_IdxSCH_H !== undefined ? values.PAR_IdxSCH_H : this.mcz.PAR_IdxSCH_H;
                this.mcz.PAR_IdxSCH_C = this.mcz.PAR_IdxSCH_H;
            }

            this.mcz.PAR_SchedOnH = values.PAR_SchedOnH !== undefined ? values.PAR_SchedOnH : this.mcz.PAR_SchedOnH;
            this.mcz.PAR_SchedOnC = this.mcz.PAR_SchedOnH;

            // just in case Iìm receiving cooling values while in AutoModeStyleDualSetpoint
            if (this.isAutoModeStyleDualSetpoint()) {

                if (!onlyState && values.PAR_IdxSCH_C !== undefined) {

                    this.mcz.PAR_IdxSCH_C = values.PAR_IdxSCH_C;
                    this.mcz.PAR_IdxSCH_H = this.mcz.PAR_IdxSCH_C;
                }

                if (values.PAR_SchedOnC !== undefined) {

                    this.mcz.PAR_SchedOnC = values.PAR_SchedOnC;
                    this.mcz.PAR_SchedOnH = this.mcz.PAR_SchedOnC;
                }
            }

            // Updating: MCZ (fixes the bug that if
            this.store.dispatch(new ChangeTableValues("MCZ", this.mcz.id, {PAR_SchedOnH: this.mcz.PAR_SchedOnH, PAR_SchedOnC: this.mcz.PAR_SchedOnC}));

        } else if (this.season == Season.Heating) {

            if (!onlyState) {

                this.mcz.PAR_IdxSCH_H = values.PAR_IdxSCH_H !== undefined ? values.PAR_IdxSCH_H : this.mcz.PAR_IdxSCH_H;
            }

            this.mcz.PAR_SchedOnH = values.PAR_SchedOnH !== undefined ? values.PAR_SchedOnH : this.mcz.PAR_SchedOnH;

        } else {

            if (!onlyState) {

                this.mcz.PAR_IdxSCH_C = values.PAR_IdxSCH_C !== undefined ? values.PAR_IdxSCH_C : this.mcz.PAR_IdxSCH_C;
            }

            this.mcz.PAR_SchedOnC = values.PAR_SchedOnC !== undefined ? values.PAR_SchedOnC : this.mcz.PAR_SchedOnC;
        }

        // update the zones
        const roomsWithoutExceptions: RMS[] = all ? this.myZones : this.myZones.filter(zone => !_.includes(this.schedulationExceptions, zone.id));

        for (const rms of roomsWithoutExceptions) {

            const zones = this.rmsZones(rms);
            const fncs = this.fncZones(rms);

            if (!onlyState) {

                if (!this.checkNoRadiant(zones, fncs)) {

                    if (this.MCU.TEC_SPMode || this.isAutoModeStyleDualSetpoint()) {

                        rms.PAR_IdxSCH_H = this.mcz.PAR_IdxSCH_H;
                        rms.PAR_SchedOnH = this.mcz.PAR_SchedOnH;
                        rms.PAR_IdxSCH_C = this.mcz.PAR_IdxSCH_C;
                        rms.PAR_SchedOnC = this.mcz.PAR_SchedOnC;

                        requests.push({
                            table: 'RMS', id: rms.id, values: {
                                PAR_IdxSCH_H: rms.PAR_IdxSCH_H,
                                PAR_SchedOnH: rms.PAR_SchedOnH,
                                PAR_IdxSCH_C: rms.PAR_IdxSCH_C,
                                PAR_SchedOnC: rms.PAR_SchedOnC
                            }
                        });
                    } else {

                        if (this.season == Season.Heating) {

                            rms.PAR_IdxSCH_H = this.mcz.PAR_IdxSCH_H;
                            rms.PAR_SchedOnH = this.mcz.PAR_SchedOnH;
                            rms.PAR_Mode = rms.PAR_IdxSCH_H == -1 ? ZoneMode.AlwaysOn : ZoneMode.Schedulation;

                            requests.push({
                                table: 'RMS', id: rms.id,
                                values: {
                                    PAR_IdxSCH_H: rms.PAR_IdxSCH_H,
                                    PAR_SchedOnH: rms.PAR_SchedOnH,
                                    PAR_Mode: rms.PAR_Mode
                                }
                            });
                        } else {

                            rms.PAR_IdxSCH_C = this.mcz.PAR_IdxSCH_C;
                            rms.PAR_SchedOnC = this.mcz.PAR_SchedOnC;

                            rms.PAR_Mode = rms.PAR_IdxSCH_C == -1 ? ZoneMode.AlwaysOn : ZoneMode.Schedulation;

                            requests.push({
                                table: 'RMS', id: rms.id,
                                values: {
                                    PAR_IdxSCH_C: rms.PAR_IdxSCH_C,
                                    PAR_SchedOnC: rms.PAR_SchedOnC,
                                    PAR_Mode: rms.PAR_Mode
                                }
                            });
                        }
                    }

                } else {

                    requests.push({
                        table: 'RMS', id: rms.id,
                        values: {
                            PAR_IdxSCH_H: -1,
                            PAR_SchedOnH: false,
                            PAR_IdxSCH_C: -1,
                            PAR_SchedOnC: false
                        }
                    });
                }
            }

            // onlyState
            else {

                if (!this.checkNoRadiant(zones, fncs)) {

                    if ((this.MCU.TEC_SPMode || this.isAutoModeStyleDualSetpoint()) && rms.PAR_IdxSCH_H != -1 && rms.PAR_IdxSCH_C != -1) {

                        rms.PAR_SchedOnH = this.mcz.PAR_SchedOnH;
                        rms.PAR_SchedOnC = this.mcz.PAR_SchedOnC;

                        requests.push({
                            table: 'RMS', id: rms.id,
                            values: {

                                PAR_SchedOnH: rms.PAR_SchedOnH,
                                PAR_SchedOnC: rms.PAR_SchedOnC
                            }
                        });
                    } else {

                        if (this.season == Season.Heating && rms.PAR_IdxSCH_H != -1) {

                            rms.PAR_SchedOnH = this.mcz.PAR_SchedOnH;
                            rms.PAR_Mode = rms.PAR_IdxSCH_H == -1 ? ZoneMode.AlwaysOn : ZoneMode.Schedulation;

                            requests.push({
                                table: 'RMS', id: rms.id,
                                values: {
                                    PAR_SchedOnH: rms.PAR_SchedOnH,
                                    PAR_Mode: rms.PAR_Mode
                                }
                            });
                        } else if (rms.PAR_IdxSCH_C != -1) {

                            rms.PAR_SchedOnC = this.mcz.PAR_SchedOnC;
                            rms.PAR_Mode = rms.PAR_IdxSCH_C == -1 ? ZoneMode.AlwaysOn : ZoneMode.Schedulation;

                            requests.push({
                                table: 'RMS', id: rms.id,
                                values: {

                                    PAR_SchedOnC: rms.PAR_SchedOnC,
                                    PAR_Mode: rms.PAR_Mode
                                }
                            });
                        }
                    }
                } else {

                    requests.push({
                        table: 'RMS', id: rms.id,
                        values: {
                            PAR_IdxSCH_H: -1,
                            PAR_SchedOnH: false,
                            PAR_IdxSCH_C: -1,
                            PAR_SchedOnC: false
                        }
                    });
                }
            }
        }

        this.store.dispatch(new ChangeTableMultipleItems(requests));
    }

    startLoadCallback() {

        timer(1000).subscribe(() => {
            this.executeCallback = true;
            this.loadCallback();
            // console.warn(`startLoadCallback loadCB:${this.executeCallback}`);
        })

    }

    stopLoadCallback() {
        this.executeCallback = false;
        // console.warn(`stopLoadCallback loadCB:${this.executeCallback}`);
    }

    /**
     * Schedule changed
     */

    // table: string, id: number, values: any, newMCZ: boolean
    async onScheduleChange(event) {

        if (this.scheduleWindow || this.changingPage) {

            return;
        }

        setTimeout(async () => {

            const table = event.table;
            const id = event.id;
            const values = event.values;
            const newMCZ = event.newMCZ;
            const onlyState = event.onlyState;

            if (onlyState) {

                this.updateRoomsSchedulation(table, id, values, true, onlyState);
                return;
            }

            if (!this.dataLoaded) { // data not yet loaded

                return;
            }

            if (this.schedulationExceptions.length == 0) { // no room with schedulation exception

                this.updateRoomsSchedulation(table, id, values, true, onlyState);
                return;
            }

            // Update only the rooms without exceptions
            if (newMCZ) {

                this.schedulationExceptions = [];
                this.updateRoomsSchedulation(table, id, values, false, onlyState);

                const popup = await this.alertController.create({
                    cssClass: 'exception',
                    header: this.translate.instant('EXCEPTION_TITLE'),
                    message: this.translate.instant('EXCEPTION_MESSAGE'),
                    buttons: [
                        {
                            text: this.translate.instant('EXCEPTION_KEEP'),
                            handler: () => {
                            }
                        },
                        {
                            text: this.translate.instant('EXCEPTION_OVERRIDE'),
                            handler: () => {
                                let navTransition = popup.dismiss();
                                navTransition.then(() => {

                                    // Override schedulations (delete all the schedulations exceptions)
                                    this.schedulationExceptions = [];
                                    this.updateRoomsSchedulation(table, id, values, true, onlyState);
                                })
                            }
                        }
                    ]
                });

                await popup.present();
            } else {

                const popup = await this.alertController.create({
                    cssClass: 'exception',
                    header: this.translate.instant('EXCEPTION_TITLE'),
                    message: this.translate.instant('EXCEPTION_MESSAGE'),
                    buttons: [
                        {
                            text: this.translate.instant('EXCEPTION_KEEP'),
                            handler: () => {

                                // Update only the rooms without exceptions
                                this.updateRoomsSchedulation(table, id, values, false, onlyState);
                            }
                        },
                        {
                            text: this.translate.instant('EXCEPTION_OVERRIDE'),
                            handler: () => {
                                let navTransition = popup.dismiss();
                                navTransition.then(() => {

                                    // Override schedulations (delete all the schedulations exceptions)
                                    this.schedulationExceptions = [];
                                    this.updateRoomsSchedulation(table, id, values, true, onlyState);
                                })
                            }
                        }
                    ]
                });

                await popup.present();
            }
        }, 2);
    }

    /**
     * Show a single zone
     */
    showZone(rms: RMS) {

        if (!this.timeoutHandle) {

            this.executeCallback = false;
            this.router.navigate(['/zone'], {queryParams: {id: rms.id, mczId: this.id !== 0 ? this.id : null}});
        }
    }

    cleanObject() {
        this.MCU = undefined
        this.HYS = undefined
        this.GRP = undefined
        this.RMS = undefined
        this.MCZ = undefined
        this.ZON = undefined
        this.FNC = undefined
    }

    /**
     * Calculate the rooms with exceptions
     */
    calculateRoomsWithExceptions(): void {

        if (!this.exceptionWindow) {

            // Schedulation exceptions
            const schedulationExceptions: number[] = [];
            const var_ = (this.season == Season.Heating) ? 'PAR_IdxSCH_H' : 'PAR_IdxSCH_C';
            const var_on_off = (this.season == Season.Heating) ? 'PAR_SchedOnH' : 'PAR_SchedOnC';
            let r = 0;

            for (let rms of this.myZones) {

                if (!this.noRadiant[r]) {

                    if (rms[var_] != this.mcz[var_] || rms[var_on_off] != this.mcz[var_on_off]) {

                        schedulationExceptions.push(rms.id);
                    }
                }

                r++
            }

            this.schedulationExceptions = [...schedulationExceptions];
        }
    }

    checkNoRadiant(zones: ZON[], fncs: FNC[]): boolean {

        return this.supportService.noRadiant(null, zones, fncs, this.HYS, this.GRP, this.mcz);
    }


    isMacroZonePage() {

        let urlArray = this.router.url.split('?');
        //console.log(urlArray);
        return urlArray[0] === '/macro-zone';

    }

    rmsZones(rms: RMS): ZON[] {
        return _.filter(this.ZON, x => x.CFG_IdxRMS == rms.id);
    }

    mczZones(): ZON[] {

        let zonList: ZON[] = [];

        for (let rms of this.myZones) {

            zonList = [...zonList, ...this.rmsZones(rms)];
        }

        return zonList;
    }

    fncZones(rms: RMS): FNC[] {

        return _.filter(this.FNC, x => x.CFG_IdxRMS.indexOf(rms.id) != -1);

    }

    /**
     * Popup for selecting the zones of the macro zone
     */
    async assignZones($event?) {
        // console.warn("assignZones popup click");
        if ($event) {
            $event.stopPropagation();
        }
        if (this.myZones.length == 0) {
            this.forceDefaultDate = true
        }
        let inputs = [];
        var orderedRMS = _.sortBy(this.RMS, 'CFG_ViewOrder')
        for (let rms of orderedRMS) {
            if (rms.CFG_IdxGRP == this.MCZ[this.id].CFG_IdxGRP) {
                inputs.push({
                    type: 'checkbox',
                    label: rms.CFG_Name,
                    value: rms.id,
                    checked: rms.CFG_IdxMCZ == this.id,
                    disabled: !!rms.CFG_IdxMCZ && rms.CFG_IdxMCZ != this.id
                });
            }
        }

        let component = this;
        let popup = await this.alertController.create({
            header: this.translate.instant('ASSIGN_ZONE'),
            message: this.translate.instant('ASSIGN_ZONE_TEXT'),
            inputs: inputs,
            buttons: [

                {
                    text: this.translate.instant('SAVE'),
                    handler: selectedRoomsIds => {
                        let requests: ChangeValuesRequest[] = [];
                        // Select the added rooms
                        for (let id of selectedRoomsIds) {
                            requests.push({
                                table: 'RMS',
                                id: id,
                                values: {CFG_IdxMCZ: component.id}
                            });
                        }
                        // Remove the deselected rooms
                        for (let rms of component.RMS) {
                            if (rms.CFG_IdxMCZ == component.id && selectedRoomsIds.indexOf(rms.id) == -1) {
                                requests.push({
                                    table: 'RMS',
                                    id: rms.id,
                                    values: {CFG_IdxMCZ: 0}
                                });
                            }
                        }
                        //component.store.dispatch(TablesActions.changeMultipleItems(requests));
                        component.store.dispatch(new ChangeTableMultipleItems(requests));

                        setTimeout(() => {
                            if (this.forceDefaultDate) {
                                this.setDefaultDataFromZones()
                                this.forceDefaultDate = false
                            }
                        }, 500)
                    }
                },
                {
                    text: this.translate.instant('CANCEL'),
                }
            ]
        });
        await popup.present();
        // console.log("chiamata  await popup.present();");
    }

    setDefaultDataFromZones() {
        // console.log(this.myZones)

        var numOfTrueES = 0, numOfFalseES = 0
        var numOfSchedOnHTrue = 0, numOfSchedOnHFalse = 0
        var numOfSchedOnCTrue = 0, numOfSchedOnCFalse = 0
        var tempsC = [], tempsH = []
        var schedsC = [], schedsH = []

        var r = 0;
        for (var rms of this.myZones) {

            var zones = this.rmsZones(rms)
            var fncs = this.fncZones(rms)

            if (this.checkNoRadiant(zones, fncs)) continue

            if (rms.PAR_ES) numOfTrueES++
            else numOfFalseES++

            if (rms.PAR_SchedOnC) numOfSchedOnCTrue++
            else numOfSchedOnCFalse++

            if (rms.PAR_SchedOnH) numOfSchedOnHTrue++
            else numOfSchedOnHFalse++

            tempsC['' + rms.PAR_SetTempC + ''] = (tempsC['' + rms.PAR_SetTempC + '']) ? tempsC['' + rms.PAR_SetTempC + ''] + 1 : 1
            tempsH['' + rms.PAR_SetTempH + ''] = (tempsH['' + rms.PAR_SetTempH + '']) ? tempsH['' + rms.PAR_SetTempH + ''] + 1 : 1
            schedsC['' + rms.PAR_IdxSCH_C + ''] = (schedsC['' + rms.PAR_IdxSCH_C + '']) ? schedsC['' + rms.PAR_IdxSCH_C + ''] + 1 : 1
            schedsH['' + rms.PAR_IdxSCH_H + ''] = (schedsH['' + rms.PAR_IdxSCH_H + '']) ? schedsH['' + rms.PAR_IdxSCH_H + ''] + 1 : 1
        }

        if (numOfTrueES > numOfFalseES) this.mcz.PAR_ES = 1
        else this.mcz.PAR_ES = 0

        if (numOfSchedOnCTrue > numOfSchedOnCFalse) this.mcz.PAR_SchedOnC = true
        else this.mcz.PAR_SchedOnC = false

        if (numOfSchedOnHTrue > numOfSchedOnHFalse) this.mcz.PAR_SchedOnH = true
        else this.mcz.PAR_SchedOnH = false

        var maxTempsC, maxTempsH, maxSchedsC, maxSchedsH
        if (Object.keys(tempsC).length > 1)
            maxTempsC = Object.keys(tempsC).reduce((a, b) => tempsC[a] > tempsC[b] ? a : b);
        else
            maxTempsC = Object.keys(tempsC)[0]

        if (Object.keys(tempsC).length > 1)
            maxTempsH = Object.keys(tempsH).reduce((a, b) => tempsH[a] > tempsH[b] ? a : b);
        else
            maxTempsH = Object.keys(tempsH)[0]

        if (Object.keys(tempsC).length > 1)
            maxSchedsC = Object.keys(schedsC).reduce((a, b) => schedsC[a] > schedsC[b] ? a : b);
        else
            maxSchedsC = Object.keys(schedsC)[0]

        if (Object.keys(tempsC).length > 1)
            maxSchedsH = Object.keys(schedsH).reduce((a, b) => schedsH[a] > schedsH[b] ? a : b);
        else
            maxSchedsH = Object.keys(schedsH)[0]

        this.mcz.PAR_IdxSCH_H = parseFloat(maxSchedsH)
        this.mcz.PAR_IdxSCH_C = parseFloat(maxSchedsC)
        this.mcz.PAR_SetTempC = parseInt(maxTempsC)
        this.mcz.PAR_SetTempH = parseInt(maxTempsH)
        //console.log(this.mcz.RTU_Flags)

        this.store.dispatch(new ChangeTableValues("MCZ", this.mcz.id, this.mcz))
        // this.store.dispatch(TablesActions.changeValues("MCZ",this.mcz.id , this.mcz))
        r++;
    }

    //
    /**
     * Confirm for deleting the macro zone
     */
    deleteMacroZone($event?) {
        this.confirmPopup($event, 'DELETE', 'DELETE_MACRO_ZONE', 'DELETE', () => {
            let requests: ChangeValuesRequest[] = [];
            // Remove the rooms
            for (let rms of this.RMS) {
                if (rms.CFG_IdxMCZ == this.id) {
                    requests.push({
                        table: 'RMS',
                        id: rms.id,
                        values: {CFG_IdxMCZ: 0}
                    });
                }
            }
            // Remove the macro zone (mark as not valid)
            requests.push({table: 'MCZ', id: this.id, values: {CFG_Valid: false}});

            this.store.dispatch(new ChangeTableMultipleItems(requests));
            this.location.back();

        })
    };


    /**
     * Show menu
     */
    openMenu = ($event?): void => {

        if ($event) {

            $event.stopPropagation();
        }

        let options: any[];

        // ------------------------------------------------------------------------------------------------------
        // --- Is override setpoint available? (A macro-zone must have at least 1 radiant zone) -----------------
        // ------------------------------------------------------------------------------------------------------
        // I won't show the "override setpoint" button if I have 0 radiant zones --------------------------------
        // ------------------------------------------------------------------------------------------------------

        let overrideSetpointIsAvailable = false;
        const rmsArrayOfMcz = this.getMyZones();

        for (const rms of rmsArrayOfMcz) {

            const zones = this.rmsZones(rms)
            const fncs = this.fncZones(rms)

            if (!this.checkNoRadiant(zones, fncs)) {

                overrideSetpointIsAvailable = true;
                break;
            }
        }

        // ------------------------------------------------------------------------------------------------------

        if (this.mcz.id == 0) {

            options = [
                {
                    text: 'CHANGE_MACROZONES_SETPOINTS',

                    handler: () => this.router.navigate(['/macro-zone-setpoints', this.mcz.id])
                }
            ];

        } else {

            options = [
                {
                    text: 'ASSIGN_ZONE',
                    handler: this.assignZones.bind(this)
                },
                {
                    text: 'RENAME_MACRO_ZONE',
                    handler: () => this.rename('MCZ', this.id, 'RENAME', 'RENAME_MACRO_ZONE_TEXT',
                        this.MCZ.filter(mcz => mcz.id == 0 || mcz.CFG_Valid))
                },
                {
                    text: 'DELETE_MACRO_ZONE',
                    role: 'destructive',
                    handler: this.deleteMacroZone.bind(this)
                },
                {
                    text: 'CHANGE_MACROZONES_SETPOINTS',

                    handler: () => this.router.navigate(['/macro-zone-setpoints', this.mcz.id])
                },
            ];
        }

        // ------------------------------------------------------------------------------------------------------
        // --- Override Setpoint setup (normal or double for dual setpoint) -------------------------------------
        // ------------------------------------------------------------------------------------------------------

        // I don't show the "override setpoint" option on an "All" mcz if I have other macro-zones.
        const overrideIsDisabled = !overrideSetpointIsAvailable || (this.mcz.id === 0 && this.validMCZ?.length > 1)

        if (this.isAutoModeStyleDualSetpoint()) {

            const heatingIsInOverride = this.mczExtended.getSetpointOverrideInfo(null, DUAL_MODE.heating).isOverride;
            const coolingIsInOverride = this.mczExtended.getSetpointOverrideInfo(null, DUAL_MODE.cooling).isOverride;

            options.push({text: 'OVERRIDE_SETPOINT_HEATING', disabled: overrideIsDisabled, handler: () => this.overrideSetpoint(DUAL_MODE.heating, coolingIsInOverride)});
            options.push({text: 'OVERRIDE_SETPOINT_COOLING', disabled: overrideIsDisabled, handler: () => this.overrideSetpoint(DUAL_MODE.cooling, heatingIsInOverride)});
        } else {

            options.push({text: 'OVERRIDE_SETPOINT', disabled: overrideIsDisabled, handler: () => this.overrideSetpoint()});
        }

        // ------------------------------------------------------------------------------------------------------

        this.showMenu(this.MCZ[this.id].CFG_Name, options)
    }

    /**
     * Swipe event
     */
    swipe = ($event): void => {
        if (this.timeoutHandle !== null) {
            this.window.clearTimeout(this.timeoutHandle);
        }
        this.timeoutHandle = this.window.setTimeout(() => this.timeoutHandle = null, 500);
    }

    /**
     * Switch OWL on/off
     */
    owlStateUpdated(): void {

        if (!this.dataLoaded) { // data not yet loaded
            return;
        }
        this.mcz.PAR_OWL = this.owlState ? 1 : 0;
        this.store.dispatch(new ChangeTableValues('MCZ', this.id, {PAR_OWL: this.mcz.PAR_OWL}));
        //this.store.dispatch(TablesActions.changeValues('MCZ', this.id, { PAR_OWL: this.mcz.PAR_OWL }));
    }

    /**
     * Switch ES on/off
     */
    esStateUpdated(): void {
        if (!this.dataLoaded) { // data not yet loaded
            return;
        }
        this.mcz.PAR_ES = this.esState ? 1 : 0;
        this.store.dispatch(new ChangeTableValues('MCZ', this.mcz.id, {PAR_ES: this.mcz.PAR_ES}));
        //this.store.dispatch(TablesActions.changeValues('MCZ', this.mcz.id, { PAR_ES: this.mcz.PAR_ES}));
        for (let rms of this.myZones) {
            this.store.dispatch(new ChangeTableValues('RMS', rms.id, {PAR_ES: this.mcz.PAR_ES}));
            // this.store.dispatch(TablesActions.changeValues('RMS', rms.id, { PAR_ES: this.mcz.PAR_ES}));
        }
    }

    /**
     * Show prev mcz
     */
    public prev(): void {
        // console.log('prev: '+ this.prevId)
        this.changingPage = true;
        this.schMode = false;


        let mczIdArray = _.map(this.validMCZ, 'id') as number[];
        // console.log(`this.id vale ${this.id} mczidArrayLen vale ${mczIdArray.length} indexof(this.idx) vale ${mczIdArray.indexOf(Number(this.id))} `)
        this.prevId = mczIdArray[(mczIdArray.indexOf(Number(this.id)) + mczIdArray.length - 1) % mczIdArray.length];

        /* console.log(`(mczIdArray.indexOf(Number(this.id))-1) % mczIdArray.length vale ${(mczIdArray.indexOf(Number(this.id))-1) % mczIdArray.length}`)

         console.error('prev cambiato: '+ this.prevId) */
        this.id = this.prevId;
        // this.prevId = -1
        this.location.replaceState(
            this.router.createUrlTree(
                ['/macro-zone'],
                {queryParams: {'id': this.id}}
            ).toString()
        );
        this.executeCallback = true;
        this.forceLoad();
        this.executeCallback = false;
        timer(400).subscribe(() => {
            this.changingPage = false;
            // console.log('prev finito ' )
        })
    }

    /**
     * Show next mcz
     */
    public next(): void {
        // console.log('next: ' + this.nextId )
        this.changingPage = true;
        this.schMode = false;

        let mczIdArray = _.map(this.validMCZ, 'id') as number[];
        /*console.log(`this.id vale ${this.id} mczidArrayLen vale ${mczIdArray.length} indexof(this.idx) vale ${mczIdArray.indexOf(this.id)} `)
        console.log('mczIdArray vale')
        console.log(mczIdArray);*/

        this.nextId = mczIdArray[(mczIdArray.indexOf(Number(this.id)) + 1) % mczIdArray.length];

        this.id = this.nextId;
        // this.nextId = -1
        this.location.replaceState(
            this.router.createUrlTree(
                ['/macro-zone'],
                {queryParams: {'id': this.id}}
            ).toString()
        );
        this.executeCallback = true;
        this.forceLoad();
        this.executeCallback = false;

        timer(400).subscribe(() => {
            this.changingPage = false;
            // console.log('next finito ' )
        })

    }


    existSCH(mcz: MCZ, season) {
        if (season == 0) {
            if (mcz.PAR_IdxSCH_H == -1) return false
        } else {
            if (mcz.PAR_IdxSCH_C == -1) return false
        }
        return true

    }

    showHidePreview = (): void => {
        //console.log('showHidePreview')
        // console.log(this.targetSCH)
    }

    showPowerOff() {

        return new ShowPowerOff(this.MCU.PAR_On);
    }

    trackById(index, item) {

        return index; // index or item.id
    }

    getScheduleStyle() {

        // Check if at least 1 zone is in-range (they should all have the same schedule)
        // I exclude zones without a schedule and obviously the SchedOnH/C must be true.
        const isInRange = _.some(this.RMS, (rms) => {

            return rms.RTU_RsIR && rms.CFG_IdxMCZ === this.mcz.id && ((rms.PAR_IdxSCH_H === this.mcz.PAR_IdxSCH_H && rms.PAR_IdxSCH_H !== -1 && rms.PAR_SchedOnH) || (rms.PAR_IdxSCH_C === this.mcz.PAR_IdxSCH_C && rms.PAR_IdxSCH_C !== -1 && rms.PAR_SchedOnC));
        });

        return isInRange ? 'thermal-in-range' : 'thermal-disabled';
    }

    isAutoModeStyleDualSetpoint() {
        return this.GRP[this.mcz?.CFG_IdxGRP]?.PAR_AutoModeStyle == AUTO_MODE_STYLE.DUAL_SETPOINT && this.GRP[this.mcz?.CFG_IdxGRP]?.PAR_Season != 0 && this.GRP[this.mcz?.CFG_IdxGRP]?.PAR_Season != 1 && this.GRP[this.mcz?.CFG_IdxGRP]?.PAR_Season != -1;
    }

    updateRealExceptionsH(event: boolean, i: number) {

        this.updateRealExceptions(event, i, DUAL_MODE.heating);
    }

    updateRealExceptionsC(event: boolean, i: number) {

        this.updateRealExceptions(event, i, DUAL_MODE.cooling);
    }

    // event true => exception | event false => no exception
    private updateRealExceptions(event, i, mode: DUAL_MODE) {

        const withoutTemperatureExceptionsArray = mode === DUAL_MODE.heating ? 'withoutTemperatureExceptions_H' : 'withoutTemperatureExceptions_C';
        const temperatureExceptionsArray = mode === DUAL_MODE.heating ? 'temperatureExceptions_H' : 'temperatureExceptions_C';
        const realExceptions = mode === DUAL_MODE.heating ? 'realExceptionsH' : 'realExceptionsC';

        if (event) {

            _.remove(this[withoutTemperatureExceptionsArray], (rms) => rms.id == i);

            this[withoutTemperatureExceptionsArray] = _.uniqBy(this[withoutTemperatureExceptionsArray], function (rms) {

                return rms.id;
            });

            this[temperatureExceptionsArray].push(i);

            this[temperatureExceptionsArray] = _.uniqBy(this[temperatureExceptionsArray], function (idxRms) {

                return idxRms;
            });

        } else {

            this[withoutTemperatureExceptionsArray].push(this.RMS[i]);

            this[withoutTemperatureExceptionsArray] = _.uniqBy(this[withoutTemperatureExceptionsArray], function (rms) {

                return rms.id;
            });

            _.remove(this[temperatureExceptionsArray], (rmsIdx) => rmsIdx == i);
        }

        this[realExceptions] = this[realExceptions] || event;
    }

    // If you want to open the "normal" override setpoint, dualSetpointMode is null. Otherwise, pass either DUAL_MODE.heating (0), or DUAL_MODE.cooling (1) to open the specific dual setpoint override
    async overrideSetpoint(dualSetpointMode: DUAL_MODE = null, otherDualSetpointModeIsInOverride = false) { // When "otherDualSetpointModeIsInOverride" is true, I apply more restrictions to the temperature min and max override, based on the currently active override.

        const newSchedule = await this.modalCtrl.create({
            component: OverrideSetpointComponent,
            cssClass: 'newScheduleModal SetpointOverrideModal',
            componentProps: {
                MCU: this.MCU,
                table: 'MCZ',
                rmsOrMczExtended: this.mczExtended,
                attrs: this.attrs,
                config: this.config,
                isApp: this.app,
                dualSetpointMode: dualSetpointMode,
                otherDualSetpointModeIsInOverride: otherDualSetpointModeIsInOverride,
                page: this
            }
        });

        await newSchedule.present();
    }

    // Don't delete (used in show-zone-override.component.html)
    async cancelOverride(dualSetpointMode: DUAL_MODE) {

        let PAR_SpoPeriod = 'PAR_SpoPeriod';
        let RTU_SpoRemain = 'RTU_SpoRemain';

        // It's an override heating of dual setpoint
        if (dualSetpointMode === DUAL_MODE.heating) {

            PAR_SpoPeriod = 'PAR_SpoPeriodH';
            RTU_SpoRemain = 'RTU_SpoRemainH';
        }

        // It's an override cooling of dual setpoint
        if (dualSetpointMode === DUAL_MODE.cooling) {

            PAR_SpoPeriod = 'PAR_SpoPeriodC';
            RTU_SpoRemain = 'RTU_SpoRemainC';
        }

        const resetOverride = () => {

            this.resetOverride(PAR_SpoPeriod, RTU_SpoRemain);

            // In case I have to reset the other override as well (For example: from a 70° / 72° setpoint macro-zone, I put an override of 80° / 82°. If I now cancel the 82° cooling override, I have to cancel the heating override as well, otherwise the cooling setpoint can't go back to 72°)
            if (violation.present) {

                const PAR_SpoPeriod2 = dualSetpointMode === DUAL_MODE.heating ? 'PAR_SpoPeriodC' : 'PAR_SpoPeriodH';
                const RTU_SpoRemain2 = dualSetpointMode === DUAL_MODE.heating ? 'RTU_SpoRemainC' : 'RTU_SpoRemainH';
                this.resetOverride(PAR_SpoPeriod2, RTU_SpoRemain2);
            }
        }

        const SpoSetTemp = dualSetpointMode === DUAL_MODE.heating ? this.mcz.PAR_SetTempH : this.mcz.PAR_SetTempC;
        const violation = this.getDualSetpointOverrideMinimumDifferenceViolation(null, dualSetpointMode, this.MCU, SpoSetTemp, this.mczExtended, true);
        const message = violation.present ? `${this.translate.instant('RESET_OVERRIDE_MESSAGE')}<br><br><span class="messana-red">${this.translate.instant(violation.text)}</span>`: 'RESET_OVERRIDE_MESSAGE';

        await this.confirmPopup(null, 'RESET_OVERRIDE_TITLE', message, 'YES', resetOverride, null, 'CLOSE');
    }

    resetOverride(PAR_SpoPeriod, RTU_SpoRemain) {

        this.store.dispatch(new ChangeTableValues('MCZ', this.id, {[PAR_SpoPeriod]: 0, [RTU_SpoRemain]: 0}));

        for (const rms of this.getMyZones()) {

            this.store.dispatch(new ChangeTableValues('RMS', rms.id, {[PAR_SpoPeriod]: 0, [RTU_SpoRemain]: 0}));
        }
    }
}

<!-- Override Setpoint in Zones -->
<ng-container *ngIf="isOverrideSetpoint">

    <div [ngClass]="{'disabled-item': rmsOrMczIsTurnedOff()}" class="d-flex flex-row justify-content-center align-items-center unselectable positionRelative">
        <div [ngClass]="{'messana-gray': rmsOrMczExtended.isMcz}" class="positionAbsolute overrideSetTempText">{{rmsOrMczExtended.getCorrectOverridePropertyValues(dualSetpointMode).PAR_SpoSetTemp}}</div>
        <p [ngClass]="{'messana-gray': rmsOrMczExtended.isMcz}" class="positionAbsolute overrideSetTempDataText">{{overrideSetpointDateText}}</p>

        <svg height="240" width="240" viewBox="0 0 24 24">

            <circle id="outer" r="10" cx="12" cy="12"
                    [attr.fill]="drawFillZone(rmsOrMczIsTurnedOff())" [attr.stroke]="getStrokeZone(rmsOrMczIsTurnedOff())" stroke-width="0.6px"/>

            <circle r="5" cx="12" cy="12" fill="transparent"
                    stroke="#fff"
                    stroke-width="10"
                    [attr.stroke-dasharray]="this.ray"
                    transform="rotate(-90) translate(-24)"/>
            <path [attr.d]="this.arc" fill="transparent" stroke="#ddd" stroke-width="0.6px"/>
        </svg>
    </div>
</ng-container>

<!-- Override Timer in Timer Component -->
<ng-container *ngIf="isOverrideTimer">

    <div class="tmrCircle d-flex flex-row justify-content-center align-items-center unselectable positionRelative"
         [ngClass]="{'disabled-override': disabledOverride,'cursorPointer':!disabledOverride}">

        <div class="positionAbsolute" style="line-height:1.5rem;">{{overTime}}</div>

        <svg *ngIf="!disabledOverride" height="24" width="24" viewBox="0 0 24 24">

            <circle id="outer" r="10" cx="12" cy="12" [attr.fill]="drawFillTimer(tmr_extended,overTime)"
                    [attr.stroke]="getStrokeTimer(tmr_extended,overTime)" stroke-width="1px"/>

            <circle *ngIf="activeButton(tmr_extended,overTime)" r="5" cx="12" cy="12"
                    fill="transparent"
                    stroke="#fff"
                    stroke-width="10"
                    [attr.stroke-dasharray]="this.ray"
                    transform="rotate(-90) translate(-24)"/>

            <path [attr.d]="this.arc" fill="transparent" stroke="#ddd"
                  stroke-width="1.15px"/>
        </svg>

        <svg *ngIf="disabledOverride" height="24" width="24" viewBox="0 0 24 24">
            <circle id="outer" r="10" cx="12" cy="12" fill="#fff" stroke="#ddd" stroke-width="1px"/>
        </svg>
    </div>
</ng-container>

<!-- Timer icon circle in Timer Component -->
<ng-container *ngIf="isTimerIcon">

    <div class="d-flex flex-row align-items-center positionRelative tmrMainCircle" style="left:-0.8rem;">

        <div class="positionAbsolute" style="z-index:200; top:50%;left:50%;transform:translate(-50%,-50%);">
            <timer-icon [tmr]="tmr_extended"></timer-icon>
        </div>

        <!-- <div *ngIf="!disabledOverride(tmr)"></div>-->
        <svg style=" z-index:100;" height="24" width="24" viewBox="0 0 24 24">

            <circle r="10" cx="12" cy="12" fill="#fff" [attr.stroke]="strokeTimerState(tmr_extended)"
                    stroke-width="1.2px"/>

            <path [attr.d]="this.arc" fill="transparent" [attr.stroke]="tmr_extended.liteOrange"
                  stroke-width="1.45px"/>

        </svg>
    </div>
</ng-container>
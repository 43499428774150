import {Injectable} from '@angular/core';
import {BleClient, ConnectionPriority, numbersToDataView, ScanMode} from "@capacitor-community/bluetooth-le";
import * as _ from 'lodash';
import {DisconnectConfig, McuConfig} from "../actions/config.actions";
import {Store} from "@ngrx/store";
import {AppState} from "./app-state.service";
import {Table} from "../models/tables";

import * as getUuid from "uuid-by-string";
import {ActionSheetController, AlertController, Platform, ToastController} from "@ionic/angular";
import {ChangeTableValues} from "../actions/tables.actions";
import {decompress} from "@blu3r4y/lzma";
import {AbstractBaseComponent} from "../components/abstract-base-component";
import {TranslateService} from "@ngx-translate/core";
import {HttpCancelService} from "./http-cancel.service";
import {Router} from "@angular/router";
import {catchError, first, timeout} from "rxjs/operators";
import {NetworkStatus, NetworkWay} from '../models/http-status';
import {interval, Observable, Subscription, throwError, timer} from "rxjs";
import {Config, EnvironmentType} from "../models/config";
import {fixedEncodeURI} from "../models/http";
import {HttpClient} from "@angular/common/http";
import {Network} from "@capacitor/network";
import {PopupService} from "./popup.service";
import {CheckRemoteConnectionState, Connected, Disconnected} from "../actions/socket.actions";
import {HttpStatusService} from "./http-status.service";

enum BluetoothPopupSections {
    MENU_MCU_SELECT = 'MENU_MCU_SELECT',
    MCU_SELECT = 'MCU_SELECT',
    HOME = 'HOME',
    SETTINGS_MENU = 'SETTINGS_MENU'
}

@Injectable({
    providedIn: 'root'
})

export class BluetoothService extends AbstractBaseComponent {

    public static isConnected = false; // Global state of the Bluetooth connection -> it means I'm currently using Bluetooth as my connection (it will modify the NetworWay) but it doesn't mean necessarily I'm currently connected (I may go out of range)
    public readonly bluetoothPopupSelections = BluetoothPopupSections;

    private static inConnection = false; // Only used to change color of the bluetooth text and disable the clicks on "Connect via BLuetooth" while attempting to connect
    private static isCurrentlyConnected = false; // When I'm actually connected to the Bluetooth (from await this.connectViaBluetooth)
    private static isBluetoothEmergency = false; // When it's connected from "no-connection" popup (from Home Page timeout timer) -> it has extra features (like when an http connection return, a popup appears)
    private static isDisconnecting = false;
    private static rssiSubscription: Subscription;
    private static bleEmergencySubscription: Subscription;
    private static bluetoothOnOffIosSubscription: Subscription;

    private readonly serviceId = this.getFixedUUID('messana-bleno');

    private readonly firstBleTimeout = {timeout: 5000}; // The first BLE scan will only take 5 seconds to search for a BLE device. If it fails -> the next scans will take 20 seconds (bleTimeout)
    private readonly bleTimeout = {timeout: 20000}; // optional timeout to use on Reads / Writes. After this timeout the ble read / writes give an error (default: 5 seconds - with this one: 20 seconds)
    private readonly textEncoder = new TextEncoder();
    private readonly textDecoder = new TextDecoder('utf-8');

    private deviceId: string;
    private bleCache: any = {}; // Since the Attributes Object is quite heavy, and the Bluetooth takes a lot of time downloading it => I will keep it cached and never query it again, once I download it (its values shouldn't change anyway) -> Also used to store previous API values
    private recentChanges: any = {}; // It works (almost) exactly as the recent changes of the http socket
    private mtuSize = 0; // The max mtuSize data (single packet) I can exchange with the Server -> it will be defined after connect
    private turnedOffBluetoothPopupDisplayed = false;
    private isFirstScan = true; // After a BLE scan device starts, this variabile will turn true (used in sync with firstScanBleTimout -> the first scan lasts 5 seconds, and if it fails it won't show an error Popup -> but it will try to scan again. If it fails yet again, it will show a popup)
    private currentToastsShowed: HTMLIonToastElement[] = []; // I keep track of all showed toasts, because when I show the "Connected via Bluetooth" toast I want to wipe out all other Bluetooth related toasts.

    private bleConnectionToast;
    private static bleRequestStatusList = {}; // Used (for example) if I'm requesting the table RMS (bluetoothIsRequestingAPI["RMS"].requesting = true) and I get another request -> I block it until the request is completed and bluetoothIsRequestingAPI["RMS"].requesting = false

    constructor(protected store: Store<AppState>, protected platform: Platform, protected alertController: AlertController, protected actionSheetController: ActionSheetController, protected translate: TranslateService, private httpCancelService: HttpCancelService, private router: Router, private http: HttpClient, private popupService: PopupService, protected toastController?: ToastController) {
        super(store, platform, alertController, actionSheetController, translate);
    }

    // -----------------------------------------------------------------------------------------------------------------

    static createBleRequestStatus(writeData = null) {

        let requesting = false; // If I started a Bluetooth communication to get data (for example: the RMS table)

        if (writeData) {

            requesting = true; // If the ble request is a write (so I've data to send) I automatically initialize it to true
        }

        // loadedOnce -> The first time I completely load a Table, loadedOnce becomes true -> and the socket (ble notify) for that table can update its data
        return {requesting: requesting, loadedOnce: false, writeData: writeData};
    }

    // -----------------------------------------------------------------------------------------------------------------

    getBluetoothText() {

        if (this.isConnected()) {

            return 'BLE_DISCONNECT_FROM_BLUETOOTH';
        }

        if (this.isConnecting()) {

            return 'BLE_CONNECTING_VIA_BLUETOOTH';
        }

        if (this.isDisconnecting()) {

            return 'BLE_DISCONNECTING_FROM_BLUETOOTH';
        }

        return 'BLE_CONNECT_VIA_BLUETOOTH';
    }

    // -----------------------------------------------------------------------------------------------------------------

    getBluetoothTextColor() {

        // Attenzione: il colore per ora non è necessario: la classe ritornata dunque sarà sempre vuota (tenere il codice per un futuro però)

        /*if (this.isConnected()) {

            return 'bluetooth-color-red';
        }

        if (this.isConnecting()) {

            return 'bluetooth-color-blue';
        }*/

        return '';
    }

    // -----------------------------------------------------------------------------------------------------------------
    // convert string -> to array of objects with number -> to normal array of integers (used to write in bluetooth as Buffer)
    // -----------------------------------------------------------------------------------------------------------------

    encodeRequest(request) {

        return _.values(this.textEncoder.encode(request)); // _.values -> for array of objects -> into array
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- Convert DataView to ByteArray -> which will be then converted to an Uint8Array and decompressed -------------
    // -----------------------------------------------------------------------------------------------------------------

    convertDataViewToByteArray(dataView) {

        const byteArray = [];
        const maxMtuBufferSize = this.mtuSize * 2;

        // console.warn('dataView.byteLength', dataView.byteLength);
        const maxLength = dataView.byteLength < maxMtuBufferSize ? dataView.byteLength : maxMtuBufferSize;

        for (let i = 0; i < maxLength; i++) {

            byteArray.push(dataView.getUint8(i));
        }

        return byteArray;
    }

    showBluetoothButton() {

        return this.platform.is('cordova') || this.platform.is('capacitor');
    }

    // -----------------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    async bluetoothSearchingCompleted() {

        BluetoothService.inConnection = false;
        await BleClient.stopLEScan();
    }

    // -----------------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    isConnecting() {

        return BluetoothService.inConnection;
    }

    isConnected() {

        return BluetoothService.isConnected;
    }

    isCurrentlyConnected() {

        return BluetoothService.isCurrentlyConnected;
    }

    isDisconnecting() {

        return BluetoothService.isDisconnecting;
    }

    isBluetoothEmergency() {

        return BluetoothService.isBluetoothEmergency;
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- DISCONNECT BLUETOOTH ----------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    async disconnectFromBluetoothAndResetModality(httpStatusService) {

        // -------------------------------------------------------------------------------------------------------------

        const disconnect = async () => {

            console.log('disconnect started!');

            this.store.dispatch(new Disconnected());
            BluetoothService.isCurrentlyConnected = false;
            BluetoothService.isConnected = false;
            BluetoothService.bleRequestStatusList = {};

            if (BluetoothService.rssiSubscription) {

                BluetoothService.rssiSubscription.unsubscribe();
                BluetoothService.rssiSubscription = null;
            }

            if (BluetoothService.bleEmergencySubscription) {

                BluetoothService.bleEmergencySubscription.unsubscribe();
                BluetoothService.bleEmergencySubscription = null;
            }

            if (BluetoothService.bluetoothOnOffIosSubscription) {

                BluetoothService.bluetoothOnOffIosSubscription.unsubscribe();
                BluetoothService.bluetoothOnOffIosSubscription = null;
            }

            try {

                await BleClient.stopNotifications(this.deviceId, this.serviceId, this.getFixedUUID('socket'));
                await BleClient.disconnect(this.deviceId);

            } catch (e) {

                console.log(e);
            }

            const status = await Network.getStatus();
            httpStatusService.dispatchCommutation(status, true);
            await disconnectingToast.dismiss();
            await this.showToast('BLE_DISCONNECTED_FROM_BLUETOOTH');
            BluetoothService.isDisconnecting = false;
        }

        // -------------------------------------------------------------------------------------------------------------

        if (!BluetoothService.isConnected || BluetoothService.isDisconnecting) {

            return;
        }

        BluetoothService.isDisconnecting = true;
        const disconnectingToast = await this.showToast('BLE_DISCONNECTING_FROM_BLUETOOTH', this.bleTimeout.timeout);

        // -------------------------------------------------------------------------------------------------------------

        await disconnect();
        return true;
    }

    // -----------------------------------------------------------------------------------------------------------------

    startSignalStrengthListener() {

        if (BluetoothService.rssiSubscription) {

            return;
        }

        BluetoothService.rssiSubscription = interval(5000).subscribe(async () => {

            try {

                const rssi = await BleClient.readRssi(this.deviceId);

                if (BluetoothService.isCurrentlyConnected && rssi <= -90) {

                    await this.showToast('BLE_LOW_RSSI_NOTIFICATION', 3000);
                }
            } catch (e) {

            }
        });
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- CONNECT TO BLUETOOTH -> IF DISCONNECT -> RECONNECT ----------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    async connectViaBluetooth(deviceId: string, httpStatusService: HttpStatusService, fromReconnection: boolean) {

        if (BluetoothService.isDisconnecting || (!BluetoothService.isConnected && fromReconnection)) {

            return;
        }

        // -------------------------------------------------------------------------------------------------------------

        const showDisconnectToast = async () => {

            let bluetoothOn = await BleClient.isEnabled();

            if (this.bleConnectionToast) {

                this.bleConnectionToast.dismiss();
            }

            if (BluetoothService.isDisconnecting) {

                return;
            }

            if (bluetoothOn && httpStatusService.reloadingNetwork != NetworkStatus.Reloading) {

                // Popup -> Questo blocca la riconnessione -> che riparte quando premo OK
                this.bleConnectionToast = await this.showToast('BLE_OUT_OF_RANGE_UNSTABLE', 10000);
                return;
            }

            // ---------------------------------------------------------------------------------------------------------
            // --- IF THE USER DISABLE THE BLUETOOTH -> ALERT (OK / SELECT YOUR SYSTEM) --------------------------------
            // ---------------------------------------------------------------------------------------------------------

            await this.showBluetoothTurnedOffPopup(httpStatusService);
        }

        // -------------------------------------------------------------------------------------------------------------

        if (fromReconnection) {

            console.log('reconnecting to Bleno: ' + deviceId);
        }

        BluetoothService.isCurrentlyConnected = false;
        this.store.dispatch(new Disconnected());

        try {

            // await BleClient.disconnect(this.deviceId); // Always disconnect before connecting?

            await BleClient.connect(this.deviceId, async (deviceId) => {

                await showDisconnectToast();
                await this.connectViaBluetooth(deviceId, httpStatusService, true);

            }, this.firstBleTimeout);

            if (this.android) {

                await BleClient.requestConnectionPriority(this.deviceId, ConnectionPriority.CONNECTION_PRIORITY_HIGH);
            }

            console.warn('Connesso a messana-bleno!');

            if (this.bleConnectionToast) {

                this.bleConnectionToast.dismiss();
            }

            await this.dismissAllToasts();
            this.bleConnectionToast = await this.showToast('BLE_CONNECTED_VIA_BLUETOOTH', this.firstBleTimeout.timeout);

            BluetoothService.isConnected = true;
            BluetoothService.isCurrentlyConnected = true;
            this.store.dispatch(new Connected());
            this.startSignalStrengthListener();
            await this.activateBluetoothSocket();

        } catch (e) {

            console.log('catch', e);
            await showDisconnectToast();
            await this.connectViaBluetooth(deviceId, httpStatusService, fromReconnection);
        }

        // await BleClient.createBond(this.deviceId); // -> Bonding isn't required to communicate via BLE
    }

    // -----------------------------------------------------------------------------------------------------------------
    // ---- CLICKING "CONNECT VIA BLUETOOTH" INITIALIZE THE BLE COMUNICATION -------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    async enterViaBluetooth(isBluetoothEmergency, showToast, httpStatusService) {

        // Check if location permission is enabled (otherwise, open settings)
        if (this.android) {

            const isLocationEnabled = await BleClient.isLocationEnabled();

            if (!isLocationEnabled) {

                await BleClient.openLocationSettings();
            }
        }

        try {

            await BleClient.initialize();
        }

        catch (e) {

            await this.showToast('BLE_PERMISSION_DENIED', this.firstBleTimeout.timeout);
            return;
        }

        const bluetoothOn = await BleClient.isEnabled();

        if (!bluetoothOn) {

            await this.showBluetoothTurnedOffPopupFromScan(isBluetoothEmergency, showToast, httpStatusService);
            return;
        }

        if (this.isConnecting() || this.isConnected()) {

            return;
        }

        console.warn('this.isFirstScan', _.cloneDeep(this.isFirstScan));

        const isFirstScan = this.isFirstScan;
        this.isFirstScan = false;

        const bleTimeout = isFirstScan ? this.firstBleTimeout : this.bleTimeout;

        let homepageBleToast;

        if (showToast) {

            homepageBleToast = await this.showToast('BLE_CONNECTING_VIA_BLUETOOTH_FULL', this.bleTimeout.timeout);
        }

        BluetoothService.inConnection = true;

        const cancelTimerScan = setTimeout(() => {

            this.bluetoothSearchingCompleted();
            console.warn('isFirstScan', isFirstScan);

            if (isFirstScan) {

                this.enterViaBluetooth(isBluetoothEmergency, showToast, httpStatusService);
            } else {

                this.confirmPopup(null, 'BLE_UNAVAILABLE', "BLE_UNAVAILABLE_TEXT", 'BLE_SCAN', () => this.enterViaBluetooth(isBluetoothEmergency, showToast, httpStatusService));
            }

        }, bleTimeout.timeout);

        await BleClient.requestLEScan({services: [this.serviceId], scanMode: ScanMode.SCAN_MODE_LOW_LATENCY}, async (result) => {

            console.log('Bleno trovato: ', result);

            clearTimeout(cancelTimerScan);

            this.deviceId = result.device.deviceId;

            console.warn('Mi sto connettendo..');
            await this.connectViaBluetooth(this.deviceId, httpStatusService, false);

            if (homepageBleToast) {

                await homepageBleToast.dismiss();
            }

            await this.bluetoothSearchingCompleted();

            BluetoothService.isBluetoothEmergency = isBluetoothEmergency;

            // -----------------------------------------------------------------------------------------------------
            // --- Handshaking to define the mtuSize you can exchange with the server and to get the MCU code ------
            // -----------------------------------------------------------------------------------------------------

            let mcuCode;

            const handshake = async () => {

                const mtu = await BleClient.getMtu(this.deviceId);
                this.mtuSize = mtu - 1; // The real MTU size is actually -1 (for example: MTU 256 - 1 = 255)
                const handshakeObject = {mtuSize: this.mtuSize};

                try {

                    await BleClient.write(this.deviceId, this.serviceId, this.getFixedUUID('handshake_create'), numbersToDataView(this.encodeRequest(JSON.stringify(handshakeObject))), this.bleTimeout);

                    const dataView = await BleClient.read(this.deviceId, this.serviceId, this.getFixedUUID('read_mcu_code'));
                    const byteArray = this.convertDataViewToByteArray(dataView);
                    const arrayResponseUint8 = new Uint8Array(byteArray);
                    const decodedString = this.textDecoder.decode(arrayResponseUint8);
                    const parsedResult = JSON.parse(decodedString);
                    mcuCode = parsedResult.mcu;
                    console.warn('mcuCode', mcuCode);

                } catch (e) {

                    await handshake();
                }
            }

            // -----------------------------------------------------------------------------------------------------
            // --- Load the APP ------------------------------------------------------------------------------------
            // -----------------------------------------------------------------------------------------------------

            await handshake();
            console.warn('TERMINATED - HANDSHAKE', mcuCode);
            this.httpCancelService.cancelPendingRequests();
            this.store.dispatch(new McuConfig(mcuCode, false));
        });
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- BLUETOOTH NOTIFICATION FROM REDIS SOCKET --------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    async activateBluetoothSocket() {

        if (BluetoothService.isDisconnecting || !BluetoothService.isConnected) {

            return;
        }

        try {

            await BleClient.startNotifications(this.deviceId, this.serviceId, this.getFixedUUID('socket'), async (dataView: DataView) => {

                try {

                    const byteArray = this.convertDataViewToByteArray(dataView);
                    const arrayResponseUint8 = new Uint8Array(byteArray);
                    const decompressedData = decompress(arrayResponseUint8) as string;
                    const parsedData = JSON.parse(decompressedData);

                    // I update the table from socket only if I've actually loaded it at least once
                    if (BluetoothService.bleRequestStatusList[parsedData.table] && BluetoothService.bleRequestStatusList[parsedData.table].loadedOnce) {

                        // log('from-notification', parsedData);

                        const values = {};
                        values[parsedData.var] = parsedData.value;

                        const property = `${parsedData.table}.${Number.parseInt(parsedData.id)}.${parsedData.var}`;

                        if (this.recentChanges[property] && this.recentChanges[property].fromInAppSetValue) {

                            this.recentChanges[property].fromInAppSetValue = false;
                            return;
                        }

                        this.store.dispatch(new ChangeTableValues(parsedData.table, Number.parseInt(parsedData.id), values, true));
                    } else {
                        // console.warn(`Notification table BLOCKED -> [${parsedData.table}]`);
                    }

                } catch (e) {

                    // console.warn(e);
                }
            });

        } catch (e) {

            console.log(e);
            await this.activateBluetoothSocket();
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // ---- BLUETOOTH TABLE + API GET ----------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    // Get the 'Attributes', API, or any Table
    async getDataViaBluetooth(tableOrApiName, isAPI = false, apiValues = null): Promise<any> {

        const getCachedResult = () => {

            if (isAPI) {

                return this.bleCache[tableOrApiName] ? this.bleCache[tableOrApiName] : {};
            }

            if (tableOrApiName === 'Attributes') {

                return this.bleCache[tableOrApiName] ? this.bleCache[tableOrApiName] : {};
            }

            return this.bleCache[tableOrApiName] ? this.bleCache[tableOrApiName] : [] as Table[];
        }

        if (!BluetoothService.bleRequestStatusList[tableOrApiName]) {

            BluetoothService.bleRequestStatusList[tableOrApiName] = BluetoothService.createBleRequestStatus();
        }

        // -------------------------------------------------------------------------------------------------------------
        // --- Preliminary checks (Queue / cache) ----------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        if (tableOrApiName === 'Attributes' && this.bleCache[tableOrApiName]) {

            return this.bleCache[tableOrApiName];
        }


        // If I'm already receiving Data via Bluetooth -> I Block any request until the previous one is completed (in the meantime I will return the previous value, if present)
        if (BluetoothService.isDisconnecting || !BluetoothService.isConnected || BluetoothService.bleRequestStatusList[tableOrApiName].requesting) { // Now I also return a cached value and end the request if I've decided to end my BLE connection (!BluetoothService.isDisconnecting)

            return getCachedResult();
        }

        // -------------------------------------------------------------------------------------------------------------
        // --- Write Request (ask for specific data to Bleno. It will fill the characteristic value so you can read it -
        // -------------------------------------------------------------------------------------------------------------

        BluetoothService.bleRequestStatusList[tableOrApiName].requesting = true;

        let encodedRequest = this.encodeRequest(tableOrApiName);

        if (apiValues) {

            encodedRequest = this.encodeRequest(JSON.stringify(apiValues));
        }

        try {

            await BleClient.write(this.deviceId, this.serviceId, this.getFixedUUID(tableOrApiName), numbersToDataView(encodedRequest), this.bleTimeout);

        } catch (e) {

            // console.warn(e);
            BluetoothService.bleRequestStatusList[tableOrApiName].requesting = false;
            return await this.getDataViaBluetooth(tableOrApiName, isAPI, apiValues);
        }

        // -------------------------------------------------------------------------------------------------------------
        // --- update_network and reboot don't need to actually read a value -------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        if (tableOrApiName === 'api/action' && (apiValues.action === 'update_network' || apiValues.action === 'reboot')) {

            BluetoothService.bleRequestStatusList[tableOrApiName].requesting = false;
            return {code: 1, content: `ble-${apiValues.action}`};
        }

        // -------------------------------------------------------------------------------------------------------------
        // --- Read Request (read the Bleno characteristic value - can be a Table or another request) ------------------
        // -------------------------------------------------------------------------------------------------------------

        let completed = false;
        let totalByteArray = [];

        while (!completed) {

            try {

                const dataViewResult = await BleClient.read(this.deviceId, this.serviceId, this.getFixedUUID(tableOrApiName));
                const byteArray = this.convertDataViewToByteArray(dataViewResult);
                totalByteArray = totalByteArray.concat(byteArray);
                // console.log(totalByteArray);
                const arrayResponseUint8 = new Uint8Array(totalByteArray);
                // console.log(arrayResponseUint8);

                try {

                    const decompressedData = decompress(arrayResponseUint8) as string;
                    const parsedData = JSON.parse(decompressedData);
                    console.warn(tableOrApiName, parsedData);
                    completed = true;

                    BluetoothService.bleRequestStatusList[tableOrApiName].requesting = false;
                    BluetoothService.bleRequestStatusList[tableOrApiName].loadedOnce = true;

                    console.warn(BluetoothService.bleRequestStatusList);

                    if (isAPI || tableOrApiName === 'Attributes') {

                        this.bleCache[tableOrApiName] = parsedData;
                        return parsedData;

                    } else {

                        // Tables
                        const tableArray = _.values(parsedData[0]);
                        this.bleCache[tableOrApiName] = tableArray;
                        return tableArray as Table[];
                    }

                } catch (e) {

                    let disconnectedCachedResult = getCachedResultBleDisconnecting();

                    if (disconnectedCachedResult) {

                        return disconnectedCachedResult;
                    }
                }

            } catch (e) {

                let disconnectedCachedResult = getCachedResultBleDisconnecting();

                if (disconnectedCachedResult) {

                    return disconnectedCachedResult;
                } else {

                    BluetoothService.bleRequestStatusList[tableOrApiName].requesting = false;
                    return await this.getDataViaBluetooth(tableOrApiName, isAPI, apiValues);
                }
            }
        }

        // -------------------------------------------------------------------------------------------------------------

        function getCachedResultBleDisconnecting() {

            if (BluetoothService.isDisconnecting || !BluetoothService.isConnected) {

                completed = true;
                BluetoothService.bleRequestStatusList[tableOrApiName].requesting = false;
                return getCachedResult();
            }
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // ---- BLUETOOTH TABLE SET / WRITE --------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    async writeDataViaBluetooth(characteristicId, data) {

        // -------------------------------------------------------------------------------------------------------------
        // ---- WRITE POPUP ERROR (if a write fails) -------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        let dialogShowed = false;

        // The popup isn't shown anymore (deprecated) -> I just retry to write forever
        const showBlePopupError = () => {

            if (dialogShowed) {

                return;
            }

            dialogShowed = true;

            // console.log('showBlePopupError', data);
            this.confirmPopup(null, 'BLE_SAVE_FAILED', 'BLE_SAVE_FAILED_TEXT', 'BLE_RETRY', () => this.writeDataViaBluetooth(characteristicId, data));
        }

        // -------------------------------------------------------------------------------------------------------------
        // ---- I cancel any request if it's disconnecting from bluetooth ----------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        if (BluetoothService.isDisconnecting || !BluetoothService.isConnected) {

            return;
        }

        // -------------------------------------------------------------------------------------------------------------
        // ---- tableSetItemValues -> requests are small, the JSON is smaller than 255 bytes -> Single Write Request ---
        // -------------------------------------------------------------------------------------------------------------

        if (characteristicId === 'tableSetItemValues') {

            for (let value in data.values) {

                let property = `${data.table}.${data.id}.${value}`;
                this.recentChanges[property] = {fromInAppSetValue: true}; // fromInAppSetValue: you ignore values you get from the socket if it's true
            }

            const encodedData = this.encodeRequest(JSON.stringify(data));

            try {

                await BleClient.write(this.deviceId, this.serviceId, this.getFixedUUID(characteristicId), numbersToDataView(encodedData), this.bleTimeout);

            } catch (e) {

                // await this.writeDataViaBluetooth(characteristicId, data);
                // showBlePopupError();
            }
            return;
        }

        // -------------------------------------------------------------------------------------------------------------
        // ---- setValues / setAttributes -> requests are more than 255 bytes. I must slice them in smaller requests ---
        // -------------------------------------------------------------------------------------------------------------

        // I block new requests if there's another already in progress (only 1 "heavy" write at a time)
        if (BluetoothService.bleRequestStatusList[characteristicId] && BluetoothService.bleRequestStatusList[characteristicId].requesting) {

            await this.showToast("BLE_REQUEST_BLOCKED");
            return;
        }

        // Data preparation (putting requesting: true and setting the writeData to slice)
        BluetoothService.bleRequestStatusList[characteristicId] = BluetoothService.createBleRequestStatus(JSON.stringify(data));

        // First I use a read to inform Bleno that I'm going to make multiple Writes
        try {

            await BleClient.read(this.deviceId, this.serviceId, this.getFixedUUID(characteristicId));

            // Then I start sending the data to Bleno in smaller pieces. Bleno will then re-compose them all, once he has them, and write data on Redis
            while (BluetoothService.bleRequestStatusList[characteristicId].writeData !== "") { // Write until all pieces have been sent

                const sliceLimit = this.mtuSize; // previously it was hardcoded as 255, but it's dynamic based on the handshaking of the device / server

                const sendingChunk = this.encodeRequest(BluetoothService.bleRequestStatusList[characteristicId].writeData.slice(0, sliceLimit));
                BluetoothService.bleRequestStatusList[characteristicId].writeData = BluetoothService.bleRequestStatusList[characteristicId].writeData.slice(sliceLimit);

                try {

                    await BleClient.write(this.deviceId, this.serviceId, this.getFixedUUID(characteristicId), numbersToDataView(sendingChunk), this.bleTimeout);

                } catch (e) {

                    BluetoothService.bleRequestStatusList[characteristicId].requesting = false;
                    BluetoothService.bleRequestStatusList[characteristicId].writeData = "";
                    // await this.writeDataViaBluetooth(characteristicId, data);
                    // showBlePopupError();
                }
            }

            BluetoothService.bleRequestStatusList[characteristicId].requesting = false;
            console.warn('written', 'written-ended');

        } catch (e) {

            BluetoothService.bleRequestStatusList[characteristicId].requesting = false;
            // await this.writeDataViaBluetooth(characteristicId, data);
            // showBlePopupError();
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // ---- SHOW TOAST -------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    async showToast(message: string, duration = 3000) {

        const toast = await this.toastController.create({
            message: this.translate.instant(message),
            duration: duration
        });

        this.currentToastsShowed.push(toast);

        toast.onDidDismiss().then(() => {

            // Remove the toast from the array when it is dismissed
            this.currentToastsShowed = this.currentToastsShowed.filter(localToast => localToast !== toast);
        });

        await toast.present();
        return toast;
    }

    // -----------------------------------------------------------------------------------------------------------------

    async dismissAllToasts() {

        for (const toast of this.currentToastsShowed) {

            await toast.dismiss();
        }

        this.currentToastsShowed.length = 0;
    }

    // -----------------------------------------------------------------------------------------------------------------
    // ---- NEW -> Show Popup for Bluetooth Connection (Network Settings) ----------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    async showBluetoothPopup(section, httpStatusService, zeroConfFromGuest = false, environment = null) {

        switch (section) {

            case this.bluetoothPopupSelections.SETTINGS_MENU:
            case this.bluetoothPopupSelections.MENU_MCU_SELECT:
            case this.bluetoothPopupSelections.MCU_SELECT:

                const showToast = true; // const showToast = section !== this.bluetoothPopupSelections.SETTINGS_MENU
                await this.confirmPopup(null, 'BLE_CONNECT_VIA_BLUETOOTH', "BLE_POPUP_DETAIL_TEXT", 'BLE_CONNECT', () => this.enterViaBluetooth(false, showToast, httpStatusService));
                break;

            case this.bluetoothPopupSelections.HOME:

                const actions = [];

                let MCU_SELECT_LABEL = 'MCU_SELECT_LABEL';
                let BLE_EMERGENCY_TEXT_SYSTEM = 'BLE_SYSTEM_SELECTION';

                if (zeroConfFromGuest) {

                    MCU_SELECT_LABEL = 'BACK_TO_LOGIN';
                    BLE_EMERGENCY_TEXT_SYSTEM = 'BLE_LOGIN_PAGE';
                }

                actions.push({
                    text: MCU_SELECT_LABEL,
                    handler: () => {

                        if (httpStatusService.modality === NetworkWay.DISCOVERY || environment === EnvironmentType.MCU) {

                            this.store.dispatch(new DisconnectConfig(zeroConfFromGuest));
                        }

                        if (zeroConfFromGuest) {

                            this.router.navigate(['/login']);

                        } else {

                            this.router.navigate(['/mcu-select']);
                        }
                    },
                    cssClass: 'blue-ion-button'
                });

                actions.push({
                    text: 'BLE_CONNECT_VIA_BLUETOOTH',
                    handler: () => this.enterViaBluetooth(true, true, httpStatusService),
                    cssClass: 'blue-ion-button'
                });

                actions.push({
                    text: 'CLOSE',
                    handler: () => {

                        this.store.dispatch(new CheckRemoteConnectionState());
                    },
                    cssClass: 'blue-ion-button'
                });

                const message = this.translate.instant('BLE_EMERGENCY_TEXT_1') + ` ${this.translate.instant(BLE_EMERGENCY_TEXT_SYSTEM)} ` + this.translate.instant('BLE_EMERGENCY_TEXT_2');

                await this.popupService.showCustomButtonsWarning('BLE_EMERGENCY_TITLE', message, actions);
                break;
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- Disconnect from Bluetooth -----------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    showBluetoothDisconnectionPopup(section, httpStatusService) {

        switch (section) {

            case this.bluetoothPopupSelections.SETTINGS_MENU:

                this.confirmPopup(null, 'BLE_DISCONNECT_FROM_BLUETOOTH', "BLE_DISCONNECT_FROM_BLUETOOTH_MANUAL", 'DISCONNECT', async () => {

                    await this.disconnectFromBluetoothAndResetModality(httpStatusService);
                    await this.router.navigateByUrl('/mcu-select');
                });
                break;
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- NOTIFY WHEN BLUETOOTH IS POWERED-OFF (IOS ONLY) -------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    startBluetoothOnOffIosListener(httpStatusService) {

        if (BluetoothService.bluetoothOnOffIosSubscription) {

            return;
        }

        // Only for iOS -> the event power-off (bluetooth) and disconnect are 2 different events (on Android everything is hanlded by onDisconnect, inside the connect method)
        if (this.platform.is('ios')) {

            BluetoothService.bluetoothOnOffIosSubscription = interval(3000).subscribe(async () => {

                if (this.turnedOffBluetoothPopupDisplayed || !BluetoothService.bluetoothOnOffIosSubscription) {

                    return;
                }

                const bluetoothOn = await BleClient.isEnabled();

                if (!bluetoothOn) {

                    await this.showBluetoothTurnedOffPopup(httpStatusService);
                }
            });
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- NOTIFY WHEN INTERNET IS RESTORED WHILE USING BLUETOOTH EMERGENCY --------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    startInternetConnectionListener(httpStatusService) {

        if (BluetoothService.bleEmergencySubscription) {

            return;
        }

        let popupIsCurrentlyDisplayed = false;
        const config = this.store.select('config') as Observable<Config>;

        config.pipe(first()).subscribe(data => {

            const baseUrl = data.baseUrl;
            console.log('baseUrl', baseUrl);

            BluetoothService.bleEmergencySubscription = interval(10000).subscribe(async () => {

                if (!BluetoothService.bleEmergencySubscription || popupIsCurrentlyDisplayed) {

                    return;
                }

                const data = await this.http.get(fixedEncodeURI(baseUrl + 'api/release200')).pipe(first(), timeout(6000),

                    catchError((error) => {

                        return throwError(`[BLE Emergency Listener - No connection yet ] ${error}`);

                    })).toPromise();

                // -----------------------------------------------------------------------------------------------------------------

                if (data) {

                    await showRestoredInternetPopup();
                }
            });
        });

        // -------------------------------------------------------------------------------------------------------------
        // --- SHOW POPUP ----------------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        const showRestoredInternetPopup = async () => {

            popupIsCurrentlyDisplayed = true;
            const actions = [];

            actions.push({
                text: 'BLE_EMERGENCY_RESTORED_ACTION_RECONNECT',
                handler: async () => await this.disconnectFromBluetoothAndResetModality(httpStatusService),
                cssClass: 'blue-ion-button'
            });

            actions.push({
                text: 'BLE_EMERGENCY_RESTORED_ACTION_KEEP',
                handler: () => {

                    BluetoothService.bleEmergencySubscription.unsubscribe();
                    BluetoothService.bleEmergencySubscription = null;
                },
                cssClass: 'blue-ion-button'
            });

            actions.push({
                text: 'BLE_EMERGENCY_RESTORED_POSTPONE',
                handler: () => {

                    BluetoothService.bleEmergencySubscription.unsubscribe();
                    BluetoothService.bleEmergencySubscription = null;

                    // this.showToast("BLE_EMERGENCY_RESTORED_POSTPONE_TOAST", 4000);

                    // After 10 minutes -> I resume the listener every 20 seconds
                    timer(600000).subscribe(() => {

                        this.startInternetConnectionListener(httpStatusService);
                    });
                },
                cssClass: 'blue-ion-button'
            });

            await this.popupService.showCustomButtonsWarning('BLE_EMERGENCY_RESTORED_TITLE', 'BLE_EMERGENCY_RESTORED_MESSAGE', actions);
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- SHOW BLUETOOTH TURNED OFF POPUP (WHEN PRESSING SCAN) --------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    async showBluetoothTurnedOffPopupFromScan(isBluetoothEmergency, showToast, httpStatusService) {

        const actions = [];

        actions.push({

            text: 'OK',
            handler: async () => {

                await this.enterViaBluetooth(isBluetoothEmergency, showToast, httpStatusService);
            },

            cssClass: 'blue-ion-button'
        });

        await this.popupService.showCustomButtonsWarning('BLE_BLUETOOTH_DISABLED_TITLE', 'BLE_BLUETOOTH_DISABLED_SCAN_MESSAGE', actions);
    }

    // -----------------------------------------------------------------------------------------------------------------
    // --- SHOW BLUETOOTH TURNED OFF POPUP (LISTENER) ------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    async showBluetoothTurnedOffPopup(httpStatusService) {

        if (this.turnedOffBluetoothPopupDisplayed) {

            return;
        }

        this.turnedOffBluetoothPopupDisplayed = true;

        const actions = [];

        actions.push({
            text: 'MCU_SELECT_LABEL',
            handler: async () => {
                this.turnedOffBluetoothPopupDisplayed = false;
                await this.disconnectFromBluetoothAndResetModality(httpStatusService);
                await this.router.navigateByUrl('/mcu-select');
            },
            cssClass: 'blue-ion-button'
        });

        actions.push({
            text: 'OK',
            handler: async () => {

                this.turnedOffBluetoothPopupDisplayed = false;

                if (this.platform.is('ios')) {

                    const bluetoothOn = await BleClient.isEnabled();

                    if (bluetoothOn) {

                        await this.connectViaBluetooth(this.deviceId, httpStatusService, true);

                    } else {

                        if (httpStatusService.reloadingNetwork != NetworkStatus.Reloading) {

                            await this.showBluetoothTurnedOffPopup(httpStatusService);
                        }
                    }
                }
            },
            cssClass: 'blue-ion-button'
        });

        if (httpStatusService.reloadingNetwork != NetworkStatus.Reloading) {

            await this.popupService.showCustomButtonsWarning('BLE_BLUETOOTH_DISABLED_TITLE', 'BLE_BLUETOOTH_DISABLED_MESSAGE', actions);
        }
        // this.bleConnectionToast = await this.showToast('BLE_DISABLED_TOAST', 10000);
    }

    getFixedUUID(text: string) {

        const uuid = getUuid(text, '6ba7b814-9dad-11d1-80b4-00c04fd430c8');
        // console.warn(text, uuid);
        return uuid;
    }
}
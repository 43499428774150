<div [ngClass]="{'greyscale': disabled, 'opacity-05' : disabled && isAnalogicErvEco2Graph}">

    <div *ngIf="!isAnalogicErvEco2Graph"
         class="d-flex flex-row justify-content-between align-items-center paddingTop-1 heading">
        <div class="day-counter-external-decrease schedule-button">
            <button *ngIf="currentDayIdx !== 0" [ngClass]="{'isWeb':isWeb}" (click)="prevDay()">&#12296;</button>
            <button *ngIf="currentDayIdx === 0" class="opacityZero">&#12296;</button>
        </div>
        <div class="d-flex flex-row justify-content-center align-items-center">
            <div>
                <label class="day-counter-label">{{currentDayLabel}}</label>
            </div>
        </div>
        <div class="day-counter-external-increase schedule-button">
            <button *ngIf="currentDayIdx !== 6" [ngClass]="{'isWeb':isWeb}" (click)="nextDay()">&#12297;</button>
            <button *ngIf="currentDayIdx === 6" class="opacityZero">&#12297;</button>
        </div>
    </div>

    <ion-item-sliding #item class="positionRelative">
        <ion-item [class]="inputClass">

            <div *ngIf="schedule?.CFG_Type === 2" class="hueBlock am1"></div>

            <div class="d-flex flex-column justify-content-center align-items-center w-100">

                <ng-container *ngIf="isAnalogicErvEco2Graph else isScheduleGraph">

                    <div class="d-flex flex-row justify-content-between align-items-center w-100">
                        <div id="chartAnalogicHRV{{id}}" class="w-100"></div>
                    </div>
                </ng-container>

                <ng-template #isScheduleGraph>
                    <div class="d-flex flex-row justify-content-between align-items-center w-100">
                        <div id="chartAM{{id}}" style="height:80px" class="w-100"></div>
                    </div>

                    <div class="d-flex flex-row justify-content-between align-items-center w-100">
                        <div id="chartPM{{id}}" style="height:80px" class="w-100"></div>
                    </div>
                </ng-template>
            </div>
        </ion-item>

        <ion-item *ngIf="!isAnalogicErvEco2Graph && (!config?.zeroConfFromGuest || schedule.CFG_Type === SchedulationType.DualVariable)">
            <div class="w-100 d-flex flex-row justify-content-center align-items-center editScheduleGraphContainer">
                <div *ngIf="!config?.zeroConfFromGuest" (click)="modifySCH(schedule)" class="editScheduleGraph cursorPointer">
                    <i class="far fa-pencil margin0 fontSizeEditScheduleGraph"></i>
                </div>

                <div [ngClass]="{'scheduleGraphHeatingSelected': PAR_DualSetpointSelection !== DUAL_MODE.cooling}" *ngIf="schedule.CFG_Type === SchedulationType.DualVariable" (click)="changeDualVariableScheduleToHeating()" class="editScheduleGraph scheduleGraphHeating cursorPointer">
                    <i class="fas fa-circle messana-orange margin0 fontSizeEditScheduleGraph"></i>
                </div>

                <div [ngClass]="{'scheduleGraphCoolingSelected': PAR_DualSetpointSelection === DUAL_MODE.cooling}" *ngIf="schedule.CFG_Type === SchedulationType.DualVariable" (click)="changeDualVariableScheduleToCooling()" class="editScheduleGraph scheduleGraphCooling cursorPointer">
                    <i class="fas fa-circle messana-blue margin0 fontSizeEditScheduleGraph"></i>
                </div>
            </div>
        </ion-item>

        <ion-item-options *ngIf="!isAnalogicErvEco2Graph && !config?.zeroConfFromGuest" side="end">
            <ion-button expand="full" color="tertiary" (click)="modifySCH(schedule)">
                <div class="d-flex flex-column justify-content-between align-items-center height-4">
                    <div>
                        <i class="fas fa-edit"></i>
                    </div>
                    <div>
                        {{ 'Edit' | translate }}
                    </div>
                </div>
            </ion-button>
        </ion-item-options>
    </ion-item-sliding>
</div>

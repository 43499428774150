export const RITTER_THERMAL_PLUS_ALARMS_TRANSLATIONS = {
  "WRONG_PHASE": "Wrong Phase",
  "MISSING_PHASE": "Missing Phase",
  "WATER_FLOW_FAILURE": "Water Flow Failure",
  "COMMUNICATION_FAILURE": "Communication Failure",
  "EMERGENCY_FAILURE": "Emergency Failure",
  "OUT_OF_USE_TIME": "Out of Use Time",
  "WATER_TANK_TEMP_SENSOR_FAILURE": "Water Tank Temp. Sensor Failure",
  "WATER_INLET_TEMP_SENSOR_FAILURE": "Water Inlet Temp. Sensor Failure",
  "INDOOR_AMBIENT_TEMP_SENSOR_FAILURE": "Indoor Ambient Temp. Sensor Failure",
  "OUTDOOR_AMBIENT_TEMP_SENSOR_FAILURE": "Outdoor Ambient Temp. Sensor Failure",
  "DHW_RETURN_WATER_TEMP_SENSOR_FAILURE": "DHW Return Water Temp. Sensor Failure",
  "WATER_OUTLET_TEMP_TOO_LOW_IN_COOLING_MODE": "Water Outlet Temp. Too Low in Cooling Mode",
  "WATER_LEVEL_SWITCH_FAILURE": "Water Level Switch Failure",
  "WATER_OUTLET_TEMP_SENSOR_FAILURE": "Water Outlet Temp. Sensor Failure",
  "WATER_OUTLET_TEMP_TOO_HIGH_IN_HEATING_MODE_LARGE_TEMP_DIFFERENCE": "Water Outlet Temp. Too High in Heating Mode Large Temp. Difference",
  "BETWEEN_WATER_INLET_AND_OUTLET": "between Water Inlet and Outlet",
  "AMBIENT_TEMP_TOO_LOW": "Ambient Temp Too Low",
  "INDOOR_AMBIENT_HUMIDITY_FAILURE": "Indoor Ambient Humidity Failure",
  "PHASE_SEQUENCE_DIP_SWITCH_ERROR": "Phase Sequence Dip Switch Error",
  "WATER_PUMP_1_FAILURE": "Water Pump 1 Failure",
  "WATER_PUMP_2_FAILURE": "Water Pump 2 Failure",
  "LOW_WATER_FLOW": "Low Water Flow",
  "PHASE_SEQUENCE_DISCONNECTED": "Phase Sequence Disconnected",
  "EXPANSION_BOARD_COMMUNICATION_FAILURE": "Expansion Board Communication Failure",
  "PLATE_HEAT_EXCHANGER_TEMP_SENSOR_FAILURE": "Plate Heat Exchanger Temp. Sensor Failure",
  "FAN_BOARD_1_COMMUNICATION_FAILURE": "Fan Board 1 Communication Failure",
  "FAN_BOARD_2_COMMUNICATION_FAILURE": "Fan Board 2 Communication Failure",
  "CASCADE_MODEL_MISMATCH": "Cascade Model Mismatch",
  "SOLAR_WATER_HEATER_TEMP_SENSOR_FAILURE": "Solar Water Heater Temp. Sensor Failure",
  "AHS_TEMP_SENSOR_FAILURE": "AHS Temp. Sensor Failure",
  "BUFFER_TANK_TEMP_SENSOR_FAILURE": "Buffer Tank Temp. Sensor Failure",
  "TOTAL_WATER_OUTLET_TEMP_SENSOR_FAILURE": "Total Water Outlet Temp. Sensor Failure",
  "ZONE_1_TEMP_SENSOR_FAILURE": "Zone 1 Temp. Sensor Failure",
  "HIGH_PRESSURE_SWITCH_FAILURE": "High Pressure Switch Failure",
  "LOW_PRESSURE_SWITCH_FAILURE": "Low Pressure Switch Failure",
  "HIGH_PRESSURE_TOO_HIGH": "High Pressure Too High",
  "HIGH_PRESSURE_TOO_LOW": "High Pressure Too Low",
  "EXHAUST_PRESSURE_TOO_HIGH": "Exhaust Pressure Too High",
  "CURRENT_PROTECTION": "Current Protection",
  "COIL_PRESSURE_TOO_HIGH": "Coil Pressure Too High",
  "COIL_TEMP_SENSOR_FAILURE": "Coil Temp. Sensor Failure",
  "SUCTION_TEMP_SENSOR_FAILURE": "Suction Temp. Sensor Failure",
  "EXHAUST_TEMP_SENSOR_FAILURE": "Exhaust Temp. Sensor Failure",
  "ECONOMIZER_INLET_TEMP_SENSOR_FAILURE": "Economizer Inlet Temp. Sensor Failure",
  "ECONOMIZER_OUTLET_TEMP_SENSOR_FAILURE": "Economizer Outlet Temp. Sensor Failure",
  "FAN_DRIVE_BOARD_COMMUNICATION_FAILURE": "Fan Drive Board Communication Failure",
  "FAN_FAILURE": "Fan Failure",
  "COOLING_COIL_TEMP_SENSOR_FAILURE": "Cooling Coil Temp. SensorFailure",
  "HIGH_PRESSURE_SENSOR_FAILURE": "High Pressure Sensor Failure",
  "LOW_PRESSURE_SENSOR_FAILURE": "Low Pressure Sensor Failure",
  "MIDDLE_PRESSURE_SWITCH_FAILURE": "Middle Pressure Switch Failure",
  "COIL_TEMP_TOO_HIGH": "Coil Temp Too High",
  "COMPRESSOR_DRIVE_BOARD_COMMUNICATION_FAILURE": "Compressor Drive Board Communication Failure",
  "IPM_OVERCURRENT_IPM_MODULE_PROTECTION": "IPM Overcurrent/IPM Module Protection",
  "COMPRESSOR_DRIVER_FAILURE": "Compressor Driver Failure",
  "COMPRESSOR_OVERCURRENT": "Compressor Overcurrent",
  "INPUT_VOLTAGE_MISSING_PHASE": "Input Voltage Missing Phase",
  "IPM_CURRENT_SAMPLING_FAILURE": "IPM Current Sampling Failure",
  "POWER_COMPONENT_OVERHEATING_AND_SHUTDOWN": "Power Component Overheating and Shutdown",
  "PRE-CHARGE_FAILURE": "Pre-charge Failure",
  "DC_BUS_OVERVOLTAGE": "DC Bus Overvoltage",
  "DC_BUS_UNDERVOLTAGE": "DC Bus Undervoltage",
  "AC_INPUT_UNDERVOLTAGE": "AC Input Undervoltage",
  "AC_INPUT_OVERVOLTAGE": "AC Input Overvoltage",
  "INPUT_VOLTAGE_SAMPLING_FAILURE": "Input Voltage Sampling Failure",
  "DSP_AND_PFC_COMMUNICATION_FAILURE": "DSP and PFC Communication Failure",
  "BOARD_RADIATOR_TEMP_SENSOR_FAILURE": "Board Radiator Temp. Sensor Failure",
  "DSP_AND_COMMUNICATE_BOARD_COMMUNICATION_FAILURE": "DSP and Communicate Board Communication Failure",
  "COMMUNICATION_FAILURE_WITH_MOTHERBOARD": "Communication Failure with Motherboard",
  "COMPRESSOR_OVERCURRENT_ALARM": "Compressor Overcurrent Alarm",
  "COMPRESSOR_WEAK_MAGNETIC_PROTECTION_ALARM": "Compressor Weak Magnetic Protection Alarm",
  "PIM_OVERHEAT_ALARM_PFC_OVERHEAT_ALARM": "PIM Overheat Alarm PFC Overheat Alarm",
  "PFC_OVERHEAT_ALARM": "PFC Overheat Alarm",
  "AC_INPUT_OVERCURRENT_ALARM": "AC Input Overcurrent Alarm",
  "EEPROM_ERROR_ALARM": "EEPROM Error Alarm",
  "EEPROM_REFRESH_COMPLETE": "EEPROM Refresh Complete",
  "TEMPERATURE_SENSING_FAILURE_LIMIT_ALARM": "Temperature Sensing Failure Limit",
  "AC_UNDERVOLTAGE_FREQUENCY_LIMIT_PROTECTION_ALARM": "AC Undervoltage Frequency Limit Protection Alarm",
  "IPM_MODULE_OVERHEAT_AND_SHUTDOWN": "IPM Module Overheat and Shutdown",
  "COMPRESSOR_MISSING_PHASE": "Compressor Missing Phase",
  "COMPRESSOR_OVERLOAD": "Compressor Overload",
  "INPUT_CURRENT_SAMPLING_FAILURE": "Input Current Sampling Failure",
  "PIM_SUPPLY_VOLTAGE_FAILURE": "PIM Supply Voltage Failure",
  "PRE-CHARGE_CIRCUIT_VOLTAGE_FAILURE": "Pre-charge Circuit Voltage Failure",
  "EEPROM_FAILURE": "EEPROM Failure",
  "AC_INPUT_OVERVOLTAGE_FAILURE": "AC Input Overvoltage Failure",
  "MICROELECTRONICS_FAILURE": "Microelectronics Failure",
  "COMPRESSOR_TYPE_CODE_FAILURE": "Compressor Type Code Failure",
  "CURRENT_SAMPLING_SIGNAL_OVERCURRENT": "Current Sampling Signal Overcurrent",
  "HIGH_PRESSURE_SWITCH_2_FAILURE": "High Pressure Switch 2 Failure",
  "LOW_PRESSURE_SWITCH_2_FAILURE": "Low Pressure Switch 2 Failure",
  "HIGH_PRESSURE_2_TOO_HIGH": "High Pressure 2 Too High",
  "HIGH_PRESSURE_2_TOO_LOW": "High Pressure 2 Too Low",
  "EXHAUST_PRESSURE_2_TOO_HIGH": "Exhaust Pressure 2 Too High",
  "CURRENT_2_PROTECTION": "Current 2 Protection",
  "COIL_2_PRESSURE_TOO_HIGH": "Coil 2 Pressure Too High",
  "COIL_2_TEMP_SENSOR_FAILURE": "Coil 2 Temp. Sensor Failure",
  "SUCTION_2_TEMP_SENSOR_FAILURE": "Suction 2 Temp. Sensor Failure",
  "EXHAUST_2_TEMP_SENSOR_FAILURE": "Exhaust 2 Temp. Sensor Failure",
  "ECONOMIZER_2_INLET_TEMP_SENSOR_FAILURE": "Economizer 2 Inlet Temp. Sensor Failure",
  "ECONOMIZER_2_OUTLET_TEMP_SENSOR_FAILURE": "Economizer 2 Outlet Temp. Sensor Failure",
  "FAN_DRIVE_2_BOARD_COMMUNICATION_FAILURE": "Fan Drive 2 Board Communication Failure",
  "FAN_2_FAILURE": "Fan 2 Failure",
  "COOLING_2_COIL_TEMP_SENSOR_FAILURE": "Cooling 2 Coil Temp Sensor Failure"
}









import { Observable,of } from 'rxjs';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';

import { TRANSLATIONS as EN_TRANSLATIONS } from './en';
import { EMA_TRANSLATIONS as EMA_EN_TRANSLATIONS } from './ema_alarms_en';
import { TRANSLATIONS as IT_TRANSLATIONS } from './it';
import { EMA_TRANSLATIONS as  EMA_IT_TRANSLATIONS} from './ema_alarms_it';
import {HIDEW_CALIBRATIONS as HIDEW_CALIBRATIONS_IT} from './hidew_calibration_it';
import {HIDEW_CALIBRATIONS as HIDEW_CALIBRATIONS_EN} from './hidew_calibration_en';

import { ALARMS_TRANSLATIONS as ALARMS_TRANSLATIONS_IT } from './alarms_it';
import { ALARMS_TRANSLATIONS as ALARMS_TRANSLATIONS_EN } from './alarms_en';

import { CENSUS_TRANSLATIONS_EN} from './census_en';
import { CENSUS_TRANSLATIONS_IT} from './census_it';

import { RITTER_THERMAL_PLUS_ALARMS_TRANSLATIONS as RITTER_THERMAL_PLUS_ALARMS_TRANSLATIONS_EN } from './ritter_thermal_plus_en';
import { RITTER_THERMAL_PLUS_ALARMS_TRANSLATIONS as RITTER_THERMAL_PLUS_ALARMS_TRANSLATIONS_IT } from './ritter_thermal_plus_it';

import { CHANGELOG_TRANSLATIONS as CHANGELOG_TRANSLATIONS_EN } from './changelog_en';
import { CHANGELOG_TRANSLATIONS as CHANGELOG_TRANSLATIONS_IT } from './changelog_it';

import {AUTH_TRANSLATIONS_EN} from './auth_en';
import {AUTH_TRANSLATIONS_IT} from './auth_it';

import * as _ from 'lodash';


const TRANSLATIONS = {
  en:  _.merge({}, AUTH_TRANSLATIONS_EN, EN_TRANSLATIONS,
    EMA_EN_TRANSLATIONS,HIDEW_CALIBRATIONS_EN,ALARMS_TRANSLATIONS_EN,RITTER_THERMAL_PLUS_ALARMS_TRANSLATIONS_EN, CENSUS_TRANSLATIONS_EN, CHANGELOG_TRANSLATIONS_EN),
  it:  _.merge({}, AUTH_TRANSLATIONS_IT, IT_TRANSLATIONS,
    EMA_IT_TRANSLATIONS,HIDEW_CALIBRATIONS_IT,ALARMS_TRANSLATIONS_IT,RITTER_THERMAL_PLUS_ALARMS_TRANSLATIONS_IT, CENSUS_TRANSLATIONS_IT , CHANGELOG_TRANSLATIONS_IT)
};

export class I18NLoader implements TranslateLoader {

    getTranslation(lang: string): Observable<any> {
        return of(TRANSLATIONS[lang] || EN_TRANSLATIONS);
    }

}


import {Component, Input, OnInit, OnChanges, Output, EventEmitter} from '@angular/core';
import {Observable, Observer, Subscription} from 'rxjs';
import * as _ from 'lodash';

import {Attributes} from '../../models/attributes';
import {Table, MCU} from '../../models/tables';
import {share, debounceTime} from 'rxjs/operators';
import {NO_TEMPERATURE} from '../../pages/schedule/commons'
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'counter',
    templateUrl: './counter.component.html',
    styleUrls: ['./counter.component.scss'],
})

export class CounterComponent implements OnInit, OnChanges {

    // table name
    @Input()
    table: string;

    // variable name
    @Input()
    var: string;

    @Input()
    on: boolean;

    @Input()
    off: boolean;

    // item
    @Input()
    item: Table;

    // itemArray lo uso per gestire un array di atu dalla pagina air-system (connectedATU)
    @Input()
    itemArray: Table[];

    @Input()
    colorLabel: boolean;

    @Input()
    noRange: boolean;

    // attributes
    @Input()
    attrs: Attributes;

    // MCU
    @Input()
    MCU: MCU;

    @Input()
    inputField: boolean;

    // if true, unit is C/F
    @Input()
    temperature: boolean;

    @Input()
    time: boolean;

    @Input()
    time_m: boolean;

    // is true, unit is %
    @Input()
    percent: boolean;

    // if used, is a custom  string as unit (example: Hz / N)
    @Input()
    custom_unit: string;

    // if true add =/- before the number
    @Input()
    differential: boolean;

    // readonly field
    @Input()
    readonly: boolean;

    // disabled
    @Input()
    inDisabled: boolean;

    // changableSetpoint even if the zone/mcz is off
    @Input()
    changableSetpoint: boolean;

    // disabled SP - used when there is a radio button
    @Input()
    disabledSP: boolean;

    // status CSS class
    @Input()
    status: string;

    // item CSS class
    @Input()
    itemClass: string;

    // label
    @Input()
    label: string;

    @Input()
    mobileLabel:string;

    // first sub label
    @Input()
    subLabel: string;

    // large/small widge
    @Input()
    large: boolean = false;

    // new schedule temp item
    //0 schedule - 1 new schedule
    @Input()
    schedule: number = -1;

    // large/small widge
    @Input()
    rtu: boolean = false;

    // min value
    @Input()
    min: number;

    // max value
    @Input()
    max: number;

    @Input()
    fad: boolean;

    // dynamic step value
    @Input()
    step: number;

    // label
    @Input()
    icon: string;

    // the timeout duration in milliseconds
    @Input()
    dueTime: number = 0;

    // receive debounce duration in milliseconds (avoid flickering)
    @Input()
    receiveDebounce: number = 1000; // Note: if you have (for example) 2 <counter> values that change when 1 is changed, overriding the "onChange" function (for example: analogic ERV with low / high speed value) -> you have to pass it as [receiveDebounce]="0" (or it will change to previous values if you click too fast)

    // function executed when the value is changed
    @Input()
    whileChange: (table: string, id: number, var_: string, value?, context?) => void;

    @Output() changeEvent: EventEmitter<any> = new EventEmitter<any>();

    // if true, add a radio button in front of the counter
    @Input()
    radio: boolean = false;

    // if true -> the radio will be hidden
    @Input()
    radioHidden: boolean = false;

    @Input()
    radioChecked: boolean;

    // function executed when the radio is clicked
    // @Input()
    //whileRadioClick: () => void;

    @Output() radioClickEmitter: EventEmitter<any> = new EventEmitter<any>();

    // current value
    @Input()
    value: number = 0;

    // current value
    @Input()
    staticValue: number = null;

    staticNull: boolean = false;

    // table name
    @Input()
    followingTable: string;

    // item
    @Input()
    followingItems: Table[];

    @Input()
    noFollowing: boolean;

    //triggerTemperature
    @Input()
    triggerTemp: number;

    @Input()
    customRange: boolean = false;

    @Input()
    customMin: number;

    @Input()
    customMax: number;

    // flowUM
    @Input()
    flowUM: boolean = false;

    // digital value
    @Input()
    digital: boolean = false;

    // digital value
    @Input()
    digitalData: string[];

    stringValue = ''

    @Input()
    auxVar: string;
    auxValue: number;

    @Input()
    auxLabel: string;

    // Doesn't refresh the HTML and doesn't perform any logic, unless a value has actually been changed (ngOnChanges) -> could be useful to reduce input lag
    @Input()
    skipOnChangesIfEqualValues: boolean;

    // Don't display a dot in the counter.html below the row: <!-- setpoint with arrow zone - macro-zone -->
    @Input()
    noDotDisplay: boolean;

    // The context Page from where it's called (typically it's "this") or any other custom data I may need later (for example: targetATU[i] of system-ars)
    @Input()
    context: any;

    @Input()
    customLightbulbIconClass: string = ''; // Used in the convective integration (ARS) of zone.page. The lightbulb icons will have min-width there, in order to match the min-width of the calendar icon

    // If you want to pass the label as HTML (it can contain tags such as <b> or <sub>) -> it overrides the Label with schedule -1 and !largw
    @Input()
    innerHtmlLabel: string;

    @Input()
    dualSetPoint: boolean;

    @Input()
    staticSeason:number;

    @Input()
    rtuClassColor: string; // When you apply a DualVariable schedule in a zone, the static numbers become colored (orange or blue)

    @Input()
    rtu_as_aligned_right_label: boolean; // When passed true, the RTU value will be aligned right instead of center (used for example in system-zone to align the temperature on right like other temperatures)

    error = false

    // observable
    private valueChange$: Observable<number>;
    // observer
    private observer: Observer<number>;
    // subscription
    private subscription: Subscription;
    // current value
    public valueInternal: number = null;
    public valueInteger: number = 0;
    public valueDecimal: number = 0;
    private internalChange: boolean = false;
    // increment/decrement
    // private step: number = 1;
    // unit (e.g. '°C' or '%')
    public unit: string = '';
    // debounced setInternal function
    private debouncedSetInternal = null;
    // used to disable debounce when change RMS page
    private oldRMSid: number = -1;

    private alreadyConvertedInDays: boolean;


    constructor(private translate: TranslateService) {
    }

    ngOnInit(): void {

        // console.warn('ngOnInit - counter.component');

        this.alreadyConvertedInDays = false;

        /* console.log('digitalData');
        console.log(this.digitalData);
        console.log('auxVar');
        console.log(this.auxVar);

        console.log(`component init var vale ${this.var} item (tabella) vale `);
        console.log(this.item);

        console.log('il digitalData che ho in ingresso vale');
        console.log(this.digitalData);

        console.log('this.item[this.var]');
        console.log(this.item[this.var]);

        console.warn("counter component, auxValue vale:");
        console.log(this.auxValue);*/

        this.valueChange$ = new Observable(observer => this.observer = observer).pipe(
            share(), // share() allows multiple subscribers
            debounceTime(this.dueTime)) as Observable<number>; // marx86:  debounceTime is fundmanetal for exception handling
            // if you don't need it set dueTime to zero

        this.subscription = this.valueChange$.subscribe({

            next: value => {
                //console.log('>> send new value ' + value);

                let fired = false;

                if (this.changeEvent) {

                    this.item[this.var] = value;

                    if (this.changeEvent.observers.length > 0) {
                        //console.warn(' (changeEvent) definito')
                        fired = true;
                    }
                    this.changeEvent.emit();
                }

                //console.log(`subscripion-valueChange whileChange:${this.whileChange}; inputField:${this.inputField} changeEvent:${this.changeEvent}`)

                if (this.whileChange) {

                    /*console.warn('[whileChange] or (changeEvent) definiti')
                       console.log('>> send new value ' + value); */

                    if (this.table && this.var && this.itemArray) {
                        // console.log(`%cSto lanciando qualcosa da counterComp riga 279`,'font-size:0.8rem;color:DarkOrchid;');

                        if (this.MCU && this.admittedArrayKeys()) {
                            let defaultValue;
                            let k = 0;
                            for (let it of this.itemArray) {
                                // if(k==0)
                                //   defaultValue=
                                // console.log(`k:${k} --  whileChange(${this.table}, ${it.id}, ${this.var}, ${value})`);
                                this.whileChange(this.table, it.id, this.var, value, this.context);
                                k++;
                            }
                        }
                    }

                    if (this.table && this.var && this.item && !this.itemArray) {

                        // console.log(`%cSto lanciando qualcosa da counterComp riga 297`,'font-size:0.8rem;color:DarkOrchid;');
                        /*this.item[this.var] = value;
                        console.log('this.item[this.var]');
                        console.log(this.item[this.var]);
                        console.log('value vale');
                        console.log(value);
                         console.error(`this.var vale ${this.var}`)
                         console.error(`this.MCU.TEC_SPMode ${this.MCU.TEC_SPMode}`)
                         console.error(`this.table ${this.table}`);
                         console.error(`this.item `);
                         console.log(this.item)*/

                        if (this.MCU && !this.MCU.TEC_SPMode) {
                            //console.log('modalità setpoint differenziati')

                            this.item[this.var] = value;


                            fired = true;
                            //console.warn(`effettuo questa chiamata 0 this.whileChange(${this.table}, ${this.item.id}, ${this.var}, ${value});`)
                            this.whileChange(this.table, this.item.id, this.var, value, this.context);
                        }

                        if (this.inputField) {
                            //console.warn(`riga 316 effettuo questa chiamata 1 this.whileChange(${this.table}, ${this.item.id}, ${this.var}, ${value});`)
                            fired = true;
                            this.whileChange(this.table, this.item.id, this.var, value, this.context);
                        }

                        if (this.MCU && this.MCU.TEC_SPMode && (this.var == "PAR_SetTempH" || this.var == "PAR_SetTempC")) {
                            //if(this.var == "PAR_SetTempH")
                            //console.log('SPMode: ON','From Heating')

                            //if(this.var == "PAR_SetTempC")
                            //console.log('SPMode: ON','From Cooling')

                            //console.log('>> send new value ' + value);
                            this.item["PAR_SetTempC"] = value;
                            this.item["PAR_SetTempH"] = value;

                            if (this.table == "RMS") {
                                /* console.log(' dentro questo if this.table == "RMS" >> send new value ' + value);
                                 console.warn(`value vale ${value}`)
                               console.warn(`effettuo questa chiamata 2 this.whileChange(${this.table}, ${this.item.id}, "PAR_SetTempC", ${value});`)
                               console.warn(`effettuo questa chiamata 2 this.whileChange(${this.table}, ${this.item.id}, "PAR_SetTempH", ${value});`) */
                                fired = true;
                                this.whileChange(this.table, this.item.id, "PAR_SetTempC", value, this.context);
                                this.whileChange(this.table, this.item.id, "PAR_SetTempH", value, this.context);
                            }
                        }

                        /*  if(this.MCU && this.MCU.TEC_SPMode && this.var == "PAR_SetTempC"){
                              console.log('SPMode: ON', 'From Cooling')
                              this.item["PAR_SetTempH"] = value;
                              if(this.table == "RMS"){
                                  console.warn(`effettuo questa chiamata 2 this.whileChange(${this.table}, ${this.item.id}, "PAR_SetTempH", ${value});`)
                                this.whileChange(this.table, this.item.id, "PAR_SetTempH", value);
                             }

                          } */

                        if (!this.changeEvent) {
                            //console.log(`%cSto lanciando qualcosa da counterComp riga 364`,'font-size:0.8rem;color:DarkOrchid;');
                            fired = true;
                            //console.warn(`effettuo questa chiamata 3 this.whileChange(${this.table}, ${this.item.id}, ${this.var}, ${value});`)
                            this.whileChange(this.table, this.item.id, this.var, ((this.var as string) == "PAR_SchSetTemp") ? value : undefined, this.context);
                        }

                        if (!this.noFollowing && this.followingTable && this.followingItems) {

                            // console.log(`%cSto lanciando qualcosa da counterComp riga 373`,'font-size:0.8rem;color:DarkOrchid;');
                            for (var i = 0; i < this.followingItems.length; i++) {
                                // console.log('FOLLOWING TABLE')
                                // console.log(this.followingItems[i])
                                this.followingItems[i][this.var] = value;
                                // console.warn(`effettuo questa chiamata 4 this.whileChange(${this.followingTable}, ${this.followingItems[i].id}, ${this.var}, ${value});`)
                                //fired=true;
                                this.whileChange(this.followingTable, this.followingItems[i].id, this.var, value, this.context);
                            }
                        }

                        if (!fired) {
                            //console.warn(`fired ${fired} emetto qui`)
                            //console.log(`%cSto lanciando qualcosa da counterComp riga 386`,'font-size:0.8rem;color:DarkOrchid;');
                            this.whileChange(this.table, this.item.id, this.var, value, this.context);
                        }

                    } else if (!this.itemArray) {
                        //console.log(`%cSto lanciando qualcosa da counterComp riga 394`,'font-size:0.8rem;color:DarkOrchid;');
                        this.whileChange(String(value), this.context, undefined, this.context, this.context);
                    }

                }
            }
        });
    }

    ngOnDestroy(): void {

        this.subscription?.unsubscribe();
        this.subscription = null;

        // console.warn('ngOnDestroy - counter.component');
    }

    updateVal(event: any) {
        /* console.warn(`update value passando`)
         console.log(event);*/
        // console.log(`%cupateVal value:${event.detail.target.value}`,'font-size:1.2rem;color:gold;');
        // console.log('1^ checkRange dentro updateval()')
        this.valueInternal = this.checkRange(event.detail.target.value);
        // console.log('2^ checkRange dentro updateval()')
        this.observer.next(this.checkRange(event.detail.target.value));
    }

    // ngOnChanges is called right after the data-bound properties have been checked if at least one of them has changed
    ngOnChanges(inputs): void {
        /*console.warn('ngOnChanges called, è cambiato:');
        console.log(inputs);*/

        if (!inputs.value && this.skipOnChangesIfEqualValues) {

            return;
        }

        var noDelay = false;

        if (inputs.triggerTemp) {
            noDelay = true
        }

        // if RMS changed - apply no delay and save new id of RMS to compare
        if (this.table == "RMS" && inputs.item && this.oldRMSid != inputs.item.currentValue.id) {
            this.oldRMSid = inputs.item.currentValue.id
            noDelay = true
        }

        if (this.internalChange) {
            this.internalChange = false;
            return;
        }

        this.error = false;
      //console.warn('ngOnChanges called, prima del try:');
        try {

            if (this.attrs && this.table && this.var && this.item || this.rtu) {

                if (this.rtu) {
                    //console.warn("rtu == true");
                    if (this.staticValue) {
                        // console.warn("staticValue == true");
                        if (this.staticValue < -1000) {
                            // console.warn("staticValue < -1000 staticNull == true");
                            this.staticNull = true;
                        } else {
                            // console.warn(" staticValue > -1000 staticNull == false");
                            this.staticNull = false;
                        }
                        // console.log(this.staticNull)
                        // console.log(this.staticValue < -1000)

                        this.min = this.max = this.value = this.staticValue;
                        this.step = 0;
                        //console.log(`static value vale ${this.staticValue}`);

                    } else {
                        this.min = this.max = this.value = null;
                        this.step = 0;
                        this.staticNull = true;

                        // if(Number.isNaN(this.staticValue))
                        // else   this.staticNull = false;
                        //
                        // this.value = this.checkRange(this.staticValue)
                    }

                } else {
                    /*console.warn("rtu == false");
                    console.warn(`attrs vale`);
                    console.log(this.attrs);
                    console.warn(`var vale ${this.var}`);
                    console.warn("table vale:");
                    console.log(this.table);*/
                    let attr = this.attrs[this.table][this.var];

                    if (!this.customRange) {
                        //console.warn(`attr vale ${attr}`);
                        if (attr.Min !== undefined && this.min == undefined) {
                            this.min = attr.Min;
                        }
                        if (attr.Max !== undefined && this.max == undefined) {
                            this.max = attr.Max;
                        }

                    } else if (this.customRange) {
                        this.min = this.customMin;
                        this.max = this.customMax;
                    }

                    if (this.step == undefined)
                        if (attr.Step !== undefined) {
                            this.step = attr.Step;
                        } else
                            this.step = 1
                    // value
                    //console.warn(`item vale ${this.item}`);
                    if ((this.item[this.var] === undefined || this.item[this.var] == null) && attr.Default !== undefined) {
                        this.item[this.var] = attr.Default
                    }

                    this.value = this.checkRange(this.item[this.var]);
                    //console.warn(`value vale ${this.value}`);
                }

                // unit
                if (this.temperature !== undefined) {
                    if (this.MCU && !this.flowUM) {
                        //this.unit = this.MCU.PAR_UM == 0 ? '°F' : '°C';
                        this.unit = '°';
                    }
                    if (this.flowUM) {
                        this.unit = this.MCU.PAR_FlowRateUM == 0 ? 'm³/h' : 'cfm';
                    }
                } else if (this.percent !== undefined) {
                    this.unit = '%';
                } else if (this.time !== undefined) {
                    this.unit = 'h';
                } else if (this.time_m !== undefined) {
                    this.unit = 'm';
                } else if (this.custom_unit !== undefined) {
                    this.unit = this.custom_unit;
                }

                if (this.differential !== undefined) {
                    this.differential = true;
                }
                if (this.readonly !== undefined) {
                    this.readonly = true;
                }
            }

        } catch (ex) {
            //console.error(`c'è sata un eccezzione e quindi inDisabled viene messo a true`);
            this.inDisabled = true
            this.min = this.max = this.value = 10;
            this.error = true
            this.step = 0;
            // console.log('Manca Valore nel db ', this.var)
        }

        //console.log(`sono dopo il catch e error vale ${this.error}`);

        if (this.digitalData) {
            // this.value = 2
            this.stringValue = this.digitalData[this.value];
        }
        if (this.auxVar) {
            this.auxValue = (this.item[this.auxVar] + this.value) || "--.-"
        }
        // console.log('<< receive value ' + this.value);
        if (this.receiveDebounce === 0 || noDelay) {
            this.setInternal();
        } else if (this.debouncedSetInternal === null) {
            this.debouncedSetInternal = _.debounce(this.setInternal, this.receiveDebounce);
            this.setInternal();
        } else {
            this.debouncedSetInternal();
        }
    }

    /**
     * Set valueInternal to value and calculate the displayed values
     */
    protected setInternal = (): void => {
        // console.log('set internal value to ' + this.value);
        this.valueInternal = this.value;
        this.calcIntegerAndDecimal();
    }

    protected checkRange(newValue: number): number {

        //console.log(`checkRange ${newValue}`);

        if (!this.noRange) {

            if (this.max != undefined && newValue > this.max) {
                // console.log(`restituisco ${this.max}`);
                return this.max;
            } else if (this.min != undefined && newValue < this.min) {
                // console.log(`restituisco ${this.min}`);
                return this.min;
            } else {
                //console.log(`restituisco ${newValue}`);
                return newValue;
            }

        } else {
            if (newValue == NO_TEMPERATURE)
                this.error = true;

            return newValue
        }

    }

    public increase(): void {


        if ((this.inDisabled || this.disabledSP) && !this.changableSetpoint) {
            return;
        }
        // console.log('checkRange dentro increase()')
        let newValue = Number(this.checkRange(Number(this.valueInternal) + Number(this.step)).toFixed(12));

        if (newValue != this.valueInternal) {
            this.internalChange = true;
            this.value = this.valueInternal = newValue;
            if (this.digitalData) {
                this.stringValue = this.digitalData[this.value];
            }
            if (this.auxVar) {
                this.auxValue = (this.item[this.auxVar] + this.value) || "--.-"
            }
            this.calcIntegerAndDecimal();
            // console.log('increase: ' + newValue);
            if (this.observer) {
                this.observer?.next(newValue);
            }
        }
    }

    public decrease(): void {
        if ((this.inDisabled || this.disabledSP) && !this.changableSetpoint) {
            return;
        }
        // console.log('checkRange dentro decrease()')
        let newValue = Number(this.checkRange(this.valueInternal - this.step).toFixed(12));

        if (newValue != this.valueInternal) {
            this.internalChange = true;
            this.value = this.valueInternal = newValue;
            if (this.digitalData) {
                this.stringValue = this.digitalData[this.value];
            }
            if (this.auxVar) {
                this.auxValue = (this.item[this.auxVar] + this.value) || "--.-"
            }

            // console.log('decrease: ' + newValue);
            this.calcIntegerAndDecimal();
            if (this.observer) {
                this.observer.next(newValue);
            }
        }
    }

    protected calcIntegerAndDecimal = (): void => {
        if (this.auxVar)
            try {
                this.valueDecimal = Math.abs(Math.round(this.auxValue * 10 % 10));
                if (this.auxValue < 0 && this.valueDecimal == 5) {
                    this.valueInteger = Math.floor(this.auxValue + 1)
                } else {
                    this.valueInteger = Math.floor(this.auxValue);
                }

            } catch (ex) {
            }
        else
            try {
                this.valueDecimal = Math.abs(Math.round(this.valueInternal * 10 % 10));
                if (this.valueInternal < 0 && this.valueDecimal == 5) {
                    this.valueInteger = Math.floor(this.valueInternal + 1)
                    //console.log(this.valueInteger)
                } else {
                    this.valueInteger = Math.floor(this.valueInternal);
                }

            } catch (ex) {
            }

        //console.log(`sono dentro calcIntegerAndDecimal() e error vale ${this.error} e il setpoint vale ${this.valueInteger}.${this.valueDecimal}`);


    }

    /* public radioClick = (): void => {
         if (this.whileRadioClick) {
             this.whileRadioClick();
         }
     } */

    public radioClick() {
        //console.warn("counter.component - radioClick(), emit()");
        this.radioClickEmitter.emit();
    }


    isInteger(number: number) {
        return Number.isInteger(number)
    }

    admittedArrayKeys(){
      return this.var == "PAR_FlowLevel" || this.var == "TEC_TargetTempH" || this.var == "TEC_TargetTempC" || this.var == "TEC_TargetTempDehum";

    }

}

<div class="d-flex flex-row justify-content-center align-items-center">

    <!-- when isAutoModeStyleDualSetpoint() is TRUE I can ignore Variable schedules (I can't assign them), and when it's FALSE I can ignore DualVariable schedules -->
    <ng-container *ngIf="!isAutoModeStyleDualSetpoint()">

        <span [ngClass]="{'exception':temperatureExceptionH || temperatureExceptionC, 'messana-disabled': !isOverride() && (sch?.CFG_Type === SchedulationType.Variable && ((rms.PAR_SchedOnC && season === 1) || (rms.PAR_SchedOnH && season === 0)))}" class="icon-targettemp"></span>

        <ng-container *ngIf="isOverride() else notOverride">

            <span class="fixed-size-number">&nbsp;{{ rms.PAR_SpoSetTemp | temperatureNoUnit }}</span>

        </ng-container>

        <ng-template #notOverride>

            <!-- See Single Setpoint -->
            <span *ngIf="sch?.CFG_Type !== SchedulationType.Variable" class="fixed-size-number" [ngClass]="{'exception':temperatureExceptionH || temperatureExceptionC}">&nbsp;{{ (season === 0 ? rms.PAR_SetTempH : rms.PAR_SetTempC) | temperatureNoUnit }}</span>

            <!-- RTU_SetPoint -->
            <!-- Variable Schedule -->
            <ng-container *ngIf="sch?.CFG_Type === SchedulationType.Variable">

            <span *ngIf="sch?.RTU_SetPoint !== 0 && !rms.PAR_SchedOnH && season === 0" class="fixed-size-number" [ngClass]="{'exception':temperatureExceptionH }">
                &nbsp;{{ rms.PAR_SetTempH | temperatureNoUnit }}
            </span>

            <span *ngIf="sch?.RTU_SetPoint !== 0 && !rms.PAR_SchedOnC && season === 1" class="fixed-size-number" [ngClass]="{'exception': temperatureExceptionC}">
            &nbsp;{{ rms.PAR_SetTempC | temperatureNoUnit }}
            </span>

            <!-- Schedule Setpoint or No Setpoint -->
            <span *ngIf="sch?.RTU_SetPoint !== 0 && rms.PAR_SchedOnH && season === 0" class="fixed-size-number messana-disabled" [ngClass]="{'exception':temperatureExceptionH }">
            &nbsp;{{ (sch?.RTU_SetPoint | temperatureNoUnit) || ' --.-  ºF' }}
            </span>

            <span *ngIf="sch?.RTU_SetPoint !== 0 && rms.PAR_SchedOnC && season === 1" class="fixed-size-number messana-disabled" [ngClass]="{'exception': temperatureExceptionC}">
            &nbsp;{{ ((sch?.RTU_SetPoint | temperatureNoUnit) || ' --.- ºF') }}
            </span>

            <!-- No Setpoint -->
            <span *ngIf="sch?.RTU_SetPoint === 0" class="fixed-size-number messana-disabled">&nbsp;{{ ' --.-  ºF' }}</span>
            </ng-container>

        </ng-template>

    </ng-container>

    <!-- See Dual Setpoint -->
    <ng-container *ngIf="isAutoModeStyleDualSetpoint()">

        <!-- icon for H -->
        <span *ngIf="!rms.autoModeRmsModeNotPresent(0)" [ngClass]="{'exception': temperatureExceptionH, 'messana-disabled-important': !isOverrideDualSetpointH() && (!rms.PAR_State || (sch?.CFG_Type === SchedulationType.DualVariable && rms.PAR_SchedOnH))}" class="icon-targettemp messana-orange"></span>

        <ng-container *ngIf="isOverrideDualSetpointH() else notOverrideH">

            <span class="fixed-size-number">&nbsp;{{ rms.PAR_SpoSetTempH | temperatureNoUnit }}</span>
        </ng-container>

        <ng-template #notOverrideH>

            <ng-container *ngIf="!rms.autoModeRmsModeNotPresent(0)">

                <!-- PAR_SetTempH --->
                <span *ngIf="sch?.CFG_Type !== SchedulationType.DualVariable" class="fixed-size-number" [ngClass]="{'exception':temperatureExceptionH}">&nbsp;{{ rms.PAR_SetTempH | temperatureNoUnit }}&nbsp;</span>

                <!-- RTU_SetPointH (DualVariable) -->
                <ng-container *ngIf="sch?.CFG_Type === SchedulationType.DualVariable">

                    <span *ngIf="sch?.RTU_SetPointH !== 0 && !rms.PAR_SchedOnH" class="fixed-size-number" [ngClass]="{'exception':temperatureExceptionH }">
                        &nbsp;{{ rms.PAR_SetTempH | temperatureNoUnit }}
                    </span>

                    <!-- Schedule Setpoint H or No Setpoint -->
                    <span *ngIf="sch?.RTU_SetPointH !== 0 && rms.PAR_SchedOnH" class="fixed-size-number messana-disabled" [ngClass]="{'exception':temperatureExceptionH }">
                        &nbsp;{{ (sch?.RTU_SetPointH | temperatureNoUnit) || ' --.-  ºF' }}
                    </span>

                    <!-- No Setpoint H -->
                    <span *ngIf="sch?.RTU_SetPointH === 0" class="fixed-size-number messana-disabled">&nbsp;{{ ' --.-  ºF' }}</span>

                </ng-container>

            </ng-container>
        </ng-template>

        <span *ngIf="!rms.autoModeRmsModeNotPresent(0) && !rms.autoModeRmsModeNotPresent(1)" style="padding-left:1rem;padding-right:1rem;"></span>

        <!-- icon for C -->
        <span *ngIf="!rms.autoModeRmsModeNotPresent(1)" [ngClass]="{'exception': temperatureExceptionC, 'messana-disabled-important':  !isOverrideDualSetpointC() && (!rms.PAR_State || (sch?.CFG_Type === SchedulationType.DualVariable && rms.PAR_SchedOnC))}" class="icon-targettemp messana-blue"></span>

        <ng-container *ngIf="isOverrideDualSetpointC() else notOverrideC">

            <span class="fixed-size-number">&nbsp;{{ rms.PAR_SpoSetTempC | temperatureNoUnit }}</span>
        </ng-container>

        <ng-template #notOverrideC>

            <ng-container *ngIf="!rms.autoModeRmsModeNotPresent(1)">

                <!-- PAR_SetTempC --->
                <span *ngIf="sch?.CFG_Type !== SchedulationType.DualVariable" class="fixed-size-number" [ngClass]="{'exception': temperatureExceptionC}">&nbsp;{{ rms.PAR_SetTempC | temperatureNoUnit }}</span>

                <!-- RTU_SetPointC (DualVariable) -->
                <ng-container *ngIf="sch?.CFG_Type === SchedulationType.DualVariable">

                    <span *ngIf="sch?.RTU_SetPointC !== 0 && !rms.PAR_SchedOnC" class="fixed-size-number" [ngClass]="{'exception': temperatureExceptionC}">
                        &nbsp;{{ rms.PAR_SetTempC | temperatureNoUnit }}
                    </span>

                    <!-- Schedule Setpoint or No Setpoint -->
                    <span *ngIf="sch?.RTU_SetPointC !== 0 && rms.PAR_SchedOnC" class="fixed-size-number messana-disabled" [ngClass]="{'exception': temperatureExceptionC}">
                        &nbsp;{{ (sch?.RTU_SetPointC | temperatureNoUnit) || ' --.-  ºF' }}
                    </span>

                    <!-- No Setpoint C -->
                    <span *ngIf="sch?.RTU_SetPointC === 0" class="fixed-size-number messana-disabled">&nbsp;{{ ' --.-  ºF' }}</span>
                </ng-container>
            </ng-container>

        </ng-template>

    </ng-container>
</div>

<ion-item [ngClass]="{'disabled-item':disabled}"
          class="tech-mode collapse item d-flex flex-row justify-contnet-between align-items-center {{itemClass}}"
          style="display:block">

    <ion-label class="d-flex align-items-center {{labelClass}}">
        <i class="fa" [ngClass]="{'fa-caret-down':_checked,'fa-caret-right':!_checked}" aria-hidden="true"></i>
        <i *ngIf="iconBeforeLabel" class="icon {{iconBeforeLabel}}" aria-hidden="true"></i>
        {{ label }}
    </ion-label>

    <div class="d-flex align-items-center" *ngIf="toggleProperties">

        <ion-toggle color="secondary" [checked]="toggleProperties.checked"
                    (click)="preventCollapse($event)"
                    (ionChange)="onToggleChange($event)"
                    [disabled]="toggleProperties.disabled"></ion-toggle>
    </div>

    <!--<ion-label *ngIf="checked">_checked vale true</ion-label>
    <ion-label *ngIf="!checked">_checked vale false</ion-label>-->
    <div class="collapse">
        <div class="checkbox-icon" [class.checkbox-checked]="_checked">
            <div class="checkbox-inner"></div>
        </div>
    </div>

    <ion-label class="text-right" *ngIf="dateString && dateString !=''">
        <i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;
        {{dateString}}
    </ion-label>

    <div *ngIf="showIcon" class="icons"><span class="{{icon}}"></span></div>

</ion-item>
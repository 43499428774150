<!-- rounded container -->
<div class="rounded-container" [ngClass]="{'opened':fwaVisibility || !extensible}">

    <ion-list-header class=" bg-header text-black positionRelative">

        <div *ngIf="extensible" class="positionAbsolute timer-arrow cursorPointer">
            <i (click)="show()" class="far" [ngClass]="{'fa-chevron-up':fwaVisibility,'fa-chevron-down':!fwaVisibility}"></i>
        </div>

        <div class="d-flex flex-row justify-content-between align-items-center w-100">

            <div class="d-flex flex-row justify-content-start align-items-center">

                <div class="d-flex flex-row align-items-center">
                    <span [ngClass]="fwa.RTU_ThermOn ? 'messana-orange' : 'messana-disabled'" class="far fa-sun-haze icon" item-left></span>
                </div>

                <div class="d-flex flex-row align-items-center">
                    <ion-label class="text-black ars-title">
                        <div class="title-tmr">
                            {{ fwa.CFG_Name }}
                        </div>

                    </ion-label>
                </div>
            </div>

            <div class="d-flex flex-row justify-content-end align-items-center">
                <ion-item lines="none">
                    <ion-toggle color="secondary" [disabled]="false" [checked]="fwaState"
                                (ionChange)="updateFwaState($event)"></ion-toggle>
                </ion-item>
            </div>
        </div>
    </ion-list-header>

    <div *ngIf="fwaVisibility || !extensible">

        <div class="tech-mode">
            <tech [showPowerOffIcon]="true" [MCU]="MCU" [config]="config" [FWA]="[fwa]" [nodata]="true"></tech>
        </div>

        <ion-item class="no-lines">
            <ion-label class="my-0">{{ 'FLOOR_TEMPERATURE' | translate }}</ion-label>
            <ion-label class="right-align my-0">{{ fwa?.RTU_CalibratedTemp | temperature }}</ion-label>
        </ion-item>

        <!-- A variable schedule is active (use RTU) -->
        <ng-container *ngIf="SCH[fwa.PAR_IdxSCH] && SCH[fwa.PAR_IdxSCH].CFG_Type === SchedulationType.Variable && fwa.PAR_SchedOn else noVariableScheduleOn">

            <counter itemClass="no-lines padding-right-counter" temperature
                     [changableSetpoint]="true" [rtu]="true" var="RTU_SetTemp" [staticValue]="fwa.RTU_SetTemp"
                     table="FWA" [item]="fwa" [attrs]="attrs" [MCU]="MCU"
                     label="{{ 'SETPOINT' | translate }}"></counter>
        </ng-container>

        <!-- Use setpoint (no variable schedule active) -->
        <ng-template #noVariableScheduleOn>

            <counter itemClass="no-lines padding-right-counter" temperature [changableSetpoint]="true"
                     table="FWA" [item]="fwa" var="PAR_SetTemp"
                     label="{{ 'SETPOINT' | translate }}" [attrs]="attrs"
                     [MCU]="MCU" [whileChange]="context.onChange"></counter>
        </ng-template>

        <schedule [usePopupIsOpenedCheck]="true" [class]="fwaScheduleStyle(fwa)"
                  [ngClass]="{'narrow': fwa.PAR_IdxSCH !== -1}" [disabled]="!fwa.PAR_On" table="FWA" [item]="fwa"
                  toggle="PAR_SchedOn" idxVar="PAR_IdxSCH" [MCU]="MCU" [SCH]="SCH" itemClass="indent-half">
        </schedule>

        <schedule-graph [currentDateTime]="currentDateTime" [config]="config"
                        *ngIf="currentDateTime.dateTimeAsMoment && fwa.PAR_SchedOn && fwa.PAR_IdxSCH !== -1"
                        [schedule]="SCH[fwa.PAR_IdxSCH]"
                        [id]="1+'fwa'"></schedule-graph>
    </div>
</div>

import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {Platform, AlertController, ActionSheetController, NavParams} from '@ionic/angular';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';

import {Router, ActivatedRoute, Params, RouterEvent, NavigationEnd} from '@angular/router';

import {AppState} from '../../services/app-state.service';
import {AbstractBaseComponent} from '../abstract-base-component';
import {Config} from '../../models/config';
import {TablesActions, ChangeValuesRequest} from '../../actions/tables.actions';
import {MCU, ODS} from '../../models/tables';
import {WeatherService} from '../../services/weather.service';
import {Weather} from '../../models/weather';
import {Subscription, timer} from 'rxjs';

import * as _ from 'lodash';
import * as moment from 'moment';


@Component({
    selector: 'weather',
    templateUrl: './weather.component.html',
    styleUrls: ['./weather.component.scss'],
})
export class WeatherComponent extends AbstractBaseComponent implements OnChanges {

    @Input()
    config: Config;

    @Input()
    mcu: MCU;

    @Input()
    ODS: ODS[];

    @Input()
    dataLoaded: boolean

    today;

    //lo sposto nel servizio
    // weather: Weather;

    dewPoint: number;
    dewPointCalc: number;
    place: string;

    noWeatherIcon = 'wi-cloud-refresh'
    errorWeather = 'wi-na'

    oldKey: string = '';
    tmp: any;

    /*   tables: Observable<Tables>;
      temperatureUnit: string = '';

      tempSub:Subscription; */

    private requestList = [];

    constructor(
        public router: Router,
        protected store: Store<AppState>,
        protected platform: Platform,
        protected alertController: AlertController,
        protected actionSheetController: ActionSheetController,
        protected translate: TranslateService,
        public weatherService: WeatherService) {
        super(store, platform, alertController, actionSheetController, translate);

        this.today = Date.now();
    }

    /**
     *
     */
    get defaultTemp(): Boolean {
        return this.ODS[0].RTU_Temp == -3276.8;
    }

    /**
     *
     */
    get defaultHum(): Boolean {
        return this.ODS[0].RTU_Hum == -3276.8;
    }

    calculateDP(t, rh): void {
        //console.warn('___ sono la calculateDP() _____ ODS vale:');
        //console.log(ODS[0]);

        if (this.ODS[0] != undefined) {
            this.dewPoint = this.ODS[0].RTU_DewPoint
        }
    }

    isLoading(previousWeatherCondition = true) {

        if (this.weatherService.previousWeather && previousWeatherCondition) {

            return false;
        }

        else {

            return this.weatherService.isLoading(previousWeatherCondition);
        }
    }

    isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    ngOnChanges(): void {

        this.updateWeatherData();
    }

    manageTimeout(err?) {

        // if(err) // console.log(err);

        if (this.weatherService.getStatus()) {
            // console.log('getStatus == true this.weatherService.weather vale')
            // console.log(this.weatherService.weather);
            this.weatherService.timeoutRequest = false
            // console.log(`ho messo timeoutRequest a ${this.weatherService.timeoutRequest} perchè weather non è vuoto`)
        } else {


            this.weatherService.timeoutRequest = true
            // console.log(`ho messo timeoutRequest a ${this.weatherService.timeoutRequest} perchè weather è vuoto`)

            //console.debug('weather timeout')
            //console.error('empty weather')
            this.weatherService.weather = {} as Weather


            //console.log('viene chiamata la resetKey')
            //this.weatherService.resetKey();


            //  //console.log(`this.checkLocationKey ${this.checkLocationKey} noValidLocationKey ${this.noValidLocationKey} timeoutRequest ${this.timeoutRequest}`)
            //console.log('weather vale ');
            //console.log(this.weatherService.weather);
        }
    }

    updateWeatherData() {

        if (this.dataLoaded && this.mcu) {

            if (this.dataLoaded && !this.weatherService.keyIsPresent && this.isNumeric(this.oldKey) && this.isNumeric(this.mcu.PAR_AccuWeatherKey) && parseInt(this.oldKey) == parseInt(this.mcu.PAR_AccuWeatherKey)) {

                this.weatherService.reset();
            }

            //in caso di cambio di chiave devo ricaricare il tutto
            if (this.dataLoaded && !this.weatherService.keyIsPresent && this.isNumeric(this.oldKey) && this.isNumeric(this.mcu.PAR_AccuWeatherKey) && parseInt(this.oldKey) != parseInt(this.mcu.PAR_AccuWeatherKey)) {
                this.weatherService.reset();
            }

            //la vecchia non c'era e ora c'è
            if (!this.weatherService.keyIsPresent && this.oldKey == '' && this.mcu.PAR_AccuWeatherKey != '' && this.dataLoaded) {
                //console.warn("sono su ramo LA VECCHIA CHIAVE NON C'ERA E ORA HO UNA CHIAVE")
                this.weatherService.reset();
            }

            //la vecchia c'era e ora è vuota
            if (this.weatherService.keyIsPresent && this.mcu.PAR_AccuWeatherKey == '' && this.dataLoaded) {
                //console.warn("sono sul ramo LA VECCHIA CHIAVE C'ERA E ORA E' VUOTA")
                this.weatherService.reset();

            }

            if (!this.weatherService.keyIsPresent && this.dataLoaded) {
                //questa parte di gesione del timeout serve a gestire l'assenza di chiave nella centrlina
                //nel momento che ho una chiave questo timeout non ha più senso
                //console.log(`la chiave non c'è, riga 245, chiamo resettimeout`)
                //this.weatherService.resetTimeout()
                //console.error("il timeout è stato riattivato, scade tra 10 secondi")
                this.weatherService.weatherTimeout = setTimeout(() => {

                    this.manageTimeout("il timeout è scaduto");

                }, 12000)
            }

            // Get the weather for the given location key

            //console.warn("prima dell'if")

            //qui gestisco la presenza della chiave e gestisco un timeout differente
            if (this.mcu.PAR_AccuWeatherKey !== "" && !this.weatherService.keyIsPresent) {
                this.weatherService.keyIsPresent = true;
                // console.log(`la chiave su this.mcu.PAR_AccuWeatherKey vale ${this.mcu.PAR_AccuWeatherKey} e this.weatherService.keyIsPresent vale ${this.weatherService.keyIsPresent} `)
                // console.log('quindi richiamo resetTimeout')
                this.weatherService.resetTimeout()
                //console.log("ora ho una chiave valida")

                if (this.dataLoaded) {

                    var ws = this.weatherService.getWeather(this.mcu.PAR_AccuWeatherKey, this.mcu.PAR_UM == 0 ? 'F' : 'C').subscribe(
                        data => {

                            // console.log(`dopo la weatherService.getWeather chiamo resettimeout e uso chiave ${this.mcu.PAR_AccuWeatherKey}` )
                            this.weatherService.resetTimeout()
                            // console.log('ASSEGNAZIONE weather')
                            // console.log(data);
                            this.weatherService.weather = data as Weather;

                            if (_.isEmpty(this.weatherService.weather)) {
                                //console.error('no weather')
                                this.weatherService.resetKey();
                                this.weatherService.showErrors();
                            } else {
                                this.calculateDP(this.weatherService.weather.temperature, this.weatherService.weather.humidity)
                                this.weatherService.validateKey();
                                this.tmp = Object.assign({}, this.mcu.PAR_AccuWeatherKey);
                                // this.oldKey.join();
                                this.oldKey = '';
                                this.weatherService.enable();

                                Object.keys(this.tmp).forEach(item => {

                                    this.oldKey = this.oldKey + this.tmp[item];
                                });

                                this.weatherService.previousWeather = _.cloneDeep(this.weatherService.weather);
                                this.weatherService.lastUpdate = moment();
                            }
                        },
                        err => this.manageTimeout(err)
                    );

                } else {

                    timer(2500).subscribe(() => {
                        // console.log('sono passati secondi e richiamo updateWeatherData()');
                        this.updateWeatherData();
                    });

                    this.weatherService.resetTimeout();
                }
            }
        }
    }

    getWeatherObject() {

        return this.weatherService.weather ? this.weatherService.weather : this.weatherService.previousWeather;
    }

    ngOnDestroy() {
        //console.error("distruggo la componente weather!");
    }

    isEmpty(obj) {
        return _.isEmpty(obj);
    }

    //Unused?
    showSettingsOnOff(): void {

        this.router.navigateByUrl('/settings-on-off')
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {ZonePage} from "../../pages/zone/zone.page";
import {MCZ_Extended, RMS_Extended} from "../../models/tables-extended";
import {DUAL_MODE} from "../../models/config";
import {MacroZonePage} from "../../pages/macro-zone/macro-zone.page";

@Component({
    selector: 'show-zone-override',
    templateUrl: './show-zone-override.component.html',
    styleUrls: ['./show-zone-override.component.scss'],
})
export class ShowZoneOverrideComponent implements OnInit {

    @Input()
    rmsOrMczExtended: RMS_Extended | MCZ_Extended;

    @Input()
    page: ZonePage | MacroZonePage;

    @Input()
    dualSetpointMode: DUAL_MODE; // 0: heating override (dual setpoint), 1: cooling override (dual setpoint), null / undefined: normal override without dual setpoint.

    @Input()
    state: boolean;

    @Input()
    fromMacrozonePage: boolean;

    public otherDualSetpointModeIsInOverride = false;

    constructor() {
    }

    ngOnInit() {

        this.updateData();
    }

    ngOnChanges() {

        this.updateData();
    }

    updateData() {

        switch (this.dualSetpointMode) {

            case DUAL_MODE.heating:

                this.otherDualSetpointModeIsInOverride = this.rmsOrMczExtended.getSetpointOverrideInfo(null, DUAL_MODE.cooling).isOverride;
                break;

            case DUAL_MODE.cooling:

                this.otherDualSetpointModeIsInOverride = this.rmsOrMczExtended.getSetpointOverrideInfo(null, DUAL_MODE.heating).isOverride;
                break;
        }
    }
}

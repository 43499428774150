import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from "@angular/common";
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';



import {NoScheduleComponent} from './no-schedule.component';


@NgModule({
	imports: [CommonModule,TranslateModule,FormsModule],
	declarations:[NoScheduleComponent],
	exports:[NoScheduleComponent],
	schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class NoScheduleModule{}
<ion-item class="no-lines" *ngIf="show" >
                    
  <div *ngIf="entity.length == 0" class="d-flex flex-row align-items-center w-100" [ngClass]="{'justify-content-start':showAll,'justify-content-end':!showAll}">
              <div *ngIf="showAll" class="d-flex flex-row justify-content-start align-items-start align-items-center minHeight-served-zone">
                   <span [class]="iconInput"></span>
              </div>
              <div class="d-flex flex-row  align-items-center minHeight-served-zone" [ngClass]="{'justify-content-start':showAll,'justify-content-end':!showAll}">
                 {{ noLabel | translate }}
              </div>
          </div>



  <div *ngIf="entity.length == 1" class="d-flex flex-row justify-content-start align-items-center w-100">
              <div *ngIf="showAll" class="d-flex flex-row justify-content-start align-items-center minHeight-served-zone">
                   <span [class]="iconInput"></span>
              </div>
              <div class="d-flex flex-row  align-items-center minHeight-served-zone w-100" [ngClass]="{'justify-content-between':showAll,'justify-content-end':!showAll}">
                 <div *ngIf="showAll">{{ singleLabel | translate }}</div> <div><span class="list-wrapper"> {{ entity[0].CFG_Name}}
              </span></div></div>
          </div>





<!-- desktop tablet-->
  <div *ngIf="entity.length > 1" class="d-sm-flex flex-row  align-items-start d-none w-100" [ngClass]="{'justify-content-between':showAll,'justify-content-end':!showAll}">
     

       <div *ngIf="showAll" class="d-flex flex-row justify-content-start align-items-start">
              <div class="d-flex flex-row justify-content-start align-items-center minHeight-served-zone">
                   <span [class]="iconInput"></span>
              </div>
              <div class="d-flex flex-row justify-content-start align-items-center minHeight-served-zone">
                 {{pluralLabel | translate}}
              </div>
          </div>

          <!-- roomsShow[i]  && -->
  <ion-list class="list-folder " [ngClass]="{'d-flex flex-row':entity.length<=3}" *ngIf=" entity.length > 1" lines="none">




      <div class="list-wrapper minHeight-served-zone" [ngClass]="{'d-flex flex-row align-items-center':entity.length<=3}" *ngFor="let room of entity;let j = index;let lastElem=last;">
     
          <span *ngIf="room">{{ room.CFG_Name}}</span>
      <span *ngIf="!lastElem && j%3!=2">,&nbsp;</span><br *ngIf="j%3==2 || lastElem">
   </div>
  </ion-list>
  </div><!-- chiuso il flex -->

   <!-- mobile -->
  <div *ngIf="entity.length > 1" class="d-flex flex-column justify-content-center align-items-start d-sm-none w-100">
        <div *ngIf="showAll" class="d-flex flex-row justify-content-start align-items-start">
              <div class="d-flex flex-row justify-content-start align-items-center minHeight-served-zone">
                   <span [class]="iconInput"></span>
              </div>
              <div class="d-flex flex-row justify-content-start align-items-center minHeight-served-zone">
                 {{pluralLabel | translate}}
              </div>
          </div>


          <!--ho tolto da qui sotto [ngClass]="{'d-flex flex-row ':entity.length<=2}" -->
  <ion-list class="list-folder w-100 "   *ngIf=" entity.length > 1" lines="none">



<!-- ho tolto da qui sotto [ngClass]="{'d-flex flex-row align-items-center':entity.length<=2}" -->
      <div class="list-wrapper minHeight-served-zone"  *ngFor="let room of entity;let j = index;let lastElem=last;">
     
          <span *ngIf="room">{{ room.CFG_Name}}</span>
      <span *ngIf="!lastElem && j%2!=1">,&nbsp;</span><br *ngIf="j%2==1 || lastElem">
   </div>
  </ion-list>
  </div><!-- chiuso il flex -->




</ion-item>
<!--<ion-backdrop></ion-backdrop>-->
<div class="alert-wrapper newMacrozoneModal">
    <div class="alert-head">
        <h2 class="alert-title" *ngIf="title" [innerHTML]="title"></h2>
    </div>

    <div class="alert-message" [innerHTML]="message"></div>

    <div *ngIf="placeholderEmail" class="alert-input-group">
        <div class="alert-input-wrapper">
            <ion-input [placeholder]="placeholderEmail" [ngModelOptions]="{standalone: true}" [(ngModel)]="email" ngDefaultControl type="text" class="alert-input"></ion-input>
        </div>
    </div>

    <div class="alert-input-group">
        <div class="alert-input-wrapper">
            <ion-input [placeholder]="placeholder" [ngModelOptions]="{standalone: true}" [(ngModel)]="name" ngDefaultControl type="text" class="alert-input"></ion-input>
        </div>
    </div>

    <ion-list class="border">
        <ion-radio-group>

            <ion-item *ngFor="let grp of GRP">
                <ion-label>{{ grp.CFG_Name }}</ion-label>
                <ion-radio slot="start" (click)="changeType(grp)" [ngModelOptions]="{standalone: true}" ngDefaultControl [checked]="groupId == grp.id"></ion-radio>
            </ion-item>

        </ion-radio-group>
    </ion-list>

    <div class="alert-button-group">

        <button category="alert-button" *ngFor="let b of buttons" (click)="btnClick(b)" class="alert-button">
            {{b.text}}
        </button>
    </div>
</div>
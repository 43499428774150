export const HIDEW_CALIBRATIONS = {
    "CALIBRATION":"Calibration",
    "PAHSE_ONE":"Phase 1",
    "PAHSE_TWO":"Phase 2",
    "PAHSE_THREE":"Phase 3",
    "SUPPLY_FAN":"Supply fan",
    "SUPPLY_FAN_TABLE":"Supply fan&nbsp;%",
    "RETURN_FAN":"Extraction fan",
    "RETURN_FAN_TABLE":"Extraction fan&nbsp;%",
    "RECIRCULATION_DUMPER":"Recirculation damper",
    "RECIRCULATION_DUMPER_TABLE":"Recirculation damper&nbsp;%",
    "REC_DUMPER":"Rec. damper",
    "REC_DUMPER_TABLE":"Rec. damper&nbsp;%",
    "PAST_CALIBRATIONS":"Past calibrations",
    "HIDEW_ATU_CALIBRATION":"Calibrazione HiDew RER - REV",
    "START_CALIBRATION":"Start calibration",
    "CALIBRATION_PHASE_ONE":"Only recirculation - phase 1 - DEUM",
    "CALIBRATION_PHASE_TWO":"Only fresh air - phase 2 - HRV",
    "CALIBRATION_PHASE_THREE":"Recirculation + fresh air - phase 3 - DEUM+HRV",
    "CAL_DESC_1":"The ATU are small air handling units which recirculate the ambient air and exchange it with the external one. Every installation is different from the others, so it is fundamental to measure and correct the airflows, according to the real functioning conditions. As standard, the units are programmed to offer a \"fix\" pressure but, almost certainly, this will not be the exact one for the specific installation; so, it is really important to correct the airflows according to the different functioning conditions:",
    "CAL_F1":"Only recirculation (phase 1)",
    "CAL_F2":"Only fresh air (phase 2)",
    "CAL_F3":"Recirculation + fresh air (phase 3)",
    "CAL_DESC_2":"If the calibration will not be done or not correctly done, the ATU unit will have airflows, different from the project, and the heat recovery will lose efficiency.",
    "CAL_DESC_3":"The non-calibrated or incorrectly calibrated unit, is excluded from the warranty.",
    "CALIBRATION_SESSION_CLOSED_BY_ANOTHER_USER":"The calibration session has been closed by another user or has expired.",
    "CURRENT_CALIBRATION":"Current calibration",
    "PREVIOUS_CALIBRATIONS":"Previous calibrations",
    "PREP_DESC_1":" Preparation for calibration",
    "PREP_STEP_1":"Drill a Hole, on Duct 2-3 and 4, sufficient for the passage of the anemometer, see dedicate paragraph on ATU user manual (10.6 FIRST STARTING, CALIBRATION AND CONFIGURATION).",
    "PREP_STEP_2":"Holes must be done in a middle of a straight section of duct, not close to a curve or too close to the unit attachments.",
    "ISTR_PHASE_1_TITLE":"Phase 1 - airflow measurement",
    "POINT_P1_1":"Insert the anemometer on duct 4 (recirculation), see dedicate paragraph on ATU user manual (10.6 FIRST STARTING, CALIBRATION AND CONFIGURATION). Airflow measured must correspond to the table.",
    "POINT_P1_2":"If the anemometer reads a different airflow, with keys LEFT or RIGHT modify the speed of the fan until you find the correct airflow.",
    "SIZE":"Size",
    "NOMINAL":"Nominal airflow (CFM)",
    "ISTR_PHASE_2_TITLE":"Phase 2 - airflow measurement",
    "POINT_P2_1":"Insert the anemometer in duct 2 (external air inlet), see dedicate paragraph on ATU user manual (10.6 FIRST STARTING, CALIBRATION AND CONFIGURATION). Airflow measured must correspond to the table. ",
    "POINT_P2_2":"On Supply Fan section, press keys LEFT or RIGHT to modify the values and consequently the speed of the supply fan, until you reach the correct value.",
    "POINT_P2_3":"Move the anemometer in duct 3 (exhaust ambient air inlet), see dedicate paragraph on ATU user manual (10.6 FIRST STARTING, CALIBRATION AND CONFIGURATION). Airflow measured must correspond to the table (refer to the table on point 1). ",
    "POINT_P2_4":"On Extraction Fan section, press kays LEFT or RIGHT to modify the values and consequently the speed of the extraction fan, until you reach the correct value. ",
    "P2_ATTENTION_START":"Attention:",
    "P2_ATTENTION":"during phase 2 of calibration, it is fundamental that the airflows of duct 2 and duct 3 are the same, otherwise the airflows will not be balanced. This leads to an incorrect functioning of the ATU H /ATU V unit and to a lower efficiency of the heat recovery.",
    "ISTR_PHASE_3_TITLE":"Phase 3 - airflow measurement",
    "POINT_P3_1":"Insert the anemometer in duct 2 (external air inlet), see dedicate paragraph on ATU user manual (10.6 FIRST STARTING, CALIBRATION AND CONFIGURATION). Airflow measured must correspond to the table.",
    "POINT_P3_2":"Successively, measure the airflow of duct 4 (recirculation air ambient inlet), airflow measured must correspond to the table (refer to the table on point 1). ",    
    "POINT_P3_3":"To reach these airflow values modify alternatively, using keys LEFT or RIGHT, the 2 parameters (Supply Fan and Recirculation Dumper). ",
    "P3_NOTES":"Notes for regolations: ",
    "P3_N_L1":"Increasing the % of the supply fan, the sum of the flows of duct 2 and 4 increases and vice versa. ",
    "P3_N_L2":"Increasing the % opening of the recirculation damper, flow on the duct 2 is reduced and flow on the duct 4 is increased and vice versa. ",
    "P3_WARN_START":"Attention:",
    "P3_WARN":"the damper moves slowly; once you have modified the opening, wait some seconds for its correct positioning.",
    "P3_FINAL":"At the end of calibration phase 3, you should have obtained 2 equal values for airflows both for duct 2 and for duct 4. Correct values for the sum duct 2 + duct 4",
    "BACK_TO_START_PAGE":"Start page",
    "GO_TO_PHASE_TWO":"Phase 2",
    "BACK_TO_PHASE_ONE":"Phase 1",
    "GO_TO_PHASE_THREE":"Phase 3",
    "BACK_TO_PHASE_TWO":"Phase 2" 
    
};
import {SCH} from '../models/tables';
import {SchedulationType, Season} from '../models/config';
import {BitArray} from './bitarray';

export enum Apply {
    ARS = 0,
    DHW = 1,
    ENG = 2,
    GRP = 3,
    MCZ = 4,
    PMP = 5,
    RMS = 6,
    TMR = 7,
    FNC = 8
}

export const APPLY_TO = [
    'ARS', // bit 0
    'DHW', // bit 1
    'ENG', // bit 2
    'GRP', // bit 3
    'MCZ', // bit 4
    'PMP', // bit 5
    'RMS', // bit 6
    'TMR', // bit 7
    'FNC',  // bit 8
    'FWA' // bit 9
];

export const APPLY_TO_LABELS = [
    'TYPE_ARS', // bit 0
    'TYPE_DHW', // bit 1
    'TYPE_ENR', // bit 2
    'TYPE_GRP', // bit 3
    'TYPE_MCZ', // bit 4
    'TYPE_PMP', // bit 5
    'TYPE_RMS', // bit 6
    'TYPE_TMR', // bit 7
    'TYPE_FNC', // bit 8
    'TYPE_FWA', // bit 9
];

/**
 * Return true if the schedule is applicable to a given table
 */
export function isApplicableTo(sch: SCH, table: string, var_: string, season?: Season, isAutoModeStyleDualSetpoint?): boolean {

    if (!sch.CFG_Valid) {

        return false;
    }

    if (table == 'GRP' && var_ == 'PAR_IdxSCH') { // season schedulation

        return sch.CFG_Type == SchedulationType.Season;
    }

    if (sch.CFG_Type == SchedulationType.Variable) { // variable schedulation

        // I can always assign a variable schedule (applied to heating) to a Floor Warmer
        if (table === 'FWA' && (sch.PAR_Season === 1 || sch.PAR_Season === 3)) {

            return true;
        }

        if (isAutoModeStyleDualSetpoint) {

            return false;
        }

        if ((table == 'RMS' && (var_ == 'PAR_IdxSCH_C' || var_ == 'PAR_IdxSCH_H')) ||
            (table == 'MCZ' && (var_ == 'PAR_IdxSCH_C' || var_ == 'PAR_IdxSCH_H'))) {

            // check the season
            if (season === null || season === undefined) {

                return true;
            }

            else if (season == Season.Heating && (sch.PAR_Season == 1 || sch.PAR_Season == 3)) { // heating or heating/cooling

                return true;
            }

            else if (season == Season.Cooling && (sch.PAR_Season == 2 || sch.PAR_Season == 3)) { // cooling or hearing/cooling

                return true;
            }
        }

        return false;
    }

    if (sch.CFG_Type == SchedulationType.DualVariable) { // dual variable schedulation

        if (!isAutoModeStyleDualSetpoint) {

            return false;
        }

        if ((table == 'RMS' && (var_ == 'PAR_IdxSCH_C' || var_ == 'PAR_IdxSCH_H')) ||
            (table == 'MCZ' && (var_ == 'PAR_IdxSCH_C' || var_ == 'PAR_IdxSCH_H'))) {

            // check the season
            if (season === null || season === undefined) {

                return true;
            }

            else if ((season == Season.Heating || season == Season.Cooling) && sch.PAR_Season == 3) { // heating/cooling

                return true;
            }
        }

        return false;
    }

    if (sch.CFG_Type == SchedulationType.Season) {

        return false;
    }

    let value: BitArray = new BitArray(APPLY_TO.length).fromNumber(sch.PAR_ApplyTo);
    let bit: number = APPLY_TO.indexOf(table);

    if (bit == -1) {

        return false;
    }

    return value.get(bit);
}

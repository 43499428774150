import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpResponse} from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map,catchError } from 'rxjs/operators';
import { Backup } from '../models/backups';
import { AppState } from './app-state.service';
import { Config, DEFAULT_LANGUAGE } from '../models/config';
import * as moment from 'moment';

import {handleError} from './support.service';


@Injectable({
  providedIn: 'root'
})
export class BackupsService {

    private config: Observable<Config>;
    private baseUrl: string;
    private language: string;

    constructor(private http: HttpClient,
                protected store: Store<AppState>)
    {
        this.config = store.select('config') as Observable<Config>;
        this.config.subscribe(data => {
            this.baseUrl = data.baseUrl;
            this.language = data.language;
        })
        //this.store.dispatch( new LoadConfig());
    }


    createBackup(name: string): Observable<Backup[]> {
        // Create a new backup using the API, reload the backup list
        let body = JSON.stringify({ name: name });
        let _headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      
        return this.http.post(this.baseUrl + 'api/backup/', body, {headers:_headers}).pipe(
            map(this.parseResponse),
            catchError(handleError));
    }

    getBackups(): Observable<Backup[]> {
        // Load the backup lists using the API
        return this.http.get(this.baseUrl + 'api/backup').pipe(
            map(this.parseResponse),
            catchError(handleError));
    }

    renameBackup(id: number, name: string): Observable<Backup[]> {
        // Rename a backup using the API, reload the backup list
        let body = JSON.stringify({ name: name });
        let _headers = new HttpHeaders({ 'Content-Type': 'application/json' });
       
        return this.http.put(this.baseUrl + 'api/backup/' + id, body, {headers:_headers}).pipe(
            map(this.parseResponse),
            catchError(handleError));
    }

    deleteBackup(id: number) {
        // Delete a backup using the API, reload the backup list
        return this.http.delete(this.baseUrl + 'api/backup/' + id).pipe(
            map(this.parseResponse),
            catchError(handleError));
    }

    restoreBackup(id: number) {
        // Restore a backup using the API, reload the backup list
        let body = JSON.stringify({});
        let _headers = new HttpHeaders({ 'Content-Type': 'application/json' });
       
        return this.http.post(this.baseUrl + 'api/backup/' + id + '/restore', body, {headers:_headers}).pipe(
            map(this.parseResponse),
            catchError(handleError));
    }

    private parseResponse = (res: HttpResponse<any>): Backup[] => {
        //vecchia versione
        /*let data = res.json();
        
        if (data.code == 0) {
            throw Error(data.content);
        }
        let backups = data.content as Backup[];
        for (let backup of backups) {
            backup.timestamp = moment(backup.timestamp).locale(this.language || DEFAULT_LANGUAGE).format('LLL');
        }
        return backups;
        */

       //nuova versione
     
        let data=res;

         if (((data as unknown) as any).code == 0) {
            throw Error(((data as unknown) as any).content);
        }
        let backups = ((data as unknown) as any).content as Backup[];
        for (let backup of backups) {
            backup.timestamp = moment(backup.timestamp).locale(this.language || DEFAULT_LANGUAGE).format('LLL');
        }
        return backups;

       
    }

}

